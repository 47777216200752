import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { SearchSchoolSubject } from 'components/tools/search_school_subject';
import { SingleLineField} from 'components/tools/modifiable_fields';

// Hooks
import { useTranslation } from 'react-i18next';

// API
import { updateSchoolClass } from 'api/classes';

export function ModifySchoolClass({value, setValue}){
  const {t} = useTranslation('common');

  return<div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("modify-preset")}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
                <SingleLineField 
                    label={true&&t("name")} 
                    modify={true} 
                    value={value.name} 
                    setValue={(name)=>{setValue({...value, name}); updateSchoolClass({slug: value.slug, name})}}/>
                  <SearchSchoolSubject 
                  label={t("equivalent-school-subject")}
                  value={value.subject}
                  size="lg"
                  setValue={(subject)=>{setValue({...value, subject}); updateSchoolClass({slug: value.slug, subject})}}
                />
              </CardContainer>
            </div>


}