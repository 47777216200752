import React, {useMemo} from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiCheck, FiToggleRight, FiX } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';

export function SelectBool({ value, setValue, supportNull, yesNo=true, Icon, disabled, color,...props }){

  const { t } = useTranslation("common");
  const values = [
    {
      name: yesNo? t("yes"): t("active"),
      value: "oui"
    },
    {
      name: yesNo?t("no"): t("inactive"),
      value: "non"
    }
  ]
  const controlledValue = useMemo(()=>value==null && supportNull? null: value===false? values[1]: value===true? values[0]: !value? values[0]: !value.value? values.find(d=>d.value === value): value, [value]);
  return <ListSelection value={controlledValue}
                        setValue={setValue}
                        disabled={disabled}
                        values={values}
                        color={color? color: value==null? 'default': value===true || value.value==="oui"? 'green': 'red'}
                        label={t('yes/no')}
                        Icon={Icon? Icon: value==null? FiToggleRight: value===true || value.value==="oui"? FiCheck: FiX}
                        getKey={(d)=>d.value}
                        format={(d)=>d.name}
                        withClearSelection={false}
                        {...props}
                        />
}