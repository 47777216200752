import React from 'react';

// Components
import { Container } from 'components/core/container';
import { ModifyStudySession } from 'components/study-sessions/modify';

export default function GeneralSettings(){

  return <Container className="h-full w-full">
           <ModifyStudySession/>
         </Container>
}

