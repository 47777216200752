/* eslint-disable */
import React from 'react';

// Components
import { Meetings } from 'components/follow-up/meetings';
import { MultiColumn } from 'components/core/layouts';
import { Button } from 'components/core/button';
import { FiArrowLeft } from 'react-icons/fi';
import { TeamContextProvider, TeamContext } from 'contexts/team';
import { Container, CardContainer } from 'components/core/container';
import { Skeleton } from 'components/core/skeleton';
import { SlideOver } from 'components/core/slide_over';
import { TeamInfo } from 'components/teams/info';
import { CoordoStats } from 'components/coordo-stats';
import { TextWarningStructured } from 'components/core/typo';

// Icons
import { FiInfo } from 'react-icons/fi';

// Utils
import classNames from 'classnames';
import { getCurrentPeriod } from 'utils/period';

// Hooks
import { useParams } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';

export default function MeetingsPage() {
    const { slug: team } = useParams();
    return <div className="h-full">
        <MultiColumn>
            <MultiColumn.Left className={classNames("border-r h-full overflow-visible z-10  md:!max-w-sm", team ? "hidden lg:block" : "")}>
                <CoordoStats.MeetingsProgression showStats={false} navigationClassName="flex-row-reverse " className="!p-3 !pb-5 bg-inherit shadow-none rounded-none border-b !rounded-b-none" />
                <Meetings.TeamsList />
            </MultiColumn.Left>
            <MultiColumn.Content className={classNames(team ? "block bg-gray-white" : "hidden lg:block", "h-full")}>
                <TeamContextProvider>
                    {
                        team && <Header />
                    }
                    <div className=''>
                        {team ? <Meetings.Details /> : <EmptyState />}
                    </div>
                </TeamContextProvider>

            </MultiColumn.Content>
        </MultiColumn>
    </div>
}

export function Header() {
    const { t } = useTranslation("common");
    const { team, loading } = useContext(TeamContext);
    const { presetId: preset } = useParams();
    const [open, setOpen] = useState(false);
    const period = team && team.active_period;
    const currentPeriod = getCurrentPeriod();
    if (!team || !team.name || loading) {
        return <Container className="py-4 bg-white">
            <Skeleton className="w-screen max-w-sm h-12" />
        </Container>
    }
    return <div>
        {
            period?.slug !== currentPeriod && <>
                <div className='p-2'>
                    <TextWarningStructured title={t("inactive-period")} description={t("team-period-is-not-active", {currentPeriod, period: period?.slug, start: period?.start, end: period?.end})}/>
                </div>
            </>
        }
        <Container className="py-3 border-b flex justify-between bg-white">
            <div className='w-full'>
                <Button href={`/suivi-terrain/${preset}/meetings`} color="inlineLink" className="md:hidden"><FiArrowLeft className="mr-1" />{t("teams")}</Button>
                <h1 className="font-medium text-2xl">{team.name}<span className="ml-3">{team.active_period && team.active_period.name}</span></h1>
                <p className="text-base text-gray-500">
                    <span>{team.sport && team.sport.name}</span> | <span>{team.school && team.school.name}</span>
                </p>
            </div>
            <div className=''>
                <Button color="toolbarIcon" size="toolbarIcon" onClick={() => setOpen(!open)}><FiInfo /></Button>
                <SlideOver open={open} setOpen={setOpen}>
                    {team && <CardContainer><TeamInfo team={team} /></CardContainer>}
                </SlideOver>
            </div>
        </Container>
    </div>
}


function EmptyState() {
    const { t } = useTranslation("common");
    return <div className="flex items-center justify-center h-96">
        <p className="text-gray-500">{t("select-team-for-details")}</p>
    </div>
}