import React, { useContext } from 'react';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { locale, parseWithTimeZone } from 'utils/locale';
import { useQuery } from 'hooks/useQuery';

// API
import { searchPortalUsers } from 'api/portal';

// Components
import { Table } from 'components/core/table';
import { Paginate } from 'components/core/paginate';
import { Error } from 'components/core/typo';
import { EmptyState } from 'components/core/empty';
import { Skeleton } from 'components/core/skeleton';
import { SlideOver } from 'components/core/slide_over';
import { UserViewQueryBased } from 'components/portal-access/user';
import { SearchContext, SearchContextProvider } from 'contexts/search';

const styles = {
    index: "font-medium max-w-xs y-2 truncate ",
    base: "text-gray-600 max-w-xs py-1 truncate ",
    inactive: "text-red-600 max-w-xs py-1 truncate bg-red-50 ",
}
export function AccessListWithContext({ role }) {
    const { query, setQuery } = useQuery();
    const [open, setOpen] = useState(query.has("ni"));
    const { filters, dispatchFilters } = useContext(SearchContext)

    const method = useMemo(() => searchPortalUsers, [role])
    const params = useMemo(() => ({ ...filters, role }), [filters, role])
    const { t } = useTranslation("common");
    const [results, { loading, setPage, paging, error, setResult }] = useSearch(method, params);
    const headers = useMemo(() => {
        const res = [
            {
                title: t("name"),
                field: (d => d ? d.name : "name"),
                itemClassName: styles.index
            },
            {
                title: t("email"),
                field: "email",
                itemClassName: styles.base
            },
            {
                title: t("email-verified"),
                field: "email_verified",
                placeholder: "-",
                itemClassName: (d => d ? styles.base : styles.inactive),
                format: (d => d ? t("oui") : t("non"))
            },
            
        ];
        if (role === "tutor") {
            res.push({
                title: t("documents-signed"),
                field: "documents_signed",
                placeholder: "-",
                itemClassName: (d => d ? styles.base : styles.inactive),
                format: (d => d ? t("oui") : t("non"))
            });
        }
        if ((role === "teacher") || (role === "partner")) {
            res.push(
                {
                    title: t("school"),
                    field: "school_permissions",
                    placeholder: "-",
                    itemClassName: styles.base,
                    format: (d => (d && d.length > 0) ? d[0].name : "-")
                });
        }
        res.push(
            {
                title: t("created-at"),
                field: "created_at",
                itemClassName: styles.base,
                format: (d => locale.format("%d %B %Y")(parseWithTimeZone(d))),
            },
            {
                title: t("last-connection"),
                field: "last_connection",
                itemClassName: styles.base,
                format: (d => d ? locale.format("%d %B %Y")(parseWithTimeZone(d)) : "-")
            },
            {
                title: t("verified"),
                field: "verified",
                placeholder: t("non"),
                itemClassName: (d => d ? styles.base : styles.inactive),
                format: (d => d ? t("oui") : t("non")),
            },
        );
      
        return res;
    },
        [role]);

    const onRowClick = useCallback(({ ni }) => {
        setOpen(true); setQuery({ ni });
    }, []);

    useEffect(() => {
        if (!open) { setQuery() }
    }, [open])

    return <div className="w-full px-3">
        {paging && <Paginate setPage={setPage} {...paging} />}
        <Table headers={headers}
            data={results}
            onRowClick={onRowClick}
            order={{ by: filters.orderBy, direction: filters.orderDirection }}
            onHeaderClick={(value) => dispatchFilters({ type: 'ordering', value })}
            indexingKey={"ni"}
        />
        {!results && loading && <div className="space-y-3">
            <Skeleton className="h-12" />
            <Skeleton className="h-12" />
            <Skeleton className="h-12" />
        </div>}
        {results && results.length === 0 && <EmptyState title={t("no-result")} />}
        {paging && <Paginate setPage={setPage} {...paging} />}
        {error ? <Error.Text className="mt-3" {...error} /> : null}

        <SlideOver open={open} setOpen={setOpen} size="xl3">
            <UserViewQueryBased setOpen={setOpen} setUsers={setResult} />
        </SlideOver>
    </div>
}

export function AccessList({ role }) {
    return <SearchContextProvider doNotStore defaultState={{ orderBy: "name", orderDirection: "asc" }}>
        <AccessListWithContext role={role} />
    </SearchContextProvider>
}