import React, { useContext, useMemo } from 'react';

// Components
import { AutoPairingLayout } from 'components/automatic-pairing/layout';
import { AutoPairingContext } from 'contexts/auto-pairing';
import { Modify } from 'components/automatic-pairing/modify';
import { Skeleton } from 'components/core/skeleton';
import { Container } from 'components/core/container';
import { ReadOnlyParameters } from 'components/automatic-pairing/parameters';
import { convertIntegerToObject, convertObjectSlugToCamel } from 'utils/objects';
// Contexts 

// Hooks

export default function Params(){
  const {result, setAutoPairing} = useContext(AutoPairingContext)
  const parameters = useMemo(()=>result && result.parameters && convertIntegerToObject(convertObjectSlugToCamel(result.parameters)), [result]);
  return <AutoPairingLayout>
          <Container>
            {!result? <Skeleton className={"h-40"}/>:
            <>
              <Modify value={result} setValue={setAutoPairing}/>
              <ReadOnlyParameters value={parameters} updateValue={null} disabled={true}/>
            </>}
            </Container>
        </AutoPairingLayout>
}
 