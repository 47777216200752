import React, { useMemo } from 'react';

// Components
import { TextInput } from 'components/core/inputs'
import { Button } from 'components/core/button';
import { SearchStudents } from 'components/tools/student_search';
import { Error, InfoSectionHeading, RawTextInfo, RawTextWarning } from 'components/core/typo'
import { SearchSchoolClass } from 'components/tools/search_school_class';
import { SelectDate } from 'components/tools/select_date';
import { Dropdown } from 'components/core/dropdown';

// API
import { addFailedClass } from 'api/classes';

// Hooks
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';
import { SelectDocumentGroup } from './select_document_group';
import { SelectPeriods } from './select_period';
import { ModifiableSelectField } from './modifiable_fields';
import { useSearch } from 'hooks/useSearch';
import { searchDocuments } from 'api/documents';
import { CreateDocumentButton } from 'components/documents/create_modal';

export function NewClassFailed({defaultStudent, disabled, setValue}){
  const { t } = useTranslation('common');
  const [student, setStudent] = useState(defaultStudent);
  const [schoolClass, setSchoolClass] = useState();
  const [score, setScore] = useState();
  const [startedAt, setStartedAt] = useState(new Date());
  const [endedAt, setEndedAt] = useState();
  const [description, setDescription] = useState();
  const [documentGroup, setDocumentGroup] = useState();
  const [period, setPeriod] = useState();

  const params = useMemo(()=>({student, period, group:documentGroup}), [student, period, documentGroup]);
  const [documents, {execute: reloadDocuments, error: errorDocuments}] = useSearch(searchDocuments, params, {validateParams: (params=>params.period && params.group)})

  const documentFound = (documents && documentGroup &&period) && (documents.length>0);
  const [result, {loading, error, execute}] = useAPI(addFailedClass, 
                                {student: student && student.ni, 
                                  schoolClass: schoolClass && schoolClass.slug, 
                                  score,
                                  documentGroup,
                                  period,
                                  endedAt,
                                  startedAt,
                                  description}, 
                                  {immediate: false});

  useEffect(()=>{
    if (result && setValue){
      setValue(result);
    }
  }, [result]);

  return <div>
          <div className='space-y-4'>
            <InfoSectionHeading title={t("add-school-class-failure")}/>

            <div className="space-y-6 flex-1 " >
            <SearchStudents size="md" value={student} setValue={setStudent} disabled={defaultStudent} color="default"/>
             <SearchSchoolClass value={schoolClass} setValue={setSchoolClass} targetWidth="flexfull" color="default" size="md"/>
            <div className="md:flex items-center space-x-3">
              <TextInput type="number" step="0.1" max="100" min="0" label={t("new-school-class.score-label")} placeholder={t('new-school-class.score-placeholder')} onChange={setScore} size="md"/>
              <TextInput className="flex-1"  label={t("new-school-class.description-label")} placeholder={t('new-school-class.description-placeholder')} onChange={setDescription} size="md"/>
            </div>
            <div className='flex items-center justify-between'>
              <InfoSectionHeading smallTitle={t("associate-document.title")} description={t("associate-document.description")}/>
              <div className='flex items-center space-x-3'>
                <SelectDocumentGroup value={documentGroup} setValue={setDocumentGroup} targetWidth="flexfull" color="default" size="md"/>
                <SelectPeriods value={period} setValue={setPeriod} targetWidth="flexfull" color="default" size="md"/>
              </div>
            </div>
         
              {(documents && documentGroup &&period) && 
              (documents.length===0?
              <RawTextWarning>
              {t("no-documents-found", {student: student.name, group: documentGroup && documentGroup.name, period: period && period.name})}
              <br/>
              {documents && documentGroup &&period && documents.length===0 && <CreateDocumentButton className="mt-2"  color="warningCard" creatorProps={{defaultStudent:student, defaultPeriod: period, defaultGroup: documentGroup}} onCompleted={reloadDocuments}/>}

            </RawTextWarning>:
            <RawTextInfo>
              {t("documents-found", {group: documents[0].group.name, period: documents[0].period.name, student: student.name, count: documents.length})}
            </RawTextInfo>)
              }
            {errorDocuments? <Error.Text {...errorDocuments}/>: null}

            <div className='space-y-3'>
            <ModifiableSelectField 
                      Field={SelectDate} 
                      label={t("failed-class-started-at")} 
                      modify={true}
                      noBorder
                      value={startedAt}
                      setValue={setStartedAt}/>
                <ModifiableSelectField 
                      Field={SelectDate} 
                      label={t("failed-class-ended-at")} 
                      subLabel={t("failed-class-ended-at-description")}
                      modify={true}
                      noBorder 
                      value={endedAt}
                      setValue={setEndedAt}/>
           
              </div>
            <Button disabled={disabled || !student || !schoolClass || !documentGroup || !period || !score || !documentFound } color="active" size="lg" block loading={loading} onClick={execute}>
              {t('add-school-class-failure')}
            </Button>
          {error? <Error.Text {...error}/>: null}
            </div>
        </div>
         </div>
}

export function SelectSchoolFail({value, setValue}){
  const [schoolClass, setSchoolClass] = useState();
  const [description, setDescription] = useState();
  const [score, setScore] = useState();
  const { t } = useTranslation('common');
  return <div className="grid grid-cols-2 gap-3 max-w-sm">
            {value&& value.map((d,i)=>
              <Dropdown key={d.schoolClass.slug} label={d.schoolClass.name}>
                <Dropdown.Item name={d.description} description={d.score}/>
                <Dropdown.Item name={t("remove")} color="danger" onClick={()=>setValue(e=>e.filter((el,j)=>j!==i))}/>
              </Dropdown>
              )}
            <div className="col-span-full">
              <Dropdown menuClassName="w-full" label={t("add-school-class-failure")} itemClassName="p-3 space-y-3 w-80">
                <SearchSchoolClass value={schoolClass} setValue={setSchoolClass}/>
                <TextInput type="number" 
                          step="0.1" 
                          max="100" 
                          min="0" 
                          label={t("new-school-class.score-label")} 
                          placeholder={t('new-school-class.score-placeholder')} 
                          onKeyDown={(e) => { if (e.code === "Space") { e.stopPropagation(); } }}
                          onChange={setScore} 
                          size="sm"/>
                <TextInput 
                  label={t("description")} 
                  placeholder={t("new-school-class.description-placeholder")}
                  required
                  size="sm"
                  onKeyDown={(e) => { if (e.code === "Space") { e.stopPropagation(); } }}
                  onChange={setDescription}/>

                <Button color="black" block size="sm" 
                disabled={!schoolClass} 
                onClick={()=>{setValue(e=>e?[...e, {schoolClass, score, description}]: [{schoolClass, score, description}]); setDescription(); setScore(); setSchoolClass()}}>
                  {t("add-school-class-failure")}
                </Button>
              </Dropdown>
            </div>
         </div>
}