import React from 'react';

// Components
import { TextInput } from 'components/core/inputs'
import { Info } from 'components/core/info';
import { Button } from 'components/core/button';
import { SearchStudents } from 'components/tools/student_search';
import { SearchTeams } from 'components/tools/search_teams';
import { Error, InfoSectionHeading } from 'components/core/typo'

// API
import { addTeamMember } from 'api/teams';

// Hooks
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

export function NewTeamMember({defaultStudent, defaultTeam, disabled, setValue}){

  const { t } = useTranslation('common');
  const [student, setStudent] = useState(defaultStudent);
  const [team, setTeam] = useState(defaultTeam);
  const [description, setDescription] = useState();

  const [result, {loading, error, execute}] = useAPI(addTeamMember, {student: student && student.ni, team: team && team.slug, description}, {immediate: false});

  useEffect(()=>{
    if (result && setValue){
      setValue(result);
    }
  }, [result]);

  return <div className='p-6'>

    <InfoSectionHeading title={t('add-team')}/>
            <Info.Container  modify={true} className={"mt-6"}>

          {!defaultStudent && <SearchStudents size="md" value={student} setValue={setStudent} disabled={defaultStudent} color="default"/>}
         {!defaultTeam &&  <SearchTeams showPeriodFilter={true} value={team} setValue={setTeam} disabled={defaultTeam} color="default"/>}
          <TextInput placeholder={t('team-member-type')} description={t("description")} onChange={setDescription} size="md"/>
          <Button disabled={disabled || !student || !team } color="active" size="lg" block loading={loading} onClick={execute}>
            {t('add-team-member', {name: student && student.name, team: team &&team.name})}
          </Button>
          {error? <Error.Text {...error}/>: null}
         </Info.Container>
         </div>
}