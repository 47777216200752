import {useContext} from 'react';

import { ActionsPlanContext } from 'contexts/actions-plan';

export function useActionsPlan() {
    const context = useContext(ActionsPlanContext);
    if (!context) {
        console.warn('useActionsPlan must be used within a ActionsPlanProvider');
        return {};
    }
    return context;
}