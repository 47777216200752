import React from 'react';
import classnames from 'classnames';

// Hooks
import { useHover } from 'hooks/useHover';
import { useDebounce } from 'hooks/useDebounce';

// Components
import { Fragment } from 'react';

const positions = {
  left: "left-0",
  center: "left-1/2 -translate-x-1/2",
  right: "right-0",
} 
const styles = {
  light: "bg-white text-gray-700 border",
  transparent: "bg-transparent !shadow-none",
  dark: "bg-gray-700 text-white"
}

const ForwardRefToChildren = React.forwardRef(
  function ForwardRefToChildren({children}, ref){
    return React.cloneElement(React.Children.only(children), {className:classnames(children.props.className, "cursor-pointer"), ref})
  }
);

export function Tooltip({content, children, className, contentClassName, onClick, delay=200, position="left", color="dark"}){
  const [hoverRef, isHovered] = useHover();
  const isHoveredDebounce = useDebounce(isHovered, delay);

  const tip = content&&
                <div className={classnames("top-full absolute h-auto w-full min-w-max max-w-md group z-50", positions[position], className)}>
                <div className={classnames("rounded-lg text-sm shadow top-10 px-3 py-1 text-left block min-w-sm max-w-md z-50", 
                                      positions[position], 
                                      styles[color], 
                                      isHoveredDebounce && isHovered? 'opacity-100': 'opacity-0 hidden', contentClassName)} onClick={onClick}>
                  {content}
                </div>
          </div>;
  return <Fragment>
          <ForwardRefToChildren ref={hoverRef}>
            {React.cloneElement(children, {}, <Fragment>{children.props.children}{tip}</Fragment>)}
          </ForwardRefToChildren>
          
        </Fragment>
}