import React from 'react';

// Components
import { Container } from 'components/core/container';
import { FlexNav } from 'components/core/layouts/flex';
import { Outlet } from "react-router-dom";
import { SubSideNavLink } from "components/core/nav";
import { FiUser } from 'react-icons/fi';
import { FiLogOut as CloseIcon, FiLogIn as OpenIcon } from 'react-icons/fi';

// Router
import { useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NavBar() {
  const { t } = useTranslation();
  const params = useParams();

  return <div >
    <p className="mt-5 text-sm font-medium text-gray-600 uppercase">{t("general-settings")}</p>
    <SubSideNavLink path={generatePath(`/coachs/:ni/settings/`, params)} Icon={FiUser} name={t("personal-info")} />
    <p className="mt-5 text-sm font-medium text-gray-600 uppercase">{t("account-managing")}</p>
    <SubSideNavLink path={generatePath(`/coachs/:ni/settings/open`, params)} Icon={OpenIcon} name={t("open")} />
    <SubSideNavLink path={generatePath(`/coachs/:ni/settings/close`, params)} Icon={CloseIcon} name={t("close")}/>
  </div>

}
export function CoachSettings() {
  return <Container className="py-6">
    <FlexNav>
      <FlexNav.Bar>
        <NavBar />
      </FlexNav.Bar>
      <FlexNav.Content>
        <Outlet />
      </FlexNav.Content>
    </FlexNav>
  </Container>
}

import { default as general } from './general';
import { CloseSettings as close } from './close';
import { OpenSettings as open } from './open';
export const CoachSettingsPages = { root: CoachSettings, general, close, open }