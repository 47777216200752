import React from 'react';

// Components
import { Tabs } from 'components/core/tabs';

// Hooks
import { useState } from 'react';
import { useParams } from 'react-router-dom';

export function NavBar(){
  const params = useParams();
  const tabs = [
    {
      name: "Jumelages",
      href: `/automatic-pairing/${params.draftId || ""}`,
    },
    {
      name: "Élèves-athlètes non jumelés",
      href: `/automatic-pairing/${params.draftId || ""}/unmatched-students/`,
    },
    {
      name: "Tuteurs non jumelés",
      href: `/automatic-pairing/${params.draftId || ""}/unmatched-tutors`,
    },
    {
      name: "Paramètres",
      href: `/automatic-pairing/${params.draftId || ""}/parameters`,
    }
  ]
  const [tab, setTab] = useState(tabs[0]);

  return <div className="border-b">
          <Tabs tabs={tabs} value={tab} setValue={setTab}/>
         </div>
}