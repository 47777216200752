import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next'; 

// Utils
import classnames from 'classnames';

// Components
import { Info } from 'components/core/info';
import { SingleLineField, ModifiableSelectField } from 'components/tools/modifiable_fields';
import { InfoSectionHeading  } from 'components/core/typo';
import { SelectDate } from 'components/tools/select_date';

export function SelectSettings({value, setValue}){
  const {t} = useTranslation("endYearAssistant")

  return <div className={classnames("")}>
            <InfoSectionHeading mediumTitle={t("students.settings.title")}/>
             <ModifiableSelectField 
                    Field={SelectDate} 
                    label={t("students.end-date")} 
                    modify={true} 
                    noBorder
                    value={value.endedAt} 
                    setValue={(endedAt)=>setValue(d=>({...d, endedAt}))} />
            <SingleLineField 
                    label={t("students.reason-of-end")} 
                    modify={true} 
                    value={value.reason} 
                    multiLine={false}
                    showSavingState={false}
                    setValue={(reason)=>setValue(d=>({...d, reason}))}>
            {(val)=><Info.Field value={val} noValueLabel={t("write-a-reason-of-end")}/>}
          </SingleLineField>
         </div>
}

