import React, {createContext, useMemo } from "react";

// Hooks
import { useAPI } from "hooks/useAPI";
import { retrieveTutorPortalUser } from "api/tutors";

const TutorPortalUserContext = createContext({});

const TutorPortalUserContextProvider = ({ ni, children }) => {
  const params = useMemo(() => ({ ni }), [ni]);
  const [portalUser, {loading, error, setResult}] = useAPI(retrieveTutorPortalUser, params);

  return (
    <TutorPortalUserContext.Provider value={{portalUser, loading, error, setPortalUser: setResult}}>
      {children}
    </TutorPortalUserContext.Provider>
  );
};

export { TutorPortalUserContext, TutorPortalUserContextProvider };