import React from 'react';

// Components
import { Button } from 'components/core/button';
import { Fragment } from 'react';
import { SlideOver } from 'components/core/slide_over';
import { OpenNotesCard } from 'components/notes/open_notes';
import { Container } from 'components/core/container';

// Hooks
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';
import { countNotes } from 'api/notes';
import { useMemoCompare } from 'hooks/useMemoCompare';

export function ShowNotesInModals({open, setOpen, filters, title}){
  return <SlideOver open={open} setOpen={setOpen} size="xl4"> 
          <Container className="mt-2">
            <OpenNotesCard filters={filters} title={title}/>
          </Container>
        </SlideOver>
}


function ShowNotesButtons({ filters, badge, title }){
  const [open, setOpen] = useState(false);

  return <Fragment>
          <Button color="gray" size="md" className="relative" onClick={()=>setOpen(true)}>
            {title}
            {badge? <span className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 bg-red-500 text-xs text-white px-1 py-0.5 rounded-lg">{badge}</span>: null}
          </Button>
          <ShowNotesInModals open={open} setOpen={setOpen} filters={filters} title={title}/>
        </Fragment>
}

export function ShowOpenNoteButton({ filters }){
  const {t} = useTranslation('common')
  const _filters = useMemo(()=>({...filters,
                                        isOpen:true,
                                        shouldDiscuss:null, 
                                        fromDate: null, 
                                        toDate: null,
                                        code: null, 
                                        group: null}), [filters])
  const params = useMemoCompare(_filters, (prev, next)=>(prev && 
    next && JSON.stringify(prev) === JSON.stringify(next)));
  const [numNotes] = useAPI(countNotes, params, {immediate:true});
  return <ShowNotesButtons filters={_filters} badge={numNotes} title={t("open-notes")}/>
}

export function ShowToDiscussNotesButton({filters}){
  const {t} = useTranslation('common')
  const _filters = useMemo(()=>({...filters,
                                        isOpen:null,
                                        shouldDiscuss:true, 
                                        fromDate: null, 
                                        toDate: null,
                                        code: null, 
                                        group: null}), [filters]);
  
  const params = useMemoCompare(_filters, (prev, next)=>(prev && 
    next && JSON.stringify(prev) === JSON.stringify(next)));
  const [numNotes] = useAPI(countNotes, params, {immediate:true});
  return <ShowNotesButtons filters={_filters} badge={numNotes} title={t("should-discuss-notes")}/>
}