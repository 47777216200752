import React from 'react';

// Hooks
import { useState, useEffect } from 'react';
import { useDebounce } from 'hooks/useDebounce';

// Components
import { TextInput } from 'components/core/inputs';

export function ListSearch({onChange, debounceDelay=300, ...props}){
  const [val, setVal] = useState();
  const debounceVal = useDebounce(val, debounceDelay);
  useEffect(()=>{
    onChange(debounceVal);
  }, [debounceVal])
  return <TextInput onChange={setVal} {...props}/>
}