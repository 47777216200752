import { React, Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { Button } from './button'

export function FlyoutFull({children, label, labelClassName=""}) {
  return (
    <Popover className="relative z-10 isolate">
      <div className="">
        <div className="mx-auto">
          <Popover.Button as='div' className={classNames(labelClassName, 'inline-flex items-center w-full px-6 py-3 text-sm font-semibold leading-6 text-gray-900 outline-none gap-x-1')}>
                <Button color={'default'} size={'md'}>
                    {label}
                    <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
                </Button>
            
          </Popover.Button>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 -translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <Popover.Panel className="absolute inset-x-0 top-0 mt-16 bg-white shadow-md">
          {children}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export const Flyout = {
    Full: FlyoutFull
}