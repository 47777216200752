import React from 'react';

// Context
import { TutorPortalUserContext } from 'contexts/tutor_portal_user';

// Hooks
import { useContext } from 'react';
import { Skeleton } from 'components/core/skeleton';
import { RawTextWarning } from 'components/core/typo';
import { useTranslation } from 'react-i18next';
import { Info } from 'components/core/info';
import { locale, parseWithTimeZone } from 'utils/locale';
import { FiCheck, FiX } from 'react-icons/fi';

export function PortalUserCard(){
    const {t} = useTranslation();
    const {portalUser, loading} = useContext(TutorPortalUserContext);
    if (loading) return <Skeleton className={"h-32"}/>
    if (!portalUser){
        return <RawTextWarning>{t("tutor-has-no-portal-user")}</RawTextWarning>
    }
    return <Info.Container label={t("tutor-portal-user.associated")}>
                        <Info.Field value={t(portalUser.role)} label={t("role")}/>
                        <Info.Field value={portalUser.email} label={t("email")}/>
                        {portalUser.role==="tutor" && <Info.Field value={!portalUser.documents_signed? 
                        <><FiX className='h-5 w-5 text-red-500 inline mr-2'/>{t("portal-user-has-required-documents", {context: portalUser.role})}</>:
                        <><FiCheck className='h-5 w-5 text-green-500 inline mr-2'/><span>{t("portal-user-has-no-required-documents", {context: portalUser.role})}</span></>}/>}
                        <Info.Field value={!portalUser.email_verified? 
                        <><FiX className='h-5 w-5 text-red-500 inline mr-2'/>{t("portal-user-not-activated")}</>:
                        <><FiCheck className='h-5 w-5 text-green-500 inline mr-2'/><span>{t("portal-user-activated")}</span></>}/>
                        <Info.Field value={portalUser.created_at && locale.format("%d %B %Y")(parseWithTimeZone(portalUser.created_at))} 
                        label={t("account-created-at")}/>
                    </Info.Container>
}
