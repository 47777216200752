import React from 'react';

// Components
import { Navigate } from 'react-router-dom';
import { CenterViewLayout } from 'components/core/layouts/center';
import { Spinner } from 'components/core/spinner';

// API
import { logout } from 'api/auth';

// Hooks
import { useUser } from 'hooks/useUser';
import { useState, useEffect } from 'react';

export function LogoutPage(){
  /*
    Check if logged in.
  */
  const [isCompleted, setIsCompleted] = useState();
  const [user, setUser] = useUser();
  useEffect(()=>{
    if (user) setUser();
    const [promise] = logout()
    promise
      .then(()=>setIsCompleted(true))
      .catch(()=>setIsCompleted(false));
  }, [])

  if (isCompleted){
    return <Navigate to={"/login"}/>
  }
  return <CenterViewLayout>
          <Spinner/>
         </CenterViewLayout>
}

const route =  { path: "/logout", 
                 name: "Logout" , 
                 showInNavBar: false, 
                 requireAuth: false, 
                 Element: LogoutPage };
export default route;
