import React from 'react';

// Components
import { TeamMemberList } from 'components/student/team_list';
import { EventCalendar } from 'components/events/calendar';
import { FullFlexNav } from 'components/core/layouts/flex';
import { Container } from 'components/core/container';

// Hooks
import { useContext } from 'react';

// Contexts
import {TeamContext} from 'contexts/team';

export default function Overview(){
  const { team } = useContext(TeamContext);
  return <FullFlexNav className="p-6 block xl:flex" >
          <FullFlexNav.Bar >
              <TeamMemberList team={team}/>
          </FullFlexNav.Bar>
          <FullFlexNav.Content>
            <Container>
            <EventCalendar team={team.slug} practicePeriods={team.practice_periods} activePeriod={team.active_period} numMonths={2}/>
            </Container>
          </FullFlexNav.Content>
         </FullFlexNav>
}

