import React, {createContext, useContext} from "react";

// API
import { getStudentsMeetingsInfos } from "api/students";

// Hooks
import { useMemo } from 'react';
import { useAPI } from 'hooks/useAPI';
import { useParams } from "react-router-dom"

const StudentsMeetingsContext = createContext({});

export const useStudentsMeetings = () => {
    const context = useContext(StudentsMeetingsContext);
    if (!context) {
        throw new Error('useCoordoStats must be used within a CoordoStatsContextProvider');
    }
    return context;
}

const StudentsMeetingsContextProvider = ({ children }) => {
    const { slug } = useParams();
    const params = useMemo(() => ({team:slug}), [slug])
    const [students,{loading, execute:reload, setResult:setStudents}] = useAPI(getStudentsMeetingsInfos, params, {immediate: true, camelize: true})
    return (
        <StudentsMeetingsContext.Provider value={{students, setStudents, reload, loading}}>
            {children}
        </StudentsMeetingsContext.Provider>
    );
};

export { StudentsMeetingsContext, StudentsMeetingsContextProvider };