import React from 'react';

// API
import { retrieveStudySessionStats } from 'api/stats';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useDimensions } from 'hooks/useDimensions';
import { useMemo, useState, useEffect, useContext } from 'react'; 

// Contexts
import { SearchStudySessionsStatsContext } from 'contexts/follow_up_stats'

// Components
import { Skeleton } from 'components/core/skeleton';
import { Container } from 'components/core/container';
import { Table } from 'components/core/table';
import { SelectTabs } from 'components/core/select';

// utils
import { locale, dateParse } from 'utils/locale';
import { timeDay } from 'd3-time';
import { format } from 'd3-format';
import * as Plot from "@observablehq/plot";
// import * as d3 from 'd3';

function StatsChart({stats}){
  const [relative, setRelative] = useState(false);
  const tabs = useMemo(()=>[
      {name: "Abs.", value: false},
      {name: "%", value: true},
    ], [])
  const [width, , ref] = useDimensions();


  useEffect(() => {
    if (stats === undefined) return;
    if (relative){
      const chart = Plot.plot({
          marks: [
            Plot.rectY((stats ||[]).map(d=>({y:
                  d.num_study_sessions_target===0? 1.0:
                  parseFloat(d.num_study_sessions_done)/parseFloat(d.num_study_sessions_target), 
                                      x1: dateParse(d.date), 
                                      x2: timeDay.offset(dateParse(d.date), 6)})), 
            {x1: "x1", x2: "x2", y: "y", "fill": "y"}), 
            Plot.ruleY([0]),
          ], 
          style: {
            fontSize: 14
          },  
          x: {
            // nice: true,
            ticks: 3
          },
          y: {
            grid: true,
            line: true,
            percent: true,
            label: "% objectif"
          },
          color: {
            type: "threshold",
            scheme: "rdbu",
            domain: [60, 75, 90],
            percent: true,
            legend: true,
            label: "%"
          },
          height: 250,
          width: width,
          marginLeft: 50,
          marginRight: 20,
          marginTop: 30,
          marginBottom: 30,
          insetTop: 0,
          insetBottom: 0,
          insetLeft: 0,
          insetRight: 0
        })
      ref.current.append(chart);
      return () => chart.remove();

    }
    else{
      const chart = Plot.plot({
          marks: [
            
            Plot.rectY((stats||[]).map(d=>({y:d.num_study_sessions_target, 
                                      x1: dateParse(d.date), 
                                      x2: timeDay.offset(dateParse(d.date), 6)})), 
                {x1: "x1", x2: "x2", y: "y", "fill": "gray", opacity: 0.2}), 
            Plot.rectY((stats||[]).map(d=>({y:d.num_study_sessions_done, 
                                      x1: dateParse(d.date), 
                                      x2: timeDay.offset(dateParse(d.date), 6)})), 
                {x1: "x1", x2: "x2", y: "y", "fill": "y", opacity: 0.8}), 
            Plot.ruleY([0]),
          ], 
          style: {
            fontSize: 14
          },  
          x: {
            // nice: true
          },
          y: {
            grid: true,
            line: true,
            nice: true,
            label: "Sessions"
          },
          color: {
            scheme: "greens",
            legend: true,
            label: "Sesions",
          },
          height: 250,
          width: width,
          marginLeft: 50,
          marginRight: 20,
          marginTop: 30,
          marginBottom: 30,
          insetTop: 0,
          insetBottom: 0,
          insetLeft: 0,
          insetRight: 0
        })
      ref.current.append(chart);
      return () => chart.remove();
    }
  }, [stats, width, relative]);

  return <div>
            <div className="w-full h-0 flex items-center justify-end translate-y-6">
                <SelectTabs values={tabs} setValue={(v)=>setRelative(v)}/>
            </div>
            <div className="w-full" ref={ref}/>
          </div>
}


function StatsTable({stats}){
  const headers = useMemo(()=>[
      {
        title: "Date",
        field: (d=>d? d: "date"),
        itemClassName: "py-0.5 font-medium whitespace-nowrap",
        format: (d=>locale.format("%d %B %Y")(dateParse(d.date)))
      },
      {
        title: "Réalisé",
        itemClassName: "text-gray-500 text-center",
        className: "text-center",
        field: (d=>d? d.num_study_sessions_done: "num_study_sessions_done"),
      },
      {
        title: "Objectif",
        itemClassName: "text-gray-500 text-center",
        className: "text-center",
        field: (d=>d? d.num_study_sessions_target: "num_study_sessions_target"),
      },
      {
        title: "%",
        field: (d=>d? d: "fract"),
        className: "text-center",
        itemClassName: "text-gray-500 text-center",
        format: (d=>d.num_study_sessions_target===0?"-":format(".2%")(parseFloat(d.num_study_sessions_done)/parseFloat(d.num_study_sessions_target)))
      },
    ], []);
  return <Table data={stats} 
            headers={headers}
            headerRowClassName="bg-gray-100"
            rowClassName="hover:bg-gray-50"
          indexingKey={"date"}/>
}
export function StatsChartAndTable({student}){
  const {filters} = useContext(SearchStudySessionsStatsContext) 
  const params = useMemo(()=>({student, ...filters}), [student, filters])
  const [stats] = useSearch(retrieveStudySessionStats, params)


  if (!stats) return <Skeleton className="w-full h-80"/>
  return <Container className="space-y-4 my-4">
            <StatsChart stats={stats && stats.length>0 && stats[0].results}/>
            <StatsTable stats={stats && stats.length>0 && stats[0].results}/>
         </Container>
}