import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Info } from 'components/core/info';

// Utils
import classnames from 'classnames';
import { locale, dateParse } from 'utils/locale';

// Hooks
import { useTranslation } from 'react-i18next';

export function CoachInfo({ coach }){
  const { t } = useTranslation('common');
  if (!coach.name){
    return <Skeleton.List numElements={5}/>
  }

  return <div className={classnames("space-y-6")}>

            <Info.Container label={"Info"}>
                <Info.Field value={coach.name} noValueLabel={`${t("none")} ${t("name").toLowerCase()}`}/>
                <Info.Field value={coach.email} noValueLabel={`${t("none")} ${t("email").toLowerCase()}`}/>
                <Info.Field value={coach.phone} noValueLabel={`${t("none")} ${t("phone").toLowerCase()}`}/>
            </Info.Container>



            <Info.Container>
              <Info.Field value={coach.service_started_at && locale.format("%d %B %Y")(new Date(dateParse(coach.service_started_at)))}
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("active-since").toLowerCase()}`}
                                        label={t("active-since")}
                                        />
              <Info.Field value={coach.service_ended_at && locale.format("%d %B %Y")(new Date(dateParse(coach.service_ended_at)))}
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("retired-of-service").toLowerCase()}`}
                                        label={t("retired-of-service")}
                                        />

            </Info.Container>


            <Info.Container label={t("active-years")}>
                {coach.active_periods && coach.active_periods.map((d,i)=>
                  <Info.Field key={i} value={d.name} label={d.description}/>
                )}
            </Info.Container>

              <Info.Container label={t('teams')}>
                   {coach.teams && coach.teams.length>0?
                    <>
                    {coach.teams.map(val=>
                      <Info.Field key={val.slug}
                                  value={`${val.name}  (${val.slug.slice(-9)})`}
                                  to={`/teams/${val.slug}`}
                                  />)}
                    </>:
                    <Info.Field noValueLabel={t('empty-state.no-teams-title')} />}
              </Info.Container>


         </div>
}


