import React, {createContext } from "react";

// Hooks
import { useParams } from 'react-router-dom';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useMemo, useEffect } from 'react';
import { useAPI } from "hooks/useAPI";
import { retrieveDraftPairing } from "api/auto-pairing";

const AutoPairingContext = createContext({});

const AutoPairingContextProvider = ({ children }) => {
  const {draftId} = useParams();
  const immediate = useMemo(()=>false, []);
  const fields = useMemo(()=>({draftId, fields: ["id", "official", "description", "num_students", "num_tutors", "num_matches", "created_at", "parameters"]}), [draftId]);
  const [result, {loading, error, setResult, execute}] = useAPI(retrieveDraftPairing, fields, {immediate});

  const paramsChanged = useMemoCompare(draftId, (prev, next)=>prev && prev===next);
  useEffect(()=>{
    if (draftId){
        execute()
    }
  }, [paramsChanged])

  return (
    <AutoPairingContext.Provider value={{ result, setAutoPairing: setResult, loading, error }}>
      {children}
    </AutoPairingContext.Provider>
  );
};

export { AutoPairingContext, AutoPairingContextProvider };