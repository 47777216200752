// Hooks
import { useParams, useLocation } from 'react-router-dom';
import { useAPI } from 'hooks/useAPI';
import { useMemo, useCallback } from 'react';
import { useMemoCompare } from 'hooks/useMemoCompare';

// API
import { retrievePreset } from 'api/presets';

export function usePreset({ immediate=false }){
  const { presetId } = useParams();
  const persistentPresetId = useMemoCompare(presetId, (prev, next)=>prev && prev===next);
  const { state } = useLocation(); // Persitent state
  const params = useMemo(()=>({id:persistentPresetId}), [persistentPresetId]);
  const validateParams = useCallback((d)=>!!d.id, []);
  const [result, others] = useAPI(retrievePreset, params, {immediate, validateParams});
  return useMemo(()=>[state || result || {id:persistentPresetId}, others], [state, result, persistentPresetId, others]);
}