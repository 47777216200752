import React from 'react';

// Hooks

// Components
import { NavBar } from 'components/bulk-import/nav'
import { Outlet } from "react-router-dom";
import { InfoSectionHeading } from 'components/core/typo';
import { useTranslation } from 'react-i18next';

export function BulkImportView(){
  // const navigate = useNavigate();
  const { t } = useTranslation('common');
  // const params = useParams();

  return <div className="flex flex-col h-full">
    <div className=" px-3 bg-gray-100 border-b">
            <InfoSectionHeading mediumTitle={t("bulk-import.title")}/>
          </div>
          <NavBar/>
          <Outlet/>
        </div>
         
}