import { useMemo } from 'react';

// Utils
import { timeMonth, timeYear } from 'd3-time';

// Get the first date of school year and last....
export function useSchoolYear(date){

  return useMemo(()=>{
    var d = date || new Date();

    // if 7:August
    if (d.getMonth()>7){
      const fromDate = timeMonth.offset(timeYear.floor(d), 6);
      const toDate = timeYear.offset(fromDate, 1);
      return {fromDate, toDate};
    }
    else {
      const fromDate = timeMonth.offset(timeYear.offset(timeYear.floor(d), -1), 6);
      const toDate = timeYear.offset(fromDate, 1);
      return {fromDate, toDate};
    }
  }, [date])
}