import React from 'react';

// Components
import { Container } from 'components/core/container';
import { StudentCloseSettings } from 'components/student/modify';

// Hooks

// Contexts

export function CloseSettings(){

  return <Container className="w-full h-full">
            <StudentCloseSettings/>
         </Container>

}
