import React from 'react';
import classnames from 'classnames';

// API
import { searchEventTypes } from 'api/utils';

// Components
import { Skeleton } from 'components/core/skeleton';

// Hooks
import { useSearch } from 'hooks/useSearch';

export function SelectEventType({ value, setValue, className }){
  const [eventTypes] = useSearch(searchEventTypes);
  if (!eventTypes){
    return <Skeleton className="h-8"/>;
  }
  return <div className={classnames("grid grid-cols-2 gap-3 whitespace-nowrap  ",className)}>
            {eventTypes.map(d=>
              <div key={d.slug} 
                className={classnames("rounded-md col-span-1 p-2 flex items-center cursor-pointer border-2",
                  value && value.slug===d.slug? "bg-gray-200": "hover:bg-gray-100 bg-gray-50 border-transparent")}
                 onClick={()=>setValue(d)}>
                <div className="w-6 text-center" >
                  <input type="radio" id={d.slug} name={"slug"} checked={(value && value.slug===d.slug) || false} readOnly/>
                </div>
              <div className="flex-1 ml-3">
                <div className="text-medium flex items-center">{d.name}</div>
              </div>
            </div>
            )}
         </div>

}
