import React, { useEffect } from 'react';

// Components
import { Link } from 'react-router-dom';

// Utils
import classNames from 'classnames';

// Hooks
import { useState } from "react";
import { useTranslation } from 'react-i18next';

export function RankGrid({children}) {
    const { t } = useTranslation();
    return <>
        <div className="flex flex-col gap-4">
                <div className="grid grid-cols-3 gap-y-1.5">
                    <span className='font-semibold my-1 col-span-2'>{t("teams")}</span>
                    <span className='text-end font-semibold my-1'>{t("students-metrics.score")}</span>
                    {children}
                </div>
            </div>
        
    </>
}

export function ExpandableItems({children, expand = false}){
    const [expanded, setExpanded] = useState(false)
    const { t } = useTranslation();
    useEffect(() => {
        setExpanded(expand)
    }, [expand])

    return <>
        {expanded && children}
        {
            <div className="flex justify-center cursor-pointer col-span-full mt-2 text-gray-500 font-medium text-sm opacity-80 hover:opacity-100 transition-all" onClick={() => setExpanded(!expanded)}>
                {
                    expanded ? t("see-less") : t("see-more")
                }
            </div>
        }
    </>
}


/**
 * Renders a grid item for ranking statistics.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the grid item.
 * @param {number} props.percent - The percentage value for the grid item.
 * @param {number} props.score - The score value for the grid item.
 * @param {boolean} props.negative - The negative value for the grid item.
 * @returns {JSX.Element} The rendered grid item.
 */
export function RankGridItem({title, href, percent, score, negative}) {
    return <>
        <div className={classNames("text-gray-500 col-span-2")}>{ href ? <Link to={href}>{title}</Link>: title}</div>
        <div className='flex justify-end items-center gap-2 relative'>
            { percent && <span className='font-semibold block leading-none'>{percent}%</span> }
            <ScoreFeedback score={score} negative={negative} /> 
        </div>
    </>
}

export function ScoreFeedback({score = 0, negative = false}){
    const maxScore = 5
    const minScore = 0
    const trueScore = score > maxScore ? maxScore : score < minScore ? minScore : score
    const scoreArray = Array.from(Array(trueScore).keys())
    const restArray = Array.from(Array(maxScore - trueScore).keys())
    return <>
    
        <div className='grid grid-cols-5 gap-0.5 shrink-0'>
            {
                !negative ? 
                    scoreArray.map((_, index) => {
                        return <div 
                            key={index} 
                            className={classNames('w-2.5 h-4 rounded-[3px]',
                                trueScore === 1 && 'bg-red-600 border-red-800',
                                trueScore === 2 && 'bg-orange-400 border-orange-600',
                                trueScore === 3 && 'bg-green-500 border-green-700',
                                trueScore === 4 && 'bg-green-500 border-green-700',
                                trueScore === 5 && 'bg-green-500 border-green-700'
                            )}>
    
                        </div>
                    })
                : 
                    scoreArray.map((_, index) => {
                        return <div 
                            key={index} 
                            className={classNames('w-2.5 h-4 rounded-[3px]',
                                trueScore === 1 && 'bg-green-400 border-green-400',
                                trueScore === 2 && 'bg-yellow-400 border-yellow-400',
                                trueScore === 3 && 'bg-orange-500 border-orange-500',
                                trueScore === 4 && 'bg-red-600 border-red-800',
                                trueScore === 5 && 'bg-red-600 border-red-800'
                            )}>
    
                        </div>
                    })
            }
            {
                restArray.map((_, index) => {
                    return <div 
                        key={index} 
                        className={classNames('w-2.5 h-4 rounded-[3px] border-2 bg-gray-200 border-gray-200')}>

                    </div>
                })
            }
        </div>
    </>
}