import React from 'react';

// Hooks
// import { useMemoCompare } from 'hooks/useMemoCompare';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSearch } from 'hooks/useSearch';

// API
import { searchPairings } from 'api/students';

// Components
import { PairingList } from 'components/pairings/list'

export function StudentPairingsList(){
  const params = useParams();
  const searchParams = useMemo(()=>({ni:params.ni, orderBy: "created_at", orderDirection: "desc"}), [params]);
  // const p = useMemoCompare(params, (prev, next)=>prev && next && prev.ni === next.ni);
  const [pairings, {loading, error, page, setPage, paging}] = useSearch(searchPairings, searchParams, {limit:3});

  return <PairingList pairings={pairings} paging={{...paging, page, setPage}} loading={loading} error={error}/>
}