import { fetchCall } from 'api';
import { isString } from 'utils/var_check';

/*
  Search teams
*/  
export const searchSportTeams = ({search, preset, team, school, fields, sport, period, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/sport-teams",
    query: {search, 
            school: school&&school.slug,
            sport: sport&&sport.slug,
            team: team &&team.slug,
            activePeriod: period && (isString(period)? period: period.slug), 
            preset: preset && (isString(preset)? preset: preset.id), 
            fields,
            page, 
            limit, 
            orderBy, 
            orderDirection}
  }
  return fetchCall(options)
};

/*
  Retrieve team
*/  
export const retrieveTeam = ({slug}) => {
  const options = {
    method: "GET", 
    endpoint: `/sport-teams/${slug}`
  }
  return fetchCall(options)
};

/*
  Update team
*/  
export function updateTeam({slug, 
          name, 
        school, 
        practicePeriods, 
        sport, 
        coach, 
        activePeriod,
        assistantCoach, 
        rseqUrl}){
  const options = {
    method: "POST", 
    endpoint: `/sport-teams/${slug}`,
    body: {name, 
          school, 
            activePeriod: activePeriod && (isString(activePeriod)? activePeriod: activePeriod.slug), 
        sport, 
        coach, 
        assistantCoach, 
        rseqUrl, 
        practicePeriods}

  }
  return fetchCall(options)
}

/*
  Create team
*/  
export const createTeam = ({name, school, sport, activePeriod, coach, assistantCoach, practicePeriods, rseqUrl}) => {
  const options = {
    method: "POST", 
    endpoint: `/sport-teams`,
    body: {name, school, sport, coach, activePeriod, assistantCoach, practicePeriods, rseqUrl}

  }
  return fetchCall(options)
};

/*
  Delete team
*/  
export const deleteTeam = ({team}) => {
  const options = {
    method: "DELETE", 
    endpoint: `/sport-teams/${team}`
  }
  return fetchCall(options)
};
/*
  Add team member
*/  
export const addTeamMember = ({team, student, description}) => {
  const options = {
    method: "POST", 
    endpoint: `/sport-teams/${team}/add-member`,
    body: {student, description}
  }
  return fetchCall(options)
};


/*
  List team members
*/  
export const searchTeamMembers = ({team, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: `/team-members`,
    query: {team, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};

/*
  Delete team member
*/  
export const deleteTeamMember = ({id}) => {
  const options = {
    method: "DELETE", 
    endpoint: `/team-members/${id}`
  }
  return fetchCall(options)
};
