/*eslint no-unused-vars: "off"*/

import React from 'react';

// Components
import { WeekView } from 'components/tools/week_view';

// Hooks
import { useAbstractPeriods } from 'hooks/useAbstractPeriods';
import { useState, useMemo} from 'react';
import { useTranslation } from 'react-i18next';

// Utils
import { cronToString } from 'utils/crons';

export function Availabilities({student, tutor, studentAvailabilities, tutorAvailabilities, period}){
  const {t} = useTranslation("common");
  const [date, studentEvents] = useAbstractPeriods(studentAvailabilities, {className: "opacity-30 bg-orange-600"});
  const [date1, tutorEvents] = useAbstractPeriods(tutorAvailabilities, {className: "opacity-30 bg-blue-600"});

  const periodsCrons = useMemo(()=>{
    if (!period) return [];
    return [{from_cron: period}]
  }, [period])

  const [date2, periodEvent] = useAbstractPeriods(periodsCrons, {className: "opacity-100 bg-cyan-600"});

  const events = useMemo(()=>{
    if (studentEvents && tutorEvents && periodEvent){
      return [...studentEvents, ...tutorEvents, ...periodEvent];
    }
    return [];
  }, [studentEvents, tutorEvents, periodEvent])

  return <div className="w-full mx-auto">
            <label className="ml-2 text-sm uppercase text-gray-500">{t("availabilities")}</label>
            <div className="lg:flex lg:space-x-3">
              <div className="mx-auto flex-1"  style={{maxHeight: 400}}>
                <WeekView abstract date={date} events={events} numTicks={6} minHour={6}/>
              </div>
              <div className="space-y-4">
                <div>
                   <label className="ml-2 text-sm uppercase text-gray-500">{t("period")}</label>
                    <div className="bg-gray-100 p-3 rounded-lg">
                      <p>{cronToString(period)}</p>
                    </div>
                </div>
                <div>
                   <label className="ml-2 text-sm uppercase text-gray-500">{t("student-availabilities")}</label>
                    <div className="bg-gray-100 p-3 rounded-lg">
                      {studentAvailabilities.map((d,i)=><p key={i}>{d.from} au {d.to}</p>)}
                    </div>
                </div>
                <div>
                   <label className="ml-2 text-sm uppercase text-gray-500">{t("tutor-availabilities")}</label>
                    <div className="bg-gray-100 p-3 rounded-lg">
                      {tutorAvailabilities.map((d,i)=><p key={i}>{d.from} au {d.to}</p>)}
                    </div>
                </div>
{/*                <div>
                   <label className="ml-2 text-sm uppercase text-gray-500">{t("common-availabilities")}</label>
                    <div className="bg-gray-100 p-3 rounded-lg">
                      {commonAvailabilities&& commonAvailabilities.map((d,i)=><p key={i}>{d.from} au {d.to}</p>)}
                    </div>
                </div>*/}

              </div>
            </div>
            <div className="space-y-2 md:space-y-0 md:flex items-center justify-between p-3 ">
              <div className="flex items-center">
                <div className="bg-orange-600 opacity-30 h-6 w-6 rounded-lg mr-2"/> {student.name} ({t("student")})
              </div>
              <div className="flex items-center">
                <div className="bg-blue-600 opacity-30 h-6 w-6 rounded-lg mr-2"/>{tutor.name}  ({t("tutor")})
              </div>
              <div className="flex items-center">
                <div className="bg-cyan-600 h-6 w-6 rounded-lg mr-2"/>{t("period")}
              </div>
            </div>
          </div>
}