/*eslint-disable no-unused-vars*/
import React, { useMemo, useContext, useState, useEffect } from "react";

// Components
import { TutorsContext } from "contexts/tutors"
import { FiUsers } from 'react-icons/fi';
import { SearchUser } from "components/tools/search_user";

// API
import { assignation } from "api/tutors";

// Hooks
import { useTranslation } from "react-i18next";
import { Modal, ModalBasic, ModalBody } from "components/core/modal";
import { Label, SubLabel } from "components/tools/modifiable_fields";
import { useAPI } from "hooks/useAPI";

export function Assignation({ values, setValues, open, setOpen, onAssign }) {
    const { tutors, setTutors } = useContext(TutorsContext)
    
    const { t } = useTranslation("common");
    const [assignedUser, setAssignedUser] = useState(null);
    const formattedValues = values.map(v => v.name).join(", ");
    const [,{execute:assign}] = useAPI(assignation, {}, {immediate: false});
    
    useEffect(() => {
        setAssignedUser(values?.length > 0 && values.every(v => v.assigned_user?.email === values[0].assigned_user?.email) ? values[0].assigned_user : null);
    }, [values]);
    
    const assignationChanged = useMemo(() => {
        if (assignedUser){
            return values.some(v => v.assigned_user?.email !== assignedUser?.email || !v.assigned_user);
        }
        return values.some(v => v.assigned_user);
    }, [values, assignedUser]);

    const handleAssign = () => {
        assign({user:assignedUser, tutors:values});
        if (assignedUser) {
            setTutors?.(tutors.map(t => values.map(v => v.ni).includes(t.ni) ? { ...t, assigned_user: assignedUser } : t));
        } else {
            setTutors?.(tutors.map(t => values.map(v => v.ni).includes(t.ni) ? { ...t, assigned_user: null } : t));
        }
        onAssign?.({user:assignedUser, tutors:values});
        setValues([])
        setAssignedUser(null);
        setOpen(false);
    }
    return <>
        <Modal open={open} setOpen={setOpen}>
            <ModalBasic
                Icon={FiUsers}
                title={t("assignation.title-with-count", { count: values.length })}
                disabled={!values || values.length === 0 || !assignationChanged}
                submitLabel={!assignedUser ? t("assignation.button-remove") : t("assignation.button-add-to", { name: assignedUser?.firstname })}
                onClick={handleAssign}
            >
                <ModalBody>
                    <div className="w-full text-left px-2 mb-4">
                        <Label>
                            {t("tutors")}
                        </Label>
                        <span className="pb-0 line-clamp-1 text-gray-500">{formattedValues}</span>
                    </div>
                    <div className="px-2 text-left">
                        <Label>
                            {t("assignation.select-coordo-description")}
                        </Label>
                        <SearchUser
                            disabled={!values || values?.length === 0}
                            value={assignedUser}
                            setValue={setAssignedUser}
                            defaultFields={["id", "email", "name", "firstname", "lastname"]}
                        />
                        <SubLabel>
                            {t("assignation.leave-select-coordo-empty")}
                        </SubLabel>
                    </div>
                </ModalBody>
            </ModalBasic>
        </Modal>
    </>
}