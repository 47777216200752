import React from 'react';


// Hooks
import { useContext } from 'react';

// Context
import { SearchFollowUpContext } from 'contexts/search_follow_up';
import { Tabs } from 'components/core/tabs';

export function FollowUpTabs() {
  const { filters } = useContext(SearchFollowUpContext);
  const values = [
    {
      name: "BDI",
      href: `${filters.preset.id}/bdi`,
      end: false
    },
    {
      name: "Aides aux devoirs",
      href: `${filters.preset.id}/aad`,
      end: false
    },
    {
      name: "Temps de jeu",
      href: `${filters.preset.id}/play-time`,
      end: false
    },
    {
      name: "Rencontres",
      href: `${filters.preset.id}/meetings`,
      end: false
    },
    {
      name: "Documents",
      href: `${filters.preset.id}/grouped-documents`,
      end: false
    },
    {
      name: "Stats",
      href: `${filters.preset.id}/stats`,
      end: false
    }
  ]
  return ( <Tabs tabs={values}/>)
}