import React from 'react';

// Components
import { GameTimeNavigator } from 'components/events/game_time';

// Hooks
import { useContext } from 'react';

// Contexts
import { SearchFollowUpContext } from 'contexts/search_follow_up';

export default function PlayTime(){
  const {filters} = useContext(SearchFollowUpContext);
  return <div className="h-full">
          <GameTimeNavigator preset={filters.preset} team={filters.team}/>
         </div>
}
