import { useMemo } from 'react';

export function useTextTruncate(text, {length=30, ending="..."}={}){
  const truncated = useMemo(()=>{
      if (!text) return

      if (text.length > length) {
          return text.substring(0, length - ending.length) + ending
      }
      return text;
  }, [text])

  return [truncated, text!==truncated]
}