import React from 'react';

// Components
import { FiFolder } from 'react-icons/fi';
import { NoteCodesList } from 'components/note-codes/list';

// Hooks
import { useTranslation } from 'react-i18next';

function SettingsNoteCodes(){
  const {t} = useTranslation("common");
  
  return <div className="h-full min-h-screen md:min-h-0 "> 
            <h1 className="info-section">{t('note-codes')}</h1>
            <NoteCodesList/>
          </div>
}
const route =  { path: "note-codes", 
                 name: "Codes de notes" , 
                 group: "data",
                 Icon: FiFolder,
                 requireAuth: true, 
                 scopes: ["Direction", "RST", "Responsable", "Développeur"],
                 Element: SettingsNoteCodes };
export default route;
