import React, { useReducer, createContext } from "react";

function filterReducer(state, action){
  switch (action.type){
    case 'student':
      return {...state, student: action.value};
    case 'tutor':
      return {...state, tutor: action.value};
    case 'pairing':
      return {...state, pairing: action.value};
    case 'dates':
      return {...state, ...action.value};
    case 'fromDate':
      return {...state, fromDate: action.value};
    case 'toDate':
      return {...state, toDate: action.value};
    case 'includeNotes':
      // Use this to limit to notes with notes
      return {...state, includeNotes: action.value};
    case 'studyCode':
      return {...state, studyCode: action.value};
    case 'ordering':
      if (state.orderBy===action.value){
        if (state.orderDirection==='desc'){
          return {...state, orderBy: action.value, orderDirection: 'asc'};
        }
        return {...state, orderBy: null, orderDirection: null};
      }
      return {...state, orderBy: action.value, orderDirection: 'desc'};
    default:
      return state
  }
}

const SearchStudySessionsContext = createContext({});

const SearchStudySessionsContextProvider = ({ defaultState, children }) => {
  const [filters, dispatchFilters] = useReducer(filterReducer, defaultState || {});

  return (
    <SearchStudySessionsContext.Provider value={{ filters, dispatchFilters }}>
      {children}
    </SearchStudySessionsContext.Provider>
  );
};

export { SearchStudySessionsContext, SearchStudySessionsContextProvider };