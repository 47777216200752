import React from 'react';
import classnames from 'classnames';

// Hooks
import { useState, useMemo } from 'react'

// Components
import { SearchField } from 'components/tools/search_field';
import { useTranslation } from 'react-i18next';
import { BsCheck as IconCheck } from 'react-icons/bs'

// API
import { searchCommentTypes } from 'api/notes';
import { useSearch } from 'hooks/useSearch';
import { FiX } from 'react-icons/fi';

export const colors = {
  "yellow": "bg-yellow-100 text-yellow-800 ring-0 stroke-0 ring-yellow-500/10 stroke-yellow-700/50 fill-yellow-500",
  "orange": "bg-orange-100 text-orange-800 ring-0 stroke-0 ring-orange-500/10 stroke-orange-700/50 fill-orange-500",
  "red": "bg-red-100 text-red-800 ring-0 stroke-0 ring-red-500/10 stroke-red-700/50 fill-red-500",
  "pink": "bg-pink-100 text-pink-800 ring-0 stroke-0 ring-pink-500/10 stroke-pink-700/50 fill-pink-500",
  "purple": "bg-purple-100 text-purple-800 ring-0 stroke-0 ring-purple-500/10 stroke-purple-700/50 fill-purple-500",
  "blue": "bg-blue-100 text-blue-800 ring-0 stroke-0 ring-blue-500/10 stroke-blue-700/50 fill-blue-500",
  "green": "bg-green-100 text-green-800 ring-0 stroke-0 ring-green-500/10 stroke-green-700/50 fill-green-500",
  "gray": "bg-gray-100 text-gray-800 ring-0 stroke-0 ring-gray-500/10 stroke-gray-700/50 fill-gray-500",
  "default": "bg-purple-100 text-purple-800 ring-0 stroke-0 ring-purple-500/10 stroke-purple-700/50 fill-purple-500"
}

export const searchTagsColors = {
  "yellow": "bg-yellow-500",
  "orange": "bg-orange-500",
  "red": "bg-red-500",
  "pink": "bg-pink-500",
  "purple": "bg-purple-500",
  "blue": "bg-blue-500",
  "green": "bg-green-500",
  "gray": "bg-gray-500",
  "default": "bg-purple-500"
}

export function CommentTypeBadge({ text, onRemove = null, removable = false, color = "default", className = "" }) {
  if (!colors[color]) color = "default"
  return <div className={`flex items-end rounded-md  pl-1.5 py-0.5 text-sm font-medium ring-1 ring-inset ${className} ${colors[color]}`}>
    {text}
    {removable && <FiX onClick={onRemove} className='h-full aspect-square mx-1 hover:text-gray-900  text-gray-600 cursor-pointer'/>
    }
  </div>
}


export function SearchCommentTypes({ value, setValue, extraParams, label, disabled, color, vOrientation  }) {
  const { t } = useTranslation('common');
  const [params, setParams] = useState();
  const queryParams = useMemo(() => ({ ...params, ...extraParams }), [extraParams, params])
  const [types, {loading}] = useSearch(searchCommentTypes, queryParams)
  return <SearchField values={types}
    loading={loading}
    by={(a,b) => a.code == b.code}
    value={value}
    setValue={setValue}
    label={label}
    multiple={true}
    disabled={disabled}
    color={color}
    size={"smWider"}
    placeholder={t("comment-types.search-placeholder")}
    indexingField={"code"}
    vOrientation={vOrientation}
    formatSelectedValue={(d => d.name || "")}
    formatSearchResult={((type, { selected, active }) =>
      <div className='flex justify-between'>
        <div className='flex items-center w-[calc(100%-30px)]'>
          <div className={`w-2 h-2 absolute rounded-full ${searchTagsColors[type.color] ? searchTagsColors[type.color]: searchTagsColors["default"]}`}></div>
          <div className={classnames('truncate ml-4', (active || selected) && '')}>{type.name}
        </div>

      </div>
        { selected && <IconCheck className="inline-block w-4 h-4 mr-1" />}
      </div>)}
    onParamsChange={setParams}
  />
}


