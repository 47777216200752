import React from 'react';

// Components
import { FlexNav } from 'components/core/layouts/flex';
import { InfoSectionHeading  } from 'components/core/typo';
import { TextInput } from 'components/core/inputs'
import { Button } from 'components/core/button';
import { Error } from 'components/core/typo'
import { Info } from 'components/core/info';
import { ModifiableSelectField} from 'components/tools/modifiable_fields';
import { SelectDate } from 'components/tools/select_date';
import { SelectMultipleTeamsAsCoach } from 'components/tools/search_teams';

// Hooks
import { useAPI } from 'hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useState, useMemo } from 'react';

// Utils
import classnames from 'classnames';
import {locale} from 'utils/locale';

// API
import { createCoach } from 'api/coachs';

function CreationView({draftCoach, setDraftCoach}){
  const { t } = useTranslation("common");
  return <div className="w-full space-y-8 lg:pr-12 mx-auto"> 
          <InfoSectionHeading title={t("create-coach")}/>
            <TextInput 
              label={t("name")} 
              placeholder={t("name")}
              required
              onChange={(name)=>setDraftCoach(d=>({...d, name}))}/>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3">
              <TextInput 
                label={t("email")} 
                placeholder={t("email")}
                required
                onChange={(email)=>setDraftCoach(d=>({...d, email}))}/>
              <TextInput 
              label={t("phone")} 
              placeholder={t("phone")}
              required
              onChange={(phone)=>setDraftCoach(d=>({...d, phone}))}/>

            </div>
             
          <Info.Container modify={true}>
            <ModifiableSelectField 
                      Field={SelectMultipleTeamsAsCoach} 
                      label={t("teams")} 
                      modify={true}
                      value={draftCoach.teams}
                      setValue={(teams)=>setDraftCoach(d=>({...d, teams}))}/>

          </Info.Container>
          <Info.Container modify={true}>
            <ModifiableSelectField 
                      Field={SelectDate} 
                      label={t("started-using-service")} 
                      modify={true}
                      value={draftCoach.serviceStartedAt}
                      setValue={(serviceStartedAt)=>setDraftCoach(d=>({...d, serviceStartedAt}))}/>
            
          </Info.Container>
          </div>
}



function SideNavigation({draftCoach, onResult}){
  const { t } = useTranslation("common");

  const steps = useMemo(()=>{
    return [{
      name: t("name"), 
      description: draftCoach.name,
      completed: draftCoach.name
    },
    {
      name: t("email"), 
      description: draftCoach.email, 
      completed: draftCoach.email ,
    },
    {
      name: t("phone"), 
      description: draftCoach.phone, 
      completed: draftCoach.phone,
    },
    {
      name: t("teams"), 
      description: draftCoach.teams && draftCoach.teams.map(d=>d.team.name).join(', '),
      completed: draftCoach.teams ,
    },
    {
      name: t("started-using-service"), 
      description: draftCoach.serviceStartedAt && locale.format("%d %B %Y")(draftCoach.serviceStartedAt),
      completed: draftCoach.serviceStartedAt ,
    }];
  }, [draftCoach]);

  const [, request] = useAPI(createCoach, draftCoach, {immediate: false, onResult});

  const disabled = useMemo(()=>{
    if (!draftCoach.name) return true;
    if (!draftCoach.email) return true;
    if (!draftCoach.phone) return true;
    return false;
  }, [draftCoach])

  return (<div className="bg-gray-100 lg:bg-white rounded-lg p-3 lg:pl-6 lg:pt-8 ">
            <InfoSectionHeading subtitle={t("summary")}/>
            {steps.filter(d=>!d.hide).map((d=>
                <div key={d.name}>
                  <div className={classnames("flex items-start py-2", d.warning&&"bg-yellow-50 rounded-lg")}>
                    {d.warning?
                      <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <span className="absolute h-4 w-4 rounded-full bg-yellow-200" />
                        <span className="relative block w-2 h-2 bg-yellow-600 rounded-full" />
                      </span>:
                      !d.completed?
                      <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                      </div>:
                      <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <span className="absolute h-4 w-4 rounded-full bg-green-200" />
                        <span className="relative block w-2 h-2 bg-green-600 rounded-full" />
                      </span>
                    }
                    <div>
                      <p className={classnames("ml-3 font-medium", d.warning?"text-yellow-800":d.completed? "text-green-600": "text-gray-400")}>{d.name}</p>
                      {
                        d.warning? <p className="ml-3 text-yellow-600 ">{d.warning}</p>:
                        d.description && <p className="ml-3 text-gray-800 ">{d.description}</p>
                      }
                    </div>
                  </div>
                </div>
            ))}

            <Button className="mt-6" 
                      color="active" 
                      onClick={request.execute} 
                      loading={request.loading} 
                      disabled={disabled} 
                      block 
                      size="lg">{t('create-coach')}</Button>
            {request.error && <Error.Text {...request.error}/>}
          </div>)
}

function AddedCoachs({coachs}){
  if (coachs.length===0) return null;
  return <div className="divide-y ">
          {coachs.map(coach=>
            <Button key={coach.ni} color="inlineLink" layout="blockLeft" className="px-3 py-1" href={`/coachs/${coach.ni}`}>{coach.name}</Button>)}
        </div>
}


function DoneCoach({coach, setCoach}){
 
  const { t } = useTranslation("common");
  return <div className="mx-auto w-full max-w-lg space-y-8 mt-12">
            <h1 className="font-semibold text-2xl">{t("coach-added-title", {name: coach.name})}</h1>
            <Button block color="active" size="lg" onClick={()=>setCoach()}>
              {t("add-more-coach")}
            </Button>
          </div>
}


export function NewCoach(){
  const [coachs, setCoachs] = useState([]);
  const [coach, setCoach] = useState();
  const [draftCoach, setDraftCoach] = useState({});

  return <FlexNav breakdown="lg" className={"max-w-6xl mx-auto relative h-full"}>
            <FlexNav.Content breakdown="lg" className="py-6">
              {coach?
                <DoneCoach coach={coach} setCoach={setCoach}/>:
                <CreationView
                draftCoach={draftCoach} 
                setDraftCoach={setDraftCoach} 
              />}
             </FlexNav.Content>
             <FlexNav.Bar breakdown="lg" className="order-last lg:border-l lg:pl-6 ">
              <div className="absolute sticky top-0 space-y-12 max-h-full pb-12">
                <SideNavigation draftCoach={draftCoach} onResult={next=>{setDraftCoach({}); setCoach(next); setCoachs(d=>[next, ...d])}}/>
              </div>
                <AddedCoachs coachs={coachs}/>
            </FlexNav.Bar>
            </FlexNav>
}
