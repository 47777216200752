import React from 'react';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import { SignupForm } from 'components/signup';

export function SignupPage(){
  return <CenterViewLayout>
          <SignupForm/>
         </CenterViewLayout>
}

const route =  { path: "/signup", 
                 name: "Signup" , 
                 showInNavBar: false, 
                 requireAuth: false, 
                 Element: SignupPage };
export default route;
