import React from "react";
import classnames from 'classnames';

export function ClosedIndicator({ isClosed }) {
    return <span className={`${classnames(isClosed ? " text-red-700 bg-red-50 ring-red-600/20" : "invisible")} scale-90 inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset `}>
    Fermé
    </span>


}
