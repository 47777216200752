/*
  This list is intended for managing the pairings, not for selection.
*/

import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useContext } from 'react';
import { useSearch } from 'hooks/useSearch';

// Utils
import { locale, dateParse } from 'utils/locale';

// Contexts
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error, InfoSectionHeading } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';

// API
import { searchPairings } from 'api/pairings';


export function ControlledPairingsList({pairings, setPage, paging, error}){
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { t } = useTranslation('common');
  const headers = useMemo(()=>[
    {
     title: t("student"),
     field: (d=>d? (d.student.name) : "student"),
     itemClassName: "!py-2"
    },{
     title: t("tutor"),
     field: (d=>d? (d.tutor.name) : "tutor"),
     itemClassName: ""
    },
    {
     title: t("active-since"),
     field: (d=>d? (d.started_at && locale.format("%d %B %Y")(new Date(dateParse(d.started_at)))) : "started_at"),
     itemClassName: "text-gray-500"
    }
  ], [pairings])

  if (!pairings){
    return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2"/>
  }

  return <Info.Container modify={true} >
          <InfoSectionHeading mediumTitle={t("active-pairings.title")} longDescription={t("active-pairings.gonna-be-deactivated")}/>
            {pairings.length===0?
              <EmptyState
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-pairings-title")}/>
              :
              <Table headers={headers}
                    data={pairings}
                    indexingKey="id"
                    order={{by:filters.orderBy, direction:filters.orderDirection}}
                    onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
              }
              {paging&& <Paginate setPage={setPage} {...paging}/>}
              {error? <Error.Text {...error}/>: null}
          </Info.Container>
}

function PairingsListWithContext(){
  const { filters } = useContext(SearchContext);
  const [pairings, {loading, error, paging, setPage}] = useSearch(searchPairings, filters, {fields:["started_at", "id"]});
  return <ControlledPairingsList pairings={pairings}
                              setPage={setPage}
                              loading={loading}
                              error={error}
                              paging={paging} />
}

export function PairingsList(){
  return <SearchContextProvider doNotStore defaultState={{orderBy:"name", orderDirection: "asc", serviceIsEnded: false}}>
            <PairingsListWithContext/>
        </SearchContextProvider>
}