import React from 'react';

// Contexts
import { SearchOpenNotesContext, SearchOpenNotesContextProvider } from 'contexts/search_open_notes';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useContext, useState, useEffect } from 'react';
import { useQuery } from 'hooks/useQuery';
import { useTranslation } from 'react-i18next';

// API
import { searchNotes } from 'api/notes';

// Components
import { NotesList } from 'components/notes/list';
import { Error } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';
import { SlideOver } from 'components/core/slide_over';
import { NoteExtendedViewQueryBased } from 'components/notes/card';
import { EmptyState } from 'components/core/empty';
import { FilterBar } from 'components/notes/filter_bar'; 
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';

export function Empty(){
  const { t } = useTranslation('common');
  return <EmptyState 
            className="h-full flex flex-col justify-center"
            title={t("empty-state.no-open-notes-title")}
            description={t("empty-state.no-open-notes-description")} >
         </EmptyState>
}

export function OpenNotesCardWithinContext({title}){
  const {filters} = useContext(SearchOpenNotesContext);
  const [notes, { error, paging }] = useSearch(searchNotes, filters);
  const { query, setQuery} = useQuery();
  const [open, setOpen] = useState(query.has("noteId"));

  useEffect(()=>{
    if (!open) setQuery();
  }, [open])

  return <CardContainer>
          <div className="flex items-center justify-between">
              <div className="whitespace-nowrap">
                <CardSectionHeading 
                    title={title} />
              </div>

              <FilterBar 
                Context={SearchOpenNotesContext} 
                openNotes={false}
                toDiscuss={false}
                toDiscussCount={false}
                addNote={false}
                preset={!filters.student && !filters.tutor && !filters.pairing}
                school={!filters.student && !filters.tutor && !filters.pairing}
                student={filters.student}
                tutor={filters.tutor}
                 />
          </div>
          <FilterBar 
            noteGroup 
            noteCode 
            Context={SearchOpenNotesContext} 
            openNotes={false}
            toDiscuss={false}
            toDiscussCount={false}/>
            {!notes?
              <Skeleton className="h-80 w-full"/>
              :notes.length===0?
              <div className="bg-gray-100 rounded-lg p-3 mt-3 h-full">
                <Empty/>
              </div>:
              <NotesList notes={notes} paging={paging} select={(d)=>{setOpen(true); setQuery({noteId: d.id});}}/>
            }
            {error? <Error.Text className="mt-3" {...error}/>: null}

            <SlideOver open={open} setOpen={setOpen} size="xl3">
              <NoteExtendedViewQueryBased/>
            </SlideOver>
          </CardContainer>
}

export function OpenNotesCard({filters, title}){
  return <SearchOpenNotesContextProvider defaultState={{...filters}}>
        <OpenNotesCardWithinContext title={title}/>
        </SearchOpenNotesContextProvider>
}


