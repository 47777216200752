import React from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiToggleRight } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';

export function SelectLeftService({ value, setValue, disabled, ...props }){

  const { t } = useTranslation("common");
  const values = [
    {
      name: "Actifs",
      value: false
    },
    {
      name: "Inactifs",
      value: true
    }
  ]
  return <ListSelection value={value}
                        setValue={setValue}
                        disabled={disabled}
                        values={values}
                        label={t('left-service')}
                        Icon={FiToggleRight}
                        getKey={(d)=>d.name}
                        format={(d)=>d.name}
                        withClearSelection={true}
                        {...props}
                        />
}