/*
  This list is intended for managing the document groups, not for selection.
*/

import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useContext, useState } from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Button } from 'components/core/button';
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';
import { Fragment } from 'react';
import { SlideOver } from 'components/core/slide_over';
import { CreateDocumentGroup } from 'components/document-groups/create';
import { ModifyDocumentGroup } from 'components/document-groups/modify';

// API
import { searchDocumentGroups } from 'api/document_groups';

function CreateButton({onChange}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("add-document-group.title")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <CreateDocumentGroup onCompleted={()=>{setOpen(false); onChange()}}/>
          </SlideOver>
        </Fragment>
}

function ModifyButton({value, setValue, onDelete}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("modify")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <ModifyDocumentGroup value={value} setValue={setValue} onDelete={()=>{setOpen(false); onDelete(value)}}/>
          </SlideOver>
        </Fragment>
}

export function ControlledDocumentGroupsList({noteCodes, setDocumentGroups, setPage, paging, error, execute}){
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { t } = useTranslation('common');
  const headers = useMemo(()=>[
    {
     title: t("color"),
     field: (d=>d? d.color : "color"),
     itemClassName: "!py-2 whitespace-nowrap ",
     format: (d=><div style={{background:d}} className="w-5 h-5 ml-3 text-left rounded-md"/>)
    },
    {
     title: t("name"),
     field: (d=>d? d.name : "name"),
     itemClassName: "py-2 whitespace-nowrap"
    },
    {
     field: (d=>d? ({value:d, onDelete:((d=>setDocumentGroups(current=>current.filter(e=>e.code!==d.code)))), setValue: ((next)=>setDocumentGroups(current=>current.map(e=>e.code===next.code? next:e)))}) : "Modifier"),
     FormatComponent: ModifyButton
    },
  ], [noteCodes])

  if (!noteCodes){
    return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2"/>
  }

  return <Info.Container modify={true} label={t("document-groups")} sideHeader={<Fragment><CreateButton onChange={execute}/></Fragment>}>
            {noteCodes.length===0?
              <EmptyState
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-documents-codes-title")}/>
              :
              <Table headers={headers}
                    data={noteCodes}
                    indexingKey="code"
                    order={{by:filters.orderBy, direction:filters.orderDirection}}
                    onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
              }
              {paging&& <Paginate setPage={setPage} {...paging}/>}
              {error? <Error.Text {...error}/>: null}
          </Info.Container>
}

function DocumentGroupsListWithContext(){
  const { filters } = useContext(SearchContext);
  const [noteCodes, {loading, error, paging, setPage, setResult, execute}] = useSearch(searchDocumentGroups, filters);
  return <ControlledDocumentGroupsList noteCodes={noteCodes} setDocumentGroups={setResult} setPage={setPage} loading={loading} error={error} paging={paging} execute={execute}/>
}

export function DocumentGroupsList(){
  return <SearchContextProvider doNotStore defaultState={{orderBy:"name", orderDirection: "asc"}}>
            <DocumentGroupsListWithContext/>
        </SearchContextProvider>
}