import React, {createContext } from "react";

// Hooks
import { useLocation, useParams } from 'react-router-dom';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useMemo, useEffect } from 'react';
import { useTutor } from 'hooks/tutors/useTutor';

const TutorContext = createContext({});

const TutorContextProvider = ({ tutorNi, children }) => {
  const location = useLocation();
  const immediate = useMemo(()=>false, []);
  const params = useParams();
  const ni = tutorNi || params.ni;
  const [tutor, {loading, error, setResult, execute}] = useTutor({tutorNi, immediate});

  const paramsChanged = useMemoCompare(location, (prev, next)=>prev && prev.pathname==next.pathname);
  useEffect(()=>{
    execute()
  }, [paramsChanged])

  return (
    <TutorContext.Provider value={{ tutor: (tutor || {}), ni, setTutor: setResult, loading, error }}>
      {children}
    </TutorContext.Provider>
  );
};

export { TutorContext, TutorContextProvider };