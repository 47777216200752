import React from 'react';
import classnames from 'classnames';

// Hooks
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';


// Context
import { SearchContext } from 'contexts/search';
import { AutoPairingsContext } from 'contexts/auto-pairings';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Button } from 'components/core/button';
import { ListSearch } from 'components/core/inputs';
import { PaginateCard } from 'components/core/paginate';
import { Error } from 'components/core/typo'
import { locale, parseWithTimeZone } from 'utils/locale';
import { StarIcon } from '@heroicons/react/24/solid';

function DraftCell({ id, num_students, num_tutors, num_matches, description, official, created_at }) {
  const { t } = useTranslation('autoPairing');
  return <Button className="p-3" layout="cell" nav color={(d) => d ? "activeCell" : "cell"} href={`/automatic-pairing/${id}`}>
    {({ isActive }) =>
      <div className='flex justify-between'>
        <div className="block w-full break-all whitespace-pre-wrap">

        <div className="w-full">
            <div className="flex items-center justify-between w-full">
              <p className={classnames("truncate w-full font-medium ", isActive? "text-white": "text-blue-800 ")}>
               {description}
            </p>
            {official && 
            <StarIcon className="w-6 h-6 text-yellow-400 inline"/>} 
            </div>
            <div className="flex items-center justify-between w-full">
              <p className={classnames("text-sm font-medium  flex-1 ", isActive? "text-white": "text-gray-700")}>
                  {t("num-matches", {count:num_matches})}
              </p>
            </div>
            <div className="flex items-center justify-between w-full">
              <p className={classnames("text-sm flex-1 ", isActive? "text-white": "text-gray-500")}>
                  {t("num-students", {count:num_students})} /  {t("num-tutors", {count:num_tutors})}
              </p>
              
            </div>
            <p className={classnames("text-sm", isActive? "text-white": "text-gray-500")}>
            {locale.format("%d %B %Y")(parseWithTimeZone(created_at))}
            </p>
        </div>

        </div>
      </div>
    }
  </Button>
}

export function DraftsList() {
  const { t } = useTranslation("autoPairing");
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { loading, paging, setPage, error, hasMore, drafts } = useContext(AutoPairingsContext)
  if (!drafts) {
    return <Skeleton.List itemClassName="h-8 rounded" className="p-3 space-y-3" numElements={4} />
  }
  return <div>
        <div className='p-2'>
            <Button block color="gray" size="md" href={`/automatic-pairing/create`}>
                {t("new-pairing")}
            </Button>
        </div>
    <div className="border-b">
      <ListSearch color="none"
        loading={loading}
        search={true}
        placeholder={t("search-by-name")}
        onChange={(value) => dispatchFilters({ type: 'search', value })} />
    </div>
    {error ? <Error.Text className="mt-3" {...error} /> : null}

    {drafts.length === 0 ?
      <EmptyState
        title={!filters.search && !filters.period?
          t("empty-state.no-drafts-title", {context: 'nofilter'}):
          filters.search && !filters.period?
          t("empty-state.no-drafts-title", {context: 'search', search: filters.search}):
          !filters.search  && filters.period?
          t("empty-state.no-drafts-title", {context: 'period', period: filters.period.slug}):
          t("empty-state.no-drafts-title", {context: 'searchperiod', search: filters.search, period: filters.period.slug})
        }
        description={t("empty-state.no-drafts-description", { search: filters.search })} >
          </EmptyState> :
      <div className="divide-y">
        {drafts.map(d => <DraftCell key={d.id} {...d} />)}
      </div>}
    {paging && hasMore && <PaginateCard setPage={setPage} {...paging} />}
  </div>
}

