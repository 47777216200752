import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Button } from 'components/core/button';
import { Info } from 'components/core/info';

// Utils
import classnames from 'classnames';
import { locale } from 'utils/locale';
import { format } from 'd3-format';

// Hooks
import { useTranslation } from 'react-i18next';

export function StudentInfo({ student }){
  const { t } = useTranslation('common');
  if (!student.name){
    return <Skeleton.List numElements={5} className="space-y-3" itemClassName="h-36"/>
  }
  return <div className={classnames("space-y-6")}>
            <Info.Container label={"Info"} >
                <Info.Field value={student.ni} label={t("ni")}/>
                <Info.Field value={student.name} noValueLabel={`${t("none")} ${t("name").toLowerCase()}`}/>
                <Info.Field value={student.email} noValueLabel={`${t("none")} ${t("email").toLowerCase()}`}/>
                <Info.Field value={student.email2} noValueLabel={`${t("none")} ${t("email2").toLowerCase()}`}/>
                <Info.Field value={student.phone} noValueLabel={`${t("none")} ${t("phone").toLowerCase()}`}/>
                <Info.Field value={student.gender} noValueLabel={`${t("none")} ${t("gender").toLowerCase()}`}/>
                <Info.Field value={student.folder_number}
                                        noValueLabel={`${t("none")} ${t("folder-number").toLowerCase()}`}
                                        label={t("folder-number")}
                                        />

                <Info.Field value={student.group_class}
                                        noValueLabel={`${t("none")} ${t("group-class").toLowerCase()}`}
                                        label={t("group-class")}
                                        />
                <Info.Field value={student.notebook_number}
                                        noValueLabel={`${t("none")} ${t("notebook-number").toLowerCase()}`}
                                        label={t("notebook-number")}
                                        />
                <Info.Field value={student.social_network_name}
                                        noValueLabel={`${t("none")} ${t("social-network-name").toLowerCase()}`}
                                        label={t("social-network-name")}
                                        />
                <Info.Field value={student.prefered_communication && (t((student.prefered_communication && student.prefered_communication.slug)|| student.prefered_communication))}
                                        noValueLabel={`${t("none")} ${t("prefered-communication").toLowerCase()}`}
                                        label={t("prefered-communication")}
                                        />
            </Info.Container>

            <Info.Container  label={t("school")} >
              <Info.Field value={student.school  && student.school.name}
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("school").toLowerCase()}`}
                                        label={t("school")}
                                        />

              <Info.Field value={student.school_year  && student.school_year.name}
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("school-year").toLowerCase()}`}
                                        label={t("school-year")}
                                        />

            </Info.Container>

            <Info.Container label={t('teams')}>
                {student.teams && student.teams.length>0?
                          student.teams.map(val=>
                            <Info.Field key={val.id}
                                        value={`${val.team.name} ${val.team.active_period && (val.team.active_period || val.team.active_period.name)}`}
                                        to={`/teams/${val.team.slug}`}
                                        label={val.description}
                                        />):
                          <Info.Field noValueLabel={t('empty-state.no-teams-title')} />
                        }

            </Info.Container>

            <Info.Container label={t('siblings')}>
                        {student.siblings && student.siblings.length>0?
                          student.siblings.map(val=>
                            <Info.Field key={val.id}
                                        value={val.target.name}
                                        to={`/students/${val.target.ni}`}
                                        label={val.description}
                                        />):
                          <Info.Field noValueLabel={`${t("none", {context: 'female'})} ${t("siblings").toLowerCase()}`} />
                        }
            </Info.Container>


              <Info.Container label={t('failed-school-classes')}>
                      {student.failed_classes && student.failed_classes.length>0?
                        student.failed_classes.map(val=>
                          <Info.Field key={val.id}
                                      value={val.name}
                                      label={`${val.score?format(".1%")(val.score/100):t("no-result")} | ${val.description || "Aucune description"}`}
                                      />):
                        <Info.Field noValueLabel={t("empty-state.no-failed-classes-title")} />
                      }
              </Info.Container>

              <Info.Container label={t('prefered-school-subjects')}>
                      {student.prefered_subjects && student.prefered_subjects.length>0?
                        student.prefered_subjects.filter(d=>d.description).map(val=>
                          <Info.Field key={val.id}
                                      value={val.name}
                                      label={t("priority."+val.description)}
                                      />):
                        <Info.Field noValueLabel={`${t("none", {context: 'female'})} ${t("school-subject").toLowerCase()}`} />
                      }
              </Info.Container>

            <Info.Container>
              <Info.Field value={student.started_using_service && locale.format("%d %B %Y")(new Date(student.started_using_service))}
                                        noValueLabel={`${t("none")} ${t("started-using-service").toLowerCase()}`}
                                        label={t("started-using-service")}
                                        />

                <Info.Field value={student.ended_using_service && locale.format("%d %B %Y")(new Date(student.ended_using_service))}
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("ended-using-service").toLowerCase()}`}
                                        label={t("ended-using-service")}
                                        />
              </Info.Container>

              <Info.Container label={t("active-years")}>
                {student.active_periods && student.active_periods.map((d,i)=>
                  <Info.Field key={i} value={d.name} label={d.description}/>
                )}
            </Info.Container>

            <Info.Container>
              <Info.Field value={student.particular_needs}
                                        noValueLabel={`${t("none")} ${t("particular-needs").toLowerCase()}`}
                                        label={t("particular-needs")}
                                        />
              </Info.Container>

              <Button color="black" size="md" block href={`/students/${student.ni}/settings/`}>{t("modify")}</Button>
         </div>
}

