import React, { useMemo } from 'react';

// Context

// Hooks
import { useContext } from 'react';
import { Skeleton } from 'components/core/skeleton';
import { RawTextWarning } from 'components/core/typo';
import { useSearch } from 'hooks/useSearch';
import { searchMails } from 'api/mails';
import { TutorContext } from 'contexts/tutor';
import { EmptyState } from 'components/core/empty';
import { useTranslation } from 'react-i18next';
import { Table } from 'components/core/table';
import { locale, parseWithTimeZone } from 'utils/locale';
import { Paginate } from 'components/core/paginate';

export function MailsCard({all}){
    const {t} = useTranslation();
    const { ni  } = useContext(TutorContext);
    const params = useMemo(()=>({tutor: all? null: ni, orderBy: 'id', orderDirection: 'desc'}), []);
    const [mails, {loading, paging, setPage, error}] = useSearch(searchMails, params,);

    const headers = useMemo(()=>[
        {title: t("id"), field: "id"},
        {title: t("sent"), field: "sent_at", format: (d)=>locale.format("%d %B %Y à %H:%M")(parseWithTimeZone(d))},
        {title: t("type"), field: "mail_type", format: (d)=>d.name, itemClassName: "font-medium"},
        {title: t("types"), field: (d=>d? d:"mail_type_slug"), format: (d)=>d.mail_type. slug, itemClassName: "font-mono text-xs text-cyan-600",},
        {title: t("email"), field: "to_email", itemClassName: 'text-gray-500'},
    ], []);

    if (loading) return <Skeleton className={"h-32"}/>

    return <div>
            <h2 className='font-medium'>{t("emails")}</h2>
            <Table headers={headers} data={mails} indexingKey={"id"}/>
            {mails && mails.length === 0 && <EmptyState title={t("no-emails.title")} description={t("no-emails.description")}/>}
            {error && <RawTextWarning>{error}</RawTextWarning>}
            {paging&&<Paginate setPage={setPage} {...paging}/>}

        </div>
}