import React, { useContext, useMemo } from 'react';

import { useSearch } from 'hooks/useSearch';
import { useUser } from 'hooks/useUser';

import { searchPresets } from 'api/presets';

export const useUserPresets = () => {
    const context = useContext(UserPresetContext);
    return context;
}
export const UserPresetContext = React.createContext();
export function UserPresetContextProvider({ children }) {
    const [user] = useUser();
    const params = useMemo(() => ({ user: user && user.email }), [user])
    const [presets, { loading, error, paging, setPage, setResult, execute }] = useSearch(searchPresets, params);
    return <UserPresetContext.Provider value={{ presets, setPresets:setResult, reload:execute, loading, error, paging, setPage }}>
        {children}
    </UserPresetContext.Provider>
}