import slugify from 'slugify';

export function camelCaseToSlug(str){
    // Example: aNiceExample -> a_nice_example
     return slugify(str.replace(/[A-Z]/g, function(s){ return "_" + s; }), {replacement: "_", lower:true})
}

export function slugToCamel(str){
    // Example: a_nice_example -> aNiceExample
    return str.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase(); });
}

export function convertObjectSlugToCamel(object){
    const camelBody = {};
    Object.keys(object).forEach(key=>(camelBody[slugToCamel(key)]=object[key]));
    return camelBody;
}

export function convertIntegerToObject(object){
    /*For each value, check if the value is an interger, if so map to a {"description": value} */
    const integerBody = {};
    Object.keys(object).forEach(key=>(integerBody[key]=Number.isInteger(object[key])? {"description": object[key]}: object[key]));
    return integerBody;
}