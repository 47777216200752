import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { Container } from 'components/core/container';
import { CreateNote } from 'components/notes/create';

export function CreateNotePage(){
  return <DashboardLayout>
          <Container className="py-6">
            <CreateNote/>
          </Container>
         </DashboardLayout>
}

const route =  { path: "/new-note", 
                 name: "Nouvelle note au dossier" , 
                 requireAuth: true, 
                 Element: CreateNotePage };
export default route;
