import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { DoubleMonthView } from 'components/tools/double_month_view';
import { Button } from 'components/core/button';

import { EventCard } from 'components/events/card'
import { SlideOver } from 'components/core/slide_over';
// API
import { searchEvents } from 'api/events';

// Hooks
import { useMemo, useState } from 'react';
import { useSearch } from 'hooks/useSearch';
import { useMonth } from 'hooks/dates/useMonth';
import { useCronsToDates } from 'hooks/dates/useCronToDates';
import { useTranslationRegularCrons } from 'hooks/dates/crons';


// Utils
import classnames from 'classnames';
import { locale, dateParse, parseWithTimeZone } from 'utils/locale';

function LoadingEvents(){
  return <div className="space-y-5">
              <Skeleton className="h-16"/>
              <Skeleton.List numElements={3} className="space-y-4" itemClassName="h-8"/>
            </div>
}

export function EventCalendar({team, activePeriod, practicePeriods, numMonths=6}){
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [date, setDate] = useState(activePeriod? dateParse(activePeriod.start): null);

  const practicePeriodDescriptions = useTranslationRegularCrons(practicePeriods);
  const windowDates = useMonth(date, {months: numMonths});
  const cronParams = useMemo(()=> practicePeriodDescriptions.map(d=>({
    fromDate: (activePeriod? Math.max(dateParse(activePeriod.start), windowDates.fromDate): windowDates.fromDate), 
    toDate: (activePeriod? Math.min(dateParse(activePeriod.end), windowDates.toDate): windowDates.toDate), 
    tooltip: `Pratique ${d}`, color: "#3182bd"})), 
    [practicePeriodDescriptions, windowDates])
  const practiceEvents = useCronsToDates(practicePeriods, cronParams);
  const searchParams = useMemo(()=>({team, ...windowDates}), [windowDates, team]);
  const [events] = useSearch(searchEvents, searchParams);

  const calendarEvents = useMemo(()=>{
    if (!events) return [];
    return events.map(d=>({date: parseWithTimeZone(d.date), color: d.event_type.color, tooltip: d.event_type.name +` le ${locale.format("%d %B à %H:%M")(parseWithTimeZone(d.date))}` + (d.notes?`, ${d.notes}`: "")}))
  }, [events])

  if (!events){
    return <LoadingEvents/>
  }
  return <div>
          <div className="w-full max-w-2xl mx-auto">
            <DoubleMonthView date={date} onDateChange={setDate} events={[...calendarEvents, ...practiceEvents]} layout="side" numMonths={numMonths}/>
          </div>
           <div className="divide-y pt-3">
            {events.map(d=>
              <Button className="p-3" 
                      layout="cell" 
                      nav 
                      key={d.id} 
                      color={"subSideNav"} 
                      onClick={()=>{setSelectedEvent(d); setOpen(true)}}>
                <div className="flex items-center space-x-2">
                  <div className="h-4 w-4 rounded ring-4 ring-white" style={{background: d.event_type.color}}/>
                  <div>
                    <div className={classnames("text-gray-800")}>{locale.format("%d %B %Y à %H:%M")(parseWithTimeZone(d.date))}</div>
                    <div className={classnames("text-gray-500 text-left")}>{d.event_type.name}</div>
                  </div>
                </div>
              </Button>)
            }
          </div>
          <SlideOver size="xl" open={open} setOpen={setOpen}>
            <EventCard event={selectedEvent} setEvent={setSelectedEvent} defaultModify={false}/>
          </SlideOver>
         </div>
}
