import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useContext, useMemo, useState } from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Api
import { searchTournamentRules } from 'api/tournament-rules';

// Components
import { Fragment } from 'react';
import { Skeleton } from 'components/core/skeleton';
import { Error } from 'components/core/typo'
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';
import { Paginate } from 'components/core/paginate';
import { Button } from 'components/core/button';
import { SlideOver } from 'components/core/slide_over';
import { EmptyState } from 'components/core/empty';
import { CreateTournamentRule } from 'components/tournament-rules/create';
import { ModifyDeleteTournamentRules } from 'components/tournament-rules/modify';
import { ListSelection } from 'components/tools/list_selection';
import { SelectSport } from 'components/tools/sports_list';

function CreateButton({ onChange }) {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    return <Fragment>
        <Button onClick={() => setOpen(true)} size="xs" color="gray">{t("add-play-time-rule")}</Button>
        <SlideOver open={open} setOpen={setOpen} size="xl">
            <CreateTournamentRule onCompleted={() => { setOpen(false); onChange() }} />
        </SlideOver>
    </Fragment>
}

function ModifyButton({ value, setValue, onDelete }) {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    return <Fragment>
        <Button onClick={() => setOpen(true)} size="xs" color="gray">{t("modify")}</Button>
        <SlideOver open={open} setOpen={setOpen} size="xl">
            <ModifyDeleteTournamentRules value={value} setValue={(v) => { setOpen(false); setValue(v) }} onDelete={onDelete} />
        </SlideOver>
    </Fragment>
}

export function ControlledTournamentRulesList({ tournamentRules, setTournamentRules, setPage, paging, error, execute }) {
    const { filters, dispatchFilters } = useContext(SearchContext);
    const { t } = useTranslation("common");

    const headers = useMemo(() => [
        {
            title: t("sport"),
            field: (d => d ? d.sport.name : "sport"),
            itemClassName: "!py-2 whitespace-nowrap",
        },
        {
            title: t("tournament-rules.weeks"),
            field: (d => d ? d.num_weeks : "num_weeks"),
            itemClassName: "",
        },
        {
            title: t("tournament-rules.matchs"),
            field: (d => d ? d.num_games : "num_games"),
            itemClassName: "",
        },
        {
            title: t("description"),
            field: (d => d ? d.description : "description"),
            itemClassName: "",
        },
        {
            field: (d => d ? ({ value: d, onDelete: ((d => setTournamentRules(current => current.filter(e => e.id !== d.id)))), setValue: ((next) => setTournamentRules(current => current.map(e => e.id === next.id ? next : e))) }) : "Modifier"),
            FormatComponent: ModifyButton
        },

    ], [tournamentRules]);

    if (!tournamentRules) {
        return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2" />
    }

    return <Info.Container modify={true} label={t("tournament-rules.title")} sideHeader={
        <><CreateButton onChange={execute} />
        <SelectSport value={filters.sport} setValue={(value)=>dispatchFilters({type:'sport', value})}/>
        </>
    }>
        {tournamentRules.length === 0 ?
            <EmptyState
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-rules-title")} />
            :
            <Table headers={headers}
                indexingKey="id"
                data={tournamentRules}
                order={{ by: filters.orderBy, direction: filters.orderDirection }}
                onHeaderClick={(value) => dispatchFilters({ type: 'ordering', value })}
            />
        }
        {paging && <Paginate setPage={setPage} {...paging} />}
        {error ? <Error.Text {...error} /> : null}
    </Info.Container>
}

export function TournamentRulesListWithContext() {

    const { filters } = useContext(SearchContext);
    const [tournamentRules, { loading, error, setPage, paging, setResult, execute }] = useSearch(searchTournamentRules, filters, { limit: 500 });

    return <ControlledTournamentRulesList tournamentRules={tournamentRules} setTournamentRules={setResult} setPage={setPage} loading={loading} error={error} paging={paging} execute={execute} />
}

export function TournamentRulesList() {
    return <SearchContextProvider doNotStore>
        <TournamentRulesListWithContext />
    </SearchContextProvider>
}

export const orderByValues = [
    {
        name: "Chronologique",
        slug: "Chronological"
    },
    {
        name: "Meilleure à pire",
        slug: "BestToWorst"
    }
]

export const getOrderValueFromSlug = (slug) => {
    return orderByValues.find((item) => item.slug == slug)
}

export function SelectOrderBy({ value, setValue, disabled, values, label, Icon, props, disabledClear }) {

    return <ListSelection value={value}
        setValue={setValue}
        disabled={disabled}
        values={values}
        label={label}
        Icon={Icon}
        getKey={(d) => d.slug}
        format={(d) => d.name}
        withClearSelection={!disabledClear}
        {...props}
    />
}