import React from 'react';
import classnames from 'classnames';

// Hooks
import { useState } from 'react'

// Components
import { SearchField } from 'components/tools/search_field'; 
import { useTranslation } from 'react-i18next';

// API
import { searchSchoolClasses } from 'api/utils';
import { useSearch } from 'hooks/useSearch';

export function SearchSchoolClass({value, setValue, disabled, color, size, targetWidth}){
  const { t } = useTranslation('common');
  const [params, setParams] = useState({});
  const [results, {loading}] = useSearch(searchSchoolClasses, params)
  return <SearchField values={results}
                      loading={loading}
                      value={value}
                      setValue={setValue}
                      size={size}
                      disabled={disabled}
                      color={color}
                      targetWidth={targetWidth}
                      placeholder={t("school-class")}
                      indexingField={"slug"}
                      formatSelectedValue={(d=>d.name)}
                      formatSearchResult={((d, {selected, active})=>
                        <div>
                          <span className={classnames('block truncate', (active || selected) && 'font-semibold')}>{d.name}</span>
                        </div>)}
                     onParamsChange={setParams}
  />
}
