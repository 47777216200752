import { fetchCall } from 'api';
import { isString } from 'utils/var_check';

/*
  Create a study session
*/  
export const createStudySession = ({student, period, useInCount, studySessionRetaken, lengthInMinutes, pairing, sessionFormat, subjects, code, studySessionHasNotOccured, options}) => {
  const params = {
    method: "POST", 
    endpoint: `/study-sessions`,
    body: {student, 
          period, 
          studySessionRetaken,
        lengthInMinutes, 
        pairing,
        subjects, 
        code, 
        useInCount,
        sessionFormat, 
        options, 
        studySessionHasNotOccured}
  }
  return fetchCall(params)
};

/*
  List the study session options
*/  
export const searchStudySessionOptions = ({studyCode, search, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: `/study-sessions-options`,
    query: {studyCode, search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};

/*
  List the study sessions
*/  
export const searchStudySessions = ({studyCode, preset, team, tutor, useInCount, sessionOccurred, pairing, fromDate, toDate, student, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: `/study-sessions`,
    query: {pairing, 
            team: team && (isString(team)? team: team.slug), 
            student: student && (isString(student)? student: student.ni), 
            studyCode: studyCode && (isString(studyCode)? studyCode: studyCode.code), 
            tutor,
            page, 
            useInCount,
            preset: preset && (isString(preset)? preset: preset.id), 
            sessionOccurred,
            fromDate: fromDate && fromDate.toISOString(), 
            toDate: toDate && toDate.toISOString(), 
            limit, 
            orderBy, 
            orderDirection}
  }
  return fetchCall(options)
};

/*
  List the grouped study sessions by students
*/  
export function searchGroupedStudySessions({studyCode, 
                                          weekdays, 
                                        studySessionVerificationFlag, 
                                        preset, 
                                        team, 
                                        pairing, 
                                        activePairings, 
                                        tutor, 
                                        student, 
                                        period,
                                        fromDate, 
                                        toDate, 
                                        stepDays, 
                                        datePartition, 
                                        choked,
                                        useInCount, 
                                        isRetaken,
                                        technicalProblem,
                                        cancelledForExternalReason,
                                        sessionOccurred,
                                        groupBy, 
                                        assignedTo,
                                        page, 
                                        limit, 
                                        orderBy, 
                                        orderDirection}){
  const options = {
    method: "GET", 
    endpoint: `/grouped-study-sessions`,
    query: {team: team && (isString(team)? team: team.slug), 
            studyCode: studyCode && (isString(studyCode)? studyCode: studyCode.code), 
            page, 
            datePartition,
            stepDays,
            studySessionVerificationFlag,
            tutor: tutor && (isString(tutor)? tutor: tutor.ni), 
            student: student && (isString(student)? student: student.ni), 
            pairing: pairing && (isString(pairing)? pairing: pairing.id), 
            preset: preset && (isString(preset)? preset: preset.id), 
            fromDate: fromDate && fromDate.toISOString(), 
            toDate: toDate && toDate.toISOString(), 
            period: period && (isString(period)? period: period.slug),
            activePairings,
            choked,
            useInCount, 
            isRetaken,
            technicalProblem,
            cancelledForExternalReason,
            sessionOccurred,
            limit, 
            weekdays,
            assignedTo: assignedTo && (isString(assignedTo)? assignedTo: assignedTo.email),
            groupBy,
            orderBy, 
            orderDirection}
  }
  return fetchCall(options)
}

/*
    Get the study sessions completion ratio
*/
export function studySessionsCompletionRatio({fromDate, toDate, team, preset, period}) {
    const options = {
        method: "GET", 
        endpoint: `/study-sessions-completion-ratio`,
        query: {team: team && (isString(team)? team: team.slug), 
                preset: preset && (isString(preset)? preset: preset.id), 
                fromDate: fromDate && typeof fromDate === "object"? fromDate.toISOString(): fromDate,
                toDate: toDate && typeof toDate === "object"? toDate.toISOString(): toDate,
                period: period && (isString(period)? period: period.slug)}
    }
    return fetchCall(options)
}

/*
  Retrieve a study session
*/  
export const retrieveStudySession = ({id}) => {
  const options = {
    method: "GET", 
    endpoint: `/study-sessions/${id}`,
  }
  return fetchCall(options)
};

/*
  Add or remove subject a study session
*/  
export const addOrRemoveSubject = ({id, subject}) => {
  const options = {
    method: "POST", 
    endpoint: `/study-sessions/${id}/add-or-remove-subject`,
    body: {
        subject: subject && (isString(subject)? subject: subject.slug), 
    }
  }
  return fetchCall(options)
};


/*
  Delete a study session
*/  
export const deleteStudySession = ({id}) => {
  const options = {
    method: "DELETE", 
    endpoint: `/study-sessions/${id}`,
  }
  return fetchCall(options)
};

/*
  Update a study session
*/  
export const updateStudySession = ({id, code, period, choked, technicalProblem, cancelledForExternalReason, originalPeriod, isRetaken, sessionFormat, lengthInMinutes, useInCount, sessionOccurred}) => {
  const options = {
    method: "POST", 
    endpoint: `/study-sessions/${id}`,
    body: {
          code: code && (isString(code)? code: code.code), 
          useInCount, 
          sessionOccurred,
          choked, 
          technicalProblem, 
          cancelledForExternalReason, 
          isRetaken, 
          sessionFormat: sessionFormat && (isString(sessionFormat)? sessionFormat: sessionFormat.code), 
            period, 
            originalPeriod, 
            lengthInMinutes}
  }
  return fetchCall(options)
};

