import React from 'react';

// Components
import { Tabs } from 'components/core/tabs';

// Hooks
import { useState } from 'react';

export function NavBar(){
  const tabs = [
    {
      name: "Tuteurs",
      href: `/portal-access/tutors`,
    },
    {
      name: "Entraîneurs",
      href: `/portal-access/coachs`,
    },
    {
      name: "Partenaires",
      href: `/portal-access/partners`,
    },
    {
      name: "Enseignants",
      href: `/portal-access/teachers`,
    },
    {
      name: "Parents",
      href: `/portal-access/parents`,
    }
  ]
  const [tab, setTab] = useState(tabs[0]);

  return <div className="border-b px-3">
          <Tabs tabs={tabs} value={tab} setValue={setTab}/>
         </div>
}