import React from 'react';

// Utils
import classnames from 'classnames';

// Components
import { MagnifyingGlassIcon, InformationCircleIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react';
import { Spinner } from 'components/core/spinner';

const rootSize = {
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    '2xl': 'max-w-2xl',
    '3xl': 'max-w-3xl',
    '4xl': 'max-w-4xl',
    '5xl': 'max-w-5xl',
    '6xl': 'max-w-6xl',
    '7xl': 'max-w-7xl',
}
function CommandRoot({open, setOpen, size="xl", children}){
    return <>
        <Transition.Root show={open} as={Fragment} appear>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-20 p-4 overflow-y-auto sm:p-6">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className={classnames("mx-auto transition-all transform bg-white divide-y divide-gray-100 shadow-2xl md:my-[10vh] rounded-xl ring-1 ring-black ring-opacity-5", rootSize[size])}>
                        {children}
                    </Dialog.Panel>
                </Transition.Child>
            </div>
        </Dialog>
    </Transition.Root>
    </>
}

function CommandSearch({setQuery, children, loading, placeholder, ...props}){
    return <Combobox {...props}>
                <div className="relative">
                    {loading ?
                        <Spinner className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" /> :
                        <MagnifyingGlassIcon
                            className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />}
                    <Combobox.Input
                        className="w-full h-12 pr-4 text-gray-900 bg-transparent border-0 pl-11 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                        placeholder={placeholder}
                        onChange={(event) => setQuery(event.target.value)}
                />
            </div>
            {children}
            </Combobox>
}

const optionsSize = {
    sm: 'max-h-36',
    md: 'max-h-72',
    lg: 'max-h-96',
    xl: 'max-h-120',
    "inherit": ''
}
function CommandOptions({size="md", children}){
    return <Combobox.Options static className="text-sm text-gray-800 ">
                <div className={classnames("p-2 overflow-y-auto scroll-py-2", optionsSize[size])}>{children}</div>
            </Combobox.Options>
}

function CommandOption({children, ...props}){
    return <div className="overflow-y-auto max-h-72 scroll-py-2">
                <Combobox.Option {...props}
                    className={({ active, selected }) =>
                        classnames('cursor-default  rounded-md select-none px-4 py-2', active && 'bg-gray-100', selected && '')
                    }
                >
                    {({ active, selected }) => (
                        <div className={classnames("flex items-center")}>
                            <div className='w-8'>{selected && <CheckIcon className={classnames('w-5 h-5 mr-2 text-orange-500')} />}
                            </div>
                            {children({active, selected})}
                        </div>
                    )}


                </Combobox.Option>
            </div>
}

function CommandHelp({children}){
    return <div className="flex flex-wrap items-center bg-gray-50 px-4 py-2.5 text-xs text-gray-700 rounded-b-2xl">
            <span className='inline-flex'><InformationCircleIcon className='inline-flex w-5 h-5 mr-2' />{children}</span>
        </div>
}

export const Command = {Root: CommandRoot, Help: CommandHelp, Search: CommandSearch, Options: CommandOptions, Option: CommandOption}