import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Container } from 'components/core/container';
import { Tabs } from 'components/core/tabs';
import { Button } from 'components/core/button';
import { FiArrowLeft } from 'react-icons/fi';

// Hooks 
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next'; 
import { useParams, useLocation, generatePath, matchPath } from 'react-router-dom';

// Contexts
import { TutorContextProvider, TutorContext } from 'contexts/tutor';

function Header(){
  const { t } = useTranslation("common");
  const { tutor } = useContext(TutorContext);
  if (!tutor.name){
    return <Container className="py-6">
              <Skeleton className="w-screen max-w-sm h-12" />
            </Container>
  }
  return <Container className="py-3 bg-gray-100 border-b">
          <Button href="/tutors" color="inlineLink"  className="md:hidden"><FiArrowLeft className="mr-1"/>{t("tutors")}</Button>
          <h1 className="font-medium text-2xl">{tutor.name}</h1>
          </Container>
}

function TutorTabs(){
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation("common");
  const tabs = useMemo(()=>[
      {
        name: t("tutor-nav.overview"),
        href: generatePath("/tutors/:ni", params),
        current: matchPath("/tutors/:ni/", location.pathname),
      }, 
      {
        name: t("tutor-nav.notes"),
        href: generatePath("/tutors/:ni/notes", params),
        current: matchPath("/tutors/:ni/notes", location.pathname),
      }, 
      {
        name: t("tutor-nav.settings"),
        href: generatePath("/tutors/:ni/settings", params),
        current: matchPath("/tutors/:ni/settings", location.pathname),
      }, 
    ], [location])
  return <Container className="border-b">
          <Tabs tabs={tabs}/>
          </Container>
}

export function TutorLayout({ children }){
  return <TutorContextProvider>  
          <Header/>
          <TutorTabs/>
          { children }
         </TutorContextProvider>

}