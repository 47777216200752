import React, {createContext, useCallback, useContext } from "react";

// Hooks
import { useLocation } from 'react-router-dom';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useMemo, useEffect } from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContext } from 'contexts/search';

// API
import { searchStudents } from 'api/students';

const StudentsContext = createContext({});

const StudentsContextProvider = ({ children }) => {
  const { filters } = useContext(SearchContext);
  const [students, { loading, error, paging, hasMore, setPage, setResult, execute }] = useSearch(searchStudents, filters, {limit: 500, fields: ["ni", "name", "notebook_number", "ended_using_service"]});
  const isLoaded = useMemo(()=>!!students, [students]);
  const location = useLocation();
  const paramsChanged = useMemoCompare(location, (prev, next)=>prev && prev.pathname==next.pathname);
  useEffect(()=>{
    execute()
  }, [paramsChanged])

  const updateStudent = useCallback((student) => {
    setResult((students) => students.map((s) => s.ni === student.ni ? student : s));
  }, [setResult]);

  return (
    <StudentsContext.Provider value={{ students, setStudents: setResult, loading, error, paging, setPage, hasMore, isLoaded, updateStudent }}>
      {children}
    </StudentsContext.Provider>
  );
};

export { StudentsContext, StudentsContextProvider };