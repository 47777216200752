import React, { useMemo } from 'react';

// Hooks
import { useSearch } from 'hooks/useSearch';

// API
import { searchSchoolProfiles } from 'api/utils';
import { useTranslation } from 'react-i18next';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Error, InfoSectionHeading } from 'components/core/typo';
import { Table } from 'components/core/table';

export function HelpSchoolProfiles(){
    const {t} = useTranslation('common');
    const [profiles, {loading, error}] = useSearch(searchSchoolProfiles);
    const headers = useMemo(() => [
        {
            title: t("code"),
            field: "slug",
            itemClassName: "font-medium "
        },
        {
            title: t("name"),
            field: "name"
        },
    ], []);

    return <div className='bg-gray-100 rounded-md  px-3 pb-3'>
            <InfoSectionHeading smallTitle={t("bulk-import.school-profile-help.title")}
                                longDescription={t("bulk-import.school-profile-help.description")}/>
            {profiles? <div className="space-y-2">
                        <Table data={profiles} headers={headers} indexingKey={"slug"}/>
                    </div>:
                loading? <Skeleton className="h-24"/>:
                error? <Error.Text {...error}/>:null
            }
            </div>

}