import React, { useEffect, useMemo, useState } from 'react';

// Components
import { useTranslation } from 'react-i18next';
import { Editor } from 'components/core/rich-editor/editor';

// Hooks
import { useContext } from 'react';

// Contexts
import { StudentContext } from 'contexts/student';
import { useAPI } from 'hooks/useAPI';
import { retrieveStudent, updateStudent } from 'api/students';
import { Error, InfoSectionHeading } from 'components/core/typo';
import { Button } from 'components/core/button';
import { Skeleton } from 'components/core/skeleton';
import { useMemoCompare } from 'hooks/useMemoCompare';

export default function PageProfessionalComments(){
  const { t } = useTranslation('common');
  const { student } = useContext(StudentContext);
  const ni = useMemoCompare(student.ni, (prev, next)=> prev === next);
  const params = useMemo(() => ({ni, fields: ["ni", "professional_comments"]}), [ni]);
  const [, {loading: loadingStudent, execute: save, error: errorStudent}] = useAPI(updateStudent, {}, {immediate: false});
  const [result, { loading, error}] = useAPI(retrieveStudent, params)
  const [value, setValue] = useState(null);
  const [lastSaved, setLastSaved] = useState(null);
  useEffect(()=>{if (result){setLastSaved(result.professional_comments); setValue(result.professional_comments); return} setValue("")}, [result]);

  return <div className='h-full'>
    <InfoSectionHeading title={t("professional-comments.title")} longDescription={t("professional-comments.description")}/>
            {error && <Error.Text {...error}/>}
            {errorStudent && <Error.Text {...errorStudent}/>}
            <div className='flex justify-between items-end'>
                {!loading && result && lastSaved!==value ? <p className='text-sm px-2 py-1.5 bg-yellow-100 rounded-md text-yellow-600'>{t("unsaved-2")}</p>: 
                <p/>}
                <Button loading={loadingStudent} color="active"  size="lg" onClick={()=>{setLastSaved(value); save({ni, professionalComments: value})}}>
                    {t("save")}
                </Button>
            </div>
            {loading? <Skeleton/>:
            result && <Editor 
            editorStyles="text-black min-h-60" 
            toolbarStyles=''  
            name="professional_comments" 
            id="profess" 
            placeholder={t("professional-comments.placeholder")} 
            text={value} 
            setText={setValue} />}
        
          </div>
}
