export function convertObjectToCSV(objArray){
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';
   for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
          array[i][index] = array[i][index].toString().replace(/"/g, '""');
          array[i][index] = '"' + array[i][index] + '"';
          
          if (line != '') line += ';'

          line += array[i][index];
          
      }

      str += line + '\r\n';
  }

  return str;
}


export function exportCSVFile(headers, rawItems, fileTitle) {
    // Update the content of the items
    var items = rawItems.map(row=>{

      return headers.filter(d=>!d.ignoreAtExport).map(({field, format})=>{
        const value = (typeof field==='function')? field(row): row[field];
        const r =  format? format(value): value;
        return r? r: "";
      });
    })

    // Add the headers on top
    items.unshift(headers.filter(d=>!d.ignoreAtExport).map(d=>d.title));

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
    var csv = convertObjectToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}