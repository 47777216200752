import React from 'react';

// Components
import { CompactCalendarWeek } from 'components/calendar/compact_week';

// Hooks
import { useState, useEffect, useCallback } from 'react';

// Utils
import { timeWeek } from 'd3-time';

export function WeekView({date, onDateChange, ...props}){
  const [leftDate, setLeftDate] = useState(date || new Date());

  const handleDateChange = useCallback((offset)=>{
    setLeftDate(d=>timeWeek.offset(timeWeek.floor(d), offset));
  }, []);

  useEffect(()=>{
    if (onDateChange) onDateChange(leftDate);
  }, [leftDate])
  return <CompactCalendarWeek date={leftDate}
                              onPreviousWeek={()=>handleDateChange(-1)}
                              onNextWeek={()=>handleDateChange(1)}
                              {...props}
                               />
}
