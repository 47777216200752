/* eslint-disable */
import React, { useState, useContext } from 'react';

// Components
import { Viewer } from 'components/core/rich-editor/viewer';
import { Dropdown } from 'components/core/dropdown';
import { Button } from 'components/core/button';
import { NoteSliderOverContext } from 'contexts/note-slider';

// Icons
import { FiChevronDown } from 'react-icons/fi';

// API
import { toggleLinkBetweenComments } from 'api/notes'

// Utils
import { locale } from 'utils/locale';
const fullDayFormat = locale.format("%A, %d %B %Y");
import classNames from 'classnames';

// Hooks
import { useAPI } from 'hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks/useQuery';

export function LinkedComments({ parentComment, linkedComments, onUnlink, isActions, student }) {
    const { t } = useTranslation("common");
    const parentId = typeof parentComment === "object" ? parentComment.id : parentComment;
    const [open, setOpen] = useState(false);
    const {setQuery} = useQuery();
    const handleOpenNote = (noteId) => {
        setQuery({noteId});
    }
    const handleResult = ({deleted, commentA, commentB}) => {
        const otherId = commentA === parentId ? commentB : commentA;
        if (deleted) {
            onUnlink?.(otherId);
        }
    }
    const [,{execute:toggleLink, loading}] = useAPI(toggleLinkBetweenComments, {}, {immediate:false, camelize:true, onResult:handleResult});
    const handleToggle = (id) => {
        toggleLink({commentId:parentId, linkedCommentId:id});
    }
    const inNoteContext = useContext(NoteSliderOverContext)?.shouldOpenNote ? true : false;
    const hideShowNote = isActions ? true: !inNoteContext;
    const studentNi = typeof student === "string" ? student : student?.ni;
    return <div className='flex flex-col @container'>
        <div className=''>
            <Button color="default"  className='!bg-inherit text-sm text-gray-500 !px-0.5 mt-0.5' onClick={()=>setOpen(prev=>!prev)}>
                {t(isActions ? "linked-actions":"linked-comments")} ({linkedComments?.length}) 
                <FiChevronDown className={classNames('mt-0.5 ml-0.5 transition-all size-4', open ? "-rotate-180":"")} />
            </Button>
        </div>
        <div className={classNames(open?'mt-2 flex flex-col gap-2':'hidden')}>
            {
                linkedComments?.map(({content, id, displayDate, noteId}) => {
                    const fDate = fullDayFormat(new Date(`${displayDate}T00:00:00`));
                    return <div key={id} className='rounded bg-white/70 p-4'>
                        <div className='@[500px]:flex-row flex-col flex justify-between flex-wrap gap-1 mb-2 items-center @[500px]:items-start'>
                            <div className='text-sm text-gray-500'>{fDate}</div>
                            <div className='flex items-start gap-2 h-fit'>
                                <Dropdown
                                    onlyIcon={<div className='px-3 py-2 text-sm rounded-md flex items-center shadow-none border-0 text-red-600 hover:text-red-700 hover:bg-red-50 outline-none'>
                                        {t("dissociate")}
                                    </div>
                                    }
                                    iconClassName="!p-0"
                                    color={"danger"}
                                    menuItemsClassName="text-xs"
                                    orientation="right"
                                >
                                    <Dropdown.Item
                                        disabled={loading}
                                        name={t("confirm-dissociation")}
                                        color="danger"
                                        className="!w-48"
                                        onClick={()=>handleToggle(id)}
                                    />
                                </Dropdown>
                                {
                                    !hideShowNote && <Button color="gray" size="sm" className="h-fit" onClick={()=>handleOpenNote(noteId)}>
                                        {t("view-note")}
                                    </Button>
                                }
                                {
                                    isActions && <Button color="gray" size="sm" className="h-fit" target="_blank" href={`/students/${studentNi}/plans#${id}`}>
                                        {t("view-action")}
                                    </Button>
                                }
                            </div>
                        </div>
                        <Viewer html={content} />
                    </div>
                })
            }
        </div>
    </div>
}