import React from 'react';

// Components
import { Container } from 'components/core/container';
import { TutorOpenSettings } from 'components/tutors/modify';

// Hooks

// Contexts

export function OpenSettings(){

  return <Container className="w-full h-full">
            <TutorOpenSettings/>
         </Container>

}
