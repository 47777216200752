export function addOrRemoveItem(array, item, indexingKey){
  if (!array) return [item];
  if (item==null) return [];
  const filtered = array.filter(d=>indexingKey? (d[indexingKey]!==item[indexingKey]): d!==item);
  if (filtered.length===array.length){
    return [...array, item];
  }
  else {
    return filtered;
  }
}

export function groupBy(array, key){
  return array.reduce((acc, curr)=>{
    if (!acc[curr[key]]) acc[curr[key]] = [];
    acc[curr[key]].push(curr);
    return acc;
  }, {})
}
