import React from 'react';

// Components
import { Container } from 'components/core/container';
import { FlexNav } from 'components/core/layouts/flex';
import { Outlet } from "react-router-dom";
import { SubSideNavLink } from "components/core/nav";
import { FiUser, FiStar, FiLogOut as CloseIcon, FiLogIn as OpenIcon} from 'react-icons/fi';
import { ComputerDesktopIcon } from '@heroicons/react/24/outline';

// Router
import { useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NavBar(){
  const { t } = useTranslation();
  const params = useParams();
  return <div >
          <SubSideNavLink path={generatePath(`/tutors/:ni/settings/`, params)} Icon={FiUser} name={t("personal-info")}/>
          <SubSideNavLink path={generatePath(`/tutors/:ni/settings/preferences`, params)} Icon={FiStar} name={t("preferences")}/>
          <SubSideNavLink path={generatePath(`/tutors/:ni/settings/portal`, params)} Icon={ComputerDesktopIcon} name={t("portal")}/>
          <SubSideNavLink path={generatePath(`/tutors/:ni/settings/open`, params)} Icon={OpenIcon} name={t("open")}/>
          <SubSideNavLink path={generatePath(`/tutors/:ni/settings/close`, params)} Icon={CloseIcon} name={t("close")}/>
        </div>
}
export function TutorSettings(){
  return <Container className="py-6">
          <FlexNav>
            <FlexNav.Bar>
              <NavBar/>
            </FlexNav.Bar>
            <FlexNav.Content>
              <Outlet/>
            </FlexNav.Content>
           </FlexNav>
          </Container>
}

import {default as general} from './general';
import {default as preferences} from './preferences';
import {default as portal} from './portal';
import {OpenSettings as open} from './open';
import {CloseSettings as close} from './close';
export const TutorSettingsPages = {root: TutorSettings, general, preferences, open, close, portal}