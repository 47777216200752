import React, { createContext, useCallback, useState } from 'react';

// Components
import { ActionsPlanSlideOver } from 'components/actions-plan';

// Hooks

export const ActionsPlanContext = createContext();

export function ActionsPlanProvider({ children }) {
    const [isOpen, setIsOpen] = useState(false);
    const [student, setStudent] = useState(null);
    const [onUpdate, setOnUpdate] = useState(null);
    const [highlight, setHighlight] = useState(null);
    const [filter, setFilter] = useState({
        past: true,
        completed: true
    })
    const displayPlanActions = useCallback(({ student } = {}) => {
        if (!student) {
            // Close the SlideOver if student is null, undefined, or not provided
            setIsOpen(false);
            setStudent(null);
        } else {
            // Open the SlideOver and set the student
            setStudent(student);
            setIsOpen(true);
        }
    }, []);

    return (
        <ActionsPlanContext.Provider value={{ display:displayPlanActions, highlight, setHighlight, onUpdate, setOnUpdate, filter, setFilter }}>
            {children}
            <ActionsPlanSlideOver student={student} open={isOpen} setOpen={setIsOpen} />
        </ActionsPlanContext.Provider>
    );
}