import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import { useAPI } from 'hooks/useAPI';

// API
import { sendResetPasswordEmail } from 'api/auth';

// Components
import { Button } from 'components/core/button'
import { TextInput } from 'components/core/inputs'
import { Error } from 'components/core/typo'

export function ForgotPasswordForm(){
  const { t } = useTranslation(["auth"]);
  const [email, setEmail] = useState();

  const [result, {loading, error, execute}] = useAPI(sendResetPasswordEmail, {email},
                                                  {immediate: false});
  const onSubmit = useCallback((e)=>{
    if (e) e.preventDefault();
    execute();
  }, [execute])

  if (result){
    return <div>
            <p className="text-gray-500">{t('forgot_password.success', {email: email})}</p>
           </div>
  }

  return <form className="space-y-6" onSubmit={onSubmit}>
          <Button color="inlineLink" size="link" href="/login">
            {t("forgot_password.back")} 
          </Button>
          <h1 className="text-xl">{t('forgot_password.title')}</h1>
          <p className="text-gray-500">{t('forgot_password.description')}</p>
          <TextInput label={t("forgot_password.email.label")} 
              autoComplete="email"
              type="email"
              required
              onChange={setEmail}/>
          <Button disabled={!email} color="active" block size="lg" loading={loading} type="submit">
            {t("forgot_password.submit")} 
          </Button>
          {error? <Error.Text {...error}/>: null}
         </form>
}
