import React  from 'react';
import classnames from 'classnames';

// API

// Hooks
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import { SearchContext } from 'contexts/search';
import { StudentsContext } from 'contexts/students';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Button } from 'components/core/button';
import { ListSearch } from 'components/core/inputs';
import { PaginateCompact } from 'components/core/paginate';
import { Error } from 'components/core/typo'
import { ClosedIndicator } from 'components/core/closed-indicator';
import { getCurrentPeriod } from 'utils/period';

function StudentCell({ ni, name, notebook_number, teams, search, ended_using_service }){
  const { filters } = useContext(SearchContext);
  const { t } = useTranslation("common");
  const isClosed = ended_using_service && new Date(ended_using_service) < new Date();
  const activeTeams = useMemo(()=>{
    if (!teams) return null;
    const currentPeriod = (filters.period? filters.period.slug : getCurrentPeriod());
    return teams.filter(d=>d.team.active_period===currentPeriod);
  }, [teams]);

  return <Button className="p-3" layout="cell" nav  color={(d)=>d? "activeCell": "cell"}href={`/students/${ni}`}>
          {({isActive})=>
            <div className='flex justify-between'>

              <span className="block w-full break-all whitespace-pre-wrap">
                <span className={classnames(isActive&& "font-medium text-white")}>{name}</span>
                {search && notebook_number &&notebook_number.includes(search) && <p className={classnames(isActive&& "font-medium text-white")}>{t("notebook-number")}: {notebook_number}</p>}
                {activeTeams && activeTeams.length>0?
                  <div className={classnames("block text-sm", isActive? "text-white":"text-gray-400")}>{activeTeams.map(d=>`${d.team.name} ${d.team.active_period}`).join(', ')}</div>
                :null}
              </span>
              <div className='flex items-center ml-1'>
                <ClosedIndicator isClosed={isClosed} />
              </div>
              </div>}
         </Button>
}

export function StudentsList(){
  const { t } = useTranslation("common");
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { loading, paging, setPage, error, hasMore, students } = useContext(StudentsContext)
  if (!students){
    return <Skeleton.List itemClassName="h-8 rounded" className="p-3 space-y-3" numElements={4}/>
  }
  return <div>
          <div className="border-b">
            <ListSearch color="none"
                loading={loading}
                search={true}
                placeholder={t("search-by-name-or-notebook-number")}
                onChange={(value)=>(value!==filters.search? dispatchFilters({type: 'search', value}): null)} />
          </div>
            {error? <Error.Text className="mt-3" {...error}/>: null}
          {students.length===0?
            <EmptyState
                title={t("empty-state.no-students-title")}
                description={t("empty-state.no-students-description", {search: filters.search})}/>:
            <div className="divide-y">
            {students.map(d=><StudentCell key={d.ni} search={filters.search} {...d}/>)}
          </div>}
            {paging&& hasMore && <PaginateCompact setPage={setPage} {...paging}/>}

        </div>
}