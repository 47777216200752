import React from 'react';

// Components
import { Container } from 'components/core/container';
import { WeeklyListNotesCardWithContext } from 'components/notes/weekly_list';

// Hooks
import { useEffect, useContext } from 'react';
// Hooks
// Contexts
import { SearchNotesContextProvider, SearchNotesContext } from 'contexts/search_notes';
import { SearchFollowUpContext } from 'contexts/search_follow_up';


export function BDI(){
  const {filters} = useContext(SearchFollowUpContext);
  const notesFilters = useContext(SearchNotesContext);
  useEffect(()=>{
    notesFilters.dispatchFilters({type:"preset", value: filters.preset})
    notesFilters.dispatchFilters({type:"team", value: filters.team})
  }, [filters])

  return <WeeklyListNotesCardWithContext/>
}


export default function BDIWithContext(){
  const {filters} = useContext(SearchFollowUpContext);
  return <div className="pb-6">
          <SearchNotesContextProvider defaultState={{preset: filters.preset, orderBy: "latest_comment", orderDirection: "desc"}}>
            <Container className={"pt-2"} >
              <BDI/>
            </Container>
          </SearchNotesContextProvider>
         </div>
}

