import React from 'react';

// Components
import { InfoSectionHeading  } from 'components/core/typo';
import { Button } from 'components/core/button';
import { SelectSettings } from 'components/end-year-assistant/students/settings';
import { StudentsList } from 'components/end-year-assistant/students/sample';

import { Error } from 'components/core/typo'
import { FlexNav } from 'components/core/layouts/flex';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAPI } from 'hooks/useAPI';

// Utils
import classnames from 'classnames';

// API
import { closeAllActiveStudents } from 'api/students';

function ApplyButton({settings, onResult}){
  const { t } = useTranslation('endYearAssistant');
  const [, {execute, loading, error}] = useAPI(closeAllActiveStudents, 
                                            settings, 
                                            {onResult, immediate:false})

  return <div className="relative">
          <InfoSectionHeading mediumTitle={t("summary.title")} longDescription={t("summary.description")}/>
            <div className={classnames(" rounded-lg p-3 w-full space-y-3 border-2 border-red-500")}>
            <div>
              <h3 className="font-medium text-red-600">{t("students.close-all-students.title")}</h3>
              <p className="text-gray-500">{t("students.close-all-students.description")}</p>
            </div>
            <Button loading={loading} disabled={!settings.endedAt || !settings.reason}  onClick={execute} block color="deleteContrast" size="lg">{t("students.close-all-students.button")}</Button>
            {error? <Error.Text {...error}/>: null}
           </div>
        </div>
}

export default function Students(){
  const { t } = useTranslation("endYearAssistant");
  const [settings, setSettings] = useState({reason: t("students.reason-of-end-default"), endDate: new Date()});
  const [result, setResult] = useState();
  return <FlexNav breakdown="lg" className={"max-w-6xl mx-auto relative px-3 "}>
            <FlexNav.Content breakdown="lg" className="py-6">
              <div className="mx-auto w-full  space-y-8">
                <InfoSectionHeading mediumTitle={t("students.title")} longDescription={t("students.description")}/>
                {result?
                   <div className="p-3 rounded-md bg-green-100 text-green-700">
                    <p className="">{t("students.close-all-students.success", {numClosed: result.num_closed})}</p>
                  </div>:
                    <div className="space-y-12">
                      <SelectSettings value={settings} setValue={setSettings}/>
                      <StudentsList/>
                    </div>

                 }
               </div>

             </FlexNav.Content>
             <FlexNav.Bar breakdown="lg" className="order-last lg:border-l lg:pl-6 ">
              <div className="absolute sticky top-0 space-y-12 max-h-full pb-12">
                <ApplyButton settings={settings} onResult={setResult}/>
              </div>
             </FlexNav.Bar>
            </FlexNav>

}
