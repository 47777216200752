import React from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiLink } from 'react-icons/fi';

// Hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch';

// API
import { searchPairingTypes } from 'api/utils';

export function SelectPairingType({ value, setValue, removeHybrid, disabled, disabledClear, ...props }){

  const { t } = useTranslation("common");
  const [results] = useSearch(searchPairingTypes);
  const types = useMemo(()=>results? (removeHybrid? results.filter(d=>d.code!=="hybride"): results): null)

  return <ListSelection value={value}
                        setValue={setValue}
                        disabled={disabled}
                        values={types}
                        label={t('pairing-type')}
                        Icon={FiLink}
                        getKey={(d)=>d.code}
                        format={(d)=>d.name}
                        withClearSelection={!disabledClear}
                        {...props}
                        />
}


// import React from 'react';
// import classnames from 'classnames';

// // API
// import { searchPairingTypes } from 'api/utils';

// // Components
// import { Skeleton } from 'components/core/skeleton';

// // Hooks
// import { useSearch } from 'hooks/useSearch';

// export function SelectPairingType({ value, setValue, removeHybrid }){
//   const [codes] = useSearch(searchPairingTypes);
//   if (!codes){
//     return <Skeleton className="h-8"/>;
//   }
//   return <div className={classnames("grid grid-cols-1 gap-3 md:gap-3", removeHybrid? "md:grid-cols-2": "md:grid-cols-2")}>
//             {codes.map(d=>
//               (removeHybrid && d.code==="hybride")? null:<div key={d.code} 
//                 className={classnames("rounded-md col-span-1 max-col p-2 flex items-center cursor-pointer border-2",
//                   value && value.code===d.code? "bg-gray-200": "hover:bg-gray-100 bg-gray-50 border-transparent")}
//                  onClick={()=>setValue(d)}>
//                 <div className="w-6 text-center" >
//                   <input type="radio" id={d.code} name={"code"} checked={(value && value.code===d.code) || false} readOnly/>
//                 </div>
//               <div className="flex-1 ml-3">
//                 <div className="text-medium flex items-center">{d.name}</div>
//               </div>
//             </div>
//             )}
//          </div>

// }
