import React, { useEffect, useMemo, useCallback } from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { SingleLineField, ModifiableSelectField } from 'components/tools/modifiable_fields';
import { SelectDate } from 'components/tools/select_date';
import { Error, TextErrorStructured, InfoSectionHeading } from 'components/core/typo'
import { Button } from 'components/core/button';
import { FiLock } from 'react-icons/fi';
import { Info } from 'components/core/info';
import { SelectSettings } from 'components/end-year-assistant/coachs/settings';
import { SelectPeriods } from 'components/tools/select_period';
import toast from 'react-hot-toast';

// Utils
import classnames from 'classnames';
import { locale, dateParse } from 'utils/locale';
import { getFullCurrentPeriod } from 'utils/period';

// Contexts
import { CoachContext } from 'contexts/coach';
import { CoachsContext } from 'contexts/coachs';

// Hooks
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAPI } from 'hooks/useAPI';

// API
import { updateCoach, deleteCoach, closeCoach, openCoach } from 'api/coachs';

function DeleteButton({ ni }) {
  const { t } = useTranslation('common');
  const [lock, setLock] = useState(true);
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    navigate(`/coachs`);
    toast.success(t("delete-coach.success"), { duration: 3000 })
  }, [])

  const [, { execute, loading, error }] = useAPI(deleteCoach,
    { ni },
    { onResult: (handleDelete), immediate: false })

  return <div className="relative">
    {lock && <div className="absolute z-10 flex items-center justify-center w-full h-full text-center">
      <div>
        <h3 className="pb-3 text-lg text-gray-600">{t("delete-coach.title")}</h3>
        <Button color="deleteContrast" size="lg" onClick={() => setLock(false)}><FiLock /> {t("delete-coach.unlock-section")}</Button>
      </div>
    </div>}
    <div className={classnames(" rounded-lg p-3 w-full space-y-3", lock ? "opacity-50 blur-sm" : "border-2 border-red-500")}>
      <div>
        <h3 className="font-medium text-red-600">{t("delete-coach.title")}</h3>
        <p className="text-gray-500">{t("delete-coach.description")}</p>
      </div>
      <Button disabled={lock} loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-coach.button")}</Button>
      {error ? <Error.Text {...error} /> : null}
    </div>
  </div>
}


export function CoachGeneralSettings() {

  const { coach, setCoach } = useContext(CoachContext);
  const { updateCoach: setUpdatedCoach } = useContext(CoachsContext);
  const modify = true;
  const { t } = useTranslation('common');
  const ni = coach.ni;
  const [, { error, execute }] = useAPI(updateCoach, {}, { immediate: false, onResult: (value) => {setUpdatedCoach(value)}});

  if (!coach.name) {
    return <Skeleton className="w-full h-36" />
  }
  return <div className={classnames("")}>
    <h1 className="info-section">{t('personal-info')}</h1>
    {error ? <Error.Text {...error} /> : null}
    <Info.Container modify={modify}>
      <SingleLineField
        label={t("name")}
        modify={modify}
        value={coach.name}
        setValue={(name) => { execute({ ni, name }); setCoach(d => ({ ...d, name }));}}>
        {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("name").toLowerCase()}`} />}
      </SingleLineField>
      <SingleLineField
        label={t("email")}
        modify={modify}
        value={coach.email}
        setValue={(email) => { execute({ ni, email }); setCoach(d => ({ ...d, email })) }}>
        {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("email").toLowerCase()}`} />}
      </SingleLineField>
      <SingleLineField
        label={t("phone")}
        modify={modify}
        value={coach.phone}
        setValue={(phone) => { execute({ ni, phone }); setCoach(d => ({ ...d, phone })) }}>
        {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("phone").toLowerCase()}`} />}
      </SingleLineField>
    </Info.Container>

    <Info.Container modify={modify}>
      <ModifiableSelectField
        Field={SelectDate}
        label={t("service-started-at")}
        orientation={"right"}
        modify={modify}
        value={coach.service_started_at && dateParse(coach.service_started_at)}
        setValue={(serviceStartedAt) => execute({ ni, serviceStartedAt: serviceStartedAt || null })} >
        {(val) => <Info.Field value={val && locale.format("%d %B %Y")(val)}
          noValueLabel={`${t("none", { context: 'female' })} ${t("service-started-at").toLowerCase()}`}
          label={t("service-started-at")}
        />}
      </ModifiableSelectField>

      <ModifiableSelectField
        Field={SelectDate}
        label={t("service-ended-at")}
        orientation={"right"}
        modify={false}
        value={coach.service_ended_at && dateParse(coach.service_ended_at)}
        setValue={(serviceEndedAt) => { execute({ ni, serviceEndedAt: serviceEndedAt || null }); setCoach(d => ({ ...d, service_ended_at: serviceEndedAt })) }} >
        {(val) => <Info.Field value={val && locale.format("%d %B %Y")(val)}
          noValueLabel={`${t("none", { context: 'female' })} ${t("service-ended-at").toLowerCase()}`}
          label={t("service-ended-at")}
        />}
      </ModifiableSelectField>

      {coach.service_ended_at && <SingleLineField
        label={modify && t("reason-for-end-service")}
        modify={modify}
        multiLine
        value={coach.reason_for_end_of_service}
        setAPI={{ method: updateCoach, extraParams: { ni }, name: 'reasonForEndOfService' }}
        setValue={(val) => { setCoach(d => ({ ...d, reason_for_end_of_service: val })) }}>
        {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("reason-for-end-service").toLowerCase()}`} />}
      </SingleLineField>}

    </Info.Container>

    <Info.Container modify={true} className="my-12">
      <DeleteButton ni={coach.ni} />
    </Info.Container>

  </div>



}

export function CoachCloseSettings() {
  const { t } = useTranslation('common');
  const { updateCoach: setUpdatedCoach } = useContext(CoachsContext);
  const { coach, setCoach } = useContext(CoachContext);
  const [settings, setSettings] = useState({ ni: coach.ni, reason: t("settings-coachs.close.reason-of-end-default"), endedAt: new Date() });
  const [result, { execute, loading, error }] = useAPI(closeCoach,
    settings, { onResult : value => {setCoach(value); setUpdatedCoach(value)}, immediate: false });
  useEffect(() => {
    if (result) {
      toast.success(t("settings-coachs.close.success"), { duration: 3000 })
    }
  }, [result])

  return <div>
    {coach.is_closed && <TextErrorStructured title={t("settings-coachs.close.already-closed")} />}
    <InfoSectionHeading mediumTitle={t("settings-coachs.close.title")} longDescription={t("settings-coachs.close.description")} />

    <SelectSettings value={settings} setValue={setSettings} />
    <InfoSectionHeading className={"mt-4"} mediumTitle={t("settings-coachs.close.summary.title")} longDescription={t("settings-coachs.close.summary.description")} />
    <div className={classnames("rounded-lg p-3 w-full space-y-3 border-2 border-red-500")}>
      <div>
        <h3 className="font-medium text-red-600">{t("settings-coachs.close.title")}</h3>
        <p className="text-gray-500">{t("settings-coachs.close.summary.warning")}</p>
      </div>
      <Button loading={loading} onClick={execute} disabled={!settings.endedAt || !settings.reason || coach.is_closed != false} block color="deleteContrast" size="lg">{coach.is_closed ? t("settings-coachs.close.already-closed-short") : t("settings-coachs.close.button")}</Button>
      {error ? <Error.Text {...error} /> : null}
    </div>
  </div>
}

export function CoachOpenSettings() {
  const { t } = useTranslation('common');
  const { updateCoach: setUpdatedCoach } = useContext(CoachsContext);
  const { coach, setCoach } = useContext(CoachContext);
  const [period, setPeriod] = useState(getFullCurrentPeriod());
  const settings =  useMemo(()=>({ni: coach.ni, period}), [coach, period]);
  const [result, { execute, loading, error }] = useAPI(openCoach,
    settings, { immediate: false, onResult: (value) => { setUpdatedCoach(value), setCoach(value) }})
  const modify = true;

  useEffect(() => {
    if (result) {
      toast.success(t("settings-coachs.open.success"), { duration: 3000 })
    }
  }, [result])

  return <div>
    {coach.is_closed == false && <TextErrorStructured title={t("settings-coachs.open.already-opened")} />}
    <InfoSectionHeading mediumTitle={t("settings-coachs.open.title")} longDescription={t("settings-coachs.open.description")} />
    <ModifiableSelectField Field={SelectPeriods} label={modify && t("settings-coachs.open.periods")} modify={modify} setValue={setPeriod} value={period} />
    <InfoSectionHeading className={"mt-4"} mediumTitle={t("settings-coachs.open.summary.title")} longDescription={t("settings-coachs.open.summary.description")} />
    <div className={classnames("rounded-lg p-3 w-full space-y-3 border-2 border-red-500")}>
      <div>
        <h3 className="font-medium text-red-600">{t("settings-coachs.open.title")}</h3>
        <p className="text-gray-500">{t("settings-coachs.open.summary.warning")}</p>
      </div>
      <Button loading={loading} onClick={execute} disabled={!coach.is_closed || !period} block color="deleteContrast" size="lg">{coach.is_closed == false ? t("settings-coachs.open.already-opened-short") : t("settings-coachs.open.button")}</Button>
      {error ? <Error.Text {...error} /> : null}
    </div>
  </div>
}