import React from 'react';

// Components
import { Button } from 'components/core/button';
import { Error } from 'components/core/typo'
import { CheckBox } from 'components/tools/checkbox';
import { FiMessageCircle } from 'react-icons/fi';

// API
import { updateNote, deleteNote } from 'api/notes';

// Hooks
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';
import { useQuery } from 'hooks/useQuery';

function OpenCloseButton({note, setNote}){
  const { t } = useTranslation('common');
  const [, {execute, loading, error}] = useAPI(updateNote,
                                            {noteId:note.id, closedAt: note.closed_at? null: new Date() },
                                            {onResult: (()=>setNote(d=>({...d, closed_at:  note.closed_at? null: (new Date()).toISOString()}))), immediate:false})

  return <div className="items-center justify-between w-full space-x-4 md:flex">
          <div>
            <h3>{t(note.closed_at? "open-note.title": "close-note.title")}</h3>
            <p className="text-gray-500">{t(note.closed_at? "open-note.description": "close-note.description")}</p>
          </div>
          <Button loading={loading} onClick={execute} color="black" size="sm" className="whitespace-nowrap">{t(note.closed_at? "open-note.button": "close-note.button")}</Button>
          {error? <Error.Text {...error}/>: null}
         </div>
}

function SetDiscussButton({note, setNote}){
  const { t } = useTranslation('common');
  const [, {execute,  error}] = useAPI(updateNote,
                                            {noteId:note.id, shouldDiscuss: note.should_discuss? false: true },
                                            {immediate:false})

  return <div>
            <CheckBox value={!!note.should_discuss}
                setValue={()=>{execute(); setNote(d=>({...d, should_discuss:  note.should_discuss? null: new Date()}))}}
                 name={t("should-discuss.title")} orientation="right"/>
            {error? <Error.Text {...error}/>: null}
          </div>
}

export function SetDiscussIcon({note, setNote}){
  const [, {execute,  error}] = useAPI(updateNote,
                                            {noteId:note.id, shouldDiscuss: note.should_discuss? false: true },
                                            {immediate:false})

  return <div>
            <Button onClick={()=>{execute(); setNote(d=>({...d, should_discuss:  note.should_discuss? null: new Date()}))}}
                  color={note.should_discuss? "active": ""}
                  className="bg-gray-100 hover:opacity-60"
                  size="toolbarIcon"><FiMessageCircle/></Button>
            {error? <Error.Text {...error}/>: null}
          </div>
}


function DeleteButton({note, setNote, onDelete}){
  const { t } = useTranslation('common');
  const {setQuery} = useQuery();
  const handleResult = ()=>{
    setQuery();
    setNote();
    onDelete?.();
  }
  const [, {execute, loading, error}] = useAPI(deleteNote,
                                            {noteId:note.id},
                                            {onResult: handleResult, immediate:false})

  return <div className="w-full p-3 space-y-3 border-2 border-red-500 rounded-lg">
          <div>
            <h3 className="font-medium text-red-600">{t("delete-note.title")}</h3>
            <p className="text-gray-500">{t("delete-note.description")}</p>
          </div>
          <Button loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-note.button")}</Button>
          {error? <Error.Text {...error}/>: null}
         </div>
}

export function ModifyNote({note, setNote, onDelete}){
  return <div className="h-full py-6 space-y-12">
          <OpenCloseButton note={note} setNote={setNote}/>
          <SetDiscussButton note={note} setNote={setNote}/>
          <DeleteButton note={note} setNote={setNote} onDelete={onDelete}/>
         </div>
}