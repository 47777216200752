import React from 'react';

// Components
import { Outlet } from "react-router-dom";
import { CoachLayout } from 'components/coachs/layout';

// Hooks

export function CoachBase(){
  return <CoachLayout>
            <Outlet/>
          </CoachLayout>
}

import { default as empty } from 'pages/coach/empty';
import { default as overview } from 'pages/coach/overview';
export const CoachPages = {root: CoachBase, overview, empty};