import React from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiLoader } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';

export function SelectConfirmedPairings({ value, setValue, disabled, ...props }){

  const { t } = useTranslation("common");
  const values = [
    {
      name: "Confirmés",
      value: true
    },
    {
      name: "En attente",
      value: false
    }
  ]
  return <ListSelection value={value || values[0]}
                        setValue={setValue}
                        disabled={disabled}
                        values={values}
                        label={t('pairing-is-confirmed-select')}
                        info={t('pairing-is-confirmed-select-info')}
                        Icon={FiLoader}
                        getKey={(d)=>d.name}
                        format={(d)=>d.name}
                        formatSelection={(d)=>d.name}
                        withClearSelection={false}
                        {...props}
                        />
}