import React from 'react';

// Components
import { Outlet } from "react-router-dom";
import { StudySessionLayout } from 'components/study-sessions/layout';

// Hooks

function StudySessionBase(){
  return <StudySessionLayout>
            <Outlet/>
          </StudySessionLayout>
}
import { default as empty } from "./empty";
import {default as overview} from './overview';
export const StudySessionPages = {root: StudySessionBase, empty, overview}