import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading, RawTextInfo  } from 'components/core/typo';
import { SingleLineField} from 'components/tools/modifiable_fields';
import { SelectSport } from 'components/tools/sports_list';
import { Error } from 'components/core/typo'
import { Button } from 'components/core/button'

// Hooks
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// API
import { updatePlayTimeRule, deletePlayTimeRule } from 'api/play_time_rules';


function DeleteButton({value, onDelete}){
  const { t } = useTranslation('common');
  const [, {execute, loading, error}] = useAPI(deletePlayTimeRule, 
                                            {id: value.id}, 
                                            {onResult: (()=>onDelete(value)), immediate:false})

  return <div className="border-2 border-red-500 rounded-lg p-3 w-full space-y-3">
          <div>
            <h3 className="font-medium text-red-600">{t("delete-play-time-rule.title")}</h3>
            <p className="text-gray-500">{t("delete-play-time-rule.description")}</p>
          </div>
          <Button loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-play-time-rule.button")}</Button>
          {error? <Error.Text {...error}/>: null}
         </div>
}

export function ModifyPlayTimeRule({value, setValue, onDelete}){
  const {t} = useTranslation('common');

  return<div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                description={t("modify-play-time-rules.description")}
                    title={<span className="flex items-center"> 
                            <div>{t("modify-play-time-rules.title")}</div></span>} />

              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
                  <RawTextInfo>
                    {t("play-time-rules-explained.info", {description: value.play_time_title, weeks: value.weeks, obli: value.target_study_sessions, done:value.done_study_sessions})}
                  </RawTextInfo>
                 <SingleLineField 
                  label={t("add-play-time-rule-group.play-time-title")}
                    modify={true} 
                    value={value.play_time_title} 
                    setValue={(playTimeTitle)=>{setValue({...value, play_time_title:playTimeTitle}); updatePlayTimeRule({id: value.id, playTimeTitle})}}/>

                <SelectSport 
                  label={t("sport")}
                  value={value.sport}
                  size="lg"
                  setValue={(sport)=>{setValue({...value, sport}); updatePlayTimeRule({id: value.id, sport})}}/>
              <SingleLineField
                      label={t("add-play-time-rule-group.weeks-title")}
                      type="number"
                      value={value.weeks} 
                    modify={true} 
                      min="0"
                      setValue={(weeks)=>{setValue({...value, weeks}); updatePlayTimeRule({id: value.id, weeks})}}
                    />

                <div className="flex items-start space-x-2">
                  <div className="w-32">
                    <SingleLineField
                      label={t("add-play-time-rule-group.done-study-sessions-title")}
                      type="number"
                      value={value.done_study_sessions} 
                    modify={true} 
                      min="0"
                      setValue={(doneStudySessions)=>{setValue({...value, done_study_sessions:doneStudySessions}); updatePlayTimeRule({id: value.id, doneStudySessions})}}
                    />
                  </div>
                  <div className="flex-1">
                    <SingleLineField
                     modify={true} 
                      label={t("add-play-time-rule-group.done-study-sessions-description-title")}
                      value={value.done_study_sessions_description} 
                      setValue={(doneStudySessionsDescription)=>{setValue({...value, done_study_sessions_description:doneStudySessionsDescription}); updatePlayTimeRule({id: value.id, doneStudySessionsDescription})}}
                    />
                  </div>
                </div>

                <div className="flex items-start space-x-2">
                  <div className="w-32">
                    <SingleLineField
                      label={t("add-play-time-rule-group.target-study-sessions-title")}
                      placeholder={t("add-play-time-rule-group.placeholder")}
                      type="number"
                      value={value.target_study_sessions} 
                    modify={true} 
                      min="0"
                      setValue={(targetStudySessions)=>{setValue({...value, target_study_sessions:targetStudySessions}); updatePlayTimeRule({id: value.id, targetStudySessions})}}
                    />
                  </div>
                  <div className="flex-1">
                    <SingleLineField
                     modify={true} 
                      label={t("add-play-time-rule-group.target-study-sessions-description-title")}
                      placeholder={t("add-play-time-rule-group.placeholder")}
                      value={value.target_study_sessions_description} 
                      setValue={(targetStudySessionsDescription)=>{setValue({...value, target_study_sessions_description:targetStudySessionsDescription}); updatePlayTimeRule({id: value.id, targetStudySessionsDescription})}}
                    />
                  </div>
                </div>

                
                <DeleteButton value={value} onDelete={onDelete}/>
               
              </CardContainer>
            </div>


}