// @flow
import React from "react";
import ReactDOM from "react-dom";

// Theme
import "./App.js";
import "./index.scss";

// Translations 
import "./i18n"

import App from "./App";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://9b3f2aa7097fc79bf55533997e07c33f@o4505568103038976.ingest.sentry.io/4505862737690624",
  });

const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.render(<App/>, rootElement);
} else {
  throw new Error("Could not find root element to mount to!");
}
