import React from 'react';

// Components
// import { Container } from 'components/core/container';
import { TutoringGrid } from 'components/tutoring-follow-up/tutoring_grid';

// Hooks
import { useContext } from 'react';
// Hooks
// Contexts
import { TutoringFollowUpContext } from 'contexts/tutoring_follow_up';
// import { SearchFollowUpContext } from 'contexts/search_follow_up';

export default function BDT(){
  const {filters} = useContext(TutoringFollowUpContext);
  return <div className="py-6">
            <TutoringGrid studyCode="tutorat" numWeeks={4} weekdays={filters.weekdays} preset={filters.preset} team={filters.team} />
          </div>
}
