import React from 'react';

// Components
import { Outlet } from "react-router-dom";
import { TeamLayout } from 'components/teams/layout';

// Hooks

function TeamBase(){
  return <TeamLayout>
            <Outlet/>
          </TeamLayout>
}
import { default as empty } from "./empty";
import {default as overview} from 'pages/team/overview';
import {default as playTime} from 'pages/team/play_time';

export const TeamPages = {root: TeamBase, overview, playTime, empty}