import React from 'react';

// Components
import { CreateNote } from 'components/notes/create';
import { Modal } from 'components/core/modal';
import { Button } from 'components/core/button';
import { Fragment } from 'react';

// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function CreateNoteInModal({open, setOpen, creatorProps}){
  return <Modal open={open} setOpen={setOpen} size="xl" disableOutsideClick={true}>
           <CreateNote {...creatorProps}/> 
        </Modal>
}

export function CreateNoteButton({creatorProps}){
  const [open, setOpen] = useState(false);
  const {t} = useTranslation('common')

  return <Fragment>
          <Button color="gray" size="md" onClick={()=>setOpen(true)}>{t("add-note")}</Button>
          <CreateNoteInModal open={open} setOpen={setOpen} creatorProps={creatorProps}/>
        </Fragment>
}