import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Container } from 'components/core/container';
import { Tabs } from 'components/core/tabs';
import { Button } from 'components/core/button';
import { FiArrowLeft } from 'react-icons/fi';

// Hooks
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next'; 
import { useParams, useLocation, generatePath, matchPath } from 'react-router-dom';

// Utils
import { locale, dateParse } from 'utils/locale';

// Contexts
import { StudySessionContextProvider, StudySessionContext } from 'contexts/study_session';

function Header(){
  const { t } = useTranslation("common");
  const { studySession } = useContext(StudySessionContext);

  if (!studySession || !studySession.code){
    return <Container className="py-6">
              <Skeleton className="w-screen max-w-sm h-12" />
            </Container>
  }
  return <Container className="py-3 ">
          <Button href="/study-sessions" color="inlineLink"  className="md:hidden"><FiArrowLeft className="mr-1"/>{t("study-sessions")}</Button>
          <h1 className="font-medium text-2xl">{studySession.code.name} du {locale.format("%d %B %Y")(dateParse(studySession.period))}</h1>
          <p className="text-base text-gray-500">{studySession.student.name}</p>
          </Container>
}

function StudySessionTabs(){
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation("common");
  const tabs = useMemo(()=>[
      {
        name: t("study-session-nav.overview"),
        href: generatePath("/study-sessions/:id", params),
        current: matchPath("/study-sessions/:id/", location.pathname),
      }, 
      {
        name: t("study-session-nav.settings"),
        href: generatePath("/study-sessions/:id/settings/", params),
        current: matchPath("/study-sessions/:id/settings/", location.pathname),
      }, 
    ], [location])
  return <Container className="border-b">
          <Tabs tabs={tabs}/>
          </Container>
}

export function StudySessionLayout({ children }){
  return <StudySessionContextProvider>  
          <Header/>
          <StudySessionTabs/>
          { children }
         </StudySessionContextProvider>

}