import React from 'react';

// Components
import { StudentFailedClassesListWithContext } from 'components/student/failed_subjects';

// Hooks
import {  useState } from 'react';

// Utils


export function ModifySchoolSubjectFailures({ student, setStudent }){
  const [refreshFailedClasses, setRefreshFailedClasses] = useState(0);


  return <div className="min-h-screen md:min-h-0 "> 
            <div className="space-y-12">
              <StudentFailedClassesListWithContext showYearRetained={true} student={student} setStudent={setStudent} forceRefresh={()=>setRefreshFailedClasses(d=>d+1)} shouldRefresh={refreshFailedClasses} archived={false}/>
           
              <StudentFailedClassesListWithContext student={student} setStudent={setStudent}  forceRefresh={()=>setRefreshFailedClasses(d=>d+1)} shouldRefresh={refreshFailedClasses} archived={true}/>
            </div>
         </div>
}
