import React from 'react';
import classnames from 'classnames';

// Hooks
import { useState, useMemo} from 'react'

// Components
import { SearchField } from 'components/tools/search_field'; 
import { useTranslation } from 'react-i18next';

// API
import { searchStudents } from 'api/students';
import { useSearch } from 'hooks/useSearch';

export function SearchStudents({label, value, setValue, extraParams, disabled, color, size, targetWidth}){
  const { t } = useTranslation('common');
  const [params, setParams] = useState();
  const queryParams = useMemo(()=>({...params, ...extraParams}), [extraParams, params])
  const [students, {loading}] = useSearch(searchStudents, queryParams, {limit:50, fields: ["ni", "name"]})
  return <SearchField values={students}
                      loading={loading}
                      value={value}
                      setValue={setValue}
                      disabled={disabled}
                      color={color}
                      size={size}
                      label={label}
                      placeholder={t("student")}
                      indexingField={"ni"}
                      targetWidth={targetWidth}
                      formatSelectedValue={(d=>d.name || "")}
                      formatSearchResult={((student, {selected, active})=>
                        <div>
                          <span className={classnames('block truncate', (active || selected) && 'font-semibold')}>{student.name}</span>
                          {params && params.search && student.notebook_number && student.notebook_number.includes(params.search) &&
                            <span className={classnames('block truncate text-gray-500', (active || selected) && 'font-semibold text-white')}>{t("notebook-number")}: {student.notebook_number}</span>}
                          <span className={classnames('text-gray-500 block', active&& "text-white", selected && 'font-semibold')}>{student.school && student.school.name}</span>
                        </div>)}
                     onParamsChange={setParams}
  />
}
