import React , {useCallback, useState} from 'react';

// Hooks
import { useContext } from 'react';
import { useTranslation } from 'react-i18next'; 
import { useAPI } from 'hooks/useAPI';
// Contexts
import { TeamContext } from 'contexts/team'

// API
import { updateTeam, deleteTeam } from 'api/teams';

// Components
import { Skeleton } from 'components/core/skeleton';
import { SingleLineField, ModifiableSelectField } from 'components/tools/modifiable_fields';
import { SelectSport } from 'components/tools/sports_list';
import { SelectSchool } from 'components/tools/schools_list';
import { SelectPeriods } from 'components/tools/select_period';
import { SearchCoach } from 'components/tools/search_coach';
import { Info } from 'components/core/info';
import { Error } from 'components/core/typo';
import { SelectListCronPeriods } from 'components/tools/select_cron_period';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Button } from 'components/core/button';
import { FiLock } from 'react-icons/fi';
import classnames from 'classnames';


function DeleteButton({ team }) {
  const { t } = useTranslation('common');
  const [lock, setLock] = useState(true);
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    navigate(`/teams`);
    toast.success(t("delete-team.success"), { duration: 3000 })
  }, [])

  const [, { execute, loading, error }] = useAPI(deleteTeam,
    { team: team.slug },
    { onResult: handleDelete, immediate: false })

  return <div className="relative">
    {lock && <div className="absolute z-10 bg-gray-100 rounded-md flex items-center justify-center w-full h-full text-center">
      <div>
        <h3 className="pb-3 text-lg text-gray-600">{t("delete-team.title")}</h3>
        <Button color="deleteContrast" size="lg" onClick={() => setLock(false)}><FiLock className='mr-1' /> {t("delete-team.unlock-section")}</Button>
      </div>
    </div>}
    <div className={classnames(" rounded-lg p-3 w-full space-y-3", lock ? "bg-white" : "border-2 bg-red-50  border-red-500")}>
      <div>
        <h3 className="font-medium text-red-600">{t("delete-team.title")}</h3>
        <p className="text-gray-600">{t("delete-team.description")}</p>
      </div>
      <Button disabled={lock} loading={loading} onClick={execute}  color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-team.button")}</Button>
      {error ? <Error.Text {...error} /> : null}
    </div>
  </div>
}

export function TeamSettings(){
  const {team, setTeam} = useContext(TeamContext);
  const modify = true;
  const { t } = useTranslation('common');
  const [, {error, execute}] = useAPI(updateTeam, {}, {immediate: false})
  const slug = team.slug;
  if (!team.name){
    return <Skeleton.List numElements={5}/>
  }
  return <div className="h-full min-h-screen md:min-h-0 "> 
            <h1 className="info-section">{t('general-settings')}</h1>
            <Info.Container modify={modify} className="space-y-0">
            {error? <Error.Text {...error} to={error.payload && error.payload.ni && `/coachs/${error.payload.ni}/settings`} button={t("configure")}/>: null}
                  <SingleLineField 
                    label={modify&&t("name")} 
                    modify={modify} 
                    value={team.name}
                    required 
                    setAPI={{method: updateTeam, extraParams: {slug}, name: 'name'}}
                    setValue={(name)=>{setTeam(d=>({...d, name}))}}>
                    {(val)=><Info.Field value={val} noValueLabel={`${t("none")} ${t("name").toLowerCase()}`}/>}
                  </SingleLineField>

                  <SingleLineField 
                    label={modify&&t("rseq")} 
                    modify={modify} 
                    value={team.rseq_url} 
                    setValue={(rseqUrl)=>{execute({slug, rseqUrl}); setTeam(d=>({...d, rseq_url:rseqUrl}))}}>
                    {(val)=><Info.Field value={val} noValueLabel={`${t("none")} ${t("rseq").toLowerCase()}`}/>}
                  </SingleLineField>

                <ModifiableSelectField 
                        Field={SelectSchool} 
                        disabledClear
                        label={modify&&t("school")} 
                        modify={modify} 
                        value={team.school} 
                        setValue={(school)=>{execute({slug, school: school&&school.slug}); setTeam(d=>({...d, school}))   }} >
                            {(val)=><Info.Field value={val  && val.name} 
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("school").toLowerCase()}`}
                                        label={t("school")}
                                        />}
                </ModifiableSelectField>

                <ModifiableSelectField 
                        Field={SelectPeriods} 
                        disabledClear
                        label={modify&&t("active-year")} 
                        modify={modify} 
                        value={team.active_period} 
                        setValue={(activePeriod)=>{execute({slug, activePeriod}); setTeam(d=>({...d, active_period: activePeriod}))   }} >
                            {(val)=><Info.Field value={val  && val.name} 
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("year").toLowerCase()}`}
                                        label={t("year")}
                                        />}
                </ModifiableSelectField>

                 <ModifiableSelectField 
                        Field={SelectSport} 
                        disabledClear
                        label={modify&&t("sport")} 
                        modify={modify} 
                        value={team.sport} 
                        setValue={(sport)=>{execute({slug, sport: sport&&sport.slug}); setTeam(d=>({...d, sport}))   }} >
                            {(val)=><Info.Field value={val  && val.name} 
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("sport").toLowerCase()}`}
                                        label={t("sport")}
                                        />}
                </ModifiableSelectField>

                 <ModifiableSelectField 
                        Field={SearchCoach} 
                        disabledClear
                        label={modify&&t("coach")} 
                        modify={modify} 
                        value={team.coach} 
                        setValue={(coach)=>{execute({slug, coach: coach&&coach.ni}); setTeam(d=>({...d, coach}))   }} >
                            {(val)=><Info.Field value={val  && val.name} 
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("coach").toLowerCase()}`}
                                        label={t("coach")}
                                        />}
                </ModifiableSelectField>
                
                <ModifiableSelectField 
                        Field={SearchCoach} 
                        disabledClear
                        label={modify&&t("assistant-coach")} 
                        modify={modify} 
                        value={team.assistant_coach} 
                        setValue={(coach)=>{execute({slug, assistantCoach: coach&&coach.ni}); setTeam(d=>({...d, coach}))   }} >
                            {(val)=><Info.Field value={val  && val.name} 
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("coach").toLowerCase()}`}
                                        label={t("coach")}
                                        />}
                </ModifiableSelectField>
                
               <ModifiableSelectField 
                    Field={SelectListCronPeriods} 
                    label={t("practice-weekly-period")} 
                    modify={true}
                    value={team.practice_periods} 
                    setValue={(practicePeriods)=>{execute({slug, practicePeriods}); setTeam(d=>({...d, practice_periods:practicePeriods}))   }} />
            </Info.Container>
            <br/>
            <br/>
            <Info.Container >
              <DeleteButton team={team}/>
            </Info.Container>
         </div>
}

