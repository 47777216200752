import React from 'react';


// Contexts
import { TutorContext } from 'contexts/tutor';
import { SearchNotesContextProvider } from 'contexts/search_notes';

// Hooks
import { useContext } from 'react';

// Components
import { Container } from 'components/core/container';
import { NotesCardWithContext } from 'components/notes/list';

export default function TutorNotes(){
  const { tutor } = useContext(TutorContext);
  return <SearchNotesContextProvider defaultState={{tutor: tutor.ni, orderBy: "latest_comment", orderDirection: "desc"}}>
            <Container className="">
              <NotesCardWithContext tutor={tutor}/>
            </Container>
          </SearchNotesContextProvider>
}
