import React, { useReducer, useMemo, createContext, useEffect } from "react";
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useLocation } from 'react-router-dom';

function filterReducer(state, action){
  switch (action.type){
    case 'student':
      return {...state, student: action.value};
    case 'dates':
      return {...state, ...action.value};
    case 'fromDate':
      return {...state, fromDate: action.value};
    case 'toDate':
      return {...state, toDate: action.value};
    case 'ordering':
      if (state.orderBy===action.value){
        if (state.orderDirection==='desc'){
          return {...state, orderBy: action.value, orderDirection: 'asc'};
        }
        return {...state, orderBy: null, orderDirection: null};
      }
      return {...state, orderBy: action.value, orderDirection: 'desc'};
    default:
      return {...state, [action.type]: action.value};
  }
}

const SearchTutoringStatsContext = createContext({});

const SearchTutoringStatsContextProvider = ({ children, doNotStore, storageKey, defaultState }) => {
  const location = useLocation();
  const [defaultFilters, setDefaultFilters] = useLocalStorage(storageKey || (location.pathname+'_filters'));

  const parsedDefaultFilters = useMemo(()=>{
    if (!defaultFilters || doNotStore) return;
    if (defaultFilters.fromDate) defaultFilters.fromDate = new Date(defaultFilters.fromDate);
    if (defaultFilters.toDate) defaultFilters.toDate = new Date(defaultFilters.toDate);
    if (defaultFilters.date) defaultFilters.date = new Date(defaultFilters.date);
    return defaultFilters;
  }, [])

  const [filters, dispatchFilters] = useReducer(filterReducer, parsedDefaultFilters || defaultState || {});
  
  useEffect(()=>{
    if (!doNotStore) setDefaultFilters(filters);
  }, [filters]);

  return (
    <SearchTutoringStatsContext.Provider value={{ filters, dispatchFilters }}>
      {children}
    </SearchTutoringStatsContext.Provider>
  );
};

export { SearchTutoringStatsContext, SearchTutoringStatsContextProvider };
