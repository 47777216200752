/*
  This list is intended for managing the members, not for selection.
*/

import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useContext, useState } from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Button } from 'components/core/button';
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';
import { Fragment } from 'react';
import { SlideOver } from 'components/core/slide_over';
import { InviteMember } from 'components/settings/members/invite';
import { ModifyMember } from 'components/settings/members/modify';

// API
import { searchUsers } from 'api/users';
import { useUser } from 'hooks/useUser';

function CreateButton({onChange}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("add-member-group.button-title")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <InviteMember onCompleted={()=>{setOpen(false); onChange()}}/>
          </SlideOver>
        </Fragment>
}

function ModifyButton({value, setValue, onDelete}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("modify")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <ModifyMember value={value} setValue={setValue} onDelete={(d)=>{setOpen(false); onDelete(d)}}/>
          </SlideOver>
        </Fragment>
}

export function ControlledMembersList({members, setMembers, paging, error, execute}){
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { t } = useTranslation('common');
  const [user] = useUser();
  const headers = useMemo(()=>
    [
      {
        title: t("email"),
        field: (d=>d?d.email: "email"),
        itemClassName: "!py-2 "
      },
      {
        title: t("name"),
        field: (d=>d?`${d.firstname} ${d.lastname}`: "firstname"),
        itemClassName: "py-1"
      },
      {
        title: t("role"),
        field: (d=>d? d.role : "role"),
        itemClassName: "py-1 text-gray-500"
      },
      {
        title: t("phone-verified"),
        field: (d=>d? d.phone_verified : "phone_verified"),
        format: (d=>d? t("yes") : t("no")),
        parseNull: true,
        itemClassName: (d=>d?"py-1 text-gray-500": "text-red-500 font-medium")
      },
      {
        title: t("school"),
        field: (d=>d? (d.main_school || {}).short_name : "main_school"),
        parseNull: true,
      },
      {
       field: (d=>d? ({value:d, onDelete:((d=>setMembers(current=>current.filter(e=>e.email!==d.email)))), setValue: ((next)=>setMembers(current=>current.map(e=>e.email===next.email? next:e)))}) : "Modifier"),
       FormatComponent: ModifyButton
      },
    ]
  , [])


  if (!members){
    return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2"/>
  }

  return <Info.Container modify={true} label={t("users")} sideHeader={<Fragment><CreateButton onChange={execute}/></Fragment>}>
            {members.length===0?
              <EmptyState
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-members-title")}/>
              :
              <Table headers={headers}
                    data={members}
                    indexingKey="email"
                    rowClassName={(d=>d.email===(user||{}).email?"!bg-gray-100":"")}
                    order={{by:filters.orderBy, direction:filters.orderDirection}}
                    onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
              }
              {paging&& <Paginate {...paging}/>}
              {error? <Error.Text {...error}/>: null}
          </Info.Container>
}

function MembersListWithContext(){
  const { filters } = useContext(SearchContext);
  const [members, {loading, error, paging, setResult, execute}] = useSearch(searchUsers, filters);

  return <ControlledMembersList members={members} setMembers={setResult} loading={loading} error={error} paging={paging} execute={execute}/>
}

export function MembersList(){
  return <SearchContextProvider doNotStore defaultState={{orderBy:"email", orderDirection: "asc"}}>
            <MembersListWithContext/>
        </SearchContextProvider>
}