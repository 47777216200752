import React from 'react';
import classnames from 'classnames';

// Hooks
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// Components
import { Button } from 'components/core/button';
import { FlexNav } from 'components/core/layouts/flex';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { InfoSectionHeading  } from 'components/core/typo';
import { Error } from 'components/core/typo'
import { Info } from 'components/core/info';
import { SelectWeeklyAvailabilities } from 'components/tools/select_weekly_availabilities';
import { SelectDraftSubjectPreferences, SelectDraftSchoolPreferences  } from 'components/tutors/preferences';

import { TextInput } from 'components/core/inputs'
import { CheckBox } from 'components/tools/checkbox';
// import { SelectSchool } from 'components/tools/schools_list';

// API
import { createTutor } from 'api/tutors';
import { FieldsSection } from 'components/core/fields-section';
import { SelectQuestion } from 'components/tools/select-question';


/*
  Side navigation is just a view to collapse the results
*/
function SideNavigation({draftTutor}){
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();

  const steps = useMemo(()=>{
    return [{
      name: t("Nom"), 
      description: `${draftTutor.firstname || ""} ${draftTutor.lastname || ""}`,
      completed: draftTutor.firstname && draftTutor.lastname
    },
    {
      name: t("email"), 
      description: draftTutor.email, 
      completed: draftTutor.email 
    },
    {
      name: t("subject-preferences"), 
      description: draftTutor.preferences && draftTutor.preferences.filter(d=>d.description && d.description!="none").map(d=>d.name).join(', '),
      completed: draftTutor.preferences ,
      hide: !draftTutor.preferences
    },
    {
      name: t("school-preferences"), 
      description: draftTutor.schoolPreferences && draftTutor.schoolPreferences.filter(d=>d.description && d.description!="none").map(d=>d.name).join(', '),
      completed: draftTutor.schoolPreferences ,
      hide: !draftTutor.schoolPreferences
    }
    ];
  }, [draftTutor]);

  const [tutor, request] = useAPI(createTutor, draftTutor, {immediate: false});
  useEffect(()=>{
    if (tutor){
      navigate(`/tutors/${tutor.ni}`)
    }
  }, [tutor])

  const disabled = useMemo(()=>{
    if (!draftTutor.firstname) return true;
    if (!draftTutor.lastname) return true;
    if (!draftTutor.email) return true;
    return false;
  }, [draftTutor])

  return (<div className="bg-gray-100 lg:bg-white rounded-lg p-3 lg:pl-6 lg:pt-8 ">
                    <InfoSectionHeading subtitle={t("summary")}/>
            {steps.filter(d=>!d.hide).map((d=>
                <div key={d.name}>
                  <div className={classnames("flex items-start py-2", d.warning&&"bg-yellow-50 rounded-lg")}>
                    {d.warning?
                      <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <span className="absolute h-4 w-4 rounded-full bg-yellow-200" />
                        <span className="relative block w-2 h-2 bg-yellow-600 rounded-full" />
                      </span>:
                      !d.completed?
                      <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                      </div>:
                      <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <span className="absolute h-4 w-4 rounded-full bg-green-200" />
                        <span className="relative block w-2 h-2 bg-green-600 rounded-full" />
                      </span>
                    }
                    <div>
                      <p className={classnames("ml-3 font-medium", d.warning?"text-yellow-800":d.completed? "text-green-600": "text-gray-400")}>{d.name}</p>
                      {
                        d.warning? <p className="ml-3 text-yellow-600 ">{d.warning}</p>:
                        d.description && <p className="ml-3 text-gray-800 ">{d.description}</p>
                      }
                    </div>
                  </div>
                </div>
            ))}

            <Button className="mt-6" 
                      color="active" 
                      onClick={request.execute} 
                      loading={request.loading} 
                      disabled={disabled} 
                      block 
                      size="lg">{t('create-tutor')}</Button>
            {request.error && <Error.Text {...request.error}/>}
          </div>)
}


function CreationView({draftTutor, setDraftTutor}){
  const { t } = useTranslation("common");
  return <div className="w-full space-y-8 lg:pr-12 mx-auto"> 
          <InfoSectionHeading title={t("create-tutor")}/>
            <FieldsSection title={t("tutor-settings.contact.title")}>
              <Info.Container modify={true}>
                  <FieldsSection.Grid>
                    <TextInput 
                      label={t("firstname")} 
                      required
                      onChange={(firstname)=>setDraftTutor(d=>({...d, firstname}))}/>
                    <TextInput
                      label={t("lastname")}
                      required
                      onChange={(lastname)=>setDraftTutor(d=>({...d, lastname}))}/>
                </FieldsSection.Grid>
                <TextInput 
                  label={t("phone")} 
                  required
                  onChange={(phone)=>setDraftTutor(d=>({...d, phone}))}/>
                <TextInput 
                  label={t("email")} 
                  required
                  onChange={(email)=>setDraftTutor(d=>({...d, email}))}/>
             
                <FieldsSection.Grid>
                  <TextInput 
                  label={t("street")} 
                  required
                  onChange={(street)=>setDraftTutor(d=>({...d, street}))}/>

                  <TextInput 
                  label={t("city")} 
                  required
                  onChange={(city)=>setDraftTutor(d=>({...d, city}))}/>
                </FieldsSection.Grid>

                <TextInput 
                  label={t("zipcode")} 
                  required
                  onChange={(zipcode)=>setDraftTutor(d=>({...d, zipcode}))}/>
              </Info.Container>
            </FieldsSection>
            
             <FieldsSection title={t("tutor-settings.inscription.title")}>
              <Info.Container modify={true}>
                <ModifiableSelectField 
                          Field={CheckBox} 
                          label={t("code-ethique-confirmed")} 
                          modify={true}
                          value={draftTutor.codeEthiqueDocumentReceived || false}
                          setValue={(codeEthiqueDocumentReceived)=>setDraftTutor(d=>({...d, codeEthiqueDocumentReceived}))}/>
                  <ModifiableSelectField 
                          Field={CheckBox} 
                          label={t("contrat-engagement-document-received")} 
                          modify={true}
                          value={draftTutor.contratEngagementDocumentReceived || false}
                          setValue={(contratEngagementDocumentReceived)=>setDraftTutor(d=>({...d, contratEngagementDocumentReceived}))}/>
                  <ModifiableSelectField 
                          Field={CheckBox} 
                          label={t("membership-document-received")} 
                          modify={true}
                          value={draftTutor.membershipDocumentReceived || false}
                          setValue={(membershipDocumentReceived)=>setDraftTutor(d=>({...d, membershipDocumentReceived}))}/>
                  <ModifiableSelectField 
                          Field={CheckBox} 
                          label={t("cyber-security-policy-document-received")} 
                          modify={true}
                          value={draftTutor.cyberSecurityPolicyDocumentReceived || false}
                          setValue={(cyberSecurityPolicyDocumentReceived)=>setDraftTutor(d=>({...d, cyberSecurityPolicyDocumentReceived}))}/>
                  <ModifiableSelectField 
                          Field={CheckBox} 
                          noBorder={true}
                          label={t("antecedent-judiciaire-document-received")} 
                          modify={true}
                          value={draftTutor.antecedentJudiciaireDocumentReceived || false}
                          setValue={(antecedentJudiciaireDocumentReceived)=>setDraftTutor(d=>({...d, antecedentJudiciaireDocumentReceived}))}/>
                </Info.Container>
            </FieldsSection>

             <SelectDraftSubjectPreferences 
                  value={draftTutor.preferences || []} 
                            setValue={(preferences)=>setDraftTutor(d=>({...d, preferences}))}/>
            <Info.Container modify={true}>
            <div className="space-y-6">
            <SelectQuestion value={draftTutor.studentInFrencizationClass} 
                            setValue={(studentInFrencizationClass)=>setDraftTutor(d=>({...d, studentInFrencizationClass}))}
                            id="student-in-frencization"/>
            {draftTutor.studentInFrencizationClass && draftTutor.studentInFrencizationClass.slug==="partiellement" &&
              <TextInput defaultValue={draftTutor.precisionOnStudentInFrencization} 
              onChange={(precisionOnStudentInFrencization)=>setDraftTutor(d=>({...d, precisionOnStudentInFrencization}))}
                      label={t("student-in-frencization.precise")} 
                      type="text"
                      labelClassName="text-base font-normal text-gray-800 "
                      placeholder={t("student-in-frencization.no-precision")}
                      className="bg-gray-100 transition-opacity rounded-md p-4 -translate-y-6"
                      autoComplete="off"/>}

            <SelectQuestion value={draftTutor.studentWithTroubles} 
                            setValue={(studentWithTroubles)=>setDraftTutor(d=>({...d, studentWithTroubles}))}
                            id="student-with-trouble"/>
                      {draftTutor.studentWithTroubles && draftTutor.studentWithTroubles.slug==="partiellement" &&
              <TextInput defaultValue={draftTutor.precisionOnStudentWithTroubles} 
              onChange={(precisionOnStudentWithTroubles)=>setDraftTutor(d=>({...d, precisionOnStudentWithTroubles}))}
              label={t("student-with-trouble.precise")} 
                      type="text"
                      labelClassName="text-base font-normal text-gray-800 "
                      placeholder={t("student-with-trouble.no-precision")}
                      className="bg-gray-100 transition-opacity rounded-md p-4 -translate-y-6"
                      autoComplete="off"/>}
            <SelectQuestion value={draftTutor.haveYouEverBeenTutor} 
                setValue={(haveYouEverBeenTutor)=>setDraftTutor(d=>({...d, haveYouEverBeenTutor}))}
                id="have-you-ever-been-tutor"/>

            </div>
            </Info.Container>
            
          <SelectQuestion value={draftTutor.preferedFormat} 
                          className="pt-4"
                            setValue={(preferedFormat)=>setDraftTutor(d=>({...d, preferedFormat}))}
                            id="prefered-session-format"/>


             <SelectDraftSchoolPreferences 
                  value={draftTutor.schoolPreferences || []} 
                            setValue={(schoolPreferences)=>setDraftTutor(d=>({...d, schoolPreferences}))}/>

            <SelectWeeklyAvailabilities 
              value={draftTutor.scheduleAvailabilitiesJSON}
              setValue={(scheduleAvailabilitiesJSON)=>setDraftTutor(d=>({...d, scheduleAvailabilitiesJSON, scheduleAvailabilities: scheduleAvailabilitiesJSON.map(e=>[e.from_cron, e.to_cron])}))}/>

            


          </div>
}

export function NewTutor(){
  const [draftTutor, setDraftTutor] = useState({});

  return <FlexNav breakdown="lg" className={"max-w-6xl mx-auto relative h-full"}>

            <FlexNav.Content breakdown="lg" className="py-6">
             <CreationView
                draftTutor={draftTutor} 
                setDraftTutor={setDraftTutor} 
               />
             </FlexNav.Content>
             <FlexNav.Bar breakdown="lg" className="order-last lg:border-l lg:pl-6 ">
              <div className="sticky top-0 space-y-12 max-h-full pb-12">
                <SideNavigation draftTutor={draftTutor}/>
              </div>
            </FlexNav.Bar>
            </FlexNav>
}
