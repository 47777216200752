import React from 'react';

// Components
import { Container } from 'components/core/container';
import { PairingGroupsSettings } from 'components/pairings/groups';

export default function GeneralSettings(){

  return <Container className="h-full w-full">
        <PairingGroupsSettings/>
    </Container>
}
