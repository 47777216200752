import React from 'react';

// Components
import { Pairings } from 'components/automatic-pairing/pairings';
import { AutoPairingLayout } from 'components/automatic-pairing/layout';
// Contexts

// Hooks

export default function Results(){
  return <AutoPairingLayout><Pairings/></AutoPairingLayout>
}
