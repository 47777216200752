import React from 'react';

// Components
import { TextInput } from 'components/core/inputs'
import { Button } from 'components/core/button';
import { SearchStudents } from 'components/tools/student_search';
import { Info } from 'components/core/info';
import { Error, InfoSectionHeading } from 'components/core/typo'
import { Dropdown } from 'components/core/dropdown';

// API
import { createSibling } from 'api/siblings';

// Hooks
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

export function NewSibling({sourceStudent, disabled, setValue}){

  const { t } = useTranslation('common');
  const [source, setSource] = useState(sourceStudent);
  const [target, setTarget] = useState();
  const [description, setDescription] = useState();

  const [result, {loading, error, execute}] = useAPI(createSibling, {source: source && source.ni, target: target && target.ni, description}, {immediate: false});

  useEffect(()=>{
    if (result && setValue){
      setValue(result);
    }
  }, [result])
  return <div className='p-6'>
          <InfoSectionHeading title={t('add-siblings')}/>
            <Info.Container  modify={true} className={"mt-6"}>
          <div className="space-y-3">
                <div className="flex-1">
                  <SearchStudents size="md" label={t("student")} value={source} setValue={setSource} disabled={sourceStudent} color="default"/>
                </div>
                <div className="flex-1">
                  <SearchStudents size="md" label={t("student")} value={target} setValue={setTarget} color="default"/>
                </div>
              </div>
          <TextInput placeholder={t('siblings-type')} label={t("description")} onChange={setDescription} size="md"/>

          <Button disabled={disabled || !source || !target || !description} color="active" size="lg" block loading={loading} onClick={execute}>
            {t('add-siblings')}
          </Button>
          {error? <Error.Text {...error}/>: null}
         </Info.Container>
        </div>

}


export function SelectSiblings({value, setValue}){
  const [student, setStudent] = useState();
  const [description, setDescription] = useState();
  const { t } = useTranslation('common');
  return <div className="grid grid-cols-2 gap-3 max-w-sm">
            {value&& value.map(d=>
              <Dropdown key={d.student.ni} label={d.student.name}>
                <Dropdown.Item name={d.description}/>
                <Dropdown.Item name={t("remove")} color="danger" onClick={()=>setValue(e=>e.filter(el=>el.student.ni!==d.student.ni))}/>
              </Dropdown>
              )}
            <div className="col-span-full">
              <Dropdown menuClassName="w-full whitespace-nowrap" label={t("add-siblings")} itemClassName=" p-3 space-y-3 w-80">
                <SearchStudents value={student} setValue={setStudent}/>
                <TextInput 
                  label={t("description")} 
                  placeholder={t("siblings-type")}
                  required
                  size="sm"
                  onKeyDown={(e) => { if (e.code === "Space") { e.stopPropagation(); } }}
                  onChange={setDescription}/>
                <Button color="black" block size="sm" disabled={!student} onClick={()=>{setValue(e=>e?[...e, {student, description}]: [{student, description}]); setDescription(); setStudent()}}>
                  {t("add-siblings")}
                </Button>
              </Dropdown>
            </div>
         </div>
}