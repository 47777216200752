import React from 'react';

// Components
import { Container } from 'components/core/container';
import { WeeklyListNotesCardWithContext } from 'components/notes/weekly_list';

// Hooks
import { useContext, useEffect } from 'react';
// Hooks
// Contexts
import { SearchNotesContextProvider, SearchNotesContext } from 'contexts/search_notes';
import { TutoringFollowUpContext } from 'contexts/tutoring_follow_up';
import { useUser } from 'hooks/useUser';

export function Notes(){
  const {filters} = useContext(TutoringFollowUpContext);
  const notesFilters = useContext(SearchNotesContext);
  const [user,] = useUser();
  useEffect(()=>{
    notesFilters.dispatchFilters({type:"pairingAssignedTo", value: filters.preset ? null : user?.email})
    notesFilters.dispatchFilters({type:"preset", value: filters.preset})
    notesFilters.dispatchFilters({type:"team", value: filters.team})
  }, [filters])

  return <WeeklyListNotesCardWithContext/>
}


export default function NotesWithContext(){
  const {filters} = useContext(TutoringFollowUpContext);
    const [user,] = useUser();
  return <div className="pb-6">
          <SearchNotesContextProvider defaultState={{preset: filters.preset, pairingAssignedTo: filters.preset? null : user?.email, orderBy: "latest_comment", orderDirection: "desc", hasActivePairing: true}}>
            <Container className="pt-3" >
              <Notes/>
            </Container>
          </SearchNotesContextProvider>
         </div>
}
