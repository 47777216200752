import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { Container } from 'components/core/container';
import { NewStudent } from 'components/students/create';

// Hookos

export function CreateStudentPage(){
  return <DashboardLayout>
          <Container className="h-full">
            <NewStudent/>
          </Container>
         </DashboardLayout>
}

const route =  { path: "/new-student", 
                 requireAuth: true, 
                 Element: CreateStudentPage };
export default route;
