import React from 'react';

// Components
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
// Hooks
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

// Utils
import classnames from 'classnames';

export function SelectShirtSize({ value, setValue, disabled }){
  const { t } = useTranslation("common");
  //  Values: P, M, L, XL, XXL, XXXL, XXXXL
  const options = [
    {
        name: "P",
        slug: 'P'
    },
    {
        name: "M",
        slug: 'M',
    },
    {
        name: "L",
        slug: 'L'
    },
    {
        name: "XL",
        slug: 'XL',
    },
    {
        name: "XXL",
        slug: 'XXL'
    },
    {
        name: "XXXL",
        slug: 'XXXL'
    },
    {
        name: "XXXXL",
        slug: 'XXXXL'
    }
    ];
  
  useEffect(()=>{
    if (value && !value.slug){
      // Value is a string
      const index = options.findIndex(d=>d.slug===value);
      if (index>-1){
        setValue(options[index]);
      }
    }
  }, [value])

  return <Listbox as="div" value={value} onChange={setValue} className="flex-shrink-0" disabled={disabled}>
            {({ open }) => (
              <>
                <Listbox.Label className="sr-only">{t("gender")}</Listbox.Label>
                <div className="relative">
                  <Listbox.Button className="relative inline-flex uppercase items-center px-2 py-2 text-sm font-medium text-gray-500 bg-gray-100 rounded-md disabled:bg-white whitespace-nowrap hover:bg-gray-100 sm:px-3">

                    <span
                      className={classnames(
                        value == null ? '' : 'text-gray-900',
                        'truncate ml-2 block'
                      )}
                    >
                      {value == null ? t("select") : value.name}
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute right-0 z-10 mt-1 overflow-auto text-base bg-white rounded-lg shadow w-52 max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {(options||[]).map((d) => (
                        <Listbox.Option
                          key={d.slug}
                          className={({ active }) =>
                            classnames(
                              active ? 'bg-gray-100' : 'bg-white',
                              'cursor-default select-none relative py-2 px-3'
                            )
                          }
                          value={d}
                        >
                          <div className="flex items-center">
                            <span className="block ml-1 font-medium truncate">{d.name}</span>
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
}