import React from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiMap } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch';

// API
import { searchSchoolProfiles } from 'api/utils';

export function SelectSchoolProfile({ value, setValue, disabled, disabledClear, ...props }){

  const { t } = useTranslation("common");
  const [periods] = useSearch(searchSchoolProfiles);

  return <ListSelection value={value}
                        setValue={setValue}
                        disabled={disabled}
                        values={periods}
                        label={t('school-profile')}
                        Icon={FiMap}
                        getKey={(d)=>d.slug}
                        format={(d)=>d.name}
                        withClearSelection={!disabledClear}
                        {...props}
                        />
}