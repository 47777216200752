import React from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { MdOutlineColorLens } from 'react-icons/md';

// Hooks
import { useTranslation } from 'react-i18next';

export function SelectColor({ value, setValue, disabled, disabledClear, ...props }){

  const { t } = useTranslation("common");
  const values = ['#a6cee3','#1f78b4','#b2df8a','#33a02c','#fb9a99','#e31a1c','#fdbf6f','#ff7f00','#cab2d6','#6a3d9a', "#bdbdbd", "#252525"];

  return <ListSelection value={value}
                        setValue={setValue}
                        disabled={disabled}
                        values={values}
                        label={t('color')}
                        Icon={MdOutlineColorLens}
                        getKey={(d)=>d}
                        format={(d)=><div className="w-6 h-6 rounded-md" style={{background:d}}/>}
                        optionClassName={"inline-flex"}
                        formatClassName={()=>"capitalize"}
                        optionsClassName="max-w-xs w-full left-0 min-w-0"
                        withClearSelection={!disabledClear}
                        {...props}
                        />
}