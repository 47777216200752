import React from 'react';

// Components
import { StudySessionsGrid } from 'components/follow-up/aad';

// Hooks
import { useContext } from 'react';
// Hooks
// Contexts
// import { TutoringFollowUpContext } from 'contexts/tutoring_follow_up';
import { SearchFollowUpContext } from 'contexts/search_follow_up';


export default function AADWithContext(){
  const {filters} = useContext(SearchFollowUpContext);
  return <div className="py-6">
                    <StudySessionsGrid studyCode="tutorat" numWeeks={4} weekdays={filters.weekdays} preset={filters.preset} team={filters.team} />
                    </div>
}
