import React, {useCallback} from 'react';

// Hooks
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

// Utils
import { formatBytes } from 'utils/bytes';
import { dsvFormat } from 'd3-dsv';

export function UploadFile({setValue, setError, fields}){
  const {t} = useTranslation('common');

  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader();
    reader.onabort = () => setError({code: "csv-parse.file-abort", message: "file reading aborted"});
    reader.onerror = () => setError({code: "csv-parse.file-read-error", message: "file reading failed"});
    reader.onload = () => {
      // Parse CSV file
      const data = dsvFormat(";").parse(reader.result.replace("ï»¿", ""));
      var hasError = false;
      if (data.length===0){
          setError({code: "csv-parse.file-empty", message: "file is empty"});
          setValue([]);
          hasError = true;
          return;
      }
      fields.forEach((field)=>{
        if (data[0][field]===undefined){
          setError({code: "csv-parse.missing-key", message: `missing key ${field}`, payload: {field}});
          setValue([]);
          hasError = true;
          return;
      }});

      if (!hasError){
        setValue(data.map((d,i)=>({...d, index: i+1})));
        setError();
      }
    };
    // read file contents
    acceptedFiles.forEach(file => reader.readAsBinaryString(file));
  }, []);

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({maxSize:1024*1024*512, maxFiles:1, onDrop, accept: {
        'text/csv': ['.csv']
      }});
    
  return <div className="py-3">
              <div {...getRootProps({className: 'dropzone border-2 text-gray-500 cursor-pointer border-dashed py-8 text-center'})}>
                <input {...getInputProps()} />
                <p className="max-w-md mx-auto">{t("bulk-import.dropzone.description")}</p>
              </div>
              <div className="space-y-2 mt-2">
              {acceptedFiles.map((d)=>
                <div key={d.path} className="bg-gray-100 p-3 rounded-md flex items-center justify-between">
                  <p className="font-medium">{d.path}</p>
                  <p className="ml-3 text-gray-600">{formatBytes(d.size)}</p>
                </div>)} 
              </div>
            </div>
}