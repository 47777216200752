import React, { useContext } from 'react';

// Components
import { NavBar } from 'components/automatic-pairing/navbar';
import { AutoPairingContext } from 'contexts/auto-pairing';
import { Error } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';
import { Container } from 'components/core/container';
import { useTranslation } from 'react-i18next';
import { StarIcon } from '@heroicons/react/24/solid';

function Header(){
    const { t } = useTranslation("autoPairing");
    const { result, loading } = useContext(AutoPairingContext);
    if (!result || loading){
      return <Container className="py-4">
                <Skeleton className="w-screen max-w-sm h-12" />
              </Container>
    }
    return <Container className="py-3 ">
            <div className='flex items-center'>
              {result.official && <StarIcon className="w-10 h-10 text-yellow-500 mr-2"/>}
              <div>
                <h1 className="font-medium text-2xl">{result.description || t("no-description", {tutors:result.num_tutors, students: result.num_students, pairings: result.num_matches})}</h1>
                <p className="">{t("no-description", {tutors:result.num_tutors, students: result.num_students, pairings: result.num_matches})}</p>
                </div>
              </div>
            </Container>
  }

export function AutoPairingLayout({children}){
    const {error} = useContext(AutoPairingContext)
    return <>
            <Header/>
            {error && <Error.Text {...error}/>}
            <NavBar/>
            {children}
         </>

}