import React, {createContext, useContext} from "react";

// Contexts
import { HomeContext } from "contexts/home";

// API
import { getCoordoStats } from "api/coordo"

// Hooks
import { useMemo } from 'react';
import { useAPI } from 'hooks/useAPI';
import { useState } from 'react';

const CoordoStatsContext = createContext({});

export const useCoordoStats = () => {
    const context = useContext(CoordoStatsContext);
    if (!context) {
        throw new Error('useCoordoStats must be used within a CoordoStatsContextProvider');
    }
    return context;
}

const CoordoStatsContextProvider = ({ children, fields, start:defaultStart, end:defaultEnd }) => {
    const [start, setStart] = useState(defaultStart)
    const [end, setEnd] = useState(defaultEnd)
    const [date, setDate] = useState(new Date().toISOString())
    const { filters } = useContext(HomeContext);
    const {coordo} = filters || {};
    const params = useMemo(() => ({fields, start, end, date, coordo}), [fields, start, end, date, coordo])
    const [stats,{loading, execute:reload, setResult:setStats}] = useAPI(getCoordoStats, params, {immediate: true, camelize: true})
    return (
        <CoordoStatsContext.Provider value={{stats, setStats, loading, reload, start, end, setStart, setEnd, date, setDate}}>
            {children}
        </CoordoStatsContext.Provider>
    );
};

export { CoordoStatsContext, CoordoStatsContextProvider };