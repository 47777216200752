import React from 'react';

// Components
import { WeekView } from 'components/tools/week_view';
import { Info } from 'components/core/info';
import { Button } from 'components/core/button';
import { EmptyState } from 'components/core/empty';
import { SelectCronIntervalWithConfirm } from 'components/tools/select_cron_interval';

// Hooks
import { useTranslationRegularIntervalCrons } from 'hooks/dates/crons'
import { useAbstractPeriods } from 'hooks/useAbstractPeriods';
import { useTranslation } from 'react-i18next';
// import { useMemo } from 'react';

export function SelectWeeklyAvailabilities({value, setValue}){
  const { t } = useTranslation('common');
  const [date, events] = useAbstractPeriods(value);
  const translatedCrons = useTranslationRegularIntervalCrons(value);
  return <div>
            <Info.Container
                label={t("availabilities")}
                sideHeader={<SelectCronIntervalWithConfirm onConfirmation={d=>setValue(value? [...value, ...d]: [...d])}/>}>
                {translatedCrons.length===0?
                  <EmptyState title={t("empty-state.no-availabilities-title")}/>:
                  translatedCrons.map((d,i)=>
                  <div key={`${i}-${d.baseStr}`} className="flex items-center justify-between px-2 bg-gray-100 rounded-md">
                    <Info.Field value={d.baseStr}/>
                    <Button color="delete" onClick={()=>setValue(value.filter((val, ind)=>ind!==i))}>{t("delete")}</Button>
                  </div>
                )}

            </Info.Container>
            <div className="mt-3">
              <WeekView  abstract date={date} events={events} numTicks={6} minHour={6}/>
            </div>
         </div>
}