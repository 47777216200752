import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { PortalAccessLayout } from 'components/portal-access/layout';
import { FiLock } from 'react-icons/fi';

function PortalAccessBase(){
  return <DashboardLayout>
          <PortalAccessLayout/>
         </DashboardLayout>
}

const route =  { path: "/portal-access",
                 name: "Accès" , 
                 Icon: FiLock,
                 group: 'portail',
                 isNested: true,
                 showInNavBar: true, 
                 requireAuth: true, 
                 Element: PortalAccessBase };
export default route;

import { default as tutors } from 'pages/portal-access/tutors'
import { default as coachs } from 'pages/portal-access/coachs'
import { default as teachers } from 'pages/portal-access/teachers'
import { default as partners } from 'pages/portal-access/partners'
import { default as parents } from 'pages/portal-access/parents'

export const PortalAccessPages = {root: PortalAccessBase, tutors, coachs, teachers, parents, partners}

