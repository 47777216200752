import React from 'react';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import { Button } from 'components/core/button';
import * as Sentry from '@sentry/react'

function Fallback({error}){
  return <CenterViewLayout>
          <h3 className='text-xl text-blue-500 font-mono'>{`Erreur 500`}</h3>
          <p className="my-3 text-gray-600">{`Une erreur est survenue dans l'app. L'équipe de développement est notifiée automatiquement. Vous pouvez tout de même avertir votre superviseur avec une capture d'écran si l'erreur persiste.`}</p>

          <Button color="default" size="lg" block href="/">{`Naviguer vers l'accueil`}</Button>
          <div className="p-3 text-sm text-red-600 mt-3 bg-gray-50 rounded-lg">
            <p className='text-red-800'>{`Message d'erreur technique`}</p>
            <code>{String(error)}</code>
          </div>
         </CenterViewLayout>
}

export function ErrorBoundary({children}){
  return <Sentry.ErrorBoundary fallback={Fallback}>{children}</Sentry.ErrorBoundary>
}
// export class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error) {
//     // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
//     return { hasError: true, error };
//   }

//   componentDidCatch(error) {
//     // Affiche une UI de repli
//     Sentry.captureException(error);
//   }

//   render() {
//     if (this.state.hasError) {
//       // Vous pouvez afficher n'importe quelle UI de repli.
//       return <CenterViewLayout>
//           <p className="my-3">{`Oups. Il y a eu une erreur dans l'app.`}</p>

//           <Button color="default" size="lg" block href="/">{`Retour à l'accueil`}</Button>
//           <div className="p-3 text-sm text-red-600 mt-3 bg-gray-50 rounded-lg">
//             <p>{`Message d'erreur`}</p>
//             <code>{String(this.state.error)}</code>
//           </div>
//          </CenterViewLayout>
//     }
//     return this.props.children;
//   }
// }