import React, { useMemo } from 'react';

// Components
import { Button } from 'components/core/button';
import { FiCheck, FiCheckSquare as SelectAllIcon } from 'react-icons/fi';
import { FiSquare as UnSelectAllIcon } from 'react-icons/fi';

// hooks 
import { useTranslation } from 'react-i18next';


// Utils
import { groupBy } from 'utils/array';
import classNames from 'classnames';
import { Dropdown } from 'components/core/dropdown';

export function ColumnsCategoryGrid({ possibleColumns, columns, setColumns }) {

    const { t } = useTranslation("common");

    const groupedByCategoryColumns = useMemo(() => groupBy(possibleColumns, "category"), [possibleColumns])

    const toggleCategory = (key) => {
        // Check if all columns are already included
        const allIncludes = groupedByCategoryColumns[key].every(v => columns.includes(v));
        if (allIncludes){
            setColumns([...columns.filter(v => !groupedByCategoryColumns[key].includes(v) || v.cannotRemove)])
            return;
        }
        setColumns([...columns.filter(v => !groupedByCategoryColumns[key].includes(v) || v.cannotRemove), ...groupedByCategoryColumns[key]])
    }

    return <div className='grid grid-cols-1 px-3 mb-10 md:grid-cols-3 gap-y-3'>
        {
        Object.keys(groupedByCategoryColumns).map((key) =>
            <div key={key} className="">
            <Button onClick={() => toggleCategory(key)} color="baseLink" size="md">
                    {key !== "undefined" ? t(`export-categories.${key.toLowerCase()}`) : t("export-categories.others")}
            </Button>
            {
                groupedByCategoryColumns[key].map((value, index) =>
                {
                    const isIncluded = columns.includes(value);
                return <div 
                        key={index + key} 
                        onClick={() => {isIncluded ? setColumns([...columns.filter(v => v != value)]) : setColumns([...columns, value]) }}
                        className={classNames('flex py-1 hover:cursor-pointer gap-x-2 transition-colors', 
                    isIncluded? 'text-gray-800  font-medium': "text-gray-400 hover:text-gray-700")}>
                    {isIncluded? <FiCheck className='h-5 w-5 text-orange-500'/>: <span className='h-5 w-5'/>} {t(value.title)}
                </div>
                }
                )
            }
            </div>
        )
        }
    </div>
}


export function ColumnsContainer({ columns, setColumns, possibleColumns, obligatoryColumns = [], orderColumns, setOrderColumns}) {
    const { t } = useTranslation("common");
    const selectAllColumns = () => {
        setColumns([...obligatoryColumns, ...possibleColumns])
    }
    const deselectAllColumns = () => {
        setColumns([...obligatoryColumns])
    }
    return <div className='px-6 mt-2 border-t'>
                <div className='flex items-center space-x-4 mt-3 mb-3'>
                    <Button onClick={selectAllColumns} color={'active'}  className={""} size={'md'} >
                    <SelectAllIcon className='mr-1' />{t("select-all")}
                    </Button>
                    <Button onClick={deselectAllColumns} className={""} color={'gray'}size={'md'}>
                    <UnSelectAllIcon className='mr-1'/>{t("unselect-all")}
                    </Button>
                    <Dropdown label={t("order-by")}>
                        <Dropdown.Item onClick={()=>setOrderColumns(false)} name={t("order-by-how-it-comes")} color={!orderColumns? 'active': 'default'}/>
                        <Dropdown.Item onClick={()=>setOrderColumns(true)} name={t("order-by-name")} color={orderColumns? 'active': 'default'}/>
                    </Dropdown>
                </div>
                <ColumnsCategoryGrid possibleColumns={possibleColumns} columns={columns} setColumns={setColumns} />
            </div>
}
