import React, { useMemo } from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { SingleLineField} from 'components/tools/modifiable_fields';
import { SelectColor } from 'components/tools/select_color';
import { Button } from 'components/core/button';
import { Error } from 'components/core/typo'

// Hooks
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// API
import { deleteDocumentGroup, searchDocumentGroupPeriods, updateDocumentGroup } from 'api/document_groups';
import { useSearch } from 'hooks/useSearch';

function DeleteButton({value, onDelete}){
  const { t } = useTranslation('common');
  const [, {execute, loading, error}] = useAPI(deleteDocumentGroup, 
                                            {code: value.code}, 
                                            {onResult: (()=>onDelete(value)), immediate:false})

  return <div className="border-2 border-red-500 rounded-lg p-3 w-full space-y-3">
          <div>
            <h3 className="font-medium text-red-600">{t("delete-document-group.title")}</h3>
            <p className="text-gray-500">{t("delete-document-group.description")}</p>
          </div>
          <Button loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-document-group.button")}</Button>
          {error? <Error.Text {...error}/>: null}
         </div>
}

function ListPeriods({value}){
  const {t} = useTranslation('common');
  const params = useMemo(()=>({group: value.code}), [value])
  const [periods] = useSearch(searchDocumentGroupPeriods, params);

  // Group periods by d.period.slug
  const groupedPeriods = useMemo(()=>{
    if (!periods) return null;
    return periods.reduce((acc, period)=>{
      if (!acc[period.period.slug]) acc[period.period.slug] = [];
      acc[period.period.slug].push(period);
      return acc;
    }, {})
  }, [periods])

  if (!periods) return null;
  return <div className='bg-gray-100 rounded-md p-3'>
    <p className="font-medium border-b mb-2 pb-2">{t("document-groups-periods")}</p>
    <div className="space-y-3">
      {Object.keys(groupedPeriods).sort().reverse().map((period, index)=>(
        <div key={index}>
          <h3 className="font-medium text-orange-500">{period}</h3>
          <ul className="list-disc list-inside ">
            {groupedPeriods[period].map((period, index)=>(
              <div className="flex items-center justify-between" key={index}>
                <span>{period.school.name}</span>
                <span>{period.should_be_received_at}</span>
              </div>
            ))}
          </ul>
         </div>
      ))}
      {periods.length === 0? <p>{t("document-groups-periods-empty")}</p>: null  }
    </div>
  </div>
}
export function ModifyDocumentGroup({value, setValue, onDelete}){
  const {t} = useTranslation('common');

  return <div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("modify-document-groups.title")}</div></span>}
                    description={t("modify-document-groups.description")} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
                 <SingleLineField 
                  label={t("add-document-group.name-title")}
                    modify={true} 
                    value={value.name} 
                    setValue={(name)=>{setValue({...value, name}); updateDocumentGroup({code: value.code, name})}}/>
                    <SingleLineField 
                  label={t("add-document-group.initials-title")}
                    modify={true} 
                    value={value.initials} 
                    setValue={(initials)=>{setValue({...value, initials}); updateDocumentGroup({code: value.code, initials})}}/>
              
                <SelectColor
                  label={t("color")}
                  value={value.color}
                  size="lg"
                  disabledClear={true}
                  setValue={(color)=>{setValue({...value, color}); updateDocumentGroup({code: value.code, color})}}/>

                  <ListPeriods value={value}/>
                <DeleteButton value={value} onDelete={onDelete}/>
              </CardContainer>
            </div>


}