import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { SelectNoteGroup } from 'components/tools/select_note_group';
import { SelectColor } from 'components/tools/select_color';
import { TextInput } from 'components/core/inputs';
import { Error } from 'components/core/typo'
import { Button } from 'components/core/button';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useAPI } from 'hooks/useAPI';

// API
import { createNoteCode } from 'api/note_codes';

export function CreateNoteCode({onCompleted}){
  const [draftNoteCode, setDraftNoteCode] = useState({});
  const [noteCode, {loading, execute, error}] = useAPI(createNoteCode, draftNoteCode, {immediate:false});
  const {t} = useTranslation('common');

  useEffect(()=>{
    if (noteCode) onCompleted(noteCode);
  }, [noteCode])

  return<div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("add-note-code.title")}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
                <TextInput
                  label={t("add-note-code.name-title")}
                  description={t("add-note-code.name-description")}
                  onChange={(name)=>setDraftNoteCode(d=>({...d, name}))}
                />
                <TextInput
                  label={t("add-note-code.initials-title")}
                  description={t("add-note-code.initials-description")}
                  onChange={(initials)=>setDraftNoteCode(d=>({...d, initials}))}
                />
                <div className="bg-gray-100 rounded-md p-3 space-y-3">
                  <SelectNoteGroup 
                    label={t("group")}
                    value={draftNoteCode.group}
                    size="lg"
                    color="white"
                    setValue={(group)=>setDraftNoteCode(d=>({...d, group}))}
                  />
                  <TextInput
                    placeholder={t("add-note-code.custom-group-title")}
                    description={t("add-note-code.custom-group-description")}
                    onChange={(group)=>setDraftNoteCode(d=>({...d, group: {group} }))}
                  />
                </div>

                <TextInput
                    label={t("add-note-code.special.title")}
                    placeholder={t("add-note-code.special.title")}
                    description={t("add-note-code.special.description")}
                    onChange={(special)=>setDraftNoteCode(d=>({...d, special }))}
                  />
                <SelectColor
                  label={t("color")}
                  value={draftNoteCode.color}
                  size="lg"
                  setValue={(color)=>setDraftNoteCode(d=>({...d, color}))}
                />
                
                <Button onClick={execute} 
                      disabled={!draftNoteCode.name || !draftNoteCode.group || !draftNoteCode.color} 
                      loading={loading} 
                      block 
                      size="lg" 
                      color="active">{t("add-note-code.button")}</Button>
                {error&& <Error.Text {...error}/>}
              </CardContainer>
            </div>


}