import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useContext, useState, useMemo } from 'react';
import { useSearch } from 'hooks/useSearch';

// Components
import { Fragment } from 'react';
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Table } from 'components/core/table'
import { Info } from 'components/core/info'
import { SlideOver } from 'components/core/slide_over';
import { CreateSchoolClass } from 'components/school-classes/create';
import { ModifySchoolClass } from 'components/school-classes/modify';
import { Button } from 'components/core/button';

// Contexts
import { TableOrderingContext, TableOrderingContextProvider } from 'contexts/table_ordering';

// API
import { searchSchoolClasses } from 'api/utils';

function CreateButton({onChange}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("add-school-class")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <CreateSchoolClass onCompleted={()=>{setOpen(false); onChange()}}/>
          </SlideOver>
        </Fragment>
}

function ModifyButton({value, setValue}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("modify")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <ModifySchoolClass value={value} setValue={setValue}/>
          </SlideOver>
        </Fragment>
}

function Empty(){
  const { t } = useTranslation('common');
  return <EmptyState
            className="flex flex-col justify-center h-full"
            title={t("empty-state.no-school-classes-title")}>
         </EmptyState>
}

function ClassesTable({schoolClasses, setSchoolClasses, execute, paging}){
  const { t } = useTranslation('common');
  const {filters, dispatchFilters } = useContext(TableOrderingContext)
  const headers = useMemo(()=>
    [
      {
        title: t("name"),
        field: (d=>d?d.name: "name"),
        itemClassName: "!py-1"
      },
      {
        title: t("school-subject"),
        field: (d=>d?d.subject && d.subject.name: "subject"),
        itemClassName: "text-gray-500"
      },
    {
     field: (d=>d? ({value:d, setValue: ((next)=>setSchoolClasses(current=>current.map(e=>e.slug===next.slug? next:e)))}) : "Modifier"),
     itemClassName: "text-right text-gray-600 py-2",
     FormatComponent: ModifyButton
    },
    ]
  , [])

  return <Fragment >
            <Info.Container
                className="px-3 pt-2 pb-3"
                label={t("school-classes")}
                modify={true}
                sideHeader={<CreateButton onChange={execute}/>}
                description={t("school-classes-description")}
                >
                {schoolClasses.length===0?
                  <Info.Container className="px-3 pt-2 pb-3" label={t("school-classes")}>
                  <Empty/>
                </Info.Container>:
              <Table headers={headers}
              data={schoolClasses}
              indexingKey={"slug"}
              order={{by:filters.orderBy, direction:filters.orderDirection}}
              onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>}
            </Info.Container>
            {paging&& <Paginate {...paging}/>}
          </Fragment>
}

export function ControlledClassesList({schoolClasses, setSchoolClasses, execute, paging, error}){

  if (!schoolClasses){
    return <Skeleton.List numElements={1} itemClassName="h-32" className="space-y-2"/>
  }

  return <div className="">
             <ClassesTable execute={execute} setSchoolClasses={setSchoolClasses} schoolClasses={schoolClasses} paging={paging}/>

              {error? <Error.Text className="mt-3" {...error}/>: null}
          </div>
}


export function ClassesListWithContext(){
  const { filters} = useContext(TableOrderingContext)
  const [schoolClasses, {loading, setResult, error, execute, page, setPage, paging}] = useSearch(searchSchoolClasses, filters, {limit:100});

  return <ControlledClassesList schoolClasses={schoolClasses} setSchoolClasses={setResult} execute={execute} paging={{...paging, page, setPage}} loading={loading} error={error}/>
}

export function ClassesList(){
  return <TableOrderingContextProvider
                defaultState={{orderBy: "name", orderDirection: "desc"}}>
          <ClassesListWithContext/>
          </TableOrderingContextProvider>
}


