import React, { useState } from 'react';
import classnames from 'classnames';
import { Button } from '../button';
import { FiChevronsRight, FiChevronsLeft } from 'react-icons/fi';

function Left({ children, enableCollapse, defaultCollapse, className }){
  const [collapse, setCollapse] = useState(defaultCollapse|| false);
  return <div className={classnames("w-full md:max-w-xs ", collapse?"!w-12 overflow-hidden": "overflow-auto", className)}>
          {enableCollapse && <Button onClick={()=>setCollapse(!collapse)} color="gray" size="smToolbarIcon" className={classnames("m-3")}>
            {collapse? <FiChevronsRight/>: <FiChevronsLeft/>}
          </Button>}
          <div className={classnames("", collapse? "opacity-0 ": 'opacity-100')}>
            {children}
          </div>
         </div>
}

function Content({ children, className }){
  return <div className={classnames("w-full md:flex-1 md:flex-auto md:overflow-auto", className)}>
          {children}
         </div>
}

export function MultiColumn({ children, className }){
  return  <div className={classnames("h-full flex-1 md:overflow-hidden flex", className)}>
            {children}
           </div>
}
MultiColumn.Content = Content;
MultiColumn.Left = Left;


