import React from 'react';

// Components
import { CoachInfo } from 'components/coachs/info';
import { CoachStats } from 'components/coachs/stats'
import { FullFlexNav } from 'components/core/layouts/flex';

// Hooks
import { useContext } from 'react';

// Contexts
import { CoachContext } from 'contexts/coach';

export default function Overview(){
  const { coach} = useContext(CoachContext);
  return <FullFlexNav className="p-6 block xl:flex" >
          <FullFlexNav.Bar >
            <CoachInfo coach={coach}/>
          </FullFlexNav.Bar>
          <FullFlexNav.Content>
            <CoachStats coach={coach}/>
          </FullFlexNav.Content>
         </FullFlexNav>
}
