import React, { useReducer, useMemo, createContext, useEffect } from "react";
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useLocation } from 'react-router-dom';

function filterReducer(state, action) {
    switch (action.type) {
        case 'search':
            // action-> {type:search, value: 'john'}
            return { ...state, search: action.value };
        case 'schoolYear':
            return { ...state, schoolYear: action.value };
        case 'school':
            return { ...state, school: action.value };
        case 'student':
            return { ...state, student: action.value };
        case 'availableForPairing':
            return { ...state, availableForPairing: action.value };
        case 'leftService':
            return { ...state, leftService: action.value };
        case 'team':
            return { ...state, team: action.value };
        case 'tutor':
            return { ...state, tutor: action.value };
        case 'pairing':
            return { ...state, pairing: action.value };
        case 'date':
            return { ...state, date: action.value };
        case 'dates':
            return { ...state, ...action.value };
        case 'fromDate':
            return { ...state, fromDate: action.value };
        case 'toDate':
            return { ...state, toDate: action.value };
        case 'includeNotes':
            return { ...state, includeNotes: action.value };
        case 'active':
            return { ...state, active: action.value };
        case 'preset':
            return { ...state, preset: action.value };
        case 'coordo':
            return { ...state, coordo: action.value };
        case 'studyCode':
            return { ...state, studyCode: action.value };
        case 'ordering':
            if (state.orderBy === action.value) {
                if (state.orderDirection === 'desc') {
                    return { ...state, orderBy: action.value, orderDirection: 'asc' };
                }
                return { ...state, orderBy: null, orderDirection: null };
            }
            return { ...state, orderBy: action.value, orderDirection: 'desc' };
        default:
            return state
    }
}

const HomeContext = createContext({});

const HomeContextProvider = ({ children, doNotStore, storageKey, defaultState }) => {
    const location = useLocation();
    const [defaultFilters, setDefaultFilters] = useLocalStorage(storageKey || (location.pathname + '_filters'));

    const parsedDefaultFilters = useMemo(() => {
        if (!defaultFilters || doNotStore) return;
        return defaultFilters;
    }, [])

    const [filters, dispatchFilters] = useReducer(filterReducer, parsedDefaultFilters || defaultState || {});

    useEffect(() => {
        if (!doNotStore) setDefaultFilters(filters);
    }, [filters]);

    return (
        <HomeContext.Provider value={{ filters, dispatchFilters }}>
            {children}
        </HomeContext.Provider>
    );
};

export { HomeContext, HomeContextProvider };