/* eslint-disable no-unused-vars */
import React from 'react';

// Hooks 
import { useAPI } from 'hooks/useAPI';
import { useState, useEffect,  useMemo } from 'react'
import { useTranslation } from 'react-i18next';

// Utils
import { locale } from 'utils/locale';

// Components
import { BsFlagFill } from 'react-icons/bs';
import { Button } from 'components/core/button';
import { Tooltip } from 'components/core/tooltip';
import { EmptyState } from 'components/core/empty';

// API
import { toggleVerificationFlag } from 'api/students';
import toast from 'react-hot-toast';

export function ControlledVerificationFlag({ ni, value, setValue, position="left", readonly=false}){
  const {t} = useTranslation('common');
  const params = useMemo(()=>({ni}), [ni])
  
  const [, {loading, error,  execute}] = useAPI(toggleVerificationFlag, params, {immediate: false, onResult: setValue})

  useEffect(()=>{
    if(error && error.code){
      toast.error(t(error.code || error.detail, {...error.payload}), {duration: 5000});
    }
    return (()=>{});
  }, [error])

  return <Tooltip  position={position} 
                content={value? t("flag-is-active", {date:  locale.format("%d %B")(new Date(value))}): readonly?t("flag-is-inactive")  :t("click-to-activate-flag") }>
          <span className="relative ">
            <Button color={value? "activeFlag": "flag"} 
                    size="flag" 
                    className="h-6 w-6 !opacity-100"
                    disabled={readonly}
                    loading={loading}
                    onClick={execute}><BsFlagFill/></Button>
          </span>
        </Tooltip>
}


export function VerificationFlag({ ni, study_session_verification_flag, onChange, position="left" }){
  const [value, setValue] = useState(study_session_verification_flag? new Date(study_session_verification_flag): null);
  return <ControlledVerificationFlag ni={ni} 
                            value={value} 
                            setValue={(result)=>{
                              if (onChange) onChange(result); 
                              setValue(result.study_session_verification_flag? new Date(result.study_session_verification_flag): null)
                            }} 
                            position={position}/>

}

export function VerificationFlagCard({student, setStudent}){
  const { t } = useTranslation("common");
  if (!student) return null;
  if (student.study_session_verification_flag){
    return <EmptyState 
                  color={"warning"}
                  className="mt-6"
                  title={t("student-has-a-verification-flag-raised", {student: student && student.name})}
                  description={t("student-has-a-verification-flag-raised-description", {date: locale.format("%d %B")(new Date(student.study_session_verification_flag)), student: student && student.name})}>
                <VerificationFlag {...student} onChange={setStudent}/>
            </EmptyState>}
  return null;
}