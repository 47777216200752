/* eslint-disable no-unused-vars */
import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useMemo, useCallback } from 'react';
import { useSearchParams } from "react-router-dom";
import { useAPI } from 'hooks/useAPI';
import { useUser } from 'hooks/useUser';
import { FiShield, FiRadio } from 'react-icons/fi';

// API
import { answerSMSChallenge, resendChallenge } from 'api/auth';

// Components
import { Button } from 'components/core/button'
import { TextInput } from 'components/core/inputs'
import { Error, RawTextWarning } from 'components/core/typo'
import { Navigate } from 'react-router-dom';

export function SMSChallengeForm(){
  const { t } = useTranslation(["auth"]);
  const [answer, setAnswer] = useState();
  const [hasResendCode, setHasResendCode] = useState();
  const [, setUser] = useUser();
  const [searchParams] = useSearchParams();
  const params = useMemo(()=>({token: searchParams.get("token"), answer}), [answer, searchParams]);
  const [result, {loading, error, execute}] = useAPI(answerSMSChallenge, params,
                                                  {onResult:setUser,
                                                  immediate: false});
  const [, resend] = useAPI(resendChallenge, {token: searchParams.get("token")}, {onResult: setHasResendCode,immediate: false})


  const onSubmit = useCallback((e)=>{
    if (e) e.preventDefault();
    execute();
  }, [execute]);

  if (result){
    if (searchParams.has("ref")){
      return <Navigate to={searchParams.get("ref")} state={result}/>
    }
    return <Navigate to="/" state={result}/>
  }
  return <div className="space-y-6">
          <div className='relative h-36 w-36 bg-orange-100 text-orange-700 p-5 rounded-full'>
                <FiRadio className="absolute w-12 h-12 ml-7 mt-6"/>
                <FiShield className="w-full h-full"/>
            </div>
          <div>
            <h1 className="text-lg text-gray-800 font-medium">{t('sms-challenge.title')}</h1>
            <p className="text-base text-gray-500">{t('sms-challenge.description')}</p>
          </div>
          <Button color="inlineLink" size="link" loading={resend?.loading} onClick={resend?.execute}>
            {t("sms-challenge.resend-code")} 
          </Button>
          {hasResendCode? <RawTextWarning>{t("sms-challenge.resend-code-success")}</RawTextWarning>: null}
          <form onSubmit={onSubmit} disabled={!answer}>
            <TextInput 
            label={t("sms-challenge.label")}
            autoComplete="none"
            type="text"
            required
            placeholder="1234567"
            onChange={setAnswer}/>
          <Button disabled={!answer} color="active" block size="xl" className={"mt-3"} loading={loading}  type="submit">
            {t("sms-challenge.submit")} 
          </Button>
          </form>
          <Button color="inlineLink" size="link" href="/login">
            {t("sms-challenge.back-to-login")} 
          </Button>
          {resend.error? <Error.Text {...resend.error}/>: null}
          {error? <Error.Text {...error}/>: null}
         </div>
}
