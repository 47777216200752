import React, { useState } from 'react';

// Components
// import { Availabilities } from 'components/student/availabilities';
import { StudentPairingsList } from 'components/student/pairings';
import { NewPairing } from 'components/tools/new_pairing';
import { useTranslation } from 'react-i18next';

// Hooks
import { useContext } from 'react';

// Contexts
import { StudentContext } from 'contexts/student';
import { InfoSectionHeading } from 'components/core/typo';
import { Button } from 'components/core/button';
import { Modal } from 'components/core/modal';

export default function PagePairingSettings(){
  const { t } = useTranslation('common');
  const { student } = useContext(StudentContext);
  const [open, setOpen] = useState(false);

  return <div>
          <InfoSectionHeading title={t("pairings")}>
            <Button color="active" size="md" onClick={()=>setOpen(true)}>
              {t("add-pairing")}
            </Button>
            </InfoSectionHeading>
          <div className="w-full pb-6 space-y-16">
            <StudentPairingsList/>
            <Modal open={open} setOpen={setOpen} size='md' className="h-[96vh] max-h-[96vh] overflow-hidden !p-0">
              {student&& student.name && <NewPairing defaultStudent={student}/>}
            </Modal>
          </div>
          </div>
}
