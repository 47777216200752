import React from 'react';

// Hooks
import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next'; 

// Components
import { Button } from 'components/core/button';
import { Dropdown } from 'components/core/dropdown';

// Utils
import { format } from 'd3-format';
const days = ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"];
const fullDays = ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"];

function SelectCronPeriodContent({value, setValue, onConfirm}){
  const {t} = useTranslation("common");
  const [internal, setInternal] = useState(value);
  const parsedValue = useMemo(()=>{
    if (!value) return {};
    const b = value.split(" ");
    return {minutes: parseInt(b[0]), hours: parseInt(b[1]), weekDay: parseInt(b[4])};
  }, [value])

  const handleChange = ({minutes, hours, weekday})=>{
    const d = `${minutes || parsedValue.minutes || 0} ${hours || parsedValue.hours || 12} * * ${weekday || parsedValue.weekDay || 5}`;
    setValue(d);
    setInternal(d);
  };

  return <div className="w-full max-w-sm mx-auto space-y-3">
          <div className="flex items-center justify-evenly space-x-2">
            {days.map((d,i)=><Button 
                    key={d}
                    onClick={()=>handleChange({weekday: i})}
                    color={parsedValue && i===parsedValue.weekDay? "active": "gray"}
                    block
                    className="uppercase "
                     size="sphere">{d[0]}</Button>)}
          </div>
          <div className="flex items-center p-3 bg-gray-100 rounded-lg justify-between">
            <label>Heure </label>
            <input className="input-time" 
                  type="time" 
                  id="startAt"
                  name="startAt" 
                  min="06:00" 
                  max="22:00" 
                  defaultValue={value &&`${format("02")(parsedValue.hours)}:${format("02")(parsedValue.minutes)}`}
                  onChange={(e)=>handleChange({minutes: e.currentTarget.value.split(":")[1], hours: e.currentTarget.value.split(":")[0]})} required/>
          </div>
          {onConfirm && <Button color="black" block size="sm" disabled={!value} onClick={()=>onConfirm(internal)}>{t("confirm")}</Button>}
         </div>
}

export function SelectCronPeriod({value, setValue, addPeriod, onConfirm}){
  const {t} = useTranslation("common");

  const label = useMemo(()=>{
    if (!value) return addPeriod? t("add-period"):t("no-period-selected")
    const b = value.split(" ");
    const v = {minutes: parseInt(b[0]), hours: parseInt(b[1]), weekDay: parseInt(b[4])};
    return `${fullDays[v.weekDay]} à ${format("02")(v.hours)}:${format("02")(v.minutes)}`
  }, [value])

  return <Dropdown label={onConfirm?t("add-period"): label} menuButtonClassName="whitespace-nowrap">
          <div className="p-3">
            <SelectCronPeriodContent value={value} setValue={setValue} onConfirm={onConfirm}/>
          </div>
         </Dropdown>
}

export function SelectListCronPeriods({value, setValue}){
  const {t} = useTranslation("common");

  const [nextVal, setNextVal] = useState();
  const handleChange = useCallback((next, index)=>{
    setValue(d=>{
      if (!d) return [next];
      if (index==null) return [...d, next];
      const _next = [...d];
      _next[index] = next;
      return _next;
    })
  }, []);

  return <div className="grid grid-cols-2 gap-3">
            {value && value.map((period, index)=>{
            const b = period.split(" ");
            const v = {minutes: parseInt(b[0]), hours: parseInt(b[1]), weekDay: parseInt(b[4])};
              return <Dropdown key={index} label={`${fullDays[v.weekDay]} à ${format("02")(v.hours)}:${format("02")(v.minutes)}`}>
                        <div className="p-3">
                          <SelectCronPeriodContent value={period} setValue={(next)=>handleChange(next, index)}/>
                        </div>
                        <Dropdown.Item name={t("delete")} color="danger" onClick={()=>setValue(d=>d.filter((e,i)=>i!==index))} />
                       </Dropdown>
            })}
            <SelectCronPeriod addPeriod value={nextVal} setValue={setNextVal} onConfirm={(d)=>{handleChange(d); setNextVal()}}/>
         </div>
}