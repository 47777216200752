import React from 'react';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import { Button } from 'components/core/button';

export function Error404(){
  return <CenterViewLayout>
          <p className="my-3">{`Oups. Erreur 404. La page n'existe pas ou n'a encore été conçue.`}</p>
          <Button color="default" size="lg" block href="/">{`Retour à l'accueil`}</Button>
         </CenterViewLayout>
}