import React from 'react';

// Components
import { DailyEventsView } from 'components/tools/daily_events';
import { Error } from 'components/core/typo';
import { FilterBar } from 'components/tools/filter_bar';
import { SlideOver } from 'components/core/slide_over';
import { EventCard } from 'components/events/card'
import { DailyAttendance } from 'components/coordo-stats/daily-attendance';
import { CoordoStatsContextProvider } from 'contexts/coordo-stats';

// API
import { searchEvents } from 'api/events';
import { searchSportTeams } from 'api/teams';

//Utils
// import { datesAreInInterval }  from 'utils/date_compare';
import { timeDays, timeDay, timeMonday} from 'd3-time';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useWeek } from 'hooks/dates/useWeek';
import { useState, useMemo, useContext } from 'react';
import { SearchContext, SearchContextProvider } from 'contexts/search';
import { useCronsToDates } from 'hooks/dates/useCronToDates';
import classnames from 'classnames';
import { merge } from 'd3-array';
import { isDate } from 'utils/var_check';
import { locale, parseWithTimeZone } from 'utils/locale';
import { FiCheck, FiSend, FiX } from 'react-icons/fi';
import { useCoordoStats } from 'contexts/coordo-stats';
// import { useTranslation } from 'react-i18next';
import { useUser } from 'hooks/useUser';
import classNames from 'classnames';
import { format } from 'd3';
import { useTranslation } from 'react-i18next';

function EventCell({ date, team, event_type, notes, transmitted, onClick }) {
    // const {t} = useTranslation();
    return <div className={classnames("bg-white rounded-r-sm  px-2 py-1", onClick && "cursor-pointer hover:bg-gray-50")}
        onClick={onClick}
        style={{ background: event_type.color + "40", borderLeft: `4px solid ${event_type.color}` }}>
        <div className="gap-1 flex justify-between w-full">
            <div className='flex items-center space-x-2 text-right'>
                <p className="text-gray-800 font-medium">{locale.format("%H:%M")(isDate(date) ? date : parseWithTimeZone(date))}</p>
                {transmitted &&
                    <p className="text-black flex items-center whitespace-nowrap text-sm"><FiSend className='mr-1' /></p>
                }
            </div>
            {/* {event_type && <p className="text-gray-500 ">{event_type.name}</p>} */}

            <p className="font-medium text-right break-all">{team.name}</p>

        </div>
        {notes && <p className="text-xs text-gray-700 line-clamp-3 ">{notes}</p>}


    </div>
}

export function WeeklyViewWithContext({ Context = SearchContext } = {}) {
    const [user,] = useUser();
    const userRole = user?.role;
    const authorizedCoordoFilterRoles = ["Direction", "Développeur", "RST", "Responsable"]
    const hasCoordoFilter = userRole && authorizedCoordoFilterRoles.includes(userRole);
    
    const { filters } = useContext(Context);
    const [date, setDate] = useState(timeMonday.floor(new Date()));
    const [open, setOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState();
    const windowDates = useWeek(date, { floorInput: true, offsetInput: -2, weeks: 4 });
    const filterWithDates = useMemo(() => ({ ...filters, ...windowDates }), [filters, windowDates])
    const [allEvents, { loading, error }] = useSearch(searchEvents, filterWithDates, { limit: 1000 });
    const {setStart, start, end, setEnd} = useCoordoStats();
    // Hopefully this won't break things
    // useEffect(()=>{
    //   if (!datesAreInInterval(windowDates.fromDate, filters.fromDate, {step: 'day'})){
    //     dispatchFilters({type: 'dates', value: {...windowDates, date}})
    //   }
    // }, [date, windowDates]);
    const events = useMemo(() => {
        if (!allEvents) return [];
        return allEvents.map(d => {
            return {
                date: parseWithTimeZone(d.date),
                color: d.event_type.color,
                content: d,
                isPrio: 2
            }
        })
    }, [allEvents]);
    
    const numEventTransmitted = useMemo(() => {
        if (!events) return 0;
        return events.filter(d => d.content.transmitted && d.date>=new Date(start) && d.date<timeDay.offset(new Date(end), 1) ).length
    }, [events])

    const numEventUntansmitted = useMemo(() => {
        if (!events) return 0;
        return events.filter(d => !d.content.transmitted && d.date>=new Date(start) && d.date<timeDay.offset(new Date(end), 1)).length
    }, [events])

    // Search team practices
    const teamFilters = useMemo(() => filters, [filters.team, filters.preset])
    const [teams,] = useSearch(searchSportTeams, teamFilters);
    const cronParams = useMemo(() => {
        if (!teams) return [];
        return merge(teams.map(d => {
            return (d.practice_periods || []).map(() => ({ ...windowDates, content: { noClick: true, team: d, event_type: { name: "Pratique", color: "#3182bd" } } }))
        }))
    }, [teams, windowDates])
    const practiceEvents = useCronsToDates(teams && merge(teams.map(d => d.practice_periods || [])), cronParams);
    return <div className="w-full h-full">
        <DailyEventsView date={date}
            onDateChange={setDate}
            events={[...(events || []), ...(practiceEvents || [])]}
            setStartDate={setStart}
            sortBy={(d)=>d.isPrio}
            setEndDate={setEnd}
            loading={loading}
            layout="full"
            numDays={7}
            dayOffset={-2}
            compact
            floorToMonday={false}
            className="w-full h-full min-h-full"
            rightHeader={<FilterBar coordo={hasCoordoFilter} preset team Context={Context} />}
            emptyPlaceholder="empty-state.no-event-title"
            EventComponent={(d) => <EventCell {...d} onClick={d.noClick ? null : () => { setOpen(true); setSelectedEvent(d) }} />}
            DailyComponent={({ ...props }) => <DailyAttendance {...props} />}
        />
        {error ? <Error.Text className="mt-3" {...error} /> : null}
        <SlideOver size="xl3" open={open} setOpen={setOpen}>
            <EventCard event={selectedEvent} setEvent={setSelectedEvent} defaultModify={false} />
        </SlideOver>
        <TransmissionProcessBar numEventTransmitted={numEventTransmitted} numEventUntansmitted={numEventUntansmitted} />
    </div>
}

function TransmissionProcessBar({numEventTransmitted, numEventUntansmitted}){
    const {t} = useTranslation();
    const ratio = (numEventTransmitted+numEventUntansmitted)>0? numEventTransmitted/(numEventTransmitted+numEventUntansmitted): 1;
    return <div className={classNames("flex whitespace-nowrap w-full items-center max-w-md gap-4 mt-1 border rounded-md px-3 py-2", ratio<1.0? "bg-red-100 border border-red-600": "bg-gray-50" )}>
            <p className = "text-black font-medium">{t("transmitted-events")}</p>
            
            <p className="text-gray-800 font-medium">{format(".0%")(ratio)}</p>
            <p className="text-gray-500 whitespace-nowrap">
                <span >{numEventTransmitted}<FiCheck className='inline text-green-500 mb-1 ml-1'/></span>
                <span className='ml-4'>{numEventUntansmitted} <FiX className='inline text-red-500 mb-1'/></span>
        </p>
        
        {/* Add progress bar */}
        <div className='h-4 w-full bg-gray-300 rounded-md'>
            <div className={classNames("rounded-md h-4", ratio>0.5? "bg-green-500": "bg-red-500")} style={{width: `${ratio?ratio*100: 3}%`}}></div>
        </div>

    </div>
}

export function WeeklyView({ Context }) {
    const date=timeMonday.floor(new Date());
    const dayOffset = -2;
    const numDays = 7;
    
    const days = timeDays(timeDay.offset(date, dayOffset), timeDay.offset(timeDay.offset(date, dayOffset),  numDays));
    if (Context) {
        return <CoordoStatsContextProvider 
            fields="tutoring_attendance"
            start={new Date(days[0]).toISOString()}
            end={new Date(days[days.length-1]).toISOString()}
        >
                <WeeklyViewWithContext Context={Context} />
        </CoordoStatsContextProvider>
    }
    return <SearchContextProvider storageKey="events-weekly-view">
        <CoordoStatsContextProvider fields="tutoring_attendance" start={new Date(days[0]).toISOString()} end={new Date(days[days.length-1]).toISOString()}>
            <WeeklyViewWithContext Context={Context} />
        </CoordoStatsContextProvider>
    </SearchContextProvider>
}
