import React from 'react';
import classnames from 'classnames';

// Components
import { DashboardLayout, MultiColumn } from 'components/core/layouts';
import { FiUsers } from 'react-icons/fi';
import { StudentsList } from 'components/students/list';
import { FilterBar } from 'components/tools/filter_bar';
import { SectionHeading } from 'components/core/typo';
import { Outlet } from "react-router-dom";

// Contexts
import { SearchContextProvider } from 'contexts/search';

// Utils
import { getFullCurrentPeriod } from 'utils/period';

// Hooks
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { StudentsContextProvider } from 'contexts/students';

export function StudentListPage(){
  const { ni } = useParams();
  const { t } = useTranslation("common")
  const defaultFields = useMemo(()=>({period: getFullCurrentPeriod()}), []);
  return <SearchContextProvider storageKey={"/students"} defaultFields={defaultFields}>
          <StudentsContextProvider>
          <DashboardLayout contentClassName={"overflow-hidden"}>
            <SectionHeading title={t("students")} className={classnames(ni && "hidden md:block")}>
            <FilterBar period preset team school/>
            </SectionHeading>
            <MultiColumn>
              <MultiColumn.Left className={classnames("border-r", ni && "hidden md:block")}>
                <StudentsList/>
              </MultiColumn.Left>
              <MultiColumn.Content  className={classnames(!ni && "hidden md:block")}>
                <Outlet/>
              </MultiColumn.Content>
            </MultiColumn>
           </DashboardLayout>
          </StudentsContextProvider>
         </SearchContextProvider>
}

const route =  { path: "/students",
                 name: "Élèves-athlètes" ,
                 Icon: FiUsers,
                 showInNavBar: true,
                 requireAuth: true,
                 Element: StudentListPage };
export default route;
