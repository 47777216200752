import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import { Outlet } from "react-router-dom";
import { NavBar } from "./navbar"
import { InfoSectionHeading  } from 'components/core/typo';

export function RecrutingLayout(){
  // const navigate = useNavigate();
  const { t } = useTranslation("common");
  // const params = useParams();

  return <div className="flex flex-col h-full">
          <div className=" bg-gray-100 border-b">
          <div className="mx-auto px-3 bg-gray-100">
            <InfoSectionHeading mediumTitle={t("recruting.title")} longDescription={t("recruting.description")}/>
          </div>
        </div>
          <NavBar/>
          <Outlet/>
        </div>
         
}