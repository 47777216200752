import React, {createContext } from "react";

// Hooks
import { useLocation } from 'react-router-dom';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useMemo, useEffect } from 'react';
import { useCoach } from 'hooks/coachs/useCoach';

const CoachContext = createContext({});

const CoachContextProvider = ({ children }) => {
  const location = useLocation();
  const immediate = useMemo(()=>false, []);
  const [coach, {loading, error, setResult, execute}] = useCoach({immediate});

  const isLoaded = useMemo(()=>coach && coach.name, [coach]);

  const paramsChanged = useMemoCompare(location, (prev, next)=>prev && prev.pathname==next.pathname);
  useEffect(()=>{
    execute()
  }, [paramsChanged])

  return (
    <CoachContext.Provider value={{ coach: (coach || {}), setCoach: setResult, loading, error, isLoaded }}>
      {children}
    </CoachContext.Provider>
  );
};

export { CoachContext, CoachContextProvider };