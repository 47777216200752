import React from 'react';
import classnames from 'classnames';

// Hooks
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// Components
import { Button } from 'components/core/button';
import { FlexNav } from 'components/core/layouts/flex';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { InfoSectionHeading  } from 'components/core/typo';
import { Error } from 'components/core/typo'
import { SelectListCronPeriods } from 'components/tools/select_cron_period';

import { TextInput } from 'components/core/inputs'
import { SelectSport } from 'components/tools/sports_list';
import { SelectSchool } from 'components/tools/schools_list';
import { SelectPeriods } from 'components/tools/select_period';
import { SearchCoach } from 'components/tools/search_coach';

// API
import { createTeam } from 'api/teams';


/*
  Side navigation is just a view to collapse the results
*/
function SideNavigation({name, sport, school, activePeriod, coach, practicePeriods, assistantCoach}){
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const steps = useMemo(()=>{
    return [{
      name: t("name"), 
      description: name,
      completed: name
    },
    {
      name: t("sport"), 
      description: sport && sport.name, 
      completed: sport 
    },
    {
      name: t("school"), 
      description: school && school.name, 
      completed: school 
    },
    {
      name: t("active-year"), 
      description: activePeriod && activePeriod.name, 
      completed: activePeriod 
    },
    {
      name: t("coach"), 
      description: coach && coach.name, 
      completed: coach 
    },
    {
      name: t("assistant-coach"), 
      description: assistantCoach && assistantCoach.name, 
      completed: assistantCoach 
    },
    {
      name: t("practice-weekly-period"), 
      completed: practicePeriods 
    }];
  }, [name, sport, activePeriod, school, coach, practicePeriods, assistantCoach]);
 
  const creationParams = useMemo(()=>(
    {sport: sport && sport.slug,
    school: school && school.slug,
    coach: coach && coach.ni,
    activePeriod: activePeriod && activePeriod.slug,
    assistantCoach: assistantCoach && assistantCoach.ni,
    practicePeriods,
      name}), [name, sport, activePeriod, school, coach, practicePeriods, assistantCoach])
  const [team, teamRequest] = useAPI(createTeam, creationParams, {immediate: false});
  useEffect(()=>{
    if (team){
      navigate(`/teams/${team.slug}`)
    }
  }, [team])

  const disabled = useMemo(()=>{
    if (!name) return true;
    if (!sport) return true;
    if (!activePeriod) return true;
    if (!school) return true;
    return false;
  }, [name, sport, school, activePeriod])

  return (<div className="bg-gray-100 lg:bg-white rounded-lg p-3 lg:pl-6 lg:pt-8 ">
                    <InfoSectionHeading subtitle={t("summary")}/>
            {steps.filter(d=>!d.hide).map((d=>
                <div key={d.name}>
                  <div className={classnames("flex items-start py-2", d.warning&&"bg-yellow-50 rounded-lg")}>
                    {d.warning?
                      <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <span className="absolute h-4 w-4 rounded-full bg-yellow-200" />
                        <span className="relative block w-2 h-2 bg-yellow-600 rounded-full" />
                      </span>:
                      !d.completed?
                      <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                      </div>:
                      <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <span className="absolute h-4 w-4 rounded-full bg-green-200" />
                        <span className="relative block w-2 h-2 bg-green-600 rounded-full" />
                      </span>
                    }
                    <div>
                      <p className={classnames("ml-3 font-medium", d.warning?"text-yellow-800":d.completed? "text-green-600": "text-gray-400")}>{d.name}</p>
                      {
                        d.warning? <p className="ml-3 text-yellow-600 ">{d.warning}</p>:
                        d.description && <p className="ml-3 text-gray-800 ">{d.description}</p>
                      }
                    </div>
                  </div>
                </div>
            ))}

            <Button className="mt-6" 
                      color="active" 
                      onClick={teamRequest.execute} 
                      loading={teamRequest.loading} 
                      disabled={disabled} 
                      block 
                      size="lg">{t('create-team')}</Button>
            {teamRequest.error && <Error.Text {...teamRequest.error}/>}
          </div>)
}


function CreationView({sport, 
                      setSport, 
                      school, 
                      setSchool, 
                      coach, 
                      setCoach,
                      assistantCoach,
                      setAssistantCoach,
                      activePeriod,
                      setActivePeriod,
                      practicePeriods,
                      setPracticePeriods,
                      setName}){
  const { t } = useTranslation("common");

  return <div className="w-full space-y-8 lg:pr-12 mx-auto"> 
          <InfoSectionHeading title={t("create-team")}/>
            <TextInput 
              label={t("name")} 
              required
              description={t("team-name-description-on-creation")}
              onChange={setName} />
            <ModifiableSelectField 
                  Field={SelectSport} 
                  disabledClear
                  label={t("sport")} 
                  modify={true} 
                  value={sport}
                  setValue={setSport} />
            <ModifiableSelectField 
                  Field={SelectSchool} 
                  disabledClear
                  label={t("school")} 
                  modify={true} 
                  value={school}
                  setValue={setSchool} />

            <ModifiableSelectField 
                  Field={SelectPeriods} 
                  disabledClear
                  label={t("active-year")} 
                  modify={true} 
                  value={activePeriod}
                  setValue={setActivePeriod} />

            <ModifiableSelectField 
                  Field={SearchCoach} 
                  disabledClear
                  label={t("coach")} 
                  modify={true} 
                  value={coach}
                  setValue={setCoach} />
              <ModifiableSelectField 
                  Field={SearchCoach} 
                  disabledClear
                  label={t("assistant-coach")} 
                  modify={true} 
                  value={assistantCoach}
                  setValue={setAssistantCoach} />

               <ModifiableSelectField 
                    Field={SelectListCronPeriods} 
                    label={t("practice-weekly-period")} 
                    modify={true}
                    value={practicePeriods} 
                    setValue={setPracticePeriods} />

          </div>
}


export function NewTeam(){
  const [name, setName] = useState();
  const [sport, setSport] = useState();
  const [school, setSchool] = useState();
  const [practicePeriods, setPracticePeriods] = useState();
  const [coach, setCoach] = useState();
  const [activePeriod, setActivePeriod] = useState();
  const [assistantCoach, setAssistantCoach] = useState();

  return <FlexNav breakdown="lg" className={"max-w-6xl mx-auto relative h-full"}>

            <FlexNav.Content breakdown="lg" className="py-6">
             <CreationView
                sport={sport} 
                school={school} 
                assistantCoach={assistantCoach} 
                coach={coach} 
                setSport={setSport} 
                setSchool={setSchool} 
                setCoach={setCoach} 
                activePeriod={activePeriod}
                setActivePeriod={setActivePeriod}
                setAssistantCoach={setAssistantCoach} 
                practicePeriods={practicePeriods} 
                setPracticePeriods={setPracticePeriods} 
                setName={setName}            
               />
             </FlexNav.Content>
             <FlexNav.Bar breakdown="lg" className="order-last lg:border-l lg:pl-6 ">
              <div className="absolute sticky top-0 space-y-12 max-h-full pb-12">
                <SideNavigation 
                      sport={sport} 
                      school={school} 
                      coach={coach} 
                      activePeriod={activePeriod} 
                practicePeriods={practicePeriods} 
                      assistantCoach={assistantCoach} 
                        name={name} />
              </div>
            </FlexNav.Bar>
            </FlexNav>
}
