import React from 'react';

// Components
import { InfoSectionHeading  } from 'components/core/typo';
import { Button } from 'components/core/button';

// Hooks
import { useTranslation } from 'react-i18next';


export default function Welcome(){
  const { t } = useTranslation('endYearAssistant');
  return <div className="max-w-3xl mx-auto px-3 py-6 w-full">
          <InfoSectionHeading mediumTitle={t("welcome.title")} longDescription={t("welcome.description")}/>
          <div className="space-y-3">
            <Button size="md" block color="gray" href="/end-year-assistant/pairings">{t("pairings.title")}</Button>
            <Button size="md" block color="gray" href="/end-year-assistant/tutors">{t("tutors.title")}</Button>
            <Button size="md" block color="gray" href="/end-year-assistant/students">{t("students.title")}</Button>
            <Button size="md" block color="gray" href="/end-year-assistant/coachs">{t("coachs.title")}</Button>
          </div>
        </div>
}