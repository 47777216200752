import React, { useMemo } from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { ModifySchoolSubjectFailures } from 'components/student/modify_subjects';

// Hooks
import { useContext } from 'react';

// Contexts
import { StudentContext } from 'contexts/student';
import { ModifiableMultipleSelectField } from 'components/tools/modifiable_fields';
import { useSearch } from 'hooks/useSearch';
import { deleteSchoolYearFailed, searchStudentFailedYears } from 'api/students';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { Info } from 'components/core/info';
import { useTranslation } from 'react-i18next';
import { NewSchoolYearFailed } from 'components/tools/new_school_year_failed';
import { Error, InfoSectionHeading } from 'components/core/typo';

export default function PageSubjectsSettings(){
  const {t} = useTranslation();
  const { student, setStudent } = useContext(StudentContext);
  const ni = useMemoCompare(student && student.ni, (a, b) => a === b);
  const params = useMemo(() => ({ student:ni, orderBy: 'period', orderDirection: 'DESC' }), [ni]);
  const [schoolYearFailed, {setResult, loading, error}] = useSearch(searchStudentFailedYears, params);
  return <div className="min-h-full w-full space-y-12 pb-16">

           
        <div>
              
            {student &&student.name?
              <ModifySchoolSubjectFailures student={student} setStudent={setStudent}/>:<Skeleton className="w-full h-80"/>}
              </div>

            <div>
              <InfoSectionHeading title={t('school-year-failed.title')} description={t('school-year-failed.description')}/>
              {error && <Error.Text {...error}/>}
              {loading? <Skeleton className={"w-full h-80"}/>: <ModifiableMultipleSelectField
                modify={true}
                value={schoolYearFailed}
                setValue={setResult}
                Field={NewSchoolYearFailed}
                fieldProps={{ defaultStudent: student }}
                onDelete={d => deleteSchoolYearFailed({ id: d.id })}
                indexField={"id"}>
                {(values) =>
                  values && values.length > 0 ?
                    values.map(val =>
                      <Info.Field key={val.id}
                        value={<p><span className='font-medium mr-2 text-blue-600 '>{val.period? val.period.name : ""}</span><span>{val.school_year.name}</span></p>}
                        description={val.description }
                      />) :
                    <Info.Container><Info.Field noValueLabel={t('empty-state.no-school-year-failed.title')} /></Info.Container>
                }
              </ModifiableMultipleSelectField>}
            </div>
           
         </div>
}
