import React from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiShare2, FiArrowRight } from 'react-icons/fi';
import { EmptyState } from 'components/core/empty';
import { Button } from 'components/core/button';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch';
import { useUser } from 'hooks/useUser';
import { useMemo } from 'react';

// API
import { searchPresets } from 'api/presets';

function EmptyView(){
  const { t } = useTranslation("common");
  return <EmptyState noMargin description={t("empty-state.no-preset-dropdown")} >
            <Button block size="md" color="gray" href={`/settings/presets`}>{t("empty-state.no-preset-btn")} <FiArrowRight/></Button>
          </EmptyState>
}

export function SelectPreset({ value, setValue, limitToUser, disabled, disabledClear, ...props }){
  const { t } = useTranslation("common");
  const [user] = useUser();
  const params = useMemo(()=>limitToUser? {user: user && user.email}: {} , [])
  const [presets] = useSearch(searchPresets, params);
  return <ListSelection value={value}
                        setValue={setValue}
                        disabled={disabled}
                        values={presets}
                        label={t('preset')}
                        Icon={FiShare2}
                        emptyView={<EmptyView/>}
                        getKey={(d)=>d.id}
                        format={(d)=>limitToUser? (d.name || d.user.name): `${d.user.name}${d.name? ` (${d.name})`: ""}`}
                        itemDescription={(d)=>d.teams.map(e=>e.name).join(', ')}
                        withClearSelection={!disabledClear}
                        {...props}
                        />
}