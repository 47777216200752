import React from 'react';

// Components
import { Container } from 'components/core/container';
import { StudentOpenSettings } from 'components/student/modify';

// Hooks

// Contexts

export function OpenSettings(){

  return <Container className="w-full h-full">
            <StudentOpenSettings/>
         </Container>

}
