import React from 'react';

// Components
import { Container } from 'components/core/container';
import { TutorCloseSettings } from 'components/tutors/modify';

// Hooks

// Contexts

export function CloseSettings(){

  return <Container className="w-full h-full">
            <TutorCloseSettings/>
         </Container>

}
