import React from 'react';
import classnames from 'classnames';

// Components
import { DashboardLayout, MultiColumn } from 'components/core/layouts';
import { FiClipboard } from 'react-icons/fi';
import { CoachsList } from 'components/coachs/list';
import { FilterBar } from 'components/tools/filter_bar';
import { SectionHeading } from 'components/core/typo';
import { Outlet } from "react-router-dom";

// Contexts
import { SearchContextProvider } from 'contexts/search';
import { CoachsContextProvider } from 'contexts/coachs';

// Utils
import { getFullCurrentPeriod } from 'utils/period';

// Hooks
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

export function CoachListPage(){
  const { ni } = useParams();
  const { t } = useTranslation("common")
  const defaultFields = useMemo(()=>({period: getFullCurrentPeriod()}), []);
  return <SearchContextProvider storageKey={"/coachs"} defaultFields={defaultFields}>
          <CoachsContextProvider>
            <DashboardLayout contentClassName={"overflow-hidden"}>
              <SectionHeading title={t("coachs")} className={classnames(ni && "hidden md:block")}>
              <FilterBar period preset team school/>
              </SectionHeading>
              <MultiColumn>
                <MultiColumn.Left className={classnames("border-r", ni && "hidden md:block")}>
                  <CoachsList/>
                </MultiColumn.Left>
                <MultiColumn.Content  className={classnames(!ni && "hidden md:block")}>
                  <Outlet/>
                </MultiColumn.Content>
              </MultiColumn>
            </DashboardLayout>
          </CoachsContextProvider>
         </SearchContextProvider>
}

const route =  { path: "/coachs",
                 name: "Entraîneurs" ,
                 Icon: FiClipboard,
                 showInNavBar: true,
                 requireAuth: true,
                 Element: CoachListPage };
export default route;
