import React from 'react';

// Components
import { FieldsSection } from 'components/core/fields-section';
import { SingleLineField, ModifiableSelectField } from 'components/tools/modifiable_fields';
import { CheckBox } from 'components/tools/checkbox';
// Hooks
import { useTranslation } from 'react-i18next';

// API
import { updateDraftPairing } from 'api/auto-pairing';
import { useParams } from 'react-router-dom';

export function Modify({value, setValue}){
    const {t} = useTranslation("autoPairing");
    const {draftId} = useParams();
    return <FieldsSection title={t("modify-section.title")} name="autopairng">
            <SingleLineField
                label={t("new-settings.draft-name.label")}
                description={t("new-settings.draft-name.description")}
                placeholder={t("new-settings.draft-name.placeholder")}
                modify={true}
                setAPI={{name: "description", method: updateDraftPairing, extraParams: {draftId} }}
                value={value.description}
                setValue={(description) => setValue(d=>({...d, description}))}/>

        <ModifiableSelectField 
                      Field={CheckBox} 
                      alwaysFlex={true}
                      label={t("official.label")} 
                      subLabel={t("official.description")} 
                setAPI={{name: "official", method: updateDraftPairing, extraParams: {draftId} }}
                      modify={true}
                      noBorder={true}
                      value={value.official} 
                setValue={(official) => setValue(d=>({...d, official}))}/>
        </FieldsSection>
}
