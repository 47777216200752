import React from 'react';

// Components
import { Table } from 'components/core/table';
import { FilterBar } from 'components/tools/filter_bar';
import { Skeleton } from 'components/core/skeleton';
import { Button } from 'components/core/button';
import { EmptyState } from 'components/core/empty';
import { Paginate } from 'components/core/paginate';
import { Error, RawTextInfo } from 'components/core/typo'
import { Flyout } from 'components/core/flyouts';
import { ColumnsContainer } from 'components/exports/columns-grid';

// hooks 
import { useContext, useState, useEffect, useMemo } from 'react';
import { useSearch } from 'hooks/useSearch';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Contexts
import { SearchContext } from 'contexts/search';

// Utils
import { exportCSVFile } from 'utils/export_csv';
import { merge } from 'd3-array';
import { config } from 'components/exports/config';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { groupBy } from 'utils/array';

const styles = {
  index: "font-medium max-w-xs y-2 truncate ",
  base: "text-gray-600 max-w-xs py-1 truncate "
}

function formatDefaultHeader(d) {
  return ({ title: d.replaceAll("_", "-"), fetchField: d, field: d, placeholder: "-", itemClassName: styles.base })
}

export function ExportContentParams(){
  const {dataType} = useParams();
  const dataTypeFixed = useMemoCompare(dataType, (a,b)=>a===b);
  return <ExportContent dataType={dataTypeFixed}/>
}


export function ExportContent({dataType}) {
  const { t } = useTranslation("common");
  const [orderColumns, setOrderColumns] = useState(false);
  const { filters, dispatchFilters } = useContext(SearchContext);
  const specificConfig = useMemo(() => (config[dataType] || {}), [dataType]);
  const [columns, setColumns] = useState((specificConfig.columns || []).filter(d => d.defaultActive));
  const possibleColumns = useMemo(() => [...specificConfig.defaultFormatFields.map(d => formatDefaultHeader(d)),
  ...specificConfig.columns.filter(d => !d.cannotRemove)],
    [dataType]);
  const baseFields = useMemo(() => specificConfig.baseFields, [dataType]);
  const dynamicFields = useMemo(() => [...new Set([...baseFields, ...columns.map(d => d.fetchField)])], [columns]);
  const method = useMemo(() => specificConfig.method, [dataType]);

  const finalFilters = useMemo(()=>({...filters, ...specificConfig.fixedFilters}), [filters])
  const [completeData, { execute, loading }] = useSearch(method, finalFilters, { immediate: false, dynamicFields, limit: 999999, validateParams: (d => d.dataType === dataType) });
  const [samples, { paging, setPage, error}] = useSearch(method, finalFilters, { limit: 25, dynamicFields, validateParams: (d => d.dataType === dataType) });
  const headers = useMemo(() => merge(
    columns.map(d => d.expand ?
      // !!!  make sure the content is sorted because we use the first row to select the columns's order !!!
      (samples && samples.length > 0 ? 
        (samples[0][d.returnedField || d.fetchField] || [])
        .map((e, idx)=>({...e, idx}))
        .filter((e=>d.filtering? d.filtering(e, finalFilters): true))
        .map((e) => ({
          ...d,
          title: d.titleMethod(e),
          format: (s => s ? d.formatIsTranslation ? t(d.format(s[d.returnedField || d.fetchField][e.idx], finalFilters)) : d.format(s[d.returnedField || d.fetchField][e.idx], finalFilters) : "-"),
          field: ((s)=>s?s:d.field(e)),
          idx: e.idx
        })) 
        : []) // Expand is null
        : [({ ...d, title: t(d.title) })]) // Not expand
  ), [samples, columns]);
  
  // Filter the headers if necassary
  const sortedHeaders = useMemo(() => {
    if (!orderColumns){
      // Group headers by sortedGroup, and sort each group
      const groupedHeaders = groupBy(headers, "sortedGrouped");
      return merge(Object.keys(groupedHeaders).map(key=>{
        if (key==="undefined") return groupedHeaders[key];
        return groupedHeaders[key].sort((a, b) => a.title.localeCompare(b.title));
      }))
    }
    return headers.sort((a, b) => a.title.localeCompare(b.title));
  }, [headers, orderColumns])

  const obligatoryColumns = useMemo(() => [...specificConfig.columns.filter(d => d.cannotRemove)], [specificConfig]);
  const infoMessages = useMemo(() => Object.values(groupBy(headers.filter(d=>d.infoMessage), "fetchField")).map(d=>d[0].infoMessage).map(d=>d(finalFilters)).filter(d=>d? true: false), [headers]);
  // .map(d=>d.infoMessage(finalFilters))
  useEffect(async () => {
    // Will force a reload
    await dispatchFilters({ type: 'reset' });
    if (dataType==="students"){
      setColumns((specificConfig.columns || []).filter(d => d.defaultActive))
    }
    else {
      setColumns((specificConfig.columns || []))
    }
    dispatchFilters({ type: 'dataType', value: dataType })
  }, [dataType])

  useEffect(() => {
    if (!completeData) return;
    exportCSVFile(sortedHeaders, completeData, dataType)
  }, [completeData])

  
  if (!samples) {
    return <Skeleton.List itemClassName="h-80 rounded" className="p-8 space-y-3 w-full" numElements={2} />
  }
  return <div className="">

        <div className='relative z-10'>
          <FilterBar {...(config[dataType] ? config[dataType].search : {})} color="default">
             <div className='absolute hidden w-full -ml-4 -translate-y-1/2 lg:block top-1/2'>
              <Flyout.Full label={t("columns-to-show")}>
                <ColumnsContainer columns={columns} 
                                setColumns={setColumns} 
                                possibleColumns={possibleColumns} 
                                obligatoryColumns={obligatoryColumns} 
                                setOrderColumns={setOrderColumns}
                                orderColumns={orderColumns} /> 
              </Flyout.Full>
            </div> 

          </FilterBar>
        </div>
        <div className='px-3 space-y-3'>
        {infoMessages.map(d=><RawTextInfo className="text-sm" key={JSON.stringify(d)}>{d}</RawTextInfo>)}
        </div>
    <div className='block w-full border-t lg:hidden mt-2'>
      <Flyout.Full labelClassName='justify-end md:justify-start px-3' label={t("columns-to-show")}>
        <ColumnsContainer columns={columns} 
        setColumns={setColumns} 
        possibleColumns={possibleColumns} 
        obligatoryColumns={obligatoryColumns} /> 
      </Flyout.Full>
    </div>
    <div className="px-3">
    {error ? <Error.Text className="mt-3" {...error} /> : null}
        {paging && <Paginate setPage={setPage} {...paging} />}

      </div>
    <div className="max-w-full py-3 space-y-3 overflow-x-auto z-0 border-t">
    
      {samples.length === 0 ?
        <EmptyState title={t("empty-state.no-results-title")} /> :
        <Table headers={sortedHeaders}
          data={samples}
          tableClassName="z-0"
          indexingKey={specificConfig.indexingKey}
          order={{ by: filters.orderBy, direction: filters.orderDirection }}
          onHeaderClick={(value) => dispatchFilters({ type: 'ordering', value })} />
      }
    </div>
    <div className="px-3">
      {paging && <Paginate setPage={setPage} {...paging} />}
      <Button className="px-8 mt-2 mb-5" color="active" size="md" loading={loading} onClick={execute}>
        {t("export-as-csv")}
      </Button>
    </div>
  </div>
}
