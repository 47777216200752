import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { SelectRoles } from 'components/tools/select_roles';
import { Button } from 'components/core/button';
import { ModifiableSelectField} from 'components/tools/modifiable_fields';
import { Error } from 'components/core/typo'

// Hooks
import { useTranslation } from 'react-i18next';
import { useUser } from 'hooks/useUser';
import { useAPI } from 'hooks/useAPI';

// API
import { updateOtherUser, deleteUser } from 'api/users';
import { SelectSchool } from 'components/tools/schools_list';


function DeleteButton({value, onDelete}){
  const { t } = useTranslation('common');
  const [, {execute, loading, error}] = useAPI(deleteUser, 
                                            {email: value.email}, 
                                            {onResult: (()=>onDelete(value)), immediate:false})

  return <div className="border-2 border-red-500 rounded-lg p-3 w-full space-y-3">
          <div>
            <h3 className="font-medium text-red-600">{t("delete-user.title")}</h3>
            <p className="text-gray-500">{t("delete-user.description")}</p>
          </div>
          <Button loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-user.button")}</Button>
          {error? <Error.Text {...error}/>: null}
         </div>
}

export function ModifyMember({value, setValue, onDelete}){
  const {t} = useTranslation('common');
  const [user, setUser] = useUser();
  return<div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("modify-members", {name: value.name})}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
               <ModifiableSelectField 
                    Field={SelectRoles} 
                    label={t("add-member-group.role-title")} 
                    subLabel={t("add-member-group.role-description")} 
                    modify={true} 
                    value={value.role? {name: value.role }: null}
                    setValue={(role)=>{
                        setValue({...value, role: role&&role.name}); 
                        updateOtherUser({email: value.email, role});
                        if (user && user.email===value.email) {setUser({...user, role: role&&role.name})}
                      }}/>
                   <ModifiableSelectField 
                    Field={SelectSchool} 
                    label={t("add-member-group.school-title")} 
                    subLabel={t("add-member-group.school-description")} 
                    modify={true} 
                    value={value.main_school && value.main_school.slug ? value.main_school: null}
                    setValue={(school)=>{
                        setValue({...value, main_school:school}); 
                        updateOtherUser({email: value.email, school: school && school.slug});
                        if (user && user.email===value.email) {setUser({...user, main_school: school})}
                      }}/>
                <DeleteButton value={value} onDelete={onDelete}/>

              </CardContainer>
            </div>
}