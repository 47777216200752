import React from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiKey } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';

// These roles should be from the API
const roles = [
  {
    'name': "Direction",
    subDescription: "Équipe de direction"
  },
  {
    'name': "Coordo",
    'subDescription': "Coordonnateurs des services"
  },
  {
    'name': "RST",
    'subDescription': "Responsable des services de tutorat"
  },
  {
    'name': "Responsable",
    'subDescription': "Responsable des services d'intervention"
  },
  {
    'name': "Autre rôle",
    'subDescription': "Autres rôles dans l'organisation"
  }
];

export function SelectRoles({ value, setValue, disabled, ...props }){
  const { t } = useTranslation("common");
  return <ListSelection value={value}
                        setValue={setValue}
                        disabled={disabled}
                        values={roles}
                        label={t('role')}
                        Icon={FiKey}
                        getKey={(d)=>d.name}
                        format={(d)=>d.name}
                        itemDescription={(d)=>d.subDescription}
                        withClearSelection={false}
                        {...props}
                        />
}

