import React from 'react';

// Components
import { Container } from 'components/core/container';
import { TutorPreferences } from 'components/tutors/preferences';

// Hooks

// Contexts

export default function Preferences(){
  return <Container className="h-full w-full">
            <TutorPreferences/>
         </Container>
}
