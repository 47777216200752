import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { Button } from 'components/core/button';
import { Error } from 'components/core/typo'
import { SelectSchool } from 'components/tools/schools_list';
import { SelectPeriods } from 'components/tools/select_period';
import { SelectDocumentGroup } from 'components/tools/select_document_group';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { SelectDate } from 'components/tools/select_date';

// Hooks
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// API
import { deleteDocumentGroupPeriod, updateDocumentGroupPeriod } from 'api/document_groups';
import { dateParse } from 'utils/locale';

function DeleteButton({value, onDelete}){
  const { t } = useTranslation('common');
  const [, {execute, loading, error}] = useAPI(deleteDocumentGroupPeriod, 
                                            {id: value.id}, 
                                            {onResult: (()=>onDelete(value)), immediate:false})

  return <div className="border-2 border-red-500 rounded-lg p-3 w-full space-y-3">
          <div>
            <h3 className="font-medium text-red-600">{t("delete-document-group-period-period.title")}</h3>
            <p className="text-gray-500">{t("delete-document-group-period-period.description")}</p>
          </div>
          <Button loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-document-group-period-period.button")}</Button>
          {error? <Error.Text {...error}/>: null}
         </div>
}


export function ModifyDocumentGroupPeriod({value, setValue, onDelete}){
  const {t} = useTranslation('common');

  return <div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("modify-document-group-periods.title")}</div></span>}
                    description={t("modify-document-group-periods.description")} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
              <ModifiableSelectField
          Field={SelectDocumentGroup}
          label={t("add-document-group-period.document-group-title")}
          subLabel={t("add-document-group-period.document-group-description")}
          modify={true}
          value={value.group}
                  setValue={(group)=>{setValue({...value, group}); updateDocumentGroupPeriod({id: value.id, group})}}/>
        <ModifiableSelectField
                            Field={SelectSchool}
                            label={t("add-document-group-period.school-title")}
                            subLabel={t("add-document-group-period.school-description")}
                            modify={true}
                            value={value.school}
                  setValue={(school)=>{setValue({...value, school}); updateDocumentGroupPeriod({id: value.id, school})}}/>

              <ModifiableSelectField
                            Field={SelectPeriods}
                            label={t("add-document-group-period.period-title")}
                            subLabel={t("add-document-group-period.period-description")}
                            modify={true}
                            value={value.period}
                  setValue={(period)=>{setValue({...value, period}); updateDocumentGroupPeriod({id: value.id, period})}}/>

                  <ModifiableSelectField
                  Field={SelectDate}
                  label={t("add-document-group-period.start-date-title")}
                  subLabel={t("add-document-group-period.start-date-description")}
                  modify={true}
                  value={dateParse(value.should_be_received_at)}
                  setValue={(shouldBeReceivedAt)=>{setValue({...value, should_be_received_at:shouldBeReceivedAt.toISOString().slice(0,10)}); updateDocumentGroupPeriod({id: value.id, shouldBeReceivedAt})}}/>
         
             
                <DeleteButton value={value} onDelete={onDelete}/>
              </CardContainer>
            </div>


}