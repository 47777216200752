import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { TextInput } from 'components/core/inputs';
import { Error } from 'components/core/typo'
import { Button } from 'components/core/button';
import { SelectRoles } from 'components/tools/select_roles';
import { ModifiableSelectField} from 'components/tools/modifiable_fields';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAPI } from 'hooks/useAPI';

// API
import { inviteUser } from 'api/auth';

export function InviteMember({onCompleted}){
  const [draftInvitation, setDraftInvitation] = useState({});
  const [, {loading, execute, error}] = useAPI(inviteUser, draftInvitation, {immediate:false, onResult: onCompleted});
  const {t} = useTranslation('common');

  return<div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                description={t("add-member-group.description")}
                    title={<span className="flex items-center"> 
                            <div>{t("add-member")}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
                <TextInput
                  label={t("add-member-group.email-title")}
                  description={t("add-member-group.email-description")}
                  onChange={(email)=>setDraftInvitation(d=>({...d, email}))}
                />
                <TextInput
                  label={t("add-member-group.name-title")}
                  description={t("add-member-group.name-description")}
                  onChange={(name)=>setDraftInvitation(d=>({...d, name}))}
                />
                 <ModifiableSelectField 
                    Field={SelectRoles} 
                    label={t("add-member-group.role-title")} 
                    subLabel={t("add-member-group.role-description")} 
                    modify={true} 
                    value={draftInvitation.role} 
                    setValue={(role)=>setDraftInvitation(d=>({...d, role}))}/>
                <Button onClick={execute} disabled={!draftInvitation.name || !draftInvitation.email || !draftInvitation.role} loading={loading} block size="lg" color="active">{t("add-member")}</Button>
                {error&& <Error.Text {...error}/>}
              </CardContainer>
            </div>


}