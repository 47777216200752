import React from "react"

// Components
import { StudentsMetricsCard } from "components/coordo-stats/students-metrics"

// Hooks
import { useTranslation } from "react-i18next"


const scoresDefinitions = {
    // Score négatif (à risque) -> Plus le score est élevé, plus il y a d'élèves à risque
    0 : [0, 10], // Vide
    1 : [11, 19], // Vert
    2 : [20, 29], // Jaune
    3 : [30, 39], // Orange
    4 : [40, 49], // Rouge
    5 : [50, 100], // Rouge
}

const globalScoreDefinitions = {
    // Score global négatif (à risque) -> Plus le score est élevé, plus il y a d'élèves à risque
    "-" : [26, 100], // Rouge
    "+-" : [20, 25], // Jaune
    "+" : [0, 19] // Vert
}

export function AtRisk({...props}) {
    const { t } = useTranslation();
    return <>
        <StudentsMetricsCard 
            title={t("students-metrics.at-risk.title")}
            projectedStatKey="nbAtRisk" 
            infoDescription={t("students-metrics.at-risk.description")} 
            scoresDefinitions={scoresDefinitions} 
            globalScoreDefinitions={globalScoreDefinitions} 
            negative
            className="border-t-4 border-yellow-500"
            scoreClassName="!text-yellow-500"
            {...props}
        />
    </>
}