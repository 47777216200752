import React from 'react';

// Components
import { HomeCoordo } from 'pages/home/coordo';
import { FiHome } from 'react-icons/fi';

// Hooks
import { useUser } from 'hooks/useUser';
export function HomePage(){
  const [user] = useUser();
  if (user.role=="Coordo"){
    return <HomeCoordo/>
  }
  return <HomeCoordo/>
}

const route =  { path: "/home", 
                 name: "Vue d'ensemble" , 
                 Icon: FiHome,
                 showInNavBar: true, 
                 requireAuth: true, 
                 Element: HomePage };
export default route;
