import React from 'react';


// Components
import { Skeleton } from 'components/core/skeleton';
import { Info } from 'components/core/info';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { SelectSessionLength } from 'components/tools/select_length';
import { SelectDate } from 'components/tools/select_date';
import { SelectSchoolSubject } from 'components/tools/select_school_subjects';
import { SearchStudyCode } from 'components/tools/select_study_code'
import { SelectPairingType } from 'components/tools/select_pairing_type';
import { Error } from 'components/core/typo'
import { Button } from 'components/core/button';
import { CheckBox } from 'components/tools/checkbox';
// import { Dropdown } from 'components/core/dropdown';
import { FiLock } from 'react-icons/fi';
import { InfoSectionHeading  } from 'components/core/typo';
// Hooks
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAPI } from 'hooks/useAPI';

// Contexts
import { StudySessionContext } from 'contexts/study_session';

// API
import { deleteStudySession, addOrRemoveSubject, updateStudySession } from 'api/study_sessions';

// Utils
import classnames from 'classnames';
import {locale, dateParse} from 'utils/locale';

function DeleteButton({value}){
  const { t } = useTranslation('common');
  const [lock, setLock] = useState(true);
  const navigate = useNavigate();
  const [, {execute, loading, error}] = useAPI(deleteStudySession, 
                                            {id: value.id}, 
                                            {onResult: (()=>navigate(`/study-sessions`)), immediate:false})

  return <div className="relative">
          {lock && <div className="absolute z-10 w-full h-full flex items-center text-center justify-center">
                    <div>
                      <h3 className="text-gray-600 pb-3 text-lg">{t("delete-study-session.title")}</h3>
                      <Button color="deleteContrast" size="lg" onClick={()=>setLock(false)}><FiLock/> {t("delete-study-session.unlock-section")}</Button>
                    </div>
                  </div>}
          <div className={classnames(" rounded-lg p-3 w-full space-y-3", lock?"opacity-50 blur-sm": "border-2 border-red-500")}>
            <div>
              <h3 className="font-medium text-red-600">{t("delete-study-session.title")}</h3>
              <p className="text-gray-500">{t("delete-study-session.description")}</p>
            </div>
            <Button disabled={lock} loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-study-session.button")}</Button>
            {error? <Error.Text {...error}/>: null}
           </div>
        </div>
}


function UpdateSubjects({value, setValue}){
  return  <SelectSchoolSubject value={value.subjects} setValue={(subjects)=>setValue({...value, subjects})} 
            allowMultiple={true}
              onSelection={(d)=>{
                    addOrRemoveSubject({id: value.id, subject: d.slug}); 
                }}/>
  
}


export function ModifyStudySession(){
  const {studySession, setStudySession} = useContext(StudySessionContext);

  const modify = true;
  const { t } = useTranslation('common');


  if (!studySession.code){
    return <Skeleton.List numElements={5}/>
  }
  return <div className="h-full min-h-screen md:min-h-0 "> 
            <h1 className="info-section">{t('general-settings')}</h1>
            <Info.Container modify={modify} className="space-y-0">
                   <ModifiableSelectField 
                    Field={SelectDate} 
                    label={modify&&t("period")} 
                    modify={modify} 
                    value={dateParse(studySession.period)} 
                    setValue={(period)=>{updateStudySession({id:studySession.id, period}); setStudySession(d=>({...d, period: locale.format("%Y-%m-%d")(period)}))}}/>
                   <ModifiableSelectField 
                      Field={SelectDate} 
                      label={modify&&t("compta-period")} 
                      subLabel={t("compta-period-description")} 
                      modify={modify} 
                      disabled={false}
                      value={dateParse(studySession.original_period)} 
                      setValue={(originalPeriod)=>{updateStudySession({id:studySession.id, originalPeriod}); setStudySession(d=>({...d, originalPeriod: locale.format("%Y-%m-%d")(originalPeriod)}))}}/>

                  <ModifiableSelectField 
                    Field={SelectSessionLength} 
                    label={modify&&t("study-session.length-label")} 
                    modify={modify} 
                    showLabel={false}
                    showPresets={false}
                    value={studySession.length_in_minutes} 
                    setValue={(lengthInMinutes)=>{updateStudySession({id:studySession.id, lengthInMinutes}); setStudySession(d=>({...d, length_in_minutes: lengthInMinutes}))}}/>

                  <ModifiableSelectField 
                    Field={CheckBox} 
                    label={t("study-session-count")} 
                    subLabel={t("study-session-count-description")} 
                    modify={true}
                    value={studySession.use_in_count} 
                    setValue={(useInCount)=>{updateStudySession({id:studySession.id, useInCount}); setStudySession(d=>({...d, use_in_count: useInCount}))}}/>
                  
                  <ModifiableSelectField 
                    Field={SearchStudyCode} 
                    label={modify&&t("study-codes")} 
                    modify={modify} 
                    value={studySession.code} 
                    setValue={(code)=>{updateStudySession({id:studySession.id, code}); setStudySession(d=>({...d, code}))}}/>

                  <ModifiableSelectField 
                    Field={UpdateSubjects} 
                    block
                    label={modify&&t("school-subjects")} 
                    modify={modify} 
                    value={studySession} 
                    setValue={(subjects)=>{setStudySession(d=>({...d, subjects}))}}/>


                  {studySession.code && studySession.code.code==="tutorat" && 
                  <div className="pt-12">
                    <InfoSectionHeading subtitle={t("tutoring-params-section-title")} description={t("tutoring-params-section-description")}/>

                    <ModifiableSelectField 
                      Field={CheckBox} 
                      label={t("study-session-occurred")} 
                      // disabled={studySession.code.code!=="tutorat"}
                      modify={true}
                      disabled={true}
                      value={studySession.session_occurred} 
                      setValue={(sessionOccurred)=>{updateStudySession({id:studySession.id, sessionOccurred}); setStudySession(d=>({...d, session_occurred: sessionOccurred}))}}/>

                    <ModifiableSelectField 
                      Field={CheckBox} 
                      label={t("study-session-choked")} 
                      subLabel={t("study-session-choked-description")} 
                      // disabled={studySession.code.code!=="tutorat"}
                      modify={true}
                      disabled={true}
                      value={studySession.choked} 
                      setValue={(choked)=>{updateStudySession({id:studySession.id, choked}); setStudySession(d=>({...d, choked: choked}))}}/>

                    <ModifiableSelectField 
                      Field={CheckBox} 
                      label={t("study-session-technical-problem")} 
                      subLabel={t("study-session-technical-problem-description")} 
                      // disabled={studySession.code.code!=="tutorat"}
                      modify={true}
                      disabled={true}
                      value={studySession.technical_problem} 
                      setValue={(technicalProblem)=>{updateStudySession({id:studySession.id, technicalProblem}); setStudySession(d=>({...d, technical_problem: technicalProblem}))}}/>

                    <ModifiableSelectField 
                      Field={CheckBox} 
                      label={t("study-session-is-cancelled-for-external-reason")} 
                      subLabel={t("study-session-is-cancelled-for-external-reason-description")} 
                      // disabled={studySession.code.code!=="tutorat"}
                      modify={true}
                      disabled={true}
                      value={studySession.cancelled_for_external_reason} 
                      setValue={(cancelledForExternalReason)=>{updateStudySession({id:studySession.id, cancelledForExternalReason}); setStudySession(d=>({...d, cancelled_for_external_reason: cancelledForExternalReason}))}}/>

                    <ModifiableSelectField 
                      Field={CheckBox} 
                      label={t("study-session-is-retake")} 
                      subLabel={t("study-session-is-retaken-description")} 
                      // disabled={studySession.code.code!=="tutorat"}
                      modify={true}
                      disabled={true}
                      value={studySession.is_retaken} 
                      setValue={(isRetaken)=>{updateStudySession({id:studySession.id, isRetaken}); setStudySession(d=>({...d, is_retaken: isRetaken}))}}/>
                  {studySession.is_retaken && 
                    <div className="pl-12 ">
                      <ModifiableSelectField 
                      Field={SelectDate} 
                      label={modify&&t("original-period")} 
                      subLabel={t("original-period-description")} 
                      modify={modify} 
                      disabled={true}
                      value={dateParse(studySession.original_period)} 
                      setValue={(originalPeriod)=>{updateStudySession({id:studySession.id, originalPeriod}); setStudySession(d=>({...d, originalPeriod: locale.format("%Y-%m-%d")(originalPeriod)}))}}/>
                    </div>
                  }


                    
                    <ModifiableSelectField 
                      Field={SelectPairingType} 
                      label={modify&&t("session-format")} 
                      modify={modify} 
                      removeHybrid={true}
                      value={studySession.session_format} 
                      setValue={(sessionFormat)=>{updateStudySession({id:studySession.id, sessionFormat}); setStudySession(d=>({...d, sessionFormat}))}}/>
                  </div>}
                  


            </Info.Container>
            <Info.Container modify={true} className="mt-12">
                  <DeleteButton value={studySession}/>
            </Info.Container>

         </div>
}