import { fetchCall } from 'api';

/*
  Archive a failed study class for a student
*/  
export const archivedFailedClass = ({id}) => {
  const params = {
    method: "POST", 
    endpoint: `/archive-class-failed/${id}`,
  }
  return fetchCall(params)
};

/*
  Delete a failed study class for a student
*/  
export const deleteFailedClass = ({id}) => {
  const params = {
    method: "DELETE", 
    endpoint: `/school-classes-failed/${id}`,
  }
  return fetchCall(params)
};


/*
  Create/add a failed study class for a student
*/  
export const addFailedClass = ({schoolClass, student, period, documentGroup, score, startedAt, endedAt, description}) => {
  const params = {
    method: "POST", 
    endpoint: `/school-classes-failed`,
    body: {student, schoolClass, score, description, startedAt, endedAt, period: period && period.slug, documentGroup: documentGroup && documentGroup.code}
  }
  return fetchCall(params)
};

/*
  Update a failed study class for a student
*/  
export const updateFailedClass = ({id, schoolClass, score, startedAt, endedAt, document, description}) => {
  const params = {
    method: "POST", 
    endpoint: `/school-classes-failed/${id}`,
    body: {schoolClass, score, description, startedAt, endedAt, document: document&&document.id}
  }
  return fetchCall(params)
};
/*
  Create a school class
*/  
export const createSchoolClass = ({name, subject}) => {
  const params = {
    method: "POST", 
    endpoint: `/school-classes`,
    body: {name, subject: subject && subject.slug}
  }
  return fetchCall(params)
};


/*
  Update a school class
*/  
export const updateSchoolClass = ({slug, name, subject}) => {
  const params = {
    method: "POST", 
    endpoint: `/school-classes/${slug}`,
    body: {
      name,
      subject: subject && subject.slug
    }
  }
  return fetchCall(params)
};