import React from 'react';

// Components
import { Tabs } from 'components/core/tabs';

// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function NavBar() {
    const { t } = useTranslation("common");
    const tabs = [
        {
            name: t("my-assignations"),
            href: `/recruiting`,
        },
        {
            name: t("assignations"),
            href: `/recruiting/inscriptions`,
        },
        {
            name: "Formulaires",
            href: `/recruiting/forms`,
        },
        {
            name: "Statistiques",
            href: `/recruiting/stats`,
        }
    ]
    const [tab, setTab] = useState(tabs[0]);

    return <div className="border-b px-3">
        <Tabs tabs={tabs} value={tab} setValue={setTab} />
    </div>
}