import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { ModifiableMultipleSelectField } from 'components/tools/modifiable_fields';
import { NewTeamMember } from 'components/tools/new_team_member';
import { Info } from 'components/core/info';

// Utils

// Hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch'

// API
import { searchTeamMembers, deleteTeamMember } from 'api/teams';

export function ModifyTeamMembers({ team }){
  const params = useMemo(()=>({team: team && team.slug, orderBy: "student"}), [team])
  const [members, {paging, setPage, setResult}] = useSearch(searchTeamMembers, params)
  const modify = true;
  const { t } = useTranslation('common');

  if (!members || !team.name){
    return <Skeleton.List numElements={5} itemClassName="h-12" className="space-y-3"/>
  }
  return <div className="min-h-screen md:min-h-0 pb-12"> 
            <h1 className="info-section mb-4">{t('team-members')}</h1>
            <ModifiableMultipleSelectField 
              modify={modify} 
              paging={paging}
              setPage={setPage}
              value={members} 
              setValue={setResult} 
              defaultTeam={team}
              Field={NewTeamMember}
              fieldProps={{defaultTeam: team}}
              onDelete={d=>deleteTeamMember({id:d.id})}
              indexField={"id"}>
                {(values)=>
                  values && values.length>0?
                    values.map(val=>
                      <Info.Field key={val.id}
                                  value={val.student.name} 
                                  to={`/students/${val.student.ni}`}
                                  label={val.description}
                                  />):
                    <Info.Container><Info.Field noValueLabel={t('empty-state.no-teams-title')} /></Info.Container>
                  }
            </ModifiableMultipleSelectField>

         </div>
}
