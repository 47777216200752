/*
  This list is intended for managing the playTimeRules, not for selection.
*/

import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useContext, useState } from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Components
// import { FiMoreVertical } from 'react-icons/fi';
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Button } from 'components/core/button';
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';
import { Fragment } from 'react';
import { SlideOver } from 'components/core/slide_over';
import { CreatePlayTimeRule } from 'components/play-time-rules/create';
import { ModifyPlayTimeRule } from 'components/play-time-rules/modify';
import { Fraction, RulesCard } from 'components/play-time-rules/card';

// Utils
// import classnames from 'classnames';

// API
import { searchPlayTimeRules } from 'api/play_time_rules';
import { SelectSport } from 'components/tools/sports_list';

function ShowCardButton({rules}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("show-play-time-rule")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <RulesCard rules={rules}/>
          </SlideOver>
        </Fragment>
}

function CreateButton({onChange}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("add-play-time-rule")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <CreatePlayTimeRule onCompleted={()=>{setOpen(false); onChange()}}/>
          </SlideOver>
        </Fragment>
}

function ModifyButton({value, setValue, onDelete}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("modify")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <ModifyPlayTimeRule value={value} setValue={setValue} onDelete={onDelete}/>
          </SlideOver>
        </Fragment>
}

export function ControlledPlayTimeRulesList({playTimeRules, setPlayTimeRules, setPage, paging, error, execute}){
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { t } = useTranslation('common');
  const headers = useMemo(()=>[
    {
     title: t("sport"),
     field: (d=>d? d.sport.name : "sport"),
     itemClassName: "!py-2 whitespace-nowrap"
    },
    {
     title: t("weeks"),
     field: (d=>d? d.weeks : "weeks"),
     itemClassName: "text-center"
    },
    {
      title: "fraction",
     field: (d=>d? {target: d.target_study_sessions, done: d.done_study_sessions} : "fraction"),
     FormatComponent: Fraction
    },
    {
     title: t("target-study-sessions"),
     field: (d=>d? d.target_study_sessions : "target_study_sessions"),
     itemClassName: "text-center"
    },
    // {
    //  title: t("target-study-sessions-description"),
    //  field: (d=>d? d.target_study_sessions_description : "target_study_sessions_description"),
    //  itemClassName: "text-center"
    // },
    {
     title: t("done-study-sessions"),
     field: (d=>d? d.done_study_sessions : "done_study_sessions"),
     itemClassName: "text-center"
    },
    // {
    //  title: t("done-study-sessions-description"),
    //  field: (d=>d? d.done_study_sessions_description : "done_study_sessions_description"),
    //  itemClassName: "text-center"
    // },
    {
     title: t("play-time-rule-title"),
     field: (d=>d? d.play_time_title : "play_time_title"),
     itemClassName: "w-80"
    },
    {
     field: (d=>d? ({value:d, onDelete:((d=>setPlayTimeRules(current=>current.filter(e=>e.id!==d.id)))), setValue: ((next)=>setPlayTimeRules(current=>current.map(e=>e.id===next.id? next:e)))}) : "Modifier"),
     FormatComponent: ModifyButton
    },
  ], [playTimeRules])

  if (!playTimeRules){
    return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2"/>
  }

  return <Info.Container modify={true} sideHeader={<Fragment>
              <ShowCardButton rules={playTimeRules}/>
              <CreateButton onChange={execute}/>
              <SelectSport value={filters.sport} setValue={(value)=>dispatchFilters({type:'sport', value})}/>
              </Fragment>}>
            {playTimeRules.length===0?
              <EmptyState
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-playTimeRules-title")}/>
              :
              <Table headers={headers}
                    data={playTimeRules}
                    indexingKey="id"
                    order={{by:filters.orderBy, direction:filters.orderDirection}}
                    onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
              }
              {paging&& <Paginate setPage={setPage} {...paging}/>}
              {error? <Error.Text {...error}/>: null}
          </Info.Container>
}

function PlayTimeRulesListWithContext(){
  const { filters } = useContext(SearchContext);
  const [playTimeRules, {loading, error, setPage, paging, setResult, execute}] = useSearch(searchPlayTimeRules, filters, {limit:500});
  return <ControlledPlayTimeRulesList playTimeRules={playTimeRules} setPlayTimeRules={setResult} setPage={setPage} loading={loading} error={error} paging={paging} execute={execute}/>
}

export function PlayTimeRulesList(){
  return <SearchContextProvider doNotStore>
            <PlayTimeRulesListWithContext/>
        </SearchContextProvider>
}