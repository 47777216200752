import React, { useContext } from 'react';

// Components
import { Outlet } from "react-router-dom";
import { ConfirmPairingPage } from 'components/pairings/confirm';
import { PairingLayout } from 'components/pairings/layout';

// Hooks

function PairingContent(){
  const {pairing} = useContext(PairingContext);
  if (pairing && !pairing.confirmed){
    return <ConfirmPairingPage/>
  }
  return <Outlet/>
}
function PairingBase(){
  return <PairingLayout>
            <PairingContent/>
          </PairingLayout>
}
import {default as empty } from "./empty";
import {default as overview} from 'pages/pairing/overview';
import {default as notes} from 'pages/pairing/notes';
import { PairingContext } from 'contexts/pairing';
export const PairingPages = {root: PairingBase, empty, overview, notes}