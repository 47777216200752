import React, {createContext } from "react";

// Hooks
import { useLocation } from 'react-router-dom';
import { useMemo, useEffect } from 'react';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useStudent } from 'hooks/students/useStudent';

const StudentContext = createContext({});

const StudentContextProvider = ({ children }) => {
  const location = useLocation();
  const immediate = useMemo(()=>false, []);
  const [student, {loading, error, setResult, execute}] = useStudent({immediate});

  const paramsChanged = useMemoCompare(location, (prev, next)=>prev && prev.pathname==next.pathname);
  useEffect(()=>{
    execute()
  }, [paramsChanged])

  return (
    <StudentContext.Provider value={{ student, setStudent: setResult, loading, error, refresh:execute }}>
      {children}
    </StudentContext.Provider>
  );
};

export { StudentContext, StudentContextProvider };