/* eslint-disable */

import React from "react"

// Components 
import { StudentsList } from "components/follow-up/meetings/students";
import { StudentsMeetingsContextProvider } from "contexts/student-meetings";

// Hooks

export function Details() {
    return <>
        <StudentsMeetingsContextProvider>
            <StudentsList />
        </StudentsMeetingsContextProvider>
    </>
}


