import React, {createContext, useCallback, useContext } from "react";

// Hooks
import { useLocation } from 'react-router-dom';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useMemo, useEffect } from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContext } from 'contexts/search';

// API
import { searchCoachs } from 'api/coachs';

const CoachsContext = createContext({});

const CoachsContextProvider = ({ children }) => {
  const { filters } = useContext(SearchContext);
  const location = useLocation();
  const [coachs, { loading, paging, setPage, error, hasMore, setResult, execute }] = useSearch(searchCoachs, filters, { fields: ["ni", "name", "service_ended_at"] });
  const isLoaded = useMemo(()=>!!coachs, [coachs]);
  const paramsChanged = useMemoCompare(location, (prev, next)=>prev && prev.pathname==next.pathname);
  useEffect(()=>{
    execute()
  }, [paramsChanged])

  const updateCoach = useCallback((coach) => {
    setResult((coachs) => coachs.map((c) => c.ni === coach.ni ? coach : c));
  }, [setResult]);

  return (
    <CoachsContext.Provider value={{ coachs, setCoachs: setResult, loading, paging, setPage, error, hasMore, isLoaded, updateCoach }}>
      {children}
    </CoachsContext.Provider>
  );
};

export { CoachsContext, CoachsContextProvider };