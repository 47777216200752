// Hooks
import { useParams, useLocation } from 'react-router-dom';
import { useAPI } from 'hooks/useAPI';
import { useMemo } from 'react';

// API
import { retrieveTeam } from 'api/teams';

export function useTeam({ immediate=false }){
  const { slug } = useParams();
  const { state } = useLocation(); // Persitent state
  const params = useMemo(()=>({slug}), [slug]);
  const [result, others] = useAPI(retrieveTeam, params, {immediate});
  return [state || result || {slug}, others];
}