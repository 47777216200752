import React, {useMemo} from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { BsChevronExpand } from 'react-icons/bs';

// Hooks
import { useTranslation } from 'react-i18next';

export function SelectConfidence({ value, setValue, Icon, disabled, ...props }){

  const { t } = useTranslation("common");
  const values = [
    {
      name: t("confident"),
      value: "oui"
    },
    {
        name: t("partially-confident"),
        value: "partiellement"
      },
    {
      name: t("not-confident"),
      value: "non"
    }
  ]
  const controlledValue = useMemo(()=>!value? values[0]: !value.value? values.find(d=>d.value === value): value, [value]);
  return <ListSelection value={controlledValue}
                        setValue={setValue}
                        disabled={disabled}
                        values={values}
                        label={t('yes/no')}
                        Icon={Icon || BsChevronExpand}
                        getKey={(d)=>d.value}
                        format={(d)=>d.name}
                        withClearSelection={false}
                        {...props}
                        />
}