import React, {useMemo} from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiSun } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';

export function SelectMotivation({ value, setValue, Icon, disabled, ...props }){

  const { t } = useTranslation("common");
  const values = [
    {
      name: t("motivation.0"),
      value: "0"
    },
    {
      name: t("motivation.1"),
      value: "1"
    },
    {
      name: t("motivation.2"),
      value: "2"
    },
    {
      name: t("motivation.3"),
      value: "3"
    },
    {
      name: t("motivation.4"),
      value: "4"
    },
    {
      name: t("motivation.5"),
      value: "5"
    }
  ]
  const controlledValue = useMemo(()=>!value? values[0]: !value.value? values.find(d=>d.value === value): value, [value]);
  return <ListSelection value={controlledValue}
                        setValue={setValue}
                        disabled={disabled}
                        values={values}
                        label={t('motivation.label')}
                        Icon={Icon || FiSun}
                        getKey={(d)=>d.value}
                        format={(d)=>d.name}
                        withClearSelection={false}
                        {...props}
                        />
}