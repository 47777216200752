/* eslint-disable no-unused-vars */
import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useMemo, useCallback } from 'react';
import { useAPI } from 'hooks/useAPI';
import { useQuery } from 'hooks/useQuery';
import { useValidatePassword } from 'hooks/validation/useValidatePassword';

// API
import { validateResetPasswordToken, resetPassword } from 'api/auth';

// Components
import { Button } from 'components/core/button'
import { TextInput } from 'components/core/inputs'
import { Spinner } from 'components/core/spinner'
import { Error } from 'components/core/typo'
import { Navigate } from 'react-router-dom';

export function ResetPasswordForm(){
  const { t } = useTranslation(["auth", "errors"]);
  const { query } = useQuery();
  const [password, setPassword] = useState();
  const validatePassword = useValidatePassword();
  const params = useMemo(()=>({token: query.get('t')}), [])

  const [result, {loading, error, execute}] = useAPI(resetPassword, {password, token: query.get('t')},
                                                  {immediate: false});

  const [isValid, valid] = useAPI(validateResetPasswordToken, params);

  const onSubmit = useCallback((e)=>{
    if (e) e.preventDefault();
    execute();
  }, [execute]);

  if (valid.loading){
    return <Spinner className="mx-auto"/>
  }

  if (valid.error){
    return <Error.Detailed 
              withReturn
              description={t(valid.error.code, {ns: 'errors', ...valid.error.payload})}/>
  }

  if (result){
    return <Navigate to={"/login"}/>
  }

  return <form className="space-y-6" onSubmit={onSubmit}>
          <h1 className="text-xl">{t('reset_password.title')}</h1>
          <p className="text-gray-500">{t('reset_password.description')}</p>
          <TextInput 
              label={t("reset_password.password.label")} 
              placeholder={t("reset_password.password.placeholder")} 
              type="password" 
              autoComplete="current-password"
              required
              errorOnBlur={v=>v? validatePassword(v): null}
              onChange={setPassword}/>
         
          <Button disabled={validatePassword(password)} color="active" block size="lg" loading={loading} type="submit">
            {t("reset_password.submit")} 
          </Button>
          {error? <Error.Text {...error}/>: null}
         </form>
}
