import React, { useContext, useEffect, useMemo } from 'react';

// Components
import { Container } from 'components/core/container';
import { InfoSectionHeading, Error, RawTextInfo} from 'components/core/typo';
import { FieldsSection } from 'components/core/fields-section';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { SearchStudentsCommand } from 'components/automatic-pairing/command_students';
import { SearchTutorsCommand } from 'components/automatic-pairing/command_tutors';
import { TestAutoPairing } from 'components/automatic-pairing//test';
// API
import { launchOptimization, evaluteOptimization } from 'api/auto-pairing';

// Hooks
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// Context
import { DraftAutoPairingContext } from 'contexts/draft-auto-pairing';
import { Button } from 'components/core/button';
import { TextInput } from 'components/core/inputs';
import { Parameters } from 'components/automatic-pairing/parameters';
import { useNavigate } from 'react-router-dom';

/*
Do not change the quantity (its an index...).
Change the key to change the values.
 */
export const priorityOptions = {
    "0": {name:"Aucune", previous: null, next: "1", qte:0},
    "1": {name:"Faiblement (x1)", previous: "0", next: "2", qte:1},
    "2": {name:"Moyennement (x2)", previous: "1", next: "3", qte:2},
    "3": {name:"Prioriraire (x3)", previous: "2", next: "10", qte:3},
    "10": {name:"Très (x10)", previous: "3", next: "100", qte:4},
    "100": {name:"Extrêmement (x100)", previous: "10", next: null,  qte:5},
  }
  
export function CreatePairing(){
    const {t} = useTranslation("autoPairing");
    const {value, updateValue} = useContext(DraftAutoPairingContext)
    const disabled = useMemo(()=>!value.students || !value.tutors, [value]);
    const [result, {loading, execute, error}] = useAPI(launchOptimization, value, {immediate:false});
    const [evaluation, {loading: evalLoading, execute:evalExecute, error: evalError}] = useAPI(evaluteOptimization, value, {immediate:false});
    const navigate = useNavigate();
    useEffect(()=>{
        if(result){
            navigate(`/automatic-pairing/${result.id}`)
        }
    }, [result]);

    return <Container>
            <InfoSectionHeading title={t("new-settings.title")}>
            </InfoSectionHeading>
            {error && <Error.Text {...error}/>}
            <FieldsSection 
                name="info"
                title={t("new-settings.info.title")}>
                    <TextInput 
                    label={t('new-settings.draft-name.label')} 
                    description={t('new-settings.draft-name.description')} 
                    placeholder={t('new-settings.draft-name.placeholder')} 
                    onChange={(value)=>updateValue({type: 'description', value})} size="md"/>

            </FieldsSection>
            <FieldsSection 
                title={t("new-settings.entities.title")}
                description={t("new-settings.entities.description")}
                name="entities"
                >
                <ModifiableSelectField
                    labelSize='xl'
                    noBorder={true}
                    Field={SearchStudentsCommand}
                    disabled={loading}
                    label={t("new-settings.students.label")}
                    subLabel={t("new-settings.students.description", {count: (value.students || []).length})}
                    modify={true}
                    values={value.students || []}
                    setValues={(value) => { updateValue({ type: 'students', value }) }} />

                <ModifiableSelectField
                    labelSize='xl'
                    noBorder={true}
                    Field={SearchTutorsCommand}
                    disabled={loading}
                    label={t("new-settings.tutors.label")}
                    subLabel={t("new-settings.tutors.description", {count: (value.tutors || []).length})}
                    modify={true}
                    values={value.tutors || []}
                    setValues={(value) => { updateValue({ type: 'tutors', value }) }} />

               <RawTextInfo>
                    {!evaluation || !evaluation.num_possible_matches==null?
                            t("new-settings.test.result-disabled"):
                            evaluation.num_possible_matches>0?
                                t("new-settings.test.result", {count: evaluation.num_possible_matches}):
                                t("new-settings.test.result-empty")
                        }
                    <Button className="!ml-10" color="info" size="md" loading={evalLoading} disabled={disabled} onClick={()=>evalExecute({evaluateOnly: true})}>{t("new-settings.test.button")}</Button>
                </RawTextInfo>
                {evalError && <Error.Text {...evalError}/>}
            </FieldsSection>
            
            <FieldsSection 
                title={t("new-settings.params.title")}
                name="params"
                description={t("new-settings.params.description")}>
                <Parameters value={value} updateValue={updateValue} disabled={loading}/>
            </FieldsSection>
            
            <FieldsSection 
                name="test"
                title={t("new-settings.test-optim.title")}>
                <TestAutoPairing value={value}/>
            </FieldsSection>
            
            <div className='max-w-xl my-8 bg-gray-100 p-3 rounded-md'>
                <p className='font-medium'>{t("new-settings.when-ready.title")}</p>
                <p className='mb-2'>{t("new-settings.when-ready.description")}</p>
                <Button color="active" loading={loading} block disabled={disabled} size="md" onClick={execute}>{t("new-settings.submit")}</Button>
            </div>

          </Container>
}