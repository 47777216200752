import React, { useState } from 'react';

// Components
import { Container } from 'components/core/container';
import { FlexNav } from 'components/core/layouts/flex';
import { SubSideNavLinkState } from "components/core/nav";
import { FiUser, FiStar, FiLogOut as CloseIcon, FiLogIn as OpenIcon} from 'react-icons/fi';
import { ComputerDesktopIcon } from '@heroicons/react/24/outline';
// import { TutorSettingsPages } from '.';

import {default as general} from './general';
import {default as preferences} from './preferences';
import {default as portal} from './portal';
import {OpenSettings as open} from './open';
import {CloseSettings as close} from './close';
export const TutorSettingsPages = {general, preferences, open, close, portal}
// Router
import { useTranslation } from 'react-i18next';

function NavBar({value, setValue}){
  const { t } = useTranslation();
  return <div >
          <SubSideNavLinkState setValue={setValue} active={value==""} value={""} Icon={FiUser} name={t("personal-info")}/>
          <SubSideNavLinkState setValue={setValue} active={value=="preferences"} value={"preferences"} Icon={FiStar} name={t("preferences")}/>
          <SubSideNavLinkState setValue={setValue} active={value=="portal"} value={"portal"} Icon={ComputerDesktopIcon} name={t("portal")}/>
            <SubSideNavLinkState setValue={setValue} active={value=="open"} value={"open"} Icon={OpenIcon} name={t("open")}/>
            <SubSideNavLinkState setValue={setValue} active={value=="close"} value={"close"} Icon={CloseIcon} name={t("close")}/>
        </div>
}

export function TutorSettingsInline(){
    const [value, setValue] = useState("");
  return <Container className="py-6">
          <FlexNav>
            <FlexNav.Bar>
              <NavBar value={value} setValue={setValue}/>
            </FlexNav.Bar>
            <FlexNav.Content>
                {value===""? <TutorSettingsPages.general/>:
                value==="preferences"? <TutorSettingsPages.preferences/>:
                value==="portal"? <TutorSettingsPages.portal/>:
                value==="open"? <TutorSettingsPages.open/>:
                value==="close"? <TutorSettingsPages.close/>:null
                }
            </FlexNav.Content>
           </FlexNav>
          </Container>
}


// import {default as general} from './general';
// import {default as preferences} from './preferences';
// import {default as portal} from './portal';
// import {OpenSettings as open} from './open';
// import {CloseSettings as close} from './close';
