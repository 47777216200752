import React, { useState } from 'react';

// Components
import { FiUsers } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';
import { SearchTutors } from 'components/tools/search_tutor';
import { Error, InfoSectionHeading, RawTextSuccess, RawTextWarning } from 'components/core/typo';
import { Button } from 'components/core/button';
import { useAPI } from 'hooks/useAPI';
import { applyMergeTutors, draftMergeTutors } from 'api/tutors';

function Draft({draft}){
  const {t} = useTranslation("common");
  if (!draft) return null;
  return <div className="space-y-3 overflow-auto">
       <InfoSectionHeading smallTitle={t('duplicata-tutors.fields.title')}
            longDescription={t("duplicata-tutors.fields.description")} />
    {draft.merged_fields.map(({field, new_value, old_value})=>
      <div key={field}>
        <p className='font-medium'>{t(field)}</p>
        <div className='flex space-x-2 '>
        <p className="bg-green-100 text-green-800 flex-1 p-2" > + {String(new_value)}</p>
        <p className="bg-red-100 text-red-700 flex-1 p-2"> - {String(old_value)}</p>
        </div>
      </div>
    )}
      <div>
    <InfoSectionHeading smallTitle={t('duplicata-tutors.subjects.title')}
            longDescription={t("duplicata-tutors.subjects.description")} />
         <div className='flex space-x-2'>
          <div className='flex-1'>
            {draft.subjects.new.length===0 && <p className='bg-gray-100 text-gray-800 flex-1 p-2'>{t("duplicata-tutors.subjects.empty")}</p>}
    {draft.subjects.new.map(({subject, id})=><p className="bg-green-100 text-green-800 flex-1 p-2" key={id}>+ {subject}</p>)}
          </div>
          <div className='flex-1'>
          {draft.subjects.old.length===0 && <p className='bg-gray-100 text-gray-800 flex-1 p-2'>{t("duplicata-tutors.subjects.empty")}</p>}
      {draft.subjects.old.map(({subject, id})=><p className="bg-red-100 text-red-800 flex-1 p-2" key={id}>- {subject}</p>)}
          </div>
      </div>
      
    
    
    
    </div>
    <div>
    <InfoSectionHeading smallTitle={t('duplicata-tutors.schools.title')}
            longDescription={t("duplicata-tutors.schools.description")} />
        <div className='flex space-x-2'>
          <div className='flex-1'>
           {draft.schools.new.length===0 && <p className='bg-gray-100 text-gray-800 flex-1 p-2'>+ {t("duplicata-tutors.schools.empty")}</p>}
          {draft.schools.new.map(({school, id})=><p className="bg-green-100 text-green-800 flex-1 p-2" key={id}>+ {school}</p>)}
          </div>
          <div className='flex-1'>
          {draft.schools.old.length===0 && <p className='bg-gray-100 text-gray-800 flex-1 p-2'>- {t("duplicata-tutors.schools.empty")}</p>}
      {draft.schools.old.map(({school, id})=><p className="bg-red-100 text-red-800 flex-1 p-2" key={id}>- {school}</p>)}
          </div>
      </div>
    </div>
    <div>
    <InfoSectionHeading smallTitle={t('duplicata-tutors.pairings.title')}
            longDescription={t("duplicata-tutors.pairings.description")} />
         <div className='flex space-x-2'>
          <div className='flex-1'>
          {draft.pairings.new.length===0 && <p className='bg-gray-100 text-gray-800 flex-1 p-2'>{t("duplicata-tutors.pairings.empty")}</p>}
        {draft.pairings.new.map(({student, id})=><p className="bg-green-100 text-green-800 flex-1 p-2" key={id}>+ <Button href={`/pairings/${id}`}>{student}</Button></p>)}
          </div>
          <div className='flex-1'>
          {draft.pairings.old.length===0 && <p className='bg-gray-100 text-gray-800 flex-1 p-2'>{t("duplicata-tutors.pairings.empty")}</p>}
        {draft.pairings.old.map(({student, id})=><p className="bg-gray-100 text-gray-800 flex-1 p-2" key={id}> <Button href={`/pairings/${id}`}>{student}</Button></p>)}
          </div>
      </div>
    </div>
    <div>
      <InfoSectionHeading smallTitle={t('duplicata-tutors.notes.title')}
            longDescription={t("duplicata-tutors.notes.description")} />
         <div className='flex space-x-2'>
          <div className='flex-1'>
          {draft.notes.new.length===0 && <p className='bg-gray-100 text-gray-800 flex-1 p-2'>{t("duplicata-tutors.notes.empty")}</p>}
        {draft.notes.new.map(({code, id})=><p className="bg-green-100 text-green-800 flex-1 p-2" key={id}>+ {code}</p>)}
          </div>
          <div className='flex-1'>
          {draft.notes.old.length===0 && <p className='bg-gray-100 text-gray-800 flex-1 p-2'>{t("duplicata-tutors.notes.empty")}</p>}
        {draft.notes.old.map(({code, id})=><p className="bg-gray-100 text-gray-800 flex-1 p-2" key={id}> {code}</p>)}
          </div>
      </div>
      </div>

      <div>
      <InfoSectionHeading smallTitle={t('duplicata-tutors.periods.title')}
            longDescription={t("duplicata-tutors.periods.description")} />
         <div className='flex space-x-2'>
          <div className='flex-1'>
          {draft.periods.new.length===0 && <p className='bg-gray-100 text-gray-800 flex-1 p-2'>{t("duplicata-tutors.periods.empty")}</p>}
        {draft.periods.new.map(({period, id})=><p className="bg-green-100 text-green-800 flex-1 p-2" key={id}>+ {period}</p>)}
          </div>
          <div className='flex-1'>
          {draft.periods.old.length===0 && <p className='bg-gray-100 text-gray-800 flex-1 p-2'>{t("duplicata-tutors.periods.empty")}</p>}
        {draft.periods.old.map(({period, id})=><p className="bg-gray-100 text-gray-800 flex-1 p-2" key={id}> {period}</p>)}
          </div>
      </div>
      </div>

      <div>
      <InfoSectionHeading smallTitle={t('duplicata-tutors.portal_users.title')}
            longDescription={t("duplicata-tutors.portal_users.description")} />
         <div className='flex space-x-2'>
          <div className='flex-1'>
          {draft.portal_users.new.length===0 && <p className='bg-gray-100 text-gray-800 flex-1 p-2'>{t("duplicata-tutors.portal_users.empty")}</p>}
        {draft.portal_users.new.map(({email, id})=><p className="bg-green-100 text-green-800 flex-1 p-2" key={id}>+ {email}</p>)}
          </div>
          <div className='flex-1'>
          {draft.portal_users.old.length===0 && <p className='bg-gray-100 text-gray-800 flex-1 p-2'>{t("duplicata-tutors.portal_users.empty")}</p>}
        {draft.portal_users.old.map(({email, id})=><p className="bg-gray-100 text-gray-800 flex-1 p-2" key={id}> {email}</p>)}
          </div>
      </div>
      </div>
      
    
    </div>

}

function SettingsMergeTUtors(){
  const {t} = useTranslation("common");
  const [tutorA, setTutorA] = useState();
  const [tutorB, setTutorB] = useState();
  const [draft, {loading, error, execute}] = useAPI(draftMergeTutors, {ni1:tutorA &&tutorA.ni, ni2:tutorB&&tutorB.ni}, {immediate: false});
  const [result, mergeResult] = useAPI(applyMergeTutors, {ni1:tutorA &&tutorA.ni, ni2:tutorB&&tutorB.ni}, {immediate: false});

  return <div className="h-full min-h-screen md:min-h-0 space-y-6 "> 
              <InfoSectionHeading mediumTitle={t('duplicata-tutors.title')}
            longDescription={t("duplicata-tutors.description")} />
            <div className="flex space-x-3 items-center w-full mb-3">
              <SearchTutors showNi value={tutorA} setValue={setTutorA} label={t("duplicata-tutors.new-tutor")}/>
              <SearchTutors showNi value={tutorB} setValue={setTutorB} label={t("duplicata-tutors.old-tutor")}/>
            </div>
              <Button onClick={execute} disabled={!tutorA || !tutorB}color="active" size="md" loading={loading}>
                {t("duplicata-tutors.button-draft")}
              </Button>
              {error && <Error.Text {...error}/>}
              <Draft draft={draft}/>
              {draft && 
              <>
              <RawTextWarning>{t("duplicata-tutors.warning")}</RawTextWarning>
              <Button onClick={mergeResult.execute} disabled={!tutorA || !tutorB}block color="black" size="lg" loading={mergeResult.loading}>
                {t("duplicata-tutors.apply-button")}
              </Button>
              {mergeResult.error && <Error.Text {...mergeResult.error}/>}
              {result && <RawTextSuccess>{t("duplicata-tutors.completed")}<Button href={`/tutors/${tutorB.ni}`} color="link">{tutorA.name}</Button></RawTextSuccess>}
              </>}
          </div>
}
const route =  { path: "merge-tutors", 
                 name: "Duplicata des tuteurs" , 
                 group: "tools",
                 Icon: FiUsers,
                 requireAuth: true, 
                 scopes: ["Direction","Développeur"],
                 Element: SettingsMergeTUtors };
export default route;
