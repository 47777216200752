import React from 'react';

// Components
import { ModifyGeneralProfile } from 'components/settings/profile/info';
import { FiSettings } from 'react-icons/fi';

function SettingsProfile(){
  return <div>
          <ModifyGeneralProfile/>
          </div>
}
const route =  { path: "profile", 
                 name: "Général" , 
                 group: "users",
                 Icon: FiSettings,
                 requireAuth: true, 
                 Element: SettingsProfile };
export default route;
