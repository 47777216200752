/* eslint no-unused-vars: "off" */

import React from "react";

// Components
import { StatsCard, StatsCardTitle, StatsCardInfo } from 'components/coordo-stats/stats-card';
import { Skeleton } from "components/core/skeleton";
import { CoordoStatsContextProvider } from 'contexts/coordo-stats';

// Utils
import classNames from "classnames";

// Hooks
import { useCoordoStats } from "contexts/coordo-stats"
import { useTranslation } from "react-i18next";

export function StudySessionsWithContext({ ...props }) {
    return <CoordoStatsContextProvider fields={"study_sessions"}>
        <StudySessions {...props} />
    </CoordoStatsContextProvider>
}

function StudySessions({ className = "" }) {
    const { stats, loading } = useCoordoStats();
    const { t } = useTranslation();
    const { studySessions, lastWeekStudySessions } = stats || {};
    const counts = studySessions?.counts || {};
    const lastWeek = lastWeekStudySessions?.counts || {};
    const legend = studySessions?.legend || {};
    const start = studySessions?.start
    const end = studySessions?.end

    const startYear = start ? new Date(start).getFullYear() : null
    const endYear = end ? new Date(end).getFullYear() : null

    const colors = {
        default: "#bc5090"
    }
    const data = {}

    Object.keys(counts || {}).forEach(key => {
        if (key !== "total") {
            data[key] = counts[key]
        }
    })
    data.total = counts.total

    const orderedLastWeekData = {};
    Object.keys(lastWeek || {}).sort().forEach(key => {
        orderedLastWeekData[key] = lastWeek?.[key];
    });


    return <>
        {
            <>
                <StatsCard title={loading ? t("study-sessions-metrics.title") : undefined} className={classNames(className, "@container")}>
                    {!loading && <div className="flex justify-between">
                        <StatsCardTitle>{t("study-sessions-metrics.title-with-period", { period: `${startYear}-${endYear}` })}</StatsCardTitle>
                    </div>
                    }
                    {
                        loading ? <Skeleton className="min-h-48" />
                            :
                            <>
                                <div>

                                    <h3 className="text-gray-500 font-medium">{t("from-last-x-days", { days: "7" })}</h3>
                                    <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3">
                                        {
                                            Object.keys(orderedLastWeekData || {}).map((key, index) => {
                                                const value = lastWeek?.[key]
                                                if (key === "total" || key === "start" || key === "end") return null
                                                const title = legend[key] || key
                                                return <div key={index + key} className="flex flex-col justify-end">
                                                    <p className=''>{title}</p>
                                                    <p className='text-4xl'>{value}</p>
                                                </div>
                                            })
                                        }

                                        <div className={"h-full w-fit flex flex-col"}>
                                            <p className='text-lg'>Total</p>
                                            <p className='text-4xl'>{lastWeek.total}</p>
                                        </div>
                                    </div>
                                    <h3 className="text-gray-500 font-medium mt-5">{t("from-school-period-start")}</h3>
                                    <BarStats legendLabels={legend} data={data} colors={colors} legend={false} />
                                </div>
                            </>
                    }
                </StatsCard>
            </>
        }
    </>
}

function BarStats({ data, legend = true, legendLabels = {}, keyLabels = {}, colors }) {
    const defaultColor = "#000000";

    const firstData = data[Object.keys(data)[0]];

    // Order data by key name (alphabetical order)
    const orderedData = {};
    Object.keys(data).sort().forEach(key => {
        orderedData[key] = data[key];
    });

    return <>
        {/* Legend */}
        {
            legend && <div className="flex flex-wrap gap-4">
                {
                    Object.keys(firstData).map((subKey, i) => {
                        const color = colors[subKey] || colors.default || defaultColor;
                        if (subKey === 'total') return null;
                        return (
                            <div key={i} className="flex flex-row items-center justify-center gap-2">
                                <div className="w-4 h-4 rounded" style={{ backgroundColor: color }}></div>
                                <p>{keyLabels[subKey]}</p>
                            </div>
                        );
                    })
                }
            </div>
        }

        <div className="w-full flex flex-col gap-2 @container group">
            {Object.keys(orderedData).map((key, index) => {
                const value = orderedData[key] || 0;
                const total = orderedData.total || 1;
                const percent = (value / total) * 100;
                const color = colors[key] || colors.default || defaultColor;
                if (key === 'total') return null;
                return <div key={key + index} className="grid grid-cols-1 @[700px]:grid-cols-3 gap-3">
                    <h4 className="">{legendLabels[key] || key}</h4>
                    <div className="w-full relative h-[25px] flex rounded lining-nums tabular-nums overflow-hidden bg-gray-200 col-span-2" style={{ position: 'relative' }}>

                        {
                            <div
                                key={index}
                                style={{
                                    width: `${percent}%`,
                                    backgroundColor: color,
                                }}
                                className={classNames("h-full relative", percent === 0 ? "min-w-fit px-1.5" : "min-w-fit px-1.5")}
                            >
                                <div className="h-full w-full flex items-center text-white text-sm whitespace-nowrap ">
                                    {value}
                                </div>
                                <div className={classNames("absolute bottom-0", percent > 75 ? "right-0 -translate-x-2" : "translate-x-2 left-full")}>
                                    {percent == 0 ? "0%" : percent.toFixed(2) + "%"}
                                </div>
                            </div>
                        }

                    </div>
                </div>
            }
            )}
        </div>
    </>;
}