/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";

// Components
import { StatsCard, StatsCardScore, StatsCardTitle, SubStat } from "components/coordo-stats/stats-card";
import { SearchTutorsCommandWithContext } from "components/tutoring-follow-up/command_tutors";
import { SearchPairingsCommandWithContext } from "components/tutoring-follow-up/command_pairings";
import { SearchStudentsCommandWithContext } from "components/tutoring-follow-up/command_students";
import { Button } from "components/core/button";
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Utils
import classNames from "classnames";
import { getCurrentPeriod } from "utils/period";

// API
import { getTutoringOverview } from "api/tutors";

// Hooks
import { useAPI } from "hooks/useAPI";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const filterOptions = {
    "tutors": {
        period:
        {
            slug: getCurrentPeriod(),
            name: getCurrentPeriod()
        },
        inService: { value: true }
    },
    "pairedTutors": {
        paired:
        {
            name: "Jumelé(e)s",
            value: true
        },
        period:
        {
            slug: getCurrentPeriod(),
            name: getCurrentPeriod()
        },
        inService: { value: true }
    },
    "unpairedTutors": {
        paired:
        {
            name: "Non jumelé(e)s",
            value: false
        },
        period:
        {
            slug: getCurrentPeriod(),
            name: getCurrentPeriod()
        },
        inService: { value: true }
    },
    "availableTutors": {
        availableForPairing: {
            name: "Disponible",
            value: true
        },
        period:
        {
            slug: getCurrentPeriod(),
            name: getCurrentPeriod()
        },
        inService: { value: true }
    },
    "availableUnpairedTutors": {
        availableForPairing: {
            name: "Disponible",
            value: true
        },
        paired:
        {
            name: "Non jumelé(e)s",
            value: false
        },
        period:
        {
            slug: getCurrentPeriod(),
            name: getCurrentPeriod()
        },
        inService: { value: true }
    },
    "availableForSecondPairingTutors": {
        availableForPairing: {
            name: "Disponible",
            value: true
        },
        paired:
        {
            name: "Jumelé(e)s",
            value: true
        },
        period:
        {
            slug: getCurrentPeriod(),
            name: getCurrentPeriod()
        },
        inService: { value: true }
    },
    "pairings": {
        period:
        {
            slug: getCurrentPeriod(),
            name: getCurrentPeriod()
        }
    },
    "pairingsActive": {
        active: {
            name: "Actifs",
            value: true
        },
        period:
        {
            slug: getCurrentPeriod(),
            name: getCurrentPeriod()
        }
    },
    "pairingsInactive": {
        active: {
            name: "Inactifs",
            value: false
        },
        period:
        {
            slug: getCurrentPeriod(),
            name: getCurrentPeriod()
        }
    },
    "prioStudents": {
        period:
        {
            slug: getCurrentPeriod(),
            name: getCurrentPeriod()
        },
        fails: { value: true }
    },
    "pairedPrioStudents": {
        paired:
        {
            name: "Jumelé(e)s",
            value: true
        },
        period:
        {
            slug: getCurrentPeriod(),
            name: getCurrentPeriod()
        },
        fails: { value: true }
    },
    "unpairedPrioStudents": {
        paired:
        {
            name: "Non jumelé(e)s",
            value: false
        },
        period:
        {
            slug: getCurrentPeriod(),
            name: getCurrentPeriod()
        },
        fails: { value: true }
    }
    
}

export function GlobalStatsWithContext() {
    const { t } = useTranslation("common");
    const { dispatchFilters } = useContext(SearchContext);
    const [openTutorsCommand, setOpenTutorsCommand] = useState(false);
    const [openPairingsCommand, setOpenPairingsCommand] = useState(false);
    const [openPrioStudentsCommand, setPrioStudentsCommand] = useState(false);

    const params = useMemo(() => { }, [])
    const [stats, { loading }] = useAPI(getTutoringOverview, params, { immediate: true, camelize: true })
    const { pairings, tutors, priorityStudents } = stats || {}
    const { total: totalPairingsCount, active: activePairingsCount, inactive: inactivePairingsCount } = pairings || {}
    const { total: totalTutorsCount, paired: pairedTutorsCount, unpaired: unpairedTutorsCount, availableForSecondPairing: availableForSecondPairingTutorsCount, available: availableTutorsCount } = tutors || {}
    const { total: totalPriorityStudentsCount, paired: pairedPriorityStudentsCount, unpaired: unpairedPriorityStudentsCount } = priorityStudents || {}
    useEffect(() => {
        if (!openTutorsCommand) dispatchFilters?.({ type: 'reset' })
    }, [openTutorsCommand])
    const handleFilter = (filter) => {
        dispatchFilters({ type: 'set', value: filter })
    }
    const openTutorsWithFilter = (filter) => {
        if (filter) handleFilter(filter)
        setOpenTutorsCommand(prev => !prev)
    }
    const openPairingsWithFilter = (filter) => {
        if (filter) handleFilter(filter)
        setOpenPairingsCommand(prev => !prev)
    }
    const openPrioStudentsWithFilter = (filter) => {
        if (filter) handleFilter(filter)
        setPrioStudentsCommand(prev => !prev)
    }
    return <>
        <Grid title={t("stats-for-period",{period: getCurrentPeriod()})}>
            <StatsCard title={<Button onClick={() => openPairingsWithFilter(filterOptions.pairings)}>Tandems</Button>} className={"border-t-4 border-orange-500"} loading={loading}>
                <StatsCardScore title={totalPairingsCount || 0} />
                <SubStat
                    title={<Button color="default" className="rounded px-2" onClick={() => openPairingsWithFilter(filterOptions.pairingsActive)}>Actifs</Button>}
                    value={formatPercent(activePairingsCount / (totalPairingsCount || 1))}
                />
                <SubStat
                    title={<Button color="default" className="rounded px-2"onClick={() => openPairingsWithFilter(filterOptions.pairingsInactive)}>Inactifs</Button>}
                    value={formatPercent(inactivePairingsCount / totalPairingsCount || 1)}
                />
            </StatsCard>
            <StatsCard title={<Button onClick={() => openTutorsWithFilter(filterOptions.tutors)}>Tuteurs</Button>} className={"border-t-4 border-orange-500"} loading={loading}>
                <StatsCardScore title={totalTutorsCount || 0} />
                <SubStat
                    title={<Button color="default" className="rounded px-2" onClick={() => openTutorsWithFilter(filterOptions.pairedTutors)}>Jumelés</Button>}
                    value={formatPercent(pairedTutorsCount / (totalTutorsCount || 1))}
                />
                <SubStat
                    title={<Button color="default" className="rounded px-2" onClick={() => openTutorsWithFilter(filterOptions.unpairedTutors)}>Non jumelés</Button>}
                    value={formatPercent(unpairedTutorsCount / (totalTutorsCount || 1))}
                />
            </StatsCard>
            <StatsCard title={<Button onClick={() => openTutorsWithFilter(filterOptions.availableTutors)}>Tuteurs disponibles</Button>} className={"border-t-4 border-orange-500"} loading={loading}>
                <StatsCardScore title={availableTutorsCount || 0} />
                <SubStat
                    title={<Button color="default" className="rounded px-2" onClick={() => openTutorsWithFilter(filterOptions.availableUnpairedTutors)}>Non jumelés</Button>}
                    value={formatPercent(unpairedTutorsCount / (totalTutorsCount || 1))}
                />
                <SubStat
                    title={<Button color="default" className="rounded px-2" onClick={() => openTutorsWithFilter(filterOptions.availableForSecondPairingTutors)}>Pour un 2e jumelage</Button>}
                    value={formatPercent(availableForSecondPairingTutorsCount / (totalTutorsCount || 1))}
                />
            </StatsCard>
            <StatsCard title={<Button onClick={() => openPrioStudentsWithFilter(filterOptions.prioStudents)}>ÉA prioritaires</Button>} className={"border-t-4 border-orange-500"} loading={loading}>
                <StatsCardScore title={totalPriorityStudentsCount || 0} />
                <SubStat
                    title={<Button color="default" className="rounded px-2" onClick={() => openPrioStudentsWithFilter(filterOptions.pairedPrioStudents)}>Jumelés</Button>}
                    value={formatPercent(pairedPriorityStudentsCount / (totalPriorityStudentsCount || 1))}
                />
                <SubStat
                    title={<Button color="default" className="rounded px-2" onClick={() => openPrioStudentsWithFilter(filterOptions.unpairedPrioStudents)}>Non jumelés</Button>}
                    value={formatPercent(unpairedPriorityStudentsCount / (totalPriorityStudentsCount || 1))}
                />
            </StatsCard>
        </Grid>
        <SearchTutorsCommandWithContext open={openTutorsCommand} setOpen={setOpenTutorsCommand} />
        <SearchPairingsCommandWithContext open={openPairingsCommand} setOpen={setOpenPairingsCommand} />
        <SearchStudentsCommandWithContext open={openPrioStudentsCommand} setOpen={setPrioStudentsCommand} />
    </>
}

export function GlobalStats() {
    return <SearchContextProvider doNotStore>
        <GlobalStatsWithContext />
    </SearchContextProvider>

}

function formatPercent(value) {
    return (value * 100).toFixed(0) + "%"
}

export function Grid({ children, className, title, ...props }) {

    return <>
        <div className={classNames(className, "grid gap-4 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 bg-gray-100 p-6")} {...props}>
            {title && <StatsCardTitle className="col-span-full">{title}</StatsCardTitle>}
            {children}
        </div>
    </>
}