import React, { useEffect } from 'react';

// Modules
import ReactQuill from 'react-quill';
import CustomToolbar from "./customtoolbar";

// CSS
import 'styles/editor-style.css';
import { Button } from '../button';
import { FiMoreHorizontal } from 'react-icons/fi';
import classNames from 'classnames';

export function Editor({ 
    text,
    setText,
    placeholder = "", 
    label = "", 
    editorStyles = "", 
    name = "", 
    id = "", 
    minHeight = "", 
    toolbarStyles = "",
    hideToolbarToggle = false,
    children,
    ...props }) {

    const handleChange = (html) => { setText(html) };
    const [toolbarOpen, setToolbarOpen] = React.useState(false);
    if (minHeight != "") {
        let root = document.documentElement;
        root.style.setProperty('--min-editor-height', minHeight);
    }

    let qlLink = document.querySelector(".ql-link");

    let qlLinkEdit = document.querySelector(".ql-tooltip[data-mode=link] .ql-action");

    useEffect(() => {
        const linkPlaceholder = "https://www.exemple.com";
        function addEventSetCustomPlaceholder(element) {
            if (element) {
                element.addEventListener("click", () => {
                    let linkTooltip = document.querySelector(".ql-tooltip[data-mode=link] input[type=text]");
                    if (linkTooltip) {
                        linkTooltip.setAttribute("placeholder", linkPlaceholder);
                    }
                })
            }
        }
        addEventSetCustomPlaceholder(qlLink);
        addEventSetCustomPlaceholder(qlLinkEdit);
    }, [qlLink, qlLinkEdit]);

    const modules = {
        toolbar: { container: id ? `#toolbar-${id}` : "#toolbar" }
    }

    return (
        <>
            <label className='block text-sm font-medium text-gray-700'>{label}</label>

            <div className='flex gap-3'>
                <div id={id? `toolbar-${id}`: "toolbar"} className={classNames("w-full", toolbarStyles)}>
                    <div className={classNames(!hideToolbarToggle && (!toolbarOpen ? "hidden" : "p-2 !rounded-md"))}>
                        <CustomToolbar />
                    </div>
                </div>
                {
                    !hideToolbarToggle && toolbarOpen && <div className='mr-1'>
                        <ToolbarToggler setOpen={setToolbarOpen} className={classNames(!toolbarOpen && 'invisible')} />
                    </div>
                }
            </div>
            {toolbarOpen && <div className='h-1 bg-inherit' />}

            <div className='flex gap-3 relative'>
                {children}
                <ReactQuill
                    id={id}
                    {...props}
                    name={name}
                    placeholder={placeholder}
                    className={`flex-1 ${editorStyles} ${minHeight != "" ? "min-h-editor" : ""}`}
                    value={text}
                    onChange={handleChange}
                    modules={modules}
                />
                {
                    !hideToolbarToggle && <div className=' mr-1'>
                        <ToolbarToggler setOpen={setToolbarOpen} className={classNames(toolbarOpen && 'invisible')} />
                    </div>
                }
            </div>
        </>
    )
}

export function ToolbarToggler({setOpen, className}) {
    return <>
        <Button className={classNames(className,"size-6 shrink-0")} color="active" size="smToolbarIcon" onClick={()=>setOpen?.(prev=>!prev)}><FiMoreHorizontal /></Button>
    </>
}