/*eslint-disable no-unused-vars*/
import React, { useMemo, useContext, useState } from "react";

// Components
import { Table } from "components/core/table";
import { TutorsContext } from "contexts/tutors"
// import { SectionHeading } from "components/core/typo";
import { Skeleton } from "components/core/skeleton";
import { SearchContext } from 'contexts/search';
import { ListSearch } from "components/core/inputs";
import { Spinner } from "components/core/spinner";
import { Paginate } from "components/core/paginate";
import { FiFileText, FiTrash } from 'react-icons/fi';
import { Assignation } from "components/recruting/inscriptions/assignation";
import { BooleanPill, InscriptionStatus, findActiveInscription, Mails, isCompleted, hasValue } from "components/recruting/inscriptions";
import { SearchContextProvider } from "contexts/search";
import { TutorsContextProvider } from "contexts/tutors";
import { Button } from "components/core/button";
import { SelectMenu } from "components/tools/select-menu";
import { PreferenceCell } from "components/student/preferences";

// Icons
import { MagnifyingGlassIcon, } from '@heroicons/react/24/outline';

// Utils
import classNames from "classnames";
import { getCurrentPeriod } from "utils/period";

// Hooks
import { useTranslation } from "react-i18next";
import { useUser } from "hooks/useUser";
import { NoteSliderOverContext } from "contexts/note-slider";
import { NoteCommentCard } from "components/notes/list";
import { Tooltip } from "components/core/tooltip";
import { SearchUser } from "components/tools/search_user";
import { TutorSliderOverContext } from "contexts/tutor_slideover";
import { format, timeDay } from "d3";
import { dateParse, locale, parseWithTimeZone } from "utils/locale";
import { cronDecompose, cronsCompact, hasDispo } from "utils/crons";

function generateTextItems(fields, {
    formatTitle,
    formatValue = (d) => d,
    className ="",
    itemClassName,
    ...props
} = {}) {
    if (!fields) return [];
    if (typeof fields === 'string') fields = [fields];
    return fields.map((field) => {
        const [key, subkey] = field.split(".");
        return {
            field: (d) => d,
            title: formatTitle ? formatTitle(key) : key,
            className: "px-4",
            active: false,
            itemClassName: itemClassName || "px-4",
            ...props,
            FormatComponent: (d) => {
                return <div className={classNames(className, "")}>
                    {subkey ? formatValue(d[key]?.[subkey]) : formatValue(d[key])}
                </div>
            }
        }
    })
}

function InscriptionNote({note}){
    const {shouldOpenNote}  = useContext(NoteSliderOverContext);
    return <div key={note.id} className="relative px-3" 
        onClick={(e)=>{shouldOpenNote(note.id); e.stopPropagation()}} >
            <Tooltip
            position="center"
            color="light"
            delay={0}
            className="fixed translate-x-6"
            content={<NoteCommentCard {...note} noBorder />}>
            <span className="relative cursor-pointer">
                <FiFileText className="h-5 w-5 text-blue-500" />
            </span>
            </Tooltip>
        </div>
}
function InscriptionNotes({ tutor }) {
    const { t } = useTranslation("common");
    const notes = tutor?.inscription_notes || [];
    
    return <>
        <div className="flex items-center gap-2">
            {notes.map((note) => <InscriptionNote key={note.id} note={note} />)}
           {notes.length===0? 
           <Tooltip
            position="center"
            color="light"
            delay={0}
            className="fixed translate-x-6"
            content={t("no-note-should-create-manually")}>
            <span className="relative cursor-pointer">
                <FiFileText className="h-5 w-5 text-gray-300" />
            </span>
            </Tooltip>: null}
        </div>
    </>
}
function HasOnlineLunch({schedule_availabilities, schools, online_preference, on_site_preference, hybrid_preference}){
    /*
    Si un tuteur est en ligne : il faut qu'il ait au moins une disponibilité en semaine 
    (lundi au jeudi) d'une heure en soirée (entre 16h30 et 20h30). S'il a juste une 
    disponible le vendredi soir, ça ne fonctionne pas non plus. Il n'y a pas de tutorat le vendredi soir.
 
    Pour tous les autres tuteurs, la règle du vendredi soir s'applique aussi : Il faut 
    qu'ils auient au moins une autre disponibilité d'une heure sur l'heure du midi ou en soirée durant la semaine.

    Techniquement, il faudrait aussi une règle pour les tuteurs disponibles seulement à Pointe-Lévy. Il faudrait qu'ils
    aient au moins une disponibilité en soirée (entre 16h30 et 20h00 pour ceux en personne / hybride & entre 17h15 et 20h30 pour ceux en ligne)

    schedule_availabilities: List[objects] with from_cron, to_cron (cron == '0 17 * * 1-4' for example)
    */
   var isValid = true;
   const hasVanier = schools?.find((s) => s.slug === "ecole-secondaire-vanier");
   const hasPointeLevy = schools?.find((s) => s.slug === "ecole-secondaire-pointe-levy");
   const hasRogerComtois = schools?.find((s) => s.slug === "ecole-secondaire-roger-comtois");
   const hasCamaradiere = schools?.find((s) => s.slug === "ecole-secondaire-de-la-camaradiere");
   const hasCharlesbourg = schools?.find((s) => s.slug === "polyvalente-de-charlesbourg");
   const hasOnlyPointeLevy = hasPointeLevy && !hasVanier && !hasRogerComtois && !hasCamaradiere && !hasCharlesbourg;
   const rules = [];
   if (online_preference){
        const h0 = hasVanier? '16:30' :hasCamaradiere? '17:00':  '17:15';
        const h1 = '20:30';

        const hasEvening = schedule_availabilities?.find((s) => (
            hasDispo(s.from_cron, s.to_cron, ["Lundi", "Mardi", "Mercredi", "Jeudi"], h0, h1, 60)));
        rules.push(`[En ligne]`);
        rules.push(`Disponibilité en soirée (entre ${h0} et ${h1}) en semaine (lundi au jeudi)`);
        isValid = isValid && hasEvening;
    }
    else {
        // Must have at least one hour on other days than friday
        const h0e = hasVanier? '15:45' :hasCamaradiere? '16:15':  '16:30';
        const h1e = '20:00'

        const h0l = hasVanier? '11:30' :hasCamaradiere? '12:00':  '12:15';
        const h1l = hasVanier? '12:30': hasCamaradiere? '13:00': '13:15';

        const hasEvening = schedule_availabilities?.find((s) => (
            hasDispo(s.from_cron, s.to_cron, ["Lundi", "Mardi", "Mercredi", "Jeudi"], h0e, h1e, 60) ||
            (!hasOnlyPointeLevy && hasDispo(s.from_cron, s.to_cron, ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"], h0l, h1l, 60))
        ));
        rules.push(`${on_site_preference? '[En personne]': '[Hybride]'}`);
        rules.push(`Disponibilité en soirée (entre ${h0e} et ${h1e}) en semaine (lundi au jeudi)`);
        if (!hasOnlyPointeLevy){
            rules.push(`Disponibilité à l'heure du midi (entre ${h0l} et ${h1l}) en semaine (lundi au jeudi)`);
        }

        isValid = isValid && hasEvening;
    }
         
    const tooltipContent = (<div>
         {rules.map((r, i) => <div key={i}>{r}</div>)}
    </div>)
    
    return <Tooltip content={tooltipContent}>
        <span className="relative"><BooleanPill value={isValid} /></span>
        </Tooltip>
}

function UserInscriptionsWithContext({hideCanAssign}) {
    const { t } = useTranslation("common");
    const { loading, paging, setPage, reload, augmentedTutors: unsortedTutors } = useContext(TutorsContext)
    const [sortingField, setSortingField] = useState("inscription-date");
    const [sortingOrder, setSortingOrder] = useState(false);
    const tutors = useMemo(() => (unsortedTutors||[]).sort((a, b) => {
        var aobj, bobj;
        if (sortingField === "inscription-date") {
            // Do something else
            const insA = findActiveInscription(a?.active_periods || []);
            const insB = findActiveInscription(b?.active_periods || []);
            aobj = new Date(insA?.created_at);
            bobj = new Date(insB?.created_at);
        }
        else if (sortingField === "completed") {    
            aobj = isCompleted(a);
            if (aobj && a.antecedent_judiciaire_document_sent && a.antecedent_judiciaire_document_validated){
                aobj = 2;
            }
            bobj = isCompleted(b);
            if (bobj && b.antecedent_judiciaire_document_sent && b.antecedent_judiciaire_document_validated){
                bobj = 2;
            }
        }
        else if (sortingField === "tutor") {
            aobj = a?.name;
            bobj = b?.name;
        }
        else if (sortingField === "emails") {
            aobj = a?.mails?.length;
            bobj = b?.mails?.length;
        }
        else if (sortingField === "notes") {    
            aobj = a?.inscription_notes?.length;
            bobj = b?.inscription_notes?.length;
        }
        else {
            aobj = !!a[sortingField];
            bobj = !!b[sortingField];
        }
        if (sortingOrder) {
            return aobj > bobj ? 1 : -1;
        } else {
            return aobj > bobj ? -1 : 1;
        }
    }), [unsortedTutors, sortingField, sortingOrder]);

    const [group, setGroup] = useState("inscription");
    const { filters, dispatchFilters } = useContext(SearchContext);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    // const [user,] = useUser();
    const {shouldOpenTutor } = useContext(TutorSliderOverContext);
    const statusFields = [
        "contrat_engagement_document_received",
        "cyber_security_policy_document_received",
        "code_ethique_document_received",
        "--separator--1",
        "antecedent_judiciaire_document_received",
        "antecedent_judiciaire_document_conform",
        "antecedent_judiciaire_document_sent",
        "antecedent_judiciaire_document_validated",
        "--separator--2",
        "schedule_availabilities",
        "schools",
        "subjects",
        "has_tutoring_type",
        "--separator--3",
        "facebook_account",
        "facebook_friend_status",
        "call_made",
        "formation_completed",
    ]
    const numCompleted = useMemo(() => tutors?.filter((d)=>isCompleted(d) && (!d?.antecedent_judiciaire_document_validated || !d?.antecedent_judiciaire_document_sent)).length, [tutors]);
    const numNotCompleted = useMemo(() => tutors?.filter((d) => !isCompleted(d)).length, [tutors]);
    const numReadyForPairing = useMemo(() => tutors?.filter((d) => isCompleted(d) && d?.antecedent_judiciaire_document_validated && d?.antecedent_judiciaire_document_sent ).length, [tutors]);

    const headers = useMemo(() => {
        const inscriptionStatusItems = [
            {
                field: (d) => d,
                title: t("emails"),
                className: "px-4",
                headerId: "emails",
                active: false,
                itemClassName: "px-4",
                FormatComponent: (d) => <div>
                    <Mails mails={d?.mails || []} />
                </div>
            },
            ...statusFields.map((field) => {
                if (field.startsWith("--separator--")){
                    return {
                        field: field,
                        headerId: field,
                        className: "px-0",
                        itemClassName: "px-0 border-r",
                    }
                }
                if (field === "facebook_friend_status"){
                    return {
                        field: (d) => d,
                        title: t(`tutor-inscription-fields.${field}`),
                        info: t(`tutor-inscription-fields.${field}-info`),
                            headerId: field,
                            className: "px-4",
                        itemClassName: "px-4",
                        FormatComponent: (d) => <div className="">
                            <BooleanPill value={d[field]} />
                        </div>
                    }
                }
                if (field === "call_made"){
                    return {
                        field: (d) => d,
                        title: t(`tutor-inscription-fields.${field}`),
                        info: t(`tutor-inscription-fields.${field}-info`),
                            headerId: field,
                            className: "px-4",
                        itemClassName: "px-4",
                        FormatComponent: (d) => <div className="">
                            <BooleanPill value={d[field]} showDate={true} />
                        </div>
                    }
                }
                if (field === "formation_completed"){
                    return {
                        field: (d) => d,
                        title: t(`tutor-inscription-fields.${field}`),
                        info: t(`tutor-inscription-fields.${field}-info`),
                            headerId: field,
                            className: "px-4",
                        itemClassName: "px-4",
                        FormatComponent: (d) => <div className="">
                            <BooleanPill value={d[field]} showDate={true}/>
                        </div>
                    }
                }
                return {
                    field: (d) => d,
                    title: t(`tutor-inscription-fields.${field}`),
                    info: t(`tutor-inscription-fields.${field}-info`),
                        headerId: field,
                        className: "px-4",
                    itemClassName: "px-4",
                    FormatComponent: (d) => <div className="">
                        <BooleanPill value={hasValue(d[field])} />
                    </div>
                }
            }),
            
        ]
        const generalItems = [
            ...generateTextItems(["email", "phone", "city", "street", "zipcode", "gender", "occupation.name", "occupation_location", "occupation_program", "tutor_or_parent_is_immigrant", "tshirt_size"], { formatTitle: (v) => t("tutor-inscription-fields." + v) }),
        ]
        const preferencesItems = [
            ...generateTextItems("subjects", { formatTitle: (v) => t("tutor-inscription-fields." + v),
                itemClassName: "px-4  text-sm",
                 formatValue: (d) => d?.map((s) => s.name).join(", ")}),
            ...generateTextItems("comment_on_prefered_subjects", { formatTitle: (v) => t("tutor-inscription-fields." + v) }),
            ...generateTextItems("schools", 
                { formatTitle: (v) => t("tutor-inscription-fields." + v), 
                itemClassName: "px-4 text-sm",
                    formatValue: (d) => d?.map((s) => s.short_name).join(", ")}),
            {
                field: (d)=> d?d.schedule_availabilities: 'schedule_availabilities',
                title: t("tutor-inscription-fields.schedule_availabilities"),
                className: "px-4",
                itemClassName: "px-4 min-w-[200px] text-sm",
                format: (d) => cronsCompact(d),

            },{
                field: (d)=> d?d: 'lunch_schedule_availabilities',
                title: t("tutor-inscription-fields.lunch_schedule_availabilities"),
                className: "px-4",
                itemClassName: "px-4 min-w-[200px] text-sm",
                FormatComponent: (d) => <div className="">
                    <HasOnlineLunch {...d} />
                </div>
            },
            {
                field: (d) => d? d: 'tutoring-type-pref',
                title: t("tutor-inscription-fields.tutoring_type"),
                className: "px-4",
                itemClassName: "px-4",
                FormatComponent: (d) => <div className="">
                    <PreferenceCell {...d} />
                </div>

            },
            ...generateTextItems(
                [
                    "student_with_troubles",
                    "precision_on_student_with_troubles",
                    "student_in_frencization_class",
                    "precision_on_student_in_frencization"
                ],
                {
                    formatTitle: (v) => t("tutor-inscription-fields." + v)
                }
            ),
        ]
        const recruitmentItems = generateTextItems(["how_have_you_heard", "how_have_you_heard_other"], { formatTitle: (v) => t("tutor-inscription-fields." + v) });
        const implicationsItems = [
            ...generateTextItems(["have_you_ever_been_tutor"], { formatTitle: (v) => t("tutor-inscription-fields." + v) }),
            ...generateTextItems(["active_periods"], {
                formatTitle: () => t("source"), formatValue: (d) => {
                    const source = findActiveInscription(d)?.source;
                    return source ? t(`inscription-sources.${source}`) : "";
                }
            }),
            ...generateTextItems(["why_get_in", "active_since"], { formatTitle: (v) => t("tutor-inscription-fields." + v) }),
            ...generateTextItems(["created_at"], { formatTitle: (v) => t("tutor-inscription-fields." + v), formatValue: (d) => new Date(d).toLocaleDateString('en-CA') }),
            ...generateTextItems(["active_periods"], { formatTitle: (v) => t("tutor-inscription-fields." + v), formatValue: (d) => [...new Set(d?.map((p) => p.name))].join(", ") }),
            ...generateTextItems(["retired_of_service_since", "reason_for_end_of_service"], { formatTitle: (v) => t("tutor-inscription-fields." + v) }),
        ]
        const commentsItems = generateTextItems(["comments", "form_comments"], { formatTitle: (v) => t("tutor-inscription-fields." + v) });
        const headerGroups = {
            inscription: inscriptionStatusItems,
            general: generalItems,
            preferences: preferencesItems,
            recruitment: recruitmentItems,
            implications: implicationsItems,
            comments: commentsItems
        }
        const items = [
         
            {
                field: (d) => d,
                title: t("tutor"),
                headerId: "tutor",
                className: "pl-3",
                active: false,
                itemClassName: "py-3 pl-3  md:min-w-[220px] lg:min-w-[300px] min-w-[220px] h-full",
                FormatComponent: (d) => <div className="">
                    <Button color="hiddenLink" href={`/tutors/${d?.ni}`} onClick={(e) => e?.stopPropagation()}>{d?.name}</Button>
                    {!d?.subscribed && <div className="text-xs text-red-500">{t("auto-emails-disabled")}</div>}
                    {d?.retired_of_service_since && <div className="text-xs text-cyan-500">{t("tutor-is-retired", {date: locale.format('%d %B')(dateParse(d?.retired_of_service_since))})}</div>}
                </div>
            },
            {
                field: (d) => d,
                title: t("inscription-date"),
                className: "",
                active: false,
                itemClassName: "!pl-0 whitespace-nowrap",
                FormatComponent: (d) => {
                    const inscription = findActiveInscription(d?.active_periods || []);
                    const formattedDate = inscription && locale.format("%d %b")(new Date(inscription?.created_at));
                    return <div>
                        <span className={"tabular-nums"}>{formattedDate}</span>
                        <span className={"text-gray-500 text-sm ml-1 "}>({t("num-days", {count:timeDay.count(parseWithTimeZone(inscription?.created_at), new Date())})})</span>
                    </div>
                }
                
            },
            {
                field: (d) => d,
                title: t("completed"),
                className: "",
                headerId: "completed",
                active: false,
                itemClassName: "!pl-0",
                FormatComponent: (d) => <InscriptionStatus tutor={d} />
            },
            {
                field: (d) => d,
                title: t("notes"),
                headerId: "notes",
                className: "",
                active: false,
                itemClassName: "!pl-0",
                FormatComponent: (d) => <InscriptionNotes tutor={d} />
            },
            
            ...(headerGroups[group] || []),
        
        ]
        return items;
    }, [tutors, selected, group]);
    return <>
        {/* <SectionHeading title={t("tutors")} /> */}
        <div className="flex items-center justify-between  border-b gap-6">
            <div className="flex items-center gap-2">
                <div className=" relative">
                {
                    loading ?
                        <Spinner className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" /> :
                        <MagnifyingGlassIcon
                            className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                }
                <ListSearch color="none"
                    noIcons
                    loading={loading}
                    search={true}
                    className="w-full h-12 pr-4 text-gray-900 bg-transparent border-0 pl-11 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder={t("search-by-name")}
                    onChange={(value) => dispatchFilters({ type: 'search', value })}
                />
               
        </div>
        
            <FilterGroup
                    value={group}
                    setValue={setGroup}
                />
            {!hideCanAssign && <>
                <Button color="gray" size="sm" onClick={() => setSelected(tutors)}>
                    {t("search-tutors.select-all")}
                </Button>
                <Button color="delete" disabled={!selected || selected.length === 0} size="toolbarIcon" onClick={() => setSelected([])}>
                    <FiTrash />
                </Button></>}
            </div>
            <div className="flex gap-2 items-center mr-2">
            {!hideCanAssign && <><div className="">
                    <Button color="gray" disabled={!selected || selected.length === 0} size="sm" onClick={() => setOpen(true)}>
                        {t("assignation.title")} ({selected.length})
                    </Button>
                    <Assignation
                        values={selected}
                        setValues={setSelected}
                        open={open}
                        setOpen={setOpen}
                        onAssign={() => reload?.()}
                    />
                </div></>}
                <div className="flex items-center gap-3">
                    <span className="text-gray-500 text-sm">{t("tutors-assigned-to")}</span>
                <SearchUser
                    value={filters.assignedTo}
                    setValue={(value)=>dispatchFilters({ type: 'assignedTo', value })}
                    defaultFields={["id", "email", "name", "firstname", "lastname"]}
                />
                <Button color="gray" size="sm" onClick={reload}>
                    {t("reload")}
                </Button>
                </div>
                
            </div>
        </div>
        
        <div>
            <div className="grid grid-cols-2 md:grid-cols-3 p-1 gap-6">
                {/* not-completed */}
                <div className="bg-red-100 rounded-md p-2 flex space-x-4 items-center">
                    <span>{t("not-completed")}</span>
                    <span className="text-xl">
                        {numNotCompleted}/{tutors?tutors.length: '-'}
                        <span className="text-sm text-gray-800 ml-3">{tutors && tutors.length? format(".2%")(numNotCompleted/(tutors? tutors.length: 1e-8)): '- %'}</span>
                    </span>
                </div>
                
              {/* notCompleted */}
                <div className="bg-gray-100 rounded-md p-2 flex space-x-4 items-center">
                    <span>{t("completed")}</span>
                    <span className="text-xl">
                        {numCompleted}/{tutors?tutors.length: '-'}
                        <span className="text-sm text-gray-800 ml-3">{tutors && tutors.length? format(".2%")(numCompleted/(tutors? tutors.length: 1e-8)): '- %'}</span>
                    </span>
                </div>
                {/* readyForPairing */}
                <div className="bg-green-100 rounded-md p-2 flex space-x-4 items-center">
                    <span>{t("ready-for-pairing")}</span>
                    <span className="text-xl">
                        {numReadyForPairing}/{tutors?tutors.length: '-'}
                        <span className="text-sm text-gray-800 ml-3">{tutors && tutors.length? format(".2%")(numReadyForPairing/(tutors? tutors.length: 1e-8)): '- %'}</span>
                    </span>
                </div>
            </div>
            <div className={classNames(tutors && tutors.length > 0 && "overflow-x-auto overflow-y-visible parent-boundary min-h-[600px]", tutors && tutors.length === 0 && "overflow-hidden")}>
                <Table
                    rowClassName={(d) => classNames("group ", d.retired_of_service_since? "bg-gray-200 ":
                        isCompleted(d) &&d.antecedent_judiciaire_document_sent && d.antecedent_judiciaire_document_validated? "bg-green-100/40":
                        isCompleted(d)? "bg-yellow-100/40": "bg-red-100/40")}
                    data={loading ? [] : tutors}
                    headers={headers}
                    order={{by:sortingField, direction:sortingOrder?"desc":"asc"}}
                    onHeaderClick={(value)=>{(value===sortingField && setSortingOrder(d=>!d)); setSortingField(value)}}
                    onRowClick={(d)=>shouldOpenTutor(d?.ni)}
                    indexingKey={(d) => d?.ni}
                />
            </div>
            {
                loading && <div className="p-2">
                    <Skeleton className="w-full h-32" />
                </div>
            }

            {
                !loading && tutors?.length === 0 && <div className="p-2 flex justify-center text-gray-500">
                    {t("no-tutors")}
                </div>
            }

            <div className="px-4 pb-4">
                {paging && <Paginate setPage={setPage} {...paging} />}
            </div>

           
        </div>
    </>
}

function FilterGroup({ value, setValue }) {
    const { t } = useTranslation("common");
    const values = [
        { name: t("recruiting-groups.inscriptions"), value: "inscription" },
        { name: t("recruiting-groups.general"), value: "general" },
        { name: t("recruiting-groups.preferences"), value: "preferences" },
        { name: t("recruiting-groups.recruitment"), value: "recruitment" },
        { name: t("recruiting-groups.implications"), value: "implications" },
        { name: t("recruiting-groups.comments"), value: "comments" },
    ];
    const findValue = values.find(v => v.value === value);
    return <SelectMenu
        buttonClassName="!min-w-[10rem]"
        values={values}
        color="gray"
        value={findValue}
        setValue={(d) => setValue(d?.value)}
        indexKey="name"
    />
}

export function UserInscriptions() {
    const period = getCurrentPeriod();
    const [user,] = useUser();
    return <SearchContextProvider doNotStore defaultState={{ period, assignedTo: user }}>
        <TutorsContextProvider
            fields={
                [
                    "assigned_user",
                    "contrat_engagement_document_received",
                    "cyber_security_policy_document_received",
                    "code_ethique_document_received",
                    "antecedent_judiciaire_document_received",
                    "schedule_availabilities",
                    "online_preference",
                    "on_site_preference",
                    "hybrid_preference",
                    "facebook_account",
                    "formation_completed",
                    "schools",
                    "subjects",
                    "email",
                    "phone",
                    "gender",
                    "city",
                    "street",
                    "zipcode",
                    "occupation",
                    "occupation_location",
                    "occupation_program",
                    "tutor_or_parent_is_immigrant",
                    "tshirt_size",
                    "comment_on_prefered_subjects",
                    "student_with_troubles",
                    "precision_on_student_with_troubles",
                    "student_in_frencization_class",
                    "precision_on_student_in_frencization",
                    "how_have_you_heard",
                    "how_have_you_heard_other",
                    "have_you_ever_been_tutor",
                    "active_periods",
                    "why_get_in",
                    "active_since",
                    "created_at",
                    "retired_of_service_since",
                    "reason_for_end_of_service",
                    "comments",
                    "form_comments",
                    "mails",
                    "subscribed",
                    "antecedent_judiciaire_document_conform",
                    "antecedent_judiciaire_document_sent",
                    "antecedent_judiciaire_document_validated",
                    "facebook_friend_status",
                    "inscription_notes",
                    "call_made"
                ]
            }
            //  Sort by data of inscription
            jsSortBy={(a, b)=>{
                const insA = findActiveInscription(a?.active_periods || []);
                const insB = findActiveInscription(b?.active_periods || []);
                return new Date(insA?.created_at) > new Date(insB?.created_at) ? -1 : 1;
            }}
            augment={(d) => {
                const has_tutoring_type = d?.online_preference || d?.on_site_preference || d?.hybrid_preference;
                return ({ ...d, has_tutoring_type });
            }}
        >
            <UserInscriptionsWithContext hideCanAssign />
        </TutorsContextProvider>
    </SearchContextProvider>
}