/*
  Source: https://stackoverflow.com/questions/4059147/check-if-a-variable-is-a-string-in-javascript
*/
export function isString(myVar){
  return (typeof myVar === 'string' || myVar instanceof String)
}

export function isBool(myVar){
  return (typeof myVar === 'boolean' || myVar instanceof Boolean)
}

export function isDate(myVar){
  return(Object.prototype.toString.call(myVar) === "[object Date]")
}

export function isInteger(myVar){
  return(Number.isInteger(myVar))
}

// Check if a string is a number
export function stringIsInteger(value){
  // Comare with regex
  return /^\d+$/.test(value);
}