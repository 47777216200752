import React from 'react';

// API
import { searchGroupedStudySessions} from 'api/study_sessions';

// Hooks
import { useCallback, useContext, useMemo, useState } from 'react';
import { useSearch } from 'hooks/useSearch';
import { useWeek } from 'hooks/dates/useWeek'
import { useTranslation } from 'react-i18next';

// Utils
import classnames from 'classnames';
import { locale, dateParse } from 'utils/locale';
import { timeWeek } from 'd3-time';
const fullDayFormat = locale.format("%d %B %Y");

// Components
import { Button } from 'components/core/button';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Table } from 'components/core/table';
import { Paginate } from 'components/core/paginate';
import { VerificationFlag } from 'components/student/verfication_flag';
import { EmptyState } from 'components/core/empty';
import { Skeleton } from 'components/core/skeleton';
import { Fragment } from 'react';
import { Tooltip } from 'components/core/tooltip';
import { TooltipContent } from 'components/events/play_time_view';
import { cellStyles } from 'components/events/play_time_view';

function ChangeWeek({ date, setDate}){
  return <div className="flex items-center justify-center">
            <div className="flex items-center">
            <Button className="px-2 py-2 rounded-full hover:bg-gray-100 flex-0" color="none" layout="none" onClick={()=>setDate(timeWeek.offset(date, -1))}><FiChevronLeft/></Button>
             <div className="py-1 text-lg text-left ">
                {fullDayFormat(date)}
              </div>
              <Button className="px-2 py-2 rounded-full hover:bg-gray-100 flex-0" color="none" layout="none" onClick={()=>setDate(timeWeek.offset(date, 1))}><FiChevronRight/></Button>
            </div>
        </div>
}


export function Cell({studySessions, fails, position="center"}){
  const fraction = `${studySessions.length} / ${fails.length}`;

  var color = "green";
  if (studySessions.length>0){
    if (studySessions.length<fails.length){
      color = "yellow";
    }
    if (studySessions.length>fails.length){
      color = "blue";
    }
  }
  else{
    if (fails.length>0) color = "red";
    else color = "green";
  }

  const content = (<span className={classnames("py-1 px-2 mx-auto rounded-sm text-center relative whitespace-nowrap", 
  cellStyles[color])}>{fraction}</span>)

  return <span className='relative'>
          <Tooltip content={<TooltipContent studySessions={studySessions} fails={fails}/>} 
                  position={position}
                  color="light" 
                  delay={0} 
                  className="translate-x-6 ">{content}</Tooltip>
         </span>
}


export function StudentsWithFlagList({Context}){
  const { t } = useTranslation("common");
  const {filters} = useContext(Context);
  const [date, setDate] = useState(timeWeek.floor(new Date()));
  const {fromDate, toDate} = useWeek(date, {weeks: 3});
  const teamMemberParams = useMemo(()=>({...filters, studySessionVerificationFlag:true, fromDate, toDate, useInCount:true, stepDays:7, datePartition: true}), [fromDate, filters]);
  const [results, {setPage, paging, setResult}] = useSearch(searchGroupedStudySessions, teamMemberParams, {immediate: true, limit:10});

  const onFlagChange = useCallback(({ni, study_session_verification_flag})=>{
    setResult(d=>d.map(e=>e.student.ni===ni? ({...e, student: {...e.student, study_session_verification_flag}}) :e))
  }, [])

  const headers = useMemo(()=>{
    if (!results || results.length===0) return [];
    var h = [{
                title: t("students"),
                field: (d=>d?d.student: "student"),
                FormatComponent: (d=><Button href={`/students/${d.ni}`} color="link">{d.name}</Button>),
                itemClassName: (d=>classnames("font-medium whitespace-nowrap !py-1", 
                                              d && d.study_session_verification_flag && "text-red-600")),
            }];

    // Add an header for each date
    results[0].results.forEach((e,i)=>{
      const header = {
        title: locale.format("%d %b")(dateParse(e.date)),
        field: (d=>d? ({studySessions: d.results[i].study_sessions, fails: d.results[i].fails}): `date-${i}`),
        headerId: locale.format("%d %B")(dateParse(e.date)),
        FormatComponent: Cell,
        itemClassName: "text-center",
        className: "text-center whitespace-nowrap"
      }
      h.push(header)
    })

    // Add verification flag
    h.push({
      title: "",
      field: (d=>d? ({...d.student, position: "right", onChange: onFlagChange}) : "verification-flag"),
      FormatComponent: VerificationFlag,
    })
    return h;
  }, [results])

  return <div className="w-full min-h-full ">
          <div className="items-center justify-between my-auto sm:flex ">
            <p className="text-lg">{t("students-with-flag")}</p>
            {results && results.length>0 ?<ChangeWeek date={date} setDate={setDate}/>: null}
          </div>
           {!results? <Skeleton className="w-full h-80"/>
            :results.length===0?
            <EmptyState 
                className="mb-auto"
                title={t("empty-state.no-students-with-flag-title")} 
                description={t("empty-state.no-students-with-flag-description")}/>:
                <Fragment>
                  <Table headers={headers} data={results} indexingKey={(d=>`${d.student.ni}`)}/>
                </Fragment>}
          {paging&& <Paginate setPage={setPage} {...paging}/>}
        </div>
}
