import React from 'react';

// Components
import { Container } from 'components/core/container';
import { CoachOpenSettings } from 'components/coachs/modify';

// Hooks

// Contexts

export function OpenSettings(){

  return <Container className="h-full w-full">
            <CoachOpenSettings/>
         </Container>

}
