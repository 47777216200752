import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useContext, useState, useMemo } from 'react';
import { useSearch } from 'hooks/useSearch';

// Components
import { Fragment } from 'react';
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Paginate } from 'components/core/paginate';
import { Error } from 'components/core/typo'
import { Table } from 'components/core/table'
import { Info } from 'components/core/info'
import { CreateSchoolSubject } from 'components/school-subjects/create';
import { ModifySchoolSubject } from 'components/school-subjects/modify';
import { Button } from 'components/core/button';
import { SlideOver } from 'components/core/slide_over';

// Contexts
import { TableOrderingContext, TableOrderingContextProvider } from 'contexts/table_ordering';

// API
import { searchSchoolSubjects } from 'api/utils';

function Empty(){
  const { t } = useTranslation('common');
  return <EmptyState
            className="flex flex-col justify-center h-full"
            title={t("empty-state.no-failed-classes-title")}>
         </EmptyState>
}

function CreateButton({onChange}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("add-school-subject")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <CreateSchoolSubject onCompleted={()=>{setOpen(false); onChange()}}/>
          </SlideOver>
        </Fragment>
}

function ModifyButton({value, setValue}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("modify")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <ModifySchoolSubject value={value} setValue={setValue}/>
          </SlideOver>
        </Fragment>
}
function SubjectsTable({subjects, setSubjects, execute, paging}){
  const { t } = useTranslation('common');
  const {filters, dispatchFilters } = useContext(TableOrderingContext)

  const headers = useMemo(()=>[
    {
        title: t("name"),
        field: (d=>d?d.name: "name"),
        itemClassName: "!py-1"

    },
    {
     field: (d=>d? ({value:d, setValue: ((next)=>setSubjects(current=>current.map(e=>e.slug===next.slug? next:e)))}) : "Modifier"),
     itemClassName: "text-gray-600 text-right py-2",
     FormatComponent: ModifyButton
    },
  ], [subjects])

  return <Fragment >
            <Info.Container
                className="px-3 pt-2 pb-3"
                description={t("school-subjects-description")}
                modify={true}
                sideHeader={<CreateButton onChange={execute}/>}
                label={t("school-subjects")}>
              <Table headers={headers}
              data={subjects}
              indexingKey={"slug"}
              order={{by:filters.orderBy, direction:filters.orderDirection}}
              onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
            </Info.Container>
            {paging&& <Paginate {...paging}/>}
          </Fragment>
}

export function ControlledSubjectsList({subjects, setSubjects, paging, execute, error}){
  const { t } = useTranslation('common');

  if (!subjects){
    return <Skeleton.List numElements={1} itemClassName="h-32" className="space-y-2"/>
  }

  return <div className="">
              {subjects.length===0?
                <Info.Container className="px-3 pt-2 pb-3" label={t("archived-failed-subjects")}>
                  <Empty/>
                </Info.Container>:<SubjectsTable subjects={subjects} setSubjects={setSubjects} execute={execute} paging={paging}/>
              }
              {error? <Error.Text className="mt-3" {...error}/>: null}
          </div>
}


export function SubjectsListWithContext(){
  const { filters} = useContext(TableOrderingContext)
  const [subjects, {setResult, loading, execute, error, page, setPage, paging}] = useSearch(searchSchoolSubjects, filters, {limit:100});

  return <ControlledSubjectsList subjects={subjects} setSubjects={setResult} paging={{...paging, page, setPage}} execute={execute} loading={loading} error={error}/>
}

export function SubjectsList(){
  return <TableOrderingContextProvider
                defaultState={{orderBy: "name", orderDirection: "asc"}}>
          <SubjectsListWithContext/>
          </TableOrderingContextProvider>
}


