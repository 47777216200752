import React from 'react';

// Components
import { Container } from 'components/core/container';
import { StudySessionStats } from 'components/follow-up/stats';

// Hooks
import { useContext } from 'react';

// Contexts
import { SearchFollowUpContext } from 'contexts/search_follow_up';

export default function Stats(){
  const {filters} = useContext(SearchFollowUpContext);
  return <div className="pb-6">
            <Container >
              <StudySessionStats {...filters}/>
            </Container>
         </div>
}
