import React, { useReducer, createContext } from "react";

function filterReducer(state, action){
  switch (action.type){
    case 'ordering':
      if (state.orderBy===action.value){
        if (state.orderDirection==='desc'){
          return {...state, orderBy: action.value, orderDirection: 'asc'};
        }
        return {...state, orderBy: null, orderDirection: null};
      }
      return {...state, orderBy: action.value, orderDirection: 'desc'};
    default:
      return state
  }
}

const TableOrderingContext = createContext({});

const TableOrderingContextProvider = ({ defaultState, children }) => {
  const [filters, dispatchFilters] = useReducer(filterReducer, defaultState || {});

  return (
    <TableOrderingContext.Provider value={{ filters, dispatchFilters }}>
      {children}
    </TableOrderingContext.Provider>
  );
};

export { TableOrderingContext, TableOrderingContextProvider };