import React from 'react';
import classnames from 'classnames';

// API
import { searchSportTeams } from 'api/teams';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import { SearchContext } from 'contexts/search';

// Components
import { Button } from 'components/core/button';
import { Skeleton } from 'components/core/skeleton';
import { ListSearch } from 'components/core/inputs';
import { EmptyState } from 'components/core/empty';
import { PaginateCompact } from 'components/core/paginate';
import { Error } from 'components/core/typo'

function TeamCell({ slug, name, active_period }){
  return <Button className="p-3" layout="cell" nav  color={(d)=>d? "activeCell": "cell"} href={`/teams/${slug}`}>
          {({isActive})=>
          <div className="flex items-center justify-between">
            <p className={classnames(isActive&& "font-medium text-white")}>{name}</p>
            <p className={classnames("text-gray-800 text-sm", isActive&& "text-white")}>{active_period && active_period.name}</p>
          </div>}
         </Button>
}

export function TeamsList(){
  const { t } = useTranslation("common");
  const { filters, dispatchFilters } = useContext(SearchContext);
  const [teams, {loading, paging, hasMore, error, setPage}] = useSearch(searchSportTeams, filters, {limit:500, fields: ["slug", "name"]});

  if (!teams){
    return <Skeleton.List itemClassName="h-8 rounded" className="space-y-3 p-3" numElements={4}/>
  }
  return <div>
          <div className="border-b">
            <ListSearch color="none"
                loading={loading}
                search={true}
                placeholder={t("search-by-name")}
                onChange={(value)=>dispatchFilters?.({type: 'search', value})} />
          </div> 
            {error? <Error.Text className="mt-3" {...error}/>: null}
          {teams.length===0?
            <EmptyState 
                title={t("empty-state.no-teams-title")} 
                description={t("empty-state.no-teams-description", {search: filters.search})}/>:
            <div className="divide-y">
            {teams.map(d=><TeamCell key={d.slug} {...d}/>)}
          </div>}
            {paging&& hasMore && <PaginateCompact setPage={setPage} {...paging}/>}
        </div>
}

