import React from 'react';
// import classnames from 'classnames';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { Outlet } from "react-router-dom";
import { FilterBar } from 'components/follow-up/filter_bar';

// Contexts
import { SearchFollowUpContextProvider } from 'contexts/search_follow_up';

// Hooks
import { useMatch } from 'react-router-dom';

export function WeeklyFollowUpBaseWithContext(){
  const match = useMatch(`/suivi-terrain/:id/:tab`);

  return <DashboardLayout>
            <FilterBar presetTo={(d)=>d?`/suivi-terrain/${d.id}${match &&match.params && match.params.tab? `/${match.params.tab}`: "/bdi"}`: `/suivi-terrain`} limitToUser={true} searchPreset />
            <Outlet/>
           </DashboardLayout>
}

export function WeeklyFollowUpBase(){
  return <SearchFollowUpContextProvider  storageKey="suivi-terrain">
            <WeeklyFollowUpBaseWithContext/>
          </SearchFollowUpContextProvider>
}

import {default as empty } from 'pages/terrain-follow/select'
import {default as bdi} from 'pages/terrain-follow/bdi'
import {default as events} from 'pages/terrain-follow/events'
import {default as groupedNotes} from 'pages/terrain-follow/grouped_notes'
import {default as groupedDocuments} from 'pages/terrain-follow/grouped_documents'
import {default as stats} from 'pages/terrain-follow/stats'
import {default as aad} from 'pages/terrain-follow/aad'
import {default as meetings} from 'pages/terrain-follow/meetings'

export const TerrainFollowUpPages = {root: WeeklyFollowUpBase, empty, bdi, events, groupedNotes, groupedDocuments, stats, aad, meetings}