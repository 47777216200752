/* eslint-disable */
import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';

// Constants
import { MIFI_CODE, GEN_2_CODE } from 'components/follow-up/meetings/mifi';

// Utils
import classNames from 'classnames';

// Hooks
import { useStudentsMeetings } from 'contexts/student-meetings';
import { useTranslation } from 'react-i18next';

export function MeetingsSummary() {
    const {t} = useTranslation("common");
    const { students, loading } = useStudentsMeetings();
    const nbStudents = students?.length || 0;
    const mifis = students?.filter(s => s?.mifi?.some(m => m.code === MIFI_CODE || m.code?.code === MIFI_CODE)) || [];
    const gen2s = students?.filter(s => s?.mifi?.some(m => m.code === GEN_2_CODE || m.code?.code === GEN_2_CODE)) || [];
    const meetings = students?.reduce((acc, s) => [...acc, ...(s.meetings || [])], []);
    return <>
        <SummaryStatsList>
            <SummaryStat title={t("students")} loading={loading}>{nbStudents}</SummaryStat>
            <SummaryStat title="MIFI" loading={loading}>{mifis?.length}</SummaryStat>
            <SummaryStat title="2e GEN" loading={loading}>{gen2s?.length}</SummaryStat>
            <SummaryStat title="Rencontres" loading={loading}>{meetings?.length}</SummaryStat>
        </SummaryStatsList>
    </>
}

export function SummaryStat({title, loading, children}){
    
    return <>
        <div className='flex flex-col'>
            <div className='text-sm text-gray-500'>{title}</div>
            {
                loading ? <Skeleton className='h-8' /> : <div className='text-2xl font-bold'>{children}</div>
            }
        </div>
    </>
}

export function SummaryStatsList({children, className}){
    return <div className={classNames('py-2 flex gap-8', className)}>
        {children}
    </div>
}