
/* eslint-disable react/no-unknown-property */ 
import React from 'react';
import Quill from "quill";
import classNames from 'classnames';

const FontAttributor = Quill.import('attributors/class/font');
FontAttributor.whitelist = [
  'sans-serif',
  'serif',
  'monospace'
];

const SizeAttributor = Quill.import('attributors/style/size');
SizeAttributor.whitelist = [
    '0.7em',
    '1em',
    '1.5em',
    '2em'
];

Quill.register(FontAttributor, true);
Quill.register(SizeAttributor, true);

const colorsData = {
    "black": "#000000",
    "red": "#ef4444",
    "green": "#22c55e",
    "blue": "#3b82f6",
    "yellow": "#eab308",
    "orange": "#f97316",
    "violet": "#a855f7",
    "white": "#ffffff",
    "transparent": "transparent"
}

const colors = [ colorsData.black, colorsData.white, colorsData.red, colorsData.green, colorsData.blue, colorsData.yellow, colorsData.orange, colorsData.violet]
const bgColors = [ colorsData.transparent, colorsData.black, colorsData.red, colorsData.green, colorsData.blue, colorsData.yellow, colorsData.orange, colorsData.violet]

const formats = [
    {className:"ql-bold"},{className:"ql-italic"},{className:"ql-underline"},{className:"ql-strike"}, {className:"ql-link"},
]



const renderSingle = (formatData, index)=>{
    const {className, value} = formatData;
    return (
        <button key={className + index} className={className} value={value}></button>
    )
}

export function CustomToolbar(){
   return <div className={classNames('flex flex-wrap items-center ')}>
        
        <select className="mr-auto ql-size" defaultValue={"1em"}>
            <option className='ql-size-yolo' value="0.7em">Small</option>
            <option value="1em">Normal</option>
            <option value="1.5em">Large</option>
            <option value="2em">X-Large</option>
        </select>

        {/* <select className="mr-5 ql-font">
            <option value="sans-serif"></option>
            <option value="serif">Serif</option>
            <option value="monospace">Monospace</option>
        </select> */}

        <span className="ql-formats !mr-0 ml-auto">
            <button className="ql-list" value="ordered" ngbpopover="Ordered list" triggers="mouseenter:mouseleave"></button>
            <button className="ql-list" value="bullet" ngbpopover="Bulleted list" triggers="mouseenter:mouseleave"></button>

        </span>

        <span className="mr-3 ql-text-align">
            <select className="ql-align" defaultValue={""}>
                    <option value=""></option>
                    <option value="center"></option>
                    <option value="right"></option>
            </select>
        </span>
        <span className="flex justify-center mr-3 ql-formats ql-colors-select items-">
            <select className="ql-color" defaultValue={""}>
                {
                    colors.map((value) =>{
                        return (
                            <option key={'txtcolor'+value} value={value}></option>
                        )
                    })
                }
            </select>
            <select className="ql-background" defaultValue={""}>
                {
                    bgColors.map((value) =>{
                        return (
                            <option key={'bgcolor'+value} value={value}></option>
                        )
                    })
                }
            </select>
        </span>

        <span className="ql-formats">
            {
                formats.map((classes, i) => {
                    return (

                        renderSingle(classes, i)

                    )
                })
            }

        </span>

    </div>
}

  export default CustomToolbar;