import React from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiToggleRight } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';

export function SelectHasBeenMet({ value, setValue, period, disabled, ...props }){

  const { t } = useTranslation("common");
  const values = [
    {
      name: period? `Rencontrés en ${period.name}` : "Rencontrés cette année",
      short: "Rencontrés",
      value: true
    },
    {
      name: period? `Non rencontrés en ${period.name}` : "Non rencontrés cette année",
      short: "Non rencontrés",
      value: false
    }
  ]
  return <ListSelection value={value}
                        setValue={setValue}
                        disabled={disabled}
                        values={values}
                        label={t('has-been-met')}
                        info={t('has-been-met-info')}
                        Icon={FiToggleRight}
                        getKey={(d)=>d.name}
                        format={(d)=>d.name}
                        formatSelection={(d)=>d.short}
                        withClearSelection={true}
                        {...props}
                        />
}