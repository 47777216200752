import React from "react"

// Components
import { StudentsMetricsCard } from "components/coordo-stats/students-metrics"

// Hooks
import { useTranslation } from "react-i18next"

const scoresDefinitions = { 
    // score négatif (échecs)
    0 : [0, 10], // Vide
    1 : [11, 19], // Vert
    2 : [20, 29], // Jaune
    3 : [30, 45], // Orange
    4 : [46, 49], // Rouge
    5 : [50, 100], // Rouge 
}

const globalScoreDefinitions = {
    // score global négatif (échecs) -> Plus le score est élevé, plus il y a d'échecs
    "-" : [40, 100], // Rouge
    "+-" : [30, 39], // Jaune
    "+" : [0, 29] // Vert
}

export function Fails({...props}) {
    const { t } = useTranslation();
    return <>
        <StudentsMetricsCard 
            title={t("students-metrics.fails.title")}
            projectedStatKey="nbFails"
            infoDescription={t("students-metrics.fails.description")} 
            scoresDefinitions={scoresDefinitions} 
            globalScoreDefinitions={globalScoreDefinitions} 
            negative
            className="border-t-4 border-red-500"
            scoreClassName="!text-red-500"
            {...props}
        />
    </>
}

