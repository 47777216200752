import React from 'react';

// Components
import { Container } from 'components/core/container';
import { CoachCloseSettings } from 'components/coachs/modify';

// Hooks

// Contexts

export function CloseSettings(){

  return <Container className="h-full w-full">
            <CoachCloseSettings/>
         </Container>

}
