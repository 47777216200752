/* eslint-disable */
import React from 'react';

// API
import { deleteDocument, retrieveDocument, updateDocument } from 'api/documents';

// Components
import { CardContainer } from 'components/core/container';
import { Skeleton } from 'components/core/skeleton';
import { CardSectionHeading } from 'components/core/typo';
import { SingleLineField, ModifiableSelectField } from 'components/tools/modifiable_fields';
import { Info } from 'components/core/info';
import { SelectDate } from 'components/tools/select_date';
import { CheckBox } from 'components/tools/checkbox';
import { SelectPeriods } from 'components/tools/select_period';
import { UploadButton } from 'components/documents/upload';
import { Button } from 'components/core/button';
import { Error } from 'components/core/typo';
import { SelectStudentStatus } from 'components/documents/student-status';

// Utils
// import {dateParse} from 'utils/locale';

// Hooks
import { useAPI } from 'hooks/useAPI';
import { useQuery } from 'hooks/useQuery';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

function DeleteButton({ id, onDelete }) {
    const { t } = useTranslation('common');
    const [, { execute, loading, error }] = useAPI(deleteDocument,
        { id },
        { onResult: (() => onDelete({ id })), immediate: false })

    return <div className="w-full p-3 space-y-3 border-2 border-red-500 rounded-lg">
        <div>
            <h3 className="font-medium text-red-600">{t("delete-document.title")}</h3>
            <p className="text-gray-500">{t("delete-document.description")}</p>
        </div>
        <Button loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-document.button")}</Button>
        {error ? <Error.Text {...error} /> : null}
    </div>
}

export function DocumentSlideOver({ onUpdate, onDelete }) {
    const { t } = useTranslation('common');
    const { query } = useQuery();
    const params = useMemo(() => ({ id: query.get("documentId"), fields:["student"] }), [query]);
    const [doc, { setResult }] = useAPI(retrieveDocument, params, { validateParams: (d => d.id) })

    return <div className="flex-1 h-full overflow-hidden flex flex-col">
        <CardContainer className="mb-6">
            <CardSectionHeading
                title={<span className="flex items-center">
                    <div>{doc && doc.group.name}</div></span>} />
        </CardContainer>
        {doc ?
            <CardContainer className="mb-6 space-y-8">

                <ModifiableSelectField
                    Field={SelectDate}
                    label={t("handed-over-at-title")}
                    subLabel={t("handed-over-at-description")}
                    modify={true}
                    value={doc.handed_over_at && new Date(doc.handed_over_at)}
                    setValue={handedOverAt => {
                        updateDocument({ id: doc.id, handedOverAt });
                        onUpdate({ ...doc, handed_over_at: handedOverAt, handedOverAt });
                        setResult(d => ({ ...d, handed_over_at: handedOverAt }));
                    }} />
                <ModifiableSelectField
                    Field={SelectPeriods}
                    disabledClear
                    label={t("period")}
                    modify={true}
                    value={doc.period}
                    setValue={period => {
                        updateDocument({ id: doc.id, period });
                        onUpdate({ ...doc, period });
                        setResult(d => ({ ...d, period }));
                    }} />

                <ModifiableSelectField
                    Field={CheckBox}
                    label={t("fully-signed")}
                    subLabel={t("fully-signed-description")}
                    modify={true}
                    value={doc.fully_signed}
                    setValue={(fullySigned) => {
                        updateDocument({ id: doc.id, fullySigned });
                        onUpdate({ ...doc, fully_signed: fullySigned });
                        setResult(d => ({ ...d, fully_signed: fullySigned }))
                    }} />
                
                <div>
                    <SelectStudentStatus onUpdate={onUpdate} />
                </div>

                <SingleLineField
                    label={t("comments")}
                    modify={true}
                    value={doc.comments}
                    multiLine={true}
                    setValue={(comments) => {
                        updateDocument({ id: doc.id, comments });
                        onUpdate({ ...doc, comments });
                    }}>
                    {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("comments").toLowerCase()}`} />}
                </SingleLineField>

                <UploadButton documentId={doc.id} onCompleted={setResult} />
                <Button block disabled={!doc.url} size="lg" className="mt-6" color="black" href={doc.url} externalLink>{t("download")}</Button>


                <Info.Container modify={true} className="py-12">
                    <DeleteButton id={doc.id} onDelete={onDelete} />
                </Info.Container>

            </CardContainer> :
            <Skeleton className="h-80 w-full" />}
    </div>

}
