import React, { useMemo } from 'react';

// Components
import { InfoSectionHeading  } from 'components/core/typo';
import { Button } from 'components/core/button';

import { Error } from 'components/core/typo'
import { FlexNav } from 'components/core/layouts/flex';
import { TeachersUpload } from 'components/bulk-import/teachers';
import { SelectSchool } from 'components/tools/schools_list';
import { Table } from 'components/core/table';
import { EmptyState } from 'components/core/empty';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAPI } from 'hooks/useAPI';

// API
import { bulkInsertTeachers } from 'api/portal';

// Utils
import classnames from 'classnames';

// API
// import { closeAllActiveTeachers } from 'api/teachers';

function ApplyButton({value, school, onResult}){
  const { t } = useTranslation('common');
  const params = useMemo(() => ({teachers: value.map(d=>({name: d.Nom, email: d.Courriel})), school}), [value, school])
  const [, {execute, loading, error}] = useAPI(bulkInsertTeachers, params, {onResult, immediate:false})

  return <div className="relative">
          <InfoSectionHeading mediumTitle={t("bulk-import.teachers.summary.title")} longDescription={t("bulk-import.teachers.summary.description")}/>
            <div className={classnames(" rounded-lg p-3 w-full space-y-3 border-2 border-orange-500")}>
            <div>
              <h3 className="font-medium text-orange-600">{t("bulk-import.teachers.title")}</h3>
              <p className="text-gray-500">{t("bulk-import.teachers.final-description", {n: (value || []).length, school: (school || {name: "(À choisir)"}).name})}</p>
            </div>
            <Button loading={loading} onClick={execute} disabled={!value || value.length===0 || !school} block color="active" size="lg">{t("bulk-import.teachers.button")}</Button>
            {error? <Error.Text {...error}/>: null}
           </div>
        </div>
}


export default function Teachers(){
  const { t } = useTranslation("common");
  const [result, setResult] = useState();
  const [value, setValue] = useState([]);
  const [school, setSchool] = useState();
  const columns = useMemo(() => [
    {
      title: t("index"),
      field: "index",
      itemClassName: "font-medium "
    },
    {
      title: t("name"),
      field: "Nom",
    },
    {
      title: t("email"),
      field: "Courriel",
    }
  ], []);
  return <FlexNav breakdown="lg" className={"max-w-6xl mx-auto relative px-3 "}>
            <FlexNav.Content breakdown="lg" className="py-6">
              <div className="mx-auto w-full  space-y-8">
                <InfoSectionHeading mediumTitle={t("bulk-import.teachers.title")} longDescription={t("bulk-import.teachers.description")}/>

                
                {result?
                   <div className="p-3 rounded-md bg-green-100 text-green-700">
                    <p className="">{t("bulk-import.teachers.success")}</p>
                  </div>:
                    <div className="space-y-12">
                      <div>
                        <InfoSectionHeading smallTitle={t("bulk-import.teachers.school.title")} longDescription={t("bulk-import.teachers.school.description")}/>
                        <SelectSchool value={school} setValue={setSchool}/>
                      </div>
                      <TeachersUpload setValue={setValue}/>
                      <div>
                        <InfoSectionHeading smallTitle={t("bulk-import.teachers.results.title")} longDescription={t("bulk-import.teachers.results.description")}/>
                        <Table data={value} headers={columns}/>
                        {!value || value.length === 0? <EmptyState title={t("bulk-import.teachers.empty.title")}/>: null}
                      </div>

                    </div>
                 }
               </div>

             </FlexNav.Content>
             <FlexNav.Bar breakdown="lg" className="order-last lg:border-l lg:pl-6 ">
              <div className="sticky top-0 space-y-12 max-h-full pb-12">
                <ApplyButton  onResult={setResult} value={value} school={school}/>
              </div>
             </FlexNav.Bar>
            </FlexNav>

}
