/*eslint-disable*/
import React from "react";
import { SearchField } from 'components/tools/search_field'; 

import { useAPI } from "hooks/useAPI";
import { getOrCreateActionsPlan } from "api/notes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Viewer } from "components/core/rich-editor/viewer";
import { FiCheck } from "react-icons/fi";
import classNames from "classnames";
import { locale } from 'utils/locale';
const fullDayFormat = locale.format("%A, %d %B %Y");

export function SearchActions({size, color, value, setValue, disabled, student, multiple, placeholder, optionsClassName}) {
    const { t } = useTranslation('common');
    const params = useMemo(() => ({ student }), [student]);
    const [plan, {loading}] = useAPI(getOrCreateActionsPlan, params, {camelize:true});
    const {comments:actions} = plan || {};
    const selectedIds = useMemo(()=>multiple ? value?.map(v=>v.id.toString()):[value?.id.toString()], [value]);
    const handleSetValue = (v) => {
        if (multiple) {
            // if v in value, remove it
            if (value?.find(val => val.id == v.id)) {
                setValue(value?.filter(val => val.id != v.id));
            }
            else {
                setValue([...value, v]);
            }
        }
        else {
            setValue(v);
        }
    }
    return <SearchField
        loading={loading}
        value={value}
        setValue={multiple ? ()=>{}: setValue}
        multiple={multiple}
        disabled={disabled}
        values={actions}
        color={color}
        size={size}
        placeholder={placeholder || t("actions")}
        indexingField={"id"}
        optionsClassName={optionsClassName}
        formatSearchResult={
            (({content, displayDate, id})=>{
                const inSelected = selectedIds.includes(id.toString());
                const formattedDate = fullDayFormat(new Date(`${displayDate}T00:00:00`));
                const extractedContent = content.replace(/<[^>]*>?/gm, '');
                return <div className="flex" onClick={()=>handleSetValue({id, content, displayDate})}>
                    {
                        <div>
                            <FiCheck className={classNames(inSelected ? "text-green-500 opacity-100":"opacity-0", "mr-2 mt-1 size-5 transition-all")} />
                        </div>
                    }
                    <div className="flex flex-col">
                        <label className="text-gray-500">{formattedDate}</label>
                        <div className="line-clamp-4">{extractedContent}</div>
                    </div>
                </div>
            }
                
            )
        }
    />
}