import React from 'react'
import classnames from 'classnames';

// API
import { searchStudySessionOptions } from 'api/study_sessions';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import { Error } from 'components/core/typo'
import { Transition } from '@headlessui/react'
import { CheckBox } from 'components/tools/checkbox';

function Option({ selected, onChange, comment, keepOpen, option}){
  const { t } = useTranslation("common");
  return <div>
          <div className={classnames("col-span-1 p-2 flex items-center cursor-pointer border-2",
                selected? "bg-gray-200 rounded-t-md ": "rounded-md hover:bg-gray-100 bg-gray-50 border-transparent")}
              onClick={()=>onChange(option.slug, {...option, comment, keepOpen, disabled:selected})}>
              <div className="w-6 text-center" >
                <input type="checkbox" id={option.slug} name={"code"} checked={selected} readOnly/>
              </div>
            <div className="flex-1 ml-3">
              <div className="text-medium">{option.name}</div>
              <div className="text-gray-500">{option.description}</div>
            </div>
          </div>
          <div className={classnames("bg-gray-200 rounded-b-md", selected&&"p-3")}>
           <Transition
              show={selected}
              enter="transition-all duration-75"
              enterFrom="opacity-50 scale-y-50"
              enterTo="opacity-100 scale-y-100"
              leave="transition-all duration-75"
              leaveFrom="opacity-100 scale-y-100"
              leaveTo="opacity-50 scale-y-50"
            >
              {option.requires_comment && <p className="text-orange-600 text-sm ml-2">{t("comment-required")}</p>}
              <textarea
                rows={4}
                name="comment"
                id="comment"
                required={option.requires_comment}
                placeholder={option.description}
                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full border-gray-300 rounded-md"
                defaultValue={comment || ''}
                onChange={(e)=>onChange(option.slug, {...option, keepOpen, comment: e.currentTarget.value})}
              />
            <div className="flex text-gray-500 bg-gray-100 mt-1 rounded-md px-3">
              <CheckBox orientation="left" 
              value={keepOpen==null? false: keepOpen} 
                setValue={(next)=>onChange(option.slug, {...option, comment, keepOpen:next  })}
                name={keepOpen?t("keep-open"): t("keep-close")}/>
            </div>
            </Transition>
          </div>
        </div>
}


export function Options({value, setValue, studyCode, studySessionIsTooShort, numTooShortStudySessionsWarnings, studySessionHasNotOccured}){
  const params = useMemo(()=>({}), []);
  const [options, { error}] = useSearch(searchStudySessionOptions, params, {limit:500} );
  const filteredOptions = useMemo(()=>{
    if (!options || !studyCode) return;
    return options.filter(d=>
      (d.study_code===studyCode && 
        (studyCode==="tutorat" && studySessionHasNotOccured? d.use_if_study_session_has_not_occured: !d.use_if_study_session_has_not_occured) &&
        (studySessionIsTooShort? true: !d.slug.includes("aide-aux-devoirs-trop-breve"))
        ));
  }, [options, studyCode, studySessionIsTooShort, studySessionHasNotOccured]);

  const handleChange = useCallback((option, payload)=>{
    const next = {};
    next[option] = payload;
    setValue(d=>({...d, ...next}));
  }, []);

  // Create warning for too short periods
  useEffect(()=>{
    if (!options || !studyCode) return;
    if (!studySessionIsTooShort){
      if (numTooShortStudySessionsWarnings>=2){
        const opt = options.find(d=>d.slug==="aide-aux-devoirs-trop-breve-apres-deux-avertissements-"+studyCode);
        handleChange(opt.slug, {...opt, disabled: true})
      }
      else {
        const opt = options.find(d=>d.slug==="aide-aux-devoirs-trop-breve-"+studyCode);
        handleChange(opt.slug, {...opt, disabled: true});
      }
      return
    }
    if (numTooShortStudySessionsWarnings>=2){
      const opt = options.find(d=>d.slug==="aide-aux-devoirs-trop-breve-apres-deux-avertissements-"+studyCode);
      handleChange(opt.slug, {...opt, disabled: false})
    }
    else {
      const opt = options.find(d=>d.slug==="aide-aux-devoirs-trop-breve-"+studyCode);
      handleChange(opt.slug, {...opt, disabled: false})
    }
  }, [studySessionIsTooShort, studyCode, studyCode])

  if (error){
    return <Error.Text {...error}/>
  }

  if (!filteredOptions){
    return <div>No study code</div>
  }
  return <div className="space-y-4">
          {filteredOptions && filteredOptions.map(d=>
            <Option 
                key={d.slug} 
                option={d}
                keepOpen={value && value[d.slug] && value[d.slug].keepOpen} 
                comment={value && value[d.slug] && value[d.slug].comment} 
                selected={(value && value[d.slug] && !value[d.slug].disabled) || false} 
                onChange={handleChange}/>)}
         </div>
}