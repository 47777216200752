import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useValidatePassword(){
  const { t } = useTranslation(["errors"]);

  const validatePassword = useCallback((v)=>{
    // Minimum eight characters, at least one letter, one number and one special character:
    const reg = /^(?=.*?[a-zA-z])(?=.*?[0-9]).{8,}$/;
    if (reg.exec(v)){
      return null
    }
    else {
      return t('invalid_password_format')
    }
  }, []);

  return validatePassword;
}