import React, { useCallback, useEffect } from 'react';

// Components
import { SingleLineField } from 'components/tools/modifiable_fields';
import { Button } from 'components/core/button';
import { Info } from 'components/core/info';
import { InfoSectionHeading, Error, RawTextWarning, RawTextInfo } from 'components/core/typo'
import { InlineSMSChallengeForm } from 'components/settings/security/sms-challenge';
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { TextInput } from 'components/core/inputs';
import { RecentConnectionsList } from 'components/settings/security/recent_connections';
// Hooks
import { useAPI } from 'hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useState, useMemo} from 'react';
import { useValidatePassword } from 'hooks/validation/useValidatePassword';
import { formatPhoneNumber, validatePhoneNumber } from 'utils/format';

// API
import {  changePassword, setupMFAPhone, retrieveMFAStatus, generateOTP} from 'api/auth';
import { FiCheck } from 'react-icons/fi';
import { useInterval } from 'hooks/useInterval';
import { useUser } from 'hooks/useUser';


/*
  Modify general information on profile
*/

function ChangePassword(){
  const [password, setPassword] = useState();
  const [currentPassword, setCurrentPassword] = useState();
  const { t } = useTranslation("auth");
  const [result, {execute, error, loading}] = useAPI(changePassword, {password, currentPassword}, {immediate: false, onResult: (()=>{setPassword(); setCurrentPassword()})})
  const validatePassword = useValidatePassword();
  return <div className="space-y-3">
            <InfoSectionHeading mediumTitle={t("change-password")}/>
            
            <SingleLineField 
              label={t("current-password")} 
              modify={true} 
              inputProps={{type:"password", autoComplete: "current-password"}}
              value={currentPassword} 
              debounceDelay={0}
              showSavingState={false}
              setValue={setCurrentPassword}/>
            <SingleLineField 
              label={t("signup.password.label")} 
              modify={true} 
              inputProps={{autoComplete: "new-password", type:"password", required: true, errorOnBlur:(d=>d? validatePassword(d): null), description:t("signup.password.help")}}
              value={password} 
              debounceDelay={0}
              showSavingState={false}
              setValue={setPassword}/>

            <Button color="black" 
            block 
            size="md" 
            type="submit"
            onClick={execute} 
            loading={loading}
            disabled={!currentPassword || !password || validatePassword(password)}>

          {t("change-password")}</Button>
           {error? <Error.Text {...error}/>: null}
           {result && <p className="text-green-600">{result.msg}</p>}
           </div>
}

function SetupNewMFA({onCompleted}){
  const { t } = useTranslation('common');
  const [phone, setPhone] = useState();
  const [token, {execute, error, loading}] = useAPI(setupMFAPhone, {phone}, {immediate: false, onResult: (()=>{setPhone()})})
  if (token) return <InlineSMSChallengeForm onCompleted={onCompleted} token={token} phone={phone}/>
  return <div>
            <InfoSectionHeading smallTitle={t("mfa.setup")} longDescription={t("mfa.setup-description")}/>
            <TextInput label={t("mfa.phone")}
                    type="tel"
                    onChange={setPhone}
                    autoComplete="tel"
                    reformatOnBlur={formatPhoneNumber}
            />
            <Button color="black" 
            block 
            className={"mt-3"}
            size="md" 
            type="submit"
            onClick={execute} 
            loading={loading}
            disabled={(!phone || !validatePhoneNumber(phone))}>
          {t("mfa.send-confirmation-code-for-setup", {phone: formatPhoneNumber(phone)})}</Button>

            {error? <Error.Text {...error}/>: null}
            
            
    </div>
}

function MFASetup(){
  const { t } = useTranslation('common');
  const params = useMemo(()=>({}), []);
  const [mfa, {execute, error}] = useAPI(retrieveMFAStatus, params, {immediate: true})
  if (!mfa) return <Skeleton className={"h-24"}></Skeleton>
  return <div className="space-y-3">
            <InfoSectionHeading mediumTitle={t("mfa.title")} longDescription={t("mfa.description")}/>
            {!mfa.phone?
              <EmptyState title={t("mfa.missing")}/>:
              <>
                <div className={"bg-gray-100 rounded-md p-3 space-y-2"}>
                    <p className=" font-medium">{t("mfa.current")}</p>
                    <div><p className='mr-2 text-gray-500 text-sm'>{t("phone")}</p>{formatPhoneNumber(mfa.phone)}</div>
                    <div><p className='mr-2 text-gray-500 '>
                      {mfa.phone_verified && <FiCheck className="inline mr-1 text-green-600"/>}
                      {mfa.phone_verified?t("mfa.phone-verified"): t("mfa.phone-not-verified")}
                      </p></div>
                  </div>
                  {!mfa.phone_verified && <RawTextWarning>{t("mfa.phone-should-be-verified")}</RawTextWarning>}
                  </>
            }
              {!mfa.phone && <RawTextWarning>{t("mfa.mfa-is-required")}</RawTextWarning>}
              {mfa.phone && <RawTextInfo size="md">{t("mfa.info")}</RawTextInfo>}
            {error? <Error.Text {...error}/>: null}
            <SetupNewMFA onCompleted={execute}/>
           </div>
}

function GenerateOTPPassword(){
  const { t } = useTranslation('common');
  const [password, setPassword] = useState();
  const [result, {loading, execute, error}] = useAPI(generateOTP, {password}, {immediate: false})
  const [timer, setTimer] = useState(0);
  useEffect(()=>{
    if (result && result.remaining){
      setTimer(result.remaining);
    }
  }, [result]);

  const callback = useCallback(()=>setTimer(d=>d-1), []);
  useInterval(callback, result?1000: null);

  return <div>
            <InfoSectionHeading mediumTitle={t("generate-otp.title")} longDescription={t("generate-otp.description")}/>
            <SingleLineField 
              label={t("current-password")} 
              modify={true} 
              inputProps={{type:"password", autoComplete: "current-password"}}
              value={password} 
              debounceDelay={0}
              showSavingState={false}
              setValue={setPassword}/>
            <Button color="black"
                    block
                    size="md"
                    onClick={execute}
              className={"mt-3"}
              loading={loading}>
                      {t("generate-otp.button")}
                    </Button>

            {error && <Error.Text {...error}/>}
            {result?
              timer<=0 ?<p className='text-red-500'>{t("generate-otp.code-expired")}</p>:
              <div className={"bg-gray-100 mt-2 rounded-md p-3"}>
              <Info.Field label={t("generate-otp.code")} value={result.code}/>
              <Info.Field label={t("generate-otp.remaining")} value={timer>-1? timer: "0"}/>
              <Button color="inlineLink"
                    size="md"
                    target="_blank"
                    href="https://portail.diplomeavantlamedaille.org/admin-login"
                    externalLink>
                      {`https://portail.diplomeavantlamedaille.org/admin-login`}
                    </Button>
              </div>: null}
          </div>
}
export function ModifySecuritySettings(){
  const { t } = useTranslation('common');
  const [user] = useUser();
  return <div className="h-full min-h-screen md:min-h-0"> 
            <h1 className="info-section">{t('security')}</h1>
            <Info.Container className="" modify={true}>
                <ChangePassword/>
            </Info.Container>

            <Info.Container className="mt-20" modify={true}>
                <RecentConnectionsList/>
            </Info.Container>

            <Info.Container className="mt-20" modify={true}>
                <MFASetup/>
            </Info.Container>
            {user && ["Direction", "Développeur"].includes(user.role)  &&
            <Info.Container className="mt-20" modify={true}>
                <GenerateOTPPassword/>
            </Info.Container>}
         </div>
}