/* eslint-disable */
import React from "react"

// Components
import { LargeLabel, SubLabel } from "components/tools/modifiable_fields"
import { SearchField } from "components/tools/search_field"

// API
import { setDocumentStudentStatus } from "api/documents"
import { searchNotes } from "api/notes"

// Utils
import classnames from "classnames"
import { STUDENT_STATUS_CODES, STUDENT_STATUS_CODES_BY_STATUS, getStudentStatusFromNotes } from 'components/follow-up/meetings/status';


// Hooks
import { useAPI } from "hooks/useAPI"
import { useQuery } from 'hooks/useQuery';
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearch } from "hooks/useSearch";

export function SelectStudentStatus({document, disabled, onUpdate}){
    const { query } = useQuery();
    const { t } = useTranslation("common");
    const [selectedStatus, setSelectedStatus] = useState(null);
    
    const handleNotesSearchResult = useCallback(({results})=>{
        if (!results) return;
        const { status } = getStudentStatusFromNotes(results);
        if (!status) return
        setSelectedStatus(STUDENT_STATUS_CODES_BY_STATUS[status]);
    }, []);
    const validateParams = useCallback((d) => d.document, []);
    const searchNotesParams = useMemo(() => ({ document: document || query.get("documentId"), orderBy:"opened_at", orderDirection:"desc" }), [query]);
    const [,{loading:loadingNotes}] = useSearch(searchNotes, searchNotesParams, { limit: 1000, validateParams, onResult: handleNotesSearchResult, camelize:true});
    const handleSetStatusResult = (note) => {
        onUpdate?.({action: "status-set", note, document: document || query.get("documentId")});
    }
    const [ ,{execute}] = useAPI(setDocumentStudentStatus, {document: document || query.get("documentId")}, {immediate: false, onResult: handleSetStatusResult, camelize:true});
    const handleSetStatus = (status)=>{
        setSelectedStatus(status);
        execute({code: status?.code});
    }
    return <>
        <div className={classnames("w-full flex justify-between flex-col md:flex-row gap-1 py-2 border-b")}>
            <div>
                <LargeLabel>{t("add-success-status-note")}</LargeLabel>
                <SubLabel>{t("leave-empty-if-not-applicable")}</SubLabel>
            </div>
            <div>
                <SearchField 
                    indexingField={"code"} 
                    formatSearchResult={(note)=>note?.name}
                    formatSelectedValue={(note)=>note?.name}
                    value={selectedStatus} 
                    setValue={handleSetStatus}
                    values={STUDENT_STATUS_CODES}
                    loading={loadingNotes}
                    disabled={disabled || loadingNotes} 
                />
            </div>
        </div>
    </>
}