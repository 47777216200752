import React from 'react';

// API
import { searchGroupedStudySessions} from 'api/study_sessions';

// Hooks
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useSearch } from 'hooks/useSearch';
import { useWeek } from 'hooks/dates/useWeek'
import { useTranslation } from 'react-i18next';

// Utils
import classnames from 'classnames';
import { locale } from 'utils/locale';
import { timeWeek } from 'd3-time';
const fullDayFormat = locale.format("%d %B %Y");

// Components
import { Button } from 'components/core/button';
import { EmptyState } from 'components/core/empty';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Table } from 'components/core/table';
import { Skeleton } from 'components/core/skeleton';
import { VerificationFlag } from 'components/student/verfication_flag';
import { Cell } from 'components/students/list_with_flags';

function ChangeWeek({ date, setDate}){
  return <div className="flex items-center justify-center">
            <div className="flex items-center">
            <Button className="px-2 py-2 hover:bg-gray-100 flex-0 rounded-full" color="none" layout="none" onClick={()=>setDate(timeWeek.offset(date, -1))}><FiChevronLeft/></Button>
             <div className=" text-left text-lg py-1">
                {fullDayFormat(date)}
              </div>
              <Button className="px-2 py-2 hover:bg-gray-100 flex-0 rounded-full" color="none" layout="none" onClick={()=>setDate(timeWeek.offset(date, 1))}><FiChevronRight/></Button>
            </div>
        </div>
}

export function TeamMemberListWithStudySessions({team, onStudentClick, studySessions}){
  const { t } = useTranslation("common");
  const [date, setDate] = useState(timeWeek.floor(new Date()));
  const {fromDate, toDate} = useWeek(date, {weeks: 2});
  const teamMemberParams = useMemo(()=>({team: team && team.slug, fromDate, toDate, stepDays:7, datePartition: true}), [fromDate, team]);
  const [results, {execute, setResult}] = useSearch(searchGroupedStudySessions, teamMemberParams, {immediate: false, limit:150});

  useEffect(()=>{
    if (teamMemberParams.team) execute();
  }, [teamMemberParams])

  const onFlagChange = useCallback(({ni, study_session_verification_flag})=>{
    setResult(d=>d.map(e=>e.student.ni===ni? ({...e, student: {...e.student, study_session_verification_flag}}) :e))
  }, [])

  const headers = useMemo(()=>{
    if (!results || results.length===0) return [];
    var h = [{
                title: t("students"),
                field: (d=>d?d.student: "student"),
                format: (d=>d.name),
                onClick: onStudentClick,
                itemClassName: (d=>classnames("font-medium whitespace-nowrap", 
                                              d && d.study_session_verification_flag && "text-red-600",
                                              studySessions.filter(e=>e.student.ni===d.ni).length>0 && "bg-gray-100" )),
            }];

    // Add an header for each date
    results[0].results.forEach((e,i)=>{
      const header = {
        title: locale.format("%d %b.")(new Date(e.date)),
        field: (d=>d? ({studySessions: d.results[i].study_sessions, fails: d.results[i].fails}): `date-${i}`),
        headerId: locale.format("%d %b.")(new Date(e.date)),
        format: (d=><Cell position="right" {...d}/>),
        className: "text-center whitespace-nowrap"
      }
      h.push(header)
    })

    // Add verification flag
    h.push({
      title: "",
      field: (d=>d? ({...d.student, position: "right", onChange: onFlagChange}) : "verification-flag"),
      FormatComponent: VerificationFlag,
    })

    return h;
  }, [studySessions, results])

  if (!team) {
    return null;
  }
  if (!results){
    return <Skeleton className="w-full h-48"/>
  }
  if (results.length===0){
    return <EmptyState title={t("empty-state.no-students-title")} description={t("empty-state.no-students-in-team-description")}/>;
  }
  return <div className=" p-3 rounded-lg">
          <ChangeWeek date={date} setDate={setDate}/>
          <Table headers={headers} data={results} indexingKey={(d=>`${d.student.ni}`)}/>
        </div>
}
