import React, {useContext} from 'react';

// Components
import { Container } from 'components/core/container';
import { PortalUserCard } from 'components/tutors/portal';
// Hooks

// Contexts
import { TutorContext } from 'contexts/tutor';
import { TutorPortalUserContextProvider } from 'contexts/tutor_portal_user';
import { MailsCard } from 'components/tutors/mails';

export default function PortalSettings(){
  const { tutor  } = useContext(TutorContext);
  return <TutorPortalUserContextProvider ni={tutor && tutor.ni}>
            <Container className="h-full w-full space-y-6">
                <PortalUserCard/>
                <MailsCard/>
            </Container>
        </TutorPortalUserContextProvider>

}
