import React from "react";

// Components
import { Button } from "components/core/button";
import { Tooltip } from "components/core/tooltip";

// Icons
import { FiBookOpen as BookIcon } from "react-icons/fi";

export function ClassFailed({ student, loading }) {
    const classFailed = student?.classFailed || [];
    const nbFailed = classFailed?.length || 0;
    if (loading) return <></>
    if (!nbFailed) return <div className="px-2.5 text-gray-300 max-w">0</div>
    return <>
        <Tooltip color="light" position="right" delay="0" contentClassName="!px-1 !right-0 !max-w-52 " content={
            <Button color="cardNav" href={`/students/${student?.ni}/settings/class-failed`} className={"p-2 w-full rounded"}>
                <div className="flex gap-1 text-gray-500">
                    <BookIcon className='mt-1 shrink-0' />
                    <div className="text-sm flex gap-1">{classFailed.map(failed => failed?.schoolClass?.name).join(", ")}</div>
                </div>
            </Button>
        }>
            <div className="relative w-fit p-2.5 py-0 h-fit">{nbFailed}</div>
        </Tooltip>
    </>
}
