import React, { useRef } from 'react'
import classNames from 'classnames'

export function FieldsSection({ title = "", description = "", name, children, fullWidth = false, contentClassName,  className }) {
    const randomName = useRef(Math.random().toString(36).substring(7));

    const n = name || randomName.current;

    return <div className={classNames(`@container/${n}`)}>
        <div className={classNames(`@lg/${n}:flex flex-wrap py-8`, className)}>

        <div className={classNames('w-full pr-5 mb-4', fullWidth ? '' : `@lg/${n}:w-4/12`)}>
            <p className={classNames('-mt-1 font-medium text-lg text-black'+ `@lg/${n}:text-xl @lg/${n}:text-gray-600`)}>
                {title}</p>
            <p className="mt-1 text-gray-600/70">{description}</p>
        </div>
        <div className={classNames('w-full rounded-md', fullWidth? "":`@lg/${n}:w-8/12`, contentClassName)}>
            {children}
        </div>

    </div>
    </div>
}
function FieldSectionGrid({children}){
    return <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-3'>{children}</div>
}
FieldsSection.Grid = FieldSectionGrid;
