import React from 'react';

// Components
import { Inscriptions } from 'components/recruting/inscriptions';

export default function LatestInscriptions() {
    return <>
        <Inscriptions />
    </>
}
