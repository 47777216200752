/* eslint-disable */
import React, { useState, useContext, useMemo, useEffect } from 'react';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useDebounce } from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { useTranslationRegularCrons } from 'hooks/dates/crons';

// Utils
import classnames from 'classnames';
import { locale, dateParse } from 'utils/locale';

// API
import { searchPairings } from 'api/pairings';

// Components
import { Command } from 'components/core/command';
import { Error } from 'components/core/typo';
import { FiBookOpen as BookIcon } from 'react-icons/fi';
import { SearchContextProvider, SearchContext } from 'contexts/search';
import { FilterBar } from 'components/tools/filter_bar';
import { Link } from 'react-router-dom';
import { Skeleton } from 'components/core/skeleton';
import { PreferenceCell } from 'components/student/preferences';

function PairingOption({ pairing, selected }) {

    const student = pairing?.student || {};
    const tutor = pairing?.tutor || {};
    const period = useTranslationRegularCrons(pairing?.period ?[pairing?.period]:[]);
    const preferedType = pairing?.prefered_type || {};
    const subjects = pairing?.subjects || [];

    return <>
        <Link to={`/pairings/${pairing?.id}`} className='grid grid-cols-3 md:grid-cols-5 gap-x-2 w-full'>
            <div className='col-span-1 '>
                <div className='flex flex-col'>
                    <span className={classnames(selected && "font-semibold")}>{student?.name}</span>
                    <span className={classnames(selected && "font-semibold", "text-gray-500")}>{tutor?.name}</span>
                    <span className={classnames("md:hidden")}><PreferenceCell preference={preferedType?.code} /></span>

                </div>
            </div>
            
            <div className='col-span-1'>
                <span className={classnames("")}>{period}</span>
            </div>
            <div className='col-span-1 hidden md:block'>
                <span className={classnames("")}><PreferenceCell preference={preferedType?.code} /></span>
            </div>
            <div className='col-span-1'>
                {subjects && subjects.length > 0 && <span className={classnames("flex gap-2 items-start")}><BookIcon className='mt-1 shrink-0' />{subjects.map(e => e.name).join(', ')}</span>}
            </div>
            <div className='col-span-1 md:block hidden'>
                <span>{pairing.ended_at ? 
                    <p className="text-sm text-gray-500">{locale.format("%d %B")(dateParse(pairing.started_at))} au {locale.format("%d %B")(dateParse(pairing.ended_at))}</p>
                :
                    <p className="text-sm text-gray-500">Depuis le {locale.format("%d %B")(dateParse(pairing.started_at))}</p>}
                </span>
            </div>
        </Link>
    </>
}


export function SearchPairingsCommandWithContext({ open, setOpen }) {
    const { t } = useTranslation("common");
    const [query, setQuery] = useState("");
    const { filters } = useContext(SearchContext);
    const debounceQuery = useDebounce(query, 500);
    const params = useMemo(() => ({ search: query, ...filters }), [debounceQuery, filters]);
    const fields = useMemo(() => ([
        "id",
        "subjects",
        "period",
        "started_at",
        "ended_at",
        "tutor",
        "student"
    ]), []);
    const validateParams = useMemo(() => ()=> {
        return !!open;
    }, [open]);
    const [pairings, { loading, error, paging }] = useSearch(searchPairings, params, { limit: 1000, fields, validateParams });
    useEffect(() => {
        if (!open) {
            setQuery("");
        }
    }, [open]);
    return <>
        <Command.Root open={open} setOpen={setOpen} size="7xl">
            <Command.Search
                setQuery={setQuery}
                loading={loading}
                placeholder={t("search-pairings.placeholder")}
                multiple by={(a, b) => a.id === b.id}>
                {error ? <Error.Text {...error} /> : null}
                <FilterBar pairingActive activeSinceFrom activeSinceTo period color="default" />

                <Command.Options static className="text-sm text-gray-800 " size="lg">
                    {/* No results */}
                    {!loading && (pairings || []).length === 0 && (
                        <p className="p-4 text-sm text-gray-500">{t("search-pairings.no-results")}</p>
                    )}
                    {
                        loading && <>
                            <Skeleton className="h-44" />
                        </>
                    }
                    {/* Show results */}
                    {!loading && (pairings || []).map((pairing) => (
                        <Command.Option key={pairing.id} value={pairing}>
                            {({ active, selected }) => (
                                <PairingOption pairing={pairing} active={active} selected={selected} />
                            )}
                        </Command.Option>
                    ))}
                </Command.Options>
                <Command.Help>{t("search-pairings.info", {count:paging?.numResults})}</Command.Help>
            </Command.Search>
        </Command.Root>
    </>
}

export function SearchPairingsCommand({ ...props }) {
    return <SearchContextProvider doNotStore={true}>
        <SearchPairingsCommandWithContext {...props} />
    </SearchContextProvider>
}