import React from 'react';

import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FiX } from 'react-icons/fi';
import { Button } from 'components/core/button';

import classnames from 'classnames';

const sizes = {
  sm: "max-w-sm",
  md: "max-w-md",
  lg: "max-w-lg",
  xl: "max-w-xl",
  xl2: "max-w-2xl",
  xl3: "max-w-3xl",
  xl4: "max-w-4xl",
  xl5: "max-w-5xl",
}
export function SlideOver({open, setOpen, size="xl3", children}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog unmount={false} as="div" className="fixed inset-0 z-20 overflow-hidden" onClose={setOpen}>
        <div className="absolute inset-0 z-20 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={classnames("pointer-events-auto w-screen", sizes[size])}>

                <div className="flex flex-col h-full py-6 overflow-y-auto bg-white shadow-xl">
                  <div className="relative flex-1 ">
                    <div className="flex items-center justify-end px-4 sm:px-6">
                      <Button onClick={()=>setOpen(false)} size="toolbarIcon" color="gray" className="rounded-md"><FiX/></Button>
                    </div>
                    {children}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}