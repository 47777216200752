import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { Container } from 'components/core/container';
import { NewTeam } from 'components/teams/create';


export function CreateTeamPage(){
  return <DashboardLayout>
          <Container className="h-full">
            <NewTeam/>
          </Container>
         </DashboardLayout>
}

const route =  { path: "/new-team", 
                 requireAuth: true, 
                 Element: CreateTeamPage };
export default route;
