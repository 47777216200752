import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Button } from 'components/core/button';
import { Info } from 'components/core/info';
import { EmptyState } from 'components/core/empty';

// Utils
import classnames from 'classnames';
import { locale, dateParse } from 'utils/locale';

// Hooks
import { useTranslation } from 'react-i18next';

// Hooks
import { useContext } from 'react';

// Contexts
import {StudySessionContext} from 'contexts/study_session';

export function StudySessionInfo(){
  const {studySession} = useContext(StudySessionContext);
  const { t } = useTranslation('common');
  if (!studySession || !studySession.code){
    return <Skeleton.List numElements={5} className="space-y-3" itemClassName="h-36"/>
  }
  return <div className={classnames("space-y-6")}> 
            {!studySession.use_in_count && <EmptyState color="warning" description={t("study-session-doesnt-count-warning")}/>}
            {!studySession.session_occurred && <EmptyState color="warning" description={t("study-session-didnt-occurred-warning")}/>}
            <Info.Container label={"Info"} >
                <Info.Field value={locale.format("%d %B %Y")(dateParse(studySession.period))} noValueLabel={`${t("none")} ${t("period").toLowerCase()}`}/>
                {studySession.is_retaken && studySession.original_period && <Info.Field value={locale.format("%d %B %Y")(dateParse(studySession.original_period))} label={t("original-period")} noValueLabel={`${t("none")} ${t("original-period").toLowerCase()}`}/>}
                <Info.Field label={t("length_in_minutes")} value={`${studySession.length_in_minutes} min.`}/>
                <Info.Field value={studySession.student.name} to={`/students/${studySession.student.ni}`} noValueLabel={`${t("none")} ${t("name").toLowerCase()}`}/>
                <Info.Field value={studySession.student.folder_number} 
                                        noValueLabel={`${t("none")} ${t("folder-number").toLowerCase()}`}
                                        label={t("folder-number")}
                                        />
            </Info.Container>

            <Info.Container label={t('school-subjects')}>
                {studySession.subjects && studySession.subjects.length>0?
                          studySession.subjects.map(val=>
                            <Info.Field key={val.slug}
                                        value={val.name} 
                                        />):
                          <Info.Field noValueLabel={t('empty-state.no-school-subjects-title')} />
                        }
                 
            </Info.Container>
            <Info.Container label={t('portal-author')}>
              {!studySession.portal_author ?
              <Skeleton.List numElements={3} className="space-y-3" itemClassName="h-8"/>:
              !studySession.portal_author.name?
              <EmptyState description={t("no-portal-author")}/>:
              <>
              <Info.Field value={studySession.portal_author.name} to={`/portal-access/${studySession.portal_author.role}s?ni=${studySession.portal_author.ni}`}/>
              <Info.Field value={studySession.portal_author.email}/>
              <Info.Field value={t(studySession.portal_author.role)}/>
              </>
              }
            </Info.Container>
            {studySession.pairing && <Info.Container label={t("pairing")} >
                <Info.Field label={t("tutor")} value={studySession.pairing.tutor.name} to={`/tutors/${studySession.pairing.tutor.ni}`}/>
                <Info.Field value={t("see-pairing")} to={`/pairings/${studySession.pairing.id}`}/>
            </Info.Container>}

            <Button color="black" size="md" block href={`/study-sessions/${studySession.id}/settings/`}>{t("modify")}</Button>
         </div>
}
