import React from 'react';
import classnames from 'classnames';

/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
*/
const styles = {
  "white": "bg-white py-8 px-4 shadow rounded-lg sm:px-10"
}
export function CenterViewLayout({ children, className, color="white"}){

  return <div className="min-h-full flex flex-col justify-center py-12 px-3 sm:px-6 lg:px-8 bg-gray-100">
          <div className={classnames("sm:mx-auto sm:w-full sm:max-w-lg", styles[color], className)}>
            {children}
          </div>
         </div>
}