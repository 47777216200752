import React, { useCallback, useMemo, useState } from 'react';

// Hooks
import { useAPI } from 'hooks/useAPI';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// API
import { computePlayTime, setPlayTimeDescription } from 'api/events';

// COmponents
import { Skeleton } from 'components/core/skeleton';
import { Error, InfoSectionHeading, RawTextInfo, RawTextWarning, SectionHeading, TextWarningStructured } from 'components/core/typo';
import { Table } from 'components/core/table';

// Utils
import { locale, parseWithTimeZone, parseDateTimeIgnoreTime } from 'utils/locale';
import classnames from 'classnames';
import { Container } from 'components/core/container';
import { Info } from 'components/core/info';
import { Button } from 'components/core/button';
import { PencilIcon } from '@heroicons/react/24/outline';
import { FiCheck, FiX, FiBellOff, FiTool, FiAlertTriangle, FiRotateCcw, FiClipboard } from 'react-icons/fi';
import { TextInput } from 'components/core/inputs';
import { dateParse } from 'utils/locale';
import { Tooltip } from 'components/core/tooltip';
import { ControlledVerificationFlag } from 'components/student/verfication_flag';
import { searchPlayTimeRules } from 'api/play_time_rules';
import { useSearch } from 'hooks/useSearch';
import { RulesCard } from 'components/play-time-rules/card';
import { Dropdown } from 'components/core/dropdown';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { CheckBox } from 'components/tools/checkbox';
import { nameToInitials } from 'utils/format';
import { TransmissionStatusWithModification } from './transmission';
import { SetEventComment } from './set_event_comment';

function getRawContent(content){
  /* The content is XML encoded. This function will return the raw content */
  const parser = new DOMParser();
  // Check if the content is XML encoded
  const is_xml = content.startsWith("<") && content.endsWith(">");
  if (!is_xml){
    return content;
  }
  try {
    // If the content is not wrapped into a single tag, we wrap it into a div
    const wrapped = content.startsWith("<div>")? content: `<div>${content}</div>`;
    const doc = parser.parseFromString(wrapped, "application/xml");
    return doc.documentElement.textContent;
  }
  catch(e){
    return content;
  }
}
export function TooltipContent({studySessions, fails}){
  const {t} = useTranslation('common');
  return <div className="py-3 space-y-3">
          <Info.Container label={t("study-sessions")}>
            {studySessions.length===0 && <Info.Field noValueLabel={t("empty-state.no-study-session-title")}/>}
            {studySessions.map(d=>
              <Info.Field key={d.id} 
                    value={`${d.code.name}, ${locale.format("%a. %d %B")(dateParse(d.period))} (${d.length_in_minutes} min.)`} >
                    {!d.session_occurred && <p className="text-yellow-600">{t("study-session-didnt-occurred-warning")}</p>}
                    {d.subjects && <p className="text-gray-500">{d.subjects.map(d=>d.name).join(', ')}</p>}
                    <div className="">
                    {/*Section of icons*/}
                    {!d.session_occurred && 
                        <div>
                          <span><FiX className={classnames("h-6 w-6 rounded-xl text-red-500 inline-flex p-1")}/><span className="text-red-500">{t("study-session-didnt-occurred")}</span></span>
                        </div>}
                    {!d.use_in_count && 
                        <div>
                          <span><FiX className={classnames("h-6 w-6 rounded-xl text-red-500 inline-flex p-1")}/><span className="text-red-500">{t("study-session-does-not-count")}</span></span>
                        </div>}
                    {d.choked && 
                        <div>
                          <span><FiBellOff className={classnames("h-6 w-6 rounded-xl text-yellow-800 inline-flex p-1")}/><span className="text-yellow-800">{t("study-session-choked")}</span></span>
                        </div>}
                    {d.technical_problem && 
                        <div>
                          <span><FiTool className={classnames("h-6 w-6 rounded-xl text-blue-800 inline-flex p-1")}/><span className="text-blue-800">{t("study-session-technical-problem")}</span></span>
                        </div>}
                    {d.cancelled_for_external_reason && 
                        <div>
                          <span><FiAlertTriangle className={classnames("h-6 w-6 rounded-xl text-blue-400 inline-flex p-1")}/><span className="text-blue-400">{t("study-session-is-cancelled-for-external-reason")}</span></span>
                        </div>}
                    {d.is_retaken && 
                        <div>
                          <span><FiRotateCcw className={classnames("h-6 w-6 rounded-xl text-green-500 inline-flex p-1")}/><span className="text-green-500">{t("study-session-is-retaken", {originalPeriod:locale.format("%d %B")(dateParse(d.original_period))})}</span></span>
                        </div>}

                  </div>
                    {d.notes && d.notes.length>0 && <div className="mt-2 space-y-1">
                      {d.notes.map(e=><div key={e.id} className='py-1 border-l-2 -ml-3 pl-3' style={{borderColor: e.code.color}}>
                        {e.note_count!=null && <p className='text-gray-500 text-xs'>{t("note-count-for-period", {count: e.note_count})}</p>}
                        <p  style={{background: e.code.color + "20", borderColor: e.code.color}}>{e.code.name}</p>
                        {e.comments && e.comments.length>0 && <p className='mt-1 text-black max-w-full whitespace-pre-wrap '>{getRawContent(e.comments[e.comments.length-1].content)}</p>}
                      </div>)}
                      </div>}
              </Info.Field>
            )}
          </Info.Container >
          <Info.Container label={t("failed-school-classes")}>
            {Math.min(fails.length, 3)===0 && <Info.Field noValueLabel={t("empty-state.no-failed-classes-title")}/>}
            {fails.map(d=>
              <Info.Field key={d.id} value={d.school_class.name} label={`${d.score|| '-'}% | ${(d.school_class && d.school_class.subject && d.school_class.subject.name) || ""}`}/>)}
          </Info.Container>
         </div>
}

export const cellStyles = {
  "green": "bg-green-500 text-white",
  "red": "bg-red-500 text-white",
  "yellow": "bg-yellow-500 text-white",
  "blue": "bg-blue-500 text-white",
}

export function DescriptionCell({eventId, matchIdx, student, description, playTimeRule}){
  const {t} = useTranslation();
  const [value, setValue] = useState(description || "");
  const [modify, setModify] = useState(false);
  const [content, setContent] = useState(description || (playTimeRule && playTimeRule.play_time_title)  || "");
  const [, {loading, error, execute}] = useAPI(setPlayTimeDescription, {}, {immediate: false, onResult: (d)=>(d.description? setContent(d.description): null)});

  return <div className='flex justify-between w-full'>
            {(modify || loading)? <TextInput value={value}
                              size='xs' 
                              color="gray"
                              loading={loading}
                              placeholder={(playTimeRule && playTimeRule.play_time_title) || t("write-play-time")}
                              className={"w-full"} 
                              onChange={setValue}/>:
            content? content:
            <span className="text-gray-400">{t("play-time-needs-info")}</span>
          }
          {error && <Error.Text {...error}/>}
          {!modify?
          <Button color="gray" size="smToolbarIcon" className="ml-auto rounded-md" onClick={()=>setModify(true)}>
            <PencilIcon className='w-4 h-4'/>
          </Button>:
            <div className='flex items-center'>
            <Button color="green" size="smToolbarIcon" className="ml-2 rounded-md" onClick={()=>{execute({id: eventId, student, description:value, matchIdx }); setModify(false)}}>
              <FiCheck className='w-4 h-4'/>
            </Button>
            <Button color="gray" size="smToolbarIcon" className="ml-6 rounded-md" 
                      onClick={()=>{setModify(false); 
                                setContent((playTimeRule && playTimeRule.play_time_title) || "");
                                execute({id: eventId, student, description:"", matchIdx })
                                 }}>
              <FiRotateCcw className='w-4 h-4'/>
            </Button>
            <Button color="deleteContrast" size="smToolbarIcon" className="ml-2 rounded-md" onClick={()=>setModify(false)}>
              <FiX className='w-4 h-4'/>
            </Button>
          </div>}
          </div>
}

export function TotalCell({game, adminFormat}){
  if (!game) return null;
  if (game.comment){
    return <Tooltip content={<p className='h-full overflow-hidden !whitespace-pre-line'>{game.comment}</p>} position="center" color="light" delay={0} className="translate-x-6 ">
              <span className={classnames("py-1 px-2 mx-auto rounded-sm text-center whitespace-nowrap relative ring-2 ring-black", 
                    cellStyles[game.color])}>{adminFormat? game.fraction: game.fraction}
                    {game.comment.startsWith("⚠️") && <span className='h-4 w-4 absolute top-0 -translate-y-1/2 rounded-full text-xs'>⚠️</span>}

                </span>
              </Tooltip>
  }
  return <span className={classnames("py-1 px-2 mx-auto rounded-sm text-center whitespace-nowrap ring-2 ring-black", 
  cellStyles[game.color])}>{game.fraction}</span>
}

export function Cell({game, week, fraction, position="center"}){
  if (!week) return null;
  if (!game) return null;
  const isSelected = game.selected_weeks_idx.includes(week.idx);

  const numASA = week.all_study_sessions.filter(d=>d.choked).length;
  const content = (<span className={classnames("py-1 px-2 mx-auto rounded-sm text-center relative whitespace-nowrap", 
  !isSelected?"opacity-25": "",
    cellStyles[week.color])}>{fraction || week.fraction}</span>)
  if (!isSelected) {
    return content;
  }
  return <span className='relative'>
          {numASA?<span className='absolute top-0 right-0 -translate-y-3 translate-x-2 w-4 h-4 text-xs bg-red-500 rounded-md text-white z-10'>{numASA}</span>: null}
          <Tooltip content={<TooltipContent studySessions={week.all_study_sessions} fails={week.detailed_fails}/>} 
                  position={position}
                  color="light" 
                  delay={0} 
                  className="translate-x-6 ">{content}</Tooltip>
         </span>
}



function StudentComponent({name, firstname, lastname, adminFormat, ni}){
  const initials = useMemo(()=>nameToInitials(firstname, lastname), [name]);
  return <div className="py-1">
    <Link to={`/students/${ni}`} target='_blank' className={classnames('hover:underline', !adminFormat && (!firstname && !lastname) && "text-red-500 bg-red-100")}>
      {adminFormat? name: firstname && lastname? initials: `Prénom/Nom manquant au dossier`}</Link>
  </div>
}

function MissingDocuments({received_documents, required_documents}){
  const {t} = useTranslation();
  const missingDocuments = useMemo(()=>{
    if (!received_documents) return required_documents;
    return required_documents.filter(d=>!received_documents.includes(d.group));
  }, [received_documents, required_documents]);

  if (!missingDocuments || missingDocuments.length===0) return null;
  return <Tooltip content={<div>
      <span className='font-medium text-gray-400 block'>{t("missing-documents")}</span>
        {missingDocuments.map(d=><span className="text-black block  mr-2" key={d.group}>{d.name}</span>)}</div>} position="center" color="light" delay={0} className="translate-x-6 ">
        <span className="relative py-1 px-2 mx-auto rounded-sm text-center whitespace-nowrap bg-red-500 text-white">{missingDocuments.length}</span>
      </Tooltip>
}

function PlayTimeGame({gameIdx, rules, adminFormat, playTime}){
  const {t} = useTranslation();
  const headers = useMemo(()=>{
    const value = [
      {
        title: t("student"), 
        field: (d=>d? ({...d.student, adminFormat}): "student"),
        itemClassName: "!py-1  min-w-[190px]",
        className: "!pl-2",
        format: d=>(adminFormat? d.name: d.id),
        FormatComponent: StudentComponent
      },
      {
        title: t("missing-documents"), 
        field: (d=>d? ({...d, required_documents: playTime.required_documents}): "docs"),
        FormatComponent: MissingDocuments
      }
    ];


    value.push({
      title: "",
      field: (d=>d? ({...d.student, position:"left", value: d.student.study_session_verification_flag, readonly: true}) : "verification-flag"),
      FormatComponent: ControlledVerificationFlag,
    })

    // Skip the weeks if no players
    if (playTime.results.length===0){
      return value;
    }
    // Append the weeks
    playTime.results[0].weeks.forEach((e, weekIdx)=>{
      value.push({
        title: (locale.format("%d %b")(parseDateTimeIgnoreTime(e.date))),
        field: (d=>d? {game: d.games[gameIdx], week: d.weeks[weekIdx]} :locale.format("%d %b")(parseDateTimeIgnoreTime(e.date))),
        headerId: locale.format("%d-%b")(parseDateTimeIgnoreTime(e.date)),
        itemClassName: "text-center",
        className: "text-center",
        FormatComponent: Cell,
      })
    });

    // Add the total cell
    value.push({
      title: t("total"),
      field: (d=>d?{game:d.games[gameIdx], adminFormat}: "total"),
      itemClassName: "text-center",
      className: "text-center",
      FormatComponent: TotalCell
    })

    // Add the play time cell
    value.push({
      title: t("play-time"),
      field: (d=>d?{...d.games[gameIdx].custom_play_time, 
                    playTimeRule: d.games[gameIdx].play_time_rule,
                    eventId: playTime.event.id, 
                    matchIdx: gameIdx+1,
                    student: d.student.ni}: "play-time"),
      itemClassName: "text-left w-full",
      className: "text-left",
      parseNull: true,
      FormatComponent: DescriptionCell
    })
    return value;
  }, [adminFormat]);

  return <div>
          <SectionHeading title={t("game-idx", {idx: gameIdx+1})}>
              <Dropdown onlyIcon={<FiClipboard/>}>
                  <InfoSectionHeading className={"px-3"} mediumTitle={t("play-time-rules-for-sport", {sport: playTime&&playTime.event && playTime.event.team && playTime.event.team.sport.name, count: playTime.num_week_per_game[gameIdx]})}/>
                  <RulesCard numWeeks={playTime.num_weeks} rules={rules && rules.filter(d=>parseInt(d.weeks)===playTime.num_week_per_game[gameIdx])}/>
              </Dropdown>
            </SectionHeading>
          <Table 
          data={playTime.results} 
          indexingKey={d=>d.student.ni}
          headers={headers}/>
        </div>
}

function Header({playTime, setPlayTime, adminFormat, setAdminFormat}){
  const {t} = useTranslation();

  if (!playTime) return null;
  const event = playTime.event;
  const tournamentRule = playTime.tournament_rule;
  const title = `${event.event_type.name} du ${locale.format("%d %B %Y à %H:%M")(parseWithTimeZone(event.date))}`;


  return <Container className={"pb-3"}>
            <div className='space-y-3 mt-6 mb-3'>
                      <h1 className='font-medium text-xl'> {title}</h1>
                      <p>{event.notes}</p>

                    

                <div className='grid xl:grid-cols-2 gap-6'>
                <TransmissionStatusWithModification event={event} setEvent={(e)=>setPlayTime(d=>({...d, event:e}))}/>

                       <ModifiableSelectField
                      Field={CheckBox}
                      label={t("play-time-admin-format")}
                      subLabel={t("play-time-admin-format-sublabel")}
                      showCheckIfTrue={false}
                      showXIfFalse={false}
                      modify={true}
                      marker="toggle"
                      noBorder={true}
                      value={adminFormat}
                      setValue={setAdminFormat}/>
                      <div className='lg:row-span-2'>
                        <SetEventComment event={event} setEvent={(e)=>setPlayTime(d=>({...d, event:e}))}/>
                      </div>

                      <div>
                        <h2 className='font-medium mb-1'>{t("required-documents", {date: locale.format("%d %B")(parseWithTimeZone(event.date))})}</h2>
                        <div className='space-x-3'>
                          {playTime && playTime.required_documents &&playTime.required_documents.map(d=>
                            <span key={d.group} className='bg-gray-100 text-gray-800 px-2 py-1 rounded-md'>{d.name}</span>

                          )}
                        </div>
                    </div>
                  </div>

            </div>
            <div className='space-y-3'>
              {adminFormat && <TextWarningStructured title={t("admin-format-do-not-share")}></TextWarningStructured>}
              {event.transmitted && <TextWarningStructured className={"my-3"} title={t("warning-event-is-transmitted")}></TextWarningStructured>}
                {!event.event_type || event.event_type.slug!=="tournois"? null:
                tournamentRule?
                <RawTextInfo title={t("tournament-rules.label", {games: playTime.num_games, sport: event.team.sport.name, weeks: playTime.num_weeks})}>{tournamentRule.description}</RawTextInfo>:
                  <RawTextWarning>{t("no-tournament-rule-applies", {games: playTime.num_games, sport: event.team.sport.name, weeks: playTime.num_weeks})}</RawTextWarning>
                }
               
               </div>
               
            </Container>
}


export function PlayTimeView({eventId}){
  const [adminFormat, setAdminFormat] = useState(false);
  const validateParams = useCallback((params)=>params.id, []);
  const searchParams = useMemo(()=>({id: eventId}), [eventId]);
  const [rawPlayTime, {loading, setResult: setPlayTime, error}] = useAPI(computePlayTime, 
                  searchParams,
                  {immediate: true, validateParams});
  

  const playTime = useMemo(()=>{
    if (!rawPlayTime) return null;
    const output = {};
    Object.keys(rawPlayTime).forEach(d=>{
      if (d==="results"){
        output[d] = rawPlayTime[d].map(e=>({...e, weeks: e.weeks.sort((a,b)=>a.idx-b.idx)}));
      }
      else {
        output[d] = rawPlayTime[d];
      }});
    return output;
    
  }, [rawPlayTime]);
  
  const numGames = useMemo(()=>playTime? playTime.num_games: 0, [playTime]);

  const rulesParams = useMemo(()=>({sport: playTime&&playTime.event && playTime.event.team.sport}), [playTime])
  const validateParamsForRUles = useCallback((d)=>!!d.sport, []);
  const [rules, {error: errorRules}] = useSearch(searchPlayTimeRules, rulesParams, {validateParamsForRUles})
  

   return <div className='pb-12'>
            {error &&<Error.Text {...error}/>}
            {errorRules &&<Error.Text {...errorRules}/>}
            {(eventId && (!playTime ||loading))? <div className='p-6 space-y-3'>
                                      <Skeleton className="h-16"/>
                                      <Skeleton className="h-72"/>
                                    </div>:
              <>
                <Header playTime={playTime} 
                    setPlayTime={setPlayTime}
                    adminFormat={adminFormat}
                    setAdminFormat={setAdminFormat}/>
                <div className='space-y-8'>
                {[...Array(numGames)].map((d, i)=><PlayTimeGame adminFormat={adminFormat} key={i} rules={rules} gameIdx={i} playTime={playTime}/>)}
                </div>
              </>
            }
            </div>
}

export function PlayTimeViewParams(){
  const params = useParams();
  return <PlayTimeView eventId={params.eventId}/>
}
