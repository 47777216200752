import React from 'react';

// API
import { searchSchoolSubjectsCovered } from 'api/stats';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useDimensions } from 'hooks/useDimensions';
import { useMemo, useState, useEffect, useContext } from 'react'; 

// Contexts
import { SearchStudySessionsStatsContext } from 'contexts/follow_up_stats'

// Components
import { Skeleton } from 'components/core/skeleton';
import { Container } from 'components/core/container';
import { Table } from 'components/core/table';
import { SelectTabs } from 'components/core/select';

// utils
// import {locale} from 'utils/locale'
import { format } from 'd3-format';
import * as Plot from "@observablehq/plot";
// import * as d3 from 'd3';

function StatsChart({stats}){
  const [displayCount, setDisplayCount] = useState(true);
  const tabs = useMemo(()=>[
      {name: "Séances", value: true},
      {name: "Temps", value: false},
    ], [])
  const [width, , ref] = useDimensions();

  useEffect(() => {
    if (stats === undefined) return;
    const common = {
          style: {
            fontSize: 14
          },  
          x: {
            tickRotate: -45,
            label: ""
          },
          y: {
            grid: true,
            line: true,
            label: displayCount? "Séances": "Heures"
          },
          
          height: 320,
          width: width,
          marginLeft: 50,
          marginRight: 20,
          marginTop: 30,
          marginBottom: 120,
          insetTop: 0,
          insetBottom: 0,
          insetLeft: 0,
          insetRight: 0
    }
    if (displayCount){
      const chart = Plot.plot({
          marks: [
            Plot.barY(stats.map(d=>({y:d.num_study_sessions, 
                                      x: d.subject.name})), 
            {x: "x", y: "y", "fill": "y"}), 
            Plot.ruleY([0]),
          ], 
          color: {
            scheme: "blues"
          },
          ...common
        })
      ref.current.append(chart);
      return () => chart.remove();

    }
    else{
      const chart = Plot.plot({
          marks: [
            Plot.barY(stats.map(d=>({y: d.num_minutes/60, 
                                      x: d.subject.name})), 
            {x: "x", y: "y", "fill": "y"}), 
            Plot.ruleY([0]),
          ],
          color: {
            scheme: "blues"
          },
          ...common
        })
      ref.current.append(chart);
      return () => chart.remove();
    }
  }, [stats, width, displayCount]);

  return <div>
            <div className="w-full flex items-center justify-end ">
                <SelectTabs values={tabs} setValue={(v)=>setDisplayCount(v)}/>
            </div>
            <div className="w-full" ref={ref}/>
          </div>
}


function StatsTable({stats}){
  const headers = useMemo(()=>[
      {
        title: "Matière scolaire",
        field: (d=>d? d: "subject"),
        itemClassName: "py-0.5 font-medium whitespace-nowrap",
        format: (d=>d.subject.name)
      },
      {
        title: "Temps",
        itemClassName: "text-gray-500 text-center",
        className: "text-center",
        field: (d=>d? `${format("2d")(Math.floor(d.num_minutes/60))}h ${format("02d")(d.num_minutes%60)}`: "num_minutes"),
      },

      {
        title: "Séances",
        itemClassName: "text-gray-500 text-center",
        className: "text-center",
        field: (d=>d? d.num_study_sessions: "num_study_sessions"),
      }
    ], []);
  return <Table data={stats} 
            headers={headers}
            headerRowClassName="bg-gray-100"
            rowClassName="hover:bg-gray-50"
          indexingKey={"date"}/>
}
export function SubjectsStatsChartAndTable({student}){
  const {filters} = useContext(SearchStudySessionsStatsContext) 
  const params = useMemo(()=>({student, ...filters}), [student, filters])
  const [stats] = useSearch(searchSchoolSubjectsCovered, params)


  if (!stats) return <Skeleton className="w-full h-80"/>
  return <Container className="space-y-4 my-4">
            <StatsChart stats={stats && stats.length>0 && stats[0].results}/>
            <StatsTable stats={stats && stats.length>0 && stats[0].results}/>
         </Container>
}