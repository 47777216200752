import React from 'react';

// Components
import { Tabs } from 'components/core/tabs';
import { InfoSectionHeading  } from 'components/core/typo';

// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function AssistantProgressBar(){
  const { t } = useTranslation('endYearAssistant');
  const tabs = [
    {
      name: "Jumelages",
      href: `/end-year-assistant/pairings`,
    },
    {
      name: "Tuteurs",
      href: `/end-year-assistant/tutors`,
    },
    {
      name: "Élèves-athlètes",
      href: `/end-year-assistant/students`,
    },
    {
      name: "Entraîneurs",
      href: `/end-year-assistant/coachs`,
    }
  ]
  const [tab, setTab] = useState(tabs[0]);

  return <>
          <div className=" bg-gray-100 border-b">
          <div className=" px-3 bg-gray-100">
            <InfoSectionHeading mediumTitle={t("assistant.title")} longDescription={t("assistant.description")}/>
          </div>
        </div>
          <div className="border-b px-3">
            <div className=" ">
              <Tabs tabs={tabs} value={tab} setValue={setTab} navClassName=" bg-white"/>
            </div>
          </div>
        </>
}