import React from 'react';
import classnames from 'classnames';

export function Container({ children, className }){
  return <div className={classnames("w-full mx-auto px-3 sm:px-4 md:px-6", className)}>{children}</div>
}

export function CardContainer({ children, className }){
  return <div className={classnames("w-full mx-auto px-3 sm:px-6", className)}>{children}</div>
}

export function ContainerFluid({ children, className }){
  return <div className={classnames("w-full mx-auto", className)}>{children}</div>
}
