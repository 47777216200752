import React, { useReducer, createContext } from "react";

function filterReducer(state, {type, value}){
  switch (type){
    case 'reset':
      return {};
    default:
      var d = {...state};
      d[type] = value;
      return d
  }
}

function updateWithDefaultFields(val, defaultFields){
  if (!defaultFields) return val;
  return {...val, ...defaultFields};
}
const DraftAutoPairingContext = createContext({});

const DraftAutoPairingContextProvider = ({ children, defaultState, defaultFields }) => {

  const [value, updateValue] = useReducer(filterReducer, updateWithDefaultFields(defaultState || {}, defaultFields));
  return (
    <DraftAutoPairingContext.Provider value={{ value, updateValue }}>
      {children}
    </DraftAutoPairingContext.Provider>
  );
};

export { DraftAutoPairingContext, DraftAutoPairingContextProvider };