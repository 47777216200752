import React from 'react';

// Hooks
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSearch } from 'hooks/useSearch';

// API
import { searchPairings } from 'api/tutors';

// Components
import { PairingList } from 'components/pairings/list'

export function TutorPairingsList(){
  const params = useParams();
  const searchParams = useMemo(()=>({ni:params.ni, orderBy: "created_at", orderDirection: "desc"}), [params.ni]);
  const [pairings, {loading, error, setPage, page, paging}] = useSearch(searchPairings, searchParams);

  return <PairingList pairings={pairings} paging={{...paging, page, setPage}} loading={loading} error={error}/>
} 