import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { FiShuffle } from 'react-icons/fi';
import { AutomaticPairingView } from 'components/automatic-pairing';


export function AutomaticPairingBase(){
  return <DashboardLayout>
          <AutomaticPairingView/>
         </DashboardLayout>
}

const route =  { path: "/automatic-pairing",
                 name: "Jumelage automatique" , 
                 Icon: FiShuffle,
                 group: 'outils',
                 isNested: true,
                 showInNavBar: true, 
                 requireAuth: true, 
                 Element: AutomaticPairingBase };
export default route;

import { default as results } from 'pages/auto-pairing/results'
import { default as unmatchedStudents } from 'pages/auto-pairing/unmatched_students'
import { default as unmatchedTutors } from 'pages/auto-pairing/unmatched_tutors'
import { default as params } from 'pages/auto-pairing/params'
import { default as empty } from 'pages/auto-pairing/empty'
import { default as create } from 'pages/auto-pairing/create'
export const AutoPairingPages = {root: AutomaticPairingBase, results, unmatchedStudents, unmatchedTutors, params, empty, create};

