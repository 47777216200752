import React from 'react';

// Components
import { CardSectionHeading } from 'components/core/typo';
import { SearchStudents } from 'components/tools/student_search';
import { SelectDocumentGroup } from 'components/tools/select_document_group';
import { SelectDate } from 'components/tools/select_date';
import { Button } from 'components/core/button';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { Error } from 'components/core/typo'
import { SelectPeriods } from 'components/tools/select_period';
import { SearchField } from 'components/tools/search_field';
import { STUDENT_STATUS_CODES } from 'components/follow-up/meetings/status';

// Utils
import { formatBytes } from 'utils/bytes';
import { uploadFile } from 'utils/upload';

// API
import { createDocument, updateDocument } from 'api/documents';
import { createNote } from 'api/notes';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAPI } from 'hooks/useAPI';

export function CreateDocument({ defaultGroup, defaultStudent, defaultPeriod, defaultHandedOverAt, onCompleted }) {
    const [draftDocument, setDraftDocument] = useState({ student: defaultStudent, group: defaultGroup, period: defaultPeriod, handedOverAt: defaultHandedOverAt });
    const { t } = useTranslation('common');
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ maxSize: 1024 * 1024 * 512, maxFiles: 1 });
    const [selectedCode, setSelectedCode] = useState(null);
 
    const handleNoteCreated = (note)=> {
        const {code} = note.code
        note.code = code || note.code;
        const newDocument = {...draftDocument, notes:[note]};
        onCompleted?.(newDocument);
    }
    const params = { student: draftDocument?.student, code: selectedCode?.code, documentGroup: draftDocument?.group, period: draftDocument?.period, openedAt: draftDocument?.handedOverAt || new Date(), closedAt: draftDocument?.handedOverAt || new Date()}
    const [, { loading: loadingNote, execute: createStatusNote }] = useAPI(createNote, params, { immediate: false, onResult: handleNoteCreated, camelize: true});
    const handleAdd = useCallback(() => {
        // 1. Create the document
        // 2. Upload with signed url
        // 3. Update document
        let dId = null;
        setLoading(true);
            const [createDocPromise] = createDocument({
                ...draftDocument,
                numBytes: (acceptedFiles && acceptedFiles.length === 1 && acceptedFiles[0].size) || null,
                name: (acceptedFiles && acceptedFiles.length === 1 && acceptedFiles[0].path) || null
            });

            createDocPromise.then((doc) => {
                if (doc.policy) {
                    return uploadFile(doc.policy.policy.url, acceptedFiles[0], doc.policy.policy.fields).then(() => doc)
                }
                else {
                    return doc?.id ? doc : true;
                }
            })
            .then((doc) => {
                if (doc?.id) setDraftDocument(prev=>({...prev, id: doc?.id}));
                dId = doc?.id;
                if (doc?.policy) {
                    setDraftDocument(prev=>({...prev, id: doc?.id}));
                    const [updateDocPromise] = updateDocument({ id: doc.id, uploadedAt: new Date() });
                    return updateDocPromise
                }
            })
            .then(() => {
                if (selectedCode) {
                    createStatusNote();
                }
                else {
                    onCompleted?.({...draftDocument, id: dId});
                }
                setLoading(false);
            })
            .catch((res) => {
                try {
                    res.json()
                        .then(({ detail, code, payload }) => { setLoading(false); setError({ code, detail, payload }) })
                        .catch(() => { setLoading(false); setError({ code: 'default' }) });
                }
                catch {
                    setLoading(false);
                    setError({ code: 'default' });
                }
            })
    }, [draftDocument, acceptedFiles, selectedCode]);
    return <div>
        <div className="mb-6">
            <CardSectionHeading
                title={<span className="flex items-center">
                    <div>{t("add-document")}</div></span>} />
        </div>

        <div className="space-x-3 flex items-center justify-between">
            <SearchStudents targetWidth="md" value={draftDocument.student} setValue={student => setDraftDocument(d => ({ ...d, student }))} />
            <SelectPeriods targetWidth="md" value={draftDocument.period} setValue={period => setDraftDocument(d => ({ ...d, period }))} />
            <SelectDocumentGroup value={draftDocument.group} setValue={group => setDraftDocument(d => ({ ...d, group }))} />
        </div>

        <div className="py-3">
            <div {...getRootProps({ className: 'dropzone border-2 text-gray-500 cursor-pointer border-dashed py-8 text-center' })}>
                <input {...getInputProps()} />
                <p className="max-w-md mx-auto">{t("dropzone-description")}</p>
            </div>
            <div className="space-y-2 mt-2">
                {acceptedFiles.map((d) =>
                    <div key={d.path} className="bg-gray-100 p-3 rounded-md flex items-center justify-between">
                        <p className="font-medium">{d.path}</p>
                        <p className="ml-3 text-gray-600">{formatBytes(d.size)}</p>
                    </div>)}
            </div>
        </div>

        <div>
            <ModifiableSelectField
                Field={SelectDate}
                label={t("handed-over-at-title")}
                subLabel={t("handed-over-at-description")}
                modify={true}
                value={draftDocument.handedOverAt && new Date(draftDocument.handedOverAt)}
                setValue={handedOverAt => setDraftDocument(d => ({ ...d, handedOverAt }))} />
        </div>

        <div>
            <ModifiableSelectField
                Field={SearchField}
                label={t("add-success-status-note")}
                subLabel={t("leave-empty-if-not-applicable")}
                indexingField={"code"}
                formatSelectedValue={(d) => d.name}
                formatSearchResult={(d) => d.name}
                modify={true}
                values={STUDENT_STATUS_CODES}
                value={selectedCode}
                setValue={setSelectedCode} />
        </div>

        <Button block disabled={!draftDocument.group} size="lg" loading={loading || loadingNote} className="mt-6" color="black" onClick={handleAdd}>{t("add-document")}</Button>
        {error ? <Error.Text className="mt-3" {...error} /> : null}

    </div>
}
