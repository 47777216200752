import { fetchCall } from 'api';
import { isString } from 'utils/var_check';

/*
  Create a new optimization
*/  
export function launchOptimization({students, 
                                tutors, 
                                description,
                                commonSchoolWeight,
                                commonSubjectWeight,
                                doNotPairAgain,
                                hasParticularityWeight,
                                isInAccueilWeight,
                                numberOfClassFailedWeight,
                                pairingExistedBefore,
                                shouldHaveCommonSchedule,
                                shouldHaveCommonFormat,
                                shouldHaveCommonSchools,
                                shouldHaveCommonSubjects,
                                tutorMustBeOkWithAccueil,
                                tutorMustBeOkWithParticularity, 
                                tutorYearsOfServiceWeight,
                                studentWithContract,
                                studentIsPriority,
                                triadeSameSchoolYearWeight,
                                test,
                                evaluateOnly}){
  const params = {
    method: "POST", 
    endpoint: test? `/auto-pairing-test`: evaluateOnly? `/auto-pairing-evaluate-num-edges`: "/auto-pairing",
    body: {
      description,
          students: students && students.map(d=>isString(d)? d: d.ni), 
          tutors: tutors && tutors.map(d=>isString(d)? d: d.ni),
          commonSchoolWeight: commonSchoolWeight && commonSchoolWeight.description,
          commonSubjectWeight: commonSubjectWeight && commonSubjectWeight.description,
          considerDoNotPairAgain: doNotPairAgain,
          hasParticularityWeight: hasParticularityWeight && hasParticularityWeight.description,
          isInAccueilWeight: isInAccueilWeight && isInAccueilWeight.description,
          numberOfClassFailedWeight: numberOfClassFailedWeight && numberOfClassFailedWeight.description,
          pairingExistedBefore: pairingExistedBefore && pairingExistedBefore.description,
          studentIsPriority: studentIsPriority && studentIsPriority.description,
          triadeSameSchoolYearWeight: triadeSameSchoolYearWeight && triadeSameSchoolYearWeight.description,
          studentWithContract,
          shouldHaveCommonSchedule,
          shouldHaveCommonFormat,
          shouldHaveCommonSchools,
          shouldHaveCommonSubjects,
          tutorMustBeOkWithAccueil,
          tutorMustBeOkWithParticularity,
          tutorOccupationWeight: {},
          tutorYearsOfServiceWeight: tutorYearsOfServiceWeight && tutorYearsOfServiceWeight.description
         }
  }
  return fetchCall(params)
}

export function evaluteOptimization(body){
  return launchOptimization({...body, evaluateOnly: true})
}

export function testOptimization(body){
  return launchOptimization({...body, evaluateOnly: false, test: true})
}
/*
  Search auto-pairing
*/  
export const searchAutoPairings = ({search, period, page, limit, orderBy, orderDirection="DESC"}) => {
  const options = {
    method: "GET", 
    endpoint: "/auto-pairing",
    query: {expand:[],
            period: period && (isString(period)? period: period.slug),
            search,
            page, 
            limit, 
            orderBy, 
            orderDirection}
  }
  return fetchCall(options)
};
/*
  Retrieve auto-pairing
*/  
export const retrieveDraftPairing = ({draftId, fields}) => {
  const options = {
    method: "GET", 
    endpoint: `/auto-pairing/${draftId}`,
    query: {fields}
  }
  return fetchCall(options)
};


/* Update auto pairing */
export const updateDraftPairing = ({draftId, description, official}) => {
  const options = {
    method: "PATCH",
    endpoint: `/auto-pairing/${draftId}`,
    body: {description, official}
  }
  return fetchCall(options)
};

/*
  Retrieve auto-pairing
*/  
export const retrieveDraftPairingResults = ({draftId}) => {
  const options = {
    method: "GET", 
    endpoint: `/auto-pairing/${draftId}`,
    query: {expand:["pairings"]}
  }
  return fetchCall(options)
};

/*
  Retrieve auto-pairing
*/  
export const retrieveDraftPairingUnmatchedStudents = ({draftId}) => {
  const options = {
    method: "GET", 
    endpoint: `/auto-pairing/${draftId}`,
    query: {expand:["unmatched_students"]}
  }
  return fetchCall(options)
};


/*
  Retrieve auto-pairing
*/  
export const retrieveDraftPairingUnmatchedTutors = ({draftId}) => {
  const options = {
    method: "GET", 
    endpoint: `/auto-pairing/${draftId}`,
    query: {expand:["unmatched_tutors"]}
  }
  return fetchCall(options)
};