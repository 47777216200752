// Hooks
import { useParams, useLocation } from 'react-router-dom';
import { useAPI } from 'hooks/useAPI';
import { useMemo } from 'react';

// API
import { retrieveStudySession } from 'api/study_sessions';

export function useStudySession({ immediate=false }){
  const { id } = useParams();
  const { state } = useLocation(); // Persitent state
  const params = useMemo(()=>({id}), [id]);
  const [result, others] = useAPI(retrieveStudySession, params, {immediate});
  return [state || result || {id}, others];
}