import { fetchCall } from 'api';

/*
  Search school years
*/  
export const searchSchoolYears = ({search, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/school-years",
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};

/*
  Search schools
*/  
export const searchSchools = ({search, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/schools",
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};

/*
  Search periods
*/  
export const searchPeriods = ({search, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/periods",
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};

/*
  Search school profiles
*/  
export const searchSchoolProfiles = ({search, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/school-profiles",
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};


/*
  Search study codes
*/  
export const searchStudyCodes = ({search, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/study-codes",
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};

/*
  Search school subjects
*/  
export const searchSchoolSubjects = ({search, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/school-subjects",
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};

/*
  Search school classes
*/  
export const searchSchoolClasses = ({search, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/school-classes",
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};

/*
  Search pairing types
*/  
export const searchPairingTypes = ({search, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/pairing-types",
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};

/*
  Search event types
*/  
export const searchEventTypes = ({search, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/event-types",
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};


/*
  Search document groups
*/  
export const searchDocumentGroups = ({search, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/document-groups",
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};

/*
  Search sports
*/  
export const searchSports = ({search, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/sports",
    query: {search, 
            page, 
            limit, 
            orderBy, 
            orderDirection}
  }
  return fetchCall(options)
};
