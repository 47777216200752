import React from 'react';

// Hooks
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSearch } from 'hooks/useSearch';
import { useMonth } from 'hooks/dates/useMonth';

// Contexts
import { SearchStudySessionsContext, SearchStudySessionsContextProvider } from 'contexts/search_study_sessions';

// API
import { searchStudySessions } from 'api/study_sessions';

// Components
import { StudySessionList } from 'components/study-sessions/list'

export function StudentStudySessionsListWithContext(){
  const params = useParams();
  const { filters, dispatchFilters } = useContext(SearchStudySessionsContext)
  const [sessions, {loading, error, page, setPage, paging}] = useSearch(searchStudySessions, filters, {limit:100});

  useEffect(()=>{
    if (params && params.ni!==filters.student){
      dispatchFilters({type: 'student', value: params.ni})
    }
  }, [params])
  return <StudySessionList studySessions={sessions} paging={{...paging, page, setPage}} loading={loading} error={error}/>
}

export function StudentStudySessionsList(){
  const params = useParams();
  const {fromDate, toDate} = useMonth();
  return <SearchStudySessionsContextProvider 
                defaultState={{student: params.ni, orderBy: "period", orderDirection: "desc", fromDate, toDate}}>
          <StudentStudySessionsListWithContext/>
          </SearchStudySessionsContextProvider>
}