import React from 'react';
import classnames from 'classnames';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import { useMonth } from 'hooks/dates/useMonth';
import { useTranslation } from 'react-i18next';
// 
// API
import { searchStudySessions } from 'api/study_sessions'; 
import { HiDesktopComputer } from "react-icons/hi";
// Components
import { Skeleton } from 'components/core/skeleton';
import { DoubleMonthView } from 'components/tools/double_month_view';
import { Error } from 'components/core/typo';
import { PaginateCompact } from 'components/core/paginate';
import { EmptyState } from 'components/core/empty';
import { Tooltip } from 'components/core/tooltip';
import { Button } from 'components/core/button';
import { FiX, FiBellOff, FiTool, FiAlertTriangle, FiRotateCcw} from 'react-icons/fi';

// Contexts
import { SearchContext } from 'contexts/search';

// Utils
import { locale, dateParse } from 'utils/locale';
import { datesAreInInterval }  from 'utils/date_compare';

function Empty(){
  const {filters } = useContext(SearchContext)
  const { t } = useTranslation('common');
  return <EmptyState 
            className="h-full flex flex-col justify-center "
            title={t("empty-state.no-study-session-title")}
            description={t("empty-state.no-study-session-description", 
              {fromDate: locale.format("%d %B")(filters.fromDate), 
              toDate: locale.format("%d %B")(filters.toDate)})}>
         </EmptyState>
}

function Cell({id, student, code, period, original_period, session_occurred, choked, technical_problem, has_portal_author, cancelled_for_external_reason, is_retaken}){
  const { t } = useTranslation('common');
  return <Button className="p-3" layout="cell" nav  color={(d)=>d? "activeCell": "cell"}href={`/study-sessions/${id}`}>
          {({isActive})=>
            <span className="block">
              <div className="flex items-star justify-between space-x-1 w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="w-3 h-3 rounded ring-2 ring-white" style={{background: code.color}}/>
                    <span className={classnames("flex-1 truncate font-medium", isActive&& "font-medium text-white")}>{code.name}</span>
                  </div>
                    <div className={classnames(isActive&& "font-medium text-white")}>{student.name}</div>
                </div>
                <div>
                  <div className={classnames("text-gray-500 ml-auto text-right", isActive&& "font-medium text-white")}>{locale.format("%d %b %Y")(dateParse(period))}</div>
                  <div className="space-x-1 flex items-center justify-end relative">
                    {/*Section of icons*/}
                    {has_portal_author && <Tooltip position="right" delay={0} content={t("study-session-has-portal-user")}><span><HiDesktopComputer className={classnames("relative bg-gray-100 p-1 h-6 w-6    text-green-500")}/></span></Tooltip>}
                    {!session_occurred && <Tooltip position="right" delay={0} content={t("study-session-didnt-occurred")}><span><FiX className={classnames("relative bg-gray-100 p-1 h-6 w-6  text-red-500")}/></span></Tooltip>}
                    {choked && <Tooltip position="right" delay={0} content={t("study-session-choked")}><span><FiBellOff className={classnames("relative bg-gray-100 p-1 h-6 w-6  text-yellow-800")}/></span></Tooltip>}
                    {technical_problem && <Tooltip position="right" delay={0} content={t("study-session-technical-problem")}><span><FiTool className={classnames("relative bg-gray-100 p-1 h-6 w-6  text-blue-800")}/></span></Tooltip>}
                    {cancelled_for_external_reason && <Tooltip position="right" delay={0} content={t("study-session-is-cancelled-for-external-reason")}><span><FiAlertTriangle className={classnames("relative bg-gray-100 p-1 h-6 w-6  text-blue-400")}/></span></Tooltip>}
                    {is_retaken && <Tooltip position="right" delay={0} content={t("study-session-is-retaken", {originalPeriod:locale.format("%d %B")(dateParse(original_period))})}><span><FiRotateCcw className={classnames("relative bg-gray-100 p-1 h-6 w-6  text-green-500")}/></span></Tooltip>}
                  </div>
                </div>
              </div>
            </span>}
         </Button>
} 

function Results({studySessions}){
  return <div className="divide-y">
            {studySessions.map(d=><Cell key={d.id} {...d}/>)}
          </div>
}

export function ControlledStudySessionSearchList({studySessions, paging, setPage, hasMore, error}){
  const { filters, dispatchFilters } = useContext(SearchContext);

  // Focus date
  const [focusDate, setFocusDate] = useState();
  const [date, setDate] = useState(filters.date);
  const windowDates = useMonth(date);

  useEffect(()=>{
    if (!datesAreInInterval(windowDates.fromDate, filters.fromDate, {step: 'day'})){
      dispatchFilters({type: 'dates', value: {...windowDates, date}})
    }
  }, [date, windowDates]);

  const events = useMemo(()=> {
    if (!studySessions) return;
    return studySessions.map(d=>({date:dateParse(d.period), color: d.code.color}))
  }, [studySessions]);

  if (!studySessions){
    return <Skeleton.List itemClassName="h-8 rounded" className="space-y-3 p-3" numElements={4}/>
  }
  return <div className="h-full w-full overflow-y-auto">
          <div className="bg-white mx-auto px-2">
            <DoubleMonthView 
                date={date} 
                onDateChange={(d)=>{setDate(d); setFocusDate(); }} 
                onClick={d=>{setFocusDate(d); dispatchFilters({type: 'dates', value:{fromDate: d, toDate: d}})}} 
                events={events} 
                interval={focusDate? {fromDate: focusDate, toDate: focusDate, light: true}: null}
                layout="full" 
                className="w-full pt-6 pb-3" 
                numMonths={1}/>
            {focusDate && 
              <p className="bg-gray-100 rounded-lg p-2 flex items-center justify-between mb-2 space-x-3">
                {locale.format("%d %B %Y")(focusDate)}
              <Button color="delete" onClick={()=>{setFocusDate(); setDate(new Date(date)) }}><FiX/></Button></p>}
          </div>
          <div className="flex-1">
                  {error? <Error.Text className="mt-3" {...error}/>: null}

                  {studySessions.length===0?
                    <div className="bg-gray-100 rounded-lg p-3 mx-3 h-full">
                      <Empty/>
                    </div>:<Results studySessions={studySessions} paging={paging}/>
                      }
            {paging&& hasMore && <PaginateCompact setPage={setPage} {...paging}/>}

                </div>
         </div>
}


export function StudySessionSearchList(){
  const { filters } = useContext(SearchContext);
  const validateParams = useCallback(({fromDate, toDate})=>{
    if (!fromDate || !toDate) return false;
    return true;
  }, [])
  const [studySessions, { loading, error, paging, hasMore, setPage }] = useSearch(searchStudySessions, filters, {validateParams, limit: 300});

  return <ControlledStudySessionSearchList studySessions={studySessions} paging={paging} setPage={setPage} hasMore={hasMore} error={error} loading={loading}/>
}