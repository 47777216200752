import React from "react"

// Components
import { Pill } from "components/core/pill"
import { Link } from "react-router-dom"
import { Button } from "components/core/button"
import { Dropdown } from "components/core/dropdown"
import { TeamContext } from "contexts/team"

// Utils
import classNames from "classnames"
import { recursiveCamelize } from "utils/casing"

// Icons
import { FiPlus as AddIcon } from "react-icons/fi";

// Constants
export const GEN_2_CODE = "ea-diversite-2e-gen"
export const MIFI_CODE = "ea-mifi-1ere-gen"

// API
import { createNote } from "api/notes"

// Hooks
import { useContext, useMemo } from "react"
import { useStudentsMeetings } from "contexts/student-meetings"
import { useAPI } from "hooks/useAPI"

export function Mifi({ student, mifi, className, loading }) {
    const isGen2 = mifi?.find(mifi => mifi?.code === GEN_2_CODE || mifi?.code?.code === GEN_2_CODE)
    const isMifi = mifi?.find(mifi => mifi?.code === MIFI_CODE || mifi?.code?.code === MIFI_CODE)
    if (loading) return <div className={classNames(className)}></div>
    return <>
        <div className={classNames(className,)}>
            {
                (!isMifi && !isGen2) && <AddMifi student={student} />
            }
            {
                (isMifi || isGen2) && <Link to={`?noteId=${isMifi?.id || isGen2?.id}`}>
                    <Pill.Empty className={"whitespace-nowrap"} color="purple">{isMifi ? "MIFI" : "2e GEN"}</Pill.Empty>
                </Link>
            }
        </div>
    </>
}

export function AddMifi({student}) {
    
    const { team, loading:loadingTeam} = useContext(TeamContext);
    const { setStudents } = useStudentsMeetings();
    const {teamPeriod, start, end} = useMemo(() => {
        const v = recursiveCamelize(team);
        return {start: v?.activePeriod?.start, end: v?.activePeriod?.end, teamPeriod: v?.activePeriod?.slug}
    }, [team])
    const openedAt = useMemo(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const startDate = new Date(start);
        const endDate = new Date(end);
        if (today >= startDate && today <= endDate) {
            return today;
        }
        return startDate;
    }, [teamPeriod, loadingTeam])
    
    const handleCreate = (note) => {
        setStudents((students) => {
            const studentIndex = students.findIndex((s) => s.ni === student?.ni);
            const studentCopy = {...students[studentIndex]};
            studentCopy.mifi = [note, ...(studentCopy?.mifi || [])];
            students[studentIndex] = studentCopy;
            return [...students];
        })
    }
    const [,{execute, loading}] = useAPI(createNote, {student: student.ni, openedAt, closedAt: openedAt}, {immediate: false, camelize: true, onResult: handleCreate})
    if (loadingTeam) return <></>
    return <>
        <div className="group-hover:opacity-100 opacity-0 transition-all relative">
            <Dropdown 
                show={loading}
                vOrientation="up" 
                menuItemsClassName="!-translate-y-[32px] bottom-0 right-2" 
                itemClassName="w-[130px]" 
                customButton={
                    <div className="text-base rounded inline-flex items-center shadow-none bg-gray-100 text-gray-600 hover:text-gray-800 hover:bg-gray-200 transition-all duration-100 outline-none !p-1.5">
                        <AddIcon />
                    </div>
                }>
                <Dropdown.Info className={"!px-1"}>
                    <div className="flex justify-between gap-1">
                        <Button onClick={()=>execute({code:MIFI_CODE})} disabled={loading} color="default" className={"rounded p-1 w-full flex justify-center"}>
                            MIFI
                        </Button>
                        <Button onClick={()=>execute({code:GEN_2_CODE})} disabled={loading} color="default" className={"rounded p-1 w-full flex justify-center"}>
                            2e GEN
                        </Button>
                    </div>
                </Dropdown.Info>
            </Dropdown>
        </div>
    </>
}
