import React, { useEffect, useMemo } from 'react';

// Components
import { InfoSectionHeading  } from 'components/core/typo';
import { Button } from 'components/core/button';

import { Error, RawTextSuccess } from 'components/core/typo'
import { FlexNav } from 'components/core/layouts/flex';
import { StudentsUpload } from 'components/bulk-import/students';
import { HelpSchoolYears } from 'components/bulk-import/school-year';
import { HelpSportTeams } from 'components/bulk-import/sport-team';
import { SelectSchool } from 'components/tools/schools_list';
import { Table } from 'components/core/table';
import { EmptyState } from 'components/core/empty';
import { Tooltip } from 'components/core/tooltip';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAPI } from 'hooks/useAPI';

// Utils
import classnames from 'classnames';

// API
import { validateBulkInsert, bulkInsertStudents } from 'api/students';
import { Spinner } from 'components/core/spinner';
import { SelectPeriods } from 'components/tools/select_period';
import { HelpSchoolProfiles } from 'components/bulk-import/school-profiles';
import { FiCheck } from 'react-icons/fi';
import { getFullCurrentPeriod } from 'utils/period';

function ApplyButton({students, school, period, onResult}){
  const { t } = useTranslation('common');
  const params = useMemo(() => ({students, school: school && school.slug, period: period && period.slug }), [students, school, period])
  const [result, {execute, loading, error}] = useAPI(bulkInsertStudents, params, {onResult, immediate:false})

  return <div className="relative">
          <InfoSectionHeading mediumTitle={t("bulk-import.students.summary.title")} longDescription={t("bulk-import.students.summary.description")}/>
            <div className={classnames(" rounded-lg p-3 w-full space-y-3 border-2 border-orange-500")}>
            <div>
              <h3 className="font-medium text-orange-600">{t("bulk-import.students.title")}</h3>
              <p className="text-gray-500">{t("bulk-import.students.final-description", {n: (students || []).length, period: (period || {name: "(À choisir)"}).name, school: (school || {name: "(À choisir)"}).name})}</p>
            </div>
            <Button loading={loading} onClick={execute} disabled={!students || students.length===0 || !school || !period} block color="active" size="lg">{t("bulk-import.students.button")}</Button>
            {result && <RawTextSuccess>
              {t("bulk-import.students.success")}
            </RawTextSuccess>}
            {error? <Error.Text {...error}/>: null}
           </div>
        </div>
}

function Cell({errors, errorKey, value}){
  const { t } = useTranslation('common');
  const _errors = errors && errors.filter(e=>e.field===errorKey);
  const hasErrors= _errors && _errors.length>0;
  const isWarnings = _errors && _errors.some(e=>e.level==="warning");
  return <div className={classnames("text-sm", !hasErrors?"text-black": isWarnings? "text-yellow-600" :"text-red-500")}>
          {value} {!hasErrors && 
          <Tooltip content={t("field-valid")}  delay={0}>
              <span className='relative'><FiCheck className="text-green-500 inline "/></span>
      </Tooltip>}
          {/* Map the errors to badges */}
          <div>
            {hasErrors && _errors.map((e,i)=>
            <Tooltip key={i} content={e.details} delay={0} className={"!max-w-xs"}>
              <span className={classnames(e.level==='warning'? "bg-yellow-100 text-yellow-600":"bg-red-100 text-red-600", " rounded-md text-xs mr-2 px-1 py-0.5 relative")}>{e.message}</span>
              </Tooltip>)}
          </div>
        </div>
}

export default function Students(){
  const { t } = useTranslation("common");
  const [result, setResult] = useState();
  const [value, setValue] = useState([]);
  const [validatedValues, setValidatedValues] = useState([]);
  const [school, setSchool] = useState();
  const [period, setPeriod] = useState();
  const currentPeriod = getFullCurrentPeriod();
  const params = useMemo(() => ({students: value.map(d=>({firstname: d.Prenom,
                                                              lastname: d.Nom,
                                                          phone: d.Tel,
                                                        email: d.Courriel1,
                                                      email2: d.Courriel2,
                                                    group_class:d.Groupe,
                                                  folder_number: d.Fiche,
                                                school_year: d.AnneeScolaire,
                                                birth_country: d.PaysDeNaissance,
                                                birth_country_parent: d.PaysDeNaissanceParent,
                                                social_networks: d.ReseauxSociaux,
                                                school_profile: d.ProfilScolaire,
                                                tshirt_size: d.TailleDeChandail,
                                                gender: d.Genre,
                                                french: d.Francisation,
                                                work_hours: d.HeuresDeTravail,
                                                year_retained: d.Redoublement,
                                              teams: d.Equipe.split(",")
                                                })), school: school && school.slug, period: period && period.slug}), [value, school, period])
  const [validationErrors, {loading, error, execute}] = useAPI(validateBulkInsert, params, {immediate:false});
  useEffect(()=>{
    if (value && value.length>0 && school && period){
      execute();
    }
  }, [value, school, period]);

  // Concatenate errors and value
  useEffect(()=>{
    if (validationErrors){
      setValidatedValues(value.map((d,i)=>({...d, errors: validationErrors[i]})));
    }
  }, [validationErrors]);

  const columns = useMemo(() => [
    {
      title:"",
      field: "index",
      itemClassName: "font-medium "
    },
    {
      title: t("folder-number"),
      field: (d=>d? {value: d.Fiche, errors: d.errors, errorKey: "folder_number"} :"Fiche"),
      FormatComponent: Cell,
      itemClassName: " ",
      className: "min-w-[200px]  "
    },
    {
      title: t("firstname"),
      field: (d=>d? {value: d.Prenom, errors: d.errors, errorKey: "firstname"} :"Prenom"),
      FormatComponent: Cell,
      className: "min-w-[200px]"
    },
    {
      title: t("name"),
      field: (d=>d? {value: d.Nom, errors: d.errors, errorKey: "lastname"} :"Nom"),
      FormatComponent: Cell,
      className: "min-w-[200px]"
    },
    {
      title: t("school-year"),
      field: (d=>d? {value: d.AnneeScolaire, errors: d.errors, errorKey: "school_year"} :"AnneeScolaire"),
      FormatComponent: Cell,
      className: "min-w-[200px]"
    },
    {
      title: t("email"),
      field: (d=>d? {value: d.Courriel1, errors: d.errors, errorKey: "email"} :"Courriel1"),
      FormatComponent: Cell,
      className: "min-w-[200px]"
    },
    {
      title: t("email2"),
      field: (d=>d? {value: d.Courriel2, errors: d.errors, errorKey: "email2"} :"Courriel2"),
      FormatComponent: Cell,
      className: "min-w-[200px]"
    },
    {
      title: t("group"),
      field: "Groupe",
    },
    {
      title: t("phone"),
      field: "Tel",
    },
    {
      title: t("team"),
      field: (d=>d? {value: d.Equipe, errors: d.errors, errorKey: "teams"} :"Equipe"),
      FormatComponent: Cell,
      className: "min-w-[200px]"
    },
    {
      title: t("country-born"),
      field: (d=>d? {value: d.PaysDeNaissance, errors: d.errors, errorKey: "birth_country"} :"PaysDeNaissance"),
      FormatComponent: Cell,
      className: "min-w-[200px]"
    },
    {
      title: t("parent-country-born"),
      field: (d=>d? {value: d.PaysDeNaissanceParent, errors: d.errors, errorKey: "birth_country_parent"} :"PaysDeNaissanceParent"),
      FormatComponent: Cell,
      className: "min-w-[200px]"
    },
    {
      title: t("social-network-name"),
      field: "ReseauxSociaux",
      className: "min-w-[200px]"
    },
    {
      title: t("school-profile"),
      field: (d=>d? {value: d.ProfilScolaire, errors: d.errors, errorKey: "school_profile"} :"ProfilScolaire"),
      FormatComponent: Cell,
      className: "min-w-[200px]"
    },
    {
      title: t("francization"),
      field: (d=>d? {value: d.Francisation, errors: d.errors, errorKey: "francization"} :"Francisation"),
      FormatComponent: Cell,
      className: "min-w-[200px]"

    },
    {
      title: t("work-hours"),
      field: (d=>d?  {value: d.HeuresDeTravail, errors: d.errors, errorKey: "work_hours"} :"HeuresDeTravail"),
      FormatComponent: Cell,
      className: "min-w-[200px]"
    },
    {
      title: t("redoublement"),
      field: (d=>d? {value: d.Redoublement, errors: d.errors, errorKey: "year_retained"} :"Redoublement"),
      FormatComponent: Cell,
      className: "min-w-[200px]"
    },
    {
      title: t("gender"),
      field: (d=>d? {value: d.Genre, errors: d.errors, errorKey: "gender"} :"Genre"),
      FormatComponent: Cell,
      className: "min-w-[200px]"
    },
    {
      title: t("tshirt-size"),
      field: (d=>d? {value: d.TailleDeChandail, errors: d.errors, errorKey: "tshirt_size"} :"TailleDeChandail"),
      FormatComponent: Cell,
      className: "min-w-[200px]"
    }
  ], []);

  return <FlexNav breakdown="lg" className={"w-full mx-auto  relative px-3 max-w-none "}>
            <FlexNav.Content breakdown="lg" className="py-6 max-w-7xl w-full ">
              <div className="mx-auto w-full  space-y-8">
                <InfoSectionHeading mediumTitle={t("bulk-import.students.title")} longDescription={t("bulk-import.students.description")}/>
                {result?
                   <div className="p-3 rounded-md bg-green-100 text-green-700">
                    <p className="">{t("bulk-import.students.success", {numClosed: result.num_closed})}</p>
                  </div>:
                    <div className="space-y-12">
                      <div className='grid lg:grid-cols-2 lg:gap-3 gap-y-3'>
                        <div className='lg:border  rounded-md lg:px-3 lg:pb-3'>
                          <InfoSectionHeading smallTitle={t("bulk-import.students.school.title")} longDescription={t("bulk-import.students.school.description")}/>
                          <SelectSchool value={school} setValue={setSchool}/>
                        </div>
                        <div  className='lg:border  rounded-md lg:px-3 lg:pb-3'>
                          <InfoSectionHeading smallTitle={t("bulk-import.students.period.title")} longDescription={t("bulk-import.students.period.description")}/>
                          <SelectPeriods value={period} setValue={setPeriod}/>
                          {/* If period != currentPeriod, show a flag */}
                          {period && period.slug !== currentPeriod.slug && <div className="text-xs text-red-500 font-medium">{t("bulk-import.students.period.warning")}</div>}
                          
                        </div>
                      </div>
                      <StudentsUpload setValue={setValue}/>
                      <div>
                        <InfoSectionHeading smallTitle={t("bulk-import.students.results.title")} longDescription={t("bulk-import.students.results.description")}/>
                        {error? <Error.Text {...error}/>: null}
                        {loading?
                          <div className="flex items-center justify-center">
                            <Spinner className="w-12 h-12"/>
                          </div>: null}
                        <div className='w-full overflow-auto relative pb-12'>
                        
                        <Table data={validatedValues} headers={columns} indexingKey={"index"} rowClassName={"even:bg-gray-50 hover:bg-gray-100"}/>
                          </div>
                        {!value || value.length === 0 || !validationErrors? <EmptyState title={t("bulk-import.students.empty.title")}/>: null}
                      </div>
                    </div>
                 }
               </div>

             </FlexNav.Content>
             <FlexNav.Bar breakdown="lg" className="order-last lg:border-l lg:pl-6 flex-shrink-0 ">
              <div className="sticky top-0 space-y-12 max-h-full pb-12">
                <ApplyButton  onResult={setResult} students={params && params.students} school={school} period={period}/>
                <HelpSchoolYears/>
                <HelpSchoolProfiles/>
                <HelpSportTeams/>

              </div>
             </FlexNav.Bar>
            </FlexNav>

}
