import React from 'react';

// Components
import { FlexNav } from 'components/core/layouts/flex';
import { InfoSectionHeading  } from 'components/core/typo';
import { Button } from 'components/core/button';
import { Error } from 'components/core/typo'
import { Info } from 'components/core/info';
import { SelectPreferedComm } from 'components/tools/prefered_communication_list';
import { ModifiableSelectField, SingleLineField} from 'components/tools/modifiable_fields';
import { SelectSchool } from 'components/tools/schools_list';
import { SelectDate } from 'components/tools/select_date';
import { SelectMultipleTeams } from 'components/tools/search_teams';
import { SelectSchoolFail } from 'components/tools/new_class_failed';
import { SelectSiblings } from 'components/tools/new_sibling';
import { SelectSchoolYear } from 'components/tools/school_year_list';
import { CheckBox } from 'components/tools/checkbox';
import { SelectDraftSubjectPreferences  } from 'components/student/preferences';
import { SelectWeeklyAvailabilities } from 'components/tools/select_weekly_availabilities';

// Hooks
import { useAPI } from 'hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useState, useMemo } from 'react';


// Utils
import classnames from 'classnames';
import {dateParse, locale} from 'utils/locale';

// API
import { createStudent } from 'api/students';
import { FieldsSection } from 'components/core/fields-section';
import { SelectSchoolProfile } from 'components/tools/select_school_profile';
import { SelectMotivation } from 'components/tools/select_motivation';
import { getCurrentPeriod } from 'utils/period';
import { FiSmile } from 'react-icons/fi';
import { SelectBool } from 'components/tools/select_bool';

function CreationView({draftStudent, setDraftStudent}){
  const { t } = useTranslation("common");
  const modify = true;
  const period = getCurrentPeriod();
  return <div className="w-full space-y-8 lg:pr-12 mx-auto"> 
          <InfoSectionHeading title={t("create-student")}/>
          <FieldsSection title={t('section-contact')} description={t('section-contact-description')} contentClassName="space-y-4" >
        <div className="grid gap-3 rounded-lg md:gap-6 md:grid-cols-2">
          <SingleLineField
            label={modify && t("firstname")}
            modify={modify}
            showSavingState={false}
            value={draftStudent.firstname}
            setValue={(firstname) => setDraftStudent(d=>({...d, firstname }))}/>
          <SingleLineField
            label={modify && t("lastname")}
            modify={modify}
            showSavingState={false}
            value={draftStudent.lastname}
            setValue={(lastname) => setDraftStudent(d=>({...d, lastname }))}/>
        </div>
        <SingleLineField
          label={modify && t("email")}
          modify={modify}
          showSavingState={false}
          value={draftStudent.email}
          setValue={(email) => setDraftStudent(d=>({...d, email }))}/>
        <SingleLineField
          label={modify && t("email2")}
          modify={modify}
          showSavingState={false}
          value={draftStudent.email2}
          setValue={(email2) => setDraftStudent(d=>({...d, email2 }))}/>

        <div className="grid gap-3 rounded-lg md:gap-6 md:grid-cols-2">
          <SingleLineField
            label={modify && t("phone")}
            modify={modify}
            showSavingState={false}
            value={draftStudent.phone}
            setValue={(phone) => setDraftStudent(d=>({...d, phone }))}/>
          <SingleLineField
            label={modify && t("cellphone")}
            modify={modify}
            showSavingState={false}
            value={draftStudent.cellphone}
            setValue={(cellphone) => setDraftStudent(d=>({...d, cellphone }))}/>
        </div>
        <SingleLineField
          label={modify && t("social-network-name")}
          modify={modify}
          showSavingState={false}
          value={draftStudent.socialNetworkName}
          setValue={(socialNetworkName) => setDraftStudent(d=>({...d, socialNetworkName }))}/>
          <ModifiableSelectField
            noBorder={true}
            Field={SelectPreferedComm}
            label={modify && t("prefered-communication")}
            modify={modify}
            value={draftStudent.preferedCommunication}
          setValue={(preferedCommunication) => setDraftStudent(d=>({...d, preferedCommunication }))}>

          </ModifiableSelectField>
          <ModifiableSelectField 
                      Field={SelectSiblings} 
                      label={t("siblings")} 
                      noBorder={true}
                      modify={true}
                      value={draftStudent.siblings}
                      setValue={(siblings)=>setDraftStudent(d=>({...d, siblings}))}/>

      </FieldsSection>
      <FieldsSection title={t('section-folder')} description={t('section-folder-description')} >
        <div className="grid gap-3 rounded-lg md:gap-6 md:grid-cols-2">
          <SingleLineField
            label={modify && t("folder-number")}
          showSavingState={false}
          modify={modify}
            value={draftStudent.folderNumber}
            setValue={(folderNumber) => setDraftStudent(d=>({...d, folderNumber }))}/>

          <SingleLineField
            label={modify && t("notebook-number")}
          showSavingState={false}
          modify={modify}
            value={draftStudent.notebookNumber}
            setValue={(notebookNumber) => setDraftStudent(d=>({...d, notebookNumber }))}/>

          <SingleLineField
            label={modify && t("group-class")}
          showSavingState={false}
          modify={modify}
            value={draftStudent.groupClass}
            setValue={(groupClass) => setDraftStudent(d=>({...d, groupClass }))}/>
        </div>
        <ModifiableSelectField 
                      Field={SelectSchoolFail} 
                      label={t("failed-school-classes")} 
                      modify={true}
                      noBorder={true}
                      value={draftStudent.schoolFailed}
                      setValue={(schoolFailed)=>setDraftStudent(d=>({...d, schoolFailed}))}/>
      </FieldsSection>

      <FieldsSection title={t('section-school')} description={t('section-school-description')} >
      <ModifiableSelectField 
                      Field={SelectMultipleTeams} 
                      label={t("teams")} 
                      modify={true}
                      noBorder={true}
                      value={draftStudent.teams}
                      setValue={(teams)=>setDraftStudent(d=>({...d, teams}))}/>
          <ModifiableSelectField
            noBorder={true}
            Field={SelectSchool}
            disabledClear
            label={modify && t("school")}
            modify={modify}
            value={draftStudent.school}
            setValue={(school) => setDraftStudent(d=>({...d, school }))}/>

        <ModifiableSelectField
                    noBorder={true}
                    Field={SelectSchoolYear}
                    disabledClear
                    label={modify && t("school-year")}
                    modify={modify}
                    value={draftStudent.schoolYear}
                    setValue={(schoolYear) => setDraftStudent(d=>({...d, schoolYear }))}/>
        <ModifiableSelectField
            noBorder={true}
            Field={SelectSchoolProfile}
            disabledClear
            label={modify && t("school-profile")}
            modify={modify}
            value={draftStudent.schoolProfile}
            setValue={(schoolProfile) => setDraftStudent(d=>({...d, schoolProfile }))}/>
        
        <ModifiableSelectField
          noBorder={true}
          Field={CheckBox}
          label={t("francization")}
          modify={true}
          value={draftStudent.francization}
            setValue={(francization) => setDraftStudent(d=>({...d, francization }))}/>


        <ModifiableSelectField
          noBorder={true}
          Field={SelectMotivation}
          label={modify && t("motivation.label")}
          modify={modify}
          disabledClear
          value={draftStudent.motivation}
            setValue={(motivation) => setDraftStudent(d=>({...d, motivation }))}/>
      </FieldsSection>
      <FieldsSection title={t('section-participation')} >
        <ModifiableSelectField
          noBorder={true}
          Field={CheckBox}
          label={t("participate-to-hc")}
          modify={true}
          value={draftStudent.participateToHc}
          setValue={(participateToHc) => setDraftStudent(d=>({...d, participateToHc }))}/>

        <ModifiableSelectField
          noBorder={true}
          Field={SelectDate}
          label={modify && t("started-using-service")}
          modify={modify}
          value={draftStudent.startedUsingService && dateParse(draftStudent.startedUsingService)}
          setValue={(startedUsingService) => setDraftStudent(d=>({...d, startedUsingService }))}/>
      </FieldsSection>


      <FieldsSection title={t('section-family')} description={t('section-family-description')} >
        <ModifiableSelectField
          noBorder={true}
          Field={SelectBool}
          label={modify && t("born-out-canada")}
          modify={modify}
          disabledClear
          value={draftStudent.bornOutCanada}
          setValue={(bornOutCanada) => setDraftStudent(d=>({...d, bornOutCanada }))}/>
        <ModifiableSelectField
          noBorder={true}
          Field={SelectBool}
          label={modify && t("parent-born-out-canada")}
          modify={modify}
          disabledClear
          value={draftStudent.parentBornOutCanada}
          setValue={(parentBornOutCanada) => setDraftStudent(d=>({...d, parentBornOutCanada }))}/>
        <ModifiableSelectField
          noBorder={true}
          Field={SelectBool}
          label={modify && t("parent-motivation")}
          modify={modify}
          disabledClear
          Icon={FiSmile}
          value={draftStudent.parentMotivation || false}
          setValue={(parentMotivation) => setDraftStudent(d=>({...d, parentMotivation }))}/>
         
      </FieldsSection>

       

      <FieldsSection title={t('section-needs')}>
          
          <SingleLineField
            label={modify && t("work-hours")}
            description={t("work-hours-description")}
            showSavingState={false}
          modify={modify}
            value={draftStudent.workHours}
            setValue={(workHours) => setDraftStudent(d=>({...d, workHours }))}/>

<SingleLineField 
                            label={t("future-dreams")} 
                            modify={true} 
                            showSavingState={false}
                            value={draftStudent.futureDreams} 
                            multiLine={true}
                            setValue={(futureDreams)=>setDraftStudent(d=>({...d, futureDreams}))}/>
<SingleLineField 
                            label={t("particular-needs")} 
                            modify={true} 
                            showSavingState={false}
                            value={draftStudent.particularNeeds} 
                            multiLine={true}
                            setValue={(particularNeeds)=>setDraftStudent(d=>({...d, particularNeeds}))}/>
             
      </FieldsSection>
            
      <InfoSectionHeading title={t("tutoring")}/>
      <ModifiableSelectField
          noBorder={true}
          Field={SelectDate}
          label={t("demand-for-tutoring.label", {period})}
          subLabel={t("demand-for-tutoring.subLabel")}
          modify={true}
          value={draftStudent.demandForTutoring && dateParse(draftStudent.demandForTutoring)}
                            setValue={(demandForTutoring)=>setDraftStudent(d=>({...d, demandForTutoring}))}/>

          <ModifiableSelectField 
                      Field={CheckBox} 
                      label={t("prioritary-for-tutoring.label")} 
                      subLabel={t("prioritary-for-tutoring.subLabel")} 
                      modify={true}
                      marker="select"
                      value={draftStudent.prioritaryForTutoring || false}
                       setValue={(prioritaryForTutoring)=>setDraftStudent(d=>({...d, prioritaryForTutoring}))}/>
            
         
                <SelectDraftSubjectPreferences 
                  value={draftStudent.preferences || []} 
                            setValue={(preferences)=>setDraftStudent(d=>({...d, preferences}))}/>

            <Info.Container modify={true}>
                    <InfoSectionHeading mediumTitle={t("tutoring-preference")}/>

                  <SelectWeeklyAvailabilities 
              value={draftStudent.scheduleAvailabilitiesJSON}
              setValue={(scheduleAvailabilitiesJSON)=>setDraftStudent(d=>({...d, scheduleAvailabilitiesJSON, scheduleAvailabilities: scheduleAvailabilitiesJSON.map(e=>[e.from_cron, e.to_cron])}))}/>


             <ModifiableSelectField 
                      Field={CheckBox} 
                      label={t("online-preference")} 
                      modify={true}
                      value={draftStudent.onlinePreference || false}
                      setValue={(onlinePreference)=>setDraftStudent(d=>({...d, onlinePreference}))}/>
              <ModifiableSelectField 
                      Field={CheckBox} 
                      label={t("hybrid-preference")} 
                      modify={true}
                      value={draftStudent.hybridPreference || false}
                      setValue={(hybridPreference)=>setDraftStudent(d=>({...d, hybridPreference}))}/>
              <ModifiableSelectField 
                      Field={CheckBox} 
                      label={t("on-site-preference")} 
                      modify={true}
                      value={draftStudent.onSitePreference || false}
                      setValue={(onSitePreference)=>setDraftStudent(d=>({...d, onSitePreference}))}/>
             
            </Info.Container>
          </div>
}

function SideNavigation({draftStudent, onResult}){
  const { t } = useTranslation("common");
  const steps = useMemo(()=>{
    return [{
      name: t("name"), 
      description: draftStudent.firstname && draftStudent.lastname && `${draftStudent.firstname} ${draftStudent.lastname}`,
      completed: draftStudent.firstname && draftStudent.lastname
    },
    {
      name: t("email"), 
      description: draftStudent.email, 
      completed: draftStudent.email ,
      hide: !draftStudent.email
    },
    {
      name: t("email2"), 
      description: draftStudent.email2, 
      completed: draftStudent.email2,
      hide: !draftStudent.email2 
    },
    {
      name: t("phone"), 
      description: draftStudent.phone, 
      completed: draftStudent.phone,
      hide: !draftStudent.phone
    },
    {
      name: t("folder-number"), 
      description: draftStudent.folderNumber, 
      completed: draftStudent.folderNumber ,
      hide: !draftStudent.folderNumber
    },
    {
      name: t("group-class"), 
      description: draftStudent.groupClass, 
      completed: draftStudent.groupClass ,
      hide: !draftStudent.groupClass
    },
    {
      name: t("notebook-number"), 
      description: draftStudent.notebookNumber, 
      completed: draftStudent.notebookNumber ,
      hide: !draftStudent.notebookNumber
    },
    {
      name: t("social-network-name"), 
      description: draftStudent.socialNetworkName, 
      completed: draftStudent.socialNetworkName ,
      hide: !draftStudent.socialNetworkName
    },
    {
      name: t("teams"), 
      description: draftStudent.teams && draftStudent.teams.map(d=>`${d.team.name}${d.description? `(${d.description})`: ""}`).join(', '),
      completed: draftStudent.teams ,
      hide: !draftStudent.teams
    },
    {
      name: t("siblings"), 
      description: draftStudent.siblings && draftStudent.siblings.map(d=>`${d.student.name}${d.description? `(${d.description})`: ""}`).join(', '),
      completed: draftStudent.siblings ,
      hide: !draftStudent.siblings
    },
    {
      name: t("failed-school-classes"), 
      description: draftStudent.schoolFailed && draftStudent.schoolFailed.map(d=>d.schoolClass.name).join(', '),
      completed: draftStudent.schoolFailed ,
      hide: !draftStudent.schoolFailed
    },
    {
      name: t("school"), 
      description: draftStudent.school && draftStudent.school.name, 
      completed: draftStudent.school
    },
    {
      name: t("school-year"), 
      description: draftStudent.schoolYear && draftStudent.schoolYear.name, 
      completed: draftStudent.schoolYear
    },
    {
      name: t("started-using-service"), 
      description: draftStudent.startedUsingService && locale.format("%d %B %Y")(draftStudent.startedUsingService),
      completed: draftStudent.startedUsingService ,
      hide: !draftStudent.startedUsingService
    },
    {
      name: t("prefered-communication"), 
      description: draftStudent.preferedCommunication && draftStudent.preferedCommunication.name, 
      completed: draftStudent.preferedCommunication,
      hide: !draftStudent.preferedCommunication
    },
    {
      name: t("subject-preferences"), 
      description: draftStudent.preferences && draftStudent.preferences.filter(d=>d.description && d.description!="none").map(d=>d.name).join(', '),
      completed: draftStudent.preferences ,
      hide: !draftStudent.preferences
    },];
  }, [draftStudent]);

  const [, request] = useAPI(createStudent, draftStudent, {immediate: false, onResult});

  const disabled = useMemo(()=>{
    if (!draftStudent.firstname) return true;
    if (!draftStudent.lastname) return true;
    if (!draftStudent.school) return true;
    if (!draftStudent.schoolYear && !draftStudent.schoolProfile) return true;
    return false;
  }, [draftStudent])

  return (<div className="bg-gray-100 lg:bg-white rounded-lg p-3 lg:pl-6 lg:pt-8 ">
            <InfoSectionHeading subtitle={t("summary")}/>
            {steps.filter(d=>!d.hide).map((d=>
                <div key={d.name}>
                  <div className={classnames("flex items-start py-2", d.warning&&"bg-yellow-50 rounded-lg")}>
                    {d.warning?
                      <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <span className="absolute h-4 w-4 rounded-full bg-yellow-200" />
                        <span className="relative block w-2 h-2 bg-yellow-600 rounded-full" />
                      </span>:
                      !d.completed?
                      <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                      </div>:
                      <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <span className="absolute h-4 w-4 rounded-full bg-green-200" />
                        <span className="relative block w-2 h-2 bg-green-600 rounded-full" />
                      </span>
                    }
                    <div>
                      <p className={classnames("ml-3 font-medium", d.warning?"text-yellow-800":d.completed? "text-green-600": "text-gray-400")}>{d.name}</p>
                      {
                        d.warning? <p className="ml-3 text-yellow-600 ">{d.warning}</p>:
                        d.description && <p className="ml-3 text-gray-800 ">{d.description}</p>
                      }
                    </div>
                  </div>
                </div>
            ))}

            <Button className="mt-6" 
                      color="active" 
                      onClick={request.execute} 
                      loading={request.loading} 
                      disabled={disabled} 
                      block 
                      size="lg">{t('create-student')}</Button>
            {request.error && <Error.Text {...request.error}/>}
          </div>)
}

function AddedStudents({students}){
  if (students.length===0) return null;
  return <div className="divide-y ">
          {students.map(student=>
            <Button key={student.ni} color="inlineLink" layout="blockLeft" className="px-3 py-1" href={`/students/${student.ni}`}>{student.name}</Button>)}
        </div>
}

function DoneStudent({student, setStudent}){
 
  const { t } = useTranslation("common");
  return <div className="mx-auto w-full max-w-lg space-y-8 mt-12">
            <h1 className="font-semibold text-2xl">{t("student-added-title", {name: student.name})}</h1>
            <Button block color="active" size="lg" onClick={()=>setStudent()}>
              {t("add-more-student")}
            </Button>
          </div>
}

export function NewStudent(){
  const [students, setStudents] = useState([]);
  const [student, setStudent ] = useState();
  const [draftStudent, setDraftStudent] = useState({});

  return <FlexNav breakdown="lg" className={"!max-w-7xl mx-auto relative h-full"}>
            <FlexNav.Content breakdown="lg" className="py-6">
              {student?
                <DoneStudent student={student} setStudent={setStudent}/>:
                <CreationView
                draftStudent={draftStudent} 
                setDraftStudent={setDraftStudent} 
              />}
             </FlexNav.Content>
             <FlexNav.Bar breakdown="lg" className="order-last lg:border-l lg:pl-6 ">
              <div className="absolute sticky top-0 space-y-12 max-h-full pb-12">
                <SideNavigation draftStudent={draftStudent} onResult={next=>{setDraftStudent({}); setStudent(next); setStudents(d=>[next, ...d])}}/>
              </div>
                <AddedStudents students={students}/>
            </FlexNav.Bar>
            </FlexNav>
}
