import React from "react";

// Components
import { Skeleton } from "components/core/skeleton";
import { Dropdown } from "components/core/dropdown"

// Icons
import { FiInfo as InfoIcon } from "react-icons/fi";

// Utils
import classNames from "classnames";

// Hooks

export function StatsCard({children, title, className, loading, ...props}){
    
    return <>
        <div className={classNames(" p-3 bg-white shadow  rounded-xl sm:p-6 flex gap-2 flex-col h-fit", className)} {...props}>
            {title && <StatsCardTitle className="font-semibold text-lg">{title}</StatsCardTitle>}
            {
                loading ? <>
                    <Skeleton className="min-h-48" />
                </>
                :
                <>
                    {children}
                </>
            }
        </div>
    
    </>
}


export function StatsCardSmall({children, title, className, loading, ...props}){
    
    return <>
        <div className={classNames(" p-2 bg-white shadow  rounded-xl sm:p-3 flex gap-2 flex-col justify-between h-fit bg-gray-100", className)} {...props}>
            {title && <StatsCardTitle className="!font-medium !text-base">{title}</StatsCardTitle>}
            {
                loading ? <>
                    <Skeleton className="min-h-48" />
                </>
                :
                <>
                    {children}
                </>
            }
        </div>
    
    </>
}

export function StatsCardTitle({children, className, ...props}){
    return <h3 className={classNames("font-semibold text-lg", className)} {...props}>
        {children}
    </h3>
}

export function StatsCardScore({globalScore, className, title, description, isPercent, children, ...props}){
    return <div className={classNames("text-6xl shrink-0", globalScore === "-" ? "text-red-600": globalScore === "+" ? "text-green-600" : globalScore ? "text-orange-600" : "", className)} {...props}>
        {title && <span>{ title }{isPercent && <span className="ml-0.5 text-4xl">%</span>}</span>}
        {description && <span className="text-base block ml-0.5">{description}</span>}
        {children}
    </div>
}


export function StatsCardScoreSmall({globalScore, className, title, description, isPercent, children, ...props}){
    return <div className={classNames("text-3xl shrink-0", globalScore === "-" ? "text-red-600": globalScore === "+" ? "text-green-600" : globalScore ? "text-orange-600" : "", className)} {...props}>
        {title && <span>{ title }{isPercent && <span className="ml-0.5 text-4xl">%</span>}</span>}
        {description && <span className="text-base block ml-0.5">{description}</span>}
        {children}
    </div>
}

export function SubStat({title, value, className, ...props}){
    return <>
        <div className={classNames("flex items-center justify-between gap-4", className)} {...props}>
            <div className="text-gray-500">{title}</div>
            <div className='flex justify-end font-semibold'>{ value }</div>
        </div>
    </>
}

export function StatsCardInfo({title, children}) {
    
    return <>
        <Dropdown 
            onlyIcon={<InfoIcon className="w-5 h-5" />}
            menuClassName="" 
            itemClassName="w-[300px]"
            >
            <Dropdown.Info title={title} description={children} />
        </Dropdown>
    </>
}
