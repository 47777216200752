import React from 'react';

// Components
import { Outlet } from "react-router-dom";
import { StudentLayout } from 'components/student/layout';
import { ErrorBoundary } from 'pages/errors/500';
// Hooks

export function StudentBase(){
  return <StudentLayout>
            <ErrorBoundary><Outlet/></ErrorBoundary>
          </StudentLayout>
}
import { default as empty } from "./empty";
import {default as overview} from 'pages/student/overview';
import {default as documents} from 'pages/student/documents';
import {default as notes} from 'pages/student/notes';
import {default as plans} from 'pages/student/plans';


export const StudentPages = {root: StudentBase, overview, documents, notes, empty, plans}