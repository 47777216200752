import { fetchCall } from 'api';

/*
  Search play time rules
*/  
export const searchPlayTimeRules = ({sport, weeks, targetStudySessions, doneStudySessions, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/play-time-rules",
    query: {sport: sport && sport.slug, weeks, targetStudySessions, doneStudySessions, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};

/*
  Update play time rules
*/  
export const updatePlayTimeRule = ({id, weeks, targetStudySessions, doneStudySessions, targetStudySessionsDescription, doneStudySessionsDescription, playTimeTitle}) => {
  const options = {
    method: "POST", 
    endpoint: `/play-time-rules/${id}`,
    body: {weeks, targetStudySessions, doneStudySessions, targetStudySessionsDescription, doneStudySessionsDescription, playTimeTitle}
  }
  return fetchCall(options)
};

/*
  Retrieve play time rule
*/  
export const retrievePlayTimeRule = ({id}) => {
  const options = {
    method: "GET", 
    endpoint: `/play-time-rules/${id}`
  }
  return fetchCall(options)
};

/*
  Delete play time rule
*/  
export const deletePlayTimeRule = ({id}) => {
  const options = {
    method: "DELETE", 
    endpoint: `/play-time-rules/${id}`
  }
  return fetchCall(options)
};

/*
  Create play time rules
*/  
export const createPlayTimeRule = ({weeks, sport, targetStudySessions, doneStudySessions, targetStudySessionsDescription, doneStudySessionsDescription, playTimeTitle}) => {
  const options = {
    method: "POST", 
    endpoint: `/play-time-rules`,
    body: {sport: sport && sport.slug, weeks, targetStudySessions, doneStudySessions, targetStudySessionsDescription, doneStudySessionsDescription, playTimeTitle}
  }
  return fetchCall(options)
};
