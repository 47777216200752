import { useMemo } from 'react';

// Utils
import { timeMonth } from 'd3-time';

// Get the first date of month and +months
export function useMonth(date, {months=1}={}){

  return useMemo(()=>{
    var d = date || new Date();
    const fromDate = timeMonth.floor(d);
    const toDate = timeMonth.offset(fromDate, months);
    return {fromDate, toDate};
  }, [date])
}