import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { SearchUser } from 'components/tools/search_user';
import { SearchTeams } from 'components/tools/search_teams';
import { TextInput } from 'components/core/inputs';
import { Error } from 'components/core/typo'
import { Info } from 'components/core/info';
import { Button } from 'components/core/button';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useMemo, useEffect } from 'react';
import { useAPI } from 'hooks/useAPI';

// API
import { createPreset } from 'api/presets';
import { FiX } from 'react-icons/fi';

import { group } from 'd3-array';

export function TeamBadge({ children, onRemove }) {
  return <div className={`flex justify-between group rounded-md px-1 py-2`}>
    <p className=''>{children}</p>
    <Button onClick={onRemove} size="smToolbarIcon" color="delete"><FiX/></Button>
  </div>
}

export function CreatePreset({onCompleted}){
  const [draftPreset, setDraftPreset] = useState({teams: []});
  const [preset, {loading, execute, error}] = useAPI(createPreset, draftPreset, {immediate:false});
  const {t} = useTranslation('common');

  useEffect(()=>{
    if (preset) onCompleted(preset);
  }, [preset])

  const groupedTeams = useMemo(()=>{
    const groups = group(draftPreset.teams, d=>d.active_period.name);
    // Get the keys
    const keys = Array.from(groups.keys()).sort((a,b)=>a<b?-1:1);
    return {keys, groups};
  }, [draftPreset]);


  return<div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("add-preset")}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
                <TextInput
                  label={t("name")}
                  description={t("add-name-to-preset-description")}
                  onChange={(name)=>setDraftPreset(d=>({...d, name}))}
                />
                <SearchUser 
                  label={t("target-user")}
                  value={draftPreset.user}
                  size="lg"
                  setValue={(user)=>setDraftPreset(d=>({...d, user}))}
                />
                <Info.Container label={t("teams")} modify={true}>
                  <SearchTeams value={draftPreset.teams}
                     setValue={teams=>setDraftPreset(d=>({...d, teams}))}
                     size="lg"
                     searchParams={{limit: 500}}
                      showPeriodFilter={true}
                      multiple={true}
                      by={(a,b)=>a.slug===b.slug}
                      indexField={"slug"}
                      />

              <div className='grid grid-cols-1 max-h-full overflow-auto'>
                    {groupedTeams.keys.map((period)=>
                      <div key={period} className="">
                        <h3 className="font-medium text-sm text-gray-500">{period}</h3>
                        <div className='mb-5'>
                          {groupedTeams.groups.get(period).map((team)=>
                          <TeamBadge key={team.slug}  onRemove={()=>setDraftPreset(e=>({...e, teams: e.teams.filter(el=>el.slug!==team.slug)}))}>
                            {team.name}
                          </TeamBadge>)}
                        </div>
                      </div>)}
                  </div>


               
                </Info.Container>
                <Button onClick={execute} disabled={!draftPreset.user || draftPreset.teams.length===0} loading={loading} block size="lg" color="active">{t("add-preset")}</Button>
                {error&& <Error.Text {...error}/>}
              </CardContainer>
            </div>


}