import React from 'react';

// Components
import { ModifiableMultipleSelectField } from 'components/tools/modifiable_fields';
import { Info } from 'components/core/info';
import { EventCard } from 'components/events/card'
import { SlideOver } from 'components/core/slide_over';
import { Skeleton } from 'components/core/skeleton';
import { NewEventSlideOver } from 'components/tools/new_event';

// API
import { searchEvents } from 'api/events';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch';
import { locale, parseWithTimeZone } from 'utils/locale';
import { useMemo, useState } from 'react';


export function EventsSettingsModify({team}){
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const params = useMemo(()=>({team: team.slug, orderBy: "date", orderDirection: "desc"}), [team]);
  const [events, {paging, setResult, setPage, error}] = useSearch(searchEvents, params, {limit:10})
  const modify = true;
  const { t } = useTranslation('common');
  if (!events){
    return <Skeleton.List numElements={3} itemClassName="w-full h-12" className="space-y-4"/>
  }
  return <div className="min-h-screen md:min-h-0"> 
                  <ModifiableMultipleSelectField 
                    modify={modify}
                    label={t('events')}  
                    value={events} 
                    setValue={setResult}
                    Field={NewEventSlideOver}
                    paging={paging}
                    setPage={setPage}
                    error={error}
                    fieldProps={{defaultTeam:team}}
                    onShowMore={d=>{setSelectedEvent(d); setOpen(true)}}
                    indexField={"id"}>
                      {(values)=>
                        values && values.length>0?
                          values.map(val=>
                            <Info.Field key={val.id}
                                        value={locale.format("Le %A %d %B %Y à %H:%M")(parseWithTimeZone(val.date))} 
                                        label={val.event_type && val.event_type.name}
                                        />):
                          <Info.Container><Info.Field noValueLabel={`${t("none", {context: 'male'})} ${t("event").toLowerCase()}`} /></Info.Container>
                        }
                  </ModifiableMultipleSelectField>
              <SlideOver size="xl3" open={open} setOpen={setOpen}>
                <EventCard event={selectedEvent} 
                setEvent={setSelectedEvent}
                onUpdate={(d)=>{setResult(current=>current.map(e=>e.id===d.id? d: e));}}
                 onDelete={(d)=>{setResult(current=>current.filter(e=>e.id!==d.id)); setSelectedEvent(); setOpen(false);}}/>
              </SlideOver>
         </div>
}
