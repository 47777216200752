
export const options = {
    overrides: {
        div: {
          props: {
            className: "text-base md:text-lg text-gray-500 relative"
          }
        },
        h1: {
            props: {
                className: 'mt-12 mb-5 text-3xl font-bold tracking-tight text-gray-900 text-center sm:text-4xl',
            },
        },
        h2: {
            props: {
                className: 'pt-6 lg:pt-12 mb-3 md:text-right pr-6 md:translate-y-[50%] text-gray-900  text-xl md:max-w-[10em] lg:max-w-[15em] text-orange-800',
            },
        },
        h3: {
            props: {
                className: 'font-semibold text-black ',
            },
        },
        p: {
            props: {
                className: 'text-gray-700 py-2 lg:leading-7 text-base md:text-lg',
            },
        },
        a: {
            props: {
                className: 'text-gray-700 text-orange-500 text-base md:text-lg',
            },
        },
        table: {
            props: {
                className: 'text-gray-700 min-w-full divide-y divide-gray-300 mb-4 text-base md:text-lg',
            },
        },
        th: {
            props: {
                className: 'text-gray-700 py-1.5 px-3 text-left text-sm font-semibold',
            },
        },
        ul: {
            props: {
                className: 'md:border-l-2 text-gray-700 list-decimal list-outside pl-3 ml-3 pt-3 space-y-1 text-base md:text-lg max-w-3xl md:ml-[12em] lg:ml-[16em] md:pl-[4em] ',
            },
        },
        li: {
            props: {
                className: 'text-gray-700 text-base md:text-lg pb-4',
            },
        },
        ol: {
            props: {
                className: 'ml-6 lg:ml-12 py-3 list-disc',
            },
        },
        img: {
            props: {
                className: 'w-full max-w-2xl shadow-2xl rounded-lg mt-2 mb-6',
            },
        },
    },
}