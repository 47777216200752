import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { SingleLineField} from 'components/tools/modifiable_fields';
import { Button } from 'components/core/button';
import { Error } from 'components/core/typo'

// Hooks
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// API
import { deleteMeetingDate, updateMeetingDate } from 'api/meeting_dates';
import { TextInput } from 'components/core/inputs';

function DeleteButton({value, onDelete}){
  const { t } = useTranslation('common');
  const [, {execute, loading, error}] = useAPI(deleteMeetingDate, 
                                            {id: value?.id}, 
                                            {onResult: (()=>onDelete(value)), immediate:false})

  return <div className="border-2 border-red-500 rounded-lg p-3 w-full space-y-3">
          <div>
            <h3 className="font-medium text-red-600">{t("delete-meeting-dates.title")}</h3>
            <p className="text-gray-500">{t("delete-meeting-dates.description")}</p>
          </div>
          <Button loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-meeting-dates.button")}</Button>
          {error? <Error.Text {...error}/>: null}
         </div>
}



export function ModifyMeetingDate({value, setValue, onDelete}){
  const {t} = useTranslation('common');

  return<div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("modify-meeting-dates.title")}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
               
                    <SingleLineField 
                  label={t("add-meeting-date.description.title")}
                  description={t("add-meeting-date.description.description")}
                    modify={true} 
                    value={value.description} 
                    setValue={(description)=>{setValue({...value, description}); updateMeetingDate({id: value.id, description})}}/>
                <TextInput type="number" 
                          step="1" 
                          max="100" 
                          min="0" 
                          defaultValue={value.meetingGoal}
                          label={t("add-meeting-date.score-label")} 
                          placeholder={t('add-meeting-date.score-placeholder')} 
                          onKeyDown={(e) => { if (e.code === "Space") { e.stopPropagation(); } }}
                          onChange={(meetingGoal)=>{setValue({...value, meetingGoal}); updateMeetingDate({id: value.id, meetingGoal})}}
                          size="sm"/>

                {!value.system && <DeleteButton value={value} onDelete={onDelete}/>}
              </CardContainer>
            </div>


}


// import React from 'react';

// // Components
// import { CardContainer } from 'components/core/container';
// import { CardSectionHeading  } from 'components/core/typo';
// import { TextInput } from 'components/core/inputs';
// import { Error } from 'components/core/typo'
// import { Button } from 'components/core/button';

// // Hooks
// import { useTranslation } from 'react-i18next';
// import { useState, useEffect } from 'react';
// import { useAPI } from 'hooks/useAPI';

// // API
// import { createMeetingDate } from 'api/meeting_dates';
// import { SelectDateInterval } from 'components/tools/select_date';
// import { ModifiableSelectField } from 'components/tools/modifiable_fields';
// import { timeDay } from 'd3';
// import { FiAlertTriangle, FiInfo } from 'react-icons/fi';

// export function ModifyMeetingDate({onCompleted, intervals}){
//   const [draftMeetingDate, setDraftMeetingDate] = useState({meetingGoal: 12});
//   const [meetingDate, {loading, execute, error}] = useAPI(createMeetingDate, draftMeetingDate, {immediate:false});
//   const {t} = useTranslation('common');

//   useEffect(()=>{
//     if (meetingDate) onCompleted(meetingDate);
//   }, [meetingDate])

//   return<div className="flex-1 h-full overflow-hidden flex flex-col">
//               <CardContainer className="mb-6">
//                 <CardSectionHeading 
//                     title={<span className="flex items-center"> 
//                             <div>{t("add-meeting-date.title")}</div></span>} />
//               </CardContainer>
//               <CardContainer className="mb-6 space-y-8">
//                 <TextInput
//                   label={t("add-meeting-date.description.title")}
//                   description={t("add-meeting-date.description.description")}
//                   onChange={(description)=>setDraftMeetingDate(d=>({...d, description}))}
//                 />
//                  <ModifiableSelectField
//                     Field={SelectDateInterval} 
//                     label={t("add-meeting-date.dates")}
//                     subLabel={t("add-meeting-date.dates-description")}
//                     modify={true} 
//                     noBorder
//                     value={null} 
//                     canRemoveSelection={false}
//                     intervals={intervals}
//                     setValue={({fromDate: start, toDate: end})=>setDraftMeetingDate(d=>({...d, start, end}))} />
//                     {draftMeetingDate.start && draftMeetingDate.end && 
//                 <p className={"text-gray-800 bg-gray-100 rounded-md p-3"}>
//                 <FiInfo className="inline mr-2" />{t("add-meeting-date.youve-selected", {days: timeDay.count(draftMeetingDate.start, draftMeetingDate.end)+1})}
//               </p>}

//                 {draftMeetingDate.start && draftMeetingDate.end && timeDay.count(draftMeetingDate.start, draftMeetingDate.end)%7!==6 && 
//                 <p className={"text-yellow-800 bg-yellow-100 rounded-md p-3"}>
//                 <FiAlertTriangle className="inline mr-2" />{t("add-meeting-date.num-days-not-week", {days: timeDay.count(draftMeetingDate.start, draftMeetingDate.end)})}
//               </p>}
              
//                <TextInput type="number" 
//                           step="1" 
//                           max="100" 
//                           min="0" 
//                           defaultValue={12}
//                           label={t("add-meeting-date.score-label")} 
//                           placeholder={t('add-meeting-date.score-placeholder')} 
//                           onKeyDown={(e) => { if (e.code === "Space") { e.stopPropagation(); } }}
//                         onChange={(meetingGoal)=>setDraftMeetingDate(d=>({...d, meetingGoal}))}
//                           size="sm"/>
//                 <Button onClick={execute} 
//                       disabled={!draftMeetingDate.start || !draftMeetingDate.end || !draftMeetingDate.meetingGoal} 
//                       loading={loading} 
//                       block 
//                       size="lg" 
//                       color="active">{t("add-meeting-date.button")}</Button>
//                 {error&& <Error.Text {...error}/>}
//               </CardContainer>
//             </div>


// }