import React, { useEffect } from 'react';

//  Utils
import classnames from 'classnames';
import { locale, dateParse, parseWithTimeZone } from 'utils/locale';
const dateFormat = locale.format("%Y-%m-%d");
const baseDayFormat = locale.format("%d %B %Y");
import toast from 'react-hot-toast';

// Components
import { DoubleMonthView } from 'components/tools/double_month_view';
import { SelectEventType } from 'components/tools/select_event_type';
import { SingleLineField, ModifiableSelectField } from 'components/tools/modifiable_fields';
import { SelectDateInterval, SelectDate } from 'components/tools/select_date';
import { Button } from 'components/core/button';
import { Error, InfoSectionHeading } from 'components/core/typo';
import { Info } from 'components/core/info';
import { PlayTimeView } from 'components/events/play_time_view';
import { SelectExcludedDates } from 'components/tools/new_event';
// API
import { updateEvent, deleteEvent} from 'api/events';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useMemo } from 'react';
import { useAPI } from 'hooks/useAPI';
import { stringIsInteger } from 'utils/var_check';

function Header({event}){
  const interval = useMemo(()=>{
    return {fromDate: dateParse(event.from_date_calculation), toDate: dateParse(event.to_date_calculation)}
  }, [event])
  return <div className="bg-gray-100 rounded-lg p-3 mb-6">
            <div className="max-w-xs mx-auto">
              <DoubleMonthView date={parseWithTimeZone(event.date)} events={[{date: parseWithTimeZone(event.date), color: event.event_type.color}]} interval={interval} layout="stacked" numMonths={1}/>
            </div>
            <div className="text-center mt-3">
              <h1 className="text-xl font-medium">{event.event_type.name}</h1>
              <h1 className="text-lg">{locale.format("%d %B %Y à %H:%M")(parseWithTimeZone(event.date))}</h1>
            </div>
          </div>
}


function DeleteButton({value, onDelete}){
  const { t } = useTranslation('common');
  const [, {execute, loading, error}] = useAPI(deleteEvent, 
                                            {id: value.id}, 
                                            {onResult: (()=>onDelete(value)), immediate:false})

  return <div className="border-2 border-red-500 rounded-lg p-3 w-full space-y-3">
          <div>
            <h3 className="font-medium text-red-600">{t("delete-event.title")}</h3>
            <p className="text-gray-500">{t("delete-event.description")}</p>
          </div>
          <Button loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-event.button")}</Button>
          {error? <Error.Text {...error}/>: null}
         </div>
}


export function EventCard({event, setEvent, onDelete, onUpdate, defaultModify=true}){
  const { t } = useTranslation('common');
  const [modify, setModify] = useState(defaultModify);
  const [hasBeenModified, {execute, error}] = useAPI(updateEvent, {id: event && event.id}, {immediate: false, onResult: onUpdate});
  useEffect(() => {
      if (hasBeenModified) {
          toast.success(t("event-updated"))
      }
  }, [hasBeenModified])

  if (!event){
    return <div/>
  }

  return <div className="p-6">
          <Header event={event}/>
          {error && <Error.Text {...error}/>}
          <ModifiableSelectField 
              Field={SelectDate} 
              canRemoveSelection={false}
              includeTime={true}
              label={t("date")} 
              modify={modify} 
              value={parseWithTimeZone(event.date)} 
              setValue={date=>{execute({id: event.id, date}); setEvent(e=>({...e, date}))}}>
            {(d)=><Info.Field value={locale.format("%d %B %Y à %H:%M")(d)}/>}
          </ModifiableSelectField>

          <ModifiableSelectField 
              Field={SelectEventType} 
              label={t("event-type")} 
              className="!grid-cols-1"
              modify={modify} 
              value={event.event_type} 
              setValue={eventType=>{execute({id: event.id, eventType: eventType.slug}); setEvent(e=>({...e, num_matches: eventType.slug==="match"? 1: e.num_matches, event_type:eventType}))}}>
            {(d)=><Info.Field value={d.name}/>}
          </ModifiableSelectField>

          {event.event_type && event.event_type.slug==="tournois" &&
            <Info.Container modify={false} className={"p-3"}>
            <SingleLineField
                label={t("num-matches.label")}
                description={t("num-matches.help")}
                modify={true}
                inputProps={{type: "number", max:"20", min:"1", step:"1", validateUpdate: stringIsInteger}}
                showSavingState={false}
                value={event.num_matches}
              setValue={numMatches=>{execute({id: event.id, numMatches}); setEvent(e=>({...e, num_matches:numMatches}))}}/>
            </Info.Container>
            }

          <InfoSectionHeading mediumTitle={t("play-time")} className={"mt-8"}/>
          <ModifiableSelectField 
              Field={SelectDateInterval} 
              subLabel={modify&&t("evaluation-period-restriction")}
              events={[{date: parseWithTimeZone(event.date), color: event.event_type.color, tooltip: event.event_type.name}]}
              label={t("evaluation-period")} 
              modify={modify} 
              canRemoveSelection={false}
              value={{fromDate: dateParse(event.from_date_calculation), toDate: dateParse(event.to_date_calculation)}} 
              setValue={evaluationPeriod=>{execute({id: event.id, evaluationPeriod}); 
                                      setEvent(e=>({...e, from_date_calculation: dateFormat(evaluationPeriod.fromDate), to_date_calculation: dateFormat(evaluationPeriod.toDate)}))}}>
            {(value)=><Info.Field value={value? `${baseDayFormat(value.fromDate)} au ${value.toDate? baseDayFormat(value.toDate): "-"}`: (t("evaluation-period"))}/>}
          </ModifiableSelectField>

          <ModifiableSelectField 
              Field={SelectExcludedDates} 
              subLabel={modify&&t("excluded-dates-description")}
              label={t("excluded-dates")} 
              modify={modify} 
              evaluationPeriod={{fromDate: dateParse(event.from_date_calculation), toDate: dateParse(event.to_date_calculation)}}
              value={event.excluded_dates} 
              block
              setValue={excludedDates=>{execute({id: event.id, excludedDates}); 
                                      setEvent(e=>({...e, excluded_dates: excludedDates }))}}>
            {(d)=><SelectExcludedDates value={d} 
                                  disabled={true}
                                  evaluationPeriod={{fromDate: dateParse(event.from_date_calculation), toDate: dateParse(event.to_date_calculation)}}/>}
          </ModifiableSelectField>

          <InfoSectionHeading mediumTitle={t("note-on-event")} className={"mt-8"}/>
          <Info.Container className={classnames("my-6", modify && "space-y-2")} modify={modify}>
            <SingleLineField 
                        label={modify&& t("visiting-team")} 
                        modify={modify} 
                        value={event.visiting_team} 
                        setValue={visitingTeam=>{execute({id: event.id, visitingTeam}); setEvent(e=>({...e, visiting_team:visitingTeam}))}}>
                {(d)=><Info.Field value={d} noValueLabel={t("no-visiting-team")} label={t("visiting-team")}/>}
            </SingleLineField>
            <SingleLineField 
                        label={modify&& t("receiving-team")} 
                        modify={modify} 
                        value={event.receiving_team} 
                        setValue={receivingTeam=>{execute({id: event.id, receivingTeam}); setEvent(e=>({...e, receiving_team:receivingTeam}))}}>
              {d=><Info.Field value={d} noValueLabel={t("no-receiving-team")} label={t("receiving-team")}/>}
            </SingleLineField>
          <SingleLineField 
                      label={modify&& t("comments")} 
                      modify={modify} 
                      value={event.notes} 
                      multiLine
                      setValue={notes=>{execute({id: event.id, notes}); setEvent(e=>({...e, notes}))}}>
                {(d)=><Info.Field value={d} noValueLabel={t("no-comments")} label={t("comments")}/>}
            </SingleLineField>

          </Info.Container>
          {modify && onDelete && <DeleteButton value={event} onDelete={onDelete}/>}
          {!modify && <PlayTimeView eventId={event.id} noHeader={true} className="my-3"/>}
          {defaultModify==false && <Button block size="lg" color="black" onClick={()=>setModify(!modify)}>{modify? t("ok") :t("modify")}</Button>}
         </div>
}