import React, {createContext } from "react";

// Hooks
import { useParams } from 'react-router-dom';
import { useMemo, useEffect } from 'react';
import { useTeam } from 'hooks/teams/useTeam';

const TeamContext = createContext({});

const TeamContextProvider = ({ children }) => {
  const {slug} = useParams();
  const immediate = useMemo(()=>false, []);
  const [team, {loading, error, execute, setResult}] = useTeam({immediate});

  useEffect(()=>{
    execute()
  }, [slug])

  return (
    <TeamContext.Provider value={{ team, loading, error, setTeam: setResult, refresh: execute }}>
      {children}
    </TeamContext.Provider>
  );
};

export { TeamContext, TeamContextProvider };