import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { Container } from 'components/core/container';
import Markdown from 'markdown-to-jsx';
import { options } from 'mdx';

const content =
`
# Journal des modifications

## 7 juin 2024

- Ajout d'un filtre d'école dans les statistiques de Suivi Tutorat.

## 5 juin 2024

- Ajout de la vue d'ensemble pour les coordonnateurs.
![Vue d'ensemble](/assets/changelog/coordo-stats-0.png)
![Vue d'ensemble](/assets/changelog/coordo-stats-1.png)

## 4 juin 2024

- Dans les préférences de tutorat d'un élève-athlète, un champ "L'élève a été rencontré pour parler du programme de tutorat et valider ses besoins" a été ajouté. La valeur est disponible à l'exportation des élèves-athlètes.

## 13 mai 2024

- Ajout des avis professionnels dans l'exportation des élèves-athlètes.

- Ajout d'un filtre pour exporter les élèves-athlètes rencontrés ou non rencontrés pour une période donnée.

  ![Filtre exportation](/assets/changelog/search-filters-export-ea.png)

## 30 avril 2024

- La vue hebdomadaire de la page d'accueil est du samedi au vendredi. 

## 26 avril 2024

- Modification du visuel des évènements dans la vue hebdomadaire.

  ![Visuel des évènements](/assets/changelog/event-update.png)

- À la modification d'un évènement (match, tournoi, pratique), un message indique que la modification a été effectuée. Également, la liste des évènements est rafraîchie pour afficher les modifications.

- À l'importation des élèves-athlètes, un message d'erreur est maintenant affiché si le nom (prénom ou nom) du jeune est différent de celui dans le système qui porte le même numéro de fiche.

 ![Erreur importation](/assets/changelog/csv-annot2.png)

## 28 mars 2024

- Ajout des commentaires dans la carte du BDT.
  ![Commentaires](/assets/changelog/card-with-comment.png)

- Ajout d'un icône au BDT s'il y a un avertissement de manque de matériel.
  ![Manque de matériel](/assets/changelog/missing-material.png)

## 22 mars 2024

- Une alerte est annoncée lorsque plus de deux matières scolaires sont sélectionnées les priorités du tutorat d'un élève-athlète.
 ![Priority](/assets/changelog/priority-alert.png)

- Ajout de la date de fin de service dans le tableau des rencontres.
  ![Date de fin de service](/assets/changelog/fin-service-rencontre.png)

- Dans les disponiblités des préférences des jeunes et des tuteurs, vous pouvez sélectionner plusieurs jours de la semaine pour les disponibilités.
  ![Multidispo](/assets/changelog/multi-dispo.png)

## 22 février 2024

- Dans la liste des aides aux devoirs, un icône vert d'ordinateur indique si l'aide aux devoirs a été inscrite en utilisant le portail.
  ![Aide aux devoirs](/assets/changelog/cell-computer.png)

- Dans la page des détails d'une aide aux devoirs, l'utilisateur du portail est inscrit (si applicable).


## 15 janvier 2024

- Réglage de l'erreur 500 à la suppression d'un évènement.

- Lorsqu'on ajoute des disponibilités pour le tutorat dans les dossiers des élèves-athlètes, les valeurs choisies sont conservées pour les prochains ajouts. Même chose pour les tuteurs.

## 18 décembre 2023

- ### Corrections

  1. La liste des élèves-athlètes à surveiller est à nouveau fonctionnelle.
  1. Par défaut, la création de rencontres crée une note ouverte.

## 23 novembre 2023

- ### Améliorations aux statistiques des rencontres

  1. Une colonne indique si la priorité a été assignée. Elle sera rouge si aucune note de rencontre n'a été faite (en excluant le MIFI). Elle s'adapte au document sélectionné.
  1. MIFI par document a été retiré de la liste des statistiques.
  1. La ligne MIFI mauve est préservée en fonction du MIFI annuel.
  1. Ajout d'une pagination pour les documents.

- ### Ajout de statistiques des rencontres

  Dans les rencontres de suivi Terrain, des statistiques ont été ajoutées. Le nombre d'élèves-athlètes est le même que le nombre de rangées dans le tableau. La deuxième case est le nombre d'élèves-athlètes MIFI pour tous les documents confondus.
  Les autres cases sont le nombre de notes de chaque type. Si vous changez la sélection du document, alors les statistiques pour les notes changent car elles sont liées au document. Noter que la statistiques est calculée sur l'année scolaire sélectionnée.

  ![Statistiques des rencontres](/assets/changelog/stats-meeting.png)

  Au bas du tableau, les statistiques par semaine pour les rencontres EA/COORDO a été ajoutée. Elle disparaît si vous sélectionnez un code de note différent de celui-ci.

- ### Corrections mineures

  1. Les notes de rencontres ne peuvent plus être ajoutées si le document est absent du dossier de l'élève-athlète. Une erreur est affichée si le document est absent.
  

## 21 novembre 2023

-  ### Corrections mineures

  1. Vous pouvez ajouter plusieurs rencontres en même temps dans le suivi Terrain. 
  1. Vous ne pouvez plus ajouter de rencontres sans spécifier la période et le document.
  1. Dans les rencontres, une colonne MIFI a été ajoutée. Elle montre les notes de type "ÉA - MIFI (1ère gén)". 
  1. Dans les paramètres de l'application, vous pouvez modifier les abréviations des types de notes et des documents pour le suivi Terrain. 
  1. Ajout du total des documents dans suivi Terrain.

  ![Total documents](/assets/changelog/stats-documents.png)


## 17 novembre 2023

- ### Lier des documents aux rencontres

  Dans la vue des rencontres, vous pouvez lier des documents à la rencontre. Vous sélectionner le type de document et la période, la note de rencontre sera automatiquement associée au document. Si le document n'existe pas pour le jeune, il sera créé. 
  
  ![Lier un document](/assets/changelog/add-document-to-meeting.png)

- ### Corrections mineures

  1. Par défaut, le pays de naissance est le Canada et le pays de naissance des parents est le Canada.
  1. 🦺 Pour les nouveaux documents, le système contrait à maximum de 1 document de chaque type/période par élève-athlète. Par exemple, si un document de type 'Bulletin 1' existe pour la période '2023-2024', alors il n'est pas possible d'en ajouter un autre. Les documents existants ne sont pas affectés par cette modification.
  1. Les texte "NaN 0NaN" qui était associé aux documents non remis est remplacé par "Non remis".

  ![Document non remis](/assets/changelog/document-flag.png)

  1. L'erreur 500 à la suppression d'un document est affichée dans un texte plus clair.
  1. Ajout d'une annotation "Transmis" dans la vue d'ensemble.
  1. L'ajout d'un échec scolaire exige un document et un résultat.

## 16 novembre 2023

- ### Corrections mineures

  1. Une erreur 500 survenait lorsque des aides aux devoirs sont ajoutées via le portail. Ce problème a été corrigé. Il était en lien avec les commentaires d'une note. 
  1. Les commentaires d'une note sont maintenant affichés dans l'ordre chronologique de la date en bleu. Une correction a été apportée pour les commentaires qui n'avaient pas de date.
  1. Les couleurs du total des statistiques de tutorat sont modifiées.
  1. Les totaux des heures de total des statistiques de tutorat sont affichées pour les rencontres qui ont eu lieu.
  1. La légende des aides aux devoirs dans le suivi Terrain a été réintroduite en haut de la page.
  1. Une correction a été apportée pour le comptage des notes dans les aides aux devoirs dans le suivi Terrain.

## 10 novembre 2023

- ### Corrections mineures

  1. Dans le suivi Tutorat, les statistiques des élèves-athlètes actifs sont par défaut aux élèves-athlètes du favori (à la première utilisation). 
  1. La légende de la vue des aides aux devoirs du suivi Terrain a été déplacée pour être plus compacte.
  1. La semaine en cours dans les aides aux devoirs du suivi Terrain est mise en évidence.
  1. Les documents et les rencontres du suivi Terrain ont des initiales pour indiquer le type.
  1. Dans le BDT, les rencontres annulées pour cause de problème technique ou une raison externe sont bleues.

- ### Nouveaux totaux pour les statistiques de tutorat 

  Dans la colonne problème technique, les rencontres annulées pour cause de problème technique ou une raison externe sont comptabilisées. Le titre de la colonne a été changé pour réfléter ce changement. 
  
  Le pourcentage affiché pour les colonnes "réussie", "manquée" et 'annulée à l'avance" est calculé par rapport au nombre de rencontres dans ces trois colonnes (on exclut donc les problèmes techniques et les raisons externes). Les rencontres annulées pour cause de problème technique ou une raison externe sont normalisées par rapport au nombre de rencontres dans les quatre colonnes.

## 9 novembre 2023

- ### Ajout des minutes aux commentaires des notes

  À la création d'une note ou d'un commentaire, un champ a été ajouté pour indiquer le nombre de minutes consacrées. Ce champ est obligatoire mais vous pouvez inscrire 0 minute.

## 8 novembre 2023

- ### Corrections mineures
  
  Correction d'un bug qui causait la fermeture des jumelages existants lors de la création d'un nouveau jumelage.

- ### Modification de la page de suivi des aides aux devoirs dans le suivi Terrain
  
  Les cellules ont été modifiés pour de nouvelles couleurs et des indicateurs des avertissements. 

- ### Ajout d'un bouton pour supprimer les jumelages

  Dans la vue des paramètres d'un jumelage, un bouton a été ajouté pour supprimer le jumelage. Cette suppression est définitive et ne peut être annulée.

  ![Supprimer un jumelage](/assets/changelog/delete-pairing.png)

## 6 novembre 2023

- ### Correction des statistiques de tutorat

  Les statistiques des élèves-athlètes actifs sont dorénavant filtrés pour les jumelages actifs.

## 2 novembre 2023

- ### Une nouvelle page de suivi des aides aux devoirs dans le suivi Terrain

  Le visuel du suivi terrain a légèrement changé pour laisser place à un nouvel onglet 'Aides aux devoirs'. Cet onglet permet de voir sous la forme d'un tableau toutes les aides aux devoirs effectuées.

  Le code de couleur des aides aux devoirs est: 

    1. Rouge: Aide aux devoirs non effectuée
    1. Jaune: Aide aux devoirs effectuée avec au moins une note (avertissement)
    1. Vert: Aide aux devoirs effectuée sans note

  En déplaçant la souris sur une cellule, vous pouvez voir le détail de l'aide aux devoirs.

  Le chiffre à droite de chaque cellule indique le nombre d'aides aux devoirs par rapport à l'objectif. Un crochet indique que l'objectif est atteint.

  ![Aides aux devoirs](/assets/changelog/suivi-aad2.png)

- ### Ajout d'un champ de transmission des temps de jeu

  Dans la vue d'un temps de jeu (dans Suivi Terrain ou sur les temps de jeu d'une page d'une équipe), un champ a été ajouté en haut à droite pour indiquer la date de transmission du temps de jeu. N'oubliez pas que les temps de jeu peuvent être modifiés après la transmission.
  
  ![Champ de transmission](/assets/changelog/transmis-play-time.png)

  

- ### Améliorations à la création d'un évènement

  Dans la vue pour ajouter un évènement, les champs de l'équipe receveuse, de l'équipe visiteuse et de notes sont disponibles. Une fois la création de l'évènement terminée, vous avez dorénavant un bouton pour ajouter un autre évènement.

- ### Ajout d'un filtre sur les documents de l'exportation

  Si une période est sélectionnée, seuls les documents de cette période seront exportés. Un bandeau d'information est affiché pour indiquer que le filtre est actif.

## 31 octobre 2023

- ### Modification à l'exportation des documents
  
  Devant chaque date de document dans l'exportation des élèves-athlètes, un indice (S) ou (NS) indique si le document a été signé ou non.

## 27 octobre 2023

- ### Les statistiques de suivi Tutorat sont organisées par jumelages
  
  Auparavant, les statistiques étaient organisées par élèves-athlètes. Le visuel a été modifié pour être plus compact et rapide à lire.

- ### Ajout d'un filtre sur la date du BDT

  Dans le BDT, un filtre d'année scolaire a été ajouté en haut à droite. Il vous permet de filtrer les jumelages pour une année scolaire particulière. Par défaut, l'année scolaire courante est sélectionnée.
  Noter que la liste des jumelages affichés est filtrée pour tous les jeunes de votre favoris. Par conséquent, si vous ne sélectionnez pas une année scolaire, tous les jumelages existants des élèves-athlètes se trouvant dans votre favoris seront affichés.

## 23 octobre 2023

- ### Ajout de champs à  l'exportation des élèves-athlètes
  Les champs suivants ont été ajoutés à l'exportation des élèves-athlètes:
  1. "Prioritaire pour le tutorat"
  1. "Demande de service pour le tutorat"
  1. "Identifiant personnel": Ce champ est unique à chaque élève-athlète et est créé automatiquement par l'application.

- ### Corrections de bugs

  1. Le calendrier pour modifier un échec scolaire est visible.

  ![Calendrier](/assets/changelog/modify_failed-class.png)

  1. Les rencontres du suivi Terrain se rafrachissent automatiquement au changement de l'équipe.
  1. Les équipes affichées des élèves-athlètes sont celles de l'année scolaire en cours dans les rencontres du suivi Terrain.

- ### Améliorations au jumelage automatique
  1. Si vous cochez la case "Tuteurs à l'aise avec les élèves-athlètes en accueil", alors une vérification est faite pour les élèves-athlètes en francisation et ceux dont l'année scolaire est 'Accueil'.
  1. Un champ vous permet de contraindre les jumelages aux 'Élèves-athlètes avec contrat remis uniquement'.
  1. Un champ vous permet de contrôler l'importance des élèves-athlètes prioritaires pour le tutorat.
  1. Dans les tableaux de résultats, les champs 'Prioritaire' et 'École' sont affichés. Les équipes sportives sont filtrées à l'année courante.

- ### Corrections aux jumelages

  Dans l'ajout manuel de jumelages, les tuteurs sont filtrés pour ne montrer que ceux qui sont disponibles.
  La comparaison des matières scolaires est corrigée pour n'afficher que les matières communes entre le tuteur et l'élève-athlète.

## 12 octobre 2023

- Dans l'onglet Document de Suivi terrain, les documents sont groupés par la période sélectionnée. Les équipes sportives sont filtrées par la période sélectionnée.

`

export function ChangeLogPage(){
  return <DashboardLayout>
          <Container className="w-full h-full max-w-6xl p-6 mx-auto">
            <Markdown options={options}>{content}</Markdown>
          </Container>
         </DashboardLayout>
}

const route =  { path: "/changelog",
                 requireAuth: true,
                 Element: ChangeLogPage };
export default route;
