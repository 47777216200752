import React, { useMemo } from 'react';

// Components
import { SearchContextProvider } from 'contexts/search';
import { ExportContent } from 'components/exports/content';
// Hooks
// import { useTranslation } from 'react-i18next';

export default function LatestTutorForms(){
  // const { t } = useTranslation();
  const dataType  = useMemo(()=>("tutorForms"), []);
  return<SearchContextProvider doNotStore={true} autoPeriod={true}>
            <ExportContent dataType={dataType}/>
          </SearchContextProvider>
}
