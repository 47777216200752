import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { RecrutingLayout } from 'components/recruting/layout';
import { FiRadio } from 'react-icons/fi';

export function RecruitingBase(){
  return <DashboardLayout>
          <RecrutingLayout/>
         </DashboardLayout>
}

const route =  { path: "/recruiting",
                 name: "Recrutement" , 
                 Icon: FiRadio,
                 group: 'portail',
                 isNested: true,
                 showInNavBar: true, 
                 requireAuth: true, 
                 Element: RecruitingBase };
export default route;

import { default as forms } from 'pages/recruting/forms'
import { default as stats } from 'pages/recruting/stats'
import { default as inscriptions } from 'pages/recruting/inscriptions'
import { default as myInscriptions } from 'pages/recruting/user-inscriptions'
export const RecruitingPages = {root: RecruitingBase, forms, stats, inscriptions, myInscriptions}

