import React from 'react';

// Components
import { NotesGrid } from 'components/notes/grouped_notes';

// Hooks
import { useContext } from 'react';

// Contexts
import { SearchFollowUpContext } from 'contexts/search_follow_up';

// Meeting with coachs
export default function GroupedNotes(){
  const {filters, dispatchFilters} = useContext(SearchFollowUpContext);
  return <div className="py-6">
          <NotesGrid filters={filters} numWeeks={6} dispatchFilters={dispatchFilters}/>
         </div>
}
