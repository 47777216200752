import React from 'react';

// Components
import { Button } from 'components/core/button';

// Hooks
import { useCallback } from 'react';

const days = ["lun", "mar", "mer", "jeu", "ven"];

export function SelectWeekdays({value, setValue, others=[]}={}){
    const handleChange = useCallback((d)=>{
      if (!value) return setValue([d]);
      if (value.includes(d)){
        return setValue(value.filter(e=>e!==d));
      }
      return setValue([...value, d]);
    }, [value]);

    return <div className="flex items-center justify-evenly space-x-2">
            {[...days, ...others].map((d)=><Button 
                    key={d}
                    onClick={()=>handleChange(d)}
                    color={value && value.includes(d)? "active": "gray"}
                    block
                    className="capitalize"
                    size="sphere">{d}
              </Button>)}
          </div>
}

