import React from 'react';

/*
  Select a recent study session
*/
// Components

// Utils
import classnames from 'classnames';
import { locale, dateParse } from 'utils/locale';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useMemo } from 'react';
import { useWeek } from 'hooks/dates/useWeek';
import { useTranslation } from 'react-i18next';
// API
import { searchStudySessions } from 'api/study_sessions';
import { EmptyState } from 'components/core/empty';

export function SelectRecentStudySession({value, setValue, date, student, sessionOccurred, useInCount, studyCode}){
  const { t } = useTranslation("common");
  const windowDates = useWeek(date || new Date(), {weeks:-1, floorInput: false, floorOutput:true});
  const filters = useMemo(()=>({sessionOccurred, useInCount, studyCode, student, ...windowDates}), [sessionOccurred, useInCount, studyCode, student]);
  const [studySessions] = useSearch(searchStudySessions, filters);
  if (!studySessions) return <div/>

  return <div className="py-2">
            <p className="text-yellow-800 font-medium pb-2">{t("select-a-recent-missed-study-session")}</p>
            <div className="grid grid-cols-1 md:grid-cols-2  gap-3 md:gap-3">
            {!date && <EmptyState className="w-full mx-auto col-span-full" color="warning" description={t("empty-state.enter-date-for-study-session-retake")}/>}
            {date && studySessions.length===0 && <EmptyState className="w-full mx-auto col-span-full" color="warning" 
                      description={t("empty-state.no-study-session-for-retake", {fromDate: locale.format("%A %d %B")(windowDates.fromDate),
                      toDate: locale.format("%A %d %B")(windowDates.toDate) })}/>}
            {date && studySessions.map(d=>
              <div key={d.id} 
                className={classnames("rounded-md col-span-1 p-2 flex items-center cursor-pointer border-2",
                  value && value.id===d.id? "bg-gray-200": "hover:bg-gray-100 bg-gray-50 border-transparent")}
                 onClick={()=>setValue(d)}>
                <div className="w-6 text-center" >
                  <input type="radio" id={d.id} name={"recent_study_id"} checked={(value && value.id===d.id) || false} readOnly/>
                </div>
                <div className="flex-1 ml-3">
                  <div className="text-medium flex items-center">{locale.format("Le %A %d %B")(dateParse(d.period))}</div>
                  <div className="text-gray-500 text-sm">
                    {d.cancelled_for_external_reason && t("cancelled-reason.external")}
                    {d.technical_problem && t("cancelled-reason.technical-problem")}
                    {d.choked && t("cancelled-reason.choked")}
                  </div>
                </div>
                </div>)}
             </div>
            </div>
}