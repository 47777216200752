import React from "react";

// Utils
import classNames from "classnames";
import { locale } from 'utils/locale';

// Icons
import { AiOutlineCalendar as CalendarIcon } from 'react-icons/ai';

const colors = {
    "orange": "text-orange-600 bg-orange-100 ring-orange-200",
    "gray": "text-gray-600 bg-gray-100 ring-gray-200",
    "green": "text-green-600 bg-green-100 ring-green-200",
    "blue": "text-blue-600 bg-blue-100 ring-blue-200",
    "red": "text-red-600 bg-red-100 ring-red-200",
    "yellow": "text-yellow-600 bg-yellow-100 ring-yellow-200",
    "purple": "text-purple-600 bg-purple-100 ring-purple-200",
    "violet": "text-violet-600 bg-violet-100 ring-violet-200",
}

const sizes = {
    "fit": "h-fit w-fit py-0.5 px-1.5 text-sm",
    "md": "px-2 py-1 text-sm"
}

export function PillEmpty({children, className, color = "gray", size="fit", border}){
    return <div className={classNames("inline-flex items-center rounded", className, colors[color], sizes[size], border && "ring-1")}>
        {children}
    </div>
}

export function PillInterval({ fromDate, toDate, excludeLastDay, short, ...props }) {
    const lastDate = excludeLastDay ? new Date(toDate - 1) : toDate;
    const sameMonth = fromDate.getMonth() === lastDate.getMonth() && fromDate.getFullYear() === lastDate.getFullYear();
    const monthFormat = short ? "%b" : "%B";
    const formatedInterval = sameMonth
      ? `${locale.format("%d")(fromDate)} - ${locale.format(`%d ${monthFormat} %Y`)(lastDate)}`
      : `${locale.format(`%d ${monthFormat} %Y`)(fromDate)} - ${locale.format(`%d ${monthFormat} %Y`)(lastDate)}`;
    return (
      <Pill.Empty {...props}>
        <CalendarIcon className='mr-2 text-lg' />
        {formatedInterval}
      </Pill.Empty>
    );
  }

export const Pill = {
    Empty: PillEmpty,
    Interval: PillInterval
};