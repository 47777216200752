import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { Container } from 'components/core/container';
import { NewCoach } from 'components/coachs/create';


export function CreateCoachPage(){
  return <DashboardLayout>
          <Container className="h-full">
            <NewCoach/>
          </Container>
         </DashboardLayout>
}

const route =  { path: "/new-coach", 
                 requireAuth: true, 
                 Element: CreateCoachPage };
export default route;
