/*
  from 
  https://stackoverflow.com/questions/30008114/how-do-i-promisify-native-xhr
  
  Usage


// Example:

uploadFile( 'http://example.com', file, fields)
.then(function (datums) {
  console.log(datums);
})
.catch(function (err) {
  console.error('Augh, there was an error!', err.statusText);
});


*/

export function uploadFile(url, file, fields, progressHandler) {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    if (progressHandler){
      xhr.upload.addEventListener("progress", progressHandler, false);
    }
    
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      });
    };
    var formdata = new FormData();
    Object.keys(fields).forEach(d=>formdata.append(d, fields[d]))
    formdata.append("file", file);
    xhr.send(formdata);
  });
}