import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { SearchSchoolSubject } from 'components/tools/search_school_subject';
import { TextInput } from 'components/core/inputs';
import { Error } from 'components/core/typo'
import { Button } from 'components/core/button';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useAPI } from 'hooks/useAPI';

// API
import { createSchoolClass } from 'api/classes';

export function CreateSchoolClass({onCompleted}){
  const [draftSchoolClass, setDraftSchoolClass] = useState({teams: []});
  const [schoolClass, {loading, execute, error}] = useAPI(createSchoolClass, draftSchoolClass, {immediate:false});
  const {t} = useTranslation('common');

  useEffect(()=>{
    if (schoolClass) onCompleted(schoolClass);
  }, [schoolClass])

  return<div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("add-school-class")}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
                <TextInput
                  label={t("name")}
                  onChange={(name)=>setDraftSchoolClass(d=>({...d, name}))}
                />
                <SearchSchoolSubject 
                  label={t("equivalent-school-subject")}
                  value={draftSchoolClass.subject}
                  size="lg"
                  setValue={(subject)=>setDraftSchoolClass(d=>({...d, subject}))}
                />

                <Button onClick={execute} disabled={!draftSchoolClass.name} loading={loading} block size="lg" color="active">{t("add-school-class")}</Button>
                {error&& <Error.Text {...error}/>}
              </CardContainer>
            </div>


}