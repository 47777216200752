import React from 'react'

// Components
import { SelectMenu } from 'components/tools/select-menu';

// Hooks
import { useTranslation } from 'react-i18next';

export function SelectQuestion({value, setValue, options, label, id, ...props}){
  const {t} = useTranslation("tutor");

  return <SelectMenu placeholder={t(`${id}.placeholder`)} 
                    indexKey="name" 
                    labelClassName="text-gray-800 text-base "
                    values={options || t(`${id}.options`)} 
                    value={value || ""} 
                    getKey={d=>d.slug}
                    setValue={setValue} 
                    label={label || t(`${id}.label`)}
                    {...props}/>
}