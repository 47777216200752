import React, {createContext, useState } from "react";

// Hooks
import { useEffect } from 'react';
import { SlideOver } from "components/core/slide_over";
import { useQuery } from "hooks/useQuery";
import { TutorContextProvider } from "./tutor";
import { TutorSettingsInline } from "pages/tutor/settings/inline_nav";

const TutorSliderOverContext = createContext({});

const TutorSliderOverContextProvider = ({ children }) => {

    const {query, setQuery} = useQuery();
    const [open, setOpen] = useState(query.has("tutor"));
    useEffect(()=>{
        if (query.has("tutor")){
            setOpen(true);
        }
        else {
            setOpen(false);
        }
    }, [query]);

    useEffect(()=>{
        if (!open){
            setQuery();
        }
    }, [open]);

    const shouldOpenTutor = (tutor) => {
        setQuery({tutor});
        setOpen(true);
    }
    return (
    <TutorSliderOverContext.Provider value={{ shouldOpenTutor }}>
      {children}
      <SlideOver open={open} setOpen={setOpen} size="xl5">
        <TutorContextProvider tutorNi={query.get("tutor")}>
            <TutorSettingsInline/>
        </TutorContextProvider>

    </SlideOver>
    </TutorSliderOverContext.Provider>
  );
};

export { TutorSliderOverContext, TutorSliderOverContextProvider };
