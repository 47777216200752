import { timeParse, timeFormatDefaultLocale } from 'd3-time-format';

// French
export const locale = timeFormatDefaultLocale({
  "dateTime": "%a %e %b %Y %X",
  "date": "%Y-%m-%d",
  "time": "%H:%M:%S",
  "periods": ["", ""],
  "days": ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
  "shortDays": ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
  "months": ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
  "shortMonths": ["jan.", "fév.", "mars", "avr.", "mai", "juin", "juil", "août", "sept.", "oct.", "nov.", "déc."]
})

const weekdaysOrder = {
  "dimanche": 0,
  "lundi": 1,
  "mardi": 2,
  "mercredi": 3,
  "jeudi": 4,
  "vendredi": 5,
  "samedi": 6
}

export function orderWeekdays(weekdays) {
  return weekdays.sort((a, b) => weekdaysOrder[a]>weekdaysOrder[b]? 1: -1);
}
export const dateParse = (d)=>timeParse("%Y-%m-%d")(d);

export const parseWithTimeZone = (d)=>(new Date(new Date(d) - new Date(d).getTimezoneOffset() * 60000));
export const parseDateTimeIgnoreTime = (d)=>(dateParse(d.slice(0, 10)));
