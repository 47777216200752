import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Button } from 'components/core/button';
import { FiPlus, FiMinus} from 'react-icons/fi';
import { useSearch } from 'hooks/useSearch';
import { useCallback, useMemo } from 'react';
import classnames from 'classnames';

// API
import { searchSchoolSubjects, searchSchools} from 'api/utils';

export function SelectIncrement({formatValue, asBlock, setValue, value, defaultOption, options, disabled}){
  return <div key={formatValue(value)} className={classnames(" hover:bg-gray-50 rounded-md p-2", asBlock? "space-y-2 px-6": "justify-between flex items-center")}>
          <div className="">
            <p className="font-medium">{formatValue(value)}</p>
            <p className="text-gray-500 mr-3 mr-auto">{(options[value.description]||defaultOption).name}</p>
          </div>
          <div className="flex space-x-3  ">
            <div className="flex items-center space-x-0.5">
              {[...Array(Object.keys(options).length-1).keys()].map(
                (i)=><span key={i} className={classnames((options[value.description]||defaultOption).qte>i?"bg-orange-500": "bg-gray-200", "rounded h-full w-4")}/>
              )}
            </div>
            <Button size="smToolbarIcon" color="gray" disabled={disabled||!(options[value.description]||defaultOption).previous} 
            onClick={()=>setValue({...value, description: (options[value.description]||defaultOption).previous })}><FiMinus/></Button>
            <Button  size="smToolbarIcon" color="gray" disabled={disabled||!(options[value.description]||defaultOption).next} 
            onClick={()=>setValue({...value, description:(options[value.description]||defaultOption).next})}><FiPlus/></Button>
          </div>
        </div>
}
export function SelectPreferences({values, setValue, formatValue, options, defaultOption}){
  if (!values) return <Skeleton className="w-full h-64"/>

  return <div className=" grid lg:grid-cols-2 gap-x-4">
          {(values).map(value=>
              <SelectIncrement key={formatValue(value)} 
                  setValue={setValue}
                  value={value}
                  formatValue={formatValue}
                  options={options}
                  defaultOption={defaultOption}
                  />
            )}
         </div>
}

export function SelectSubjectPreferencesAuto({setValues, ...props}){
  // Fetch the
  const params = useMemo(()=>({}), [])
  const onResult = useCallback(d=>setValues(d.results), [])
  useSearch(searchSchoolSubjects, params, {limit: 100, onResult})
  return <SelectPreferences {...props}/>
}

export function SelectSchoolPreferencesAuto({setValues, ...props}){
  // Fetch the
  const params = useMemo(()=>({}), [])
  const onResult = useCallback(d=>setValues(d.results), [])
  useSearch(searchSchools, params, {limit: 100, onResult})
  return <SelectPreferences {...props}/>
}