import React from 'react';

// Components
import { TextInput } from 'components/core/inputs'
import { Info } from 'components/core/info';
import { Button } from 'components/core/button';
import { SearchStudents } from 'components/tools/student_search';
import { SelectSchoolYear } from 'components/tools/school_year_list';
import { Error, InfoSectionHeading } from 'components/core/typo'

// API
import { addSchoolYearFailed } from 'api/students';

// Hooks
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';
import { SelectPeriods } from './select_period';

export function NewSchoolYearFailed({defaultStudent, defaultSchoolYear, defaultPeriod, disabled, setValue}){

  const { t } = useTranslation('common');
  const [student, setStudent] = useState(defaultStudent);
  const [schoolYear, setSchoolYear] = useState(defaultSchoolYear);
  const [period, setPeriod] = useState();
  const [description, setDescription] = useState();

  const [result, {loading, error, execute}] = useAPI(addSchoolYearFailed, {student, schoolYear, period, description}, {immediate: false});

  useEffect(()=>{
    if (result && setValue){
      setValue(result);
    }
  }, [result]);

  return <div className='p-6'>

    <InfoSectionHeading title={t('add-school-year-failed.title')}/>
            <Info.Container  modify={true} className={"mt-6"}>

          {!defaultStudent && <SearchStudents size="smWider" value={student} setValue={setStudent} disabled={defaultStudent} color="default"/>}
         {!defaultSchoolYear &&  <SelectSchoolYear showPeriodFilter={true} value={schoolYear} setValue={setSchoolYear} disabled={defaultSchoolYear} color="default"/>}
         {!defaultPeriod && <SelectPeriods value={period} setValue={setPeriod} disabled={defaultPeriod} color="default"/>}
          <TextInput placeholder={t('description')} description={t("add-school-year-failed.description")} onChange={setDescription} size="md"/>
          <Button disabled={disabled || !student || !schoolYear || !period} color="active" size="lg" block loading={loading} onClick={execute}>
            {t('add-school-year-failed.button', {name: student && student.name, schoolYear: schoolYear &&schoolYear.name})}
          </Button>
          {error? <Error.Text {...error}/>: null}
         </Info.Container>
         </div>
}