import React from 'react';

// Components
import { Tabs } from 'components/core/tabs';

// Hooks
import { useState } from 'react';

export function NavBar(){
  const tabs = [
    {
      name: "Élèves-athlètes",
      href: `/bulk-import/students`,
    },
    {
      name: "Enseignants",
      href: `/bulk-import/teachers`
    },
    {
      name: "Entraîneurs",
      href: `/bulk-import/coachs`,
    },
    {
      name: "Partenaires",
      href: `/bulk-import/partners`,
    }
  ]
  const [tab, setTab] = useState(tabs[0]);
  return <div className="border-b">
          <Tabs tabs={tabs} value={tab} setValue={setTab} navClassName=""/>
         </div>
}