import React from 'react';

// Hooks
import { useUser } from 'hooks/useUser';
import { useConfirmActivityPrompt } from 'hooks/useConfirmActivityPrompt';

// Components
import { Spinner } from 'components/core/spinner';
import { CenterViewLayout } from 'components/core/layouts/center';
import { Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'pages/errors/500';
import { Modal, ModalBasic } from 'components/core/modal';
import { useTranslation } from 'react-i18next';
import { BellAlertIcon } from '@heroicons/react/24/outline';

export function InactivityGuard({children}){
  const [user, ,{loading}] = useUser();
  const { open, setOpen, handleStillHere, remaining } = useConfirmActivityPrompt();
  const {t} = useTranslation("common");

  if (!user && loading){
      return <CenterViewLayout color="plain">
                  <Spinner className="mx-auto" size="md"/>
              </CenterViewLayout>
  }
  if (!user){
    return <Navigate to={`/login?ref=${encodeURIComponent(window.location.pathname+window.location.search)}`}/>
  }
  return <ErrorBoundary>
          {children}
          <Modal open={open} setOpen={setOpen} disableOutsideClick={true} removeCloseButton size="xs">
                <ModalBasic title={t('confirm-activity.title')}
                            description={t('confirm-activity.description', {remaining})}
                            submitLabel={t('confirm-activity.confirm')}
                            Icon={BellAlertIcon}
                            onClick={handleStillHere}/>
          </Modal>
        </ErrorBoundary>;
}
