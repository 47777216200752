import React from 'react';

// Components
import { Container } from 'components/core/container';
import { CoachGeneralSettings } from 'components/coachs/modify';

// Hooks

// Contexts

export default function GeneralSettings(){

  return <Container className="h-full w-full">
            <CoachGeneralSettings/>
         </Container>

}
