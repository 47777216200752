import React from "react";
import classnames from "classnames";

export function Skeleton({ className}){
  return <div className={classnames("bg-gray-100 rounded-lg animate-pulse", className)}/>
}

function SkeletonList({ numElements, className, itemClassName }){
  return (<div className={classnames(className)}>
            {[...Array(numElements).keys()].map((index) => <Skeleton className={classnames('mr-2', itemClassName)}
                                                             key={index}/>)}
          </div>)
}

Skeleton.List = SkeletonList;