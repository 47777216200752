/*
  This list is intended for managing the tutors, not for selection.
*/

import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useContext } from 'react';
import { useSearch } from 'hooks/useSearch';

// Utils
import { locale, dateParse } from 'utils/locale';

// Contexts
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error, InfoSectionHeading } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';

// API
import { searchTutors } from 'api/tutors';


export function ControlledTutorsList({tutors, setPage, paging, error}){
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { t } = useTranslation('common');
  const headers = useMemo(()=>[
    {
     title: t("name"),
     field: (d=>d? d.name : "name"),
     itemClassName: "!py-2 whitespace-nowrap"
    },
    {
     title: t("email"),
     field: (d=>d? d.email : "email"),
     itemClassName: "font-mono whitespace-nowrap"
    },
    {
     title: t("active-since"),
     field: (d=>d? (d.active_since && locale.format("%d %B %Y")(new Date(dateParse(d.active_since)))) : "active_since"),
     itemClassName: "text-gray-500 whitespace-nowrap"
    }
  ], [tutors])

  if (!tutors){
    return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2"/>
  }

  return <Info.Container modify={true} >
          <InfoSectionHeading mediumTitle={t("active-tutors.title")} longDescription={t("active-tutors.gonna-be-deactivated")}/>
            {tutors.length===0?
              <EmptyState
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-tutors-title")}/>
              :
              <Table headers={headers}
                    data={tutors}
                    indexingKey="ni"
                    order={{by:filters.orderBy, direction:filters.orderDirection}}
                    onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
              }
              {paging&& <Paginate setPage={setPage} {...paging}/>}
              {error? <Error.Text {...error}/>: null}
          </Info.Container>
}

function TutorsListWithContext(){
  const { filters } = useContext(SearchContext);
  const [tutors, {loading, error, paging, setPage}] = useSearch(searchTutors, filters);
  return <ControlledTutorsList tutors={tutors}
                              setPage={setPage}
                              loading={loading}
                              error={error}
                              paging={paging} />
}

export function TutorsList(){
  return <SearchContextProvider doNotStore defaultState={{orderBy:"name", orderDirection: "asc", serviceIsEnded: false}}>
            <TutorsListWithContext/>
        </SearchContextProvider>
}