import React from 'react';

// Components
import { InfoSectionHeading  } from 'components/core/typo';
import { Error } from 'components/core/typo';
import { Container } from 'components/core/container';
import { Table } from 'components/core/table';
import { Skeleton } from 'components/core/skeleton';
import { Button } from 'components/core/button';
import { FiUsers, FiWifi } from 'react-icons/fi';
import { AutoPairingLayout } from 'components/automatic-pairing/layout';

// Hooks
import { useAPI } from 'hooks/useAPI';
import { useParams } from 'react-router-dom';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Utils
import { cronsIntervalToString } from 'utils/crons';
import { exportCSVFile } from 'utils/export_csv';
import { locale, dateParse } from 'utils/locale';

// API
import { retrieveDraftPairingUnmatchedTutors } from 'api/auto-pairing';

function PreferenceCell({on_site_preference, online_preference, hybrid_preference}){
  const { t } = useTranslation("autoPairing");

  return <div className="whitespace-nowrap">
          {on_site_preference&&
           <p className="flex items-center space-x-2 text-sm text-gray-700"><FiUsers/> <span>{t("preferences.onsite")}</span></p>}
          {online_preference&& 
            <p className="flex items-center space-x-2 text-sm text-green-600"><FiWifi/> <span>{t("preferences.online")}</span></p>}
          {hybrid_preference&& 
            <p className="flex items-center space-x-2 text-sm text-pink-600"><FiUsers/><FiWifi/> <span>{t("preferences.hybrid")}</span></p>}
          </div>
}



export default function UnmatchedTutors(){
  const { t } = useTranslation("autoPairing");
  const params = useParams();
  const [draft, {error}] = useAPI(retrieveDraftPairingUnmatchedTutors, params, {validateParams: ((d)=>!!d.draftId)})
  const headers = useMemo(()=>[
      {
        title: t("tutor-name"),
        field: (d=>d?d: "name"),
        className: "px-2",
        itemClassName: "font-medium py-1",
        format: (d=>d.name)
      },
      {
        title: t("active-since"),
        field: "active_since",
        format: (d=>d ?locale.format("%d %b %Y")(new Date(dateParse(d))): ""),
        itemClassName: "text-gray-500",
      },
      {
        title: t("availabilities"),
        field: (d=>d?d.schedule_availabilities: "availabilities"),
        itemClassName: " py-1 max-w-md text-gray-500",
        className: "px-2",
        format: (d=>d.map(s=>`${cronsIntervalToString(s)}`).join(', '))
      },
      {
        title: t("prefered-schools"),
        field: (d=>d?d.schools && d.schools.map(e=>`${e.short_name}`).join(', '): "schools"),
        itemClassName: "text-gray-600 "
      },
      {
        title: t("prefered-school-subjects"),
        field: (d=>d?d.subjects && d.subjects.filter(e=>e.description).map(e=>`${e.name}`).join(', '): "prefered_subjects"),
        itemClassName: "text-gray-600"
      },
      {
        title: t("preferences.title"),
        field: (d=>d?d: "preferences"),
        itemClassName: " py-1 max-w-md text-gray-500",
        className: "px-2",
        format:(d=>((d.on_site_preference)? t("preferences.onsite"):
          (d.online_preference)? t("preferences.online"):
          (d.hybrid_preference)? t("preferences.hybrid"):
          t("preferences.undefined")
          )),
        FormatComponent: PreferenceCell
      },
      {
        title: t("student.matches"),
        field: "matches",
        itemClassName: "text-gray-600  "
      }
  ], [])

  const exportFile = useCallback(()=>{
    if (!draft || !draft.unmatched_tutors) return;
    exportCSVFile(headers, draft.unmatched_tutors, `ea_non_jumeles_${draft.id}.csv`)
  }, [draft])


  return <AutoPairingLayout><Container>
          <InfoSectionHeading mediumTitle={t("unmatched-tutors.title")} longDescription={t("unmatched-tutors.description")}>
            <Button color="active" size="md" onClick={exportFile}>
              {t("export-as-csv")}
            </Button>
          </InfoSectionHeading>
          {!draft?
            <Skeleton className="h-80"/>:
            <Table data={draft.unmatched_tutors} headers={headers} indexingKey={d=>`${d.ni}`}/>
          }
          {error? <Error.Text {...error}/>: null}
         </Container></AutoPairingLayout>

}
