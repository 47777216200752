import React from 'react';

// Components
import { CreateDocument } from 'components/documents/create';
import { Modal } from 'components/core/modal';
import { Button } from 'components/core/button';
import { Fragment } from 'react';

// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function CreateDocumentInModal({open, setOpen, onCompleted, creatorProps}){
  return <Modal open={open} setOpen={setOpen} size="md" disableOutsideClick={true}>
          <CreateDocument {...creatorProps} onCompleted={(d)=>{setOpen(false); onCompleted(d)}}/>
        </Modal>
}

export function CreateDocumentButton({onCompleted, creatorProps, className, color="gray"}){
  const [open, setOpen] = useState(false);
  const {t} = useTranslation('common')
  return <Fragment>
          <Button color={color} size="md" className={className} onClick={()=>setOpen(true)}>{t("add-document")}</Button>
          <CreateDocumentInModal  open={open} setOpen={setOpen} onCompleted={onCompleted} creatorProps={creatorProps}/>
        </Fragment>
}