import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { SelectColor } from 'components/tools/select_color';
import { TextInput } from 'components/core/inputs';
import { Error } from 'components/core/typo'
import { Button } from 'components/core/button';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useAPI } from 'hooks/useAPI';

// API
import { createDocumentGroup } from 'api/document_groups';

export function CreateDocumentGroup({onCompleted}){
  const [draftDocumentGroup, setDraftDocumentGroup] = useState({});
  const [noteCode, {loading, execute, error}] = useAPI(createDocumentGroup, draftDocumentGroup, {immediate:false});
  const {t} = useTranslation('common');

  useEffect(()=>{
    if (noteCode) onCompleted(noteCode);
  }, [noteCode])

  return <div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("add-document-group.title")}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
                <TextInput
                  label={t("add-document-group.name-title")}
                  description={t("add-document-group.name-description")}
                  onChange={(name)=>setDraftDocumentGroup(d=>({...d, name}))}
                /><TextInput
                label={t("add-document-group.initials-title")}
                description={t("add-document-group.initials-description")}
                onChange={(initials)=>setDraftDocumentGroup(d=>({...d, initials}))}
              />
                <SelectColor
                  label={t("color")}
                  value={draftDocumentGroup.color}
                  size="lg"
                  setValue={(color)=>setDraftDocumentGroup(d=>({...d, color}))}
                />
                
                <Button onClick={execute} 
                      disabled={!draftDocumentGroup.name  || !draftDocumentGroup.color} 
                      loading={loading} 
                      block 
                      size="lg" 
                      color="active">{t("add-document-group.button")}</Button>
                {error&& <Error.Text {...error}/>}
              </CardContainer>
            </div>


}