import React from 'react';

// Components
import { SearchPreset } from 'components/tools/search_preset';
import { InfoSectionHeading } from 'components/core/typo';
// Hooks
import { useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Context
import { SearchFollowUpContext } from 'contexts/search_follow_up';

export default function EmptyWeeklyFollowUp(){
  const {t} = useTranslation("common");
  const match = useMatch(`/suivi-terrain/:id/:tab`);
  const { filters, dispatchFilters } = useContext(SearchFollowUpContext);
  return <div className="w-full h-full flex items-center justify-center p-6">
          <div className="space-y-4">
            <InfoSectionHeading title={t("select-preset")} description={t("select-preset-description")}/>
            <SearchPreset  
                      to={(d)=>d?`/suivi-terrain/${d.id}${match &&match.params && match.params.tab? `/${match.params.tab}`: "/bdi"}`: `/suivi-terrain`}
                      targetWidth="lg" 
                      color="default" 
                      size="lg"
                      value={filters.preset} 
                      limitToUser={true} 
                      setValue={(value)=>dispatchFilters({type: "preset", value})}/>
          </div>
         </div>
}
