import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Container, CardContainer} from 'components/core/container';
import { Tabs } from 'components/core/tabs';
import { Button } from 'components/core/button';
import { FiArrowLeft, FiInfo } from 'react-icons/fi';
import { SlideOver } from 'components/core/slide_over';
import { TeamInfo } from 'components/teams/info';

// Hooks
import { useContext, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next'; 
import { useParams, useLocation, generatePath, matchPath } from 'react-router-dom';

// Contexts
import { TeamContextProvider, TeamContext } from 'contexts/team';

export function Header(){
  const { t } = useTranslation("common");
  const { team } = useContext(TeamContext);

  if (!team || !team.name){
    return <Container className="py-6">
              <Skeleton className="w-screen max-w-sm h-12" />
            </Container>
  }
  return <Container className="py-3 ">
          <Button href="/teams" color="inlineLink"  className="md:hidden"><FiArrowLeft className="mr-1"/>{t("teams")}</Button>
          <h1 className="font-medium text-2xl">{team.name}<span className="ml-3">{team.active_period && team.active_period.name}</span></h1>
          <p className="text-base text-gray-500">
            <span>{team.sport && team.sport.name}</span> | <span>{team.school && team.school.name}</span>
          </p>
          </Container>
}

function TeamTabs(){
  const { team, refresh} = useContext(TeamContext);
  const params = useParams();
  const location = useLocation();  

  const [open, setOpen] = useState(false);

  useEffect(()=>{
    if (open){ refresh();}
  }, [open])

  const { t } = useTranslation("common");
  const tabs = useMemo(()=>[
      {
        name: t("team-nav.overview"),
        href: generatePath("/teams/:slug", params),
        current: matchPath("/teams/:slug/", location.pathname),
      }, 
      {
        name: t("team-nav.play-time"),
        href: generatePath("/teams/:slug/play-time/*", params),
        current: matchPath("/teams/:slug/play-time/*", location.pathname),
        end: false
      }, 
      {
        name: t("team-nav.settings"),
        end: false,
        href: generatePath("/teams/:slug/settings/", params),
        current: matchPath("/teams/:slug/settings/", location.pathname),
      }, 
    ], [location])
  
  return <Container className="border-b flex items-center justify-between">
          <Tabs tabs={tabs}/>
          <Button color="toolbarIcon" size="toolbarIcon" onClick={()=>setOpen(!open)}><FiInfo/></Button>
          <SlideOver open={open} setOpen={setOpen}>
            {team && <CardContainer><TeamInfo team={team}/></CardContainer>}
          </SlideOver>
          </Container>
}

export function TeamLayout({ children }){
  return <TeamContextProvider>  
          <Header/>
          <TeamTabs/>
          { children }
         </TeamContextProvider>

}