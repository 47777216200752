import React from 'react';

// Components
import { SectionHeading } from 'components/core/typo';
import { Tabs } from 'components/core/tabs';
import { Outlet } from "react-router-dom";

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useMemo } from 'react';

// Context
import { SearchContextProvider } from 'contexts/search';

const tabsKeys = ['students', 'tutors', 'pairings', 'coachs']

export function ExportView(){
  const {t} = useTranslation('common');
  const [tab, setTab] = useState({key: 'students', desc: t(`export.students.description`), name: t(`export.students.name`) });
  const tabs = useMemo(()=>tabsKeys.map(d=>({key:d, desc: t(`export.${d}.description`), href: `/export/${d}`, name: t(`export.${d}.name`)})))
  return <SearchContextProvider storageKey={"export-data-modal"}>
            <SectionHeading title={t("export-data")} >
            </SectionHeading>
            <div className="px-3 border-b">
              <Tabs tabs={tabs} value={tab} setValue={setTab} navClassName=""/>
            </div>
            <Outlet/>
          </SearchContextProvider>
}