import React from 'react';

// Components
import { TeamSettings } from 'components/teams/modify';
import { Container } from 'components/core/container';

export default function GeneralSettings(){

  return <Container className="h-full w-full">
          <TeamSettings/>
         </Container>
}
