import  { useMemo, useContext as useC} from 'react';
import { Casing } from 'utils/casing';

/**
 * Custom hook that converts the context object to camel case.
 * @param {object} context - The context object to convert to camel case.
 * @param {object} options - The options object.
 * @param {string[]} options.whitelist - The list of properties to exclude from the camel case conversion.
 * @returns {object} - The context object with camel case properties.
 */
export const useContext = (context, options) => {
    const camelContext = useC(context);
    return useMemo(() => Casing.recursiveCamelize(camelContext, options), [camelContext]);
}