/*
  This list is intended for managing the noteCodes, not for selection.
*/

import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useContext, useState } from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Button } from 'components/core/button';
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';
import { Fragment } from 'react';
import { SlideOver } from 'components/core/slide_over';
import { CreateNoteCode } from 'components/note-codes/create';
import { ModifyNoteCode } from 'components/note-codes/modify';

// API
import { searchNoteCode } from 'api/notes';
import { FiLock } from 'react-icons/fi';

function CreateButton({onChange}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("add-note-code.title")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <CreateNoteCode onCompleted={()=>{setOpen(false); onChange()}}/>
          </SlideOver>
        </Fragment>
}

function ModifyButton({value, setValue, onDelete}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("modify")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <ModifyNoteCode value={value} setValue={setValue} onDelete={()=>{setOpen(false); onDelete(value)}}/>
          </SlideOver>
        </Fragment>
}

export function ControlledNoteCodesList({noteCodes, setNoteCodes, setPage, paging, error, execute}){
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { t } = useTranslation('common');
  const headers = useMemo(()=>[
    {
     title: t("color"),
     field: (d=>d? d.color : "color"),
     itemClassName: "!py-2 whitespace-nowrap ",
     format: (d=><div style={{background:d}} className="w-5 h-5 mx-auto text-center rounded-md"/>)
    },
    {
     title: t("name"),
     field: (d=>d? d.name : "name"),
     itemClassName: "py-2 whitespace-nowrap"
    },
    {
      headerId: "sys",
     field: (d=>d? d.system : "system"),
     itemClassName: "text-xs",
     format: (d=>d? <FiLock className="text-gray-500"/>: null)
    },
    {
     title: t("group"),
     field: (d=>d? d.group : "group"),
     itemClassName: "py-2 text-gray-500 first-letter:capitalize whitespace-nowrap"
    },
    {
     field: (d=>d? ({value:d, onDelete:((d=>setNoteCodes(current=>current.filter(e=>e.code!==d.code)))), setValue: ((next)=>setNoteCodes(current=>current.map(e=>e.code===next.code? next:e)))}) : "Modifier"),
     FormatComponent: ModifyButton
    },
  ], [noteCodes])

  if (!noteCodes){
    return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2"/>
  }

  return <Info.Container modify={true} label={t("note-codes")} sideHeader={<Fragment><CreateButton onChange={execute}/></Fragment>}>
            {noteCodes.length===0?
              <EmptyState
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-noteCodes-title")}/>
              :
              <Table headers={headers}
                    data={noteCodes}
                    indexingKey="code"
                    order={{by:filters.orderBy, direction:filters.orderDirection}}
                    onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
              }
              {paging&& <Paginate setPage={setPage} {...paging}/>}
              {error? <Error.Text {...error}/>: null}
          </Info.Container>
}

function NoteCodesListWithContext(){
  const { filters } = useContext(SearchContext);
  const [noteCodes, {loading, error, paging, setPage, setResult, execute}] = useSearch(searchNoteCode, filters);
  return <ControlledNoteCodesList noteCodes={noteCodes} setNoteCodes={setResult} setPage={setPage} loading={loading} error={error} paging={paging} execute={execute}/>
}

export function NoteCodesList(){
  return <SearchContextProvider doNotStore defaultState={{orderBy:"name", orderDirection: "asc"}}>
            <NoteCodesListWithContext/>
        </SearchContextProvider>
}