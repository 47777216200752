import { useState, useEffect } from 'react';

export function useElementSize(ref) {
  // State for storing the width and height
  const [size, setSize] = useState({ width: undefined, height: undefined });

  useEffect(() => {
    let animationFrameId = null;

    // Function to update the size state
    function updateSize() {
      if (ref.current) {
        setSize({
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
        });
      }
    }

    // ResizeObserver callback
    function handleResize() {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      animationFrameId = requestAnimationFrame(updateSize);
    }

    // Update the size at the first run
    updateSize();

    // Set up a resize observer to listen for size changes
    const resizeObserver = new ResizeObserver(handleResize);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    // Clean up observer on unmount
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      resizeObserver.disconnect();
    };
  }, [ref]); // Only re-run if the ref object changes

  return size;
}
