// API
import { useMemo } from 'react';
import { timeMonday, timeMinute } from 'd3-time';

import {merge} from 'd3-array'
export function useCronToDates(cron, {fromDate, toDate, ...props}){
  return useMemo(()=>{
    if (!cron) return [];
    const fc = cron.split(" ");
    const min = parseInt(fc[0]);
    const hour = parseInt(fc[1]);
    const day = parseInt(fc[4]);

    return timeMonday.range(timeMonday.floor(fromDate), timeMonday.ceil(toDate)).map(date=>{
      return {date:timeMinute.offset(date, -24*60+day*24*60 + hour*60 + min), ...props}
    })
  }, [cron, fromDate, toDate]);
}

export function useCronsToDates(crons, info){
  return useMemo(()=>{
    if (!crons) return [];
    return merge(crons.map((cron, index)=>{
      const fc = cron.split(" ");
      const min = parseInt(fc[0]);
      const hour = parseInt(fc[1]);
      const day = parseInt(fc[4]);
      const {fromDate, toDate, ...props} = info[index];
      // Hack: Use timeMonday instead of timeWeek (timeSunday) to prevent for time change on sunday. Remove -24*60 minutes
      return timeMonday.range(timeMonday.floor(fromDate), timeMonday.ceil(toDate)).map(date=>{
        return {date:timeMinute.offset(date, -24*60+ day*24*60 + hour*60 + min), ...props}
      })
    }));
    
  }, [crons, info]);
}

export function computeNextOccurence(cron, date){
  if (!cron) return null;
  const fc = cron.split(" ");
  const min = parseInt(fc[0]);
  const hour = parseInt(fc[1]);
  const day = parseInt(fc[4]);
  return timeMinute.offset(timeMonday.floor(date), -24*60 + day*24*60 + hour*60 + min)
}

export function useNextOccurrence(cron, date){
  return useMemo(()=>{
    if (!cron) return null;
    const fc = cron.split(" ");
    const min = parseInt(fc[0]);
    const hour = parseInt(fc[1]);
    const day = parseInt(fc[4]);
    return timeMinute.offset(timeMonday.floor(date), -24*60 + day*24*60 + hour*60 + min)
  }, [cron, date]);
}
