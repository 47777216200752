import React from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiFolder } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch';

// API
import { searchNoteGroups } from 'api/notes';

export function SelectNoteGroup({ value, setValue, disabled, disabledClear, ...props }){

  const { t } = useTranslation("common");
  const [values] = useSearch(searchNoteGroups);

  return <ListSelection value={value}
                        setValue={setValue}
                        disabled={disabled}
                        values={values}
                        label={t('group')}
                        Icon={FiFolder}
                        getKey={(d)=>d.group}
                        format={(d)=>d.group}
                        formatClassName={()=>"first-letter:capitalize"}
                        withClearSelection={!disabledClear}
                        {...props}
                        />
}