import React from 'react';

// Components
import { ModifySecuritySettings } from 'components/settings/security';
import { FiShield } from 'react-icons/fi';

function Security(){
  return <ModifySecuritySettings/>
}
const route =  { path: "security", 
                 group: "users",
                 name: "Sécurité" , 
                 Icon: FiShield,
                 requireAuth: true, 
                 Element: Security };
export default route;
