/*
  This list is intended for managing the mailTypes, not for selection.
*/

import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useContext, useState } from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Button } from 'components/core/button';
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';
import { Fragment } from 'react';
import { SlideOver } from 'components/core/slide_over';
// import { ModifyMailType } from 'components/mail-types/modify';

// API
import { searchMailType } from 'api/mails';
import { locale, parseWithTimeZone } from 'utils/locale';
import { BooleanPill } from 'components/recruting/inscriptions';


export function ModifyButton(){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("modify")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            {/* <ModifyMailType value={value} setValue={setValue} onDelete={()=>{setOpen(false); onDelete(value)}}/> */}
          </SlideOver>
        </Fragment>
}

function IsActiveMailType({active, inactive}){
  // Is active today if either:
  // - active is not null and inactive is null, and active <= today
  // - active is not null and inactive is not null, and active <= today <= inactive 
  
  const isActive = active && (parseWithTimeZone(active)<=new Date()) && (!inactive || (inactive && parseWithTimeZone(inactive)>=new Date()));
  return <BooleanPill value={isActive}/>
}

export function ControlledMailTypesList({mailTypes,  setPage, paging, error}){
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { t } = useTranslation('common');
  const headers = useMemo(()=>[
    
    {
        title: t("ni"),
        field: (d=>d? d.slug : "slug"),
        itemClassName: "text-xs text-cyan-700 font-mono"
    },
    {
     title: t("name"),
     field: (d=>d? d.name : "name"),
     itemClassName: "py-2 min-w-[240px] font-medium"
    },
    {
      title: t("description"),
      field: (d=>d? d.description : "description"),
      itemClassName: "py-2 text-sm min-w-[300px] text-gray-500 "
     },
    {
      title: t("active"),
      field: (d=>d? d.active : "active"),
      format: (d=>d? locale.format("%d %b %Y")(parseWithTimeZone(d)):"-"),
     parseNull: true,
     itemClassName: "py-2 whitespace-nowrap"
    },
    {
      title: t("inactive"),
      field: (d=>d? d.inactive : "inactive"),
     itemClassName: "py-2 whitespace-nowrap",
     parseNull: true,
     format: (d=>d? locale.format("%d %b %Y")(parseWithTimeZone(d)):"-")
     },
    {
      title: t("is-active-today"),
      field: (d=>d? d : "is_active"),
     itemClassName: "py-2 whitespace-nowrap",
     parseNull: true,
     FormatComponent: IsActiveMailType
     },
    // {
    //  field: (d=>d? ({value:d, onDelete:((d=>setMailTypes(current=>current.filter(e=>e.code!==d.code)))), setValue: ((next)=>setMailTypes(current=>current.map(e=>e.code===next.code? next:e)))}) : "Modifier"),
    //  FormatComponent: ModifyButton
    // },
  ], [mailTypes])

  if (!mailTypes){
    return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2"/>
  }

  return <Info.Container modify={true} label={t("mail-types")}>
            {mailTypes.length===0?
              <EmptyState
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-mailTypes-title")}/>
              :
              <Table headers={headers}
                    data={mailTypes}
                    indexingKey="slug"
                    order={{by:filters.orderBy, direction:filters.orderDirection}}
                    onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
              }
              {paging&& <Paginate setPage={setPage} {...paging}/>}
              {error? <Error.Text {...error}/>: null}
          </Info.Container>
}

function MailTypesListWithContext(){
  const { filters } = useContext(SearchContext);
  const [mailTypes, {loading, error, paging, setPage, setResult, execute}] = useSearch(searchMailType, filters);
  return <ControlledMailTypesList mailTypes={mailTypes} setMailTypes={setResult} setPage={setPage} loading={loading} error={error} paging={paging} execute={execute}/>
}

export function MailTypesList(){
  return <SearchContextProvider doNotStore defaultState={{orderBy:"name", orderDirection: "asc"}}>
            <MailTypesListWithContext/>
        </SearchContextProvider>
}