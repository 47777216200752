import React from 'react'
import { Popover as HeadlessPopover, Transition} from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'
import classnames from 'classnames'

const styles = {
  default: {
    button: 'border-none bg-gray-100 disabled:bg-gray-200 disabled:text-gray-900 font-medium text-gray-900 whitespace-nowrap hover:bg-gray-100 sm:px-3',
    iconActive: 'text-gray-900 ',
    iconInactive: 'text-gray-300 ',
  },
  white: {
    button: 'border-none bg-white shadow disabled:bg-gray-200 disabled:text-gray-800 font-medium text-gray-900 whitespace-nowrap sm:px-3',
    iconActive: 'text-gray-900 ',
    iconInactive: 'text-gray-300 ',
  }
}

const sizes = {
  none : {
    width: ""
  },
  xs: {
    width: "w-full max-w-xs"
  },
  sm: {
    text: 'text-sm',
    button: 'py-1.5 md:py-2 pl-3 pr-10 sm:text-sm ',
    width: "w-80 max-w-sm"
  },
  md: {
    text: 'text-base',
    button: 'py-2 md:py-3 pl-3 pr-10 text-base ',
    width: "w-full max-w-md"
  },
  lg: {
    width: "w-screen  max-w-lg"
  },
  full: {
    width: "w-full"
  },
  flexfull: {
    width: "w-full flex-1",
    button: 'flex-1'
  },
  fit: {
    button: 'p-2'
  }
}

export function Popover({children, color="white", size="sm", label}) {
    return (
      <div className="">
        <HeadlessPopover className="relative">
          {({ open }) => (
            <>
              <HeadlessPopover.Button
                className={classnames(
                  open && "text-opacity-80",
                  "group flex w-full items-center rounded-md focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500",
                  styles[color].button,
                  sizes[size].button)}
              >
                <div className='items-center w-full md:flex'>
                  <span className='block ml-1 text-sm'>{label}</span>
                </div>


                <ChevronDownIcon
                  className={`${open ? '' : ''}
                  ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                  aria-hidden="true"
                  />
              </HeadlessPopover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <HeadlessPopover.Panel className="absolute z-10 w-full min-w-[18rem] pb-10 mt-3 transform -translate-x-1/2 lg:w-72 left-1/2">
                  <div className="overflow-hidden rounded-md shadow-lg ring-1 ring-black ring-opacity-5 ">
                      <div className="relative p-3 bg-white ">
                          {children}
                      </div>
                  </div>
                </HeadlessPopover.Panel>
              </Transition>
            </>
          )}
        </HeadlessPopover>
      </div>
    )
  }
