import { fetchCall } from 'api';

export const searchMailType = ({search, page, limit,orderBy, orderDirection})=>{

  const options = {
    method: "GET",
    endpoint: "/mail-types",
    query: {search, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
}

export const updateMailType = ({ slug, name, description, active, inactive })=>{
  const options = {
    method: "POST",
    endpoint: `/mail-types/${slug}`,
    body: {name, description, active, inactive}
  }
  return fetchCall(options)
}

export const searchMails = ({tutor, mailType, search, page, limit,orderBy, orderDirection})=>{

  const options = {
    method: "GET",
    endpoint: "/mails",
    query: {search, page, limit, orderBy, orderDirection, tutor, mailType}
  }
  return fetchCall(options)
}
