import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { SingleLineField} from 'components/tools/modifiable_fields';

// Hooks
import { useTranslation } from 'react-i18next';

// API
import { updateSchoolSubject } from 'api/subjects';

export function ModifySchoolSubject({value, setValue}){
  const {t} = useTranslation('common');

  return<div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("modify-school-subject")}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
                 <SingleLineField 
                    label={true&&t("name")} 
                    modify={true} 
                    value={value.name} 
                    setValue={(name)=>{setValue({...value, name}); updateSchoolSubject({slug: value.slug, name})}}/>
          
               
              </CardContainer>
            </div>


}