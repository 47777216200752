import { updateEvent } from 'api/events';
import classNames from 'classnames';
import { Button } from 'components/core/button';
import { Modal } from 'components/core/modal';
import { useAPI } from 'hooks/useAPI';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function SetEventComment({event, setEvent}){
    const {t} = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [comment, setComment ] = useState(event.comment);
    const [result, {loading, execute}] = useAPI(updateEvent, 
                                {id: event.id, comment}, 
                                {immediate: false})

    useEffect(() => {
        if(result && !loading){
            setEvent({...event, comment})
            setModalOpen(false);
        }
    }, [result, loading]);
    
    return <>
           <>
            <div className='flex justify-between items-center gap-2'>
                <div className='w-full'>
                  <h2 className='font-medium'>{t("play-time-comment.label")}</h2>
                  <p className={classNames('line-clamp-3 w-full lg:line-clamp-none', event.comment? "text-gray-800 bg-gray-50 p-3 rounded-md border": "text-gray-400")}>{event.comment||t("play-time-comment.no-comment")}</p>
                  </div>
                {!event.transmitted && <Button color="gray" disabled={event.transmitted} onClick={()=>setModalOpen(true)} size="md">{t("update-event-comment.modal-button")}</Button>}
            </div>
        </>
         <Modal open={modalOpen} setOpen={setModalOpen}>
          <Modal.Title>
            {t("update-event-comment.title")}
          </Modal.Title>
          <Modal.Body>
          <div className='space-y-3'>
            <p className='text-gray-500'>{t("update-event-comment.description1")}</p>
          </div>
          <div className='space-y-2'>
          <textarea type='text' 
          id="description"
          placeholder={t("update-event-comment.placeholder")}
          rows={5}
          className="rounded-md block w-full border-0 py-0 resize-none placeholder-gray-400 focus:ring-2 focus:ring-orange-500 ring-1 ring-gray-300  pt-2.5 "
         
          value={comment} onChange={(e) => setComment(e.target.value)} />
          <Button color="active" 
          loading={loading}
          size="md" 
          block 
          onClick={() => execute()}>{t("update-event-comment.button")}</Button>
            </div>

          </Modal.Body>
          </Modal>
            </>

}