import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Editor } from 'components/core/rich-editor/editor';
import { SubLabel, LargeLabel } from 'components/tools/modifiable_fields';

/**
 * Assembles an array of objects containing text into a single HTML string.
 *
 * @param {Array} disassembledText - An array of objects, each containing a `label` and `text` property.
 * @returns {string} - A single HTML string with each text wrapped in a paragraph and label in a strong tag.
 */
export function assembleText(disassembledText) {
    return (
        disassembledText
            ?.filter(v => v && typeof v?.text === 'string' && v?.text?.trim() !== '' && v?.text?.replace(/<[^>]*>?/gm, '') !== '')
            .map(({ label, text }) => {
                const safeLabel = label || "";
                const safeText = text || "";
                const labelHtml = `<p><u><strong>${safeLabel}</strong></u></p>`;
                return `${labelHtml}${safeText}`;
            })
            .join('<p><br></p>')
    )
}

export function DisassembledEditor({ config, setText, setIsValid }) {
    const {comments: configComments} = config || {};

    const [comments, setComments] = useState(
        configComments?.map(item => ({
            label: item?.label,
            text: item?.text || '',
            required: item?.required || false
        }))
    );

    useEffect(() => {
        const assembled = assembleText(comments || []);
        setText?.(assembled);
    }, [comments]);

    const handleTextChange = (index, newText) => {
        setComments(prevComments => {
            const updatedComments = [...prevComments];
            updatedComments[index].text = newText;
            return updatedComments;
        });
    };
    
    useEffect(() => {
        const v = comments?.every(({ text, required }) => !required || (text && text?.replace(/<[^>]*>?/gm, '').trim() !== ''));
        setIsValid?.(v);
    }, [comments]);
    
    return (
        <div className='relative pt-12 mt-2 p-0.5 pb-2 w-full'>
            <div className='flex flex-col gap-2 w-full'>
                {configComments?.map(({ label, placeholder, sublabel, description, required }, index) => (
                    <div key={index} className="group">
                        {label && <div className='ml-0.5 mt-4 sm:mt-0'><LargeLabel>{label}{required && <span className='text-red-500 ml-0.5'>*</span>}</LargeLabel></div>}
                        <p className='whitespace-pre-wrap text-gray-500 ml-0.5 text-sm mb-0.5'>{description}</p>
                        <Editor
                            editorStyles="text-black"
                            minHeight={"100px"}
                            toolbarStyles={classNames(
                                'absolute top-0 left-0 w-[calc(100%-8px)] py-2',
                                "group-focus-within:visible",
                                index === 0 ? 'visible' : 'invisible'
                            )}
                            name={label}
                            id={index}
                            text={comments[index]?.text}
                            setText={(v) => handleTextChange(index, v)}
                            placeholder={placeholder}
                            hideToolbarToggle
                        />
                        {sublabel && <div className='mt-0.5 ml-0.5'><SubLabel>{sublabel}</SubLabel></div>}
                    </div>
                ))}
            </div>
        </div>
    );
}