import React from 'react';
import classnames from 'classnames';

// Components
import { DashboardLayout, MultiColumn } from 'components/core/layouts';
import { SectionHeading } from 'components/core/typo';
import { TutorsList } from 'components/tutors/list';
import { FilterBar } from 'components/tools/filter_bar';
import { FiZap } from 'react-icons/fi';
import { Outlet } from "react-router-dom";

// Contexts
import { SearchContextProvider } from 'contexts/search';
import { TutorsContextProvider } from 'contexts/tutors';

// Utils
import { getFullCurrentPeriod } from 'utils/period';

// Hooks
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

export function TutorListPage(){
  const { ni } = useParams();
  const { t } = useTranslation("common");
  const defaultFields = useMemo(()=>({period: getFullCurrentPeriod()}), []);
  return <SearchContextProvider storageKey={"/tutors"} defaultFields={defaultFields}>
          <TutorsContextProvider>
            <DashboardLayout contentClassName={"overflow-hidden"}>
              <SectionHeading title={t("tutors")}  className={classnames(ni && "hidden md:block")}>
                <FilterBar period availableForPairing leftService/>
              </SectionHeading>
              <MultiColumn>
                <MultiColumn.Left className={classnames("border-r", ni && "hidden md:block")}>
                  <TutorsList/>
                </MultiColumn.Left>
                <MultiColumn.Content  className={classnames(!ni && "hidden md:block")}>
                  <Outlet/>
                </MultiColumn.Content>
              </MultiColumn>
            </DashboardLayout>
          </TutorsContextProvider>
         </SearchContextProvider>
}

const route =  { path: "/tutors",
                 name: "Tuteurs bénévoles" ,
                 Icon: FiZap,
                 showInNavBar: true,
                 requireAuth: true,
                 Element: TutorListPage };
export default route;
