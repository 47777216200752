import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading, RawTextInfo, RawTextWarning  } from 'components/core/typo';
import { SelectSport } from 'components/tools/sports_list';
import { TextInput } from 'components/core/inputs';
import { Error } from 'components/core/typo'
import { Button } from 'components/core/button';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useAPI } from 'hooks/useAPI';

// API
import { createPlayTimeRule } from 'api/play_time_rules';


function getReductibleFraction({a, b}){
  var gcd = function gcd(a,b){
    return b ? gcd(b, a%b) : a;
  };
  gcd = gcd(a,b);
  return {reducedA:a/gcd, reducedB: b/gcd};
}
function isRedutibleFraction({a, b}){
  const gcd = getReductibleFraction({a,b});
  return gcd.reducedA!=a || gcd.reducedB!=b;
}



export function CreatePlayTimeRule({onCompleted}){
  const [draftPlayTimeRule, setDraftPlayTimeRule] = useState({teams: []});
  const [playTimeRule, {loading, execute, error}] = useAPI(createPlayTimeRule, draftPlayTimeRule, {immediate:false});
  const {t} = useTranslation('common');
  const disabled = !draftPlayTimeRule.playTimeTitle || !draftPlayTimeRule.sport || draftPlayTimeRule.doneStudySessions==null|| draftPlayTimeRule.targetStudySessions==null || !draftPlayTimeRule.weeks;
  useEffect(()=>{
    if (playTimeRule) onCompleted(playTimeRule);
  }, [playTimeRule])

  return<div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("add-play-time-rule")}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
                <TextInput
                  label={t("add-play-time-rule-group.play-time-title")}
                  description={t("add-play-time-rule-group.play-time-description")}
                  onChange={(playTimeTitle)=>setDraftPlayTimeRule(d=>({...d, playTimeTitle}))}
                />
                <SelectSport 
                  label={t("sport")}
                  value={draftPlayTimeRule.sport}
                  size="lg"
                  setValue={(sport)=>setDraftPlayTimeRule(d=>({...d, sport}))}
                />
                <TextInput
                      label={t("add-play-time-rule-group.weeks-title")}
                      description={t("add-play-time-rule-group.weeks-description")}
                      type="number"
                      min="0"
                      onChange={(weeks)=>setDraftPlayTimeRule(d=>({...d, weeks}))}
                    />
                <div className="flex items-start space-x-2">
                  <div className="w-32">
                    <TextInput
                      label={t("add-play-time-rule-group.target-study-sessions-title")}
                      description={t("add-play-time-rule-group.target-study-sessions-description")}
                      type="number"
                      min="0"
                      onChange={(targetStudySessions)=>setDraftPlayTimeRule(d=>({...d, targetStudySessions}))}
                    />
                  </div>
                  <div className="flex-1">
                    <TextInput
                      label={t("add-play-time-rule-group.target-study-sessions-description-title")}
                      description={t("add-play-time-rule-group.target-study-sessions-description-description")}
                      onChange={(targetStudySessionsDescription)=>setDraftPlayTimeRule(d=>({...d, targetStudySessionsDescription}))}
                    />
                  </div>
                </div>

                <div className="flex items-start space-x-2">
                  <div className="w-32">
                    <TextInput
                      label={t("add-play-time-rule-group.done-study-sessions-title")}
                      description={t("add-play-time-rule-group.done-study-sessions-description")}
                      type="number"
                      min="0"
                      onChange={(doneStudySessions)=>setDraftPlayTimeRule(d=>({...d, doneStudySessions}))}
                    />
                  </div>
                  <div className="flex-1">
                    <TextInput
                      label={t("add-play-time-rule-group.done-study-sessions-description-title")}
                      description={t("add-play-time-rule-group.done-study-sessions-description-description")}
                      onChange={(doneStudySessionsDescription)=>setDraftPlayTimeRule(d=>({...d, doneStudySessionsDescription}))}
                    />
                  </div>
                </div>
                {draftPlayTimeRule && draftPlayTimeRule.targetStudySessions && draftPlayTimeRule.doneStudySessions && isRedutibleFraction({a:draftPlayTimeRule.doneStudySessions , b: draftPlayTimeRule.targetStudySessions})
                 && <RawTextWarning>{t("play-time-rules-explained.warning-reductible", {done: draftPlayTimeRule.doneStudySessions, obli: draftPlayTimeRule.targetStudySessions, ...getReductibleFraction({a:draftPlayTimeRule.doneStudySessions , b: draftPlayTimeRule.targetStudySessions})})}</RawTextWarning>
          }
                {!disabled && <RawTextInfo>
                    {t("play-time-rules-explained.info", {description: draftPlayTimeRule.playTimeTitle, weeks: draftPlayTimeRule.weeks, obli: draftPlayTimeRule.targetStudySessions, done:draftPlayTimeRule.doneStudySessions})}
                  </RawTextInfo>}
                <Button onClick={execute} disabled={disabled} loading={loading} block size="lg" color="active">{t("add-play-time-rule")}</Button>
                {error&& <Error.Text {...error}/>}
              </CardContainer>
            </div>


}