import React from 'react';

// Components
import { Container } from 'components/core/container';
import { PairingSettings } from 'components/pairings/modify';

export default function GeneralSettings(){

  return <Container className="h-full w-full">
              <PairingSettings/>
         </Container>
}
