import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useMemo } from 'react';
import { useAPI } from 'hooks/useAPI';

// Components
import { Button } from 'components/core/button';
import { InfoSectionHeading } from 'components/core/typo';
import { Error } from 'components/core/typo';
import { StateTabs } from 'components/core/tabs';
import { RuleMakerForm } from 'components/tournament-rules/rule-maker';
import { TextWarningStructured } from 'components/core/typo';

// Utils
import { getOrderValueFromSlug, orderByValues } from 'components/tournament-rules/tournament-rules';
import toast from 'react-hot-toast';

// API
import { updateTournamentRule, deleteTournamentRule } from 'api/tournament-rules';

function DeleteButton({ value, onDelete }) {
    const { t } = useTranslation('common');
    const handleDelete = () => {
        onDelete(value)
        toast.success(t("tournament-rules.delete-success"))
    }
    const [, { execute, loading, error }] = useAPI(deleteTournamentRule,
        { id: value.id },
        { onResult: handleDelete, immediate: false })

    return <div className="w-full p-3 space-y-3 border-2 border-red-500 rounded-lg">
        <div>
            <h3 className="font-medium text-red-600">{t("delete-play-time-rule.title")}</h3>
            <p className="text-gray-500">{t("tournament-rules.delete-description")}</p>
        </div>
        <Button loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-play-time-rule.button")}</Button>
        {error ? <Error.Text {...error} /> : null}
    </div>
}

function DeleteTournamentRule({ value, onDelete }) {

    return <div className='px-6'>
        <div className='my-6'>
            <DeleteButton value={value} onDelete={onDelete} />
        </div>
    </div>
}

const deepCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

const deepCompare = (obj1, obj2) => {
    return JSON.stringify(obj1) == JSON.stringify(obj2);
}

function ModifyTournamentRule({ value, setValue }) {

    const { t } = useTranslation("common");
    const [sport, setSport] = useState(value.sport || null);
    const [description, setDescription] = useState(value.description || "");
    const [nbWeeks, setNbWeeks] = useState(value.num_weeks || "");
    const [nbGames, setNbGames] = useState(value.num_games || "");
    const [weeks, setWeeks] = useState(deepCopy(value.games_to_weeks_map) || []);
    const [orderWeeksBy, setOrderWeeksBy] = useState(getOrderValueFromSlug(value.order_weeks_by) || orderByValues[0]);
    const [highlightBest, setHighlightBest] = useState(value.highlight_best || false);
    const [highlightWorst, setHighlightWorst] = useState(value.highlight_worst || false);
    const [isValid, setIsValid] = useState(false);

    const handleUpdateResult = () => {
        setValue({ ...value, sport: sport, description: description, num_weeks: nbWeeks, num_games: nbGames, games_to_weeks_map: weeks, order_weeks_by: orderWeeksBy.slug, highlight_best: highlightBest, highlight_worst: highlightWorst })
        toast.success(t("tournament-rules.modify-success"));
    }

    const handleReset = () => {
        setOrderWeeksBy(getOrderValueFromSlug(value.order_weeks_by));
        setHighlightBest(value.highlight_best);
        setHighlightWorst(value.highlight_worst);
        setDescription(value.description);
        setWeeks(deepCopy(value.games_to_weeks_map));
    }

    const hasChanged = useMemo(() => {
        return (orderWeeksBy.slug || orderWeeksBy) != value.order_weeks_by || highlightBest != value.highlight_best || highlightWorst != value.highlight_worst || description != value.description || !deepCompare(weeks, value.games_to_weeks_map)
    }, [orderWeeksBy, highlightBest, highlightWorst, description, weeks])

    const [, { loading, error, execute: executeUpdate }] = useAPI(updateTournamentRule, {
        id: value.id,
        sport,
        numWeeks: nbWeeks,
        numGames: nbGames,
        gamesToWeeksMap: weeks,
        description,
        orderWeeksBy: orderWeeksBy.slug,
        highlightBest,
        highlightWorst
    }, { immediate: false, onResult: handleUpdateResult });

    return (
        <div className='min-h-[calc(100vh-185px)] flex flex-col justify-between px-3 sm:px-6'>
            <div>
                {
                    hasChanged && <TextWarningStructured className={"mt-4"} title={t("tournament-rules.has-changed-not-saved-title")} description={t("tournament-rules.has-changed-not-saved")}/>
                }
                <RuleMakerForm
                    state={"modify"}
                    initialRule={value}
                    sport={sport} setSport={setSport}
                    description={description} setDescription={setDescription}
                    nbWeeks={nbWeeks} setNbWeeks={setNbWeeks}
                    nbGames={nbGames} setNbGames={setNbGames}
                    weeks={weeks} setWeeks={setWeeks}
                    orderWeeksBy={orderWeeksBy} setOrderWeeksBy={setOrderWeeksBy}
                    highlightBest={highlightBest} setHighlightBest={setHighlightBest}
                    highlightWorst={highlightWorst} setHighlightWorst={setHighlightWorst}
                    setIsValid={setIsValid}
                    loading={loading}
                />
            </div>
            <div className='pt-6 pr-2 border-t'>
                {error && <Error.Text className={"mb-2"} {...error} />}

                <div className='flex space-x-2'>
                    <Button onClick={handleReset} loading={loading} disabled={!hasChanged} color={"gray"} size={"lg"} block >{t("tournament-rules.reset-button")}</Button>
                    <Button onClick={executeUpdate} loading={loading} disabled={isValid} color={"active"} size={"lg"} block >{t("tournament-rules.modify-button")}</Button>
                </div>

            </div>
        </div>
    )
}

export function ModifyDeleteTournamentRules({ value, setValue, onDelete }) {

    const { t } = useTranslation("common");

    const tabs = useMemo(() => [
        { name: t("stats-overview"), val: "overview" },
        { name: t("parameters"), val: "parameters" },
    ], []);

    const [tab, setTab] = useState(tabs[0]);

    return <>
        <InfoSectionHeading className={"mx-6"} mediumTitle={t("tournament-rules.modify")} />
        <StateTabs tabs={tabs} value={tab} setValue={setTab} />
        {tab.val === "overview" && <ModifyTournamentRule value={value} setValue={setValue} />}
        {tab.val === "parameters" && <DeleteTournamentRule value={value} onDelete={onDelete} />}
    </>

}