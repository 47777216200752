import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading, RawTextInfo  } from 'components/core/typo';
import { SingleLineField} from 'components/tools/modifiable_fields';
import { SelectNoteGroup } from 'components/tools/select_note_group';
import { SelectColor } from 'components/tools/select_color';
import { EmptyState } from 'components/core/empty';
import { Button } from 'components/core/button';
import { Error } from 'components/core/typo'

// Hooks
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// API
import { deleteNoteCode, updateNoteCode } from 'api/note_codes';
import { FiAlertTriangle, FiLock } from 'react-icons/fi';

function DeleteButton({value, onDelete}){
  const { t } = useTranslation('common');
  const [, {execute, loading, error}] = useAPI(deleteNoteCode, 
                                            {code: value.code}, 
                                            {onResult: (()=>onDelete(value)), immediate:false})

  return <div className="border-2 border-red-500 rounded-lg p-3 w-full space-y-3">
          <div>
            <h3 className="font-medium text-red-600">{t("delete-note-code.title")}</h3>
            <p className="text-gray-500">{t("delete-note-code.description")}</p>
          </div>
          <Button loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-note-code.button")}</Button>
          {error? <Error.Text {...error}/>: null}
         </div>
}



export function ModifyNoteCode({value, setValue, onDelete}){
  const {t} = useTranslation('common');

  return<div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("modify-note-codes.title")}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
                {value.system && 
                <EmptyState color="danger" 
                Icon={FiLock}
                title={t("note-cannot-be-modified")} 
                description={t("note-cannot-be-modified-description")}/>}
                 <EmptyState 
                 color="warning" 
                Icon={FiAlertTriangle}
                 description={t("modify-note-codes.description")}/>
                 <SingleLineField 
                  label={t("add-note-code.name-title")}
                    modify={true} 
                    value={value.name} 
                    setValue={(name)=>{setValue({...value, name}); updateNoteCode({code: value.code, name})}}/>
                    <SingleLineField 
                  label={t("update-note-code-initials")}
                  description={t("update-note-code-initials-description")}
                    modify={true} 
                    value={value.initials} 
                    setValue={(initials)=>{setValue({...value, initials}); updateNoteCode({code: value.code, initials})}}/>
            <SingleLineField 
                  label={t("update-note-code-special-title")}
                  description={t("update-note-code-special-description")}
                    modify={true} 
                    value={value.special} 
                    setValue={(special)=>{setValue({...value, special}); updateNoteCode({code: value.code, special})}}/>
            
                {value.special==="rencontre" && <RawTextInfo>{t("note-code-in-rencontre")}</RawTextInfo>}
                  <SelectNoteGroup 
                    label={t("group")}
                    value={{group:value.group}}
                    size="lg"
                    disabledClear={true}
                    setValue={(group)=>{setValue({...value, group: group && group.group}); updateNoteCode({code: value.code, group: group&& group.group})}}/>
                <SelectColor
                  label={t("color")}
                  value={value.color}
                  size="lg"
                  disabledClear={true}
                  setValue={(color)=>{setValue({...value, color}); updateNoteCode({code: value.code, color})}}/>
                {!value.system && <DeleteButton value={value} onDelete={onDelete}/>}
              </CardContainer>
            </div>


}