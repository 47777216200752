/* eslint-disable */
import React, { useState, useContext, useMemo, useEffect } from 'react';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useDebounce } from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';

// Utils
import classnames from 'classnames';
import { orderWeekdays } from 'utils/locale';
import { getCurrentPeriod } from 'utils/period';

// API
import { searchStudents } from 'api/students';

// Components
import { Command } from 'components/core/command';
import { Error } from 'components/core/typo';
import { SearchContextProvider, SearchContext } from 'contexts/search';
import { FilterBar } from 'components/tools/filter_bar';
import { Link } from 'react-router-dom';
import { Skeleton } from 'components/core/skeleton';
import { PreferenceCell } from 'components/student/preferences';

function StudentOption({ student, active, selected }) {
    const activeTeams = useMemo(()=>{
        if (!student.teams) return null;
        const currentPeriod = getCurrentPeriod()
        return student.teams.filter(d=>d.team.active_period===currentPeriod);
    }, [student]);
    
    const availabilities = useMemo(()=>{
        const weekdays = student.schedule_availabilities.map(s=>`${s.from.split("à")[0]}`.replace(" ", ""));
        // Get uniques
        const uniqueWeekDays = [...new Set(weekdays)];
        // Sort weekdays
        const sortedWeekDays = orderWeekdays(uniqueWeekDays)
        return sortedWeekDays.join(', ');

    }, [student]);
    return <>
        <Link to={`/students/${student?.ni}`} className='grid grid-cols-4 md:grid-cols-5 gap-x-2 w-full'>
        <div className='col-span-1'>
                    <span className={classnames(selected &&"font-semibold")}>{student.name}</span>
                    <span className={classnames(!active&& 'opacity-80')}><PreferenceCell {...student}/></span>
                </div>
                <div className=' col-span-1'>
                    <p className={classnames(!active&& 'opacity-80')}>{(activeTeams || []).map(d=>(d.team.name)).join(', ')}</p>
                </div>
                <div className='col-span-1'>
                    <p className={classnames(!active&& 'opacity-80')}>{availabilities}</p>
                </div>
                <div className='col-span-1'>
                    <p className={classnames(!active&& 'opacity-80')}>
                        {(student.prefered_subjects || []).filter(e=>e.description).map(e=>`${e.name}`).join(', ')}
                    </p>
                </div>
        </Link>
    </>
}


export function SearchStudentsCommandWithContext({ open, setOpen }) {
    const { t } = useTranslation("common");
    const [query, setQuery] = useState("");
    const { filters } = useContext(SearchContext);
    const debounceQuery = useDebounce(query, 500);
    const params = useMemo(() => ({ search: query, ...filters }), [debounceQuery, filters]);
    const fields = useMemo(() => ([

    ]), []);
    const validateParams = useMemo(() => ()=> {
        return !!open;
    }, [open]);
    const [students, { loading, error, paging }] = useSearch(searchStudents, params, { limit: 1000, fields, validateParams });
    useEffect(() => {
        if (!open) {
            setQuery("");
        }
    }, [open]);
    return <>
        <Command.Root open={open} setOpen={setOpen} size="7xl">
            <Command.Search
                setQuery={setQuery}
                loading={loading}
                placeholder={t("search-students.placeholder")}
                multiple by={(a, b) => a.ni === b.ni}>
                {error ? <Error.Text {...error} /> : null}
                <FilterBar paired activeSinceFrom activeSinceTo period color="default" />

                <Command.Options static className="text-sm text-gray-800 " size="lg">
                    {/* No results */}
                    {!loading && (students || []).length === 0 && (
                        <p className="p-4 text-sm text-gray-500">{t("search-students.no-results")}</p>
                    )}
                    {
                        loading && <>
                            <Skeleton className="h-44" />
                        </>
                    }
                    {/* Show results */}
                    {!loading && (students || []).map((student) => (
                        <Command.Option key={student.ni} value={student}>
                            {({ active, selected }) => (
                                <StudentOption student={student} active={active} selected={selected} />
                            )}
                        </Command.Option>
                    ))}
                </Command.Options>
                <Command.Help>{t("search-students.info", {count:paging?.numResults})}</Command.Help>
            </Command.Search>
        </Command.Root>
    </>
}

export function SearchStudentsCommand({ ...props }) {
    return <SearchContextProvider doNotStore={true}>
        <SearchStudentsCommandWithContext {...props} />
    </SearchContextProvider>
}