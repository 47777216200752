import React from 'react';

// Hooks
import { useQuery } from 'hooks/useQuery';
import { useAPI } from 'hooks/useAPI';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useMemo, useContext, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// API
import { retrieveStudent } from 'api/students';

// Context
import { SearchStudySessionsStatsContext } from 'contexts/follow_up_stats'

// Components
import { CardSectionHeading  } from 'components/core/typo';
import { CardContainer } from 'components/core/container';
import { StateTabs } from 'components/core/tabs';
import { Fragment } from 'react';
import { StatsChartAndTable } from 'components/students/stats_display';
import { SubjectsStatsChartAndTable } from 'components/students/subjects_stats_chart';
import { SelectDateInterval } from 'components/tools/select_date';

function OverviewTab({ni}){
  return <StatsChartAndTable student={ni}/>
}

function SubjectsTab({ni}){
  return <SubjectsStatsChartAndTable student={ni}/>
}

function Card({dates, ni}){
  const {filters, dispatchFilters} = useContext(SearchStudySessionsStatsContext)
  const {t} = useTranslation("common");
  const params = useMemo(()=>({ni}), [ni]);
  const validateParams = useCallback(({ni})=>!!ni);
  const [student] = useAPI(retrieveStudent, params, {validateParams});
  const tabs = useMemo(()=>[
    {name: t("stats-overview"), val: "overview"},
    {name: t("stats-by-subject"), val: "subjects"},
  ], []);
  const [tab, setTab] = useState(tabs[0]);

  useEffect(()=>{
    if (!filters.fromDate){
      dispatchFilters({type: "dates", value: dates})
    }
  }, []);

  return <Fragment>
          <CardContainer>
            <CardSectionHeading title={student && student.name}>
              <SelectDateInterval
                orientation="right"
                value={{fromDate:filters.fromDate || dates.fromDate, toDate: filters.toDate || dates.toDate}}
                setValue={value=>dispatchFilters({type: "dates", value: value(filters)})} />
            </CardSectionHeading>
          </CardContainer>
             <StateTabs tabs={tabs} value={tab} setValue={setTab}/>
                {tab.val==="overview" && <OverviewTab ni={ni}/>}
                {tab.val==="subjects" && <SubjectsTab ni={ni}/>}
          </Fragment>
}


/*
  Autonomous card based on query params (student).
*/
export function StudentStatsExtendedViewQueryBased({dates}){
  const {query} = useQuery();
  const student = useMemoCompare(query, (prev, next)=>prev&& (prev.get("student")!==next.get("student")));
  const params = useMemo(()=>({ni: query.get("student")}), [student]);

  return <Card dates={dates} {...params}/>
}