/* eslint-disable */
import React, { useContext, useEffect, useMemo } from "react";

// Compnents
import { InfoContainer, Info } from 'components/core/info';
import { Button } from 'components/core/button';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { CheckBox } from 'components/tools/checkbox';
import { SelectCronPeriod } from 'components/tools/select_cron_period';
import { SelectPairingType } from 'components/tools/select_pairing_type';
import { Error, RawTexError } from 'components/core/typo'
import { SearchUser } from "components/tools/search_user";
import { DeleteButton } from "./modify";
import { SelectDate } from "components/tools/select_date";
import { Skeleton } from "components/core/skeleton";
import { RawTextInfo } from "components/core/typo";
import { FiShare2 as TriadeIcon } from "react-icons/fi";
import { CreateGroupButton, DeleteGroupModal } from "components/pairings/groups";

// Hooks
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAPI } from 'hooks/useAPI';

// Utils
import { cronsIntervalToString, cronToString } from 'utils/crons';
import { dateParse } from "utils/locale";
import classNames from 'classnames';

//API
// import { createPairing } from 'api/pairings';
import { SelectSchoolSubjectsDropdown } from 'components/tools/select_school_subjects';
import { PairingContext } from "contexts/pairing";
import { updatePairing } from "api/pairings";
import { retrievePairingGroupWithPairingId } from 'api/pairings';
import { useMemoCompare } from "hooks/useMemoCompare";

function GroupInfo({ children, className, ...props }) {

    return <>
        <div className={classNames("text-blue-900 bg-blue-50 rounded-md p-1 px-2 w-fit text-sm", className)} {...props}>
            <TriadeIcon className="inline mr-2 -mt-0.5" />
            {children}
        </div>
    </>
}

export function ConfirmPairingPage() {
    const { pairing, execute: reloadPairing } = useContext(PairingContext);
    const [draftPairing, setDraftPairing] = useState({...pairing, studentConfirmed: true, tutorConfirmed:true});
    const retrieveGroupParams = useMemo(() => ({ pairingId: pairing?.id }), [pairing]);
    const retrieveGroupParamsMemo = useMemoCompare(retrieveGroupParams, (prev, next)=>(prev && next && prev.pairingId === next.pairingId));
    const [group, { loading: loadingGroup, setResult:setGroup }] = useAPI(retrievePairingGroupWithPairingId, 
                                    retrieveGroupParamsMemo, 
                                    { immediate: true, camelize: true, validateParams: (params) => !!params.pairingId });
    const otherPairing = group?.pairings?.find(p => p.id !== pairing.id)
    const isInGroup = !!pairing?.group && otherPairing;

    const params = useMemo(() => ({
        confirmed: new Date().toISOString().slice(0, 10),
        id: draftPairing.id,
        studentConfirmed: draftPairing.studentConfirmed,
        tutorConfirmed: draftPairing.tutorConfirmed,
        period: draftPairing.period,
        startedAt: draftPairing.startedAt,
        preferedType: draftPairing.preferedType && draftPairing.preferedType.code,
        subjects: draftPairing.subjects,
        assignedUser: draftPairing.assigned_user
    }), [draftPairing]);
    const [result, { loading, error, execute }] = useAPI(updatePairing, params, { immediate: false });
    const { t } = useTranslation('common');
    useEffect(() => {
        setDraftPairing({...pairing, studentConfirmed: true, tutorConfirmed:true});
    }, [pairing])
    if (!draftPairing.student || loadingGroup) {
        return <Skeleton className="h-80" />
    }
    if (result) {
        return <div className="h-full flex flex-col justify-center items-center bg-gray-100">
            <div className="bg-white my-3 shadow-sm rounded-md p-6 w-full max-w-xl">
                <h2 className="text-2xl font-medium">{t('pairing-is-confirmed.title')}</h2>
                <p className="text-gray-500 mb-2">{t("pairing-is-confirmed.info")}</p>

                <Button onClick={() => reloadPairing()} block color="active" className="mt-5" size="lg">
                    {t("pairing-is-confirmed.button")}
                </Button>
            </div>
        </div>

    }
    return <div className="min-h-full flex flex-col justify-center items-center bg-gray-100">
        <div className="flex justify-end w-full max-w-xl mt-5">
            {
                group ? <>
                    <DeleteGroupModal groupId={group?.id} onResult={()=>setGroup(null)} />
                </>
                :
                <>
                    <CreateGroupButton onResult={setGroup} />
                </>
            }
        </div>
        <div className="bg-white my-3 shadow-sm rounded-md p-6 w-full max-w-xl">
            <h1 className="text-2xl font-medium">{t('pairing-is-not-confirmed.title')}</h1>
            <p className="text-gray-500 mb-2">{t("pairing-is-not-confirmed.info")}</p>
            <hr className="my-3" />
            {!result && draftPairing &&
                <>
                    {
                        isInGroup && <Button href={`/students/${otherPairing?.student?.ni}`} className="w-full" target="_blank">
                            <GroupInfo className="w-full p-3 px-3">{t("pairing-is-in-group-info",{student:otherPairing?.student?.name})}</GroupInfo>
                        </Button>
                    }
                    <div className="flex w-full space-x-2">
                        <InfoContainer containerClassName="flex-1">
                            <Info.Field value={draftPairing.student.name} label={t("student")} />
                        </InfoContainer>
                        <InfoContainer containerClassName="flex-1">
                            <Info.Field value={draftPairing.tutor.name} label={t("tutor")} />
                        </InfoContainer>
                    </div>
                    <InfoContainer modify={true} className="space-y-0">
                        <ModifiableSelectField
                            Field={CheckBox}
                            label={t("student-has-confirmed-pairing")}
                            modify={true}
                            yesNo={true}
                            marker="select"
                            value={draftPairing.studentConfirmed}
                            setValue={(studentConfirmed) => setDraftPairing(d => ({ ...d, studentConfirmed }))} />
                        <ModifiableSelectField
                            Field={CheckBox}
                            label={t("tutor-has-confirmed-pairing")}
                            modify={true}
                            yesNo={true}
                            marker="select"
                            value={draftPairing.tutorConfirmed}
                            setValue={(tutorConfirmed) => setDraftPairing(d => ({ ...d, tutorConfirmed }))} />
                        <ModifiableSelectField
                            Field={SearchUser}
                            label={t("assigned-coordo")}
                            modify={true}
                            value={draftPairing.assigned_user || pairing.assigned_user}
                            setValue={(assignedUser) => { setDraftPairing(d => ({ ...d, assigned_user: assignedUser })) }}
                            subLabel={
                                otherPairing && <GroupInfo>{otherPairing?.assignedUser?.name || 'Aucun'}</GroupInfo>
                            }
                        />
                        <ModifiableSelectField
                            Field={SelectDate}
                            label={t("started-at")}
                            canRemoveSelection={false}
                            modify={true}
                            value={draftPairing.startedAt && dateParse(pairing.started_at)}
                            setValue={(startedAt) => setDraftPairing(d => ({ ...d, startedAt }))} />
                        <ModifiableSelectField
                            Field={SelectCronPeriod}
                            label={t("weekly-period")}
                            subLabel={
                                <div className="flex flex-col gap-1">
                                    <div>
                                        {t("shared") + ((draftPairing.common_availability || []).length === 0 ? 'Aucune disponibilité commune' : (draftPairing.common_availability || []).map(s => `${cronsIntervalToString([s.from_cron, s.to_cron]).trim()}`).join(', '))}
                                    </div>
                                    {
                                        otherPairing && otherPairing?.period && <GroupInfo>
                                            {cronToString(otherPairing?.period)}
                                        </GroupInfo>
                                    }
                                </div>
                            }
                            modify={true}
                            value={draftPairing.period}
                            setValue={(period) => setDraftPairing(d => ({ ...d, period }))}
                        />
                        <ModifiableSelectField
                            Field={SelectPairingType}
                            label={t("pairing-type")}
                            subLabel={
                                <div className="flex flex-col gap-1">
                                    <div>{t("shared") + ((draftPairing.student.on_site_preference && draftPairing.tutor.on_site_preference) ? t("on-site-preference") :
                                        (draftPairing.student.online_preference && draftPairing.tutor.online_preference) ? t("online-preference") :
                                        (draftPairing.student.hybrid_preference && draftPairing.tutor.hybrid_preference) ? t("hybrid-preference") :
                                        t("none")
                                    )}
                                    </div>
                                    {
                                        otherPairing && otherPairing?.preferedType && <GroupInfo>{t(otherPairing?.preferedType+"-preference")}</GroupInfo>
                                    }
                                </div>
                            }
                            removeHybrid={false}
                            modify={true}
                            value={draftPairing.preferedType || null}
                            setValue={(preferedType) => setDraftPairing(d => ({ ...d, preferedType }))} />
                        {/* Schools subjects */}
                        <ModifiableSelectField
                            optionsClassName="z-10"
                            Field={SelectSchoolSubjectsDropdown}
                            label={t("pairing-subjects")}
                            subLabel={
                                <div className="flex flex-col gap-1">
                                    <div>{draftPairing?.common_subjects?.length > 0 ? t("shared") + (draftPairing.common_subjects || []).map(s => `${s.name}`).join(', ') : t("shared") + t("none")}</div>
                                    {
                                        otherPairing && otherPairing?.subjects && <GroupInfo>{otherPairing?.subjects.map(s => s.name).join(', ')}</GroupInfo>
                                    }
                                </div>
                            }
                            modify={true}
                            block={true}
                            noBorder={true}
                            value={draftPairing.subjects || []}
                            setValue={(subjects) => setDraftPairing(d => ({ ...d, subjects }))} />
                        {draftPairing.subjects && draftPairing.subjects.length > 2 && <RawTexError>{t("pairing-subjects-limit")}</RawTexError>}
                    </InfoContainer>
                    <Button loading={loading} onClick={execute} block color="active" className="mt-5" size="lg"
                        disabled={!draftPairing.preferedType || !draftPairing.period || !draftPairing.startedAt || (draftPairing.subjects && draftPairing.subjects.length > 2)}>
                        {t("confirm-pairing.button")}
                    </Button>
                    {error ? <Error.Text {...error} /> : null}
                </>}
            {result &&
                <div>
                    <p>{t("pairing-created")}</p>
                </div>
            }
        </div>

        <div className="w-full max-w-xl mt-5 z-0">
            <DeleteButton id={draftPairing.id} />
        </div>
    </div>
}