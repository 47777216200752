import React from 'react';
import classnames from 'classnames';

// Components
import { Button } from 'components/core/button';
import { Toggle } from 'components/core/toggle';
import { Fragment } from 'react';
import { FiCalendar, FiCheck, FiLock, FiUnlock, FiX } from 'react-icons/fi';

// Hooks
import { useState } from 'react';
import { SelectDate } from './select_date';
import { SelectBool } from './select_bool';

export function CheckBox({value, 
                          setValue, 
                          name, 
                          description, 
                          blurred, 
                          disabled, 
                          marker="toggle", 
                          className, 
                          yesNo,
                          onlyIcon,
                          orientation="right", 
                          canRemoveSelection,
                          showCheckIfTrue=false, 
                          showXIfFalse=false}){
  const [locked, setLocked] = useState(true);
  return <div className={classnames("py-2 flex items-center cursor-pointer border-0", className)}>
              <div className={classnames("flex items-center justify-center", orientation==="right" &&"order-last", orientation==="left"&& "mr-3")}>
                {showCheckIfTrue && value && <FiCheck className="text-green-500 h-6 w-6 mr-2"/>}
                {showXIfFalse && !value && <FiX className="text-red-500 h-6 w-6 mr-2"/>}
                {blurred && !locked&&
                <Button size="toolbarIcon" className="mr-3" color="gray" onClick={()=>setLocked(true)}><FiUnlock/></Button>}
                {blurred && locked?
                <Button size="toolbarIcon" color="gray" onClick={()=>setLocked(false)}><FiLock/></Button>:
                <Fragment>
                  {marker==="toggle" && <Toggle disabled={disabled} value={value} setValue={setValue}/>}
                  {marker==="checkbox" && <input disabled={disabled} type={"checkbox"} id={name} name={name} checked={value}/>}
                  {marker==="date" && <SelectDate disabled={disabled} canRemoveSelection={canRemoveSelection} value={value} setValue={setValue} onlyIcon={onlyIcon? <FiCalendar/>: null}/>}
                  {marker==="select" && <SelectBool disabled={disabled} yesNo={yesNo} value={value} setValue={(d)=>setValue(d.value==="oui")}/>}
                </Fragment>}
                 
              </div>
               <div className="flex-1">
                <div className="text-medium flex items-center">{name}</div>
                {description && <div className="text-gray-500">{description}</div>}
              </div>
          </div>
} 