import React from 'react';

// Components
import { GameTimeNavigator } from 'components/events/game_time';

// Hooks
import { useContext } from 'react';

// Contexts
import {TeamContext} from 'contexts/team';

export default function PlayTime(){
  const {team} = useContext(TeamContext);
  return <GameTimeNavigator team={team.slug}/>
}

// const route =  { path: "play-time/:eventId", 
//                  requireAuth: true, 
//                  Element: PlayTime };

// export const alternateRoute =  { path: "play-time/", 
//                  requireAuth: true, 
//                  Element: PlayTime };
// export default route;
