import React from 'react';

// Components
import { FiList } from 'react-icons/fi';
import { PlayTimeRulesList } from 'components/play-time-rules/list';

// Hooks
import { useTranslation } from 'react-i18next';
import { InfoSectionHeading } from 'components/core/typo';

function SettingsPlayTimesRules(){
  const {t} = useTranslation("common");
  
  return <div className="h-full min-h-screen md:min-h-0 "> 
            <InfoSectionHeading 
            title={t('play-time-rules')}
            longDescription={t("play-time-rules-info")}/>
            <PlayTimeRulesList/> 
          </div>
}
const route =  { path: "play-time-rules", 
                 name: "Règles de temps de jeu" , 
                 group: "data",
                 Icon: FiList,
                 requireAuth: true, 
                 scopes: ["Direction", "RST", "Responsable", "Développeur"],
                 Element: SettingsPlayTimesRules };
export default route;
