import React from 'react';

// Utils
import { locale } from 'utils/locale';

// Hooks
import { useTranslation } from 'react-i18next';

const dayFormat = locale.format("%a %d %B %Y");
export function DocumentCard({name, group, period, handed_over_at, fully_signed, comments, uploaded_at}){
  const { t } = useTranslation("common");
  return <div className="p-1">
          <div className="flex items-end w-full">
            <div className="w-full">
              <div className="flex items-center justify-between ">
                {group && <p className="font-medium ">{group.name} {period && period.name}</p>}
                <span className="h-4 w-4 ml-2 flex-shrink-0 rounded-md inline-block " style={{background:group.color}}/>
              </div>
              <div className="flex items-center justify-between ">
                {name && <span className="text-gray-400 font-base text-xs uppercase flex items-center">{name}</span>}
              </div>
            </div>
            </div>
            {handed_over_at?
              <p>{t("handed-over-at")+" "}<span className="font-bold">{dayFormat(new Date(handed_over_at))}</span></p>:
              <p className="text-red-500">{t("not-handed-over")}</p>
            }
            {uploaded_at?
              <p>{t("uploaded-at")+" "}<span className="font-bold">{dayFormat(new Date(uploaded_at))}</span></p>:
              <p className="text-red-500">{t("not-uploaded")}</p>
            }
            {!fully_signed && <p className="text-red-500">{t("not-fully-signed")}</p>
            }
            {comments && <p className="text-gray-500">{comments}</p>
            }
          </div>
}