/*
  This list is intended for managing the students, not for selection.
*/

import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useContext } from 'react';
import { useSearch } from 'hooks/useSearch';

// Utils
import { locale, dateParse } from 'utils/locale';

// Contexts
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error, InfoSectionHeading } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';

// API
import { searchStudents } from 'api/students';


export function ControlledStudentsList({students, setPage, paging, error}){
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { t } = useTranslation('common');
  const headers = useMemo(()=>[
    {
     title: t("name"),
     field: (d=>d? d.name : "name"),
     itemClassName: "!py-2 whitespace-nowrap"
    },
    {
     title: t("school"),
     field: (d=>d? d.school.name : "school"),
     itemClassName: "text-gray-500"
    },
    {
     title: t("active-since"),
     field: (d=>d? (d.started_using_service && locale.format("%d %B %Y")(new Date(dateParse(d.started_using_service)))) : "started_using_service"),
     itemClassName: "text-gray-500"
    }
  ], [students])

  if (!students){
    return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2"/>
  }

  return <Info.Container modify={true} >
          <InfoSectionHeading mediumTitle={t("active-students.title")} longDescription={t("active-students.gonna-be-deactivated")}/>
            {students.length===0?
              <EmptyState
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-students-title")}/>
              :
              <Table headers={headers}
                    data={students}
                    indexingKey="ni"
                    order={{by:filters.orderBy, direction:filters.orderDirection}}
                    onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
              }
              {paging&& <Paginate setPage={setPage} {...paging}/>}
              {error? <Error.Text {...error}/>: null}
          </Info.Container>
}

function StudentsListWithContext(){
  const { filters } = useContext(SearchContext);
  const [students, {loading, error, paging, setPage}] = useSearch(searchStudents, filters);
  return <ControlledStudentsList students={students}
                              setPage={setPage}
                              loading={loading}
                              error={error}
                              paging={paging} />
}

export function StudentsList(){
  return <SearchContextProvider doNotStore defaultState={{orderBy:"name", orderDirection: "asc", serviceIsEnded: false}}>
            <StudentsListWithContext/>
        </SearchContextProvider>
}