import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Container } from 'components/core/container';
import { Tabs } from 'components/core/tabs';
import { Button } from 'components/core/button';
import { FiArrowLeft } from 'react-icons/fi';
import { TextWarningStructured } from 'components/core/typo';


// Hooks
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, generatePath, matchPath } from 'react-router-dom';

// Contexts
import { CoachContextProvider, CoachContext } from 'contexts/coach';
import { locale, dateParse } from 'utils/locale';

function Header(){
  const { t } = useTranslation("common");
  const { coach } = useContext(CoachContext);
  if (!coach.name){
    return <Container className="py-6">
              <Skeleton className="w-screen max-w-sm h-12" />
            </Container>
  }
  return <Container className="py-3 ">
          <Button href="/coachs" color="inlineLink"  className="md:hidden"><FiArrowLeft className="mr-1"/>{t("coachs")}</Button>
          <h1 className="font-medium text-2xl">{coach.name}</h1>
          </Container>
}

function CoachTabs(){
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation("common");
  const tabs = useMemo(()=>[
      {
        name: t("coach-nav.overview"),
        href: generatePath("/coachs/:ni", params),
        current: matchPath("/coachs/:ni/", location.pathname),
      },
      {
        name: t("coach-nav.settings"),
        href: generatePath("/coachs/:ni/settings/", params),
        current: matchPath("/coachs/:ni/settings/", location.pathname),
      },
    ], [location])
  return <Container className="border-b">
          <Tabs tabs={tabs}/>
          </Container>
}
function CoachIsClosedCard(){
  const { t } = useTranslation("common");
  const { coach } = useContext(CoachContext);
  if (coach && coach.service_ended_at && new Date(coach.service_ended_at) < new Date()){
    return <Container className="py-2">
            <TextWarningStructured title={t("coach-has-left-service.title")} description={t("coach-has-left-service.description")}/>
            </Container>
  }
  if (coach && coach.service_ended_at && new Date(coach.service_ended_at) > new Date()){
    return <Container className="py-2">
            <TextWarningStructured
            title={t("coach-will-left-service.title")}
            description={t("coach-will-left-service.description", {date: locale.format("%d %B")(dateParse(coach.service_ended_at))})}/>
            </Container>
  }
  return null
}

export function CoachLayout({ children }){
  return <CoachContextProvider>
          <Header/>
          <CoachTabs/>
          <CoachIsClosedCard/>
          { children }
         </CoachContextProvider>
}