import React from 'react';
import classnames from 'classnames';

// Components
import { DashboardLayout, MultiColumn } from 'components/core/layouts';
import { FiDribbble } from 'react-icons/fi';
import { TeamsList } from 'components/teams/side_list'; 
import { FilterBar } from 'components/tools/filter_bar';
import { SectionHeading } from 'components/core/typo';
import { Outlet } from "react-router-dom";

// Contexts
import { SearchContextProvider } from 'contexts/search';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

// Utils
import { getFullCurrentPeriod } from 'utils/period';

export function TeamListPage(){
  const { slug } = useParams();
  const { t } = useTranslation("common");
  const defaultFields = useMemo(()=>({period: getFullCurrentPeriod()}), []); 
  return <SearchContextProvider storageKey={"/teams"} defaultState={{orderBy: "name", orderDirection: "asc"}} defaultFields={defaultFields}>
          <DashboardLayout contentClassName={"overflow-hidden"}>
            <SectionHeading title={t("teams")} className={classnames(slug && "hidden md:block")}>
            <FilterBar sport school period/>
            </SectionHeading>
            <MultiColumn>
              <MultiColumn.Left className={classnames("border-r", slug && "hidden md:block")}> 
                <TeamsList/>
              </MultiColumn.Left>
              <MultiColumn.Content  className={classnames(!slug && "hidden md:block")}>
                <Outlet/>
              </MultiColumn.Content>
            </MultiColumn>
           </DashboardLayout>
         </SearchContextProvider>
}


const route =  { path: "/teams", 
                 name: "Équipes" , 
                 Icon: FiDribbble,
                 showInNavBar: true, 
                 requireAuth: true, 
                 Element: TeamListPage };
export default route;
