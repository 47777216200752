import React from 'react';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import { Button } from 'components/core/button';

export function Error401({scopes}){
  return <CenterViewLayout>
          <h1 className="text-xl font-medium">Erreur de type 401</h1>
          <p className="mb-4 text-gray-500">{`Vous n'êtes pas autorisé à accéder à cette ressource. ${scopes?`Cette ressource n'est accessible que pour les membres aux accès suivants: ${scopes.join(', ')}.` : ""}`}</p>
          {!scopes && <Button block color="gray" size="md" href="/login">Retour au login</Button>}
         </CenterViewLayout>
}