import React from 'react';

// Components
// import { InviteMember } from 'components/settings/members/invite_member';
import { MembersList } from 'components/settings/members/list';
import { FiKey } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next'; 

function SettingsMembers(){
  const {t} = useTranslation("common");
  
  return <div className="h-full min-h-screen md:min-h-0 "> 
            <h1 className="info-section">{t('users')}</h1>
              <MembersList/>
          </div>
}
const route =  { path: "members", 
                 name: "Utilisateurs" , 
                 group: "users",
                 Icon: FiKey,
                 requireAuth: true, 
                 scopes: ["Direction", "Développeur"],
                 Element: SettingsMembers };
export default route;
