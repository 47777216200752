import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { ExportView } from 'components/exports/base';
import { FiDownload } from 'react-icons/fi';

export function ExportPage(){
  return <DashboardLayout>
            <ExportView/>
         </DashboardLayout>
}

const route =  { path: "/export/students", 
                 name: "Exportation",
                 Icon: FiDownload,
                 group: 'outils',
                 showInNavBar: true,
                 requireAuth: true, 
                 Element: ExportPage };
export default route;
