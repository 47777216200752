/* eslint-disable */
import React, { useState, useContext, useMemo, useEffect } from 'react';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useDebounce } from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';

// Utils
import classnames from 'classnames';
import { orderWeekdays } from 'utils/locale';

// API
import { searchTutors } from 'api/tutors';

// Components
import { Command } from 'components/core/command';
import { Error } from 'components/core/typo';
import { SearchContextProvider, SearchContext } from 'contexts/search';
import { FilterBar } from 'components/tools/filter_bar';
import { Link } from 'react-router-dom';
import { Skeleton } from 'components/core/skeleton';
import { PreferenceCell } from 'components/student/preferences';

function TutorOption({ tutor, active, selected }) {

    const availabilities = useMemo(() => {
        const weekdays = tutor.schedule_availabilities.map(s => `${s.from.split("à")[0]}`.replace(" ", ""));
        // Get uniques
        const uniqueWeekDays = [...new Set(weekdays)];
        // Sort weekdays
        const sortedWeekDays = orderWeekdays(uniqueWeekDays)
        return sortedWeekDays.join(', ');

    }, [tutor]);

    return <>
        <Link to={`/tutors/${tutor?.ni}`} className='grid grid-cols-4 gap-x-2 w-full'>
            <div className='col-span-1'>
                <span className={classnames(selected && "font-semibold")}>{tutor.name}</span>
                <span className={classnames(!active && 'opacity-80')}><PreferenceCell {...tutor} /></span>
            </div>
            <div className=' col-span-1'>
                <p className={classnames(!active && 'opacity-80')}>
                    {(tutor.schools || []).filter(e => e.description).map(e => `${e.short_name}`).join(', ')}
                </p>
            </div>
            <div className='col-span-1'>
                <p className={classnames(!active && 'opacity-80')}>{availabilities}</p>
            </div>
            <div className='col-span-1'>
                <p className={classnames(!active && 'opacity-80')}>
                    {(tutor.subjects || []).filter(e => e.description).map(e => `${e.name}`).join(', ')}
                </p>
            </div>
        </Link>
    </>
}


export function SearchTutorsCommandWithContext({ open, setOpen }) {
    const { t } = useTranslation("common");
    const [query, setQuery] = useState("");
    const { filters } = useContext(SearchContext);
    const debounceQuery = useDebounce(query, 500);
    const params = useMemo(() => ({ search: query, ...filters }), [debounceQuery, filters]);
    const fields = useMemo(() => (["ni",
        "name",
        "schedule_availabilities",
        "active_since",
        "subjects",
        "schools",
        "online_preference",
        "on_site_preference",
        "hybrid_preference"]), []
    );
    const validateParams = useMemo(() => ()=> {
        return !!open;
    }, [open]);
    const [tutors, { loading, error, paging }] = useSearch(searchTutors, params, { limit: 1000, fields, validateParams });
    useEffect(() => {
        if (!open) {
            setQuery("");
        }
    }, [open]);
    return <>
        <Command.Root open={open} setOpen={setOpen} size="7xl">
            <Command.Search
                setQuery={setQuery}
                loading={loading}
                placeholder={t("search-tutors.placeholder")}
                multiple by={(a, b) => a.ni === b.ni}>
                {error ? <Error.Text {...error} /> : null}
                <FilterBar period paired availableForPairing activeSinceFrom activeSinceTo color="default" />

                <Command.Options static className="text-sm text-gray-800 " size="lg">
                    {/* No results */}
                    {!loading && (tutors || []).length === 0 && (
                        <p className="p-4 text-sm text-gray-500">{t("search-tutors.no-results")}</p>
                    )}
                    {
                        loading && <>
                            <Skeleton className="h-44" />
                        </>
                    }
                    {/* Show results */}
                    {!loading && (tutors || []).map((tutor) => (
                        <Command.Option key={tutor.ni} value={tutor}>
                            {({ active, selected }) => (
                                <TutorOption tutor={tutor} active={active} selected={selected} />
                            )}
                        </Command.Option>
                    ))}
                </Command.Options>
                <Command.Help>{t("search-tutors.info", {count:paging?.numResults})}</Command.Help>
            </Command.Search>
        </Command.Root>
    </>
}

export function SearchTutorsCommand({ ...props }) {
    return <SearchContextProvider doNotStore={true}>
        <SearchTutorsCommandWithContext {...props} />
    </SearchContextProvider>
}