import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// Components
import { FaHashtag as HashtagIcon } from 'react-icons/fa';
import { CheckBox } from 'components/tools/checkbox';
import { SelectSport } from 'components/tools/sports_list';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { TextInput } from 'components/core/inputs';
import { InfoSectionHeading } from 'components/core/typo';

// Icons
import { FaSun as BestIcon } from "react-icons/fa";
import { FaCloudRain as WorstIcon } from "react-icons/fa";
import classNames from 'classnames';

// Utils
import { orderByValues, SelectOrderBy } from 'components/tournament-rules/tournament-rules';

export function RuleMaker({ nbWeeks, nbGames, weeks, setWeeks, orderWeeksBy, highlightBest, highlightWorst, disabled = false }) {
    const { t } = useTranslation("common");
    const games = [...Array(nbGames || 0).keys()];

    const checkLeftSelected = (index) => {
        return weeks[index].game.length > 0 && weeks[index - 1] && !weeks[index - 1].game.length > 0
    }
    const checkRightSelected = (index) => {
        return weeks[index].game.length > 0 && weeks[index + 1] && !weeks[index + 1].game.length > 0
    }
    const checkIsLastSelected = (index) => {
        return weeks[index].game.length > 0 && index == weeks.length - 1
    }
    const checkIsFirstSelected = (index) => {
        return weeks[index].game.length > 0 && index == 0
    }

    const handleSelectWeek = (index, game, e) => {
        if (disabled) return;
        if (e && !e.buttons) return;

        if (weeks[index].game.includes(game)) {
            weeks[index].game = weeks[index].game.filter((item) => item != game);
        }
        else {
            weeks[index].game.push(game);
        }
        setWeeks([...weeks]);
    };

    return <div>

        {<div className='flex items-center w-full pb-2 pl-4 my-3 text-center border-b'><HashtagIcon className='mr-[45px] text-[14px]' />{t("tournament-rules.weeks")}</div>}
        <div className="flex items-center justify-start w-full overflow-x-auto">
            <div className="w-full">
                <div className={classNames("flex flex-col items-start")}>
                    {(!nbWeeks || !nbGames || nbWeeks == "" || nbGames == "") && <div className="w-full my-2 text-center text-gray-500 ">{t("tournament-rules.no-selection")}</div>}
                    <div className={classNames('w-full transition-all duration-200', nbWeeks == "" || nbGames == "" ? "opacity-0" : "opacity-100")}>
                        {games.map((game, j) =>
                            nbWeeks != "" && <div key={j} className={classNames("flex items-center justify-start w-full min-w-fit mb-1 border rounded-md bg-gray-50")}>
                                {
                                    nbGames && nbWeeks && <span className="block pl-4 mr-8 select-none w-9 w-xl whitespace-nowrap">{j + 1}.</span>
                                }
                                {(weeks.map((week, index) => (

                                    <div key={index} onMouseDown={() => handleSelectWeek(index, game)} onMouseEnter={(e) => handleSelectWeek(index, game, e)} className={`transition-all w-xl my-1 h-9 flex items-center justify-center ${!disabled && "hover:cursor-pointer"} ${week.game.length > 0 ? "bg-sky-500 text-white" : "text-gray-600"} ${week.game.length > 0 && !week.game.includes(game) ? "bg-opacity-20" : ""} ${checkIsFirstSelected(index) || checkLeftSelected(index) ? "rounded-l-xl" : ""} ${checkIsLastSelected(index) || checkRightSelected(index) ? "rounded-r-xl" : ""}`}>
                                        <div className="flex items-center justify-center select-none w-9 h-9">

                                            {(highlightBest || highlightWorst) && (
                                                (index == 0 && highlightBest) && <BestIcon /> ||
                                                (index == 0 && highlightWorst && !highlightBest && orderWeeksBy.slug == "Chronological") && <WorstIcon /> ||
                                                (index == 1 && highlightWorst && highlightBest && orderWeeksBy.slug == "Chronological") && <WorstIcon /> ||
                                                (index == weeks.length - 1 && highlightWorst && orderWeeksBy.slug == "BestToWorst") && <WorstIcon /> ||
                                                <div>{index + 1}</div>
                                            )}
                                            {
                                                !highlightBest && !highlightWorst && <div>{index + 1}</div>
                                            }

                                        </div>
                                    </div>
                                )))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const MAX_WEEKS = 20;
const MAX_GAMES = 20;

export function RuleMakerForm({sport, setSport, description, setDescription, nbWeeks, setNbWeeks, nbGames, setNbGames, weeks, setWeeks, orderWeeksBy, setOrderWeeksBy, highlightBest, setHighlightBest, highlightWorst, setHighlightWorst, setIsValid, loading, state = "create"}) {
    const { t } = useTranslation("common");
    const [reloadWeeks, setReloadWeeks] = useState(false);

    const handleChangeWeek = (value) => {
        if (value > MAX_WEEKS) return;
        setNbWeeks(parseInt(value) || "")
        setReloadWeeks(true);
    }

    const handleChangeGames = (value) => {
        if (value > MAX_GAMES) return;
        setNbGames(parseInt(value) || "")
        setReloadWeeks(true);
    }

    useEffect(() => {
        if (!reloadWeeks) return;
        setWeeks([]);
        if (isNaN(nbWeeks)) return;
        for (let i = 0; i < nbWeeks; i++) {
            let week = {
                game: []
            }
            setWeeks((weeks) => [...weeks, week]);
        }
        setReloadWeeks(false);
    }, [nbWeeks, reloadWeeks])

    useEffect(() => {
        if (sport && description && description.trim() != "" && nbWeeks && nbGames && nbWeeks != "" && nbGames != "") {
            return setIsValid(false)
        }
        return setIsValid(true)
    }, [sport, description, nbWeeks, nbGames])
  
    return <div>
        <InfoSectionHeading className={"mt-2"} subtitle={t("tournament-rules.title-infos")} />
        <div className='-mt-1'>
            <ModifiableSelectField
                disabled={state==="modify"}
                Field={SelectSport}
                disabledClear
                label={t("sport")}
                modify={true}
                value={sport}
                setValue={setSport} noBorder />
        </div>
        <div className='grid grid-cols-2 gap-2 mb-2'>
            <TextInput size="sm" disabled={state==="modify"} onChange={handleChangeWeek} value={nbWeeks} label={t("tournament-rules.weeks")} placeholder={t("tournament-rules.weeks")} />
            <TextInput size="sm" disabled={state==="modify"} onChange={handleChangeGames} value={nbGames} label={t("tournament-rules.matchs")} placeholder={t("tournament-rules.matchs")} />
        </div>
        <div className='mt-4 mb-2'>
            <TextInput description={t("tournament-rules.description-example")} size="sm" onBlur={() => setDescription(description.trim())} onChange={setDescription} value={description} label={t("description")} placeholder={t("description")} />
        </div>
        <InfoSectionHeading className={"mt-4"} subtitle={t("tournament-rules.title-rule")} />
        <div className=''>
            <ModifiableSelectField
                Field={()=> SelectOrderBy({value: orderWeeksBy, setValue:setOrderWeeksBy, values: orderByValues, disabledClear: true})}
                disabledClear
                label={t("tournament-rules.order-by-label")}
                modify={true}
                noBorder 
            />
        </div>
        <div className='mb-2'>
            <ModifiableSelectField
                disabled={loading}
                labelSize={"xl"}
                Field={() => CheckBox({setValue: setHighlightBest, value: highlightBest})}
                label={t("tournament-rules.best-week")}
                subLabel={t("tournament-rules.best-week-description")}
                noBorder
                modify
            />
        </div>
        <div className='mb-4'>
            <ModifiableSelectField
                disabled={loading}
                labelSize={"xl"}
                Field={() => CheckBox({setValue: setHighlightWorst, value: highlightWorst})}
                label={t("tournament-rules.worst-week")}
                subLabel={t("tournament-rules.worst-week-description")}
                noBorder
                modify
            />
        </div>
        
        <RuleMaker
            sport={sport}
            setSport={setSport}
            description={description} setDescription={setDescription}
            nbWeeks={nbWeeks} setNbWeeks={setNbWeeks}
            nbGames={nbGames} setNbGames={setNbGames}
            weeks={weeks} setWeeks={setWeeks}
            orderWeeksBy={orderWeeksBy} setOrderWeeksBy={setOrderWeeksBy}
            highlightBest={highlightBest} setHighlightBest={setHighlightBest}
            highlightWorst={highlightWorst} setHighlightWorst={setHighlightWorst}
            setIsValid={setIsValid}
            loading={loading} 
        />
    </div>
}