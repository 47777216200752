import React, { useEffect } from 'react';

// Hooks
import { useState, useMemo } from 'react';
import { useTranslationRegularIntervalCrons } from 'hooks/dates/crons'
import { useTranslation } from 'react-i18next'; 
import { addOrRemoveItem } from 'utils/array';

// Components
import { Button } from 'components/core/button';
import { Dropdown } from 'components/core/dropdown';
import { Error } from 'components/core/typo'

// Utils
import { format } from 'd3-format';
const days = ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"];

function SelectCronIntervalContent({value, setValue}){

  const parsedValue = useMemo(()=>{
    if (!value) return {weekday: 5, fromMinutes: 0, fromHours: 12, toMinutes: 0, toHours: 14};
    const b = value.from_cron.split(" ");
    const c = value.to_cron.split(" ");
    return {weekDay: parseInt(b[4]),
            fromMinutes: parseInt(b[0]), 
            fromHours: parseInt(b[1]),
            toMinutes: parseInt(c[0]), 
            toHours: parseInt(c[1])};
  }, [value])

  const handleChange = ({fromMinutes, fromHours, toMinutes, toHours, weekday})=>{
    setValue({
      from_cron: `${(fromMinutes!=null? fromMinutes: parsedValue.fromMinutes || 0)} ${(fromHours!=null? fromHours: parsedValue.fromHours || 12)} * * ${(weekday!=null? weekday: parsedValue.weekDay || 5)}`,
      to_cron: `${(toMinutes!=null? toMinutes: parsedValue.toMinutes || 0)} ${(toHours!=null? toHours: parsedValue.toHours || 14)} * * ${(weekday!=null? weekday: parsedValue.weekDay || 5)}`,
    })
  };

  return <div className="w-full max-w-sm mx-auto space-y-3">
          <div className="flex items-center justify-evenly space-x-2">
            {days.map((d,i)=><Button 
                    key={d}
                    onClick={()=>handleChange({weekday: i})}
                    color={parsedValue && i===parsedValue.weekDay? "active": "gray"}
                    block
                    className="uppercase "
                    size="sphere">{d[0]}</Button>)}
          </div>
          <div className="flex items-center p-3 bg-gray-100 rounded-lg justify-between">
            <label>Heures </label>
            <input className="input-time" 
                  type="time" 
                  id="startAt"
                  name="startAt" 
                  min="06:00" 
                  max="22:00" 
                  defaultValue={value &&`${format("02")(parsedValue.fromHours)}:${format("02")(parsedValue.fromMinutes)}`}
                  onChange={(e)=>handleChange({fromMinutes: e.currentTarget.value.split(":")[1], fromHours: e.currentTarget.value.split(":")[0]})} required/>
            <label> à </label>
            <input className="input-time" 
                  type="time" 
                  id="endAt"
                  name="endAt" 
                  min="06:00" 
                  max="22:00" 
                  defaultValue={value &&`${format("02")(parsedValue.toHours)}:${format("02")(parsedValue.toMinutes)}`}
                  onChange={(e)=>handleChange({toMinutes: e.currentTarget.value.split(":")[1], toHours: e.currentTarget.value.split(":")[0]})} required/>
          </div>
      
         </div>
}


function SelectCronIntervalContentMultiple({value, setValue}){

  const [selectedDays, setSelectedDays] = useState([5]);
  const parsedValue = useMemo(()=>{
    if (!value || value.length===0) return [{weekday: 5, fromMinutes: 0, fromHours: 12, toMinutes: 0, toHours: 14}];
    const v = value[0];
    const b = v.from_cron.split(" ");
    const c = v.to_cron.split(" ");
    return {fromMinutes: parseInt(b[0]), 
            fromHours: parseInt(b[1]),
            toMinutes: parseInt(c[0]), 
            toHours: parseInt(c[1])};
  }, [value])

  const handleChange = ({fromMinutes, fromHours, toMinutes, toHours})=>{
    setValue(selectedDays.map(d=>({
      from_cron: `${(fromMinutes!=null? fromMinutes: parsedValue.fromMinutes || 0)} ${(fromHours!=null? fromHours: parsedValue.fromHours || 12)} * * ${(d)}`,
      to_cron: `${(toMinutes!=null? toMinutes: parsedValue.toMinutes || 0)} ${(toHours!=null? toHours: parsedValue.toHours || 14)} * * ${(d)}`,
    })));
  };

  useEffect(()=>{
    handleChange({});
  }, [selectedDays])

  return <div className="w-full max-w-sm mx-auto space-y-3">
          <div className="flex items-center justify-evenly space-x-2">
            {days.map((d,i)=><Button 
                    key={d}
                    onClick={()=>setSelectedDays(addOrRemoveItem(selectedDays, i))}
                    color={selectedDays &&selectedDays.includes(i)? "active": "gray"}
                    block
                    className="uppercase "
                    size="sphere">{d[0]}</Button>)}
          </div>
          <div className="flex items-center p-3 bg-gray-100 rounded-lg justify-between">
            <label>Heures </label>
            <input className="input-time" 
                  type="time" 
                  id="startAt"
                  name="startAt" 
                  min="06:00" 
                  max="22:00" 
                  defaultValue={value &&`${format("02")(parsedValue.fromHours)}:${format("02")(parsedValue.fromMinutes)}`}
                  onChange={(e)=>handleChange({fromMinutes: e.currentTarget.value.split(":")[1], fromHours: e.currentTarget.value.split(":")[0]})} required/>
            <label> à </label>
            <input className="input-time" 
                  type="time" 
                  id="endAt"
                  name="endAt" 
                  min="06:00" 
                  max="22:00" 
                  defaultValue={value &&`${format("02")(parsedValue.toHours)}:${format("02")(parsedValue.toMinutes)}`}
                  onChange={(e)=>handleChange({toMinutes: e.currentTarget.value.split(":")[1], toHours: e.currentTarget.value.split(":")[0]})} required/>
          </div>
      
         </div>
}


export function SelectCronInterval({value, setValue}){
  const label = useTranslationRegularIntervalCrons(value);

  return <Dropdown label={label.baseStr}>
          <div className="p-3">
            <SelectCronIntervalContent value={value} setValue={setValue}/>
          </div>
         </Dropdown>
}

export function SelectCronIntervalWithConfirm({onConfirmation}){
  const [value, setValue] = useState([{from_cron: '0 12 * * 3', to_cron: '0 14 * * 3'}])

  const isInverse = useMemo(()=>{
    if (!value || value.length==0) return false;
    const b = value[0].from_cron.split(" ");
    const c = value[0].to_cron.split(" ");
    if ((parseInt(b[1])*60+ parseInt(b[0]))>(parseInt(c[1])*60+ parseInt(c[0]))) return true;
    return false;
  }, [value]);

  const {t} = useTranslation("common");
  const label = useTranslationRegularIntervalCrons(value);
  return <Dropdown label={t("add-interval")}>
          <div className="p-3">
            <SelectCronIntervalContentMultiple value={value} setValue={setValue}/>
            {isInverse && <Error.Text className="mt-2" code={"inverse-interval-error"}/>}

            <Button 
              className="mt-2"
                 block 
                 disabled={isInverse}
                 size="md" 
                 color="black" onClick={()=>{onConfirmation(value);}}>{t("add")} {label.map(d=>d.baseStr).join(", ")}</Button>
          </div>
         </Dropdown>
}