import React from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiCalendar } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';

export function SelectAddNoteDay({ value, setValue, disabled, ...props }){

  const { t } = useTranslation("common");
  const values = ["Jour de la pratique", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]
  return <ListSelection value={value}
                        setValue={setValue}
                        disabled={disabled}
                        values={values}
                        label={t('add-note-day')}
                        Icon={FiCalendar}
                        optionsClassName={"w-48 whitespace-nowrap"}
                        getKey={(d)=>d}
                        format={(d)=>d}
                        withClearSelection={true}
                        {...props}
                        />
}