import React from 'react';
import classnames from 'classnames';

// API
import { searchStudyCodes } from 'api/utils';

// Components
import { Skeleton } from 'components/core/skeleton';
import { SearchField } from 'components/tools/search_field'; 

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';

export function SelectStudyCode({ value, setValue }){
  const [codes] = useSearch(searchStudyCodes);
  if (!codes){
    return <Skeleton className="h-8"/>;
  }
  return <div className="grid grid-cols-1 md:grid-cols-2  gap-3 md:gap-3">
            {codes.map(d=>
              <div key={d.code} 
                className={classnames("rounded-md col-span-1 p-2 py-2.5 flex items-center cursor-pointer border-0",
                  value && value.code===d.code? "text-white": " bg-gray-50 border-transparent")}
                style={{background: d.color+ (value && value.code===d.code?"FF": "20"), color: (value && value.code===d.code?'white':d.color)}}
                 onClick={()=>setValue(d)}>
                <div className="w-6 text-center" >
                  <input type="radio" id={d.code} name={"code"} checked={(value && value.code===d.code) || false} readOnly/>
                </div>
              <div className="flex-1 ml-3">
                <div className="text-medium flex items-center">{d.name}</div>
              </div>
            </div>)}
         </div>

}

export function SearchStudyCode({value, setValue, disabled, color, size, ...props}){
  const { t } = useTranslation('common');
  const [params, setParams] = useState();
  const [codes, {loading}] = useSearch(searchStudyCodes, params)

  return <SearchField values={codes}
                      loading={loading}
                      value={value}
                      setValue={setValue}
                      size={size}
                      disabled={disabled}
                      color={color}
                      placeholder={t("study-codes")}
                      indexingField={"code"}
                      formatSelectedValue={(d=>d.name || "")}
                      formatSearchResult={((d, {selected, active})=>
                        <div className="flex items-center space-x-2">
                          <div className="w-3 h-3 rounded ring-4 ring-white" style={{background: d.color}}/>
                          <span className={classnames('flex-1 block truncate', (active || selected) && 'font-semibold')}>{d.name}</span>
                        </div>)}
                     onParamsChange={setParams}
                     {...props}
  />
}
