import React from 'react'

/*
  Check if there is some warning concerning the 
  length of the study sessions.
*/

// Utils
// import classnames from 'classnames';
import { locale } from 'utils/locale';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useMemo, useEffect } from 'react';
import { useSchoolYear } from 'hooks/dates/useSchoolYear';
import { useTranslation } from 'react-i18next';
// API
import { searchNotes } from 'api/notes';
import { EmptyState } from 'components/core/empty';


export function CheckSessionWithIncorrectSubjects({student, setStudySessionDoesCount}){
  const { t } = useTranslation("common");
  const windowDates = useSchoolYear();
  const filters = useMemo(()=>({code: "les-sujets-etudies-ne-sont-pas-en-echec", student: student && student.ni, ...windowDates}), [student]);
  const [notes] = useSearch(searchNotes, filters);

  useEffect(()=>{
    if (!notes) return;
    if (notes.length>=3){
      setStudySessionDoesCount(false);
    }
  }, [notes])

  if (!notes) return <div/>

  return <div className="py-2">
            {notes.length===0 && 
              <EmptyState 
                className="w-full mx-auto" 
                color="gray" 
                description={t("empty-state.no-notes-about-incorrect-subjects", {from: locale.format("%d %B %Y")(windowDates.fromDate), to: locale.format("%d %B %Y")(windowDates.toDate)})}/>}
            {notes.length===1 && 
              <EmptyState 
                className="w-full mx-auto" 
                color="warning" 
                description={t("empty-state.one-note-about-incorrect-subjects", {from: locale.format("%d %B %Y")(windowDates.fromDate), to: locale.format("%d %B %Y")(windowDates.toDate)})}/>}
            {notes.length===2 && 
              <EmptyState 
                className="w-full mx-auto" 
                color="warning" 
                description={t("empty-state.two-notes-about-incorrect-subjects", {from: locale.format("%d %B %Y")(windowDates.fromDate), to: locale.format("%d %B %Y")(windowDates.toDate)})}/>}
            {notes.length>2 && 
              <EmptyState 
                className="w-full mx-auto" 
                color="warning" 
                description={t("empty-state.more-than-two-notes-about-incorrect-subjects", {count: notes.length, from: locale.format("%d %B %Y")(windowDates.fromDate), to: locale.format("%d %B %Y")(windowDates.toDate)})}/>}
            
        </div>
}

