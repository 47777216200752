import React from 'react';

// Components
import { LoginForm } from 'components/login';

export function LoginPage(){
  return <div className="min-h-full w-screen flex">
          <div className="flex-1 bg-white flex flex-col justify-center py-12 px-0 sm:flex-none w-screen max-w-xl">
            <div className=" mx-auto w-full max-w-md px-6">
              <LoginForm/>
            </div>
          </div>
          <div className="hidden sm:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full min-h-full w-full object-cover"
            src="/assets/login-background.jpg"
            style={{objectFit: 'cover'}}
            alt=""
          />
        </div>
        </div>
}

const route =  { path: "/login", 
                 name: "Login" , 
                 showInNavBar: false, 
                 requireAuth: false, 
                 Element: LoginPage };
export default route;
