import React, { Fragment } from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Info } from 'components/core/info';
import { Button } from 'components/core/button';
import { Paginate } from 'components/core/paginate';

// Hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch'

// API
import { searchTeamMembers } from 'api/teams';

export function TeamMemberList({ team }){
  const params = useMemo(()=>({team: team && team.slug}), [team])
  const [members, {paging}] = useSearch(searchTeamMembers, params)
  const { t } = useTranslation('common');

  if (!members || !team.name){
    return <Skeleton.List numElements={5} itemClassName="h-12" className="space-y-3"/>
  }
  return <div className=""> 
              <Info.Container label={t('team-members')} 
                        sideHeader={<Button size="xs" color="gray"  href={`/teams/${team && team.slug}/settings/members`}>{t("modify")}</Button>}>
                   {members && members.length>0?
                    <Fragment>
                    {members.map(val=>
                      <Info.Field key={val.id}
                                  value={val.student.name} 
                                  to={`/students/${val.student.ni}`}
                                  label={val.description}
                                  />)}
                    </Fragment>:<Info.Field noValueLabel={t('empty-state.no-teams-title')} />}
              </Info.Container>
                    {paging&& <Paginate {...paging}/>}
            
         </div>
}
