import React from 'react';

// Components
import { DailyEventsWeekView } from 'components/calendar/daily_events_week';

// Hooks
import { useState, useEffect, useCallback } from 'react';

// Utils
import { timeMonday } from 'd3-time';

export function DailyEventsView({date, onDateChange, ...props}){
  const [leftDate, setLeftDate] = useState(date || new Date());

  const handleDateChange = useCallback((date)=>{
    setLeftDate(timeMonday.floor(date));
  }, []);

  useEffect(()=>{
    if (onDateChange) onDateChange(leftDate);
  }, [leftDate])

  return <DailyEventsWeekView date={leftDate} 
                              setDate={handleDateChange}
                              onSelectDate={(d)=>{onDateChange(d); setLeftDate(props.floorToMonday? timeMonday.floor(d): d)}}
                              {...props}
                               />
}
