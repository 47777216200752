import { fetchCall } from 'api';

/*
  Search tournament rules
*/
export const searchTournamentRules = ({sport, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET",
    endpoint: "/tournament-rules",
    query: {sport: sport && sport.slug, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};

/*
  Create play time rules
*/
export const createTournamentRule = ({sport, numWeeks, numGames, gamesToWeeksMap, description, orderWeeksBy, highlightBest, highlightWorst}) => {
  const options = {
    method: "POST",
    endpoint: `/tournament-rules`,
    body: {sport: sport && sport.slug, numWeeks, numGames, gamesToWeeksMap, description, orderWeeksBy, highlightBest, highlightWorst}
  }
  return fetchCall(options)
};

/*
  Update tournament rules
*/
export const updateTournamentRule = ({id, sport, numWeeks, numGames, gamesToWeeksMap, description, orderWeeksBy, highlightBest, highlightWorst}) => {
  const options = {
    method: "POST",
    endpoint: `/tournament-rules/${id}`,
    body: {sport: sport && sport.slug, numWeeks, numGames, gamesToWeeksMap, description, orderWeeksBy, highlightBest, highlightWorst}
  }
  return fetchCall(options)
};

/*
  Delete tournament rule
*/
export const deleteTournamentRule = ({id}) => {
  const options = {
    method: "DELETE",
    endpoint: `/tournament-rules/${id}`
  }
  return fetchCall(options)
};