import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { WeekView } from 'components/tools/week_view';
import { Info } from 'components/core/info';

// Utils
import classnames from 'classnames';
import { locale, dateParse } from 'utils/locale';

// Hooks
import { useTranslation } from 'react-i18next';
import { useAbstractPeriods } from 'hooks/useAbstractPeriods';

export function TutorInfo({ tutor }){
  const { t } = useTranslation('common');
  if (!tutor.name){
    return <Skeleton.List numElements={5}/>
  }
  return <div className={classnames("p-3 space-y-6")}>

            <Info.Container label={"Info"}>
                <Info.Field value={tutor.ni} label={t("ni")}/>
                <Info.Field value={tutor.name} noValueLabel={`${t("none")} ${t("name").toLowerCase()}`}/>
                <Info.Field value={tutor.email} noValueLabel={`${t("none")} ${t("email").toLowerCase()}`}/>
                <Info.Field value={tutor.phone} noValueLabel={`${t("none")} ${t("phone").toLowerCase()}`}/>
            </Info.Container>

            <Info.Container label={"Info"}>
                <Info.Field value={tutor.street} noValueLabel={`${t("none")} ${t("email").toLowerCase()}`}/>
                <Info.Field value={tutor.city} noValueLabel={`${t("none")} ${t("city").toLowerCase()}`}/>
                <Info.Field value={tutor.zipcode} noValueLabel={`${t("none")} ${t("zipcode").toLowerCase()}`}/>
            </Info.Container>

            <Info.Container>
              <Info.Field value={tutor.active_since && locale.format("%d %B %Y")(new Date(dateParse(tutor.active_since)))}
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("active-since").toLowerCase()}`}
                                        label={t("active-since")}
                                        />
              <Info.Field value={tutor.retired_of_service_since && locale.format("%d %B %Y")(new Date(dateParse(tutor.retired_of_service_since)))}
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("retired-of-service").toLowerCase()}`}
                                        label={t("retired-of-service")}
                                        />

            </Info.Container>


            <Info.Container label={t("school-preferences")}>
                {tutor.schools && tutor.schools.filter(d=>d.description!=="none").map((d,i)=>
                  <Info.Field key={i} value={d.name} label={t("availability."+d.description)}/>
                )}
            </Info.Container>

            <Info.Container label={t('school-subjects-preferences')}>
                      {tutor.subjects && tutor.subjects.length>0?
                        tutor.subjects.filter(d=>d.description!=="none").map(val=>
                          <Info.Field key={val.id}
                                      value={val.name}
                                      label={t(`subject-description.${val.description}`)}
                                      />):
                        <Info.Field noValueLabel={`${t("none", {context: 'female'})} ${t("school-subject").toLowerCase()}`} />
                      }
            </Info.Container>


            <Info.Container label={t("active-years")}>
                {tutor.active_periods && tutor.active_periods.map((d,i)=>
                  <Info.Field key={i} value={d.name} label={d.description}/>
                )}
            </Info.Container>


         </div>
}




export function TutorAvailabilities({ tutor }){
  const { t } = useTranslation('common');
  const [date, events] = useAbstractPeriods(tutor.schedule_availabilities);
  if (!tutor.name){
    return <Skeleton.List numElements={5}/>
  }
  return <div className={classnames("mt-12 space-y-6")}>

            <Info.Container label={t("availabilities")}>
                {tutor.schedule_availabilities.map((d,i)=>
                  <Info.Field key={i} value={`${d.from} au ${d.to}`}/>
                )}
            </Info.Container>


            <WeekView abstract date={date} events={events} numTicks={6} minHour={6}/>



         </div>
}

