/* eslint-disable no-unused-vars */
import React from "react";

// Components
import { Pill } from "components/core/pill";
import { Link } from "react-router-dom";
import { Button } from "components/core/button";
import { APITrigger } from "components/core/trigger";
import { TeamContext } from "contexts/team";

// Icons
import { FiPlus as AddIcon } from "react-icons/fi";

// API
import { createNote } from "api/notes";

// Utils
import classNames from "classnames";
import { recursiveCamelize } from "utils/casing";

// Hooks
import { useContext, useMemo, useState } from "react";
import { useStudentsMeetings } from "contexts/student-meetings";

export const STUDENT_SOCIO_AFFECTIF_CODE = "ea-sans-echec-mais-compsocio"

export function SocioAffectifs({ student, socioAffectifs, loading, className }){
    if (loading) return <></>
    return <>
        <div className={classNames("",className)}>
                {!loading && (!socioAffectifs || socioAffectifs?.length === 0) && <AddSocioAffectifs student={student} />}
                {
                    (!loading && socioAffectifs?.length > 0) && <Link to={`?noteId=${socioAffectifs[0]?.id}`}>
                        <Pill.Empty color="blue" className={"h-fit w-fit !py-0.5 !px-1.5"}>
                            Socio
                        </Pill.Empty>
                    </Link>
                }
        </div>
    </>
}

export function AddSocioAffectifs({student}){
    const [loading, setLoading] = useState(false);
    return <>
        <div className="group-hover:opacity-100 opacity-0 transition-all">
            <CreateSocioNoteTrigger student={student} setLoading={setLoading}>
                <Button disabled={loading} size={"icon"} color={"default"} className={"!p-1.5 !text-base"}>
                    <AddIcon />
                </Button>
            </CreateSocioNoteTrigger>
        </div>
    </>
}

export function CreateSocioNoteTrigger({children, student, disabled, setLoading,  ...props}){
    const { team, loading:loadingTeam} = useContext(TeamContext);
    const { setStudents } = useStudentsMeetings();
    const {teamPeriod, start, end} = useMemo(() => {
        const v = recursiveCamelize(team);
        return {start: v?.activePeriod?.start, end: v?.activePeriod?.end, teamPeriod: v?.activePeriod?.slug}
    }, [team])
    const openedAt = useMemo(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const startDate = new Date(start);
        const endDate = new Date(end);
        if (today >= startDate && today <= endDate) {
            return today;
        }
        return startDate;
    }, [teamPeriod, loadingTeam])
    
    const handleCreateNote = (note) => {
        setStudents((students) => {
            const studentIndex = students.findIndex((s) => s.ni === student.ni);
            const studentCopy = {...students[studentIndex]};
            studentCopy.socioAffectifs = [note, ...(studentCopy.socioAffectifs || [])];
            students[studentIndex] = studentCopy;
            return [...students];
        })
    }
    if (loadingTeam) return <></>
    return <>
        <APITrigger 
            method={createNote}
            params={{
                student: student.ni,
                openedAt,
                closedAt: openedAt,
                code: STUDENT_SOCIO_AFFECTIF_CODE
            }}
            options={
                {
                    onResult: handleCreateNote,
                    immediate: false,
                    camelize: true,
                }
            }
            disabled={disabled}
            setLoading={setLoading}
            {...props}
        >
            {children}
        </APITrigger>
    </>
}