/* eslint-disable */
import React, { useMemo, useState, useContext, createContext } from 'react';

// Components
import { CreateNoteInModal } from 'components/notes/create_modal';

// Icons
import { FiCheck } from 'react-icons/fi';

// API
import { toggleCommentCompletion, retrieveNoteCode } from 'api/notes';

// Utils
import classNames from 'classnames';

// Hooks
import { useAPI } from 'hooks/useAPI';
import { Skeleton } from 'components/core/skeleton';

const ActionsCompletionCodeContext = createContext();
const COMPLETION_NOTE_CODE = "action-completee";

export const useActionsCompletionCode = () => {
    return useContext(ActionsCompletionCodeContext);
}

export function ActionsCompletionCodeProvider({ children }) {
    const params = useMemo(() => ({code: COMPLETION_NOTE_CODE}), []);
    const [code,{loading, setResult:setCode, error, setError}] = useAPI(retrieveNoteCode, params);
    return <ActionsCompletionCodeContext.Provider value={{ code, setCode, loading, error, setError }}>
        {children}
    </ActionsCompletionCodeContext.Provider>
}


/**
 * ToggleActionCompletion component handles the toggling of an action's completion status.
 * It optionally opens a modal for adding a completion comment if the action is being marked as completed.
 *
 * @param {Object} props - The properties object.
 * @param {Object} props.action - The action object containing details about the action.
 * @param {Object} props.student - The student object associated with the action.
 * @param {Function} props.setAction - Function to update the action state.
 * @param {Function} props.onToggle - Callback function to be called when the action's completion status is toggled.
 * @param {boolean} props.bypassCompletionComment - Flag to bypass the completion comment modal.
 *
 * @returns {JSX.Element} The rendered component.
 */
export function ToggleActionCompletion({ action, student, setAction, onToggle, bypassCompletionComment }) {
    const [, { execute, loading }] = useAPI(toggleCommentCompletion, { id: action?.id }, { immediate: false, camelize: true });
    const { completed } = action || {};
    const [open, setOpen] = useState(false);
    const { code, loading:loadingCode } = useActionsCompletionCode();
    const handleToggle = () => {
        if (loading) return;
        if (!completed && !bypassCompletionComment) {
            setOpen(true);
        }
        else {
            execute();
            const updatedAction = { ...action, completed: !completed, completedAt: completed ? null : new Date().toISOString() };
            setAction?.(updatedAction);
            onToggle?.(updatedAction);
        }
    }
    const handleNoteCreated = (note) => {
        const newComments = note?.comments.map(comment => ({...comment, noteId: note?.id}));
        const linkedComments = [...(action?.linkedComments || []), ...(newComments || [])];
        execute();
        setAction?.({ 
            ...action, 
            completed: !completed, 
            completedAt: 
            completed ? null : new Date().toISOString(),
            linkedComments
        });
    }
    if (loadingCode) return <Skeleton className="size-5 rounded-full" />;
    return <>
        <div onClick={handleToggle} className={classNames("size-5 cursor-pointer rounded opacity-30 hover:opacity-100 transition-all flex items-center justify-center", completed ? "bg-emerald-500 !opacity-100" : "bg-gray-300")}>
            {
                completed ? <FiCheck className="text-white size-4" /> :
                    <FiCheck className="text-gray-400 transition-all size-4" />
            }
        </div>
        <CreateNoteInModal 
            open={open} 
            setOpen={setOpen} 
            creatorProps={
                {
                    defaultActionsToLink:[action?.id], 
                    defaultCode: code,
                    disableCode: true,
                    defaultStudent: student,
                    disableStudent: true,
                    onResult: handleNoteCreated
                }
            }/>
    </>
}