import React, { useCallback, useMemo } from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { SearchUser } from 'components/tools/search_user';
import { SearchTeams } from 'components/tools/search_teams';
import { ModifiableSelectField, SingleLineField} from 'components/tools/modifiable_fields';
import { Info } from 'components/core/info';
import { Button } from 'components/core/button';
import { Error } from 'components/core/typo'
import { FiX } from 'react-icons/fi';

// Utils
import { group } from 'd3-array';

// Hooks
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// API
import { updatePreset, deletePreset, toggleTeamFromPreset } from 'api/presets';
import { SelectBool } from 'components/tools/select_bool';

function DeleteButton({value, onDelete}){
  const { t } = useTranslation('common');
  const [, {execute, loading, error}] = useAPI(deletePreset, 
                                            {id: value.id}, 
                                            {onResult: (()=>onDelete(value)), immediate:false})

  return <div className="border-2 border-red-500 rounded-lg p-3 w-full space-y-3">
          <div>
            <h3 className="font-medium text-red-600">{t("delete-preset.title")}</h3>
            <p className="text-gray-500">{t("delete-preset.description")}</p>
          </div>
          <Button loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-preset.button")}</Button>
          {error? <Error.Text {...error}/>: null}
         </div>
}

export function TeamBadge({ children, onRemove }) {
  return <div className={`flex justify-between group rounded-md  py-2`}>
    <p className=''>{children}</p>
    <Button onClick={onRemove} size="smToolbarIcon" color="delete"><FiX/></Button>
  </div>
}

export function ModifyPreset({value, setValue, onDelete}){
  const {t} = useTranslation('common');

  const onUpdateTeam = useCallback((teams)=>{
    // Compare value.teams and teams and update only if different
    if (teams.length>value.teams.length){
      const newTeam = teams.filter(t=>!value.teams.includes(t))[0];
      toggleTeamFromPreset({id: value.id, team: newTeam});
    } else {
      const removedTeam = value.teams.filter(t=>!teams.includes(t))[0];
      toggleTeamFromPreset({id: value.id, team: removedTeam});
    }
    setValue({...value, teams});
  }, [value]);

  const groupedTeams = useMemo(()=>{
    const groups = group(value.teams, d=>(d.active_period && (d.active_period.name || d.active_period) ));
    // Get the keys
    const keys = Array.from(groups.keys()).sort((a,b)=>a<b?-1:1);

    return {keys, groups};
  }, [value]);


  return<div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("modify-preset")}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
                 <SingleLineField 
                    label={true&&t("name")} 
                    modify={true} 
                    value={value.name} 
                    setValue={(name)=>{setValue({...value, name}); updatePreset({id: value.id, name})}}/>
               <ModifiableSelectField
                    Field={SelectBool} 
                    label={t("modify-preset-core-title")} 
                    subLabel={t("modify-preset-core-description")} 
                    modify={true} 
                    value={value.core || false }
                    setValue={(core)=>{
                        setValue({...value, core: core.value==="oui"? true: false}); 
                        updatePreset({id: value.id, core: core.value==="oui"? true: false})
                      }}/>

                <SearchUser 
                  label={t("target-user")}
                  value={value.user}
                  size="lg"
                  setValue={(user)=>{setValue({...value, user}); updatePreset({id: value.id, user})}}/>

                <Info.Container label={t("teams")} modify={true}>
                  <SearchTeams value={value.teams}
                    setValue={onUpdateTeam}
                     size="lg"
                     multiple={true}
                     searchParams={{limit: 500}}
                      showPeriodFilter={true}
                      by={(a,b)=>a.slug===b.slug}
                      indexField={"slug"}
                      />
                  <div className='grid grid-cols-1 max-h-full overflow-auto'>
                    {groupedTeams.keys.map((period)=>
                      <div key={period} className="">
                        <h3 className="font-medium text-sm text-gray-500">{period}</h3>
                        <div className='mb-5'>
                          {groupedTeams.groups.get(period).map((team)=>
                          <TeamBadge key={team.slug} onRemove={()=>{toggleTeamFromPreset({id: value.id, team}); setValue(({...value, teams: value.teams.filter(el=>el.slug!==team.slug)}))}}>
                            {team.name}
                          </TeamBadge>)}
                        </div>
                      </div>)}
                  </div>
                </Info.Container>

              </CardContainer>
              <CardContainer className="mt-auto pb-12">
                <DeleteButton value={value} onDelete={onDelete}/>
                </CardContainer>
            </div>


}