import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { StudentSettingsTeams } from 'components/student/modify';

// Hooks
import { useContext } from 'react';

// Contexts
import { StudentContext } from 'contexts/student';

export default function PageTeamsSettings(){

  const { student, setStudent} = useContext(StudentContext);
  return <div className="h-full w-full">
            {student?
              <StudentSettingsTeams student={student} setStudent={setStudent}/>:<Skeleton className="w-full h-36"/>}
         </div>

}
