import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { BulkImportView } from 'components/bulk-import';
import { FiFolderPlus } from 'react-icons/fi';

export function BulkImportBase(){
  return <DashboardLayout>
          <BulkImportView/>
         </DashboardLayout>
}

const route =  { path: "/bulk-import",
                 name: "Importation" , 
                 Icon: FiFolderPlus,
                 group: 'outils',
                 isNested: true,
                 showInNavBar: true, 
                 requireAuth: true, 
                 Element: BulkImportBase };
export default route;

import { default as coachs } from 'pages/bulk-import/coachs'
import { default as partners } from 'pages/bulk-import/partners'
// import { default as tutors } from 'pages/end-year-assistant/tutors'
import { default as students } from 'pages/bulk-import/students';
import { default as teachers } from 'pages/bulk-import/teachers';
export const BulkImportPages = {root: BulkImportBase, students, teachers, coachs, partners}

