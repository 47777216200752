import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

export function useQuery() {

  let [searchParams, setSearchParams] = useSearchParams();
  let [query, setQuery] = useState(searchParams);

  useEffect(()=>{
    setSearchParams(query)
  }, [query])

  return {query:searchParams, setQuery};
}
export function useQueryContent(){
  const {query} = useQuery();
  return query || {};
}