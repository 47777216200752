import React, { useState } from 'react';

// Components
import { UploadFile } from 'components/bulk-import/upload';
import { Error, InfoSectionHeading  } from 'components/core/typo';

// Hooks
import { useTranslation } from 'react-i18next';
import { Button } from 'components/core/button';

/*
  This component will handle all the settings so that it will 
  output a nice and formatted list of students to push to the API.
*/

const fields = ["Nom", 
        "Courriel"];

export function PartnersUpload({setValue}){
  const [error, setError] = useState();
  const {t} = useTranslation('common');
  return <div>
          <InfoSectionHeading smallTitle={t("bulk-import.partner-upload.title")} longDescription={t("bulk-import.partner-upload.description", {fields: fields.join(', ')})}>
            <Button color="gray" size="sm" className={"ml-3"} onClick={()=>{window.open("/assets/partner-template.csv")}}>{t("bulk-import.partners.download-template")}</Button>
            </InfoSectionHeading>
          <UploadFile setValue={setValue} setError={setError} fields={fields}/>
          {error? <Error.Text {...error}/>: null}
         </div>
}