import React from 'react';
import classnames from 'classnames';

// Hooks
import { useState } from 'react'

// Components
import { SearchField } from 'components/tools/search_field'; 
import { useTranslation } from 'react-i18next';

// API
import { searchSchoolSubjects } from 'api/utils';
import { useSearch } from 'hooks/useSearch';

export function SearchSchoolSubject({value, setValue, ...props}){
  const { t } = useTranslation('common');
  const [params, setParams] = useState({});
  const [results, {loading}] = useSearch(searchSchoolSubjects, params)
  return <SearchField values={results}
                      loading={loading}
                      value={value}
                      setValue={setValue}
                      placeholder={t("school-subject")}
                      indexingField={"slug"}
                      formatSelectedValue={(d=>d.name)}
                      formatSearchResult={((d, {selected, active})=>
                        <div>
                          <span className={classnames('block truncate', (active || selected) && 'font-semibold')}>{d.name}</span>
                        </div>)}
                     onParamsChange={setParams}
                      {...props}
  />
}
