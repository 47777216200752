import React from 'react';

// Components
import { StudySessionInfo } from 'components/study-sessions/info';
import { FullFlexNav } from 'components/core/layouts/flex';
// import { Skeleton } from 'components/core/skeleton';
import { NotesCardWithContext } from 'components/notes/list';

// Contexts
import { SearchNotesContextProvider } from 'contexts/search_notes';
import {StudySessionContext} from 'contexts/study_session';

// Hooks
import { useContext } from 'react';

export default function Overview(){
  const {studySession, loading} = useContext(StudySessionContext);
  return <FullFlexNav className="p-6 block xl:flex">
          <FullFlexNav.Bar>
            <StudySessionInfo/>
          </FullFlexNav.Bar>
          <FullFlexNav.Content>
            {!loading && 
              <SearchNotesContextProvider defaultState={{studySession: studySession.id, orderBy: "latest_comment"}}>
                <NotesCardWithContext noCalendar 
                  student={studySession.student} 
                  tutor={studySession.pairing && studySession.pairing.tutor} 
                  pairing={studySession.pairing} 
                  studySession={studySession}/>
             </SearchNotesContextProvider>}
          </FullFlexNav.Content>
         </FullFlexNav>
}
