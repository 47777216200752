import { useState, useRef, useEffect } from 'react';

// Resize the svg
export function useDimensions(){
  const ref = useRef();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(()=>{
    if (width && height) return;
    if (!ref.current) return;
    const element = ref.current;

    const resizeObserver = new ResizeObserver(entries => {
      if (!Array.isArray(entries)) return;
      if (!entries.length) return;
      const entry = entries[0];
      if (width != entry.contentRect.width) setWidth(entry.contentRect.width);
      if (height != entry.contentRect.height) setHeight(entry.contentRect.height);
    })

    resizeObserver.observe(element);
    return () => resizeObserver.unobserve(element);

  }, [ref.current]);

  return [width, height, ref]
}

