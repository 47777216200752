import React from 'react';

// Components
import { UserInscriptions } from 'components/recruting/inscriptions/user-inscriptions';
import { TutorSliderOverContextProvider } from 'contexts/tutor_slideover';

export default function UserAssignations() {
    return <>
    <TutorSliderOverContextProvider>
        <UserInscriptions />
    </TutorSliderOverContextProvider>
    </>
}
