import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Container } from 'components/core/container';
import { Tabs } from 'components/core/tabs';
import { Button } from 'components/core/button';
import { FiArrowLeft } from 'react-icons/fi';

// Hooks
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next'; 
import { useParams, useLocation, generatePath, matchPath } from 'react-router-dom';

// Contexts
import { PairingContextProvider, PairingContext } from 'contexts/pairing';

function Header(){
  const { t } = useTranslation("common");
  const { pairing } = useContext(PairingContext);

  if (!pairing.student){
    return <Container className="py-6">
              <Skeleton className="w-screen max-w-sm h-12" />
            </Container>
  }
  return <Container className="py-3 ">
          <Button href="/pairings" color="inlineLink"  className="md:hidden"><FiArrowLeft className="mr-1"/>{t("pairings")}</Button>
          <h1 className="font-medium text-2xl">{pairing.student.name} / {pairing.tutor.name}</h1>
          </Container>
}

function PairingTabs(){
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation("common");
  const tabs = useMemo(()=>[
      {
        name: t("pairing-nav.overview"),
        href: generatePath("/pairings/:id", params),
        current: matchPath("/pairings/:id/", location.pathname),
      }, 
      {
        name: t("pairing-nav.notes"),
        href: generatePath("/pairings/:id/notes", params),
        current: matchPath("/pairings/:id/notes", location.pathname),
      }, 
      {
        name: t("pairing-nav.settings"),
        href: generatePath("/pairings/:id/settings/", params),
        current: matchPath("/pairings/:id/settings/", location.pathname),
      }, 
    ], [location])
  return <Container className="border-b">
          <Tabs tabs={tabs}/>
          </Container>
}

export function PairingLayout({ children }){
  return <PairingContextProvider>  
          <Header/>
          <PairingTabs/>
          { children }
         </PairingContextProvider>

}