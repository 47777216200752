import React from 'react';

// Components
import { Container } from 'components/core/container';
import { FlexNav } from 'components/core/layouts/flex';
import { Outlet } from "react-router-dom";
import { SubSideNavLink } from "components/core/nav";
import { FiUser, FiBookmark, FiStar, FiDribbble, FiCommand, FiUsers, FiLogOut as CloseIcon, FiLogIn as OpenIcon, FiEdit } from 'react-icons/fi';

// Router
import { useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NavBar(){
  const { t } = useTranslation();
  const params = useParams();
  return <div >
          <p className="mt-5 text-sm font-medium text-gray-600 uppercase">{t("general")}</p>
          <SubSideNavLink path={generatePath(`/students/:ni/settings/`, params)} Icon={FiUser} name={t("personal-info")}/>
          <SubSideNavLink path={generatePath(`/students/:ni/settings/class-failed`, params)} Icon={FiBookmark} name={t("failed-school-classes")}/>
          <SubSideNavLink path={generatePath(`/students/:ni/settings/teams`, params)} Icon={FiDribbble} name={t("teams")}/>
          <SubSideNavLink path={generatePath(`/students/:ni/settings/professional-comments`, params)} Icon={FiEdit} name={t("professional-comments.title")}/>
          <SubSideNavLink path={generatePath(`/students/:ni/settings/siblings`, params)} Icon={FiCommand} name={t("siblings")}/>
          <p className="mt-5 text-sm font-medium text-gray-600 uppercase">{t("tutoring")}</p>
          <SubSideNavLink path={generatePath(`/students/:ni/settings/preferences`, params)} Icon={FiStar} name={t("preferences")}/>
          <SubSideNavLink path={generatePath(`/students/:ni/settings/pairings`, params)} Icon={FiUsers} name={t("pairings")}/>
          <p className="mt-5 text-sm font-medium text-gray-600 uppercase">{t("account-managing")}</p>
          <SubSideNavLink path={generatePath(`/students/:ni/settings/open`, params)} Icon={OpenIcon} name={t("open")} />
          <SubSideNavLink path={generatePath(`/students/:ni/settings/close`, params)} Icon={CloseIcon} name={t("close")}  />
        </div>
}
function StudentSettings(){

  return <Container className="h-full py-6" >
          <FlexNav>
            <FlexNav.Bar>
              <NavBar/>
            </FlexNav.Bar>
            <FlexNav.Content>
              <Outlet/>
            </FlexNav.Content>
           </FlexNav>
          </Container>
}

import {default as general} from './general';
import {default as pairings} from './pairings';
import {default as siblings} from './siblings';
import {default as subjects} from './subjects';
import {default as teams} from './teams';
import {default as preferences} from './preferences';
import {OpenSettings as open} from './open';
import {CloseSettings as close} from './close';
import {default as professionalComments} from './procomments';

export const StudentSettingsPages = {root: StudentSettings,
                                        general,
                                        subjects,
                                        siblings,
                                        teams,
                                        preferences,
                                        pairings,
                                        open,
                                        professionalComments,
                                        close};