/*
  This list is intended for managing the coachs, not for selection.
*/

import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useContext } from 'react';
import { useSearch } from 'hooks/useSearch';

// Utils
import { locale, dateParse } from 'utils/locale';

// Contexts
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error, InfoSectionHeading } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';

// API
import { searchCoachs } from 'api/coachs';


export function ControlledCoachsList({coachs, setPage, paging, error}){
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { t } = useTranslation('common');
  const headers = useMemo(()=>[
    {
     title: t("name"),
     field: (d=>d? d.name : "name"),
     itemClassName: "!py-2 whitespace-nowrap"
    },
    {
     title: t("teams"),
     field: (d=>d? d.teams.map(e=>e.name) : "teams"),
     itemClassName: "py-2 text-gray-500"
    },
    {
     title: t("active-since"),
     field: (d=>d? (d.service_started_at && locale.format("%d %B %Y")(new Date(dateParse(d.service_started_at)))) : "service_started_at"),
     itemClassName: "text-gray-500"
    }
  ], [coachs])

  if (!coachs){
    return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2"/>
  }

  return <Info.Container modify={true} >
          <InfoSectionHeading mediumTitle={t("active-coachs.title")} longDescription={t("active-coachs.gonna-be-deactivated")}/>
            {coachs.length===0?
              <EmptyState
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-coachs-title")}/>
              :
              <Table headers={headers}
                    data={coachs}
                    indexingKey="ni"
                    order={{by:filters.orderBy, direction:filters.orderDirection}}
                    onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
              }
              {paging&& <Paginate setPage={setPage} {...paging}/>}
              {error? <Error.Text {...error}/>: null}
          </Info.Container>
}

function CoachsListWithContext(){
  const { filters } = useContext(SearchContext);
  const [coachs, {loading, error, paging, setPage}] = useSearch(searchCoachs, filters);
  return <ControlledCoachsList coachs={coachs}
                              setPage={setPage}
                              loading={loading}
                              error={error}
                              paging={paging} />
}

export function CoachsList(){
  return <SearchContextProvider doNotStore defaultState={{orderBy:"name", orderDirection: "asc", serviceIsEnded: false}}>
            <CoachsListWithContext/>
        </SearchContextProvider>
}