import React from 'react';
import classnames from 'classnames';

// API

// Utils

// Hooks
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import { SearchContext } from 'contexts/search';
import { TutorsContext } from 'contexts/tutors';

// Components
import { Button } from 'components/core/button';
import { Skeleton } from 'components/core/skeleton';
import { ListSearch } from 'components/core/inputs';
import { EmptyState } from 'components/core/empty';
import { PaginateCard } from 'components/core/paginate';
import { Error } from 'components/core/typo'
import { ClosedIndicator } from 'components/core/closed-indicator';

function TutorCell({ ni, name, retired_of_service_since }){
  const isClosed= retired_of_service_since && new Date(retired_of_service_since) < new Date();
  return <Button className="p-3" layout="cell" nav  color={(d)=>d? "activeCell": "cell"}href={`/tutors/${ni}`}>
          {({isActive})=>
          <div className='flex justify-between'>

            <div className='block w-full break-all whitespace-pre-wrap'>
              <p className={classnames(isActive&& "font-medium text-white")}>{name}</p>
            </div>
            <div className='flex items-center ml-1'>
                <ClosedIndicator isClosed={isClosed} />
            </div>
            </div>}

         </Button>
}

export function TutorsList(){
  const { t } = useTranslation("common");
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { loading, paging, setPage, error, hasMore, tutors } = useContext(TutorsContext)
  if (!tutors){
    return <Skeleton.List itemClassName="h-8 rounded" className="p-3 space-y-3" numElements={4}/>
  }
  return <div>
          <div className="border-b">
            <ListSearch color="none"
                loading={loading}
                search={true}
                placeholder={t("search-by-name")}
                onChange={(value)=>dispatchFilters({type: 'search', value})} />
          </div>
            {error? <Error.Text className="mt-3" {...error}/>: null}
          {tutors.length===0?
            <EmptyState
                title={t("empty-state.no-tutors-title")}
                description={t("empty-state.no-tutors-description", {search: filters.search})}/>:
            <div className="divide-y">
            {tutors.map(d=><TutorCell key={d.ni} {...d}/>)}
          </div>}
            {paging&& hasMore && <PaginateCard setPage={setPage} {...paging}/>}
        </div>
}

