/* eslint no-unused-vars: "off" */
import React from "react"

// Components
import { StudentsMetricsCard } from "components/coordo-stats/students-metrics"

// Hooks
import { useTranslation } from "react-i18next"

const globalScoreDefinitions = {
    // score global positif (succès)
    "-" : [0, 29], // Rouge
    "+-" : [30, 39], // Jaune
    "+" : [40, 100] // Vert
}

const scoresDefinitions = {
    // score positif (succès) -> Plus le score est élevé, plus il y a de succès
    1 : [0, 19], // Rouge
    2 : [20, 30], // Orange
    3 : [30, 39], // Vert
    4 : [40, 49], // Vert
    5 : [50, 100], // Vert
}

export function Success({...props}) {    
    const { t } = useTranslation();
    return <>
        <StudentsMetricsCard 
            title={t("students-metrics.success.title")}
            projectedStatKey="nbSuccess" 
            infoDescription={t("students-metrics.success.description")}
            scoresDefinitions={scoresDefinitions} 
            globalScoreDefinitions={globalScoreDefinitions}
            className="border-t-4 border-green-500"
            scoreClassName="!text-green-500"
            {...props}
        />
    </>
}
