import React from 'react';
import classnames from 'classnames';

// Hooks
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';


// Context
import { SearchContext } from 'contexts/search';
import { CoachsContext } from 'contexts/coachs';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Button } from 'components/core/button';
import { ListSearch } from 'components/core/inputs';
import { ClosedIndicator } from 'components/core/closed-indicator';
import { PaginateCard } from 'components/core/paginate';
import { Error } from 'components/core/typo'

function CoachCell({ ni, name, teams, service_ended_at }) {
  const isClosed = service_ended_at ? true : false;
  return <Button className="p-3" layout="cell" nav color={(d) => d ? "activeCell" : "cell"} href={`/coachs/${ni}`}>
    {({ isActive }) =>
      <div className='flex justify-between'>
        <div className="block w-full break-all whitespace-pre-wrap">

          <div className={classnames(isActive && "font-medium text-white")}>{name}</div>

          {teams && teams.length > 0 ?
            <div className={classnames("block text-sm", isActive ? "text-white" : "text-gray-400")}>{teams.map(d => d.name).join(', ')}</div>
            : null}
        </div>
        <div className='flex items-center ml-1'>
          <ClosedIndicator isClosed={isClosed} />
        </div>
      </div>
    }
  </Button>
}

export function CoachsList() {
  const { t } = useTranslation("common");
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { loading, paging, setPage, error, hasMore, coachs } = useContext(CoachsContext)
  if (!coachs) {
    return <Skeleton.List itemClassName="h-8 rounded" className="p-3 space-y-3" numElements={4} />
  }
  return <div>
    <div className="border-b">
      <ListSearch color="none"
        loading={loading}
        search={true}
        placeholder={t("search-by-name")}
        onChange={(value) => dispatchFilters({ type: 'search', value })} />
    </div>
    {error ? <Error.Text className="mt-3" {...error} /> : null}

    {coachs.length === 0 ?
      <EmptyState
        title={t("empty-state.no-coachs-title")}
        description={t("empty-state.no-coachs-description", { search: filters.search })} /> :
      <div className="divide-y">
        {coachs.map(d => <CoachCell key={d.ni} {...d} />)}
      </div>}
    {paging && hasMore && <PaginateCard setPage={setPage} {...paging} />}
  </div>
}

