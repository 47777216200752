// Hooks
import { useParams, useLocation } from 'react-router-dom';
import { useAPI } from 'hooks/useAPI';
import { useMemo } from 'react';

// API
import { retrievePairing } from 'api/pairings';

export function usePairing({ immediate=false }){
  const { id } = useParams();
  const { state } = useLocation(); // Persitent state
  const params = useMemo(()=>({id}), [id]);
  const [result, others] = useAPI(retrievePairing, params, {immediate});
  return [state || result || {id}, others];
}