import { fetchCall } from 'api';

/*
  Update 
*/  
export const updateMeetingDate = ({id, description, start, end, meetingGoal}) => {
  const options = {
    method: "POST", 
    endpoint: `/meeting-dates-setup/${id}`,
    body: { description, 
      start: start && new Date(start).toISOString().split("T")[0],
      end: end && new Date(end).toISOString().split("T")[0],
       meetingGoal}
  }
  return fetchCall(options)
};

/*
  Retrieve 
*/  
export const retrieveMeetingDate = ({id}) => {
  const options = {
    method: "GET", 
    endpoint: `/meeting-dates-setup/${id}`
  }
  return fetchCall(options)
};

/*
  Delete 
*/  
export const deleteMeetingDate = ({id}) => {
  const options = {
    method: "DELETE", 
    endpoint: `/meeting-dates-setup/${id}`
  }
  return fetchCall(options)
};

/*
  Create 
*/  
export const createMeetingDate = ({ description, start, end, meetingGoal}) => {
  const options = {
    method: "POST", 
    endpoint: `/meeting-dates-setup`,
    body: { description, 
      start: start && new Date(start).toISOString().split("T")[0],
      end: end && new Date(end).toISOString().split("T")[0],
      meetingGoal}
  }
  return fetchCall(options)
};


/*
  Search noteCodes
*/
export const searchMeetingDates = ({fromDate, toDate, page, limit, orderBy, orderDirection}) => {
    const options = {
      method: "GET",
      endpoint: "/meeting-dates-setup",
      query: {
            fromDate: fromDate && new Date(fromDate).toISOString().split("T")[0],
            toDate: toDate && new Date(toDate).toISOString().split("T")[0],
              page,
              limit,
              orderBy,
              orderDirection}
    }
    return fetchCall(options)
  };
  