import React from 'react';

// Components
import { AccessList } from 'components/portal-access/list'

// Hooks

export default function PortalParents(){
  return <AccessList role="parent"/>
}
