import React from 'react';

// Components
import { StudentPairingsList } from 'components/student/pairings';
import { StudentStudySessionsList } from 'components/student/study_sessions';
import { VerificationFlagCard } from 'components/student/verfication_flag';
import { StudentInfo } from 'components/student/info';
import { FullFlexNav } from 'components/core/layouts/flex';

// Hooks
import { useContext } from 'react';

// Contexts
import { StudentContext } from 'contexts/student';
import { TutoringGrid } from 'components/tutoring-follow-up/tutoring_grid';

export default function Overview(){
  const { student, setStudent } = useContext(StudentContext);
  return <FullFlexNav className="p-6 block xl:flex">
          <FullFlexNav.Bar className="flex-shrink-0">

            <StudentInfo student={student}/>
          </FullFlexNav.Bar>
          <FullFlexNav.Content className="space-y-6">

                 <VerificationFlagCard student={student} setStudent={setStudent}/>
              <div className='pb-12'>
          <TutoringGrid studyCode="tutorat" numWeeks={4} student={student} showTotalInCards={true} noAssignedTo={true}/>
          </div>
          <StudentPairingsList/>
          <div className='pt-12'>
          <StudentStudySessionsList/>
          </div>
          </FullFlexNav.Content>
         </FullFlexNav>
}
