import React, {createContext } from "react";

// Hooks
import { useLocation } from 'react-router-dom';
import { useMemo, useEffect } from 'react';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useStudySession } from 'hooks/study-sessions/useStudySession';

const StudySessionContext = createContext({});

const StudySessionContextProvider = ({ children }) => {
  const location = useLocation();
  const immediate = useMemo(()=>false, []);
  const [studySession, {loading, error, execute, setResult}] = useStudySession({immediate});

  const paramsChanged = useMemoCompare(location, (prev, next)=>prev && prev.pathname==next.pathname);
  useEffect(()=>{
    execute()
  }, [paramsChanged])
 
  return (
    <StudySessionContext.Provider value={{ studySession, loading, error, setStudySession: setResult }}>
      {children}
    </StudySessionContext.Provider>
  );
};

export { StudySessionContext, StudySessionContextProvider };