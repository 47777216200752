import React from 'react';

// Components
import { TutoringStats } from 'components/tutoring-follow-up/stats';

// Hooks
import { useContext } from 'react';
// Contexts
import { TutoringFollowUpContext } from 'contexts/tutoring_follow_up';

export default function Stats(){
  const {filters} = useContext(TutoringFollowUpContext);
  return <TutoringStats {...filters}  />
}
