import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect, useContext } from 'react';
import { useMonth } from 'hooks/dates/useMonth';

// Components
import { Fragment } from 'react'; 
import { Skeleton } from 'components/core/skeleton';
import { DoubleMonthView } from 'components/tools/double_month_view';
import { EmptyState } from 'components/core/empty';
import { Error } from 'components/core/typo';
import { Table } from 'components/core/table'
import { Paginate } from 'components/core/paginate';

// Contexts
import { SearchStudySessionsContext } from 'contexts/search_study_sessions';

// Utils
import { locale, dateParse } from 'utils/locale';
import { Link } from 'react-router-dom';

function Empty(){
  const {filters } = useContext(SearchStudySessionsContext)
  const { t } = useTranslation('common');
  return <EmptyState 
            className="h-full flex flex-col justify-center"
            title={t("empty-state.no-study-session-title")}
            description={t("empty-state.no-study-session-description", 
              {fromDate: locale.format("%d %B")(filters.fromDate), 
              toDate: locale.format("%d %B")(filters.toDate)})}>
         </EmptyState>
}

function StudyTable({studySessions, paging}){
  const { t } = useTranslation('common');
  const {filters, dispatchFilters } = useContext(SearchStudySessionsContext)
  const headers = useMemo(()=>
    [
      {
        title: "",
        field: (d=>d? d.code.color: "color"),
        format: (d=><div className="h-4 w-4 rounded px-0" style={{background: d}}/>)
      },
      {
        title: t("period"),
        field: "period",
        format: (d=>locale.format("%d %B")(dateParse(d))),
        itemClassName: "text-gray-500"
      },
      {
        title: t("type"),
        field: (d=>d?d.code.name: "code"),
        itemClassName: "font-medium py-2"
      },
      {
        title: t("length_in_minutes"),
        field: "length_in_minutes",
        format: (d=>`${d} min.`),
        itemClassName: "text-gray-500"
      }, 
      {
        title: t("notes"),
        field: (d=>d?d.notes.length: "notes"),
        itemClassName: "text-gray-500",
        format: (d=>`${d} note${d>1? 's': ''}`),
      },
      {
        title: t("link"),
        field: (d=>d?d: "link"),
        itemClassName: "text-orange-500",
        FormatComponent: (d=><Link to={`/study-sessions/${d.id}`} className="text-blue-500 underline">{t("see")}</Link>)
      },
    ]
  , [])

  return <Fragment >
            <div className="bg-gray-100 rounded-lg pb-3 pt-2 px-3">
              <Table headers={headers} 
              data={studySessions} 
              indexingKey={"id"} 
              order={{by:filters.orderBy, direction:filters.orderDirection}}
              onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
            </div>
            {paging&& <Paginate {...paging}/>}
          </Fragment>
}

export function StudySessionList({studySessions, paging, error}){
  const { t } = useTranslation('common');
  const { dispatchFilters } = useContext(SearchStudySessionsContext)
  const [date, setDate] = useState();
  const windowDates = useMonth(date);

  useEffect(()=>{
    dispatchFilters({type: 'dates', value: windowDates})
  }, [windowDates]);

  const events = useMemo(()=> {
    if (!studySessions) return;
    return studySessions.map(d=>({date:dateParse(d.period), color: d.code.color}))
  }, [studySessions]);


  if (!studySessions){
    return <Skeleton.List numElements={1} itemClassName="h-32" className="space-y-2"/>
  }

  return <div className="">
            <label className="ml-2 text-sm uppercase text-gray-500">{t("study-sessions")}</label>
           <div className=" space-y-6 2xl:space-y-0 2xl:flex">
                <div className="flex-1 min-w-max max-w-sm pr-6 mx-auto py-6 2xl:py-0 ">
                  <DoubleMonthView date={date} onDateChange={setDate} events={events} layout="full" className="w-full" numMonths={1}/>
                </div>
                <div className="flex-1">
                  {studySessions.length===0?
                    <div className="bg-gray-100 rounded-lg p-3 h-full">
                      <Empty/>
                    </div>:<StudyTable studySessions={studySessions} paging={paging}/>
                  }
                  {error? <Error.Text className="mt-3" {...error}/>: null}
                </div>
              </div>
          </div>
}