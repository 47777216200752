
import { SocioAffectifs } from "components/coordo-stats/students-metrics/socio-affectifs"
import { Fails } from "components/coordo-stats/students-metrics/fails"
import { StudentsMetricsGrid } from "components/coordo-stats/students-metrics"
import { AtRisk } from "components/coordo-stats/students-metrics/at-risk"
import { Success } from "components/coordo-stats/students-metrics/success"
import { MeetingsProgressionWithContext as MeetingsProgression } from "components/coordo-stats/meetings"
import { CommunicationsWithContext as Communications } from "components/coordo-stats/communications"
import { StudySessionsWithContext as StudySessions } from "components/coordo-stats/study-sessions"

export const CoordoStats = {
    StudentsMetricsGrid,
    SocioAffectifs,
    Fails,
    AtRisk,
    Success,
    MeetingsProgression,
    Communications,
    StudySessions
}