import React from 'react';
import classnames from 'classnames';

// Components
import { Tab } from '@headlessui/react'

export function SelectTabs({defaultIndex, values, setValue}) {

  return (
    <Tab.Group onChange={(d)=>setValue(values[d].value)} defaultIndex={defaultIndex}>
        <Tab.List className="flex space-x-1 rounded-xl bg-gray-100 p-1">
          {values.map((category) => (
            <Tab
              key={category.value}
              as={'button'}
              className={({ selected }) =>
                classnames(
                  'w-full whitespace-nowrap  rounded-lg py-1.5 px-3 leading-5 ',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-gray-400 focus:outline-none',
                  selected
                    ? 'bg-white text-gray-700 shadow'
                    : 'text-gray-400 hover:bg-white/40 hover:text-gray-300 '
                )
              }
            >
              {category.name}
            </Tab>
          ))}
        </Tab.List>
    </Tab.Group>
  )
}