import { fetchCall } from 'api';

export function getCoordoStats({fields, start, end, date, coordo}) {
    const options = {
        method: "GET",
        endpoint: "/stats/coordinator-stats",
        query: {
            fields,
            start,
            end,
            date,
            coordo: coordo && typeof coordo === 'object' ? coordo?.email : coordo
        }
    }
    return fetchCall(options)
}
