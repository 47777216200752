import React from 'react';

// Components
import { EmptyState } from 'components/core/empty';

// Contexts
// Hooks
import { useTranslation } from 'react-i18next';

export default function Empty(){
  const {t} = useTranslation("autoPairing");
  return <div className="flex flex-col h-full justify-center items-center">
       <EmptyState title={t("empty.title")} description={t("empty.description")}/>
  </div>
}
