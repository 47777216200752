import React, {createContext, useContext } from "react";

// Hooks
import { useMemo } from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContext } from 'contexts/search';

// API
import { searchAutoPairings } from 'api/auto-pairing';

const AutoPairingsContext = createContext({});

const AutoPairingsContextProvider = ({ children }) => {
  const { filters } = useContext(SearchContext);
  const [drafts, { loading, paging, setPage, error, hasMore, setResult }] = useSearch(searchAutoPairings, filters, { fields: ["id", "num_tutors", "num_students", "num_matches"] });
  const isLoaded = useMemo(()=>!!drafts, [drafts]);

  return (
    <AutoPairingsContext.Provider value={{ drafts, setAutoPairings: setResult, loading, paging, setPage, error, hasMore, isLoaded }}>
      {children}
    </AutoPairingsContext.Provider>
  );
};

export { AutoPairingsContext, AutoPairingsContextProvider };