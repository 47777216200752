import React, { useCallback } from 'react';
import classnames from 'classnames';

// Hooks
import { useState, useEffect, useMemo } from 'react'


// Components
import { SearchField } from 'components/tools/search_field';
import { useTranslation } from 'react-i18next';

// API
import { searchPairings } from 'api/pairings';
import { useSearch } from 'hooks/useSearch';

export function SearchPairings({ label, value, setValue, disabled, color, size, student, tutor, active, targetWidth, extraParams, filterResults }) {
    const { t } = useTranslation('common');
    const [params, setParams] = useState({ student, tutor, active });
    const queryParams = useMemo(() => ({ ...params, ...extraParams }), [extraParams, params])
    const [pairings, setPairings] = useState();
    const onResult = useCallback(({ results }) => {
        filterResults ? setPairings(filterResults(results)) : setPairings(results);
    }, [filterResults]);
    const [, { loading }] = useSearch(searchPairings, queryParams, { onResult });
    useEffect(() => {
        setParams(d => ({ ...d, student, tutor, active }))
    }, [active, student, tutor]);

    return <SearchField values={pairings}
        loading={loading}
        value={value}
        setValue={setValue}
        disabled={disabled}
        color={color}
        size={size}
        label={label}
        placeholder={t("pairing")}
        indexingField={"id"}
        targetWidth={targetWidth}
        formatSelectedValue={(d => (d.student && d.student.name) ? `${d.student.name} / ${d.tutor.name}` : `Jumelé avec ${d.tutor.name}`)}
        formatSearchResult={((d, { selected, active }) =>
            <div>
                <span className={classnames('block max-w-md truncate', (active || selected) && 'font-semibold')}>{d.student.name} / {d.tutor.name}</span>
                <div className='flex gap-1 items-center'>

                    {d.ended_at ?
                        <span className={classnames('block', !(active || selected) && 'text-gray-500')}>{t("ended", { context: "male" })}</span> :
                        <span className={classnames('block', !(active || selected) && 'text-gray-500')}>{t("active", { context: "male" })}</span>}
                    {
                        !d.confirmed && <span className={classnames("bg-orange-50 text-orange-500 px-1.5 rounded ml-1")}>{t("not-confirmed")}</span>
                    }
                </div>
            </div>)}
        onParamsChange={setParams}
    />
}
