import React from 'react';

// Hooks

// Components
import { AssistantProgressBar } from 'components/end-year-assistant/progress_bar'
import { Outlet } from "react-router-dom";

export function EndYearAssistantView(){
  // const navigate = useNavigate();
  // const { t } = useTranslation("autoPairing");
  // const params = useParams();

  return <div className="flex flex-col h-full">
          <AssistantProgressBar/>
          <Outlet/>
        </div>
         
}