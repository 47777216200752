// Hooks
import { useParams, useLocation } from 'react-router-dom';
import { useAPI } from 'hooks/useAPI';
import { useMemo } from 'react';

// API
import { retrieveTutor } from 'api/tutors';

export function useTutor({ tutorNi, immediate=false }){
  const urlParams = useParams();
  const ni = tutorNi ||urlParams.ni;
  const { state } = useLocation(); // Persitent state
  const params = useMemo(()=>({ni}), [ni]);
  const [result, others] = useAPI(retrieveTutor, params, {immediate});
  return [state || result || {ni}, others];
}