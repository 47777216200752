import { fetchCall } from 'api';
import { isString } from 'utils/var_check';

/*
  List the portal users
*/  
export function searchPortalUsers({search, role, page, limit, orderBy, orderDirection}){
  const params = {
    method: "GET", 
    endpoint: `/portal-users`,
    query :{search, role, 
          page, 
            limit, 
            orderBy, 
            orderDirection}
  }
  return fetchCall(params)
}

/*Update portal user*/

export function updatePortalUser({ni, email}){
  const params = {
    method: "PATCH",
    endpoint: `/portal-users/${ni}`,
    body: {changeEmail: email}
  }
  return fetchCall(params)
}

export function bulkInsertTeachers({teachers, school}){
  const params = {
    method: "POST", 
    endpoint: `/bulk-insert-teachers`,
    body :{teachers,
          school: isString(school) ? school : school.slug}
  }
  return fetchCall(params)
}

export function bulkInsertCoachs({coachs, period}){
  const params = {
    method: "POST", 
    endpoint: `/bulk-insert-coachs`,
    body :{coachs, 
      period: period && (isString(period)? period: period.slug)}
  }
  return fetchCall(params)
}

export function bulkInsertPartners({partners, school}){
  const params = {
    method: "POST", 
    endpoint: `/bulk-insert-partners`,
    body :{partners,
      school: isString(school) ? school : school.slug}

  }
  return fetchCall(params)
}


/*
  Retrieve the portal user
*/  
export function retrievePortalUser({ni}){
  const params = {
    method: "GET", 
    endpoint: `/portal-users/${ni}`
  }
  return fetchCall(params)
}

/* List portal user authentification history */
export function searchPortalUserAuthHistory({ni, page, limit, orderBy, orderDirection}){
  const params = {
    method: "GET",
    endpoint: `/portal-users/${ni}/auth-history`,
    query: {page, limit, orderBy, orderDirection}
  }
  return fetchCall(params)
}

/*
  Send activation email a portal user
*/  
export function sendActivationEmail({ni}){
  const params = {
    method: "POST", 
    endpoint: `/portal-users/${ni}/send-activation-email`
  }
  return fetchCall(params)
}
/*
  Block a portal user
*/  
export function blockPortalUser({ni}){
  const params = {
    method: "POST", 
    endpoint: `/portal-users/${ni}/block`
  }
  return fetchCall(params)
}
/*
  unBlock a portal user
*/  
export function unblockPortalUser({ni}){
  const params = {
    method: "POST", 
    endpoint: `/portal-users/${ni}/unblock`
  }
  return fetchCall(params)
}

/*
  Change school access
*/  
export function updateSchoolAccess({ni, school}){
  const params = {
    method: "POST", 
    endpoint: `/portal-users/${ni}/school/${school}`
  }
  return fetchCall(params)
}
/*
  Delete portal user
*/  
export function deletePortalUser({ni}){
  const params = {
    method: "DELETE", 
    endpoint: `/portal-users/${ni}`
  }
  return fetchCall(params)
}

/*
    Toggle verification of portal user
*/
export function toggleVerificationPortalUser({ni}){
  const params = {
    method: "POST", 
    endpoint: `/portal-users/${ni}/toggle-verification`
  }
  return fetchCall(params)
}