import React from 'react';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useTranslation } from 'react-i18next';
// Utils
import classnames from 'classnames';
import { format } from 'd3-format';
import { locale, dateParse } from 'utils/locale';

// API
import { searchCoachMeetingStats } from 'api/stats';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Table } from 'components/core/table';
import { Tooltip } from 'components/core/tooltip';
import { SelectDateList } from 'components/tools/select_date';
import { SearchNoteCode } from 'components/tools/search_note_code';
import { FiInfo } from 'react-icons/fi'
import { InfoSectionHeading } from 'components/core/typo';
import * as Plot from "@observablehq/plot";
import { useDimensions } from 'hooks/useDimensions';

export function ProgressChart({stats, targetStudents}){

  const [width, , ref] = useDimensions();

  useEffect(() => {
    if (stats === undefined) return;
    const data = stats.map((d,i)=>({x1: 
      dateParse((i?stats[i-1].to_date: stats[0].from_date)), 
      x2: dateParse(stats[i].to_date), 
      y1:0, 
      y2: Math.max(0.0, d.students_done.length)/Math.max(0.01, (targetStudents|| []).length) }));

      const maxY2 = Math.max(...data.map(d=>d.y2));
      const chart = Plot.plot({
          marks: [
            Plot.rectY(data, {x1: 'x1', x2: 'x2', y1: 'y1', y2: 'y2', inset: 1, fill: "y2"}),
            Plot.tip(data, Plot.pointerX({ x: "x2", y: "y1", fill: "#119a91"}))
          ],
          style: {
            fontSize: 14
          },  
          x: {
            nice: true,
            ticks: 5,
          },
          y: {
            grid: true,
            line: true,
            percent: true,
            label: "% objectif",
            domain: (100*maxY2>10)? undefined: [0,10]
          },
          color: {
            scheme: "Cool",
            percent: true,
            label: "%"
          },
          height: 250,
          width: width,
          marginLeft: 50,
          marginRight: 30,
          marginTop: 30,
          marginBottom: 30,
          insetTop: 0,
          insetBottom: 0,
          insetLeft: 30,
          insetRight: 30
        })
      ref.current.append(chart);
      return () => chart.remove();
  }, [stats, width]);

  return <div className="w-full" ref={ref}/>
}

function StatTooltip({students, done}){
  const {t} = useTranslation("common")

  return <div>
          {students&& students.map(student=>
            <p key={student.ni} 
            className={classnames(done.includes(student.ni)? "text-green-600": "text-gray-600")}>
              {student.name}
            </p>)}

          {!students && <p>{t("no-students")}</p>}
         </div>
}

function CompletedCell({students_done, targetStudents}){
  return <Tooltip color="light" position="center" delay="100" content={<StatTooltip done={students_done} students={targetStudents}/>}>
                  <span  className="flex items-center space-x-2 whitespace-nowrap my-1">
                    <span>{students_done.length}</span>
                    <FiInfo className="bg-gray-100 p-0.5 h-6 w-6 rounded text-blue-500"/>
                  </span>
                </Tooltip>
}

function Results({stats, showHelp}){
  const {t} = useTranslation("common")

  const headers = useMemo(()=>{
    var h = [
      {
        title: "Semaine",
        field: (d=>d || "week"),
        format: (d=>d.rowIndex+1),
        itemClassName: "font-medium"
      },
      {
        title: "De",
        field: (d=>d || "fromDate"),
        format: (d=>locale.format("%d %B")(dateParse(d.from_date))),
      }, 
      {
        title: "À",
        field: (d=>d || "toDate"),
        format: (d=>locale.format("%d %B")(dateParse(d.to_date)))
      },
      {
        title: "Complétées",
        field: (d=>d? d: "done"),
        itemClassName: "relative",
        FormatComponent: CompletedCell
      },
      {
        title: "Objectif",
        field: (d=>d? (d.targetStudents|| []).length :"target"),
      },
      {
        title: "%",
        field: (d=>d || "percent"),
        format: (d=>{const e = d.students_done.length/(d.targetStudents|| []).length; return isNaN(e)?"- %": format(".1%")(e)}),
        itemClassName: "font-medium my-2"
      }
    ];
    return h;
  }, [])
  if (showHelp) return <EmptyState title={t("coach-stats-help-title")} description={t("coach-stats-help-description")}/>
  if (!stats) return <Skeleton className="h-80 w-full"/>;

  return <div className="space-y-3">
        {stats.map(({coach, target_students, results})=>
          <div key={coach.ni} className=" rounded-lg p-3">
            {/* <ProgressChart stats={results} targetStudents={target_students}/> */}
            <Table indexingKey={(_,index)=>index} data={results} headers={headers} rowProps={{targetStudents:target_students}}/>
          </div>
        )}
        </div>
}

export function CoachStats({coach}){
  const {t} = useTranslation("common")
  const extraParse = useCallback((next)=>{
    return next.map(d=>new Date(d));
  }, [])
  const [dates, setDates] = useLocalStorage("coach-stats-dates", [], extraParse);
  const [code, setCode] = useState({code: "rencontre-ea-entraineur", name: "Rencontre ÉA-Coordonnateur"});
  const filters = useMemo(()=>({dates, coach, code}), [dates, code, coach])
  const fixedFilters = useMemoCompare(filters, (prev, next)=>{
    return (prev && prev.dates.length===next.dates.length 
        && prev.coach && next.coach && prev.coach.ni===next.coach.ni
        && prev.code && next.code && prev.code.code ===next.code.code)
  })
  const validateParams = useCallback(({dates, code})=>dates &&dates.length>1 && code, []);
  const [stats, {loading}] = useSearch(searchCoachMeetingStats, fixedFilters, {validateParams})

  return <div>
            <InfoSectionHeading mediumTitle={t("coach-statistics")}  className="mb-3">
          <div className="flex justify-end items-center space-x-3 mb-3">
            <SelectDateList value={dates} setValue={setDates} />
            <SearchNoteCode value={code} setValue={setCode} disabled={true}/>
          </div>
          </InfoSectionHeading>
          <Results stats={stats} loading={loading} showHelp={!validateParams(fixedFilters)}/>
         </div>
}