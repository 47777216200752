import React from 'react';

// Components
import { DocumentsGrid } from 'components/documents/grouped';

// Hooks
import { useContext } from 'react';

// Contexts
import { SearchFollowUpContext } from 'contexts/search_follow_up';

export default function GroupedDocuments(){
  const {filters, dispatchFilters} = useContext(SearchFollowUpContext);
  return <div className="pb-6 h-full pt-3">
          <DocumentsGrid filters={filters} dispatchFilters={dispatchFilters}/>
         </div>
}
