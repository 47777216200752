/* eslint-disable */
import React from 'react';

// Components
import { Info } from 'components/core/info';

// Hooks
import { useTranslation } from 'react-i18next';

export function ActionPlanStats({ plan }) {
    const {comments:actions, student} = plan || {};
    const { t } = useTranslation("common");
    const latestComment = actions?.reduce((acc, curr) => acc.createdAt > curr.createdAt ? acc : curr, actions[0]);
    const sortedActions = actions?.sort((a, b) => new Date(a.displayDate) - new Date(b.displayDate));
    const upcomingActions = sortedActions?.filter(({ displayDate }) => displayDate >= new Date().toLocaleDateString('en-CA'));
    const daysToNextAction = upcomingActions?.length > 0 ? Math.ceil((new Date(upcomingActions[0].displayDate) - new Date()) / (1000 * 60 * 60 * 24)) : 0;
    const completedActions = actions?.filter(({ completed }) => completed);
    const completionPercentage = actions?.length > 0 ? Math.round((completedActions.length / actions.length) * 100) : 0;
    const numberOfRecentDays = 14;
    const recentlyCompletedActions = actions?.filter(({ completed, completedAt }) => completed && (new Date() - new Date(completedAt)) / (1000 * 60 * 60 * 24) <= numberOfRecentDays);
    return (
        <Info.Container label={"Info"} className='w-full @[700px]:min-w-72'>
            <Info.Field value={student?.name} label={t("student")} />
            <Info.Field value={latestComment ? new Date(latestComment.createdAt).toLocaleDateString('en-CA') : null} label={t("last-updated")} noValueLabel={t("no-update")} />
            <Info.Field
                value={upcomingActions?.length > 0 ? daysToNextAction === 0 ? t("today") : `${daysToNextAction} ${t("days")}` : null}
                label={t("next-action-planned")}
                noValueLabel={t("no-next-action")}
            />
            {actions?.length > 0 && (
                <Info.Field
                    value={`${completedActions?.length}/${actions?.length} (${completionPercentage}%)`}
                    label={t("completed-actions")}
                    noValueLabel={t("no-completed-action")}
                />
            )}
            {actions?.length > 0 && (
                <Info.Field
                    value={recentlyCompletedActions?.length || t("none_female")}
                    label={t("completed-in-last-x-days", { days: numberOfRecentDays })}
                    noValueLabel={t("no-completed-action")}
                />
            )}
        </Info.Container>
    );
}
