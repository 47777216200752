import { fetchCall } from 'api';
import { isString } from 'utils/var_check';

/*
  Search document groups
*/
export const searchDocumentGroups = ({ search, page, limit, orderBy, orderDirection, period, team }) => {
    const options = {
        method: "GET",
        endpoint: "/document-groups",
        query: { search, page, limit, orderBy, orderDirection, period, team }
    }
    return fetchCall(options)
};

/*
  Update document groups
*/
export const updateDocumentGroup = ({ code, name, description, initials, color }) => {
    const options = {
        method: "POST",
        endpoint: `/document-groups/${code}`,
        body: { name, description, color, initials }
    }
    return fetchCall(options)
};

/*
  Retrieve document group
*/
export const retrieveDocumentGroup = ({ code }) => {
    const options = {
        method: "GET",
        endpoint: `/document-groups/${code}`
    }
    return fetchCall(options)
};

/*
  Delete document group
*/
export const deleteDocumentGroup = ({ code }) => {
    const options = {
        method: "DELETE",
        endpoint: `/document-groups/${code}`
    }
    return fetchCall(options)
};

/*
  Create document group
*/
export const createDocumentGroup = ({ name, description, initials, color }) => {
    const options = {
        method: "POST",
        endpoint: `/document-groups`,
        body: { name, description, color, initials }
    }
    return fetchCall(options)
};



/*
  Search document groups
*/
export const searchDocumentGroupPeriods = ({ school, period, team, group, page, limit, orderBy, orderDirection }) => {
    const options = {
        method: "GET",
        endpoint: "/document-group-periods",
        query: { school, period, group, team, page, limit, orderBy, orderDirection }
    }
    return fetchCall(options)
};

/*
  Update document groups
*/
export const updateDocumentGroupPeriod = ({ id, period, group, shouldBeReceivedAt, school }) => {
    const options = {
        method: "POST",
        endpoint: `/document-group-periods/${id}`,
        body: {
            period: period && (isString(period) ? period : period.slug),
            group: group && (isString(group) ? group : group.code),
            school: school && (isString(school) ? school : school.slug),
            shouldBeReceivedAt: (shouldBeReceivedAt && shouldBeReceivedAt.toISOString().slice(0, 10))
        }
    }
    return fetchCall(options)
};

/*
  Retrieve document group
*/
export const retrieveDocumentGroupPeriod = ({ id }) => {
    const options = {
        method: "GET",
        endpoint: `/document-group-periods/${id}`
    }
    return fetchCall(options)
};

/*
  Delete document group
*/
export const deleteDocumentGroupPeriod = ({ id }) => {
    const options = {
        method: "DELETE",
        endpoint: `/document-group-periods/${id}`
    }
    return fetchCall(options)
};

/*
  Create document group
*/
export const createDocumentGroupPeriod = ({ period, group, shouldBeReceivedAt, school }) => {
    const options = {
        method: "POST",
        endpoint: `/document-group-periods`,
        body: {
            period: period && (isString(period) ? period : period.slug),
            group: group && (isString(group) ? group : group.code),
            school: school && (isString(school) ? school : school.slug),
            shouldBeReceivedAt: shouldBeReceivedAt.toISOString().slice(0, 10)
        }
    }
    return fetchCall(options)
};
