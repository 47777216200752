import React from "react"
import { useEffect } from "react"
import { useAPI } from "hooks/useAPI"

/**
 * A component that triggers an API request when clicked.
 *
 * @param {Object} props - The component props.
 * @param {function} props.method - The HTTP method for the API request.
 * @param {Object} props.options - Additional options for the API request.
 * @param {Object} props.options.onResult - A callback function to handle the API response.
 * @param {Object} props.options.onCatch - A callback function to handle the API error.
 * @param {boolean} props.options.immediate - Indicates if the API request should be executed immediately.
 * @param {Object} props.options.validateParams - A function to validate the API parameters.
 * @param {boolean} props.options.camelize - Indicates if the API response should be camelized.
 * @param {Object} props.options.casingWhitelist - A list of keys to NOT convert to camel case.
 * @param {Object} props.params - Parameters for the API request.
 * @param {boolean} props.disabled - Indicates if the trigger is disabled.
 * @param {ReactNode} props.children - The content of the trigger.
 * @param {function} props.setLoading - A function to set the loading state.
 * @returns {ReactNode} The rendered component.
 */
export function APITrigger({method, options, params, disabled, children, setLoading, setError, onClick, ...props}) {
    const [, { execute, loading, error }] = useAPI(
        method, 
        {
            ...params
        }, 
        {
            ...options,
        }
    )
    const handleTrigger = () => {
        if (loading || disabled) return
        onClick?.()
        execute()
    }
    useEffect(() => {
        setLoading?.(loading)
    }, [loading])
    useEffect(() => {
        setError?.(error)
    }, [error])
    return <>
        <div onClick={handleTrigger} {...props}>
            {children}
        </div>
    </>
}