import React from 'react';

// Components
import { EmptyState } from 'components/core/empty'

// Hooks
import { useTranslation } from 'react-i18next';

export default function CoachNotSelected(){
  const { t } = useTranslation();
  return <div className="flex flex-col h-full justify-center items-center">
          <EmptyState title={t("empty-state.no-coach-selected-title")}
              description={t("empty-state.no-coach-selected-description")}/>
          </div>
}
