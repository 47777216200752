import React from 'react';
import classnames from 'classnames';
import { useCallback } from 'react';

import { Button } from 'components/core/button';
import { useNavigate, NavLink } from 'react-router-dom';

export function Tabs({ tabs, state, navClassName}){
   const navigate = useNavigate();
   const handleOptionChange = useCallback((e)=>{
    const tab = tabs.find((tab) => tab.name===e.currentTarget.value)||{};
    if (tab.href){
      navigate(tab.href);
    }
   }, [tabs])
   return <div>
      <div className="py-1 lg:hidden ">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          onChange={handleOptionChange}
          className="block w-full border-gray-300 rounded-md focus:ring-orange-500 focus:border-orange-500"
          defaultValue={(tabs.find((tab) => tab.current)||{}).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block ">
        <nav className={classnames("flex space-x-4", navClassName)} aria-label="Tabs">
          {tabs.map((tab) => (
            <NavLink
              end={tab.end!=null? tab.end : true}
              key={tab.name}
              to={{pathname: tab.disabled?"#": (tab.href || "#")}}
                state={state}
              className={({isActive})=>classnames(
                isActive ? 'text-orange-600 border-b-2 border-orange-600 ' : 'text-gray-400 hover:text-gray-700 ',
                'px-3 py-2 '
              )}
            >
              {tab.name}
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
}

export function StateTabs({ tabs, value, setValue }){
  const handleOptionChange = useCallback((e)=>{
    const tab = tabs.find((tab) => tab.name===e.currentTarget.value)||{};
    setValue(tab);
   }, [tabs])

   return <div>
      <div className="hidden py-1">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          onChange={handleOptionChange}
          className="block w-full border-gray-300 rounded-md focus:ring-orange-500 focus:border-orange-500"
          defaultValue={value.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="block px-6 border-b">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <Button
              key={tab.name}
              onClick={()=>setValue(tab)}
              className={classnames(
                value.name===tab.name ? 'text-orange-600 border-b-2 border-orange-600 ' : 'border-b-2 border-transparent text-gray-400 hover:text-gray-700 ',
                'px-3 py-2 '
              )}
            >
              {tab.name}
            </Button>
          ))}
        </nav>
      </div>
    </div>
}