import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Info } from 'components/core/info';
import { EmptyState } from 'components/core/empty';

// Hooks
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

// Contexts
import { PairingContext } from 'contexts/pairing';

// Utils
import { locale, dateParse} from 'utils/locale';
import { cronToString } from 'utils/crons';

export function PairingInfo(){
  const {pairing} = useContext(PairingContext);
  const { t } = useTranslation('common');

  if (!pairing.student){
    return <Skeleton className="h-80"/>
  }
  return <div className="min-h-screen md:min-h-0 space-y-6"> 
                
            {pairing.ended_at && <EmptyState className="w-full mx-auto" color="danger" description={t("pairing-is-closed", {date: locale.format("%d %B %Y")(dateParse(pairing.ended_at))})}/>}
            <Info.Container>
             {/* <ModifiableSelectField 
                    Field={CheckBox} 
                    name={t("student-has-confirmed-pairing")} 
                    modify={true}
                    value={pairing.student_confirmed} 
                    setValue={(studentConfirmed)=>{updatePairing({id, studentConfirmed}); setPairing(d=>({...d, student_confirmed: studentConfirmed}))}}/>
                <ModifiableSelectField 
                    Field={CheckBox} 
                    name={t("tutor-has-confirmed-pairing")} 
                    modify={true}
                    value={pairing.tutor_confirmed} 
                    setValue={(tutorConfirmed)=>{updatePairing({id, tutorConfirmed}); setPairing(d=>({...d, tutor_confirmed: tutorConfirmed}))}}/>
*/}
              <Info.Field value={cronToString(pairing.period)}/>
              <Info.Field value={pairing.prefered_type && pairing.prefered_type.name || t("no-pairing-type")} label={t("pairing-type")}/>
              <Info.Field value={pairing.student.particular_needs} 
                                        noValueLabel={`${t("none")} ${t("particular-needs").toLowerCase()}`}
                                        label={t("particular-needs")}
                                        />
            </Info.Container>

            <Info.Container>
              {pairing.student_confirmed?
                <Info.Field value={t("student-has-confirmed-pairing")}/>:
                <EmptyState className="w-full mx-auto" color="warning" description={t("student-has-not-confirmed-pairing")}/>
              }
              {pairing.tutor_confirmed?
                <Info.Field value={t("tutor-has-confirmed-pairing")}/>:
                <EmptyState className="w-full mx-auto" color="warning" description={t("tutor-has-not-confirmed-pairing")}/>
              }
            </Info.Container>

                

            <Info.Container >
              <Info.Field value={pairing.started_at && locale.format("%d %B %Y")(dateParse(pairing.started_at))} 
                                        noValueLabel={`${t("none")} ${t("started-at").toLowerCase()}`}
                                        label={t("started-at")}
                                        />
              <Info.Field value={pairing.ended_at && locale.format("%d %B %Y")(dateParse(pairing.ended_at))} 
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("ended-at").toLowerCase()}`}
                                        label={t("ended-at")}
                                        />
            </Info.Container>

         </div>
}
