import React, { useReducer, createContext } from "react";

function filterReducer(state, action){
  switch (action.type){
    case 'search':
      // action-> {type:search, value: 'john'}
      return {...state, search: action.value};
    case 'schoolYear':
      return {...state, schoolYear: action.value};
    case 'school':
      return {...state, school: action.value};
    case 'student':
      return {...state, student: action.value};
    case 'team':
      return {...state, team: action.value};
    case 'tutor':
      return {...state, tutor: action.value};
    case 'pairing':
      return {...state, pairing: action.value};
    case 'date':
      return {...state, date: action.value};
    case 'dates':
      return {...state, ...action.value};
    case 'fromDate':
      return {...state, fromDate: action.value};
    case 'toDate':
      return {...state, toDate: action.value};
    case 'includeNotes':
      return {...state, includeNotes: action.value};
    case 'preset':
      return {...state, preset: action.value};
    case 'group':
      return {...state, group: action.value};
    case 'code':
      return {...state, code: action.value};
    case 'studyCode':
      return {...state, studyCode: action.value};
    case 'studySession':
      return {...state, studySession: action.value};
    case 'ordering':
      if (state.orderBy===action.value){
        if (state.orderDirection==='desc'){
          return {...state, orderBy: action.value, orderDirection: 'asc'};
        }
        return {...state, orderBy: null, orderDirection: null};
      }
      return {...state, orderBy: action.value, orderDirection: 'desc'};
    default:
      return state
  }
}

const SearchOpenNotesContext = createContext({});

const SearchOpenNotesContextProvider = ({ children, defaultState }) => {

  const [filters, dispatchFilters] = useReducer(filterReducer, defaultState || {});

  return (
    <SearchOpenNotesContext.Provider value={{ filters, dispatchFilters }}>
      {children}
    </SearchOpenNotesContext.Provider>
  );
};

export { SearchOpenNotesContext, SearchOpenNotesContextProvider };