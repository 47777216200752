import React, { useCallback, useContext } from 'react';

// API
import { createCommentType, updateCommentType, deleteCommentType } from 'api/notes';

// Hooks
import { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// Constants
import { searchTagsColors } from 'components/tools/search-comment-types';

// Utils
import classNames from 'classnames';
import toast from 'react-hot-toast';

// Contexts
import { SearchContext } from 'contexts/search';

// Components
import { CommentTypeBadge } from 'components/tools/search-comment-types';
import { Table } from 'components/core/table';
import { Button } from 'components/core/button';
import { Fragment } from 'react';
import { SlideOver } from 'components/core/slide_over';
import { InfoSectionHeading, TextError } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';
import { CardContainer } from 'components/core/container';
import { Error } from 'components/core/typo';

export function CommentTypesList({ typesList, setTypesList }) {
    const { t } = useTranslation("common");
    const { filters, dispatchFilters } = useContext(SearchContext);

    const headers = useMemo(() => [
        {
            title: t("color"),
            field: (d => d ? d.color : "color"),
            itemClassName: "py-2 whitespace-nowrap ",
            format: (d => <div className={classNames("w-5 h-5 my-2 ml-3 text-left rounded-md", searchTagsColors[d])} />)
        },
        {
            title: t("comment-type"),
            field: (d => d ? d.name : "name"),
            itemClassName: "py-2 whitespace-nowrap"
        },
        {
            field: (d => d ? ({ value: d, onDelete:() => {setTypesList(current => current.filter(e => e.code != d.code))}, setValue: (next) => { setTypesList(current => current.map(e => e.code === next.code ? next : e)) } }) : t("modify")),
            FormatComponent: ModifyButton
        },
    ], [typesList])

    if (!typesList) {
        return <>
            <InfoSectionHeading className={"my-3 mt-8"} mediumTitle={t("gestion-comment-types")} />
            <Skeleton.List numElements={1} itemClassName="h-8 mt-8" className="space-y-2" />
        </>
    }

    return <>
        <InfoSectionHeading className={"my-3 mt-8"} mediumTitle={t("gestion-comment-types")} />
        <div className='mb-10'>
            {
                typesList &&
                <Table headers={headers}
                    data={typesList}
                    indexingKey="code"
                    order={{ by: filters.orderBy, direction: filters.orderDirection }}
                    onHeaderClick={(value) => dispatchFilters({ type: 'ordering', value })} />
            }
        </div>
    </>
}

function DeleteButton({value, onDelete}){
    const { t } = useTranslation('common');
    const [, {execute, loading, error}] = useAPI(deleteCommentType,
                                                {code: value.code},
                                                {onResult: (()=>onDelete(value)), immediate:false})

    return <div className="w-full p-3 space-y-3 border-2 border-red-500 rounded-lg">
            <div>
                <h3 className="font-medium text-red-600">{t("delete-comment-type")}</h3>
                <p className="text-gray-500">{t("comment-type-deletion")}</p>
            </div>
            <Button loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("comment-type-delete-button")}</Button>
            {error? <Error.Text {...error}/>: null}
            </div>
}

function ModifyButton({ value, setValue, onDelete }) {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    return <Fragment>
        <Button onClick={() => setOpen(true)} size="xs" color="gray">{t("modify")}</Button>
        <SlideOver open={open} setOpen={setOpen} size="xl">
            <ModifyCommentType value={value} setValue={setValue} onDelete={onDelete} />
        </SlideOver>
    </Fragment>
}

function ModifyCommentType({ value, setValue, onDelete }) {
    return <>
        <UpdateCommentTypes value={value} setValue={setValue} onDelete={onDelete} />
    </>
}

export function AddCommentTypes({ setTypesList }) {
    const { t } = useTranslation("common");

    const typeInput = useRef(null);
    const [type, setType] = useState()
    const group = "default"
    const [selectedColor, setSelectedColor] = useState()
    const params = useMemo(() => ({ name: type, color: selectedColor, group }), [type, selectedColor, group])
    const [result, { execute, error, loading }] = useAPI(createCommentType, params, { immediate: false });

    const handleChangeColor = useCallback((color) => {
        if (selectedColor == color) {
            return setSelectedColor(null)
        }
        setSelectedColor(color)
    }, [selectedColor])


    useEffect(() => {
        if (result) {
            setTypesList(current => [...current, result])
            toast.success(t("comment-type-created"))
            typeInput.current.value = ""
            setType("")
            setSelectedColor(null)
        }
    }, [result])

    return <>
        {error && <TextError code={error.code} payload={error.payload} />}
        <InfoSectionHeading mediumTitle={t("add-comment-types")} longDescription={t("comment-type-creation")} />
        <div className='flex flex-wrap items-center mt-3'>
            <div className='flex flex-wrap w-full mb-7 '>
                <ColorSelector colors={searchTagsColors} selectedColor={selectedColor} handleChangeColor={handleChangeColor} />
            </div>
            <div className='flex flex-wrap items-center w-full mt-5 '>
                <div className='flex items-end w-full h-12 max-w-lg'>
                    <div className='relative w-full pt-6'>
                        <div className='absolute top-0 flex justify-between w-full text-sm text-gray-500'>
                            {t("comment-type")}
                            <CommentTypeBadge className='w-fit' text={type && type.trim() || t("preview")} color={selectedColor} />
                        </div>
                        <input ref={typeInput} onChange={(e) => setType(e.target.value)} className={classNames('w-full mt-1.5 p-1.5 border-2 rounded-md focus:ring-orange-500 focus:border-orange-500 outline-none')} placeholder={t("insert-comment-type")} />
                    </div>

                </div>
                <Button className={"max-w-lg mt-3"} disabled={!type || type.trim().length <= 0 || !selectedColor} loading={loading} onClick={execute} color="active" size="md" block >{t("add")}</Button>
            </div>
        </div>
    </>
}

export function UpdateCommentTypes({ value, setValue, onDelete }) {
    const { t } = useTranslation("common");

    const [code,] = useState(value && value.code)
    const [type, setType] = useState(value && value.name)
    const [group,] = useState(value && value.group)
    const [selectedColor, setSelectedColor] = useState(value && value.color)

    const handleChangeColor = useCallback((color) => {
        if (selectedColor == color) {
            return setSelectedColor(null)
        }
        setSelectedColor(color)
    }, [selectedColor])

    const params = useMemo(() => ({ code, name: type, color: selectedColor, group }), [code, type, selectedColor, group])

    const [, { execute, error, loading }] = useAPI(updateCommentType, params, { immediate: false, onResult: () => { toast.success(t("comment-type-updated")); setValue({ code, group, name: type, color: selectedColor }); } });

    return <CardContainer className="mb-6">
        <div className='flex flex-col justify-between h-[calc(100vh-110px)] px-2'>
            <div>
                {error && <TextError code={error.code} payload={error.payload} />}
                <InfoSectionHeading mediumTitle={t("modify-comment-types")} longDescription={t("modify-comment-types-description")} />
                <div className='flex flex-wrap items-center'>
                    <div className='flex flex-wrap w-full mb-5 '>
                        <ColorSelector colors={searchTagsColors} selectedColor={selectedColor} handleChangeColor={handleChangeColor} />
                    </div>
                    <div className='flex flex-wrap items-center w-full mt-5 '>
                        <div className='flex items-end w-full h-12 max-w-lg'>
                            <div className='relative w-full pt-6'>
                                <div className='absolute top-0 flex justify-between w-full text-sm text-gray-500'>
                                    {t("comment-type")}
                                    <CommentTypeBadge className='w-fit' text={type && type.trim() || t("preview")} color={selectedColor} />
                                </div>
                                <input value={type} onChange={(e) => setType(e.target.value)} className={classNames('w-full mt-1.5 p-1.5 border-2 rounded-md focus:ring-orange-500 focus:border-orange-500 outline-none')} placeholder={t("insert-comment-type")} />
                            </div>

                        </div>

                        <Button className={"max-w-lg mt-3"} disabled={!type || type.trim().length <= 0 || !selectedColor} loading={loading} onClick={execute} color="active" size="md" block >{t("modify")}</Button>
                    </div>
                </div>
            </div>
            <div className='mt-10'>
                <DeleteButton value={value} onDelete={onDelete}/>
            </div>
        </div>
    </CardContainer>
}

export function ColorSelector({ colors, selectedColor, handleChangeColor }) {
    return colors && Object.keys(colors).map(color =>
        color != "default" &&
        <div key={color} onClick={() => handleChangeColor(color)} className={classNames('overflow-visible px-1 transition-all duration-75  hover:scale-125 hover:cursor-pointer group hover:z-10', selectedColor == color ? "" : "")}>
            <div className={classNames(searchTagsColors[color], "rounded-lg w-10 h-10 border-[3px] drop-shadow-md group-hover:drop-shadow-lg ", selectedColor == color ? "border-black/50" : "border-white", selectedColor && selectedColor != color ? "opacity-50 hover:opacity-100" : "")}></div>
        </div>
    )
}