import React from 'react';

// Components
import { FiShare2 } from 'react-icons/fi';
import { PresetsList } from 'components/presets/list';

// Hooks
import { useTranslation } from 'react-i18next';

function SettingsPresets(){
  const {t} = useTranslation("common");
  
  return <div className="h-full min-h-screen md:min-h-0 "> 
            <h1 className="info-section">{t('presets')}</h1>
            <PresetsList/>
          </div>
}
const route =  { path: "presets", 
                 name: "Favoris" , 
                 group: "users",
                 Icon: FiShare2,
                 requireAuth: true, 
                 Element: SettingsPresets };
export default route;
