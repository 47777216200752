import React from 'react';

// Components
import { Spinner } from 'components/core/spinner';
import { Error } from 'components/core/typo'

// Utils
import { uploadFile } from 'utils/upload';

// API
import { createUploadUrl, updateDocument } from 'api/documents';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

export function UploadButton({documentId, onCompleted}){
  const {t} = useTranslation('common');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({maxSize:1024*1024*512, maxFiles:1});

  useEffect(()=>{
    if (!acceptedFiles || acceptedFiles.length===0 || !documentId) return;
    // 1. Create the upload url
    // 2. Upload with signed url
    // 3. Update document
    setLoading(true);
    const [createDocPromise] = createUploadUrl({id: documentId, 
                                        numBytes: (acceptedFiles && acceptedFiles.length===1 && acceptedFiles[0].size )|| null,
                                        name: (acceptedFiles && acceptedFiles.length===1 && acceptedFiles[0].path)|| null});

    createDocPromise.then((res)=>{
      if (res.policy){
        return uploadFile(res.policy.url, acceptedFiles[0], res.policy.fields)
      }
      else {
        return true;
      }
    })
    .then(()=>{
      // Upload completed
      const [updateDocPromise] = updateDocument({id: documentId, uploadedAt: new Date()});
      return updateDocPromise
    })
    .then((res)=>{
      setLoading(false);
      onCompleted(res);
    })
    .catch((res)=>{
      try {
        res.json()
            .then(({detail, code, payload})=>{setLoading(false); setError({code, detail, payload})})
            .catch(()=>{setLoading(false); setError({code: 'default'})});
      }
      catch {
        setLoading(false); 
        setError({code: 'default'});
      }
    })
  }, [documentId, acceptedFiles]);

  return <div>
            <div {...getRootProps({className: 'dropzone border-2 text-gray-500 cursor-pointer border-dashed py-8 text-center'})}>
                <input {...getInputProps()} />
                <p className="max-w-md mx-auto">{t("dropzone-description")}</p>
                {loading && <Spinner/>}
              </div>


            {error? <Error.Text className="mt-3" {...error}/>: null}
        </div>
}