import React from 'react';

// Components
import { ModifyTeamMembers } from 'components/teams/modify_team_members'
import { Container } from 'components/core/container';

// Hooks
import { useContext } from 'react';

// Contexts
import {TeamContext} from 'contexts/team';

export default function MemberSettings(){
  const {team} = useContext(TeamContext);

  return <Container className="h-full w-full pb-6">
          <ModifyTeamMembers team={team}/>
         </Container>
}