import { fetchCall } from 'api';

/*
  Create a school subject
*/  
export const createSchoolSubject = ({name}) => {
  const params = {
    method: "POST", 
    endpoint: `/school-subjects`,
    body: {name}
  }
  return fetchCall(params)
};


/*
  Update a school subject
*/  
export const updateSchoolSubject = ({slug, name}) => {
  const params = {
    method: "POST", 
    endpoint: `/school-subjects/${slug}`,
    body: {
      name
    }
  }
  return fetchCall(params)
};