import React from 'react';

// Components
import { SelectSchoolYear } from 'components/tools/school_year_list';
import { SelectSchool } from 'components/tools/schools_list';
import { SearchTeams } from 'components/tools/search_teams';
import { SearchStudents } from 'components/tools/student_search';
import { SelectNoteGroup } from 'components/tools/select_note_group';
import { SearchNoteCode } from 'components/tools/search_note_code';
import { SearchStudyCode } from 'components/tools/select_study_code';
import { SelectActivePairing } from 'components/tools/select_active_pairing';
import { SelectAvailableForPairing } from 'components/tools/select_available_for_pairing';
import { SelectLeftService } from 'components/tools/select_left_service';
import { SearchTutors } from 'components/tools/search_tutor';
import { SelectPreset } from 'components/tools/select_preset';
import { CreateNoteButton } from 'components/notes/create_modal';
import { ShowOpenNoteButton, ShowToDiscussNotesButton } from 'components/notes/active_notes_slideover';
import { SelectShouldDiscuss } from 'components/tools/select_should_discuss';
import { SelectHasActivePairing } from 'components/tools/select_has_active_pairing';

// Hooks
import { useContext } from 'react';

// Context
import { SearchNotesContext } from 'contexts/search_notes';

export function FilterBar({school,
                            schoolYear,
                          student,
                          team,
                          tutor,
                          studyCode,
                          noteGroup,
                          noteCode,
                          pairingActive,
                          availableForPairing,
                          leftService,
                          preset,
                          activePairing,
                          addNote=true,
                          openNotes=true,
                          toDiscuss=true,
                          toDiscussCount=true,
                          Context=SearchNotesContext,
                          limitToUser=true}){
  const { filters, dispatchFilters } = useContext(Context);
  return <div className="flex flex-wrap items-center justify-end w-full space-x-3 space-y-3">
            <div className=""/>
            {addNote &&<CreateNoteButton creatorProps={{defaultStudent: filters.student, defaultTutor: filters.tutor, defaultPairing: filters.pairing, defaultStudySession: filters.studySession}}/>}
            {openNotes && <ShowOpenNoteButton filters={filters}/>}
            {toDiscussCount && <ShowToDiscussNotesButton filters={filters}/>}
            {noteCode && <SearchNoteCode color="default" group={filters.group} value={filters.code} setValue={(value)=>dispatchFilters({type: "code", value})}/>}
            {noteGroup && <SelectNoteGroup color="default" value={filters.group} setValue={(value)=>dispatchFilters({type: "group", value})}/>}
            {studyCode && <SearchStudyCode color="default" size="lg" targetWidth="lg" value={filters.studyCode} setValue={(value)=>dispatchFilters({type: "studyCode", value})}/>}
            {student&&<SearchTutors color="default" value={filters.student} setValue={(value)=>dispatchFilters({type: "student", value})}/>}
            {tutor&&<SearchStudents color="default" value={filters.tutor} setValue={(value)=>dispatchFilters({type: "tutor", value})}/>}
            {team&&<SearchTeams color="default" value={filters.team} setValue={(value)=>dispatchFilters({type: "team", value})}/>}
            {schoolYear&&<SelectSchoolYear color="default" value={filters.schoolYear} setValue={(value)=>dispatchFilters({type: "schoolYear", value})}/>}
            {school&&<SelectSchool color="default" value={filters.school} setValue={(value)=>dispatchFilters({type: "school", value})}/>}
            {pairingActive&&<SelectActivePairing color="default" value={filters.active} setValue={(value)=>dispatchFilters({type: "active", value})}/>}
            {availableForPairing&&<SelectAvailableForPairing color="default" value={filters.availableForPairing} setValue={(value)=>dispatchFilters({type: "availableForPairing", value})}/>}
            {leftService&&<SelectLeftService color="default" value={filters.leftService} setValue={(value)=>dispatchFilters({type: "leftService", value})}/>}
            {preset&&<SelectPreset color="default" value={filters.preset} limitToUser={limitToUser} setValue={(value)=>dispatchFilters({type: "preset", value})}/>}
            {toDiscuss&&<SelectShouldDiscuss value={filters.shouldDiscuss} setValue={(value)=>dispatchFilters({type: "shouldDiscuss", value})}/>}
            {activePairing&&<SelectHasActivePairing value={filters.hasActivePairing} setValue={(value)=>dispatchFilters({type: "hasActivePairing", value})}/>}
          </div>
}