import React from 'react';

// Components
import { Container } from 'components/core/container';
import { FlexNav } from 'components/core/layouts/flex';
import { Outlet } from "react-router-dom";
import { SubSideNavLink } from "components/core/nav";
import { FiSettings, FiUsers, FiCalendar } from 'react-icons/fi';

// Router
import { useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NavBar(){
  const { t } = useTranslation("common");
  const params = useParams();
  return <div >
          <SubSideNavLink path={generatePath(`/teams/:slug/settings/`, params)} Icon={FiSettings} name={t("general-settings")}/>
          <SubSideNavLink path={generatePath(`/teams/:slug/settings/members`, params)} Icon={FiUsers} name={t("team-members")}/>
          <SubSideNavLink path={generatePath(`/teams/:slug/settings/events`, params)} Icon={FiCalendar} name={t("events")}/>
        </div>
}

export function TeamSettings(){
  return <Container className="py-6 h-full" >
          <FlexNav>
            <FlexNav.Bar>
              <NavBar/>
            </FlexNav.Bar>
            <FlexNav.Content>
              <Outlet/>
            </FlexNav.Content>
           </FlexNav>
          </Container>
}

import {default as general} from './general';
import {default as events} from './events';
import {default as members} from './members';
export const TeamSettingsPages = {root: TeamSettings, general, events, members}