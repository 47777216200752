import { fetchCall } from 'api';
import { isString } from 'utils/var_check';

/*
  Search documents
*/  
export const searchDocuments = ({student, group, search, period, page, limit, orderBy, orderDirection}) => {
  const params = {
    method: "GET", 
    endpoint: `/documents`,
    query: {
      student: student && student.ni, 
      group: group && group.code, 
      period: period && (isString(period)? period: period.slug), 
      search, 
      page, 
      limit, 
      orderBy, 
      orderDirection
    }
  }
  return fetchCall(params)
};

/*
  Create a document
*/
export const createDocument = ({student, name, numBytes, group, period, fullySigned, handedOverAt})=>{
  const params = {
    method: "POST", 
    endpoint: `/documents`,
    body: {
      student: student && student.ni, 
      period: period && (isString(period)? period: period.slug), 
      group: group && group.code, 
      name, 
      fullySigned,
      numBytes,
      handedOverAt
    }
  }
  return fetchCall(params)
}

/*
  Update
*/
export const updateDocument = ({id, student, name, numBytes, group, period, fullySigned, comments, handedOverAt, uploadedAt})=>{
  const params = {
    method: "POST", 
    endpoint: `/documents/${id}`,
    body: {
      student: student && student.ni, 
      group: group && group.code, 
      name, 
      fullySigned, 
      comments,
      period: period && (isString(period)? period: period.slug), 
      numBytes,
      handedOverAt,
      uploadedAt
    }
  }
  return fetchCall(params)
}
/*
  Retrieve
*/
export const retrieveDocument = ({id, fields})=>{
  const params = {
    method: "GET", 
    endpoint: `/documents/${id}`,
    query: {fields}
  }
  return fetchCall(params)
}
/*
/*
  Delete
*/
export const deleteDocument = ({id})=>{
  const params = {
    method: "DELETE", 
    endpoint: `/documents/${id}`
  }
  return fetchCall(params)
}
/*
  upload File
*/
export const createUploadUrl = ({id, name, numBytes})=>{
  const params = {
    method: "POST", 
    endpoint: `/documents/${id}/upload-file`,
    body: {
      name, 
      numBytes,
    }
  }
  return fetchCall(params)
}

/*
  Search note grouped by date/student
*/
export function searchGroupedDocuments({team, 
                              preset, 
                            fromDate, 
                            toDate, 
                            group,
                            period,
                            student,
                            showLeftServiceStudents,
                            page, 
                            limit, 
                            orderBy, 
                            orderDirection}){
  const options = {
    method: "GET", 
    endpoint: "/grouped-documents",
    query: {student: student && (isString(student)? student: student.ni), 
            team: team && (isString(team)? team: team.slug), 
            preset: preset && (isString(preset)? preset: preset.id), 
            fromDate: fromDate && fromDate.toISOString(), 
            toDate: toDate && toDate.toISOString(), 
            group: group && (isString(group)? group: group.code), 
             period: period && (isString(period)? period: period.slug), 
             showLeftServiceStudents,
            page, 
            limit, 
            orderBy, 
            orderDirection}
  }
  return fetchCall(options)
}

/*
  Set document student status
*/
export const setDocumentStudentStatus = ({document, code})=>{
  const params = {
    method: "POST", 
    endpoint: `/documents/${document}/set-student-status`,
    body: {
      code
    }
  }
  return fetchCall(params)
}