import React from 'react';

import classnames from 'classnames';

function StatsContainer({className, children}){
    return <div className={classnames("grid grid-cols-2 gap-3 mx-auto max-w-7xl", className)}>
                {children}
            </div>
}

function StatsElement({name, description, value, unit, extra, style, className}){
   return <div  className={classnames("px-3 py-3 bg-gray-100 rounded-md flex flex-col justify-end", className)} style={style}>
          <p className="font-medium leading-6 text-gray-600">{name}</p>
          <p className="text-sm font-medium leading-6 text-gray-400">{description}</p>
          <p className="mt-2 flex items-baseline gap-x-2">
            <span className="text-4xl font-semibold tracking-tight text-gray-800 ">{value}</span>
            {unit ? <span className="text-sm text-gray-400">{unit}</span> : null}
            {extra ? <span className=" text-blue-600">{extra}</span> : null}
          </p>
        </div>
}

export const Stats = {Container: StatsContainer, Element: StatsElement};