import React from 'react';


// Contexts
import {PairingContext} from 'contexts/pairing';
import { SearchNotesContextProvider } from 'contexts/search_notes';

// Hooks
import { useContext } from 'react';

// Components
import { Container } from 'components/core/container';
import { NotesCardWithContext } from 'components/notes/list';

export default function PairingNotes(){
  const { pairing } = useContext(PairingContext);
  return <SearchNotesContextProvider doNotStore defaultState={{pairing: pairing.id, orderBy: "latest_comment", orderDirection: "desc"}}>
            <Container className="">
              <NotesCardWithContext pairing={pairing}/>
            </Container>
          </SearchNotesContextProvider>
}
