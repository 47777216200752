import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useAPI } from 'hooks/useAPI';

// Components
import { Button } from 'components/core/button';
import { InfoSectionHeading } from 'components/core/typo';
import { Error } from 'components/core/typo'
import { RuleMakerForm } from 'components/tournament-rules/rule-maker';

// Utils
import { orderByValues } from 'components/tournament-rules/tournament-rules';
import toast from 'react-hot-toast';

// API
import { createTournamentRule } from 'api/tournament-rules';

export function CreateTournamentRule({ onCompleted }) {
    const { t } = useTranslation("common");
    const [sport, setSport] = useState();
    const [description, setDescription] = useState("");
    const [nbWeeks, setNbWeeks] = useState("");
    const [nbGames, setNbGames] = useState("");
    const [weeks, setWeeks] = useState([]);
    const [orderWeeksBy, setOrderWeeksBy] = useState(orderByValues[0]);
    const [highlightBest, setHighlightBest] = useState(false);
    const [highlightWorst, setHighlightWorst] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const [result, { loading, execute, error }] = useAPI(createTournamentRule, {
        sport,
        numWeeks: nbWeeks,
        numGames: nbGames,
        gamesToWeeksMap: weeks,
        description,
        orderWeeksBy: orderWeeksBy && orderWeeksBy.slug,
        highlightBest,
        highlightWorst
    }, { immediate: false });

    useEffect(() => {
        if (result && onCompleted) {
            onCompleted(result);
            toast.success(t("tournament-rules.add-success"));
        }
    }, [result])

    return (
        <div className='min-h-[calc(100vh-85px)] flex flex-col justify-between px-3 sm:px-6'>
            <div>
                <InfoSectionHeading mediumTitle={t("tournament-rules.add")} />
                <RuleMakerForm
                    sport={sport} setSport={setSport}
                    description={description} setDescription={setDescription}
                    nbWeeks={nbWeeks} setNbWeeks={setNbWeeks}
                    nbGames={nbGames} setNbGames={setNbGames}
                    weeks={weeks} setWeeks={setWeeks}
                    orderWeeksBy={orderWeeksBy} setOrderWeeksBy={setOrderWeeksBy}
                    highlightBest={highlightBest} setHighlightBest={setHighlightBest}
                    highlightWorst={highlightWorst} setHighlightWorst={setHighlightWorst}
                    setIsValid={setIsValid}
                    loading={loading}
                />
            </div>
            <div className='pt-6 pr-2 border-t'>
                {error && <Error.Text className={"mb-2"} {...error} />}
                <Button onClick={execute} loading={loading} disabled={isValid} color={"active"} size={"lg"} block >{t("tournament-rules.add-button")}</Button>
            </div>
        </div>
    )
}

