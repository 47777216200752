import React from 'react';

// Components
import { SingleLineField } from 'components/tools/modifiable_fields';
import { Info } from 'components/core/info';

// Hooks
import { useUser } from 'hooks/useUser';
import { useTranslation } from 'react-i18next';

// API
import { updateUser } from 'api/users';
import { changeEmail } from 'api/auth';

export function ModifyGeneralProfile(){
  const modify = true;
  const [user, setUser] = useUser();
  const { t } = useTranslation('common');

  return <div className="h-full min-h-screen md:min-h-0 "> 
            <h1 className="info-section">{t('personal-info')}</h1>
            <Info.Container modify={modify} className="space-y-0">
                  <SingleLineField 
                    label={modify&&t("firstname")} 
                    modify={modify} 
                    value={user.firstname} 
                    setAPI={{name: "firstname", method: updateUser}}
                    setValue={(firstname)=>{setUser(d=>({...d, firstname}));}}>
                    {(val)=><Info.Field value={val} noValueLabel={`${t("none")} ${t("firstname").toLowerCase()}`}/>}
                  </SingleLineField>
                  <SingleLineField 
                    label={modify&&t("lastname")} 
                    modify={modify} 
                    value={user.lastname} 
                    setAPI={{name: "lastname", method: updateUser}}
                    setValue={(lastname)=>{setUser(d=>({...d, lastname}));}}>
                    {(val)=><Info.Field value={val} noValueLabel={`${t("none")} ${t("lastname").toLowerCase()}`}/>}
                  </SingleLineField>
                  <SingleLineField 
                            label={modify&&t("email")} 
                            modify={modify} 
                            value={user.email} 
                            setAPI={{name: "email", method: changeEmail}}
                            setValue={(email)=>{setUser(d=>({...d, email}));}}>
                    {(val)=><Info.Field value={val} noValueLabel={`${t("none")} ${t("email").toLowerCase()}`}/>}
                  </SingleLineField>
            </Info.Container>

      
         </div>
}