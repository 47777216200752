import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { EndYearAssistantView } from 'components/end-year-assistant';
import { FiLogOut } from 'react-icons/fi';

export function EndYearAssistantBase(){
  return <DashboardLayout>
          <EndYearAssistantView/>
         </DashboardLayout>
}

const route =  { path: "/end-year-assistant",
                 name: "Fermeture de l'année" , 
                 Icon: FiLogOut,
                 group: 'outils',
                 isNested: true,
                 showInNavBar: true, 
                 requireAuth: true, 
                 Element: EndYearAssistantBase };
export default route;

import { default as coachs } from 'pages/end-year-assistant/coachs'
import { default as tutors } from 'pages/end-year-assistant/tutors'
import { default as students } from 'pages/end-year-assistant/students'
import { default as pairings } from 'pages/end-year-assistant/pairings'
import { default as welcome } from 'pages/end-year-assistant/welcome';
export const EndYearAssistantPages = {root: EndYearAssistantBase, coachs, tutors, students, pairings, welcome}

