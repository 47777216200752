import React, {createContext } from "react";

// Hooks
import { useParams } from 'react-router-dom';
import { useMemo, useEffect } from 'react';
import { usePairing } from 'hooks/pairings/usePairing';

const PairingContext = createContext({});

const PairingContextProvider = ({ children }) => {
  const params = useParams();
  const immediate = useMemo(()=>false, []);
  const [pairing, {loading, error, execute, setResult}] = usePairing({immediate});
  useEffect(()=>{
    execute()
  }, [params])

  return (
    <PairingContext.Provider value={{ pairing, loading, error, setPairing: setResult, execute }}>
      {children}
    </PairingContext.Provider>
  );
};

export { PairingContext, PairingContextProvider };