import React from 'react';


// Contexts
import { StudentContext } from 'contexts/student';
import { SearchNotesContextProvider } from 'contexts/search_notes';

// Hooks
import { useContext } from 'react';

// Components
import { Container } from 'components/core/container';
import { NotesCardWithContext } from 'components/notes/list';

export default function StudentNotes(){
  const { student } = useContext(StudentContext);
  return <SearchNotesContextProvider defaultState={{student: student.ni, orderBy: "latest_comment", orderDirection: "desc"}}>
            <Container className="">
              <NotesCardWithContext student={student}/>
            </Container>
          </SearchNotesContextProvider>
}
