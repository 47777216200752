import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { Error } from 'components/core/typo'
import { Button } from 'components/core/button';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useAPI } from 'hooks/useAPI';

// API
import { createDocumentGroupPeriod } from 'api/document_groups';
import { SelectSchool } from 'components/tools/schools_list';
import { SelectPeriods } from 'components/tools/select_period';
import { SelectDocumentGroup } from 'components/tools/select_document_group';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { SelectDate } from 'components/tools/select_date';

export function CreateDocumentGroupPeriod({onCompleted}){
  const [draftDocumentGroupPeriod, setDraftDocumentGroupPeriod] = useState({});
  const [noteCode, {loading, execute, error}] = useAPI(createDocumentGroupPeriod, draftDocumentGroupPeriod, {immediate:false});
  const {t} = useTranslation('common');

  useEffect(()=>{
    if (noteCode) onCompleted(noteCode);
  }, [noteCode])

  return <div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("add-document-group-period.title")}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
              <ModifiableSelectField
          Field={SelectDocumentGroup}
          label={t("add-document-group-period.document-group-title")}
          subLabel={t("add-document-group-period.document-group-description")}
          modify={true}
          value={draftDocumentGroupPeriod.group}
          setValue={(group)=>setDraftDocumentGroupPeriod(d=>({...d, group}))}
        />
        <ModifiableSelectField
                            Field={SelectSchool}
                            label={t("add-document-group-period.school-title")}
                            subLabel={t("add-document-group-period.school-description")}
                            modify={true}
                            value={draftDocumentGroupPeriod.school}
                            setValue={(school)=>setDraftDocumentGroupPeriod(d=>({...d, school}))}
                          />

              <ModifiableSelectField
                            Field={SelectPeriods}
                            label={t("add-document-group-period.period-title")}
                            subLabel={t("add-document-group-period.period-description")}
                            modify={true}
                            value={draftDocumentGroupPeriod.period}
                            setValue={(period)=>setDraftDocumentGroupPeriod(d=>({...d, period}))}
                          />

                  <ModifiableSelectField
                  Field={SelectDate}
                  label={t("add-document-group-period.start-date-title")}
                  subLabel={t("add-document-group-period.start-date-description")}
                  modify={true}
                  value={draftDocumentGroupPeriod.shouldBeReceivedAt}
                  setValue={(shouldBeReceivedAt)=>setDraftDocumentGroupPeriod(d=>({...d, shouldBeReceivedAt}))}
                />
         
                <Button onClick={execute} 
                      disabled={!draftDocumentGroupPeriod.group  || !draftDocumentGroupPeriod.period || !draftDocumentGroupPeriod.school} 
                      loading={loading} 
                      block 
                      size="lg" 
                      color="active">{t("add-document-group-period.button")}</Button>
                {error&& <Error.Text {...error}/>}
              </CardContainer>
            </div>


}