import React from 'react';
import classnames from 'classnames';

// Hooks
import { useState } from 'react'

// Components
import { SearchField } from 'components/tools/search_field'; 
import { useTranslation } from 'react-i18next';

// API
import { searchTutors } from 'api/tutors';
import { useSearch } from 'hooks/useSearch';

export function SearchTutors({value, setValue, showNi, disabled, defaultParams, color, size, description, label}){
  const { t } = useTranslation('common');
  const [params, setParams] = useState(defaultParams);
  const [tutors, {loading}] = useSearch(searchTutors, params, {limit:50, fields: ["ni", "name"]})
  return <SearchField values={tutors}
                      loading={loading}
                      description={showNi? value && value.ni :description}
                      value={value}
                      setValue={setValue}
                      disabled={disabled}
                      color={color}
                      size={size}
                      label={label}
                      placeholder={t("tutors")}
                      indexingField={"ni"}
                      formatSelectedValue={(d=>d.name)}
                      formatSearchResult={((tutor, {selected, active})=>
                        <div>
                          <span className={classnames('block truncate', (active || selected) && 'font-semibold')}>{tutor.name}</span>
                          {showNi && <span className={classnames('block opacity-50')}>{tutor.ni}</span>}
                        </div>)}
                     onParamsChange={setParams}
  />
}
