import React from 'react';
import { Fragment, useEffect, useContext } from 'react'
import classnames from 'classnames';

// Components
import { Dialog, Transition } from '@headlessui/react'
import { FiX } from 'react-icons/fi';
import { Button } from 'components/core/button';

import { ModalIsOpenContext } from 'contexts/modalIsOpen';

export function Modal({ open, setOpen, disableOutsideClick, children, initialFocus, size = "sm", buttonLabel, closeBtnColor, removeCloseButton, unmount = true, className }) {
    const sizes = {
        xs: 'sm:max-w-xl',
        sm: 'sm:max-w-2xl',
        md: 'sm:max-w-3xl',
        lg: 'sm:max-w-5xl',
        xl: 'sm:max-w-7xl',
        screen: 'sm:max-w-screen',
    }
    const context = useContext(ModalIsOpenContext);
    useEffect(() => context && context.setOpen && context.setOpen(open), [open]);

    return (<Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={disableOutsideClick ? (() => null) : setOpen} initialFocus={initialFocus} unmount={unmount}>
            <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 mx-4 ">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div className={classnames("inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all  sm:align-middle w-full relative p-6 sm:p-6", sizes[size], className)}>
                        {!removeCloseButton && <div className="absolute top-0 right-0 translate-y-4 z-20 flex items-center justify-end px-4 sm:px-6">
                            <Button onClick={() => setOpen(false)} size={buttonLabel ? "md" : "toolbarIcon"} color={closeBtnColor || "gray"} className="rounded-md">{buttonLabel ? buttonLabel : <FiX />}</Button>
                        </div>}
                        {children}
                    </div>
                </Transition.Child>
            </div>
        </Dialog>
    </Transition.Root>)
}

export function ModalDescription({ children, className }) {
    return <div className="mt-2">
        <div className={classnames("text-sm text-gray-500", className)}>
            {children}
        </div>
    </div>
}
export function ModalBody({ children, className }) {
    return <div className={classnames("mt-2", className)}>
        {children}
    </div>
}

export function ModalTitle({ children }) {
    return <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
        {children}
    </Dialog.Title>
}

export function ModalBasic({ Icon, title, description, submitLabel, onClick, children, disabled }) {
    return <>
        <div>
            {
                Icon && <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-orange-100 ">
                    <Icon className="h-6 w-6 text-orange-600" aria-hidden="true" />
                </div>
            }
            <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg font-semibold leading-6 text-gray-900">
                    {title}
                </h3>
                <div className="mt-2 ">
                    <p className=" text-gray-500">
                        {description}
                    </p>
                </div>
                {children}
            </div>
        </div>
        <div className="mt-5 sm:mt-6">
            <Button disabled={disabled} block color="active" size="md" onClick={onClick} >
                {submitLabel}
            </Button>
        </div>
    </>
}

// const iconColors = {
//   primary: 'bg-orange-100 text-orange-600',
//   red: 'bg-red-100 text-red-600'
// }
// export function ModalLayout({ children, Icon, setOpen, color}){

//   return ( <div className="h-full w-full">
//               <div className="hidden sm:block absolute top-0 right-0 pt-6 pr-4">
//                 <Button
//                   color="baseGray"
//                   size="icon"
//                   onClick={() => setOpen(false)}
//                 >
//                   <FiX className="h-6 w-6" aria-hidden="true" />
//                 </Button>
//               </div>
//               <div className="sm:flex sm:items-start mr-4 py-3 w-full">
//                 <div className={classnames("mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-14 sm:w-14", iconColors[color])}>
//                   <Icon className="h-6 w-6" aria-hidden="true" />
//                 </div>
//                 <div className="flex-1 mt-3 sm:mt-0 sm:ml-4 text-center sm:text-left">
//                   {children}
//                 </div>
//               </div>
//             </div>)
// }

Modal.Description = ModalDescription;
Modal.Title = ModalTitle;
// Modal.Layout = ModalLayout;
Modal.Body = ModalBody;
