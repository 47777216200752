/* eslint-disable */
import React from "react"

// Components
import { Pill } from "components/core/pill";
import { Link } from "react-router-dom";

// Utils
import classNames from "classnames";

// Hooks
import { useTranslation } from "react-i18next";

export const STUDENT_FAIL_CODES = ["ea-prioritaire", "ea-prio-echecs-socio"]
export const STUDENT_AT_RISK_CODES = ["ea-semi-prio-notes-en-bas-de-70-sans-echec-ni-compsocio"]
export const STUDENT_SUCCESS_CODES = ["rencontre-ea-coordo-non-prioritaire"]

export const STUDENT_STATUS_CODES = [
    {
        code: STUDENT_SUCCESS_CODES[0],
        name: "ÉA non prioritaire"
    },
    {
        code: STUDENT_FAIL_CODES[0],
        name: "ÉA PRIO (échecs)"
    },
    {
        code: STUDENT_AT_RISK_CODES[0],
        name: "ÉA semi-PRIO (notes en bas de 70%)"
    }
]

export const STUDENT_STATUS_CODES_BY_STATUS = {
    "success": STUDENT_STATUS_CODES.find(status => status.code === STUDENT_SUCCESS_CODES[0]),
    "fail": STUDENT_STATUS_CODES.find(status => status.code === STUDENT_FAIL_CODES[0]),
    "at-risk": STUDENT_STATUS_CODES.find(status => status.code === STUDENT_AT_RISK_CODES[0])
}

export function getStudentStatusFromNotes(notes) {
    const notesByCreationDate = notes?.sort((a, b) => new Date(b?.openedAt) - new Date(a?.openedAt))
    notesByCreationDate?.forEach(note => {
        if (!note) return
        if (typeof note?.code === "object") {
            note.code = note?.code?.code
        }
    })
    const latestFromStatus = notesByCreationDate?.find(note => [...STUDENT_SUCCESS_CODES, ...STUDENT_AT_RISK_CODES, ...STUDENT_FAIL_CODES].includes(note?.code))
    if (!latestFromStatus) return {}
    if (STUDENT_SUCCESS_CODES.includes(latestFromStatus.code)) return {status: "success", note: latestFromStatus}
    if (STUDENT_AT_RISK_CODES.includes(latestFromStatus.code)) return {status: "at-risk", note: latestFromStatus}
    if (STUDENT_FAIL_CODES.includes(latestFromStatus.code)) return {status: "fail", note: latestFromStatus}
}

export function StatusLegend(){
    const { t } = useTranslation("common");
    return <>
        <div className="">
            {t("student-status-info")}
        </div>
    </>
}

export function Status({ notes, loading, className }) {
    const { t } = useTranslation("common");
    const { status, note } = getStudentStatusFromNotes(notes)
    return <>
        <div className={classNames(className)}>
            {
                (!loading && status && note) && <Link to={`?noteId=${note?.id}`}>
                    <Pill.Empty color={status == "fail" ? "red" : status === "at-risk" ? "yellow" : "green"} className={"h-fit w-fit !py-0.5 !px-1.5"}>
                        {t("students-metrics.status." + status)}
                    </Pill.Empty>
                </Link>
            }
        </div>
    </>
}