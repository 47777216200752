// Hooks
import { useParams, useLocation } from 'react-router-dom';
import { useAPI } from 'hooks/useAPI';
import { useMemo } from 'react';

// API
import { retrieveStudent } from 'api/students';

export function useStudent({ immediate=false }){
  const { ni } = useParams();
  const { state } = useLocation(); // Persitent state
  const params = useMemo(()=>({ni}), [ni]);
  const [result, others] = useAPI(retrieveStudent, params, {immediate});
  return [state || result || {ni}, others];
}