import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { Container } from 'components/core/container';
import { NewTutor } from 'components/tutors/create';


export function CreateTutorPage(){
  return <DashboardLayout>
          <Container className="h-full">
            <NewTutor/>
          </Container>
         </DashboardLayout>
}

const route =  { path: "/new-tutor", 
                 requireAuth: true, 
                 Element: CreateTutorPage };
export default route;
