import React from 'react';
import classnames from 'classnames';

export function FlexNav({ children, className, breakdown="xl"}){

  return <div className={classnames("max-w-6xl mr-auto space-y-6 xl:space-y-0 xl:flex h-full w-full", breakdown==="xl"? "xl:space-y-0 xl:flex xl:space-x-6": breakdown==="lg"? "lg:space-y-0 lg:flex lg:space-x-6": "md:space-y-0 md:flex md:space-x-6",  className)}>
          {children}
         </div>
}

export function FlexNavBar({ children, className, breakdown="xl" }){

  return <div className={classnames("w-full", breakdown==="xl"? "xl:max-w-xs": breakdown==="lg"? "lg:max-w-sm": "md:max-w-xs", className)}>
          {children}
         </div>
}

export function FlexNavContent({ children, className, breakdown="xl" }){

  return <div className={classnames("w-full", breakdown==="xl"? "xl:flex-auto": breakdown==="lg"? "lg:flex-auto": "md:flex-auto",  className)}>
          {children}
         </div>
}

FlexNav.Bar = FlexNavBar
FlexNav.Content = FlexNavContent




export function FullFlexNav({ children, className, breakdown="xl"}){

  return <div className={classnames("space-y-6 xl:space-y-0 xl:flex h-full w-full", breakdown==="xl"? "xl:space-y-0 xl:flex xl:space-x-6": breakdown==="lg"? "lg:space-y-0 lg:flex lg:space-x-6": "md:space-y-0 md:flex md:space-x-6",  className)}>
          {children}
         </div>
}

export function FullFlexNavBar({ children, className, breakdown="xl" }){

  return <div className={classnames("w-full", breakdown==="xl"? "xl:max-w-xs": breakdown==="lg"? "lg:max-w-sm": "md:max-w-xs", className)}>
          {children}
         </div>
}

export function FullFlexNavContent({ children, className, breakdown="xl" }){

  return <div className={classnames("w-full", breakdown==="xl"? "xl:flex-auto": breakdown==="lg"? "lg:flex-auto": "md:flex-auto",  className)}>
          {children}
         </div>
}

FullFlexNav.Bar = FullFlexNavBar
FullFlexNav.Content = FullFlexNavContent