import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useIsMounted } from 'hooks/useIsMounted';
import { Casing } from "utils/casing";
/*
*/
export function useSearch(method, params, { onResult,
    camelize = false,
    casingWhitelist,
    limit = 50,
    immediate = true,
    fields,
    dynamicFields,
    validateParams } = {}) {
    const [page, setPage] = useState(1);
    const [paging, setPaging] = useState();
    const [result, setResult] = useState();
    const [loading, setLoading] = useState(immediate);
    const [error, setError] = useState();
    const activeController = useRef();
    const isMounted = useIsMounted();

    const execute = useCallback(() => {
        if (!method) return;
        if (validateParams && !validateParams(params)) return;
        setLoading(true);
        setError();
        const handleSuccess = (res) => {
            const camelRes = Casing.recursiveCamelize(res, { whitelist: casingWhitelist });
            res = camelize ? camelRes : res;
            if (!isMounted.current) return;
            setResult(res.results);
            setPaging({ page: res.page, limit: res.limit, totalPages: res.total_pages, numPageResults: res.num_results, numResults: res.total_results })
            setLoading(false);
            if (onResult) {
                return onResult(res);
            }
        }
        const onError = (res) => {
            if (!isMounted.current) return;
            setLoading(false);
            try {
                res.json()
                    .then(({ detail, code, payload }) => { setLoading(false); setError({ code, detail, payload }) })
                    .catch(() => { setLoading(false); setError({ code: 'default' }) });
            }
            catch {
                setLoading(false);
                setError({ code: 'default' });
            }
        }
        if (activeController.current) activeController.current.abort();
        const [promise, { controller }] = method({ ...params, fields: fields || dynamicFields, page, limit })
        activeController.current = controller;
        return promise.then(handleSuccess).catch(onError);
    }, [method, onResult, params, dynamicFields, page])

    const hasMore = useMemo(() => {
        if (!paging) return;
        return (paging.page < paging.totalPages) || paging.page > 1;
    }, [paging]);

    // Fire when page reload
    useEffect(() => {
        if (immediate) execute();
    }, [execute]);

    return [result, { loading, error, setResult, execute, page, paging, setError, setLoading, setPage, hasMore }];
}

