import React, { useCallback } from 'react';

// API
import { 
    retrievePortalUser,
    sendActivationEmail,
    searchPortalUserAuthHistory,
    updateSchoolAccess,
    blockPortalUser,
    unblockPortalUser,
    updatePortalUser,
    deletePortalUser,
    toggleVerificationPortalUser
} from 'api/portal';

// Hooks
import { useQuery } from 'hooks/useQuery';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useMemo, useEffect } from 'react';
import { useAPI } from 'hooks/useAPI';
import { useSearch } from 'hooks/useSearch';
import { useTranslation } from 'react-i18next';

// Utils
import { dateParse, locale, parseWithTimeZone } from 'utils/locale';

// Components
import { ButtonField, ModifiableSelectField, SingleLineField } from 'components/tools/modifiable_fields';
import { CardContainer } from 'components/core/container';
import { CardSectionHeading, InfoSectionHeading, RawTextSuccess, RawTextWarning } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';
import { Error } from 'components/core/typo';
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';
import { EmptyState } from 'components/core/empty';
import { SelectSchool } from 'components/tools/schools_list';
import { FiCheck, FiX } from 'react-icons/fi';

function AuthHistory({portalUser}){
    const {t} = useTranslation("common");
    const params = useMemo(()=>({ni: portalUser&&portalUser.ni, orderBy:"created_at", orderDirection:"desc"}), [portalUser]);
    const [results, {error, loading}] = useSearch(searchPortalUserAuthHistory, params, {limit:20});
    const headers = useMemo(()=>[
        {title: "id", field: 'id'},
        {title: t("status"), field: (d=>d? t(`auth-history.status.${d.status}`): 'status')},
        {title:  t("login-at"),
        field: (d=>d? locale.format("%d %B %Y à %H:%M:%S")(parseWithTimeZone(d.created_at)):'created_at')
        },
    ], []);
    return <Info.Container label={t("auth-history.label")} className={"px-3 py-2"}>
        {error && <Error.Text className="mt-3" {...error}/>}
        {loading? <Skeleton className="h-24"></Skeleton>:
            (results || []).length===0? <EmptyState title={t("auth-history.empty")}/>:
            <Table headers={headers} data={results} indexingKey={'id'}/>}
    </Info.Container>
}

export function ChangeEmail({portalUser, setPortalUser}){
    const onResult = useCallback(({email})=>{setPortalUser((prev)=>({...prev, email}))}, [setPortalUser]);
    const [, {execute, error}] = useAPI(updatePortalUser, {ni: portalUser&&portalUser.ni}, {immediate:false, onResult});
    const {t} = useTranslation("common");

    if (!portalUser){
        return <Skeleton className="m-6 h-80"></Skeleton>
    }
    return <Info.Container modify={true} className={"p-4 border"}>
            <InfoSectionHeading smallTitle={t("change-portal-user-email")}/>
            <SingleLineField
            label={t("email")}
            modify={true}
            value={portalUser.email}
            setValue={(email) => { execute({email });}}/>
            {error && <Error.Text className="mt-3" {...error}/>}
            <RawTextWarning>{t("change-portal-user-email-warning")}</RawTextWarning>
    </Info.Container>

}

export function UserView({portalUser, setPortalUser}){
    const {t} = useTranslation("common");
    const [, block] = useAPI(blockPortalUser, {ni: portalUser&&portalUser.ni}, {immediate:false, onResult: setPortalUser});
    const [, unblock] = useAPI(unblockPortalUser, {ni: portalUser&&portalUser.ni}, {immediate:false, onResult: setPortalUser});
    const [, deleteExec] = useAPI(deletePortalUser, {ni: portalUser&&portalUser.ni}, {immediate:false, onResult: setPortalUser})
    const [, toggleVerification] = useAPI(toggleVerificationPortalUser, {ni: portalUser&&portalUser.ni}, {immediate:false, onResult: setPortalUser})
    const [activationResults, sendActivation] = useAPI(sendActivationEmail, {ni: portalUser&&portalUser.ni}, {immediate:false, onResult: (()=>setPortalUser())});

    if (!portalUser){
        return <Skeleton className="m-6 h-80"></Skeleton>
    }
    return <div className="flex flex-col flex-1 h-full">
                <CardContainer>
                    <CardSectionHeading title={t("portal-user")} />
                </CardContainer>
                <div className={"mx-5 space-y-6 mb-6"}>
                    <Info.Container >
                        <Info.Field 
                            value={portalUser?.name}
                            label={t("name")}
                        />
                    </Info.Container>
                </div>
                <div className={"mx-5 space-y-6"}>
                    <Info.Container label={t("connection-info")}>
                        <Info.Field value={t(portalUser.role)} label={t("role")}/>
                        {portalUser.role==="tutor" && <Info.Field value={!portalUser.documents_signed?
                        <><FiX className='inline w-5 h-5 mr-2 text-red-500'/>{t("portal-user-has-required-documents", {context: portalUser.role})}</>:
                        <><FiCheck className='inline w-5 h-5 mr-2 text-green-500'/><span>{t("portal-user-has-no-required-documents", {context: portalUser.role})}</span></>}/>}
                        <Info.Field value={!portalUser.email_verified?
                        <><FiX className='inline w-5 h-5 mr-2 text-red-500'/>{t("portal-user-not-activated")}</>:
                        <><FiCheck className='inline w-5 h-5 mr-2 text-green-500'/><span>{t("portal-user-activated")}</span></>}/>
                        <Info.Field value={portalUser.created_at && locale.format("%d %B %Y")(parseWithTimeZone(portalUser.created_at))}
                        label={t("account-created-at")}/>
                        <Info.Field 
                            value={portalUser.last_connection ? locale.format("%d %B %Y")(parseWithTimeZone(portalUser.last_connection)) : "-"}
                            label={t("last-connection")}/>
                    </Info.Container>
                    <ButtonField
                        label={t("verify-portal-user.title")}
                        subLabel={t("verify-portal-user.description")}
                        onClick={()=>toggleVerification.execute()}
                        error={toggleVerification.error}
                        color="default"
                        size="md"
                        noBorder
                        loading={false}>
                            {portalUser?.verified? <><FiCheck className='inline w-5 h-5 mr-2 text-green-500'/>{t("verified")}</>: <><FiX className='inline w-5 h-5 mr-2 text-red-500'/>{t("not-verified")}</>}
                    </ButtonField>
                    <ChangeEmail portalUser={portalUser} setPortalUser={setPortalUser}/>

                    {portalUser.role==="tutor" && portalUser.tutor &&
                    <Info.Container label={t("tutor")}>
                        <Info.Field value={t(portalUser.tutor.name)} to={`/tutors/${portalUser.tutor.ni}`}/>
                        <Info.Field value={t(portalUser.tutor.email)}/>
                        <Info.Field value={t(portalUser.tutor.phone)}/>
                        <Info.Field value={portalUser.tutor.active_since && locale.format("%d %B %Y")(dateParse(portalUser.tutor.active_since))}
                        label={t("active-since")}/>
                    </Info.Container>
                    }

                    <AuthHistory portalUser={portalUser}/>
                    
                    {!portalUser.email_verified &&
                        <>
                        <ButtonField
                        label={t("portal-user-send-activation-email.label")}
                        subLabel={t("portal-user-send-activation-email.description")}
                        onClick={()=>sendActivation.execute()}
                        color="black"
                        error={sendActivation.error}
                        size="md"
                        disabled={portalUser.email_verified}
                        loading={sendActivation.loading}>
                            {t("portal-user-send-activation-email.button")}
                        </ButtonField>
                        {activationResults && <RawTextSuccess className="mt-3">{t("portal-user-send-activation-email.success")}
                        <code className='block break-all whitespace-pre-wrap'>{activationResults.url}</code>
                        </RawTextSuccess>}
                        </>
                    }
                    {(portalUser.role==="partner" || portalUser.role==="teacher") &&

                        <ModifiableSelectField
                            Field={SelectSchool}
                            label={t("portal-user-select-school")}
                            subLabel={t("portal-user-select-school-description")}
                            modify={true}
                            value={portalUser.school_permissions && portalUser.school_permissions.length>0 && portalUser.school_permissions[0]}
                            setValue={(school)=>updateSchoolAccess({school: school && school.slug, ni: portalUser.ni})}/>
                }

                    {portalUser.blocked &&
                        <ButtonField
                        label={t("portal-user-is-blocked")}
                        subLabel={t("portal-user-is-blocked-description")}
                        onClick={()=>unblock.execute()}
                        color="deleteContrast"
                        error={unblock.error}
                        size="md"
                        loading={unblock.loading}>
                        {t("unblock-portal-user")}
                    </ButtonField>
                    }
                    {!portalUser.blocked &&
                        <ButtonField
                        label={t("portal-user-is-not-blocked")}
                        subLabel={t("portal-user-is-not-blocked-description")}
                        onClick={()=>block.execute()}
                        error={block.error}
                        color="delete"
                        size="md"
                        loading={block.loading}>
                            {t("block-portal-user")}
                        </ButtonField>
                    }
                    <ButtonField
                        label={t("delete-portal-user.title")}
                        subLabel={t("delete-portal-user.description")}
                        onClick={()=>deleteExec.execute()}
                        error={deleteExec.error}
                        blurred={true}
                        color="deleteContrast"
                        noBorder="true"
                        size="md"
                        loading={deleteExec.loading}>
                            {t("delete-portal-user.button")}
                        </ButtonField>
                </div>
            </div>
}

/*
  Autonomous card based on query params (ni)
*/
export function UserViewQueryBased({setOpen, setUsers}){
    const {query} = useQuery();
    const ni = useMemoCompare(query, (prev, next)=>prev&& (prev.get("ni")!==next.get("ni")));
    const params = useMemo(()=>({ni: query.get("ni")}), [ni]);
    const [portalUser, {setResult, error, execute}] = useAPI(retrievePortalUser, params, {immediate:false});
    useEffect(()=>{query.get("ni") && execute()}, [execute])

    if (error) <Error.Text className="mt-3" {...error}/>
    return <UserView portalUser={portalUser} setPortalUser={(d)=>{
        if (d){
            setResult(d); 
            setUsers?.((prev)=>
                prev?.map((u)=>u.ni===portalUser.ni? {...u, ...d}: u) || []
            );
            return
        }
        setOpen(false);
        setResult()
        setUsers?.((prev)=>prev?.filter((u)=>u.ni!==portalUser.ni) || []);
        
            
    }}/>
  }