import React from 'react';

// Components
import { Container } from 'components/core/container';
import { EventsSettingsModify } from 'components/events/modify';

// Hooks
import { useContext } from 'react';

// Context
import {TeamContext} from 'contexts/team';

export default function EventsSettings(){
  const {team} = useContext(TeamContext);
  return <Container className="h-full w-full">
          {team && team.name && <EventsSettingsModify team={team}/>}
         </Container>
}
