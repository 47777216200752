import React from 'react';
import classnames from 'classnames';

// Components
import { DashboardLayout, MultiColumn } from 'components/core/layouts';
import { FilterBar } from 'components/tools/filter_bar';
import { SectionHeading } from 'components/core/typo';
import { Outlet } from "react-router-dom";

// Contexts
import { SearchContextProvider } from 'contexts/search';
import { AutoPairingsContextProvider } from 'contexts/auto-pairings';
import { AutoPairingContextProvider } from 'contexts/auto-pairing';
// Utils
import { getFullCurrentPeriod } from 'utils/period';

// Hooks
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

// Components
import { FiShuffle } from 'react-icons/fi';
import { DraftsList } from 'components/automatic-pairing/drafts-list';

export function DraftAutoPairingListPage(){
    const { draftId } = useParams();
    const { t } = useTranslation("autoPairing");
    const defaultFields = useMemo(()=>({period: getFullCurrentPeriod()}), []);
    return <SearchContextProvider storageKey={"/automatic-pairings"} defaultFields={defaultFields}>
            <AutoPairingsContextProvider>
              <AutoPairingContextProvider>
            <DashboardLayout contentClassName={"overflow-hidden"}>
              <SectionHeading title={t("title")} className={classnames(draftId && "hidden md:block")}>
                <FilterBar period/>
              </SectionHeading>
              <MultiColumn>
                <MultiColumn.Left className={classnames("border-r", draftId && "hidden md:block")}>
                  <DraftsList/>
                </MultiColumn.Left>
                <MultiColumn.Content  className={classnames(!draftId && "hidden md:block")}>
                  <Outlet/>
                </MultiColumn.Content>
              </MultiColumn>
             </DashboardLayout>
             </AutoPairingContextProvider>
            </AutoPairingsContextProvider>
           </SearchContextProvider>
  }
  
const route =  { path: "/automatic-pairing", 
                 name: "Jumelage automatique" , 
                 Icon: FiShuffle,
                 group: 'outils',
                 showInNavBar: true, 
                 requireAuth: true };
export default route;
