import React from "react"

// Components
import { StudentsMetricsCard } from "components/coordo-stats/students-metrics"

// Hooks
import { useTranslation } from "react-i18next"

const scoresDefinitions = {
    // Score négatif (socio-affectifs) -> Plus le score est élevé, plus il y a de problèmes socio-affectifs
    0 : [0, 3], // Vide
    1 : [4, 9], // Vert
    2 : [10, 15], // Jaune
    3 : [16, 19], // Orange
    4 : [20, 49], // Rouge
    5 : [50, 100], // Rouge
}

const globalScoreDefinitions = {
    // Score global négatif (socio-affectifs) -> Plus le score est élevé, plus il y a de problèmes socio-affectifs
    "-" : [20, 100], // Rouge
    "+-" : [11, 19], // Jaune
    "+" : [0, 10] // Vert
}


export function SocioAffectifs({...props}) {
    const { t } = useTranslation();
    return <>
        <StudentsMetricsCard 
            title={t("students-metrics.socio-affectifs.title")}
            projectedStatKey="nbSocioAffectifs"
            infoDescription={t("students-metrics.socio-affectifs.description")}
            scoresDefinitions={scoresDefinitions} 
            globalScoreDefinitions={globalScoreDefinitions} 
            className="border-t-4 border-blue-500"
            scoreClassName="!text-blue-500"
            negative
            {...props}
        />
    </>
}

