import React, { Fragment } from 'react';

// Components
import { TextInput } from 'components/core/inputs'
import { Button } from 'components/core/button';
import { FiCheck, FiMoreHorizontal, FiActivity, FiUnlock, FiLock } from 'react-icons/fi';
import { Paginate } from 'components/core/paginate';
import { Error, InfoSectionHeading } from 'components/core/typo'
import { Dropdown } from 'components/core/dropdown'

// Utils
import classnames from 'classnames';

// Hooks
import { useDebounce } from 'hooks/useDebounce';
import { useAPI } from 'hooks/useAPI';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SlideOver } from 'components/core/slide_over';

const sizes = {
    "sm": "max-w-sm",
    "md": "max-w-md",
    "lg": "max-w-lg",
    "xl": "max-w-xl",
    "2xl": "max-w-2xl",
    "3xl": "max-w-3xl",
    "4xl": "max-w-4xl"
}
export function Label({ children }) {
    return <label className="block mb-0.5 text-sm font-medium text-gray-800">{children}</label>
}
export function LargeLabel({ children }) {
    return <label className="font-medium text-gray-800">{children}</label>
}

export function SubLabel({ children }) {
    return <div className="text-sm text-gray-500">{children}</div>
}

export function SingleLineField({ label,
    description,
    modify,
    value,
    setValue,
    setAPI = {},
    showSavingState = true,
    disabled,
    debounceDelay = 500,
    multiLine = false,
    placeholder,
    size,
    rows = 8,
    required,
    color,
    inputProps,
    children }) {
    const [val, setVal] = useState(value);
    const debouncedVal = useDebounce(val, debounceDelay);
    const [isSaved, setIsSaved] = useState(true);
    const { t } = useTranslation('common');

    const apiParams = useMemo(() => { }, [])
    const [, { error, execute }] = useAPI(setAPI.method, apiParams, { immediate: false })
    useEffect(() => {
        if (modify && (debouncedVal !== val)) {
            setIsSaved(false);
        }
    }, [val]);

    useEffect(() => {
        // Save
        if (modify && value !== debouncedVal && (debouncedVal || (!debouncedVal && !required))) {
            setValue(debouncedVal);
            if (setAPI.method) {
                var p = { ...setAPI.extraParams };
                p[setAPI.name] = debouncedVal;
                execute(p)
            }
            setIsSaved(true);
        }
    }, [debouncedVal]);

    return <div>
        {label && <Label>{label}</Label>}
        {modify ?
            multiLine ?
                <textarea
                    className="block w-full my-3 placeholder-gray-400 border-gray-300 rounded-md shadow resize-none border-1 focus:ring-0 focus:border-2 focus:border-orange-500"
                    onChange={e => setVal(e.currentTarget.value)}
                    placeholder={label}
                    value={val != null ? val : ""}
                    disabled={!modify}
                    rows={rows}
                    {...inputProps}
                />
                : <TextInput
                    onChange={setVal}
                    placeholder={placeholder || label}
                    value={val != null ? val : ""}
                    color={color}
                    size={size}
                    disabled={disabled || !modify}
                    {...inputProps}
                /> :
            children ?
                typeof children === "function" ? children(value) : children :
                val ?
                    <p className="font-medium text-gray-700">{val}</p> :
                    <p className="text-gray-400">{t("none")}</p>}
        {description && <SubLabel>{description}</SubLabel>}

        {modify && showSavingState &&
            <p className="w-full mt-1 text-xs text-gray-400">
                {isSaved ?
                    <span className="flex items-center justify-end text-right"><FiCheck className="mr-1" /> {t("saved")}</span> :
                    (!val && required) ?
                        <span className="flex items-center justify-end font-medium text-right text-red-700"><FiActivity className="mr-1" /> {t("required")}</span> :
                        <span className="flex items-center justify-end text-right"><FiActivity className="mr-1" /> {t("unsaved")}</span>
                }
            </p>}
        {error ? <Error.Text className="mt-3" {...error} /> : null}

    </div>
}

export function ButtonField({ label, subLabel, block, blurred, noBorder, error, ...props }) {
    const [locked, setLocked] = useState(true);

    return <><div className={classnames("w-full py-2  md:relative", noBorder ? "" : "border-b", block ? "space-y-4" : "md:flex items-center justify-between space-y-0")}>
        {label || subLabel ?
            <div className="w-full max-w-sm">
                {label && <LargeLabel>{label}</LargeLabel>}
                {subLabel && <SubLabel>{subLabel}</SubLabel>}
            </div> : null}
        <Fragment>
            {blurred && !locked &&
                <Button size="toolbarIcon" className="mr-3" color="gray" onClick={() => setLocked(true)}><FiUnlock /></Button>}
            {blurred && locked ?
                <Button size="toolbarIcon" color="gray" onClick={() => setLocked(false)}><FiLock /></Button> :
                <Fragment>
                    <Button {...props} />
                </Fragment>}

        </Fragment>
    </div>
        {error ? <Error.Text className="mt-3" {...error} /> : null}
    </>
}


export function ModifiableSelectField({ Field,
    modify,
    label,
    subLabel,
    value,
    setAPI = {},
    alwaysFlex,
    block,
    setValue,
    alwaysShowChildren,
    children,
    mainClassName,
    labelSize = "sm",
    noBorder, ...props }) {
    const [val, setVal] = useState(value);
    const apiParams = useMemo(() => { }, [])
    const [, { error, execute }] = useAPI(setAPI.method, apiParams, { immediate: false })
    useEffect(() => {
        if (modify && val !== value) {
            setValue(val);
            if (setAPI.method) {
                var p = { ...setAPI.extraParams };
                p[setAPI.name] = val;
                execute(p)
            }
        }
    }, [val]);

    return <div className={classnames("w-full py-2  md:relative", noBorder ? "" : "border-b", block ? "space-y-4" : alwaysFlex ? "space-x-4 flex items-center justify-between space-y-0" : "md:flex items-center justify-between space-y-0", mainClassName)}>
        {label || subLabel ?
            <div className={classnames("w-full ", sizes[labelSize])}>
                {label && <LargeLabel>{label}</LargeLabel>}
                {subLabel && <SubLabel>{subLabel}</SubLabel>}
            </div> : null}
        <Fragment>
            {(!modify && children) ?
                children(val) :
                <Field value={val} setValue={setVal} disabled={!modify} {...props} />
            }
        </Fragment>
            {alwaysShowChildren? children : null}
            {error ? <Error.Text className="mt-3" {...error} /> : null}
    </div>
}

export function ModifiableMultipleSelectField({ Field, modify, label, value, setValue, children, onInsert, onDelete, onShowMore, fieldProps, paging, setPage, error, indexField }) {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    const handleDelete = useCallback((d) => {
        onDelete(d);
        setValue(value.filter(a => a[indexField] !== d[indexField]));
    }, [setValue, value]);

    const handleCreate = useCallback((d) => {
        setValue(value ? [d, ...value] : [d]);
        if (onInsert) onInsert(d);
    }, [setValue, value]);

    return <div>
        <InfoSectionHeading mediumTitle={label}>
            <Button onClick={() => setOpen(!open)} size="smWide" color="active">
                {t("add")}
            </Button>
        </InfoSectionHeading>
        <Fragment>
            {!modify && children ?
                children(value) :
                <div className="space-y-6">
                    {/*Delete*/}
                    <div className="mb-4 space-y-2">
                        {!value || value.length === 0 ? children([]) :
                            value.map((d, i) =>
                                <div key={i} className="flex items-center justify-between px-2 bg-gray-100 rounded-md">
                                    {children([d])}
                                    {onShowMore && <Button color="gray" size="icon" onClick={() => onShowMore(d)} className="px-2 py-2 flex-0"><FiMoreHorizontal /></Button>}
                                    {onDelete &&
                                        <Dropdown onlyIcon={<FiMoreHorizontal />}>
                                            <Dropdown.Item color="danger" name={t("delete")} onClick={() => handleDelete(d)} />
                                            {/*<Button color="delete" size="xs" onClick={()=>handleDelete(d)} className="px-2 py-2 flex-0">{t("delete")}</Button>*/}
                                        </Dropdown>}
                                </div>
                            )}
                    </div>
                    {paging && <Paginate setPage={setPage} {...paging} />}
                    {error ? <Error.Text className="mt-3" {...error} /> : null}

                    {/*Add*/}
                    <SlideOver open={open} setOpen={setOpen} size="xl3">
                        {Field && <Field value={value} setValue={handleCreate} disabled={!modify} {...fieldProps} />}
                    </SlideOver>
                </div>
            }
        </Fragment>
    </div>
}