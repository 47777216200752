import React from 'react';

// Components
// import { EmptyState } from 'components/core/empty'

// Hooks
// import { useTranslation } from 'react-i18next';

export default function Stats(){
  // const { t } = useTranslation();
  return <div className="flex flex-col h-full justify-center items-center">
    {`Aggrégation de la stratégie de recrutement: #/occupation, comment ils vous ont trouver, etc.`}
          </div>
}
