import React, { useMemo, useState } from 'react';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useDebounce } from 'hooks/useDebounce';

// API
import { searchSportTeams } from 'api/teams';
import { useTranslation } from 'react-i18next';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Error, InfoSectionHeading } from 'components/core/typo';
import { Table } from 'components/core/table';
import { TextInput } from 'components/core/inputs';

export function HelpSportTeams(){
    const {t} = useTranslation('common');
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 500);
    const params = useMemo(() => ({search}), [debouncedSearch]);
    const [teams, {loading, error}] = useSearch(searchSportTeams, params, {limit: 8});
    const headers = useMemo(() => [
        {
            title: t("code"),
            field: "slug",
            itemClassName: "font-medium "
        },
        {
            title: t("name"),
            field: "name"
        },
        {
            title: t("period"),
            field: (d=>d? (d.active_period || {}).name: "active_period")
        },
    ], []);

    return <div className='bg-gray-100 rounded-md px-3 pb-3'>
            <InfoSectionHeading smallTitle={t("bulk-import.sport-team-help.title")}
                                longDescription={t("bulk-import.sport-team-help.description")}/>
            <TextInput loading={loading} value={search} onChange={setSearch} placeholder={t("bulk-import.sport-team-help.placeholder")} search size='sm'/>
            {teams? <div className="space-y-2">
                        <Table data={teams} headers={headers} indexingKey={"slug"}/>
                    </div>:
                loading? <Skeleton className="h-24"/>:
                error? <Error.Text {...error}/>:null
            }
            </div>

}