import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import { FiMoreVertical } from 'react-icons/fi'; 
import { Button } from 'components/core/button';
import { Dropdown } from 'components/core/dropdown';

// Utils
import { locale, dateParse } from 'utils/locale';
import classnames from 'classnames';

export function StudySessionCell({student, period, code, id}){
  const { t } = useTranslation('common');
  return <div className={classnames("rounded-md p-3 md:flex items-center bg-gray-100")}>
          <div className={classnames("flex-1")}>
            <div className="flex items-center justify-between">
              <div className=" text-gray-500">
                {locale.format("%d %B %Y")(dateParse(period))}
              </div>
              <div className="text-gray-500">
                {code.name}
              </div>
            </div>
            <p className="text-gray-400">
            <Button color="inlineLink" href={`/students/${student && student.ni}`}>{student && student.name}</Button>
            </p>
          </div>
          <div className="ml-2 ">
            <Dropdown onlyIcon={<FiMoreVertical/>} menuItemsClassName="whitespace-nowrap">
              <Dropdown.Item name={t("show-more")} href={`/study-sessions/${id}`}/>
            </Dropdown>
          </div>
         </div> 
}
