import React, { useReducer, createContext } from "react";

function filterReducer(state, action){
  switch (action.type){
    case 'student':
      return {...state, ni: action.value};
    case 'archived':
      return {...state, archived: action.value};
    case 'ordering':
      if (state.orderBy===action.value){
        if (state.orderDirection==='desc'){
          return {...state, orderBy: action.value, orderDirection: 'asc'};
        }
        return {...state, orderBy: null, orderDirection: null};
      }
      return {...state, orderBy: action.value, orderDirection: 'desc'};
    default:
      return state
  }
}

const SearchFailedSubjectsContext = createContext({});

const SearchFailedSubjectsContextProvider = ({ defaultState, children }) => {
  const [filters, dispatchFilters] = useReducer(filterReducer, defaultState || {});

  return (
    <SearchFailedSubjectsContext.Provider value={{ filters, dispatchFilters }}>
      {children}
    </SearchFailedSubjectsContext.Provider>
  );
};

export { SearchFailedSubjectsContext, SearchFailedSubjectsContextProvider };