import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {useTranslationRegularCron} from 'hooks/dates/crons';

// Components
import { FiCheck, FiMoreVertical } from 'react-icons/fi'; 
import { Skeleton } from 'components/core/skeleton';
import { Button } from 'components/core/button';
import { EmptyState } from 'components/core/empty';
import { Error } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Dropdown } from 'components/core/dropdown';

// Utils
import { locale, dateParse } from 'utils/locale';
import classnames from 'classnames';
import {timeDay} from 'd3-time';

export function PairingCell({expanded, student, tutor, started_at, ended_at, period, id}){
  const { t } = useTranslation('common');
  const periodReadble = useTranslationRegularCron(period)
  const inactive = useMemo(()=>(ended_at && dateParse(ended_at)<=timeDay.floor(new Date())), [ended_at]);
  return <div className={classnames("rounded-md p-3 md:flex items-center", inactive? "bg-gray-50": "bg-gray-100")}>
          <div className={classnames("flex-1", inactive&& "opacity-60")}>
            <div className="flex items-center justify-between">
              <div className="flex items-center text-gray-500">
                {
                  !started_at?
                    <span>{t("no-starting-date")}</span>:
                    <span>{locale.format("%d %B %Y")(dateParse(started_at))}
                      <span> - </span>
                      {ended_at?
                        inactive? 
                        locale.format("%d %B %Y")(dateParse(ended_at)):
                        t("ended-expected", {date: locale.format("%d %B %Y")(dateParse(ended_at))}):
                        t("pending")
                      }
                    </span>}
              </div>
              <div className={classnames(inactive? "text-gray-400": "text-orange-600")}>
                {!inactive &&<FiCheck className="inline mr-1 mb-1"/>}
                {!inactive &&t("active", {context: 'male'})}
              </div>
            </div>
            <p className="text-gray-400">
            <Button color="inlineLink" href={`/students/${student && student.ni}`}>{student && student.name}</Button>
            {t("paired-with")}
            <Button color="inlineLink" href={`/tutors/${tutor && tutor.ni}`}>{tutor && tutor.name}</Button>
            </p>
            {expanded && 
              <div className="text-gray-500">
                {periodReadble}
              </div>}
          </div>
          <div className="ml-2 ">
            <Dropdown onlyIcon={<FiMoreVertical/>} menuItemsClassName="whitespace-nowrap">
              <Dropdown.Item name={t("show-more")} href={`/pairings/${id}`}/>
            </Dropdown>
          </div>
         </div> 
}

export function PairingList({pairings, paging, loading, error}){
  const { t } = useTranslation('common');

  if (!pairings || loading){
    return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2"/>
  }

  return <div>
            <label className="ml-2 text-sm uppercase text-gray-500">{t("pairings")}</label>
            {pairings.length===0?
              <EmptyState 
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-pairing-title")}/>
              :
              <div className="space-y-2">
                {pairings.map(d=><PairingCell key={d.id} {...d}/>)}
              </div>}
              {error? <Error.Text {...error}/>: null}
              {paging&& <Paginate {...paging}/>}
          </div>
}