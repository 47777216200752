/* eslint no-unused-vars: "off" */

import React from "react"

// Components
import { CoordoStats } from "components/coordo-stats"
import { CoordoStatsContextProvider } from 'contexts/coordo-stats';
import { RankGrid, RankGridItem, ExpandableItems } from "components/coordo-stats/rank-grid"
import { StatsCard, StatsCardScore, SubStat, StatsCardTitle, StatsCardInfo } from "components/coordo-stats/stats-card"

// Utils
import classNames from "classnames"

// Hooks
import { useMemo, useState } from "react"
import { useCoordoStats } from "contexts/coordo-stats"
import { useTranslation } from "react-i18next";

export function StudentsMetricsGrid({header}){
    const [expand, setExpand] = useState(false)
    const { t } = useTranslation();
    return <>
        <div className="flex items-end gap-2 flex-wrap justify-between mb-2 text-gray-500 font-medium text-sm opacity-80 hover:opacity-100 transition-all">
            <div>
                {header}
            </div>
            <div onClick={() => setExpand(!expand)} className="cursor-pointer ">
                {
                    !expand ? t("see-all") : t("see-less")
                }
            </div>
        </div>
        <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4">
            <CoordoStatsContextProvider fields={"success"}>
                <CoordoStats.Success expand={expand}/>
            </CoordoStatsContextProvider>
            <CoordoStatsContextProvider fields={"at_risk"}>
                <CoordoStats.AtRisk expand={expand}/>
            </CoordoStatsContextProvider>
            <CoordoStatsContextProvider fields={"fails"}>
                <CoordoStats.Fails expand={expand}/>
            </CoordoStatsContextProvider>
            <CoordoStatsContextProvider fields={"socio_affectifs"}>
                <CoordoStats.SocioAffectifs expand={expand}/>
            </CoordoStatsContextProvider>
        </div>
    </>
}


/**
 * Renders a card component that displays students' metrics.
 *
 * @param {Object} props - The component props.
 * @param {string} props.projectedStatKey - The key for the projected statistic.
 * @param {string} props.infoDescription - The description for the information displayed on the card.
 * @param {string} props.title - The title of the card.
 * @param {Object} props.scoresDefinitions - The definitions for the scores.
 * @param {Object} props.globalScoreDefinitions - The definitions for the global scores.
 * @param {boolean} props.negative - Indicates whether the card is displaying negative metrics.
 * @param {boolean} props.expand - Expands the card.
 * @returns {JSX.Element} The rendered StudentsMetricsCard component.
 */
export function StudentsMetricsCard({projectedStatKey, infoDescription, title, scoresDefinitions, globalScoreDefinitions, negative, expand, className, scoreClassName}) {
    const { stats, loading } = useCoordoStats()
    const schoolName = stats?.coordoSchoolStats?.schoolName
    const { t } = useTranslation();

    const {totalPercent, totalProjected, totalStudents} = useMemo(() => {
        const teamsStats = stats?.coordoTeamsStats || [];
        const { totalStudents, totalProjected } = teamsStats.reduce((acc, team) => {
            acc.totalStudents += team.totalStudents;
            acc.totalProjected += team[projectedStatKey];
            return acc;
        }, { totalStudents: 0, totalProjected: 0 });
        
        const percent = (totalProjected / totalStudents) * 100
        return {
            totalPercent: totalStudents ? percent.toFixed(0) : 0,
            totalStudents,
            totalProjected
        }
    }, [stats]);
    
    const damPercent = useMemo(() => {
        const damStats = stats?.damStats
        return damStats ? ((damStats[projectedStatKey] / damStats.totalStudents) * 100).toFixed(0) : 0
    }, [stats])

    const schoolPercent = useMemo(() => {
        const schoolStats = stats?.coordoSchoolStats
        return schoolStats ? ((schoolStats[projectedStatKey] / schoolStats.totalStudents) * 100).toFixed(0) : undefined
    }, [stats])

    const sortedTeams = useMemo(() => {
        const teamsStats = stats?.coordoTeamsStats || [];
        const diffs = teamsStats.map(team => {
            const teamProjected = team[projectedStatKey];
            const teamPercent = team.totalStudents ? ((teamProjected / team.totalStudents) * 100).toFixed(0) : 0;
            return {
                id: team.teamId,
                name: team.teamName,
                percent: teamPercent,
                diff: (totalPercent - teamPercent) || 0
            }
        });
        // Sort by worst performing teams
        negative ? diffs.sort((a, b) => a.diff - b.diff) : diffs.sort((a, b) => b.diff - a.diff);
        return diffs
    }, [stats]);

    const getTeamScore = (percent) => {
        const score = Object.entries(scoresDefinitions).find(([, [min, max]]) => percent >= min && percent <= max)
        return score ? Number(score[0]) : 1
    }

    const getGlobalScore = (percent) => {
        const score = Object.entries(globalScoreDefinitions).find(([, [min, max]]) => percent >= min && percent <= max)
        return score ? score[0] : "-"
    }

    return <>
        <StatsCard title={loading ? title : undefined} loading={loading} className={classNames("@container", className)}>
            <div className="flex justify-between">
                <StatsCardTitle>{title}</StatsCardTitle>
                <StatsCardInfo>
                    {infoDescription}
                </StatsCardInfo>
            </div>
            <div className="flex items-start flex-col gap-x-4 gap-y-2 @[350px]:flex-row @[350px]:items-center @[350px]:justify-between">
                <StatsCardScore globalScore={getGlobalScore(totalPercent)} className={classNames("", scoreClassName)}>
                    <span>{ totalPercent }<span className="ml-0.5 text-4xl">%</span></span>
                    <span className="text-base block ml-0.5"><span>{totalProjected}</span> / {totalStudents} {t("students").toLowerCase()}</span>
                </StatsCardScore>
                <div className="flex flex-col @[350px]:items-end w-full">
                    <SubStat title="DAM" value={damPercent + "%"} />
                    {schoolName && <SubStat title={schoolName} value={schoolPercent + "%"} />}
                </div>
            </div>
            <RankGrid>
                {
                    !sortedTeams.length && !loading && <div className="text-gray-500 col-span-3 text-center">{t("no-teams")}</div>
                }
                {
                    // Worst 3 performing teams
                    sortedTeams.slice(0, 3).map(({id, name, percent}) => <RankGridItem key={id} href={`/teams/${id}`} title={name} percent={percent} score={getTeamScore(percent)} negative={negative} />)
                }
                {
                    sortedTeams.length > 3 && <>
                        <ExpandableItems expand={expand}>
                            {
                                // Rest of the teams
                                sortedTeams.slice(3).map(({id, name, percent}) => <RankGridItem key={id} href={`/teams/${id}`} title={name} percent={percent} score={getTeamScore(percent)} negative={negative} />)
                            }
                        </ExpandableItems>
                    </>
                }
            </RankGrid>
        </StatsCard>
    </>
}
