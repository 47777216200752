import React from 'react';

// Components
import { TextInput } from 'components/core/inputs';
import { Button } from 'components/core/button';

// Hooks
import { useTranslation } from 'react-i18next';

const presets = [
  { name: "45 min.", value: 45},
  { name: "50 min.", value: 50},
  { name: "55 min.", value: 55},
  { name: "60 min.", value: 60}
]
export function SelectSessionLength({ value, setValue, showPresets=true, showLabel=true}){
  const { t } = useTranslation("common");
  
  return <div className="space-y-3 md:space-y-0 md:flex items-end space-x-3">
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
            {
             showPresets&&presets.map(d=>
                <Button key={d.value} 
                      color={value===d.value? 'active': 'gray'} 
                      className="text-center"
                      size="lg"
                      onClick={(e)=>{e.preventDefault(); setValue(d.value)}}>
                    {d.name}
                </Button>)
            }
          </div>
          <div className="flex-1">
            <TextInput value={value? value: ""} type="number" min="0" step="5" label={showLabel && t("study-session.length-label")} placeholder={t("study-session.length-placeholder")} onChange={e=>setValue(parseInt(e))}/>
          </div>
        </div>
}