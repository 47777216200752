import React from 'react';

// Components
import { Container } from 'components/core/container';
import { DashboardLayout } from 'components/core/layouts';
import { FlexNav } from 'components/core/layouts/flex';
import { SubSideNavLink } from "components/core/nav";

// Hooks
import { useUser } from 'hooks/useUser';
import { useMemo } from 'react';
// Contexts

import { nestedSettingsRoutes } from 'pages/settings';
import { useTranslation } from 'react-i18next';

function NavBar(){
  const [user] = useUser();
  const {t} = useTranslation("common");
  const routes = useMemo(()=>{
    if (!user) return nestedSettingsRoutes.filter(d=>!d.scopes)
    return nestedSettingsRoutes.filter(d=>!d.scopes || d.scopes.includes(user.role))
  }, [user]);

  // Group the routes by group
  const groups = routes.reduce((acc, d)=>{
    if (!acc[d.group]) acc[d.group] = [];
    acc[d.group].push(d);
    return acc;
  }, {})

  return <div>
          {Object.keys(groups).map((d, i)=><div key={i} className='mb-6'>
            <label className="text-base">{t(d)}</label>
            {groups[d].map((d, i)=><SubSideNavLink key={i} path={`/settings/${d.path}`} Icon={d.Icon} name={d.name}/>)}
            </div>)}
        </div>
}

export function SettingsLayout({ children }){
  return <DashboardLayout>
          <Container className="h-full py-6" >
          <FlexNav>
            <FlexNav.Bar>
              <NavBar/>
            </FlexNav.Bar>
            <FlexNav.Content>
              { children }
            </FlexNav.Content>
           </FlexNav>
          </Container>
         </DashboardLayout>

}