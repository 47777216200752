import React, { useState } from 'react';

// Components
import { UploadFile } from 'components/bulk-import/upload';
import { Error, InfoSectionHeading  } from 'components/core/typo';

// Hooks
import { useTranslation } from 'react-i18next';
import { Button } from 'components/core/button';

/*
  This component will handle all the settings so that it will 
  output a nice and formatted list of students to push to the API.
*/
const fields = ["Prenom", 
        "Nom", 
        "Equipe", 
        "AnneeScolaire", 
        "Fiche", 
        "Groupe", 
        "Courriel1", 
        "Courriel2", 
        "Tel",
        "PaysDeNaissance",
        "PaysDeNaissanceParent",
        "ReseauxSociaux",
        "ProfilScolaire",
        "Genre",
        "Francisation",
        "HeuresDeTravail",
        "Redoublement"
      ];

export function StudentsUpload({setValue}){
  const [error, setError] = useState();
  const {t} = useTranslation('common');
  return <div>
          <InfoSectionHeading smallTitle={t("bulk-import.student-upload.title")} longDescription={t("bulk-import.student-upload.description",{fields:fields.join(', ')})}>
            <Button color="gray" size="sm" className={"ml-3"} onClick={()=>{window.open("/assets/student-template.csv")}}>{t("bulk-import.students.download-template")}</Button>
            </InfoSectionHeading>
          <UploadFile setValue={setValue} setError={setError} fields={fields}/>
          {error? <Error.Text {...error}/>: null}
         </div>
}