import React from 'react';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import { ResetPasswordForm } from 'components/reset_password';

export function ResetPasswordPage(){
  return <CenterViewLayout>
          <ResetPasswordForm/>
         </CenterViewLayout>
}

const route =  { path: "/reset-password", 
                 name: "Reset password" , 
                 showInNavBar: false, 
                 requireAuth: false, 
                 Element: ResetPasswordPage };
export default route;
