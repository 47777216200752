import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonFR from 'i18n/locales/fr/common.json';
import authFR from 'i18n/locales/fr/auth.json';
import errorFR from 'i18n/locales/fr/errors.json';
import autoPairingFR from 'i18n/locales/fr/automatic-pairing.json';
import endYearAssistantFR from 'i18n/locales/fr/end-year.json';
import tutorFR from 'i18n/locales/fr/tutor.json';

//  https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  fr: {
    common: commonFR,
    auth: authFR,
    errors: errorFR,
    autoPairing: autoPairingFR,
    endYearAssistant: endYearAssistantFR,
    tutor: tutorFR
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: ['common', 'auth', 'errors', 'tutor'],
    lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    returnObjects: true,
    keySeparator: ".",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

i18n.services.formatter.add('lowercase', (value) => {
  return value.toLowerCase();
})


export default i18n;