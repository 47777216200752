import React from 'react';

// Contexts
import { SearchContext, SearchContextProvider } from 'contexts/search';

// API
import { searchDocuments, deleteDocument, updateDocument} from 'api/documents';

// Utils
import { locale , parseWithTimeZone} from 'utils/locale';
// import { formatBytes } from 'utils/bytes';
import classnames from 'classnames';

// Hooks
import { useMemo, useState, useCallback, useContext, useEffect } from 'react'
import { useSearch } from 'hooks/useSearch';
import { useQuery } from 'hooks/useQuery';
import { useTranslation } from 'react-i18next';
import { getFullCurrentPeriod } from 'utils/period';

// Components
import { Error } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { SelectDate } from 'components/tools/select_date';
import { Paginate } from 'components/core/paginate';
import { CreateDocumentButton } from 'components/documents/create_modal';
import { Table } from 'components/core/table';
import { FiMoreVertical } from 'react-icons/fi';
import { Dropdown } from 'components/core/dropdown';
import { DocumentSlideOver } from 'components/documents/slideover';
import { SlideOver } from 'components/core/slide_over';
import { FilterBar } from 'components/tools/filter_bar';



function HandOverDocument({handed_over_at, onCompleted, ...doc}){
  const { t } = useTranslation('common');
  return <SelectDate 
            label={t("not-handed-over")} 
            dateFormat={"%d %b %Y"}
            value={handed_over_at? new Date(handed_over_at): null} 
            menuButtonClassName={classnames("py-1", !handed_over_at && "bg-red-100 text-red-800" )}
            setValue={(handedOverAt)=>{
              updateDocument({id:doc.id, handedOverAt});
              onCompleted({...doc, handed_over_at: handedOverAt})
            }}/>
}

function DeleteDropdown({url, onCompleted, setOpen, setQuery, ...doc}){
  const { t } = useTranslation('common');
  return <Dropdown onlyIcon={<FiMoreVertical/>}>
          <Dropdown.Item name={t("modify")} 
              color="default" 
              onClick={()=>{
                setQuery({documentId: doc.id});
                setOpen(true)
              }}/>
          {url && 
            <Dropdown.Item name={t("download")} 
              color="default" 
              externalLink
              target="_blank" 
              href={url}/>
            }
          <Dropdown.Item name={t("delete")} 
              color="danger" 
              onClick={()=>{
                deleteDocument({id: doc.id});
                onCompleted(doc)
              }}/>
         </Dropdown>
}


function Documents({documents, setDocuments, setDocument, onDelete, error, setPage, paging, orderBy, orderDirection, dispatchFilters}){
  const {query, setQuery} = useQuery();
  const [open, setOpen] = useState(query.has("documentId"));

  useEffect(()=>{
    if (!open){setQuery()}
  }, [open])

  const { t } = useTranslation('common');
  const headers = useMemo(()=>[
      {
        title: t("group"),
        field: (d=>d? d.group && d.group.name : "group"),
        itemClassName: "font-medium py-2 h-12",
      },
      {
        title: t("handed-over-at"),
        field: (d=>d? {...d, onCompleted: setDocument}: "handed_over_at"),
        itemClassName: (d=>d.handed_over_at? "text-gray-500 ": "text-red-500 "),
        FormatComponent: HandOverDocument
        // format: (d=>d.handed_over_at? locale.format("%d %B %Y")(parseWithTimeZone(d.handed_over_at)): t("not-handed-over"))
      },

      {
        title: t("uploaded-at"),
        field: (d=>d? d: "uploaded_at"),
        itemClassName: (d=>d.uploaded_at? "text-gray-500 ": "text-red-500 "),
        format: (d=>d.uploaded_at? locale.format("%d %B %Y à %H:%M")(parseWithTimeZone(d.uploaded_at)): t("not-uploaded"))
      },
      {
        title: t("signed"),
        field: (d=>d? (d.fully_signed? t("oui"): t("non")): "fully_signed"),
        itemClassName: (d=>(d===t("oui"))? "text-gray-500 capitalize ": "text-red-500 capitalize"),
      },
      {
        title: t("period"),
        field: (d=>d? (d.period && d.period.name): "period"),
      },
      {
        title: "",
        field: (d=>d? {...d, onCompleted: onDelete, setQuery, setOpen}: "delete"),
        itemClassName: "text-right",
        FormatComponent: DeleteDropdown
      },
  ], []);

  return <div>
              <Table 
              data={documents} 
              indexingKey="id" 
              headers={headers}
              order={{by:orderBy, direction:orderDirection}}
              onHeaderClick={(value)=>dispatchFilters({type: 'ordering',value})}/>
            {!documents && <Skeleton className="h-36 w-full"/>}
            {documents && documents.length===0 && <EmptyState title={t("empty-state.no-documents-title")}/>}
            {error? <Error.Text className="mt-3" {...error}/>: null}
            {paging&& <Paginate setPage={setPage} {...paging}/>}
            <SlideOver open={open} setOpen={setOpen} size="xl3">
              <DocumentSlideOver onDelete={({id})=>setDocuments(d=>d.filter(e=>e.id!==id))} onUpdate={setDocument}/>
            </SlideOver>
         </div>
}

export function DocumentListWithContext(){
  const {filters, dispatchFilters} = useContext(SearchContext);
  const [documents, {paging, setResult, setPage, execute, error}] = useSearch(searchDocuments, filters);

  const setDocument = useCallback((next)=>{
    setResult(d=>d.map(e=>e.id===next.id? next: e))
  }, []);

  const onDelete = useCallback((next)=>{
    setResult(d=>d.filter(e=>e.id!==next.id))
  }, []);
  return <div>
            {/*Toolbar*/}
            <div className="py-1">
              <FilterBar noMargin period color="default">
                <CreateDocumentButton onCompleted={execute} creatorProps={{defaultStudent: filters.student}}/>
              </FilterBar>
            </div>
            <Documents documents={documents} 
                      setDocument={setDocument}
                      onDelete={onDelete}
                      error={error} 
                      setDocuments={setResult}
                      setPage={setPage}
                      paging={paging} 
                      orderBy={filters.orderBy} 
                      orderDirection={filters.orderDirection} 
                      dispatchFilters={dispatchFilters}/>
          </div>
}


export function DocumentsList({student}){
  const defaultFields = useMemo(()=>({period: getFullCurrentPeriod()}), []); 
  return <SearchContextProvider student={student} defaultState={{student}} doNotStore defaultFields={defaultFields}>
          <DocumentListWithContext/>
         </SearchContextProvider>  
}