import React from 'react';

// Components
import { Button } from "components/core/button";
import { Tooltip } from "components/core/tooltip";

// Utils

// Icons
import { FiUsers as MatchedIcon } from "react-icons/fi";
import { FiBookOpen as BookIcon } from "react-icons/fi";

export function Pairings({student, loading}){
    const pairings = student?.pairings || [];
    const pairing = pairings[0] || {};
    const {tutor} = pairings[0] || {};
    const {subjects} = pairings[0] || {};
    if (!tutor || loading) return <></>
    return <>
    
        <Tooltip color="light" position="right" delay="0" contentClassName="!px-1 !max-w-52" content={
            <Button href={`/pairings/${pairing?.id}`} color="cardNav" size="link" className="flex flex-col gap-1 w-full rounded p-2">
                <div className="text-sm text-gray-500 flex gap-1 w-full"><MatchedIcon className='mt-1 shrink-0'/>{tutor?.name}</div>
                {
                    subjects?.length > 0 && <div className="text-sm text-gray-500 flex gap-1 w-full"><BookIcon className='mt-1 shrink-0'/>{subjects?.map(subject => subject?.name).join(", ")}</div>
                }
            </Button>
        }>
            <div className='relative w-fit p-2.5 py-1 h-fit'>
                <MatchedIcon className="text-base" />
            </div>
        </Tooltip>
    </>
}

