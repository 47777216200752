import React, { useMemo, useState } from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading, Error, InfoSectionHeading, RawTextInfo, RawTextWarning  } from 'components/core/typo';
import { SingleLineField, ModifiableSelectField} from 'components/tools/modifiable_fields';
import { SelectDate } from 'components/tools/select_date';
import { SearchSchoolClass } from 'components/tools/search_school_class';

// Hooks
import { useTranslation } from 'react-i18next';
// import { useAPI } from 'hooks/useAPI';

// API
import { updateFailedClass } from 'api/classes';

// Utils
import {dateParse} from 'utils/locale';
import { SelectDocumentGroup } from 'components/tools/select_document_group';
import { SelectPeriods } from 'components/tools/select_period';
import { searchDocuments } from 'api/documents';
import { useSearch } from 'hooks/useSearch';
import { CreateDocumentButton } from 'components/documents/create_modal';
import { Button } from 'components/core/button';
import { useAPI } from 'hooks/useAPI';

export function ModifyFailedClass({value, setValue}){
  const {t} = useTranslation('common');

  const student = useMemo(()=>(value.student || {}), [value.student]);
  const [selectedDocument, setSelectedDocumnet] = useState(value.document);
  const [period, setPeriod] = useState();
  const [documentGroup, setDocumentGroup] = useState();
  const params = useMemo(()=>({student, period, group:documentGroup}), [student, period, documentGroup]);
  const [documents, {execute: reloadDocuments, error: errorDocuments}] = useSearch(searchDocuments, params, {validateParams: (params=>params.period && params.group)})

  const [result, {error, execute}] = useAPI(updateFailedClass, {id:value.id}, {immediate: false})
  
  return<div className="flex-1 h-full flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("modify-failed-class.title")}</div></span>} />
              </CardContainer>
               {error && <Error.Text {...error}/>}
              <CardContainer className="mb-6 space-y-8">
                  {value.school_class?
                    <ModifiableSelectField 
                      Field={SearchSchoolClass} 
                      label={t("school-class")} 
                      modify={true} 
                      noBorder={true}
                      canRemoveSelection={false}
                      value={value.school_class} 
                    setValue={(schoolClass)=>{setValue({...value, school_class:schoolClass}); execute({id: value.id, schoolClass: schoolClass.slug})}}/>:
                   <ModifiableSelectField 
                      Field={SearchSchoolClass} 
                      label={t("school-class")} 
                      modify={true} 
                      noBorder={true}
                      canRemoveSelection={false}
                      value={value} 
                    setValue={(schoolClass)=>{setValue({...value, ...schoolClass}); execute({id: value.id, schoolClass: schoolClass.slug})}}/>}
                   <div className='flex items-center space-x-3 w-full'>
                    <SingleLineField 
                    label={true&&t("score")} 
                    modify={true} 
                    inputProps={{type: "number", step: 0.1, max: "100", min: "0"}}
                      noBorder={true}
                      value={value.score} 
                    setValue={(score)=>{setValue({...value, score}); execute({id: value.id, score})}}/>

                    <SingleLineField 
                    label={t("description")} 
                    modify={true} 
                      noBorder={true}
                      value={value.description} 
                    setValue={(description)=>{setValue({...value, description}); execute({id: value.id, description})}}/>
                  </div>
                  <div>
                  <div className='space-y-3'>
                    <InfoSectionHeading mediumTitle={t("associate-document.title")} />
                    {value.document && value.document.group && value.document.period &&
                      <RawTextInfo>
                        {t("document-associated", {group: value.document.group.name,  
                          id: value.document.id, 
                          period: value.document.period.name, 
                          student: student.name})}
                      </RawTextInfo>}
                  <div className='flex items-center justify-between'>
                    <InfoSectionHeading smallTitle={t("associate-document.new-title")} description={t("associate-document.new-description")}/>

                    <div className='flex items-center space-x-3 justify-end'>
                      <SelectDocumentGroup 
                        value={documentGroup} 
                        setValue={(value)=>{setDocumentGroup(value); setSelectedDocumnet()}} 
                        targetWidth="flexfull" 
                        color="default" 
                        size="md"/>
                      <SelectPeriods value={period} 
                        setValue={(value)=>{setPeriod(value); setSelectedDocumnet()}} 
                            targetWidth="flexfull"
                            color="default" 
                            size="md"/>
                    </div>
                    </div>
                  </div>
              
                    {(documents && documentGroup &&period) && 
                    ((result && result.document &&selectedDocument && result.document.id===selectedDocument.id)?
                    <RawTextInfo>
                      {t("document-associated-with-failed", {group: selectedDocument.group.name, period: selectedDocument.period.name, student: student.name})}
                    </RawTextInfo>
                    :
                    documents.length===0?
                    <RawTextWarning>
                    {t("no-documents-found-for-modify", {student: student.name, group: documentGroup && documentGroup.name, period: period && period.name})}
                    <br/>
                    {documents && documentGroup &&period && documents.length===0 && <CreateDocumentButton className="mt-2"  color="warningCard" creatorProps={{defaultStudent:student, defaultPeriod: period, defaultGroup: documentGroup}} onCompleted={reloadDocuments}/>}

                    </RawTextWarning>:
                    <RawTextInfo>
                      {t("documents-found-for-modify", {group: documents[0].group.name, period: documents[0].period.name, student: student.name, count: documents.length})}
                      <br/>
                      {documents.map(document=><Button color="infoCard" size="md" className="mt-2" key={document.id} 
                      onClick={()=>{setValue({...value, document}); 
                      setSelectedDocumnet(document);
                      execute({document})}}>{`${document.group.name} ${document.period.name} (id: ${document.id})`}</Button>)}
                    </RawTextInfo>)
                    }
                  {errorDocuments? <Error.Text {...errorDocuments}/>: null}

                    </div>
                  <ModifiableSelectField 
                      Field={SelectDate} 
                      label={t("started-at")} 
                      modify={true} 
                      noBorder={true}
                      canRemoveSelection={false}
                      value={dateParse(value.started_at)} 
                      setValue={(startedAt)=>{setValue({...value, started_at: startedAt?startedAt.toISOString().slice(0,10):null}); execute({id: value.id, startedAt})}}/>
                   
                  <ModifiableSelectField 
                      Field={SelectDate} 
                      label={t("ended-at")} 
                      noBorder={true}
                      modify={true} 
                      value={dateParse(value.ended_at)} 
                      setValue={(endedAt)=>{setValue({...value, ended_at: endedAt?endedAt.toISOString().slice(0,10): null}); execute({id: value.id, endedAt: endedAt || "null"})}}/>
                   



              </CardContainer>
            </div>
}