import React from "react";

// Components
import { Pill } from "components/core/pill";
import { Tooltip } from "components/core/tooltip";
import { Button } from "components/core/button";

// Utils
import classNames from "classnames";

// Hooks
import { useTranslation } from "react-i18next";

export function Asa({ asa, loading, className}) {
    const { t } = useTranslation();
    if (loading || !asa) return <div className={className}></div>
    return <div className={classNames(className,"")}>
        <Tooltip color="light" position="right" delay="0" contentClassName="!px-1 !max-w-52" content={
            <div className="p-1">
                <div className="text-gray-500">{t("asa")}</div>
                <div className="flex flex-col gap-1 text-gray-500">
                    {
                        asa?.map((asa, i) => {
                            const formattedDate = new Date(asa?.createdAt).toLocaleDateString('en-CA')
                            return <Button key={i} color="hiddenLink" href={`?noteId=${asa?.id}`}>
                                <div className="text-sm flex gap-1">{formattedDate}</div>
                            </Button>
                        })
                    }
                </div>
            </div>
        }>
            <div className='relative w-fit p-2.5 py-1 h-fit'>
                <Pill.Empty color="red" className="px-2">
                    {asa?.length}
                </Pill.Empty>
            </div>
        </Tooltip>
    </div>
}
