import React, { createContext } from "react";

// API
import { retrievePairingGroupWithPairingId } from 'api/pairings';


// Hooks
import { useMemo } from 'react';
import { useAPI } from 'hooks/useAPI';
import { useParams } from 'react-router-dom';

const PairingGroupContext = createContext({});

const PairingGroupContextProvider = ({ children }) => {
    const {id} = useParams();
    const params = useMemo(() => ({pairingId: id}), [id]);
    const validateParams = useMemo(() => (params) => !!params.pairingId, []);
    const [group, { loading, execute, setResult:setGroup, error, }] = useAPI(retrievePairingGroupWithPairingId, params, { immediate: true, camelize: true, validateParams });
    return (
        <PairingGroupContext.Provider value={{group, setGroup, reload:execute, loading, error}}>
            {children}
        </PairingGroupContext.Provider>
    );
};

export { PairingGroupContext, PairingGroupContextProvider };