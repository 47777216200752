import React from 'react';

// Hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { EmptyState } from 'components/core/empty';

// Utils
import  * as d3 from 'd3-array';
import { InfoSectionHeading } from 'components/core/typo';
import { cellStyles } from 'components/events/play_time_view';
import classnames from 'classnames';

/*
  Show the rules
*/
export function Fraction({done, target}){
  const fraction = `${done} / ${target}`;

  var color = "green";
  if (done>0){
    if (done<target){
      color = "yellow";
    }
    if (done>target){
      color = "blue";
    }
  }
  else{
    if (target>0) color = "red";
    else color = "green";
  }

  return <span className={classnames("py-1 px-2 rounded-md h-8 text-center relative whitespace-nowrap", 
  cellStyles[color])}>{fraction}</span>

}
function Cell({target, values}){
  const {t} = useTranslation('common');
  return <div>
           <div className="flex items-center justify-between">
            <InfoSectionHeading noMargin smallTitle={t("num-add-by-week", {count:target})}/>
          </div>
           <div className=" space-y-3 font-normal bg-gray-100 rounded-lg py-2">
            {values.sort((a, b)=>d3.ascending(parseInt(a.done_study_sessions), parseInt(b.done_study_sessions))).map(d=>
              <div key={d.done_study_sessions}>
                <div className='space-x-3 flex items-center hover:bg-gray-200 px-2 '>
                  <Fraction done={d.done_study_sessions} target={target}/>
                  <p className="w-full">{d.play_time_title}</p>
                </div>
                {d.done_study_sessions_description && <p className="text-gray-500 text-sm underline"> {d.done_study_sessions_description}</p>}
              </div>
              )}
           </div>
          </div>
}

export function RulesCard({rules}){
  const {t} = useTranslation('common');
  const sortedRules = useMemo(()=>{
    if (!rules) return;
    return Array.from(d3.group(rules, d=>d.sport.name, d=>d.weeks, d=>d.target_study_sessions)).sort(([a], [b]) => d3.ascending(a, b));
  }, [rules]);

  if (!rules || rules.length===0) {
    return <EmptyState title={t("empty-state.no-rules-title")} className="normal-case "/>
  }
  return (<div className="text-base space-y-12 normal-case p-3 text-gray-800">
            {sortedRules.map(([sport, val])=>
              <div key={sport}>
                {sortedRules.length>1 && <p className="text-2xl font-bold capitalize ">{sport}</p>}
                <div className="w-screen max-w-lg">
                  {Array.from(val).map(([weeks, val2])=>
                    <div key={weeks}>
                     {Array.from(val).length>1 && <p className="text-xl mt-3 text-blue-500 font-bold">{weeks} semaine</p>}
                     <div className="space-y-6">
                      {Array.from(val2).sort(([a], [b])=>d3.ascending(parseInt(a), parseInt(b))).map(([target, val3])=><Cell key={target} values={val3} target={target}/>)}
                     </div>
                  </div>
                  )}
                </div>
              </div>
              )}


            {/*{sortedRules.map(([sport, weeks, target, val],i)=><Cell key={i} values={val} target={target} sport={sport} weeks={weeks}/>)}*/}
          </div>)
}