import { useState, useEffect } from 'react'; 
import { isLoggedIn } from 'api/auth';
/*
  Make a call eveytime to check if use is connected.
*/
export function useIsConnected(){
  const [isConnected, setIsConnected] = useState();
  useEffect(()=>{
    const [promise] = isLoggedIn()
    promise
      .then(()=>setIsConnected(true))
      .catch(()=>setIsConnected(false));
  }, []);
  return isConnected;
}