/*
  This list is intended for managing the meetingDates, not for selection.
*/

import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useContext, useState } from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Button } from 'components/core/button';
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';
import { Fragment } from 'react';
import { SlideOver } from 'components/core/slide_over';
import { CreateMeetingDate } from 'components/meeting-dates/create';
import { ModifyMeetingDate } from 'components/meeting-dates/modify';

// API
import { searchMeetingDates } from 'api/meeting_dates';
import { DoubleMonthView } from 'components/tools/double_month_view';
// import { useMonth } from 'hooks/dates/useMonth';

function CreateButton({onChange, intervals}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="md" color="active">{t("add-meeting-date.create-btn")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <CreateMeetingDate onCompleted={()=>{setOpen(false); onChange()}} intervals={intervals}/>
          </SlideOver>
        </Fragment>
}

function ModifyButton({value, setValue, onDelete}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("modify")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <ModifyMeetingDate value={value} setValue={setValue} onDelete={()=>{setOpen(false); onDelete(value)}}/>
          </SlideOver>
        </Fragment>
}

export function ControlledMeetingDatesList({meetingDates, setMeetingDates, setPage, paging, error, execute}){
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { t } = useTranslation('common');

  const [date, setDate] = useState(null);
  const intervals = useMemo(()=>[...(meetingDates || [])].sort((a,b)=>a.id>b.id).map((d,i)=>{
    const fromDate = new Date(d.start);
    fromDate.setDate(fromDate.getDate()+1);
    const toDate = new Date(d.end);
    toDate.setDate(toDate.getDate()+1);
    return {fromDate, toDate, blue: i%2===0}
  }), [meetingDates]);
  const intervalsPale = useMemo(()=>(meetingDates||[]).map((d)=>{
    const fromDate = new Date(d.start);
    fromDate.setDate(fromDate.getDate()+1);
    const toDate = new Date(d.end);
    toDate.setDate(toDate.getDate()+1);
    return {fromDate, toDate, className:'cursor-not-allowed even text-gray-300 bg-gray-100 pointer-events-none'}
  }), [meetingDates]);

  // const windowDates = useMonth(date, {months: 4});
  const headers = useMemo(()=>[
    
   
    {
      title: t("start"),
      field: (d=>d? d.start : "start"),
      itemClassName: "py-2 whitespace-nowrap"
     },
     {
       title: t("end"),
       field: (d=>d? d.end : "end"),
       itemClassName: "py-2 whitespace-nowrap"
      },
      {
        title: t("meeting-goal"),
        field: (d=>d? d.meeting_goal : "meeting-goal"),
        itemClassName: "py-2 whitespace-nowrap"
       },
       {
        title: t("description"),
        field: (d=>d? d.description : "description"),
        parseNull: true,
        format: (d=>d? d : "-"),
        itemClassName: "py-2 text-xs"
       },
    {
     field: (d=>d? ({value:d, 
      onDelete:((d=>setMeetingDates(current=>current.filter(e=>e.id!==d.id)))), 
      setValue: ((next)=>setMeetingDates(current=>current.map(e=>e.id===next.id? next:e)))}) : "Modifier"),
     FormatComponent: ModifyButton
    },
  ], [meetingDates])

  if (!meetingDates){
    return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2"/>
  }

  return <Info.Container modify={true} sideHeader={<Fragment><CreateButton intervals={intervalsPale} onChange={execute}/></Fragment>}>
          <div className="w-full max-w-2xl mx-auto">
            <DoubleMonthView date={date} onDateChange={setDate} intervals={intervals} layout="side" numMonths={2}/>
          </div>
            {meetingDates.length===0?
              <EmptyState
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-meetingDates-title")}/>
              :
              <Table headers={headers}
                    data={meetingDates}
                    indexingKey="id"
                    order={{by:filters.orderBy, direction:filters.orderDirection}}
                    onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
              }
              {paging&& <Paginate setPage={setPage} {...paging}/>}
              {error? <Error.Text {...error}/>: null}
          </Info.Container>
}

function MeetingDatesListWithContext(){
  const { filters } = useContext(SearchContext);
  const [meetingDates, {loading, error, paging, setPage, setResult, execute}] = useSearch(searchMeetingDates, filters);
  return <ControlledMeetingDatesList meetingDates={meetingDates} setMeetingDates={setResult} setPage={setPage} loading={loading} error={error} paging={paging} execute={execute}/>
}

export function MeetingDatesList(){
  return <SearchContextProvider doNotStore defaultState={{orderBy:"start", orderDirection: "desc"}}>
            <MeetingDatesListWithContext/>
        </SearchContextProvider>
}