import React from 'react';
import classnames from 'classnames';

// Components
import { DashboardLayout, MultiColumn } from 'components/core/layouts';
import { FiClock } from 'react-icons/fi';
import { StudySessionSearchList } from 'components/study-sessions/side_list'; 
import { FilterBar } from 'components/tools/filter_bar';
import { SectionHeading } from 'components/core/typo';
import { Outlet } from "react-router-dom";

// Contexts
import { SearchContextProvider } from 'contexts/search';

// Hooks
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export function StudySessionListPage(){
  const { id } = useParams();
  const { t } = useTranslation("common")
  return <SearchContextProvider storageKey={"/study-sessions"}  defaultState={{orderBy: "period", orderDirection: "desc", date: new Date()}}>
          <DashboardLayout contentClassName={"overflow-hidden"}>
            <SectionHeading title={t("study-sessions")} className={classnames(id && "hidden md:block")}>
            <FilterBar preset team student studyCode/>
            </SectionHeading>
            <MultiColumn>
              <MultiColumn.Left className={classnames("border-r", id && "hidden md:block")}> 
                <StudySessionSearchList/>
              </MultiColumn.Left>
              <MultiColumn.Content  className={classnames(!id && "hidden md:block")}>
                <Outlet/>
              </MultiColumn.Content>
            </MultiColumn>
           </DashboardLayout>
         </SearchContextProvider>
}

const route =  { path: "/study-sessions", 
                 name: "Aides aux devoirs" , 
                 Icon: FiClock,
                 showInNavBar: true, 
                 requireAuth: true, 
                 Element: StudySessionListPage };
export default route;


