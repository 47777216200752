import React from 'react';
import classnames from 'classnames';

// Components
import { NavLink } from 'react-router-dom'
import { Tab } from '@headlessui/react'

// Hooks
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

// Context
import { TutoringFollowUpContext } from 'contexts/tutoring_follow_up';

export function TutoringFollowUpTabs() {
  const { filters } = useContext(TutoringFollowUpContext);
  const location = useLocation();
  const values = [
    {
      name: "BDT",
      to: filters?.preset ? `${filters.preset.id}/bdt` : "bdt"
    },
    {
      name: "BDI",
      to: filters?.preset ? `${filters.preset.id}/notes` : "notes"
    },
    {
      name: "Stats",
      to: filters?.preset ? `${filters.preset.id}/stats` : "stats"
    }
  ]
  const active = values.findIndex(d=>location.pathname.includes(d.to))
  return (
    <Tab.Group defaultIndex={values.findIndex(d=>location.pathname.includes(d.to))}>
        <Tab.List className="flex space-x-1 rounded-xl bg-gray-800 p-1 max-w-md">
          {values.map((category, index) => (
            <Tab
              key={category.name}
              as={NavLink}
              to={category.to}
              className={() =>
                classnames(
                  'w-full whitespace-nowrap px-3 rounded-lg py-1 text-sm  leading-5 ',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-gray-400 focus:outline-none',
                  active == index
                    ? 'bg-white text-gray-600 shadow'
                    : 'text-white hover:bg-white/20 hover:text-white'
                )
              }
            >
              {category.name}
            </Tab>
          ))}
        </Tab.List>
    </Tab.Group>
  )
}