import React from 'react';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import { ForgotPasswordForm } from 'components/forgot_password';

export function ForgotPasswordPage(){
  return <CenterViewLayout>
          <ForgotPasswordForm/>
         </CenterViewLayout>
}

const route =  { path: "/forgot-password", 
                 name: "Forgot password" , 
                 showInNavBar: false, 
                 requireAuth: false, 
                 Element: ForgotPasswordPage };
export default route;
