import React from 'react';

// Components
import { SearchTeams } from 'components/tools/search_teams';
import { SearchPreset } from 'components/tools/search_preset';
import { FollowUpTabs } from 'components/follow-up/tabs';
import { Button } from 'components/core/button';

// Hooks
import { useContext, useMemo } from 'react';

// Context
import { SearchFollowUpContext } from 'contexts/search_follow_up';
import { InfoSectionHeading } from 'components/core/typo';
import { useTranslation } from 'react-i18next';

export function FilterBar({presetTo}){
  const { filters, dispatchFilters } = useContext(SearchFollowUpContext);
  const teamExtraParams = useMemo(()=>({preset: filters.preset}), [filters && filters.preset])
  const { t } = useTranslation('common');
  return <div className=' bg-gray-100 border-b lg:px-3'>
        <div className="lg:flex lg:items-center lg:justify-between w-full flex-shrink-0  lg:h-12 py-2  space-x-3">
            <InfoSectionHeading mediumTitle={t("suivi-terrain")}/>
            <div className='lg:flex lg:items-center ml-auto lg:space-x-3 space-y-3 lg:space-y-0 '>
              <SearchPreset  
                    to={presetTo} 
                    targetWidth="lg" 
                    color="white" 
                    value={filters.preset} 
                    limitToUser={true} 
                    setValue={(value)=>dispatchFilters({type: "preset", value})}/>
            {filters.preset&&
              <SearchTeams 
                  color="white" 
                  value={filters.team} targetWidth="sm" 
                    extraParams={teamExtraParams} 
                    setValue={(value)=>dispatchFilters({type: "team", value})}/>}
          </div>
         
          </div>
          {filters.preset && filters.preset.id && <FollowUpTabs/>}

          </div>
}

export function TeamListInPreset(){
  const { filters, dispatchFilters } = useContext(SearchFollowUpContext);
  return filters.preset && filters.preset.teams ?
          <div className="items-center flex-shrink-0 px-6 py-1 bg-gray-100 border-b">
            {filters.preset.teams.map(d=><Button key={d.slug} 
                                                color={filters.team && filters.team.slug===d.slug?"active": "gray"}
                                                 size="md" 
                                                  className="inline-flex mr-2 "
                                                 onClick={()=>dispatchFilters({type: 'team', value: (filters.team && filters.team.slug===d.slug)? null: d})}>{d.name}</Button>)}
          </div>: null
}