/*eslint-disable */
/*
  This list is intended for managing the presets, not for selection.
*/

import React, { useCallback } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useContext, useState } from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Components
// import { FiMoreVertical } from 'react-icons/fi';
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Button } from 'components/core/button';
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';
import { Fragment } from 'react';
import { SlideOver } from 'components/core/slide_over';
import { CreatePreset } from 'components/presets/create';
import { ModifyPreset } from 'components/presets/modify';
import { FiCopy } from "react-icons/fi";
import { useUserPresets, UserPresetContextProvider } from 'contexts/user-presets';

// Utils
// import classnames from 'classnames';
import { group } from 'd3-array';

// API
import { copyPreset, searchPresets } from 'api/presets';
import { useUser } from 'hooks/useUser';
import { Modal, ModalBasic } from 'components/core/modal';
import { useAPI } from 'hooks/useAPI';

function CreateButton({ onChange }) {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    return <Fragment>
        <Button onClick={() => setOpen(true)} size="md" color="active">{t("add-preset")}</Button>
        <SlideOver open={open} setOpen={setOpen} size="xl">
            <CreatePreset onCompleted={() => { setOpen(false); onChange?.() }} />
        </SlideOver>
    </Fragment>
}

function ModifyButton({ value, setValue, onDelete }) {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    return <Fragment>
        <Button onClick={() => setOpen(true)} size="xs" color="gray">{t("modify")}</Button>
        <SlideOver open={open} setOpen={setOpen} size="xl">
            <ModifyPreset value={value} setValue={setValue} onDelete={onDelete} />
        </SlideOver>
    </Fragment>
}

function CopyButton({ preset }) {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    const [, { execute }] = useAPI(copyPreset, { id: preset?.id }, { immediate: false });
    const {reload} = useUserPresets();
    const handleCopy = () => {
        execute()
        reload()
        setOpen(false)
    }
    return <>
        <Button onClick={() => setOpen(true)} size="xs" color="gray">{t("copy")}</Button>
        <Modal open={open} setOpen={setOpen} size="sm">
            <ModalBasic
                title={t("copy-preset.title", { name: preset?.name })}
                description={t("copy-preset.description", { name: preset?.name })}
                Icon={FiCopy}
                submitLabel={t("copy-preset.button")}
                onClick={handleCopy}
            />
        </Modal>

    </>
}

export function ControlledPresetsList({ presets, title, setPresets, paging, setPage, error, execute, canAdd = true, }) {
    const { filters, dispatchFilters } = useContext(SearchContext);
    const { t } = useTranslation('common');
    const groupTeams = useCallback((teams) => {
        const groups = group(teams, d => (d.active_period.name || d.active_period));
        // Join the groups as a string
        return Array.from(groups).map(([key, value]) => `${key}: ${value.map(e => e.name).join(", ")}`).join("\n");
    }, [])
    const headers = useMemo(() => [
        {
            title: t("users"),
            field: (d => d ? d : "user"),
            format: (d => `${d.user && d.user.name}`),
            itemClassName: d => (d.core ? "!py-2 border-l-4 border-orange-500 md:min-w-[200px] " : "!py-2 md:min-w-[200px] ")
        }, {
            title: t("name"),
            field: (d => d ? d.name : "name"),
            itemClassName: "text-gray-700 md:min-w-[200px] ",
        },
        {
            title: t("teams"),
            field: (d => d ? groupTeams(d.teams) : "équipes"),
            itemClassName: "text-gray-600 py-2 whitespace-pre-wrap text-xs "
        },
        {
            headerId: "copy",
            field: (d) => d,
            hideHeader: true,
            itemClassName: "text-gray-600 py-2",
            FormatComponent: (d) => <CopyButton preset={d} />
        },
        {
            hideHeader: true,
            field: (d => d ? ({ value: d, onDelete: (d => setPresets(e => e.filter(v => v.id !== d.id))), setValue: ((next) => setPresets(current => current.map(e => e.id === next.id ? next : e))) }) : "Modifier"),
            itemClassName: "text-gray-600 py-2",
            FormatComponent: ModifyButton
        },
    ], [presets])

    if (!presets) {
        return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2" />
    }

    return <Info.Container modify={true} largeTitle={title} description={t("presets-description")} sideHeader={canAdd && <CreateButton onChange={execute} />}>
        {presets.length === 0 ?
            <EmptyState
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-presets-title")} />
            :
            <Table headers={headers}
                data={presets}
                rowClassName={(d => d.core ? "!bg-orange-50" : "")}
                indexingKey="id"
                order={{ by: filters.orderBy, direction: filters.orderDirection }}
                onHeaderClick={(value) => dispatchFilters({ type: 'ordering', value })} />
        }
        {paging && <Paginate setPage={setPage} {...paging} />}
        {error ? <Error.Text {...error} /> : null}
    </Info.Container>
}

function PresetsListWithContext() {
    const { filters } = useContext(SearchContext);
    const [presets, { loading, error, paging, setPage, setResult, execute }] = useSearch(searchPresets, filters);
    const { t } = useTranslation('common');
    return <ControlledPresetsList presets={presets}
        title={t("all-presets")}
        setPresets={setResult}
        loading={loading}
        setPage={setPage}
        error={error}
        paging={paging}
        execute={execute}
        canAdd={false} />
}

function SelfPresetsList() {
    const { t } = useTranslation('common');
    const {presets, setPresets, loading, error, paging, setPage, reload} = useUserPresets();
    return <ControlledPresetsList presets={presets}
        title={t("my-presets")}
        setPresets={setPresets}
        loading={loading}
        setPage={setPage}
        error={error}
        paging={paging}
        execute={reload} />

}



export function PresetsList() {
    return <>
        <UserPresetContextProvider>
            <SearchContextProvider doNotStore>
                <SelfPresetsList />
            </SearchContextProvider>
                <div className="h-24 " />
            <SearchContextProvider doNotStore>
                <PresetsListWithContext />
            </SearchContextProvider>
        </UserPresetContextProvider>
    </>
        
}