import {timeMonth} from 'd3-time';

export function getCurrentPeriod(){
  const date = timeMonth.offset(new Date(), -6); // 1er juillet
  return `${date.getFullYear()}-${date.getFullYear()+1}`
}

export function getFullCurrentPeriod(){
    const start = new Date(new Date().getFullYear(), 6, 1); // 1er juillet
    const end = new Date(new Date().getFullYear() + 1, 5, 30); // 30 juin
  return {slug: getCurrentPeriod(), name: getCurrentPeriod(), start, end}
}