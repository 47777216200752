import React from 'react';

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading  } from 'components/core/typo';
import { TextInput } from 'components/core/inputs';
import { Error } from 'components/core/typo'
import { Button } from 'components/core/button';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useAPI } from 'hooks/useAPI';

// API
import { createSchoolSubject } from 'api/subjects';

export function CreateSchoolSubject({onCompleted}){
  const [schoolSubject, setSchoolSubject] = useState({teams: []});
  const [subject, {loading, execute, error}] = useAPI(createSchoolSubject, schoolSubject, {immediate:false});
  const {t} = useTranslation('common');

  useEffect(()=>{
    if (subject) onCompleted(subject);
  }, [subject])

  return<div className="flex-1 h-full overflow-hidden flex flex-col">
              <CardContainer className="mb-6">
                <CardSectionHeading 
                    title={<span className="flex items-center"> 
                            <div>{t("add-school-subject")}</div></span>} />
              </CardContainer>
              <CardContainer className="mb-6 space-y-8">
                <TextInput
                  label={t("name")}
                  onChange={(name)=>setSchoolSubject(d=>({...d, name}))}
                />
               
                <Button onClick={execute} disabled={!schoolSubject.name} loading={loading} block size="lg" color="active">{t("add-school-subject")}</Button>
                {error&& <Error.Text {...error}/>}
              </CardContainer>
            </div>


}