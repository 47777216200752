import { fetchCall } from 'api';

/*
  Update note codes
*/  
export const updateNoteCode = ({code, name, description, initials, special, color, group}) => {
  const options = {
    method: "POST", 
    endpoint: `/note-codes/${code}`,
    body: { name, description, color, group, special, initials}
  }
  return fetchCall(options)
};

/*
  Retrieve note code
*/  
export const retrieveNoteCode = ({code}) => {
  const options = {
    method: "GET", 
    endpoint: `/note-codes/${code}`
  }
  return fetchCall(options)
};

/*
  Delete note code
*/  
export const deleteNoteCode = ({code}) => {
  const options = {
    method: "DELETE", 
    endpoint: `/note-codes/${code}`
  }
  return fetchCall(options)
};

/*
  Create note codes
*/  
export const createNoteCode = ({ name, description, color, group,initials, special}) => {
  const options = {
    method: "POST", 
    endpoint: `/note-codes`,
    body: { name, description, color, group: group && group.group, special, initials}
  }
  return fetchCall(options)
};
