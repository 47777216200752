import React from 'react';

// Utils
import classnames from "classnames";

// Components
import { FiAlertCircle, FiSearch, FiEye, FiEyeOff, FiCheck } from "react-icons/fi";
import { Spinner } from 'components/core/spinner';
import { Button } from 'components/core/button';

// Hooks 
import { useFocus } from 'hooks/useFocus';
import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const sizes = {
  xs: 'px-2 py-1 w-full',
  sm: 'px-2 py-1.5 w-full',
  md: 'px-2 py-2 lg:py-3 w-full',
  empty: 'px-0 py-2 lg:py-3',
}

const colors = {
  default: 'ring-[1px] ring-gray-300 border-0 text-warm-gray-900 placeholder-gray-300 shadow-sm rounded-md outline-none ring-0 focus:ring-2 focus:ring-orange-500 focus:border-transparent disabled:opacity-50 disabled:bg-gray-100 disabled:text-gray-500',
  none: 'bg-white focus:ring-0 focus:outline-none border-0 placeholder-gray-300',
  gray: 'bg-gray-100 focus:ring-2 ring-orange-500 focus:outline-none border-0 placeholder-gray-300 rounded-md ',
  grayWarning: 'bg-gray-100 border-2 border-yellow-500 focus:ring-2 ring-orange-500 focus:outline-none placeholder-gray-300 rounded-md ',
  yellow: 'bg-yellow-100 focus:ring-2 ring-orange-500 focus:outline-none border-0 placeholder-gray-300 rounded-md ',
  transparent: 'bg-none focus:ring-0 focus:outline-none border-0 placeholder-gray-300',
}

export function TextInput({label, 
                          description, 
                          xsDescription,
                          optional, 
                          error, 
                          success, 
                          warning, 
                          useLeftIcon, 
                          noIcons,
                          loading, 
                          hideEyeOnPassword, 
                          search, 
                          className, 
                          required, 
                          size="md", 
                          type="text", 
                          color="default", 
                          errorOnBlur, 
                          reformatOnBlur, 
                          defaultValue="",
                          validateUpdate,
                          onChange, 
                          ...props}){
  const {t} = useTranslation("common");
  const [value, setValue] = useState(defaultValue);
  const [asPassword, setAsPassword] = useState(type==="password");
  const [ref, onFocus] = useFocus();

  const _error = useMemo(()=>{
    if (errorOnBlur && !onFocus) return errorOnBlur(value);
  }, [value, onFocus]);

  const handleChange = useCallback((e)=>{
    if (validateUpdate && !validateUpdate(e.currentTarget.value)) return;
    setValue(e.currentTarget.value);
    onChange(e.currentTarget.value);
  }, [])

  return (<div className={className} >
            <div className="flex ">
              {label?
                <label htmlFor="title" className={"block text-sm font-medium text-gray-700"}>
                  {label}
                </label>:null}
              {optional? 
                <span className="ml-auto text-sm text-gray-500">
                  {t("optional")}
                </span>: null}
            </div>
            <div className="flex items-center w-full max-w-full mt-0">
              {!noIcons && useLeftIcon?
                <div className="flex-shrink-0 w-6">
                  {_error || error? <FiAlertCircle className="text-red-600"/>:
                    success? <FiCheck  className="text-green-500"/>: 
                  <FiCheck/>}
                </div>: null
              }
              <input
                ref={ref}
                required={required}
                onChange={handleChange}
                value={!onFocus && reformatOnBlur? reformatOnBlur(value): value}
                type={!hideEyeOnPassword&&type=="password"&& !asPassword? "text": type}
                {...props}
                className={classnames("flex-1",
                            sizes[size], 
                            colors[color],
                            _error || error? "border-red-600 text-red-600 placeholder-red-300": "")}
              />
              {!hideEyeOnPassword && type==="password"?
                <div className="flex-shrink-0 ml-2">
                  <Button color={!asPassword?"default":"inactive"} 
                    size="toolbarIcon" 
                    onClick={(e)=>{e.preventDefault(); setAsPassword(!asPassword)}}>{asPassword? <FiEye/>: <FiEyeOff/>}</Button>
                </div>
                :null}
              {(loading || search)  && (!useLeftIcon ) && !noIcons ?
                <div className={classnames('px-2 flex space-x-2 items-center ')}>
                  {loading? 
                    <Spinner className='my-auto ml-auto text-gray-500'/>:
                    search? <FiSearch className='my-auto ml-auto text-gray-500'/>: null}

                  { !useLeftIcon && (_error || error) ?
                  <div className="flex items-center pointer-events-none ">
                    <FiAlertCircle className="w-5 h-5 text-red-500" aria-hidden="true" />
                  </div>: null
                  }
                </div>:null}
            </div>
            {_error || error?
            <p className="mt-2 text-red-600 text">
              {_error || error}
            </p>: null}
            {xsDescription && <p className="text-gray-500 text-xs">{xsDescription}</p>}

            {description?
            <p className="mt-2 text-sm text-gray-500">
              {description}
            </p>: null}
            {success?
            <p className="mt-2 text-base text-green-500">
              {success}
            </p>: null}
            {warning?
            <p className="mt-2 text-base text-yellow-600">
              {warning}
            </p>: null}
          </div>)
}
