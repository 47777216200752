import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { Container } from 'components/core/container';
import { NewStudySession } from 'components/study-sessions/create';
import { SearchTeams } from 'components/tools/search_teams';

// Hookos
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function CreateStudySessionPage(){
  const { t } = useTranslation('common');
  const [team, setTeam] = useState();
  return <DashboardLayout>
           <div className="border-b py-2">
              <Container className="h-full">
                <div className="w-full max-w-sm">
                  <SearchTeams value={team} setValue={setTeam} label={t("select-team-context")}/>
                </div>
              </Container>
          </div>
          <Container className="h-full">
            <NewStudySession team={team}/>
          </Container>
         </DashboardLayout>
}

const route =  { path: "/new-study-session", 
                 name: "Nouvelle aide aux devoirs" , 
                 requireAuth: true, 
                 Element: CreateStudySessionPage };
export default route;
