import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { MultiColumn } from 'components/core/layouts';
import { DoubleMonthView } from 'components/tools/double_month_view';
import { Button } from 'components/core/button';
import { FiX } from 'react-icons/fi';
import { PlayTimeViewParams } from 'components/events/play_time_view';

// API
import { searchEvents } from 'api/events';

// Hooks
import { useMemo, useState } from 'react';
import { useSearch } from 'hooks/useSearch';
import { useMonth } from 'hooks/dates/useMonth';
import { useParams } from 'react-router-dom';

// Utils
import classnames from 'classnames';
import { locale, parseWithTimeZone} from 'utils/locale';
import { timeDay } from 'd3-time';
import { useTranslation } from 'react-i18next';

function LoadingEvents(){
  return <div className="space-y-5">
              <Skeleton className="h-16"/>
              <Skeleton.List numElements={3} className="space-y-4" itemClassName="h-8"/>
            </div>
}

function EventsList({preset, team}){
  const params = useParams();
  const {t} = useTranslation("common");
  const [focusDate, setFocusDate] = useState();
  const [date, setDate] = useState();
  const windowDates = useMonth(date);
  const searchParams = useMemo(()=>({team, preset, fromDate: focusDate? focusDate: windowDates.fromDate, toDate: focusDate? timeDay.offset(focusDate, 1): windowDates.toDate, excludePractice: false}), [windowDates, focusDate, preset, team]);
  const [events, {loading}] = useSearch(searchEvents, searchParams);

  const calendarEvents = useMemo(()=>{
    if (!events) return [];
    return events.map(d=>({date: parseWithTimeZone(d.date), color: d.event_type.color}))
  }, [events])

  if (!events){
    return <LoadingEvents/>
  }
  return <div className=''>
          <div className="w-full p-6">
            <DoubleMonthView 
                  date={date} 
                  onDateChange={setDate} 
                  onClick={d=>{setFocusDate(d)}} 
                  events={calendarEvents} 
                  layout="stacked" 
                  numMonths={1}/>
            {focusDate && 
              <p className="flex items-center justify-between p-2 mt-2 mb-2 space-x-3 bg-gray-100 rounded-lg">
                {locale.format("%d %B %Y")(focusDate)}
              <Button color="delete" onClick={()=>{setFocusDate(); setDate(new Date(date)) }}><FiX/></Button></p>}
          </div>
           <div className="divide-y">
            {(!events || loading)?
              <div className='space-y-3'>
                  <Skeleton className="h-16"/>
                  <Skeleton className="h-16"/>
                  <Skeleton className="h-16"/>
              </div>
              :events.map(d=>
              <Button className="p-3" 
                      layout="cell" 
                      nav 
                      key={d.id} 
                      color={(d)=>d? "activeCell": "cell"} 
                      href={params.eventId? `../play-time/${d.id}`:`${d.id}`}>
                {({isActive})=>
                <div className="flex items-center space-x-2">
                  <div className="w-4 h-4 rounded ring-4 ring-white" style={{background: d.event_type.color}}/>
                  <div className="flex-1">
                    <div className="flex items-center justify-between w-full">
                      <div className={classnames(isActive&& "font-medium text-white")}>{locale.format("%d %B %Y à %H:%M")(parseWithTimeZone(d.date))}</div>
                      <div className={classnames("text-sm whitespace-nowrap", isActive&& " text-white")}>{d.event_type.name}</div>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <div className={classnames("text-sm", isActive&& " text-white")}>{d.team.name}</div>
                      {d.transmitted? <div className={classnames("text-sm", isActive?" text-white": "text-blue-500")}>{t("is-transmitted")}</div>: null}
                    </div>

                  </div>
                </div>}
              </Button>)
            }
          </div>
         </div>
}

export function GameTimeNavigator({team, preset}){
  return <MultiColumn className="rounded-lg">
          <MultiColumn.Left enableCollapse={true} className="min-h-[calc(100vh-102px)] border-r bg-gray-50">
            <EventsList team={team} preset={preset}/>
          </MultiColumn.Left>
          <MultiColumn.Content>
            <PlayTimeViewParams className="p-3"/>
          </MultiColumn.Content>
         </MultiColumn>

}