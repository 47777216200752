import React from 'react';
import classnames from 'classnames';

// API
import { searchSchoolSubjects } from 'api/utils';
import { searchStudentFailedClasses } from 'api/students';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Fragment } from 'react';
import { Info } from 'components/core/info';
import { EmptyState } from 'components/core/empty';
import { CheckSessionWithIncorrectSubjects } from 'components/study-sessions/check_session_with_incorrect_subject';

// Hooks
import { useMemo, useEffect, useCallback } from 'react';
import { useSearch } from 'hooks/useSearch';
import { useTranslation } from 'react-i18next';
import { SelectMenu } from './select-menu';

export function SelectSchoolSubject({ value, setValue, onSelection, student, setStudySessionDoesCount, allowMultiple, name="subject" }){
  const { t } = useTranslation("common");
  const params = useMemo(()=>({ni: student && student.ni}), [student]);
  const [failedClasses, {execute}] = useSearch(searchStudentFailedClasses, params, {immediate: false});
  useEffect(()=>{
    if (student) execute();
  }, [execute]);

  const [subjects] = useSearch(searchSchoolSubjects);
  
  const handleChange = useCallback((d)=>{
    if (onSelection) onSelection(d);
    if (!allowMultiple){
      setValue(d);
      return
    }
    if (value){
      if (value.find(e=>e.slug===d.slug)){
          setValue(value.filter(e=>e.slug!==d.slug));
          return
        }
        setValue([...value, d]);
        return;
    }
    else {
      setValue([d]);
      return 
    }
  }, [value]);

  const containsSubjectNotFailed = useMemo(()=>{
    if (!failedClasses || failedClasses.length===0 || !value || value.length===0) return false;
    // Check if one fail class contains any

    if (failedClasses.filter(d=>!d.school_class.subject).length>0) return false;
    return value.filter(d=>failedClasses.filter(e=>e.school_class.subject.slug===d.slug).length===0).length>0

  }, [failedClasses, value])

  if (!subjects){
    return <Skeleton className="h-8"/>;
  }

  return <Fragment>
           {failedClasses && 
            <Info.Container className="bg-gray-100 mb-6" label={t("failed-school-classes")}>
              <Info.Field noValueLabel={t("empty-state.no-failed-classes-title")} 
                    value={failedClasses && failedClasses.map(d=>`${d.school_class.name} (${(d.school_class.subject && d.school_class.subject.name) || "tous"})`).join(", ")}/>  
            </Info.Container>}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-3 md:gap-3">
              {subjects.map(d=>
                {
                  const active = (value && ((Array.isArray(value) && value.find(e=>e.slug===d.slug)) || (d.slug===value.slug)))? true: false;
                  const isFailed = ((!failedClasses) || (failedClasses && failedClasses.filter(f=>(f.school_class.subject && f.school_class.subject.slug===d.slug)).length>0));
                  const hasNoFailed = ((!failedClasses) || (failedClasses.length===0));
                  return <div key={d.slug} 
                          className={classnames("rounded-md col-span-1 p-2 flex items-center cursor-pointer border-2",
                            active && isFailed ? "bg-gray-200 border-transparent":
                            active && !isFailed? "bg-yellow-100 text-yellow-700 border-transparent" : "hover:bg-gray-100 bg-gray-50 border-transparent",
                            !failedClasses? "text-gray-800":
                             isFailed ?"font-semibold ": "text-gray-600")}
                           onClick={()=>handleChange(d)}>
                          <div className="w-6 text-center " >
                            <input type={allowMultiple?"checkbox": "radio"} id={d.slug} name={name} readOnly checked={active}/>
                          </div>
                          <div className="flex-1 ml-3 ">
                            <div className="text-medium flex items-center">{d.name}</div>
                          {active && !isFailed && !hasNoFailed && <p className=" text-yellow-700 text-xs">{t("subject-not-failed")}</p>}
                          </div>
                      </div>}
              )}
            </div>

            {containsSubjectNotFailed && 
             <Fragment>
                <EmptyState 
                    color={"warning"}
                    className="mt-6"
                    title={t("contains-subjects-not-failed")}
                    description={t("contains-subject-not-failed-description")}>
                </EmptyState>
                <CheckSessionWithIncorrectSubjects setStudySessionDoesCount={setStudySessionDoesCount} student={student} />
              </Fragment>}

           </Fragment>
}


export function SelectSchoolSubjectsFormDropdown({ subjects, value, setValue, color, name, optionsClassName }){
  const {t} = useTranslation()
  return <SelectMenu values={(subjects||[]).map(d=>d.name)}
                     value={(value||[]).map(d=>d.name)}
                     setValue={(v)=>setValue((subjects||[]).filter(d=>v.includes(d.name)))}
                     optionsClassName={optionsClassName}
                     color={color}
                     format={d=>d}
                     name={name}
                     buttonLabel={(value || []).length===0? t("select-subject.menu.label", {count: (value || []).length}):
                              (value || []).length<4? (value || []).map(d=>d.name).join(', '):
                            t("select-subject.menu.label", {count: (value || []).length})}
                     indexKey={null}
                     multiple={true}/>
}

export function SelectSchoolSubjectsDropdown({value, setValue, optionsClassName}){
  const params = useMemo(()=>({}),[]);
  const [subjects ] = useSearch(searchSchoolSubjects,params);
  return <SelectSchoolSubjectsFormDropdown 
              value={value} 
              setValue={setValue} 
              name={"schoolSubjects"}
              color="grayMd"
              subjects={subjects}
              optionsClassName={optionsClassName}
        />
}

