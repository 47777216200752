import { fetchCall } from 'api';
import { isString, isBool } from 'utils/var_check';

/*
  Retrieve coach
*/
export const retrieveCoach = ({ni}) => {
  const options = {
    method: "GET",
    endpoint: `/coachs/${ni}`
  }
  return fetchCall(options)
};

/*
  Delete coach
*/
export const deleteCoach = ({ni}) => {
  const options = {
    method: "DELETE",
    endpoint: `/coachs/${ni}`
  }
  return fetchCall(options)
}

/*
  Update coach
*/
export const updateCoach = ({ni, name, email, phone, reasonForEndOfService, serviceStartedAt, serviceEndedAt}) => {
  const options = {
    method: "POST",
    endpoint: `/coachs/${ni}`,
    body: {name, email, phone, reasonForEndOfService, serviceStartedAt, serviceEndedAt}
  }
  return fetchCall(options)
};

/*
  Close all active coachs
*/
export const closeAllActiveCoachs = ({endedAt, reason}) => {
  const options = {
    method: "POST",
    endpoint: "/close-all-active-coachs",
    body: {endedAt: endedAt.toISOString().slice(0,10), reason}
  }
  return fetchCall(options)
};

/*
  Open coach
*/
export const openCoach = ({ni, period}) => {
  const options = {
    method: "POST",
    endpoint: `/coachs/${ni}/open`,
    body: {period: period && (isString(period)? period: period.slug)}
  }
  return fetchCall(options)
};

/*
  Close coach
*/
export const closeCoach = ({ni, endedAt, reason}) => {
  const options = {
    method: "POST",
    endpoint: `/coachs/${ni}/close`,
    body: {endedAt: endedAt.toISOString().slice(0,10), reason}
  }
  return fetchCall(options)
};

/*
  Search coaches
*/
export const searchCoachs = ({search, school, preset, period, fields, team, page, leftService, serviceIsEnded, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET",
    endpoint: "/coachs",
    query: {search,
            preset: preset && preset.id,
            school: school&&school.slug,
            period: period && (isString(period)? period: period.slug),
            fields,
            team: team && (isString(team)? team: team.slug),
            leftService: leftService && (isBool(leftService)? leftService: leftService.value),
            serviceIsEnded: serviceIsEnded && (isBool(serviceIsEnded)? serviceIsEnded: serviceIsEnded.value),
            page,
            limit,
            orderBy,
            orderDirection}
  }
  return fetchCall(options)
};


export function createCoach({name, email, phone, teams, serviceStartedAt, serviceEndedAt}){
    const options = {
    method: "POST",
    endpoint: "/coachs",
    body: {name, email, phone, teams, serviceStartedAt, serviceEndedAt}
  }
  return fetchCall(options)
}