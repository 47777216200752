import React from 'react';

import { Container } from 'components/core/container'
import { DocumentsList } from 'components/documents/list';

// Hooks
import { useContext } from 'react';

// Contexts
import { StudentContext } from 'contexts/student';

export default function StudentDocuments(){
  const { student } = useContext(StudentContext);

  return <Container>
          <DocumentsList student={student}/>
          </Container>
}
