import React from 'react';

// Components
import { Outlet } from "react-router-dom";
import { TutorLayout } from 'components/tutors/layout';

// Hooks

export function TutorBase(){
  return <TutorLayout>
            <Outlet/>
          </TutorLayout>
}

import { default as empty } from 'pages/tutor/empty';
import {default as overview} from 'pages/tutor/overview';
import {default as notes} from 'pages/tutor/notes';

export const TutorPages = {root: TutorBase, overview, notes, empty}