import { useState, useCallback, useEffect, useRef } from 'react';
import { useIsMounted } from 'hooks/useIsMounted';
import { Casing } from "utils/casing";

/*
  Example usage

*/
export function useAPI(method, params, {onResult, onCatch, immediate=true, validateParams, camelize=false, casingWhitelist}={}){

  const [result, setResult] = useState();
  const [loading, setLoading] = useState(immediate);
  const [error, setError] = useState();
  const activeController = useRef();
  const isMounted = useIsMounted();

  const execute = useCallback((extraParams)=>{
    if (!method) return;
    if (validateParams && !validateParams(params)) return;
    setLoading(true);
    setError();
    const handleSuccess = (res)=>{
      const camelRes = Casing.recursiveCamelize(res, {whitelist: casingWhitelist});
      res = camelize? camelRes: res;
      if (!isMounted.current) return;
      if (onResult){
        onResult(res);
      }
      setResult(res);
      setLoading(false);
      
    }
    const onError = (res)=>{
      if (!isMounted.current) return;
      setLoading(false);
      try {
        res.json()
            .then(({detail, code, payload})=>{setLoading(false); setError({code, detail, payload})})
            .catch(()=>{setLoading(false); setError({code: 'default'}); if (onCatch){onCatch()} });
      }
      catch {
        setLoading(false); 
        if (onCatch) onCatch();
        setError({code: 'default'});
      }
    }

    if (activeController.current) activeController.current.abort();
    const [promise, {controller}] = method(extraParams? ({...extraParams, ...params}): params)
    activeController.current = controller;
    return promise.then(handleSuccess).catch(onError);
  }, [method, onResult, params])

  // Fire when page reload
  useEffect(() => {
    if (immediate) execute();
  }, [execute]);

  return [result, {loading, error, execute, setResult, setError}];
}

