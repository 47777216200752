import { timeDay, timeHour, timeWeek, timeMonth } from 'd3-time';

export function datesAreInInterval(date1, date2, {step="hour"}){
  if (!date1 || !date2) return;
  switch (step){
    case 'hour':
      return Math.abs(timeHour.count(date1, date2))<1
    case 'day':
      return Math.abs(timeDay.count(date1, date2))<1
    case 'week':
      return Math.abs(timeWeek.count(date1, date2))<1
    case 'month':
      return Math.abs(timeMonth.count(date1, date2))<1
    default:
      return false
  }
}
