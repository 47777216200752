import { fetchCall } from 'api';

/*
  Create a sibling
*/  
export const createSibling = ({source, target, description, bidirectional}) => {
  const options = {
    method: "POST", 
    endpoint: `/siblings`,
    body: {source, target, description, bidirectional}
  }
  return fetchCall(options)
};

/*
  Delete a sibling
*/  
export const deleteSibling = ({id}) => {
  const options = {
    method: "DELETE", 
    endpoint: `/siblings/${id}`,
  }
  return fetchCall(options)
};
