import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSearch } from 'hooks/useSearch';

// Components
import { Fragment } from 'react'; 
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error, InfoSectionHeading } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Table } from 'components/core/table'
import { Info } from 'components/core/info'
import { ModifyFailedClass } from 'components/school-subjects/modify_failed';
import { Dropdown } from 'components/core/dropdown';
import { FiMoreHorizontal } from 'react-icons/fi'; 

// Contexts
import { SearchFailedSubjectsContext, SearchFailedSubjectsContextProvider } from 'contexts/search_failed_subjects';

// Utils
import { locale, dateParse } from 'utils/locale';
import { format } from 'd3-format';

// API
import { searchStudentFailedClasses, updateStudent } from 'api/students';
import { archivedFailedClass, deleteFailedClass } from 'api/classes';
import { useAPI } from 'hooks/useAPI';
import { Modal } from 'components/core/modal';
import { NewClassFailed } from 'components/tools/new_class_failed';
import { Button } from 'components/core/button';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { CheckBox } from 'components/tools/checkbox';

function Empty(){
  const { t } = useTranslation('common');
  return <EmptyState 
            className="h-full flex flex-col justify-center"
            title={t("empty-state.no-failed-classes-title")}>
         </EmptyState>
}

function OpenModal({setSelectedValue, id,  onDelete, ...props}){
  const { t } = useTranslation('common');
  const {filters } = useContext(SearchFailedSubjectsContext);
  const [ , deleteProps] = useAPI(deleteFailedClass, {id}, {immediate: false, onResult: (()=>onDelete(id))});
  const [ , archiveProps] = useAPI(archivedFailedClass, {id}, {immediate: false, onResult: (()=>onDelete(id))});
  return <Dropdown onlyIcon={<FiMoreHorizontal/>} menuItemsClassName="!w-[300px] ">
                {!filters.archived && <Dropdown.Item 
                  name={t("archive-failed-class")} 
                  description={t("archive-failed-class-description")} 
                  disabled={archiveProps.loading}
                  onClick={archiveProps.execute}/>}
              <Dropdown.Item 
                  name={t("modify-failed-class.title")} 
                  description={t("modify-failed-class.description")} 
                  onClick={()=>setSelectedValue({id, ...props})}/>

              <Dropdown.Item 
              color="danger" 
              name={t("delete")} 
              description={t("delete-failed-class-description")}
                disabled={deleteProps.loading}
               onClick={deleteProps.execute}/>
            </Dropdown>
}

function SubjectsTable({classes, paging, reload, setResult}){
  const { t } = useTranslation('common');
  const {filters, dispatchFilters } = useContext(SearchFailedSubjectsContext);
  const [selectedValue, setSelectedValue] = useState();
  const headers = useMemo(()=>{
    var h = [ 
      
      {
        title: t("name"),
        field: (d=>d?(d.school_class && d.school_class.name): "school_class"),
      },
      {
        title: t("score"),
        field: (d=>d?d.score: "score"),
        format: (d=>format(".2%")(d/100)),
        itemClassName: "text-gray-500 truncate"
      },
      {
        title: t("short-description"),
        field: (d=>d?d.description: "description"),
        itemClassName: "text-gray-500 truncate"
      },
      {
        title: t("started-at"),
        field: (d=>d?d.started_at: "started_at"),
        format: (d=>locale.format("%d %b %Y")(dateParse(d))),
        itemClassName: "text-gray-500"
      },
    ];
    if (filters.archived){
      h.push(
        {
          title: t("ended-at"),
          field: (d=>d?d.ended_at: "ended_at"),
          format: (d=>locale.format("%d %b %Y")(dateParse(d))),
          itemClassName: "text-gray-500"
        })
    }
    h.push({
          title: t("document"),
          field: (d=>d?d.document: "document"),
          format: (d=>d && d.id? `${d.group.name}`: ""),
          itemClassName: "text-gray-500"
        },
        {
          title: t("period"),
          field: (d=>d?d.document: "period"),
          format: (d=>d && d.id? `${d.period.name}`: ""),
          itemClassName: "text-gray-500"
        },
        {
          title: "",
          field: (d=>d?({...d, onDelete:reload, setSelectedValue}): "modify"),
          FormatComponent: OpenModal
        })
    return h
  }
  , [])

  return <Fragment >
     
            <Info.Container className="pb-3 pt-2 px-3 md:px-0" modify={true} >
              <Table headers={headers} 
              data={classes} 
              indexingKey={"id"} 
              order={{by:filters.orderBy, direction:filters.orderDirection}}
              onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
            </Info.Container>
            <Modal open={!!selectedValue} setOpen={(d)=>setSelectedValue(!!d)} >
              <ModifyFailedClass value={selectedValue} setValue={(d)=>{setSelectedValue(d);setResult(e=>e.map(f=>f.id===d.id?d:f))}}/>
            </Modal>
            {paging&& <Paginate {...paging}/>}
          </Fragment>
}

export function FailedClassesList({classes, student, setStudent, reload,  paging, error, showYearRetained, setResult}){
  const { t } = useTranslation('common');
  const { filters } = useContext(SearchFailedSubjectsContext)
  const [open, setOpen] = useState(false);

  if (!classes){
    return <Skeleton.List numElements={1} itemClassName="h-32" className="space-y-2"/>
  }

  return <div className="">

      {showYearRetained && <ModifiableSelectField
            Field={CheckBox} 
              label={t("has-year-retained.label")} 
              subLabel={t("has-year-retained.subLabel")} 
              modify={true}
              marker="select"
              value={(student.year_retained && student.year_retained==="True")? true: false}
              setValue={
                (yearRetained)=>{
                  updateStudent({ni:student.ni, yearRetained});
                  setStudent(d=>({...d, year_retained: yearRetained}))
                }
              }/>}



              <InfoSectionHeading title={filters.archived? t("archived-failed-classes"): t("active-failed-school-classes")} 
              description={filters.archived? t("archived-failed-classes-description"): t("active-failed-school-classes-description")}>
                {!filters.archived && <Button color="active" size="md" onClick={()=>setOpen(true)}>{t("add-school-class-failure")}</Button>}

              </InfoSectionHeading>
              {classes.length===0?
                <>
              
                
                <Info.Container className="pb-3 pt-2 px-3" >
                  <Empty/>
                </Info.Container></>:<SubjectsTable reload={reload} classes={classes} paging={paging} setResult={setResult}/>
              }
              {error? <Error.Text className="mt-3" {...error}/>: null}
              {!filters.archived && <Modal open={open} setOpen={setOpen}>
                <NewClassFailed defaultStudent={student} setValue={()=>{reload(); setOpen(false)}}/>
                </Modal>}
          </div>
}


export function FailedList({shouldRefresh, student, setStudent, forceRefresh, showYearRetained}){
  const params = useParams();
  const { filters, dispatchFilters } = useContext(SearchFailedSubjectsContext)
  const [classes, {loading, error, execute, setResult, page, setPage, paging}] = useSearch(searchStudentFailedClasses, filters, {limit:100});

  useEffect(()=>{
    if (shouldRefresh) execute()
  }, [shouldRefresh])
  
  useEffect(()=>{
    if (params && params.ni!==filters.ni){
      dispatchFilters({type: 'student', value: params.ni})
    }
  }, [params]);

  return <FailedClassesList showYearRetained={showYearRetained} classes={classes} student={student} setStudent={setStudent} reload={forceRefresh} paging={{...paging, page, setPage}} setResult={setResult} loading={loading} error={error}/>
}

export function StudentFailedClassesListWithContext({shouldRefresh, student, setStudent, forceRefresh, showYearRetained, archived}){
  const params = useParams();
  return <SearchFailedSubjectsContextProvider 
                defaultState={{ni: params.ni, archived, orderBy: "started_at", orderDirection: "desc"}}>
          <FailedList shouldRefresh={shouldRefresh} student={student} setStudent={setStudent} forceRefresh={forceRefresh} showYearRetained={showYearRetained}/>
          </SearchFailedSubjectsContextProvider>
}


