import { useMemo } from 'react';

// Utils
import { timeWeek, timeDay } from 'd3-time';

// Get the first date of month and +weeks
export function useWeek(date, {floorInput=true, floorOutput=false, offsetInput=0, weeks=1, weekDayOffset=0}={}){

  return useMemo(()=>{
    var d = date || new Date();
    const _fromDate = floorInput? timeWeek.offset(timeWeek.floor(d), offsetInput): timeWeek.offset(d, offsetInput);
    const _toDate = floorOutput?timeWeek.floor(timeWeek.offset(_fromDate, weeks)) : timeWeek.offset(_fromDate, weeks);
    const fromDate = timeDay.offset(_fromDate, weekDayOffset);
    const toDate = timeDay.offset(_toDate, weekDayOffset);
    
    if (weeks<0){
      return {fromDate: toDate, toDate: fromDate};
    }
    return {fromDate, toDate};
  }, [date])
}
