import { fetchCall } from 'api';

/*
  Update user info
*/  
export const updateUser = ({firstname, lastname}) => {
  const options = {
    method: "POST", 
    endpoint: "/users",
    body: {firstname, lastname}
  }
  return fetchCall(options)
};

/*
  Update user info
*/  
export const updateOtherUser = ({email, scope, role, school}) => {
  const options = {
    method: "POST", 
    endpoint: "/update-users",
    body: {email, scope, role: role && role.name, main_school: school}
  }
  return fetchCall(options)
};

/*
  Delete user
*/  
export const deleteUser = ({email}) => {
  const options = {
    method: "DELETE", 
    endpoint: "/users",
    query: {email}
  }
  return fetchCall(options)
};

/*
  Search app users
*/  
export const searchUsers = ({search, page, limit, orderBy, orderDirection, fields}) => {
  const options = {
    method: "GET", 
    endpoint: "/users",
    query: {search, page, limit, orderBy, orderDirection, fields}
  }
  return fetchCall(options)
};